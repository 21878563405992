import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseFormComponent, exclamationTriangle, SpinnerService, times } from 'src/app/shared/components';
import { AlertsService, ExtensionValidationService, FormUtilityService } from 'src/app/shared/services';
import { DietaryWarningsService } from '../../services/dietary-warnings.service';
import { DietaryWarningModel } from '../../interface/dietary-warning-model';
import * as $ from 'jquery';
import { Messages, RuntimeConstants } from 'src/app/shared/constants';

@Component({
  selector: 'pos-dietary-warning-edit',
  templateUrl: './dietary-warning-edit.component.html',
  styleUrls: ['./dietary-warning-edit.component.scss']
})
export class DietaryWarningEditComponent extends BaseFormComponent {

  @ViewChild('dietaryWarningForm', { static: true }) form: NgForm;
  id: number;
  icons = { exclamationTriangle, times }
  get getForm(): NgForm {
    return this.form
  }
  dietaryWarningsData: DietaryWarningModel;
  showImageIcon: boolean;
  image: File;
  imagePath = RuntimeConstants.IMAGE_BASE_PATH + '/allergens';
  originalName = '';

  constructor(
    private alertService: AlertsService,
    private spinnerService: SpinnerService,
    private route: ActivatedRoute,
    private router: Router,
    formUtilityService: FormUtilityService,
    private dietaryWarningsService: DietaryWarningsService,
    private extensionValidation: ExtensionValidationService,
  ) {
    super(formUtilityService);
    const id = parseInt(route.snapshot.params.id ?? 0, 10)
    this.dietaryWarningsData = this.dietaryWarningsService.newDietaryWarning(id);
  }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    if (this.dietaryWarningsData.Id > 0) {
      this.spinnerService.show();
      this.dietaryWarningsService.getDietaryWarningsById(this.dietaryWarningsData.Id)
      .subscribe({
        next: (res) => {
          this.dietaryWarningsData = res;
          this.originalName = this.dietaryWarningsData.Name;
          this.showImageIcon = this.dietaryWarningsData.Image ?  true : false;
        },
        error: this.alertService.showApiError,
        complete: () => this.spinnerService.hide(),
      })
    }
  }

  public handleFileInput(files: FileList) {
    this.image = files.item(0);
    const fileToUpload = this.image ? this.image.name : '';
    if (this.extensionValidation.isInvalidExtension(fileToUpload, ['.jpg', '.bmp', '.png', '.gif', '.webp'])) {
      if (fileToUpload) {
        this.alertService.renderErrorMessage(Messages.InvalidInventoryProductImageType);
      }
      this.removeIcon();
      return;
    }
    if (files && files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        $('#ingredientImage').attr('src', reader.result);
      };
      reader.readAsDataURL(files[0]);
      this.dietaryWarningsData.Image = this.image ? this.image.name : '';
      this.showImageIcon = true;
    }
  }

  public removeIcon(): void {
    this.dietaryWarningsData.Image = '';
    $('#image').val('');
    this.image = null;
    this.showImageIcon = false;
  }

  saveDietaryWarning(isValid: boolean) {
    if (!isValid) return;
    this.spinnerService.show();
    this.dietaryWarningsService.saveDietaryWarning(this.dietaryWarningsData, this.image)
    .subscribe({
      next: () => {
        this.alertService.renderSuccessMessage(Messages.DietaryWarningsSavedSuccess);
        this.onCancel()
      },
      error: this.alertService.showApiError,
      complete: () => this.spinnerService.hide(),
    })
  }

  onCancel() {
    this.router.navigate(['dietary-warnings'], { relativeTo: this.route.parent});
  }
}
