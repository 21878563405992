import { Component, TemplateRef, ViewChild } from '@angular/core';
import { AlertsService, ApplicationStateService, AuthenticationService, Levels, Permissions } from 'src/app/shared';
import { BaseGridComponent, ModalService, SpinnerService } from 'src/app/shared/components';
import { GiftCardService } from '../../services';
import { DateColumn, GridColumn, TableComponent, TemplateColumn, TextAlign } from '@tarktech/tark-ng-utils';
import { finalize } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { sortBy } from 'lodash';
import { GiftCardComponent } from '../gift-card.component';
import * as moment from 'moment';

@Component({
  selector: 'pos-gift-card-list',
  templateUrl: './gift-card-list.component.html',
  styleUrls: ['./gift-card-list.component.scss']
})
export class GiftCardListComponent extends BaseGridComponent {
  
  giftCardsList = [];
  filteredGiftCardsList = [];
  giftCardColumns: Array<GridColumn> = [];
  isActive = true;
  @ViewChild('priceTemplate', { static: true }) private priceTemplate: TemplateRef<any>;
  @ViewChild('giftCardNumberTemplate', { static: true }) private giftCardNumberTemplate: TemplateRef<any>;
  @ViewChild('giftCardNumberEditTemplate', { static: true }) private giftCardNumberEditTemplate: TemplateRef<any>;

  @ViewChild('giftCardList', { static: true }) giftCardList: TableComponent;

  permission = {
    name: Permissions.GiftCards,
    readOnlyLevel: Levels.ReadOnly,
    editLevel: Levels.Access,
    parent: Permissions.Sales,
    parentAccessLevel: Levels.Access
  };

  constructor(
    private alertService: AlertsService,
    private spinnerService: SpinnerService,
    private giftCardService: GiftCardService,
    private router: Router,
    private authenticationService: AuthenticationService,
    private modalService: ModalService,
    protected route: ActivatedRoute,
    protected applicationStateService: ApplicationStateService,
  ) {
    super(applicationStateService, route);
  }

  ngOnInit() {
    this.giftCardList.context = this.gridContext;
    this.configureGridColumns();
    this.loadData();
  }

  configureGridColumns() {
    const userHasPermission = this.authenticationService.userHasPermission([{ Name: this.permission.name, Level: this.permission.editLevel }], 'any');

    this.giftCardColumns = [
      new TemplateColumn({ HeaderText: 'Gift Card Number', Field: 'CardNumber', itemTemplate: userHasPermission ? this.giftCardNumberEditTemplate : this.giftCardNumberTemplate, IsSortable: true, TextAlign: TextAlign.Left, Width: '200px' }),
      new TemplateColumn({ HeaderText: 'Current Balance', Field: 'CurrentBalance', itemTemplate: this.priceTemplate, IsSortable: true, TextAlign: TextAlign.Right, Width: "100px" }),
      new DateColumn({ HeaderText: 'Last Used', Field: 'LastUsed', IsSortable: true, TextAlign: TextAlign.Left, Width: '200px' }),
      new DateColumn({ HeaderText: 'Date Added', Field: 'DateAdded', IsSortable: true, TextAlign: TextAlign.Left, Width: '200px' }),
    ];
  }

  loadData() {
    this.spinnerService.show();
    this.giftCardService.getAllGiftCards()
    .pipe(finalize(() => this.spinnerService.hide()))
    .subscribe({
      next: (res) => {
        this.giftCardsList = sortBy(res, (item) => {
          return item.LastUsed === null ? Infinity : -new Date(item.LastUsed);
        });
        this.giftCardsList.forEach(x => {
          if (x.DateAdded) {
            x.DateAdded = moment.parseZone(x.DateAdded).format('YYYY-MM-DDTHH:mm:ss[Z]')
          }
          if (x.LastUsed) {
            x.LastUsed = moment.parseZone(x.LastUsed).format('YYYY-MM-DDTHH:mm:ss[Z]')
          }
        });
        this.filterGiftCards();
      },
      error: this.alertService.showApiError,
    })
  }

  filterGiftCards() {
    this.gridContext.PageNumber = 1;
    if (this.giftCardsList?.length && this.isActive)
      this.filteredGiftCardsList = [ ...this.giftCardsList.filter(x => x.CurrentBalance > 0) ];
    else
      this.filteredGiftCardsList = [ ...this.giftCardsList ];
  }

  onCancel() {
    this.router.navigate(['/manage/console']);
  }

  openGiftCardDialog(cardNumber: any) {
    const modal = this.modalService.show(GiftCardComponent, {
      keyboard: false,
      animated: false,
      class: 'vertical-center modal-max-width-98',
      initialState: {
        giftCardNumber: cardNumber
      }
    });
    modal.close.subscribe(() => {
      this.loadData();
    })
  }
}
