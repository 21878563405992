<pos-list-wrapper [headingIcon]="icons.salesProductsFileInvoiceDollar" heading="Sales Products" (close)="close()">
  <div class="row ">
    <div class="col-12 text-right">
      <div class="inline-block ps-pix-10 float-right pt-pix-7">
        <p-checkbox (onChange)="getProductDetailsWithFilter()" name="Active" #Active="ngModel"
          [(ngModel)]="isActive" binary="true" label="Active">
        </p-checkbox>
      </div>
      <div class="inline-block ps-pix-10 float-right col-200">
        <ng-select posInput [items]="salesCategories" bindLabel="name" bindValue="id" placeholder="Category"
          [(ngModel)]="salesProductCategoryId" appendTo="body" name="Category"
          (change)="getProductDetailsWithFilter()">
        </ng-select>
      </div>
    </div>
  </div>
  <div class="overflow-x-visible sales_product">
    <div class="bulk-update-button">
      <button class="btn btn-primary normal pos-button white" (click)="updateBulkProducts()"
        [disabled]="!salesProductsForBulkUpdateIds.length">
        Bulk update {{salesProductsForBulkUpdateIds.length}} product(s)
      </button>
    </div>
    <ng-table #salesProductList [columns]="salesProductColumns" [exportFilename]="'sales-products'"
      [data]="salesProducts" (onFilter)="onTableFiltered($event)" [customFilterColumns]="filterColumns">
    </ng-table>
  </div>
</pos-list-wrapper>

<ng-template #popOverForSalesProductName let-data="rowData">
  <span #pop="bs-popover" class="grey popover-zone pointerStyle pointer" placement="right"
    (click)="openSalesProductShowRecipe(data)" [outsideClick]="true" [popover]="salesProductNamePopoverTemplate"
    triggers="click" #popoverTooltip><span tooltip="Click to show ingredients"
      placement="top">{{data.Name}}</span></span>
</ng-template>

<ng-template let-data="rowData" #bulkUpdateHeaderTemplate>
  <p-checkbox name="bulkUpdateAll" (onChange)="addAllProductsForBulkUpdate()"
    [(ngModel)]="isBulkUpdateAllProducts" binary="true">
  </p-checkbox>
</ng-template>

<ng-template let-data="rowData" #bulkUpdateCheckboxTemplate>
  <p-checkbox name="bulkUpdate" #bulkUpdate="ngModel" (onChange)="addProductForBulkUpdate(data)"
    [(ngModel)]="data.IsAddToBulkUpdate" binary="true">
  </p-checkbox>
</ng-template>

<ng-template let-data="rowData" #taxableTemplate>
  <span *ngIf="(data.Taxable)" class="green text-center vertical-mid font-13">
    <pos-icon [icon]="icons.check"></pos-icon>
  </span>
</ng-template>

<ng-template let-data="rowData" #activeTemplate>
  <span *ngIf="(data.Active)" class="green text-center vertical-mid font-13">
    <pos-icon [icon]="icons.check"></pos-icon>
  </span>
</ng-template>

<ng-template let-data="rowData" #operationHeaderTemplate>
  <div class="text-center">
    <span *ngIf="editProductPermission" class="pe-pix-5 d-inline-block" (click)="addSaleProduct(0)" tooltip title="Add">
      <pos-action-icon [icon]="icons.plusWhite" iconText="Add"></pos-action-icon>
    </span>
    <pos-export-dropdown *ngIf="editProductPermission || readOnlyProductPermission" class="d-inline-block" (onPrint)="printDiv()" (onCSV)="exportCSV()">
    </pos-export-dropdown>
  </div>
</ng-template>
<ng-template let-data="rowData" #operationTemplate>
  <span *ngIf="editProductPermission" class="pe-pix-3" (click)="editSalesProduct(data)" tooltip title="Edit" scrollup>
    <pos-action-icon [icon]="icons.editWhite"></pos-action-icon>
  </span>
  <span *ngIf="editProductPermission || readOnlyProductPermission" class="pe-pix-3" (click)="openDashboard(data)">
    <a class="action-btn grey pointer">
      <pos-action-icon [icon]="icons.dashboardList"></pos-action-icon>
    </a>
  </span>
  <span *ngIf="editProductPermission">
    <a class="action-btn grey pointer" #pop="bs-popover" placement="left" (click)="openSalesProductPopover(data, pop)"
      [outsideClick]="true" tooltip-append-to-body="true" [popover]="salesProductPopoverTemplate" triggers="click">
      <pos-action-icon [icon]="icons.barsWhite"></pos-action-icon>
    </a>
  </span>
</ng-template>



<ng-template let-data="rowData" #salesProductPopoverTemplate>
  <div class="col-170">
    <ul>
      <li class="listbullet pointer">
        <a class="grey ps-pix-2" (click)="openDefaultButtonModal()">
          <pos-icon [icon]="icons.home" class="pe-pix-3">
          </pos-icon>
          Default Buttons
        </a>
      </li>
      <li class="listbullet pointer">
        <a class="grey ps-pix-4" (click)="openProductIngredientModal()">
          <pos-icon [icon]="icons.salesProductRecipe" class="pe-pix-10">
          </pos-icon>Recipe Card
        </a>
      </li>
      <li class="listbullet pointer">
        <a class="grey ps-pix-3" (click)="openListAllButtonModal(data)">
          <pos-icon [icon]="icons.list" class="pe-pix-7">
          </pos-icon>Mapped Buttons
        </a>
      </li>
      <li class="listbullet pointer">
        <a class="grey ps-pix-4" (click)="openProductQuickReportModal(data)">
          <pos-icon [icon]="icons.fileAlt" class="pe-pix-10">
          </pos-icon>Quick Report
        </a>
      </li>
      <li class="listbullet pointer">
        <!-- *ngIf="data.id > 0" -->
        <a class="grey ps-pix-4" (click)="openProductInstructionsModal(data)">
          <pos-icon [icon]="icons.listAlt" class="pe-pix-7">
          </pos-icon>Product Instructions
        </a>
      </li>

      <li class="listbullet pointer">
        <!-- *ngIf="data.id > 0" -->
        <a class="grey ps-pix-4" (click)="openProductCopyModal(data)">
          <pos-icon [icon]="icons.clone" class="pe-pix-7">
          </pos-icon>Copy Product
        </a>
      </li>
      <li class="listbullet pointer">
        <a class="grey ps-pix-4" (click)="openMergeProductModal(data)">
          <pos-icon [icon]="icons.codeBranch" class="pe-pix-10">
          </pos-icon>Merge Product
        </a>
      </li>
      <li class="listbullet pointer">
        <a class="grey ps-pix-4" (click)="onSalesProductHistory()">
          <pos-icon [icon]="icons.history" class="pe-pix-7">
          </pos-icon>History
        </a>
      </li>
      <li class="listbullet pointer">
        <a class="grey ps-pix-7" (click)="onSalesProductSizeHistory()" title="Price History">
          <pos-icon [icon]="icons.dollarSign" class="pe-pix-10">
          </pos-icon>Price History
        </a>
      </li>
      <li class="listbullet pointer">
        <a class="grey ps-pix-4" (click)="refreshSalesProductAvailability()">
          <pos-icon [icon]="icons.salesRefreshAvailability" class="pe-pix-9">
          </pos-icon>Sync Availability
        </a>
      </li>
    </ul>
  </div>
</ng-template>

<ng-template #salesProductNamePopoverTemplate>
  <pos-sales-product-show-recipe [salesProductId]="salesProductShowRecipeId"></pos-sales-product-show-recipe>
</ng-template>
<pos-print-table [hidden]="true"></pos-print-table>
