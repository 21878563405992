<div class="row numpad-modal gx-1">
  <div *ngIf="options.additionalInformationText" [innerHtml]="options.additionalInformationText"></div>
  <div class="col-12 pt-pix-15 px-0">
    <input *ngIf="!options.mask" id="txtNumpad_{{numpadId}}" type="text" [(ngModel)]="options.prefix + formattedValue"
      class="numpad-modal__input form-control numpad__input" [disabled]="options.isReadOnly" />
    <input *ngIf="options.mask" id="txtNumpad_{{numpadId}}" type="text" [(ngModel)]="options.prefix + formattedValue"
      [mask]="options.mask" [showMaskTyped]="true"
      class="numpad-modal__input form-control numpad__input font-desktop-large" />
    <span class="text-danger" *ngFor="let error of validationErrors.value" [innerHtml]="error"></span>
  </div>
  <div *ngIf="options.additionalInfoFooterText" [innerHtml]="options.additionalInfoFooterText"></div>
  <div class="col-12 pt-pix-10 px-0 row mx-0">
    <div class="col-3 ps-0">
      <div class="btn-cal" [ngClass]="{'btn-disabled': options.disable}" (click)="NumpadKeyClicked(1)">1</div>
    </div>
    <div class="col-3 ps-0">
      <div class="btn-cal" [ngClass]="{'btn-disabled': options.disable}" (click)="NumpadKeyClicked(2)">2</div>
    </div>
    <div class="col-3 ps-0">
      <div class="btn-cal" [ngClass]="{'btn-disabled': options.disable}" (click)="NumpadKeyClicked(3)">3</div>
    </div>
    <div class="col-3 px-0">
      <div class="btn-cal" [ngClass]="{'btn-disabled': options.disableCancel}" (click)="CancelClicked()">
        {{options.cancelButtonText ? options.cancelButtonText : 'Cancel'}}
      </div>
    </div>
  </div>
  <div class="col-12 pt-pix-10 px-0 row mx-0">
    <div class="col-3 ps-0">
      <div class="btn-cal" [ngClass]="{'btn-disabled': options.disable}" (click)="NumpadKeyClicked(4)">4</div>
    </div>
    <div class="col-3 ps-0">
      <div class="btn-cal" [ngClass]="{'btn-disabled': options.disable}" (click)="NumpadKeyClicked(5)">5</div>
    </div>
    <div class="col-3 ps-0">
      <div class="btn-cal" [ngClass]="{'btn-disabled': options.disable}" (click)="NumpadKeyClicked(6)">6</div>
    </div>
    <div class="col-3 px-0">
      <div class="btn-cal" [ngClass]="{'btn-disabled': options.disable}" (click)="NumpadKeyClicked('delete')">Delete
      </div>
    </div>
  </div>
  <div class="px-0" [ngClass]="{'col-9 row mx-0':!options.enterButton}">
    <div class="col-12 pt-pix-10 px-0 row mx-0">
      <div class="ps-0" [ngClass]="{'col-3':options.enterButton, 'col-4':!options.enterButton}">
        <div class="btn-cal" [ngClass]="{'btn-disabled': options.disable}" (click)="NumpadKeyClicked(7)">7</div>
      </div>
      <div class="ps-0" [ngClass]="{'col-3':options.enterButton, 'col-4':!options.enterButton}">
        <div class="btn-cal" [ngClass]="{'btn-disabled': options.disable}" (click)="NumpadKeyClicked(8)">8</div>
      </div>
      <div class="ps-0" [ngClass]="{'col-3':options.enterButton, 'col-4':!options.enterButton}">
        <div class="btn-cal" [ngClass]="{'btn-disabled': options.disable}" (click)="NumpadKeyClicked(9)">9</div>
      </div>
      <div class="col-3 px-0 pt-0" *ngIf="options.enterButton">
        <div class="btn-cal btn-cal-clear"
          [ngClass]="{'btn-disabled': options.disable}" (click)="clearClicked(0)">
          Clear
        </div>
      </div>
    </div>
    <div class="col-12 pt-pix-10 px-0 row mx-0">
      <div *ngIf="options.doubleZero" class="ps-0" [ngClass]="{'col-3':options.enterButton, 'col-4':!options.enterButton}">
        <div class="btn-cal" [ngClass]="{'btn-disabled': options.disable}" (click)="NumpadKeyClicked(100);">00</div>
      </div>
      <div class="ps-0" [ngClass]="{'col-3':options.doubleZero && options.enterButton, 'col-4':options.doubleZero && !options.enterButton, 
      'col-6':!options.doubleZero && options.enterButton, 'col-8':!options.doubleZero && !options.enterButton}">
        <div class="btn-cal" (click)="NumpadKeyClicked(10)" [ngClass]="{'btn-disabled':options.disable}">0</div>
      </div>
      <div class="ps-0" *ngIf="options.allowDecimal"
        [ngClass]="{'col-3':options.enterButton, 'col-4':!options.enterButton}">
        <div class="btn-cal" (click)="NumpadKeyClicked('.')" [ngClass]="{'btn-disabled':options.disable}">.</div>
      </div>
      <div class="px-0" [ngClass]="{'col-3':options.allowDecimal,'col-6':!options.allowDecimal}"
        *ngIf="options.enterButton">
        <div class="btn-cal btn-cal-green" (click)="EnterClicked()"
          [ngStyle]="{'background-color': options.enterButtonColor}"
          [ngClass]="{'btn-disabled':options.disable || options.enterDisable}">
          {{options.enterButtonText ? options.enterButtonText : 'Enter'}}</div>
      </div>
    </div>
  </div>
  <div class="col-3 pt-pix-10 px-0" *ngIf="!options.enterButton">
    <div class="btn-cal  btn-cal-clear" [ngClass]="{'btn-disabled': options.disable}"
      (click)="clearClicked(0)">Clear
    </div>
  </div>
</div>
