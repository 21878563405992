import { Component, ElementRef, EventEmitter, HostListener, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ApplicationStateService } from '../../shared/services/application-state.service';
import { MakeTableOrderItem, MakeTableOrderModel } from '../interfaces/make-table-order';
import {
  makeTableCollapseCaretRight, makeTableCollapseCaretDown, exclamationTriangle, clock, star, checkCircleMakeTable,
  rocket, magic, cogs, times, check, plusMessage, cog, makeTableNotified, tasks
} from 'src/app/shared/components/icon';
import { MakeTableColorConfig } from '../interfaces/make-table-color-config';
import { some, findIndex, forEach, find, remove, groupBy, filter, debounce, map, sum, sortBy } from 'lodash';
import { MakeTableCookingPanelProduct } from '../interfaces/make-table-cooking-panel-product';
import { from, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { MakeTableUIState } from '../interfaces/make-table-ui-state';
import {
  AlertsService, CameraAnnotationService, ColorUtilityService, EventBroadcastingService,
  LoggerService,
  OrderCardService,
  OrderService, RabbitMQService, StringUtilityService,
  UserService
} from 'src/app/shared/services';
import { DomainConstants, Messages } from 'src/app/shared/constants';
import { CameraAnnotationObject, SettingParam } from 'src/app/shared/interface';
import { ModalService, SpinnerService } from 'src/app/shared/components';
import { SalesProductsService } from 'src/app/information-management/sales-products/services/sales-products.service';
import { SalesSize } from 'src/app/information-management/sales-sizes/interface/sales-size';
import { TerminalProperty } from 'src/app/configurator/terminals/interface/terminal-property';
import { TerminalsService } from 'src/app/configurator/terminals/services/terminals.service';
import { TerminalDetails } from 'src/app/orders/interface/terminal-details';
import { MakeTableService } from '../services/make-table.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FilteredProducts } from '../filtered-product';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectUserComponent } from './select-user/select-user.component';
import { User } from 'src/app/information-management';
import { UserEntity } from 'src/app/information-management/users/interface/user-entity';
declare let $: any;
@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'pos-make-table',
  templateUrl: './make-table.component.html',
  styleUrls: ['./make-table.component.scss']
})
export class MakeTableComponent implements OnInit, OnDestroy {
  headerLinks = {
    changeTerminal: true,
    mappedTerminals: true,
    time: false,
    signIn: false,
    home: true,
    admin: false,
    activeOrders: true,
    tasks: this.applicationStateService.applicationInitializationConfigurations.HasTaskSetup,
    filterProducts: true
  };
  homeState = 'make-table';
  makeTableColorConfig: MakeTableColorConfig = {};
  makeTableRabbitMQSubscriptions: Array<Subscription> = [];
  updatedOrderItems: Array<number> = [];
  isMobileMode: boolean;
  currentDate: Date;
  productSizes: Array<SalesSize> = [];
  makeTableCookingPanelProducts: Array<MakeTableCookingPanelProduct> = [];
  makeTableCookingProductsByGroup: any = [];
  isOpenCookingPanel: boolean;
  isStarColor = false;
  isReadOnlyMakeTable: boolean = false;
  isAllowOrderingFractionalQty: boolean;
  makeTableView = DomainConstants.MaketableView;
  isHiddenScrollTop: boolean;
  isHiddenScrollBottom: boolean;
  settingParam: SettingParam;
  productLabelPrinting: number;
  trimColor: string;
  notifyButtonColor: string;
  serveButtonColor: string;
  notifyButtonFontColor: string;
  serveButtonFontColor: string;
  toggleItemInProgress: boolean;
  makeTableLayoutViews = DomainConstants.MaketableView;
  cameraAnnotations: CameraAnnotationObject;
  @ViewChild('cookingPanel') cookingPanel: ElementRef;
  // rabbitMq subscriptions
  rabbitMqOrderServedSubscription: Subscription;
  rabbitMqOrderItemDeleteSubscription: Subscription;
  rabbitMqTimerTerminalBroadcastSubscription: Subscription;
  rabbitMqProductLabelPrintSubscription: Subscription;
  isFilteredProductsApplied: boolean;
  users: Array<UserEntity> = [];
  filteredProductData: FilteredProducts;
  isShowFilterScreen: boolean;
  resolvePromptPromise: (value?: number | PromiseLike<number>) => void;
  rejectPromptPromise: (reason?: any) => void;

  makeTableWorkflowStates = {
    Pending: false,
    Cooking: false,
    RetrievingIngredients: false,
    Crafting: false,
    Ready: false,
    Done: false,
    PartiallyInProgress: false,
  };
  currentTime = new Date().getTime();
  // Keep track of scale value
  scaling = { 'currentScale': 1 };
  scaleClass: string;
  public icons = {
    makeTableCollapseCaretRight, makeTableCollapseCaretDown, exclamationTriangle, checkCircleMakeTable, rocket,
    cogs, magic, clock, times, check, star, plusMessage, cog, makeTableNotified, tasks
  };
  makeTableOrders: Array<MakeTableOrderModel> = [];
  makeTableUIStates: Array<MakeTableUIState> = [];
  makeTableStates = DomainConstants.MakeTableStates;
  currentLayout = DomainConstants.MaketableView.Grid;
  noOfInflightRequests = 0;
  makeTableLayout: TerminalProperty;
  private _noOfColumns: number;
  columns: Array<number>;
  filteredProductsSubscription: Subscription;
  rabbitMqMakeTableExchangeSubscription: Subscription;
  rabbitMqClockedInEventSubscription: Subscription;
  rabbitMqClockedOutEventSubscription: Subscription;
  IsShowCookingPanel: boolean = false;

  setMakeTableConfigColor() {
    this.makeTableColorConfig.MakeTableProcessColor = this.settingParam.MakeTableProcessColor;
    this.makeTableColorConfig.MakeTableCompleteColor = this.settingParam.MakeTableCompleteColor;
    this.makeTableColorConfig.MakeTableReadyColor = this.settingParam.MakeTableReadyColor;
    this.makeTableColorConfig.MakeTableAccountColor = this.settingParam.MakeTableAccountColor;
    this.makeTableColorConfig.MakeTableCallInColor = this.settingParam.MakeTableCallInColor;
    this.makeTableColorConfig.MakeTablePagerColor = this.settingParam.MakeTablePagerColor;
    this.makeTableColorConfig.MakeTablePartiallyInProgressColor = this.settingParam.MakeTablePartiallyInProgressColor;
    this.trimColor = this.applicationStateService.applicationInitializationConfigurations.ColorPreference.TrimColor;
    this.notifyButtonColor = this.settingParam.MakeTableNotifyColor ? this.settingParam.MakeTableNotifyColor : 'Green';
    this.serveButtonColor = this.settingParam.MakeTableServeColor ? this.settingParam.MakeTableServeColor : 'linear-gradient(to bottom,#d9534f 0,#c12e2a 100%)';
    this.notifyButtonFontColor = this.settingParam.MakeTableNotifyFontColor ? this.settingParam.MakeTableNotifyFontColor :
      this.colorUtilityService.getContrastColor(this.notifyButtonColor);
    this.serveButtonFontColor = this.settingParam.MakeTableServeFontColor ? this.settingParam.MakeTableServeFontColor :
      this.colorUtilityService.getContrastColor(this.serveButtonColor);
  }

  constructor(private applicationStateService: ApplicationStateService,
    private orderService: OrderService,
    private alertService: AlertsService,
    private salesProductsService: SalesProductsService,
    private colorUtilityService: ColorUtilityService,
    private rabbitMQService: RabbitMQService,
    private spinnerService: SpinnerService,
    private terminalService: TerminalsService,
    private orderCardService: OrderCardService,
    private eventBroadcastingService: EventBroadcastingService,
    private makeTableService: MakeTableService,
    private deviceService: DeviceDetectorService,
    private route: ActivatedRoute,
    private userService: UserService,
    private cameraAnnotationService: CameraAnnotationService,
    private stringUtilityService: StringUtilityService,
    private loggerService: LoggerService,
    private modalService: ModalService,
    private router: Router) {
    this.settingParam = this.applicationStateService.settingParam;
    this.cameraAnnotations = this.applicationStateService.cameraAnnotations;
  }

  ngOnInit() {
    this.headerLinks.tasks = this.applicationStateService.applicationInitializationConfigurations.HasTaskSetup;
    this.isAllowOrderingFractionalQty = this.settingParam.AllowOrderingFractionalQty;
    this.IsShowCookingPanel = this.settingParam.IsShowCookingPanel;
    this.setMakeTableConfigColor();
    this.setStarColor();
    this.setMakeTableGlobalConfiguration(this.settingParam.MakeTableWorkflowStates);
    this.setOrderTimerInterval();
    this.getMappedOrderEntryTerminals();
    this.getSizes();
    this.setNoOfColumns();
    this.getTerminalProperty(true);
    setTimeout(() => {
      this.setScrollButton();
      if (this.route.snapshot.firstChild?.routeConfig?.path == 'filter' && this.deviceService.isMobile()) {
        this.isShowFilterScreen = true;
      } else {
        this.isShowFilterScreen = false;
      }
    });
    this.subscribeRabbitMq();
    this.autoRefreshMakeTableOrders();
    if (this.settingParam.PromptToChooseUsernameOnMakeTable) {
      this.getClockedInUsers();
    }
    this.productLabelPrinting = this.settingParam.ProductLabelPrinting;
    $(window).scroll(() => { this.setScrollButton(); });
    this.isMobileMode = this.deviceService.isMobile();
    if (!this.isMobileMode) {
      this.resizeWindow();
    }
    this.subscribeProductsFiltered();
    this.headerLinks = {
      changeTerminal: !this.isMobileMode,
      mappedTerminals: !this.isMobileMode,
      time: false,
      signIn: false,
      home: !this.isMobileMode,
      admin: false,
      tasks: this.isMobileMode ? false : this.applicationStateService.applicationInitializationConfigurations.HasTaskSetup,
      filterProducts: true,
      activeOrders: !this.isMobileMode
    };
  }

  filterProducts() {
    if (this.applicationStateService.isFirstTimeAfterLogin) {
      if (this.isMobileMode) {
        this.filterProduct();
      }
      this.applicationStateService.isFirstTimeAfterLogin = false;
    }
  }
  productsFiltered(event) {
    this.router.navigate(['make-table']);
    this.isShowFilterScreen = false;
    this.getTerminalProperty();
  }

  filterProduct() {
    this.router.navigate(['make-table/filter']);
    this.isShowFilterScreen = true;
  }

  subscribeProductsFiltered() {
    this.filteredProductsSubscription = this.eventBroadcastingService.productsFiltered.subscribe(() => {
      this.getTerminalProperty();
      $('.warning-popover').popover('hide');
    });
  }

  @HostListener('window:orientationchange', ['$event'])
  onOrientationChange = (event) => {
    const timeout = setTimeout(() => {
      this.setNoOfColumns();
      clearInterval(timeout);
    }, 300);
  }

  resizeWindow = () => {
    $(window).resize(() => {
      this.setScrollButton();
      $('#cookingButton').css('right', (this.cookingPanel.nativeElement.clientWidth + 2));
    });
  }

  getClockedInUsers(showSpinner: boolean = true) {
    if (showSpinner) {
      this.spinnerService.show();
    }
    this.userService.getAllClockedInUsers()
      .pipe(finalize(() => {
        if (showSpinner) {
           this.spinnerService.hide();
        }
      }))
      .subscribe({
        next: (users: Array<UserEntity>) => {
          this.users = users;
        }, error: this.alertService.showApiError
      });
  }

  autoRefreshMakeTableOrders() {
    setInterval(() => {
      // Don't refresh if there is any update state request is in progress.
      // As, otherwise, it will dirty read older status and will refresh screen incorrectly
      if (!this.toggleItemInProgress && this.noOfInflightRequests <= 0) {
        this.getMakeTableOrders(true);
      }
    }, 60000);
  }

  subscribeRabbitMq() {
    this.subscribeToTerminalBroadcastQueue();
    this.subscribeToTimerTerminalBroadcastExchange();
    this.subscribeToProductLabelPrintingParameterExchange();
    this.subscribeToScheduleOrderExchange();
    if (this.settingParam.PromptToChooseUsernameOnMakeTable) {
      this.subscribeToClockedInEventExchange();
    }
  }
  ngOnDestroy() {
    if (this.filteredProductsSubscription) {
      this.filteredProductsSubscription.unsubscribe();
    }
  }

  subscribeToProductLabelPrintingParameterExchange() {
    this.rabbitMqProductLabelPrintSubscription = this.rabbitMQService.subscribeToProductLabelPrintingParameterMessage$()
      .subscribe((message: any) => {
        if (message.Payload) {
          this.productLabelPrinting = message.Payload.Message ? parseInt(message.Payload.Message) : 0;
        }
      });
  }

  subscribeToClockedInEventExchange() {
    this.rabbitMqClockedInEventSubscription = this.rabbitMQService.subscribeToUserClockInMessage$()
      .subscribe((message: any) => {
        this.getClockedInUsers(false);
      });
    this.rabbitMqClockedOutEventSubscription = this.rabbitMQService.subscribeToUserClockOutMessage$()
      .subscribe((message: any) => {
        this.getClockedInUsers(false);
      });
  }
  subscribeToTerminalBroadcastQueue() {
    this.rabbitMqOrderServedSubscription = this.rabbitMQService.subscribeToOrderServedMessage$()
      .subscribe((message: any) => {
        const deletedOrderId = message.Payload.OrderId;
        forEach(this.makeTableOrders, (order) => {
          if (order.Id === deletedOrderId) {
            forEach(order.OrderItems, (product) => {
              if (product && product.Id) {
                this.hidePopover(product.Id);
              }
            });
          }
        });
        this.makeTableOrders = filter(this.makeTableOrders, (order) => {
          return order.Id !== deletedOrderId;
        });
        this.setOrderProperties();
      });
    this.rabbitMqOrderItemDeleteSubscription = this.rabbitMQService.subscribeToOrderItemDeletedMessage$()
      .subscribe((message: any) => {
        const orderItemId = message.Payload.OrderId;
        if (orderItemId != null) {
          forEach(this.makeTableOrders, (order) => {
            order.OrderItems = filter(order.OrderItems, (product) => {
              if (product && product.Id) {
                this.hidePopover(product.Id);
              }
              return product.Id !== orderItemId;
            });
          });
        }
        this.setOrderProperties();
      });
  }


  subscribeToTimerTerminalBroadcastExchange() {
    this.rabbitMqTimerTerminalBroadcastSubscription = this.rabbitMQService.subscribeToTimerTerminalBroadcastExchange$()
      .subscribe((message: any) => {
        if (message.Source.Id !== this.applicationStateService.terminalId) {
          /**
             * Added this condition to handle following case:
             * When click on order update strip state is changed to pending from current state then need to change timer state of the items
                 */
          const orderDetails = message.Payload.OrderDetails;
          let orderIndex = null;
          orderIndex = findIndex(this.makeTableOrders, (order) => {
            return order.Id === orderDetails.Id;
          });
          if (orderIndex >= 0) {
            forEach(this.makeTableOrders[orderIndex].OrderItems, (item) => {
              const orderProduct = find(orderDetails.OrderItems, (orderItem) => {
                return orderItem.Id === item.Id;
              });
              if (orderProduct) {
                forEach(item.OrderProductComponents, (component) => {
                  const orderProductComponent = find(orderProduct.OrderProductComponents, (orderComponent) => {
                    return orderComponent.Id === component.Id;
                  });
                  component.TimerState = orderProductComponent.TimerState;
                });
              }
            });
          }

        }
      });
  }

  subscribeToScheduleOrderExchange() {
    this.rabbitMqMakeTableExchangeSubscription = this.rabbitMQService.subscribeToUpdateScheduleOrderMessage$()
      .subscribe(() => {
        this.getMakeTableOrders(true);
      });
  }

  serveThisOrder(orderId) {
    if (!this.isReadOnlyMakeTable && orderId) {
      this.cameraAnnotationService.addAnnotationToCamera(this.cameraAnnotations.MakeTableOrderServe, {
        OrderId: this.makeTableUIStates[orderId]?.SurrogateOrderId
      });
      this.noOfInflightRequests++;
      this.orderService.serveOrder(orderId, this.applicationStateService.userDetails.id)
        .subscribe({
          next: () => {
            this.noOfInflightRequests--;
          }, error: (err) => {
            this.noOfInflightRequests--;
            this.alertService.showApiError(err);
          }
        });
      remove(this.makeTableOrders, (order) => {
        return order.Id === orderId && this.makeTableUIStates[order.Id].IsReadyToServe;
      });
    }
  }

  checkPickupTime(pickupTime) {
    if (pickupTime) {
      if (this.settingParam.AutoExpandMinutesOnlineOrder > 0) {
        const date = new Date(pickupTime);
        const pickupDateTime = new Date(date.getTime() - this.settingParam.AutoExpandMinutesOnlineOrder * 60000);
        return (Date.parse(pickupDateTime.toString()) > Date.now());
      } else {
        return true;
      }
    }
    return null;
  }

  prepareOrderProductForLabelPrint(orderProduct: MakeTableOrderItem, order: MakeTableOrderModel) {
    const components = [];
    forEach(orderProduct.OrderProductComponents, (component) => {
      if (orderProduct.SalesProductId !== component.SalesProductId) {
        components.push({ ComponentName: component.MakeTableText, ReceiptText: component.ReceiptText });
      }
    });
    const subaccountName = find(order.OrderSubaccounts, (subaccount) => subaccount.SubaccountOrdinal == orderProduct.SubAccountOrdinal)?.SubaccountName;
    return {
      IsManuallyPrint: false,
      IsFromMakeTable: true,
      OrderId: order.Id,
      SurrogateOrderId: order.SurrogateOrderId,
      ScreenName: orderProduct.MakeTableText,
      ReceiptText: orderProduct.ReceiptText,
      SalesProductID: orderProduct.SalesProductId,
      IsSentToKitchen: orderProduct.IsSentToKitchen,
      IsDirty: orderProduct.IsDirty,
      Qty: orderProduct.Qty,
      Items: components,
      OrderName: (subaccountName ? subaccountName : order.OrderName)
    };
  }


  setScrollButton() {
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
      this.isHiddenScrollTop = false;
    } else {
      this.isHiddenScrollTop = true;
    }
    $.fn.scrollBottom = function () {
      return $(document).height() - this.scrollTop() - this.height();
    };
    const bottomScroll = $(window).scrollBottom();
    if (bottomScroll > 20) {
      this.isHiddenScrollBottom = false;
    } else {
      this.isHiddenScrollBottom = true;
    }
  }

  get noOfColumns(): number {
    return this._noOfColumns;
  }

  set noOfColumns(value: number) {
    this._noOfColumns = value;
    this.columns = Array(this._noOfColumns).fill(1).map((x, i) => i + 1);
  }


  setNoOfColumns(value?: number) {
    if (value) {
      this.noOfColumns = value;
    } else {
      if ($(window).width() > 1199) {
        this.noOfColumns = 5;
      } else if ($(window).width() > 650) {
        this.noOfColumns = 2;
      } else {
        this.noOfColumns = 1;
      }
    }
  }

  getDataForColumn(column: number) {
    let pickIndex = column - 1;
    const columnData = [];
    while (pickIndex < this.makeTableOrders.length) {
      columnData.push(this.makeTableOrders[pickIndex]);

      pickIndex += this.noOfColumns;
    }

    return columnData;
  }

  setOrderTimerInterval() {
    const timerRefreshInterval = 5;
    setInterval((e) => {
      this.updateMakeTableUIStateTime();
    }, timerRefreshInterval * 1000);
  }

  updateMakeTableUIStateTime() {
    if (this.makeTableOrders) {
      forEach(this.makeTableOrders, (order) => {
        this.makeTableUIStates[order.Id].FormattedTime = this.calculateOrderTime(order);
      });
    }
    this.currentTime = new Date().getTime();
  }

  calculateOrderTime(order) {
    return this.orderCardService.calculateOrderTime(order.MakeTableDateTime);
  }

  getSizes() {
    this.salesProductsService.getSalesSizes(0)
      .subscribe((sizes: Array<SalesSize>) => {
        this.productSizes = sizes;
      });
  }

  getMappedOrderEntryTerminals() {
    this.orderService.getMappedTerminalsByType(this.applicationStateService.terminalId, (DomainConstants.TerminalTypes.ORDER_ENTRY.Name + ',' + DomainConstants.TerminalTypes.ORDER_KIOSK.Name))
      .subscribe({
        next: (response: Array<TerminalDetails>) => {
          this.mappedOrderEntryTerminalsSubscription(response);
        }, error: this.alertService.showApiError
      });
  }

  rabbitMQProductStateChange(message) {
    forEach(this.makeTableOrders, (order) => {
      if (order.Id === message.Payload.OrderDetails.Id) {
        forEach(order.OrderItems, (item) => {
          if (item.Id === message.Payload.OrderDetails.OrderItems[0]?.Id) {
            item.MakeTableState = message.Payload.OrderDetails.OrderItems[0]?.MakeTableState;
            item.MakeTableBgColor = this.orderCardService.getItemMakeTableColor(item, this.makeTableColorConfig);
            item.FontColor = this.colorUtilityService.getContrastColor(item.MakeTableBgColor);
            item.OrderUpdate = false;
            item.MakeTableStateByUserFirstName = message.Payload.OrderDetails.OrderItems[0]?.MakeTableStateByUserFirstName;
            item.MakeTableStateByUserLastName = message.Payload.OrderDetails.OrderItems[0]?.MakeTableStateByUserLastName;
            item.MakeTableStateByUserRole = message.Payload.OrderDetails.OrderItems[0]?.MakeTableStateByUserRole;
            item.IsMakeTableStateChangeByGenericUser = this.checkIsMakeTableStateChangeByGenericUser(item);
            this.addOrRemoveUpdatedOrder(message.Payload.OrderDetails.OrderItems[0]?.Id, false);
            if (item.MakeTableState === DomainConstants.MakeTableStates.PENDING) {
              this.makeTableUIStates[order.Id].IsReadyToServe = false;
              this.makeTableUIStates[order.Id].IsShowNotifyButton = false;
            } else {
              this.setOrderProperties();
            }
          }
        });
      }

    });
  }

  rabbitMQDeleteOrder(message) {
    forEach(this.makeTableOrders, (order) => {
      if (order) {
        if (order.Id === message.Payload.OrderId) {
          forEach(order.OrderItems, (product) => {
            if (product && product.Id) {
              this.hidePopover(product.Id);
            }
          });
        }
      }
    });
    this.makeTableOrders = filter(this.makeTableOrders, (order) => {
      return order.Id !== message.Payload.OrderId;
    });
  }

  mappedOrderEntryTerminalsSubscription = (mappedOrderEntryTerminals: Array<TerminalDetails>) => {
    if (mappedOrderEntryTerminals && mappedOrderEntryTerminals.length > 0) {
      forEach(mappedOrderEntryTerminals, (mappedOrderEntryTerminal) => {
        this.rabbitMQService.subscribeToOrderDeletedMessage$(mappedOrderEntryTerminal.id)
          .pipe(untilDestroyed(this))
          .subscribe((message) => {
            if (message.Source.Id !== this.applicationStateService.terminalId) {
              this.rabbitMQDeleteOrder(message);
            }
          });

        this.rabbitMQService.subscribeToOrderItemStateChangedMessage$(mappedOrderEntryTerminal.id)
          .pipe(untilDestroyed(this))
          .subscribe((message) => {
            if (message.Source.Id !== this.applicationStateService.terminalId) {
              this.rabbitMQProductStateChange(message);
            }
          });

        this.rabbitMQService.subscribeToOrderUpdatedMessage$(mappedOrderEntryTerminal.id)
          .pipe(untilDestroyed(this))
          .subscribe((message) => {
            if (message.Source.Id !== this.applicationStateService.terminalId) {
              this.rabbitMqOrderUpdate(message);
            }
          });
      });
    }
  }
  rabbitMqOrderUpdate = (message) => {
    let orderIndex = null;
    orderIndex = findIndex(this.makeTableOrders, (order) => {
      return order.Id === message.Payload.OrderDetails.Id;
    });
    if (orderIndex >= 0) {
      forEach(this.makeTableOrders[orderIndex]?.OrderItems, (x) => {
        this.hidePopover(x.Id);
      })
      this.makeTableOrders[orderIndex] = message.Payload.OrderDetails;
      remove(this.makeTableOrders[orderIndex].OrderItems, x => x.StatusId == DomainConstants.OrderProductStatus.Refunded);
      const updatedItem = find(message.Payload.OrderDetails.OrderItems, (item) => {
        return item.OrderUpdate;
      });
      if (updatedItem && updatedItem.MakeTableState !== DomainConstants.MakeTableStates.PENDING) {
        this.addOrRemoveUpdatedOrder(updatedItem.Id, true);
      }
    } else {
      this.makeTableOrders = [...this.makeTableOrders, message.Payload.OrderDetails];
    }
    this.setOrderProperties();
  }

  addOrRemoveUpdatedOrder(id: number, isUpdate: boolean) {
    if (isUpdate) {
      this.updatedOrderItems.push(id);
    } else {
      remove(this.updatedOrderItems, (updatedId) => {
        return id === updatedId;
      });
    }
  }
  checkFilteredProduct(properties: Array<TerminalProperty>) {
    let filteredProductProperty = find(properties, (property) => {
      return property.PropertyKey === DomainConstants.TerminalProperties.FILTERED_PRODUCTS.Key;
    })?.PropertyValue;
    if (filteredProductProperty) {
      this.filteredProductData = JSON.parse(filteredProductProperty);
      this.isFilteredProductsApplied = this.orderCardService.checkIsFilteredApplied(this.filteredProductData);
      return;
    }
    this.isFilteredProductsApplied = false;
  }

  getTerminalProperty(needOpenFilterProduct = false) {
    this.terminalService.getTerminalProperties(this.applicationStateService.terminalId)
      .subscribe((response: Array<TerminalProperty>) => {
        if (response && response.length > 0) {
          this.makeTableLayout = find(response, (item) => {
            return item.PropertyKey === DomainConstants.TerminalProperties.LAYOUT.Key;
          });
          this.currentLayout = this.makeTableLayout && this.makeTableLayout.PropertyValue ? this.makeTableLayout.PropertyValue
            : DomainConstants.MaketableView.Grid;
          const makeTableReadOnly = find(response, (item) => {
            return item.PropertyKey === DomainConstants.TerminalProperties.READ_ONLY.Key;
          });
          this.checkFilteredProduct(response);
          if (needOpenFilterProduct) {
            this.filterProducts();
          }

          this.isReadOnlyMakeTable = makeTableReadOnly && makeTableReadOnly.PropertyValue == 'true' ? true : false;
        } else {
          this.currentLayout = DomainConstants.MaketableView.Grid;
          this.makeTableLayout = {
            Id: 0,
            TerminalId: this.applicationStateService.terminalId,
            PropertyKey: DomainConstants.TerminalProperties.LAYOUT.Key,
            PropertyValue: DomainConstants.MaketableView.Grid
          };
        }
        this.getMakeTableOrders(false);
      });

  }

  changeLayout(layout) {
    if (layout !== this.currentLayout) {
      this.makeTableLayout.PropertyValue = layout;
      this.terminalService.saveMakeTableTerminalProperty(this.makeTableLayout)
        .subscribe(() => { });
      this.currentLayout = layout;
      this.setNoOfColumns();
    }
    $('.warning-popover').popover('hide');
  }

  setMakeTableGlobalConfiguration(makeTableWorkflowStates) {
    forEach(makeTableWorkflowStates, (makeTableState) => {
      if (makeTableState.Code === DomainConstants.MakeTableStates.PENDING) {
        this.makeTableWorkflowStates.Pending = makeTableState.IsSelected;
      }
      if (makeTableState.Code === DomainConstants.MakeTableStates.RETRIEVING_INGREDIENTS) {
        this.makeTableWorkflowStates.RetrievingIngredients = makeTableState.IsSelected;
      }
      if (makeTableState.Code === DomainConstants.MakeTableStates.COOKING) {
        this.makeTableWorkflowStates.Cooking = makeTableState.IsSelected;
      }
      if (makeTableState.Code === DomainConstants.MakeTableStates.CRAFTING) {
        this.makeTableWorkflowStates.Crafting = makeTableState.IsSelected;
      }
      if (makeTableState.Code === DomainConstants.MakeTableStates.READY) {
        this.makeTableWorkflowStates.Ready = makeTableState.IsSelected;
      }
      if (makeTableState.Code === DomainConstants.MakeTableStates.DONE) {
        this.makeTableWorkflowStates.Done = makeTableState.IsSelected;
      }
      if (makeTableState.Code === DomainConstants.MakeTableStates.PARTIALLY_IN_PROGRESS) {
        this.makeTableWorkflowStates.PartiallyInProgress = makeTableState.IsSelected;
      }
    });
  }

  setStarColor() {
    if (this.settingParam.IsShowMakeTablePendingState) {
      this.isStarColor = true;
      setInterval(() => {
        this.isStarColor = !this.isStarColor;
      }, 750);
    }
  }

  getMakeTableOrders(isFromAutoRefresh: boolean) {
    if (!isFromAutoRefresh) {
      this.spinnerService.show();
    }
    this.makeTableService.getActiveOrdersData(this.applicationStateService.terminalId)
      .pipe(finalize(() => {
        if (!isFromAutoRefresh) {
          this.spinnerService.hide();
        }
      }))
      .subscribe({
        next: (res: Array<MakeTableOrderModel>) => {
          this.makeTableOrders = res;
          this.setOrderProperties();
        }, error: this.alertService.showApiError
      });
  }

  setIsFilteredProduct(orderItem: MakeTableOrderItem) {
    orderItem.IsFiltered = true;
    if (orderItem.OrderItemType === DomainConstants.OrderItemTypes.Product && this.isFilteredProductsApplied && this.filteredProductData) {
      orderItem.IsFiltered = this.filteredProductData.SalesCategories?.includes(orderItem.SalesCategoryId)
        || this.filteredProductData.SalesGroups?.includes(orderItem.SalesGroupId) || orderItem.SalesProductId < 0
    }
  }

  setOrderProperties() {
    this.makeTableCookingPanelProducts = [];
    forEach(this.makeTableOrders, (order) => {
      forEach(order.OrderItems, (orderItem: MakeTableOrderItem, index) => {
        if (orderItem) {
          this.setMakeTableColors(orderItem);
          this.setCommonProperties(order, orderItem, index);

          this.checkOrderItemUpdated(orderItem);
          this.setIsFilteredProduct(orderItem);
        }
        orderItem.IsMakeTableStateChangeByGenericUser = this.checkIsMakeTableStateChangeByGenericUser(orderItem);
        orderItem.IsScheduledProduct = orderItem.ScheduleTime && new Date(orderItem.ScheduleTime) > new Date();
      });
      order.ScheduledProductCount = order.OrderItems.filter(x => x.IsScheduledProduct).length;
      this.addMissingMakeTableUIState(order);
    });
    this.makeTableOrders = filter(this.makeTableOrders, (order) => {
      return (Number(this.makeTableUIStates[order.Id].TotalOrderProducts) > 0 || !this.isFilteredProductsApplied || this.attributeExists(order)) &&
        (!order.ScheduleDate || (order.ScheduleDate && new Date(order.ScheduleDate) <= new Date()));
    });
    this.makeTableOrders = this.makeTableOrders.filter(order => order.OrderItems.filter(x => x.OrderItemType == DomainConstants.OrderItemTypes.Product).length || this.attributeExists(order));
    forEach(this.makeTableOrders, (order) => {
      forEach(order.OrderItems, (orderItem: MakeTableOrderItem, index) => {
        if (orderItem) {
          this.getMakeTableOrderProductList(order, orderItem);
        }
      });
    });
    // sort orders by make table time
    this.updateMakeTableUIStateTime();
    const sortByProperty = this.settingParam.ShortMakeTableOrdersBy ?? DomainConstants.MakeTableSortProperties[0].Value;
    this.makeTableOrders = sortBy(this.makeTableOrders, sortByProperty);
    this.makeTableCookingProductsByGroup = groupBy(this.makeTableCookingPanelProducts, 'CookingTabHeader');
    this.setScrollButton();
  }

  attributeExists(order: MakeTableOrderModel) {
    return order.OrderAttributes.IsCallIn || order.OrderAttributes.Name || order.OrderAttributes.PhoneNumber;
  }

  checkOrderItemUpdated(orderItem) {
    if (this.updatedOrderItems && this.updatedOrderItems.length) {
      const updatedOrderItem = find(this.updatedOrderItems, (updatedId) => {
        return updatedId === orderItem.Id;
      });
      if (updatedOrderItem) {
        orderItem.OrderUpdate = true;
      }
    }
  }


  getProductCount(order) {
    let productCount: string | number = sum(map(filter(order.OrderItems, (orderItem) => {
      return orderItem.OrderItemType === DomainConstants.OrderItemTypes.Product
        && orderItem.OrderProductComponents[0].IsShowOnMakeTable
        && orderItem.IsSentToKitchen
        && orderItem.IsFiltered;
    }), 'Qty'));
    if (productCount && productCount % 1 !== 0) {
      productCount = productCount.toFixed(2);
    }
    return productCount;
  }
  addMissingMakeTableUIState(order) {
    if (!this.makeTableUIStates[order.Id]) {
      const uiState = {
        FormattedTime: this.calculateOrderTime(order),
        IsCollapsible:
          order.OrderAttributes.OnlineOrderId &&
          this.checkPickupTime(order.OrderAttributes.PickupTime),
        IsCollapsed:
          this.settingParam.AutoCollapseOnlineOrders &&
          order.OrderAttributes.OnlineOrderId &&
          this.checkPickupTime(order.OrderAttributes.PickupTime),
        IsReadyToServe: this.isShowServeButton(order),
        IsShowNotifyButton: this.isShowNotifyButton(order),
        IsDisableNotifyButton: false,
        TotalOrderProducts: this.getProductCount(order),
        SurrogateOrderId: order.SurrogateOrderId,
      };
      this.makeTableUIStates[order.Id] = uiState;
    } else {
      this.makeTableUIStates[order.Id].IsReadyToServe = this.isShowServeButton(order);
      this.makeTableUIStates[order.Id].IsShowNotifyButton = this.isShowNotifyButton(order);
      this.makeTableUIStates[order.Id].IsDisableNotifyButton = this.makeTableUIStates[order.Id].IsDisableNotifyButton ?
        this.makeTableUIStates[order.Id].IsDisableNotifyButton : false;
      this.makeTableUIStates[order.Id].TotalOrderProducts = this.getProductCount(order);
      this.makeTableUIStates[order.Id].SurrogateOrderId = order.SurrogateOrderId;
      if (order.OrderAttributes.OnlineOrderId) {
        this.makeTableUIStates[order.Id].IsCollapsible = this.checkPickupTime(order.OrderAttributes.PickupTime);
        this.makeTableUIStates[order.Id].IsCollapsed = this.makeTableUIStates[order.Id].IsCollapsed && this.makeTableUIStates[order.Id].IsCollapsible;
      }
    }
  }
  setMakeTableColors(orderItem) {
    orderItem.MakeTableBgColor = this.orderCardService.getItemMakeTableColor(orderItem, this.makeTableColorConfig);
    orderItem.FontColor = this.colorUtilityService.getContrastColor(orderItem.MakeTableBgColor);
    orderItem.QtyBgColor = this.orderCardService.getBackGroundColor(orderItem.Qty, this.settingParam.MakeTableQuantityColors);
    orderItem.QtyFontColor = this.colorUtilityService.getContrastColor(orderItem.QtyBgColor);
  }

  setCommonProperties(order, product, index) {
    product.OrderUpdate = product.OrderUpdate && product.MakeTableState !== this.makeTableStates.PENDING;
    product.SizeName = this.getSizeName(product);
    const productCount = sum(map(filter(order.OrderItems, (orderItem) => {
      return orderItem.OrderItemType === DomainConstants.OrderItemTypes.Product
        && orderItem.OrderProductComponents[0].IsShowOnMakeTable
        && orderItem.IsSentToKitchen
        && orderItem.IsFiltered;
    }), 'Qty'));
    if (this.makeTableUIStates[order.Id]) {
      this.makeTableUIStates[order.Id].TotalOrderProducts = productCount;
    }
  }

  getSizeName(product) {
    let sizeName = '';
    forEach(product.OrderProductComponents, (item) => {
      forEach(this.productSizes, (size) => {
        if (item.IsSizeButton && item.SizeId === size.Id) {
          sizeName = size.MakeTableSize;
        }
      });
    });
    return sizeName;
  }

  isAllowMakeTableStateChangeFromCooking(product) {
    // if (current status = 'Cooking') {
    //     if (any of the component have cooking status != 'queued') {
    //         if (any of the components are marked to use workflow timer and it's status != 'done') {
    //             don't change status
    //         }
    //     }
    // }
    // //we are good to change status

    let queuedProductCount = 0;
    let isAllowStateChange = true;
    let cookingOrderProductComponent = [];
    if (product.OrderProductComponents && product.OrderProductComponents[0].IsUseWorkflowTimer
      && product.OrderProductComponents[0].TimerState !== DomainConstants.TimerStates.QUEUED
      && product.OrderProductComponents[0].TimerState !== null) {
      queuedProductCount++;
    }
    if (queuedProductCount === 0) {
      cookingOrderProductComponent = $.grep(product.OrderProductComponents, (productComponent) => {
        return (productComponent.IsUseWorkflowTimer
          && productComponent.TimerState !== DomainConstants.TimerStates.QUEUED
          && productComponent.TimerState !== null);
      });
      if (cookingOrderProductComponent && cookingOrderProductComponent.length > 0) {
        queuedProductCount++;
      }
    }
    if (queuedProductCount) {
      cookingOrderProductComponent = [];
      cookingOrderProductComponent = $.grep(product.OrderProductComponents, (productComponent) => {
        return (productComponent.IsUseWorkflowTimer
          && productComponent.TimerState !== DomainConstants.TimerStates.DONE
          && productComponent.TimerState !== null);
      });
      if (cookingOrderProductComponent && cookingOrderProductComponent.length > 0) {
        isAllowStateChange = false;
      }
    }
    return isAllowStateChange;
  }

  hidePopover(popoverId) {
    $('#' + popoverId).popover('hide');
  }



  changeOrderUpdateState(event) {
    if (!this.isReadOnlyMakeTable) {
      const product = event.Product;
      const order = event.Order;
      product.OrderUpdate = false;
      product.IsAlreadyOrderUpdated = true;
      this.addOrRemoveUpdatedOrder(product.Id, false);
      var newState = this.makeTableStates.PENDING;
      if (product.makeTableState != this.makeTableStates.PENDING && this.makeTableWorkflowStates.PartiallyInProgress) {
        newState = this.makeTableStates.PARTIALLY_IN_PROGRESS;
      }
      this.changeProductState(product, newState, order.Id, product.MakeTableState, order.SurrogateOrderId);
    }
  }

  getNextWorkflowStatus(currentStatus) {
    if (currentStatus === DomainConstants.MakeTableStates.PENDING || currentStatus === DomainConstants.MakeTableStates.PARTIALLY_IN_PROGRESS) {
      return DomainConstants.MakeTableStates.RETRIEVING_INGREDIENTS;
    } else if (currentStatus === DomainConstants.MakeTableStates.RETRIEVING_INGREDIENTS) {
      return DomainConstants.MakeTableStates.COOKING;
    } else if (currentStatus === DomainConstants.MakeTableStates.COOKING) {
      return DomainConstants.MakeTableStates.CRAFTING;
    } else if (currentStatus === DomainConstants.MakeTableStates.CRAFTING) {
      return DomainConstants.MakeTableStates.READY;
    } else if (currentStatus === DomainConstants.MakeTableStates.READY) {
      return DomainConstants.MakeTableStates.DONE;
    } else if (currentStatus === DomainConstants.MakeTableStates.DONE) {
      return DomainConstants.MakeTableStates.PENDING;
    }
  }

  getNextState(product, currentStatus) {
    const nextWorkflowStatus = this.getNextWorkflowStatus(currentStatus);
    if (nextWorkflowStatus === DomainConstants.MakeTableStates.RETRIEVING_INGREDIENTS) {
      const useParallelPickScreenAndTimerForProduct = (product.UseParallelPickScreen !== null) ? product.UseParallelPickScreen
        : this.settingParam.UseParallelPickScreenAndTimer;
      if (!this.makeTableWorkflowStates.RetrievingIngredients ||
        !product.IsUsePickScreen ||
        useParallelPickScreenAndTimerForProduct) {
        return this.getNextState(product, nextWorkflowStatus);
      }
    } else if (nextWorkflowStatus === DomainConstants.MakeTableStates.COOKING) {
      if (!this.makeTableWorkflowStates.Cooking
        || !product.IsUseWorkflowTimer) {
        return this.getNextState(product, nextWorkflowStatus);
      }
    } else if (nextWorkflowStatus === DomainConstants.MakeTableStates.CRAFTING) {
      if (!this.makeTableWorkflowStates.Crafting
        || !product.IsShowInstructions) {
        return this.getNextState(product, nextWorkflowStatus);
      }
    } else if (nextWorkflowStatus === DomainConstants.MakeTableStates.READY) {
      if (!this.makeTableWorkflowStates.Ready
        || product.IsSkipReadyState) {
        return this.getNextState(product, nextWorkflowStatus);
      }
    }
    return nextWorkflowStatus;
  }

  openPopover(id, message) {
    setTimeout(() => {
      $('#' + id).attr('data-content', message);
      $('#' + id).popover('show');
      $('.popover-content').css('color', '#a94442');
    });


  }

  shouldSendLabelPrintRequest(product: MakeTableOrderItem): boolean {
    return this.productLabelPrinting === 2 && product.SalesProductId != -3
      && !product.IsAlreadyOrderUpdated
  }

  sendLabelPrint(product: MakeTableOrderItem, order: MakeTableOrderModel) {
    if (this.shouldSendLabelPrintRequest(product)) {
      const orderProductItems = [];
      const labelPrintObj = this.prepareOrderProductForLabelPrint(product, order);
      orderProductItems.push(labelPrintObj);
      if (orderProductItems?.length) {
        this.orderService.productLabelPrint(orderProductItems)
          .subscribe(() => {
          });
      } else {
        this.loggerService.logWarning($`Trying to call label print from makeTableComponent.sendLabelPrint with orderProductItems: 
                  ${orderProductItems?.length} for orderId ${labelPrintObj.OrderId}, surrogateId ${labelPrintObj.SurrogateOrderId} 
                  product ${labelPrintObj.ReceiptText} SalesProductID ${labelPrintObj.SalesProductID}`);
      }
    }
  }

  shouldSelectUser(orderProduct: MakeTableOrderItem): boolean {
    return this.settingParam.PromptToChooseUsernameOnMakeTable
      && orderProduct.MakeTableState === DomainConstants.MakeTableStates.PENDING
      && this.users?.length
      && some(this.applicationStateService.userDetails?.Roles, (role) => {
        return role.RoleName === DomainConstants.GENERIC_USER;
      });
  }

  // tslint:disable-next-line: member-ordering
  orderItemStateChange = (event) => {
    if (!this.isReadOnlyMakeTable) {
      const orderProduct: MakeTableOrderItem = event.Product;
      const order: MakeTableOrderModel = event.Order;
      if (orderProduct.OrderItemType === DomainConstants.OrderItemTypes.Product) {
        this.performPendingItemActions(orderProduct, order);
        if (this.shouldSelectUser(orderProduct)) {
          from(this.selectUser(orderProduct))
            .subscribe(
              {
                next: (userId: number) => {
                  this.stateChange(orderProduct, order);
                }, error: () => {}
              })
        } else {
          if (orderProduct.MakeTableState === DomainConstants.MakeTableStates.PENDING) {
            this.setExistingUserDetails(orderProduct);
          }
          else if (orderProduct.MakeTableState === DomainConstants.MakeTableStates.DONE) {
            this.resetUserDetails(orderProduct);
          }
          this.stateChange(orderProduct, order);
        }

      }
      this.toggleItemInProgress = false;
    }
  };

  resetUserDetails(orderProduct: MakeTableOrderItem) {
    orderProduct.MakeTableStateByUserFirstName = null;
    orderProduct.MakeTableStateByUserLastName = null;
    orderProduct.MakeTableStateByUserId = null;
    orderProduct.MakeTableStateByUserRole = [];
    orderProduct.IsMakeTableStateChangeByGenericUser = false;
  }

  private setExistingUserDetails(orderProduct: MakeTableOrderItem) {
    orderProduct.MakeTableStateByUserFirstName = this.applicationStateService.userDetails?.firstname;
    orderProduct.MakeTableStateByUserLastName = this.applicationStateService.userDetails?.lastname;
    orderProduct.MakeTableStateByUserId = this.applicationStateService.userDetails?.id;
    if (this.applicationStateService.userDetails?.Roles) {
      orderProduct.MakeTableStateByUserRole = [];
      forEach(this.applicationStateService?.userDetails?.Roles, (role) => {
        orderProduct.MakeTableStateByUserRole.push(role.RoleName);
      });
      orderProduct.IsMakeTableStateChangeByGenericUser = this.checkIsMakeTableStateChangeByGenericUser(orderProduct);
    }
  }

  stateChange(orderProduct: MakeTableOrderItem, order: MakeTableOrderModel) {
    const previousState = orderProduct.MakeTableState;
    this.toggleItemInProgress = true;
    if ((orderProduct.MakeTableState === DomainConstants.MakeTableStates.COOKING
      && !orderProduct.IsShowInstructions)
      || orderProduct.MakeTableState === DomainConstants.MakeTableStates.CRAFTING) {
      if (!this.isAllowMakeTableStateChangeFromCooking(orderProduct)) {
        if (orderProduct.Id > 0) {
          this.openPopover(orderProduct.Id, Messages.CookingWarningTimerFinish);
          setTimeout((item, id) => {
            this.hidePopover(id);
          }, 2000, true, orderProduct.Id);
        }
        return;
      } else {
        $('#' + orderProduct.Id).popover('dispose');
      }
    }

    let alreadyDonePopover = 0;
    forEach(this.makeTableOrders, (makeTableOrder) => {
      if (makeTableOrder.Id === order.Id) {
        forEach(makeTableOrder.OrderItems, (product) => {
          if (product.Id === orderProduct.Id) {
            let newState;
            newState = this.getNextState(product, product.MakeTableState);
            let isAllComponentsCookDone = true;
            if (newState === DomainConstants.MakeTableStates.COOKING) {
              forEach(product.OrderProductComponents, (item) => {
                if (item.IsUseWorkflowTimer
                  && item.TimerState !== DomainConstants.TimerStates.DONE) {
                  isAllComponentsCookDone = false;
                }
              });
              alreadyDonePopover = alreadyDonePopover !== orderProduct.Id ? orderProduct.Id : 0;
              if (isAllComponentsCookDone) {
                if (alreadyDonePopover > 0) {
                  this.openPopover(orderProduct.Id, Messages.CookingWarningAllComponentCooked);
                  setTimeout((item, id) => {
                    this.hidePopover(id);
                    alreadyDonePopover = 0;
                  }, 5000, true, alreadyDonePopover);
                }
              }
            }
            if (this.shouldSelectUser(orderProduct)) {
              this.setSelectedUserDetails(product, orderProduct.MakeTableStateByUserId);
            }
            this.changeProductState(product, newState, makeTableOrder.Id, previousState, order.SurrogateOrderId);
          }
        });
        this.makeTableUIStates[order.Id].IsReadyToServe = this.isShowServeButton(makeTableOrder);
        this.makeTableUIStates[order.Id].IsShowNotifyButton = this.isShowNotifyButton(makeTableOrder);
        this.makeTableUIStates[order.Id].IsDisableNotifyButton = false;
      }
    });
  }

  setSelectedUserDetails(orderProduct: MakeTableOrderItem, userId: number) {
    const user = this.users.find(x => x.Id == userId);
    orderProduct.MakeTableStateByUserFirstName = user.FirstName;
    orderProduct.MakeTableStateByUserLastName = user.LastName;
    orderProduct.MakeTableStateByUserId = userId;
    if (user?.UserRole) {
      orderProduct.MakeTableStateByUserRole = [];
      forEach(user?.UserRole, (role) => {
        orderProduct.MakeTableStateByUserRole.push(role.PermissionRole.Name);
      });
      orderProduct.IsMakeTableStateChangeByGenericUser = this.checkIsMakeTableStateChangeByGenericUser(orderProduct);
    }
  }

  selectUser(orderProduct: MakeTableOrderItem): Promise<number> {
    const modal = this.modalService.getModalWrapper(SelectUserComponent);
    const modalRef = modal.show({
      keyboard: false,
      animated: false,
      class: 'vertical-center',
      initialState: {
        users: this.users
      }
    });
    modalRef.close.subscribe((res) => {
      if (res.userId) {
        orderProduct.MakeTableStateByUserId = res.userId;
          this.resolvePromptPromise(res.userId);
      } else {
        this.rejectPromptPromise();
      }
    });
    return new Promise<number>((resolve, reject) => {
      this.resolvePromptPromise = resolve;
      this.rejectPromptPromise = reject;
    });
  }


  performPendingItemActions(orderProduct, order) {
    if (orderProduct.MakeTableState === DomainConstants.MakeTableStates.PENDING
      && this.settingParam.IsUseMakeTable) {
      this.sendLabelPrint(orderProduct, order);
    }
  }

  changeProductState = (product: MakeTableOrderItem, newState: string, orderId: number, previousState: string, surrogateOrderId) => {
    this.cameraAnnotationService.addAnnotationToCamera(this.cameraAnnotations.MakeTableStateChange, {
      ProductName: product.MakeTableText,
      OrderId: surrogateOrderId,
      PreviousState: this.stringUtilityService.displayName(previousState),
      NextState: this.stringUtilityService.displayName(newState)
    });
    product.MakeTableState = newState;
    product.MakeTableBgColor = this.orderCardService.getItemMakeTableColor(product, this.makeTableColorConfig);
    product.FontColor = this.colorUtilityService.getContrastColor(product.MakeTableBgColor);
    const productStatusUpdateModel =
      this.orderCardService.getProductStatusUpdateModel(product, orderId, previousState, this.applicationStateService.terminalId);
    product.QtyBgColor = this.orderCardService.getBackGroundColor(product.Qty, this.settingParam.MakeTableQuantityColors);
    product.QtyFontColor = this.colorUtilityService.getContrastColor(product.QtyBgColor);

    this.callApiForUpdateState(orderId, productStatusUpdateModel, product);
  }

  callApiForUpdateState = debounce((orderId, productStatusUpdateModel, product) => {
    this.noOfInflightRequests++;
    if (productStatusUpdateModel.PreviousMakeTableState == DomainConstants.MakeTableStates.PENDING) {
      productStatusUpdateModel.MakeTableStateByUserId = product.MakeTableStateByUserId;
    }
    this.makeTableService.updateProductState(this.applicationStateService.terminalId, orderId, productStatusUpdateModel)
      .subscribe({
        next: () => {
          this.noOfInflightRequests--;
          if (product.IsUsePickScreen) {
            this.notifyPickScreen(product);
          }
        }, error: (err) => {
          this.noOfInflightRequests--;
          this.alertService.showApiError(err);
        }
      });
  }, this.applicationStateService.settingParam.MakeTableStateChangeDelayMsec, {
    'maxWait': this.applicationStateService.settingParam.MakeTableStateChangeDelayMsec,
    'trailing': true,
    'leading': true
  });

  checkIsMakeTableStateChangeByGenericUser = (product) => {
    return some(product.MakeTableStateByUserRole, (role) => {
      return role === DomainConstants.GENERIC_USER;
    });
  }

  cookingPanelItemStateChange(groupProduct, productStateObject) {
    this.orderItemStateChange(productStateObject);
    groupProduct.BgColor = this.orderCardService.getItemMakeTableColor(productStateObject.Product, this.makeTableColorConfig);
    groupProduct.FontColor = this.colorUtilityService.getContrastColor(groupProduct.BgColor);
  }
  // tslint:enable-next-line: member-ordering
  getMakeTableOrderProductList(order, product) {
    forEach(product.OrderProductComponents, (component) => {
      if ((component.IsTimedPrep || component.PrepGroupName) && product.IsFiltered) {
        const bgColor = this.orderCardService.getItemMakeTableColor(product, this.makeTableColorConfig);
        const qtyBgColor = this.orderCardService.getBackGroundColor(product.Qty, this.settingParam.MakeTableQuantityColors)
        const makeTableCookingPanelProduct: MakeTableCookingPanelProduct = {
          CookingTabHeader: component.PrepGroupName ? component.PrepGroupName : '',
          BgColor: bgColor,
          FontColor: this.colorUtilityService.getContrastColor(bgColor),
          QtyBgColor: qtyBgColor,
          QtyFontColor: this.colorUtilityService.getContrastColor(qtyBgColor),
          MakeTableFgColor: component.MakeTableFgColor,
          Name: component.MakeTableText,
          TimerState: component.TimerState,
          Product: product,
          Order: order
        };
        this.makeTableCookingPanelProducts.push(makeTableCookingPanelProduct);
      }
    });
  }

  shouldRemoveFromPickScreen(product) {
    // Item should be visible in pick screen if it's status is "RetrievingIngredients"
    // or item is marked to use parallel pick screen and item status is cooking
    const showInPickScreen = product.MakeTableState === DomainConstants.MakeTableStates.RETRIEVING_INGREDIENTS
      || ((product.UseParallelPickScreen === true || (product.UseParallelPickScreen == null
        && this.settingParam.UseParallelPickScreenAndTimer === true))
        && (product.MakeTableState === DomainConstants.MakeTableStates.COOKING));

    return !showInPickScreen;
  }

  notifyPickScreen(product) {
    const messageForPickScreen = product;
    messageForPickScreen.Remove = this.shouldRemoveFromPickScreen(product);
    this.pickScreen(messageForPickScreen);
  }

  pickScreen(orderDetailsForPickScreen) {
    console.log(orderDetailsForPickScreen);
    console.log('Sending message to PickScreen for order: '
      + orderDetailsForPickScreen.OrderId + ', Remove: ' + orderDetailsForPickScreen.Remove);
    this.rabbitMQService.sendOrderUpdateFromMakeTable(this.applicationStateService.terminalId, orderDetailsForPickScreen);
  }

  showCookingProduct() {
    $('#cookingProducts').toggle();
    this.isOpenCookingPanel = !this.isOpenCookingPanel;
    if (!this.isOpenCookingPanel) {
      $('#cookingButton').css('right', (this.cookingPanel.nativeElement.clientWidth + 2));
    } else {
      $('#cookingButton').css('right', '25%');
    }
    this.setNoOfColumns(($(window).width() > 1199) ? (this.isOpenCookingPanel ? 4 : 5) : this.noOfColumns - 1);
  }

  isShowServeButton(order) {
    const onlyDiscount = filter(order.OrderItems, product => product.OrderItemType === DomainConstants.OrderItemTypes.Discount)?.length == order.OrderItems?.length;
    const products = some(order.OrderItems, product => product.MakeTableState !== DomainConstants.MakeTableStates.DONE
      && product.OrderItemType === DomainConstants.OrderItemTypes.Product
      && product.OrderProductComponents[0].IsShowOnMakeTable);
    if (order.GrandTotal <= order.PaidAmount && !products && !onlyDiscount && order.OrderItems?.length) {
      return true;
    } else {
      return false;
    }
  }

  isShowNotifyButton(order: MakeTableOrderModel) {
    let isShowNotifyButton = true;
    const hasProduct = some(order.OrderItems, product => product.OrderItemType === DomainConstants.OrderItemTypes.Product);
    if (hasProduct && this.makeTableOrders && this.settingParam.OrderReadyShowNotifyButtonOnMaketable) {
      forEach(order.OrderItems, (product) => {

        if (isShowNotifyButton && product.OrderItemType === DomainConstants.OrderItemTypes.Product
          && product.MakeTableState !== DomainConstants.MakeTableStates.DONE
          && product.MakeTableState !== DomainConstants.MakeTableStates.READY && product.OrderProductComponents[0].IsShowOnMakeTable) {
          isShowNotifyButton = false;
        }
      });
    } else {
      isShowNotifyButton = false;
    }
    return isShowNotifyButton;
  }

  scrollUp() {
    const x = $(document).height() - $(document).height() - $(document).height();
    window.scrollBy(0, x);
  }

  scrollDown() {
    window.scrollBy(0, $(document).height());
  }

  notify = (orderId) => {
    if (!this.isReadOnlyMakeTable) {
      this.cameraAnnotationService.addAnnotationToCamera(this.cameraAnnotations.MakeTableNotify, {
        OrderId: this.makeTableUIStates[orderId]?.SurrogateOrderId
      });
      this.orderService.notify(orderId)
        .subscribe({
          next: () => {
            // Notified successfully
          }, error: (err) => {
            this.alertService.showApiError(err);
          }
        });
      this.makeTableUIStates[orderId].IsDisableNotifyButton = true;
      this.enableNotifyButton(orderId);
    }
  }

  enableNotifyButton = (orderId) => {
    setTimeout((item, id) => {
      if (this.makeTableUIStates && this.makeTableUIStates.length && this.makeTableUIStates[id]) {
        this.makeTableUIStates[id].IsDisableNotifyButton = false;
      }
    }, 5000, true, orderId);
  }

}
