<pos-list-wrapper [headingIcon]="icons.font" heading="Fonts" (close)="close()">
    <div class="row">
      <div class="col-12">
        <ng-table #salesUnitGrid [columns]="fontColumns" [exportFilename]="'fonts'" [data]="fonts"
          id="fonts" [sort]="true">
        </ng-table>
      </div>
    </div>
  </pos-list-wrapper>
  <pos-print-table [hidden]="true"></pos-print-table>
