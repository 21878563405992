import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import { Observable } from 'rxjs';
import { CentralApiConfiguration } from '../interface';

@Injectable({
    providedIn: 'root'
})
export class CentralApiConfigurationService {

    constructor(private httpService: HttpClient) { }
    newCentralApiConfiguration(): CentralApiConfiguration {
        const centralApiConfigurationObj: CentralApiConfiguration = {
            ClientSecret: '',
            AuthApiUrl: '',
            Scope: '',
            CentralApiUrl: '',
            PrivateKey: '',
        };
        return centralApiConfigurationObj;
    }

    getCentralApiConfiguration(): Observable<CentralApiConfiguration> {
        return this.httpService.get<CentralApiConfiguration>(
            `${RuntimeConstants.API_PATH}centralapiconfiguration`
        );
    }
    saveCentralApiConfiguration(centralApiConfig: CentralApiConfiguration): Observable<CentralApiConfiguration> {
        return this.httpService.post<CentralApiConfiguration>(
            `${RuntimeConstants.API_PATH}centralapiconfiguration`, centralApiConfig
        );
    }
}
