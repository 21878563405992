<div class="m-0-auto width-full">
    <div class="page-content">
        <div class="tab-content" [ngStyle]="{'width': isFullScreen ? '100%' : '80%'}">
            <div class="tab-pane fade in active show">
                <div class="col-12 pos-page-body">
                    <a class="close" (click)="close()">×</a>
                    <div class="col-lg-9 col-md-9 col-sm-8 col-8 pb-pix-5">
                        <h3 class="page-content__header">
                            Event
                        </h3>
                    </div>
                </div>
                <form #formEventConfigurationList="ngForm" class="edit-form"
                    (submit)="saveEventConfiguration(formEventConfigurationList.valid)" autocomplete="off">
                    <div class="col-12 pos-page-body">
                        <div class="row mx-0">
                            <div class="display-flex edit-form__section__heading p-0 event-heading">
                                <div class="flex-auto">
                                    <h3 class="pi-0 bold">{{eventConfiguration.Name}} </h3>
                                    <div class="col-12 font-14 p-0">
                                        <label>
                                            {{eventConfiguration.Description}}
                                        </label>
                                    </div>
                                </div>
                                <div class="float-end pt-pix-5">
                                    <button class="btn btn-primary white normal me-pix-5 pos-button mb-1" type="button"
                                        (click)="newEventSubscription()">
                                        <pos-icon [icon]="icons.eventActionPlus" class="plus-icon">
                                        </pos-icon> Subscription
                                    </button>
                                    <div class="btn-group mb-1">
                                        <label class="btn btn-radio"
                                            [ngClass]="eventConfiguration.IsActive ? 'active btn-primary normal' : 'btn-secondary'">
                                            <input type="radio" name="percentTypeAuto" class="form-check-input"
                                                [(ngModel)]="eventConfiguration.IsActive" (click)="changeEventStatus()"
                                                [value]="true">Active</label>
                                        <label class="btn btn-radio"
                                            [ngClass]="!eventConfiguration.IsActive ? 'active btn-danger normal' : 'btn-secondary'">
                                            <input type="radio" name="percentTypeAuto" class="form-check-input"
                                                [(ngModel)]="eventConfiguration.IsActive" (click)="changeEventStatus()"
                                                [value]="false">Inactive</label>
                                    </div>
                                </div>
                            </div>
                            <div class="row p-pix-10" *ngIf="eventConfiguration.IsScheduled">
                                <div class="col-200 col-lg-3 col-md-3 col-sm-4 col-12 pt-pix-12">
                                    <label class="edit-form__section__label required">Schedule
                                        Expression</label>
                                </div>
                                <div class="col-lg-9 col-md-9 col-sm-12 col-12"
                                    [ngClass]="{'has-error': (!isCronValid && formEventConfigurationList.submitted)}">
                                    <app-cron-editor (cronChange)="cronExpressionChanged($event)"
                                        [cronStartingValue]="eventConfiguration.Schedule" [options]="cronOptions">
                                    </app-cron-editor>
                                    <span *ngIf="!isCronValid && formEventConfigurationList.submitted">
                                        <label class="control-label">Please enter
                                            valid expression.
                                        </label>
                                    </span>
                                </div>

                            </div>
                            <div class="overflow-auto-tabs mt-pix-5"
                                [ngStyle]="{'height': 'calc(100vh - '+ (eventConfiguration.IsScheduled ? '570px' : '350px') +')'}"
                                *ngIf="eventConfiguration.EventSubscriptions?.length">
                                <div *ngFor="let subscription of eventConfiguration.EventSubscriptions; let i = index;">
                                    <div class="subscription-heading item-vertical-center flex-wrap mt-pix-10">
                                        <div class="col-8" *ngIf="!subscription.EventSubscriptionAttributes?.length">
                                            <p class="bold ps-pix-15 pt-pix-14 theme-separator">Subscription {{i + 1}}
                                            </p>
                                        </div>
                                        <!-- Attribute section -->
                                        <div *ngIf="subscription.EventSubscriptionAttributes?.length"
                                            class="col-lg-8 col-md-9 col-sm-6 col-12 p-0 mt-pix-10 mb-pix-10 ps-pix-5"
                                            [ngClass]="{'row mx-0': eventConfiguration.EventIdentifier == eventTypes.HardwareConnectedEvent || eventConfiguration.EventIdentifier == eventTypes.EquipmentTemperatureExceptionEvent || eventConfiguration.EventIdentifier == eventTypes.InventoryPrepNeededEvent}">
                                            <div *ngFor="let attribute of subscription.EventSubscriptionAttributes"
                                                [ngClass]="{'col-8 p-0': attribute.AttributeKey === eventSubscriptionAttributeKeys.HardwareDevices || attribute.AttributeKey === eventSubscriptionAttributeKeys.TemperatureExceptionState,
                                                    'col-4 p-0': attribute.AttributeKey === eventSubscriptionAttributeKeys.HardwareStateMinutes || attribute.AttributeKey === eventSubscriptionAttributeKeys.TemperatureExceptionMinutes || attribute.AttributeKey === eventSubscriptionAttributeKeys.WithinPercentOfMinimum || attribute.AttributeKey === eventSubscriptionAttributeKeys.QtyStatus}">
                                                <div class="row mx-0"
                                                    *ngIf="eventConfiguration.EventIdentifier == eventTypes.NoOfActiveOrderExceedsRecoverEvent ||
                                                    eventConfiguration.EventIdentifier == eventTypes.NoOfActiveOrderFallsEvent">
                                                    <div class="col-lg-4 col-md-6 col-sm-6 col-12 col-150">
                                                        <label class="label-section">Active
                                                            Orders</label>
                                                    </div>
                                                    <div class="col-sm-6 col-12 col-120">
                                                        <input class="form-control" id="activeOrderCount"
                                                            name="activeOrderCount{{i}}" min="0" step="1"
                                                            [(ngModel)]="attribute.AttributeValue" type="number"
                                                            #activeOrder="ngModel" />
                                                    </div>
                                                </div>
                                                <div class="row mx-0"
                                                    *ngIf="eventConfiguration.EventIdentifier == eventTypes.UserClockedInOverXHoursEvent
                                                    || (eventConfiguration.EventIdentifier == eventTypes.UserClockedInOverXHourFromXPeriodEvent
                                                        && attribute.AttributeKey === eventSubscriptionAttributeKeys.Hours)">
                                                    <div class="col-2 col-100 pb-pix-5"
                                                        *ngIf="eventConfiguration.EventIdentifier == eventTypes.UserClockedInOverXHoursEvent">
                                                        <label class="label-section">Hours</label>
                                                    </div>
                                                    <div class="col-2 pb-pix-5 col-220"
                                                        *ngIf="eventConfiguration.EventIdentifier == eventTypes.UserClockedInOverXHourFromXPeriodEvent">
                                                        <label class="label-section">When a user is clocked in
                                                            for</label>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6 col-sm-6 col-12 col-120 pb-pix-5">
                                                        <input class="form-control" id="timeClockHour"
                                                            name="timeClockHour{{i}}" min="0" step="1"
                                                            [(ngModel)]="attribute.AttributeValue" type="number"
                                                            #timeClockHour="ngModel" />
                                                    </div>
                                                    <div class="col-lg-6 col-md-6 col-sm-6 col-12 pb-pix-5 p-0"
                                                        *ngIf="eventConfiguration.EventIdentifier == eventTypes.UserClockedInOverXHourFromXPeriodEvent">
                                                        <label class="label-section">hours in this time frame</label>
                                                    </div>
                                                </div>
                                                <div *ngIf="(eventConfiguration.EventIdentifier == eventTypes.UserClockedInOverXHourFromXPeriodEvent
                                                        && attribute.AttributeKey === eventSubscriptionAttributeKeys.Period)"
                                                    class="row mx-0">
                                                    <div class="col-12 pb-pix-5">
                                                        <label
                                                            class="col-12 ps-0 pe-0 align-items-center display-flex pe-pix-20">
                                                            <label class="align-items-center display-flex mb-0">
                                                                <input [(ngModel)]="selectedPeriodList[i].Period"
                                                                    type="radio" #periodWeek="ngModel"
                                                                    (ngModelChange)="onPeriodChange(i)"
                                                                    [value]="period.Day" name="period{{i}}">
                                                                <div>The last </div>
                                                            </label>
                                                            <input class="form-control col-90 ms-pix-15 me-pix-15"
                                                                id="day" name="day{{i}}" min="0" step="1"
                                                                [disabled]="selectedPeriodList[i].Period != period.Day"
                                                                [(ngModel)]="selectedPeriodList[i].Day" type="number"
                                                                #day="ngModel" />
                                                            <div>days</div>
                                                        </label>
                                                        <label
                                                            class="label-section col-12 ps-0 pe-0 align-items-center display-flex pt-pix-5 pe-pix-20">
                                                            <label class="align-items-center display-flex mb-0">
                                                                <input [(ngModel)]="selectedPeriodList[i].Period"
                                                                    type="radio" #periodDay="ngModel"
                                                                    (ngModelChange)="onPeriodChange(i)"
                                                                    [value]="period.Week" name="period{{i}}">
                                                                <div>Since</div>
                                                            </label>

                                                            <input class="form-control col-90 ms-pix-15 me-pix-15"
                                                                id="day" name="weekday{{i}}" min="0" step="1"
                                                                [disabled]="selectedPeriodList[i].Period != period.Week"
                                                                [(ngModel)]="selectedPeriodList[i].WeekDay"
                                                                type="number" #weekday="ngModel" />
                                                            <ng-select posInput [items]="weeks" placeholder="Select day name"
                                                                class="p-0 col-150 me-pix-15" [hideSelected]="true"
                                                                [disabled]="selectedPeriodList[i].Period != period.Week"
                                                                bindLabel="Key" bindValue="Value" [searchable]="false"
                                                                [(ngModel)]="selectedPeriodList[i].Week" #week="ngModel"
                                                                name="week{{i}}">
                                                            </ng-select>
                                                            <div>'s ago</div>
                                                        </label>
                                                        <label class="label-section col-12 ps-0 pe-0 align-items-center 
                                                            hour-over-month display-flex pt-pix-5 pe-pix-20">
                                                            <label class="align-items-center display-flex mb-0">
                                                                <input [(ngModel)]="selectedPeriodList[i].Period"
                                                                    type="radio" #periodWeek="ngModel"
                                                                    (ngModelChange)="onPeriodChange(i, true)"
                                                                    [value]="period.Month" name="period{{i}}">
                                                                <div>Since day</div>
                                                            </label>
                                                            <input class="form-control col-90 ms-pix-15" id="day"
                                                                name="monthOfDay{{i}}" min="0" step="1"
                                                                [disabled]="selectedPeriodList[i].Period != period.Month"
                                                                [(ngModel)]="selectedPeriodList[i].DayOnMonth"
                                                                type="number" #monthOfDay="ngModel" />
                                                            <div class="ms-pix-15 me-pix-15">of the</div>
                                                            <ng-select posInput [items]="hoursOverPeriodMonthOptions"
                                                                class="col-120" [hideSelected]="true"
                                                                [clearable]="false"
                                                                [disabled]="selectedPeriodList[i].Period != period.Month"
                                                                bindLabel="Key" bindValue="Value" [searchable]="false"
                                                                [(ngModel)]="selectedPeriodList[i].Month"
                                                                #month="ngModel" name="month{{i}}">
                                                            </ng-select>
                                                            <div class="ms-pix-15">month</div>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="col-12 p-0"
                                                    *ngIf="eventConfiguration.EventIdentifier == eventTypes.EquipmentTemperatureExceptionEvent">
                                                    <span class="row mx-0 mb-pix-10"
                                                        *ngIf="attribute.AttributeKey === eventSubscriptionAttributeKeys.Equipments">
                                                        <div class="col-2 pe-0 col-170">
                                                            <label class="label-section">Equipment(s)</label>
                                                        </div>
                                                        <div class="col-md-9 col-12">
                                                            <ng-select posInput [items]="equipments" [multiple]="true"
                                                                placeholder="Select equipment(s)" [hideSelected]="true"
                                                                bindLabel="Name" bindValue="Id"
                                                                [(ngModel)]="attribute.AttributeValue"
                                                                name="equipments{{i}}">
                                                            </ng-select>
                                                        </div>
                                                    </span>
                                                    <span class="row mx-0"
                                                        *ngIf="attribute.AttributeKey === eventSubscriptionAttributeKeys.TemperatureExceptionState">
                                                        <div class="col-lg-4 col-md-4 col-12 pe-0 col-170">
                                                            <label class="label-section">When temperature is</label>
                                                        </div>
                                                        <div class="col-md-8 col-12 pe-0">
                                                            <ng-select posInput [items]="temperatureExceptions" [multiple]="true"
                                                                placeholder="Select temperature exception(s)"
                                                                [hideSelected]="true" bindLabel="Key" bindValue="Value"
                                                                [(ngModel)]="attribute.AttributeValue"
                                                                name="temperatureException{{i}}">
                                                            </ng-select>
                                                        </div>
                                                    </span>
                                                    <span class="row mx-0"
                                                        *ngIf="attribute.AttributeKey === eventSubscriptionAttributeKeys.TemperatureExceptionMinutes">
                                                        <div class="col-lg-4 col-md-4 col-sm-4 col-12 p-0 col-20">
                                                            <label class="label-section">for</label>
                                                        </div>
                                                        <div class="col-lg-6 col-md-6 col-sm-6 col-12 col-120">
                                                            <input class="form-control"
                                                                name="temperatureExceptionMinute{{i}}" min="0" step="1"
                                                                [(ngModel)]="attribute.AttributeValue" type="number"
                                                                #temperatureExceptionMinute="ngModel" />
                                                        </div>
                                                        <div class="col-lg-4 col-md-4 col-sm-6 col-12 p-0 col-30">
                                                            <label class="label-section">minutes</label>
                                                        </div>
                                                    </span>
                                                </div>
                                                <div class="col-12"
                                                    *ngIf="eventConfiguration.EventIdentifier == eventTypes.HardwareConnectedEvent">
                                                    <span class="row mx-0"
                                                        *ngIf="attribute.AttributeKey === eventSubscriptionAttributeKeys.HardwareDevices">
                                                        <div class="col-2 p-0 col-90">
                                                            <label class="label-section">Hardware</label>
                                                        </div>
                                                        <div class="col-sm-10 col-12 ps-0">
                                                            <ng-select posInput [items]="hardwareDevices" [multiple]="true"
                                                                placeholder="Select hardware device(s)"
                                                                [hideSelected]="true" bindLabel="Name" bindValue="Id"
                                                                [(ngModel)]="attribute.AttributeValue"
                                                                name="hardwareDevices{{i}}">
                                                            </ng-select>
                                                        </div>
                                                    </span>
                                                    <span class="row mx-0"
                                                        *ngIf="attribute.AttributeKey === eventSubscriptionAttributeKeys.HardwareStateMinutes">
                                                        <div class="col-lg-4 col-md-4 col-sm-4 col-12 p-0 col-130">
                                                            <label class="label-section">disconnected for</label>
                                                        </div>
                                                        <div class="col-sm-6 col-12 col-120">
                                                            <input class="form-control" name="hardwareStateMinutes{{i}}"
                                                                min="0" step="1" [(ngModel)]="attribute.AttributeValue"
                                                                type="number" #hardwareStateMinutes="ngModel" />
                                                        </div>
                                                        <div class="col-lg-4 col-md-4 col-sm-6 col-12 p-0 col-30">
                                                            <label class="label-section">minutes</label>
                                                        </div>
                                                    </span>
                                                </div>
                                                <div class="row mx-0"
                                                    *ngIf="eventConfiguration.EventIdentifier == eventTypes.UnservedOrderMinutesEvent ||
                                                eventConfiguration.EventIdentifier == eventTypes.CreditCardTerminalDisconnectedEvent">
                                                    <div class="col-lg-4 col-md-6 col-sm-6 col-12 col-100">
                                                        <label class="label-section">Minutes</label>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6 col-sm-6 col-12 col-120">
                                                        <input class="form-control" id="unServedOrderMinute"
                                                            name="unServedOrderMinute{{i}}" min="0" step="1"
                                                            [(ngModel)]="attribute.AttributeValue" type="number"
                                                            #unServedOrderMinute="ngModel" />
                                                    </div>
                                                </div>
                                                <div class="row mx-0"
                                                    *ngIf="eventConfiguration.EventIdentifier == eventTypes.ButtonPressedEvent">
                                                    <div class="col-lg-4 col-md-4 col-12 col-120">
                                                        <label class="label-section">Button(s)</label>
                                                    </div>
                                                    <div class="col-lg-8 col-md-8 col-12" *ngIf="buttons.length > 0">
                                                        <ng-select posInput [items]="buttons" bindLabel="ButtonScreen"
                                                            bindValue="Id" [multiple]="true"
                                                            placeholder="Select button(s)" [hideSelected]="true"
                                                            [(ngModel)]="attribute.AttributeValue" name="buttons{{i}}">
                                                        </ng-select>
                                                    </div>
                                                </div>
                                                <div class="row mx-0"
                                                    *ngIf="eventConfiguration.EventIdentifier == eventTypes.ProductOrderedEvent">
                                                    <div class="col-lg-4 col-md-4 col-12 col-120">
                                                        <label class="label-section">Product(s)</label>
                                                    </div>
                                                    <div class="col-lg-8 col-md-8 col-12">
                                                        <ng-select posInput [items]="salesProducts" bindLabel="Name"
                                                            bindValue="id" [multiple]="true"
                                                            placeholder="Select product(s)"
                                                            [(ngModel)]="attribute.AttributeValue"
                                                            name="selectedProduct{{i}}" [hideSelected]="true">
                                                        </ng-select>
                                                    </div>
                                                </div>
                                                <div class="row mx-0"
                                                    *ngIf="eventConfiguration.EventIdentifier == eventTypes.PrinterErrorEvent">
                                                    <div class="col-lg-4 col-md-4 col-12 pe-0 col-100">
                                                        <label class="label-section">Printer</label>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6 col-12">
                                                        <ng-select posInput [items]="printerDevices" [multiple]="true"
                                                            placeholder="Select printer device(s)" [hideSelected]="true"
                                                            bindLabel="Name" bindValue="Id"
                                                            [(ngModel)]="attribute.AttributeValue"
                                                            name="printerDevices{{i}}">
                                                        </ng-select>
                                                    </div>
                                                </div>
                                                <div class="col-9 ps-pix-15 mt-pix-5"
                                                    *ngIf="eventConfiguration.EventIdentifier ==  eventTypes.InventoryQtyMinimumReachedEvent 
                                                        || eventConfiguration.EventIdentifier == eventTypes.UnexpectedInventoryLoggedEvent
                                                        || eventConfiguration.EventIdentifier == eventTypes.InventoryQtyCriticalLevelReachedEvent">
                                                        <p-checkbox name="active{{i}}" #active="ngModel"
                                                            [(ngModel)]="attribute.AttributeValue" binary="true" label="Notify for DNI products" />
                                                </div>
                                                <!-- Inventory Prep Needed Event -->
                                                <div class="col-12"
                                                    *ngIf="eventConfiguration.EventIdentifier == eventTypes.InventoryPrepNeededEvent">
                                                    <span class="row mx-0"
                                                        *ngIf="attribute.AttributeKey === eventSubscriptionAttributeKeys.QtyStatus">
                                                        <div class="col-2 pe-0 col-100">
                                                            <label class="label-section">Status</label>
                                                        </div>
                                                        <div class="col-2 col-200">
                                                            <label class="label-section">
                                                                <input type="radio" name="inp_qtyStatus{{i}}"
                                                                    class="form-check-input"
                                                                    [(ngModel)]="attribute.AttributeValue"
                                                                    (click)="changeWithinPercentOfMinimum(subscription, 0)"
                                                                    [value]="inventoryPrepNeededQtyStatus.BelowPar">Below
                                                                Par</label>
                                                            <label class="label-section ms-pix-20">
                                                                <input type="radio" name="inp_qtyStatus{{i}}"
                                                                    class="form-check-input"
                                                                    [(ngModel)]="attribute.AttributeValue"
                                                                    (click)="changeWithinPercentOfMinimum(subscription)"
                                                                    [value]="inventoryPrepNeededQtyStatus.WithinPercentOfMinimum">Within
                                                            </label>
                                                        </div>
                                                    </span>
                                                    <span class="row mx-0" *ngIf="attribute.AttributeKey === eventSubscriptionAttributeKeys.WithinPercentOfMinimum">
                                                        <span class="col-2 p-0 col-170">
                                                            <p class="input-group">
                                                                <input class="form-control price-input" id="percentOfMin{{i}}"
                                                                    (change)="changeQtyStatus(subscription, attribute.AttributeValue)"
                                                                    name="percentOfMin{{i}}" min="0" step="1"
                                                                    posOnlyNumbers
                                                                    [(ngModel)]="attribute.AttributeValue" type="number"
                                                                    #percentOfMin="ngModel" />
                                                                <span class="input-group-addon">%</span>
                                                            </p>
                                                        </span>
                                                        <span class="col-2 pe-0 col-100">
                                                            <label class="label-section">
                                                                of Minimum </label>
                                                        </span>
                                                    </span>
                                                </div>
                                                <div class="row mx-0"
                                                    *ngIf="eventConfiguration.EventIdentifier == eventTypes.TerminalConnectedEvent">
                                                    <div class="col-lg-4 col-md-4 col-12 pe-0 col-100">
                                                        <label class="label-section">Terminals</label>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6 col-12">
                                                        <ng-select posInput [items]="terminals" [multiple]="true"
                                                            placeholder="Select terminal(s)" [hideSelected]="true"
                                                            bindLabel="TerminalName" bindValue="TerminalId"
                                                            [(ngModel)]="attribute.AttributeValue"
                                                            name="terminals{{i}}">
                                                        </ng-select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-3 col-sm-6 col-12 text-align-right" [ngClass]="{'pt-pix-12': subscription.EventSubscriptionAttributes?.length,
                                            'pb-pix-10 pt-pix-10': !subscription.EventSubscriptionAttributes?.length}">
                                            <div class="btn-group">
                                                <button
                                                    class="btn btn-primary white normal btn-sm me-pix-5 dropdown-toggle pos-button"
                                                    type="button" data-bs-toggle="dropdown">
                                                    <pos-icon [icon]="icons.eventActionPlus" class="plus-icon">
                                                    </pos-icon>
                                                    <span class="pt-pix-5 font-13">
                                                        Action <span class="caret"></span>
                                                    </span>
                                                </button>
                                                <ul aria-labelledby="eventActionMenu"
                                                    class="dropdown-menu dropdown-menu-right dropdown-min-width me-pix-5"
                                                    role="menu">
                                                    <li role="presentation" *ngFor="let deliveryType of deliveryTypes"
                                                        (click)="addConfiguration(deliveryType, subscription)"
                                                        [feature]="(deliveryType.type == eventDeliveryChannels.SMS.type ? features.SMSNotification : null)">
                                                        <a class="dropdown-item pointer" role="menuitem" tabindex="-1">
                                                            <div class="display-flex pre-wrap">
                                                                <fa-icon
                                                                    class="{{deliveryType.icon}} me-pix-5 mt-pix-3 width-12-5 text-align-center">
                                                                </fa-icon>
                                                                {{deliveryType.description}}
                                                            </div>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div class="btn-group" *ngIf="eventConfiguration.IsRecoverableEvent">
                                                <button
                                                    class="btn btn-primary white normal btn-sm me-pix-5 dropdown-toggle pos-button"
                                                    type="button" data-bs-toggle="dropdown">
                                                    <pos-icon [icon]="icons.eventActionPlus" class="plus-icon">
                                                    </pos-icon>
                                                    <span class="pt-pix-5 font-13">
                                                        Action on Recovery <span class="caret"></span>
                                                    </span>
                                                </button>
                                                <ul aria-labelledby="eventActionMenu"
                                                    class="dropdown-menu dropdown-menu-right dropdown-min-width me-pix-5"
                                                    role="menu">
                                                    <li role="presentation" *ngFor="let deliveryType of deliveryTypes"
                                                        (click)="addConfiguration(deliveryType, subscription, eventType.Recovery)"
                                                        [feature]="deliveryType.type == eventDeliveryChannels.SMS.type ? features.SMSNotification : null">
                                                        <a class="dropdown-item pointer" role="menuitem" tabindex="-1">
                                                            <div class="display-flex pre-wrap">
                                                                <fa-icon
                                                                    class="{{deliveryType.icon}} me-pix-5 mt-pix-3 width-12-5 text-align-center">
                                                                </fa-icon>
                                                                {{deliveryType.description}}
                                                            </div>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div class="btn-group">
                                                <label class="btn btn-radio normal line-height-12"
                                                    [ngClass]="subscription.IsActive ? 'active btn-primary' : 'btn-secondary'">
                                                    <input type="radio" name="percentTypeAuto" class="form-check-input"
                                                        [(ngModel)]="subscription.IsActive"
                                                        (click)="changeSubscriptionStatus(subscription)"
                                                        [value]="true">Active</label>
                                                <label class="btn btn-radio normal line-height-12"
                                                    [ngClass]="!subscription.IsActive ? 'active btn-danger' : 'btn-secondary'">
                                                    <input type="radio" name="percentTypeAuto" class="form-check-input"
                                                        [(ngModel)]="subscription.IsActive"
                                                        (click)="changeSubscriptionStatus(subscription)"
                                                        [value]="false">Inactive</label>
                                            </div>
                                            <span (click)="confirmDeleteEventSubscription(subscription, i)"
                                                class="px-pix-5">
                                                <pos-action-icon [icon]="icons.deleteWhite"></pos-action-icon>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-12 event-actions subscription-body">
                                        <ng-table [sort]="false" [paginator]="false" [columns]="cols"
                                            [data]="subscription.EventSubscriptionActions" [isReorderableTable]="true"
                                            [ReorderableColumnRow]="'row'" [search]="false" [tableStyle]="'min-width: 720px'"
                                            (onRowReorder)="gridRowReorder(subscription.EventSubscriptionActions)">
                                        </ng-table>
                                        <fieldset class="fieldset mb-pix-10 mt-pix-10 pi-0 text-align-center"
                                            *ngIf="eventConfiguration.IsRecoverableEvent">
                                            <legend class="reset-style">
                                                <span class="bold bg-theme-primary">Actions on Recovery</span>
                                            </legend>
                                            <ng-table [sort]="false" [paginator]="false" [columns]="cols"
                                                [data]="subscription.EventSubscriptionRecoveryActions"
                                                [isReorderableTable]="true" [ReorderableColumnRow]="'row'"
                                                [tableStyle]="'min-width: 720px'"
                                                [search]="false"
                                                (onRowReorder)="gridRowReorder(subscription.EventSubscriptionRecoveryActions)">
                                            </ng-table>
                                        </fieldset>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="!eventConfiguration.EventSubscriptions?.length" class="pt-pix-15">
                                <b> No subscription(s) found. </b>
                            </div>
                            <div class="clearfix"></div>
                        </div>
                    </div>
                    <div class="col-12 action-button-container page-footer">
                        <button class="btn btn-primary btn-lg btn-save pos-button" type="submit">Save</button>
                        <button class="btn btn-secondary btn-lg btn-cancel pos-button" type="button"
                            (click)="close()">Cancel</button>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <ng-template let-data="rowData" #actionName>
        <div class="row mx-0">
            <section class="text-align-center col-20 p-0">
                <span *ngIf="eventDeliveryChannels.Email.type == data.DeliveryChannel">
                    <pos-icon [icon]="icons.envelopeSolid"></pos-icon>
                </span>
                <span *ngIf="eventDeliveryChannels.TerminalMessage.type == data.DeliveryChannel">
                    <pos-icon [icon]="icons.terminalTv"></pos-icon>
                </span>
                <span *ngIf="eventDeliveryChannels.AudioNotification.type == data.DeliveryChannel">
                    <pos-icon [icon]="icons.audioNotification"></pos-icon>
                </span>
                <span *ngIf="eventDeliveryChannels.Delay.type == data.DeliveryChannel">
                    <pos-icon [icon]="icons.clock"></pos-icon>
                </span>
                <span *ngIf="eventDeliveryChannels.ExternalAPIDelivery.type == data.DeliveryChannel">
                    <pos-icon [icon]="icons.externalApiPlug"></pos-icon>
                </span>
                <span *ngIf="eventDeliveryChannels.Task.type == data.DeliveryChannel">
                    <pos-icon [icon]="icons.eventTasksCheckDouble"></pos-icon>
                </span>
                <span *ngIf="eventDeliveryChannels.OrderEntryTerminalWarning.type == data.DeliveryChannel">
                    <pos-icon [icon]="icons.eventOrderEntryTerminalWarning"></pos-icon>
                </span>
                <span *ngIf="eventDeliveryChannels.SMS.type == data.DeliveryChannel">
                    <pos-icon [icon]="icons.sms"></pos-icon>
                </span>
                <span *ngIf="eventDeliveryChannels.DJ.type == data.DeliveryChannel">
                    <pos-icon [icon]="icons.music"></pos-icon>
                </span>
                <span *ngIf="eventDeliveryChannels.PushNotification.type == data.DeliveryChannel">
                    <pos-icon [icon]="icons.bell"></pos-icon>
                </span>
                <span *ngIf="eventDeliveryChannels.Light.type == data.DeliveryChannel">
                    <pos-icon [icon]="icons.lightBulb" class="font-15"></pos-icon>
                </span>
                <span *ngIf="eventDeliveryChannels.EnableOrDisableKiosk.type == data.DeliveryChannel">
                    <pos-icon [icon]="icons.tabletAlt" class="font-15"></pos-icon>
                </span>
            </section>
            <div class="col-11 pe-0">
                {{eventDeliveryChannels.ExternalAPIDelivery.type == data.DeliveryChannel ?
                eventDeliveryChannels.ExternalAPIDelivery.description : data.DeliveryChannel}}
            </div>
        </div>
    </ng-template>

    <ng-template let-data="rowData" #descriptions>
        <span>{{data.Description}}</span>
    </ng-template>

    <ng-template let-data="rowData" #operationTemplate>
        <span (click)="editConfiguration(data)" class="">
            <pos-action-icon [icon]="icons.editWhite"></pos-action-icon>
        </span>
        <span (click)="confirmDelete(data)" class="ps-pix-2">
            <pos-action-icon [icon]="icons.deleteWhite" [additionalClasses]="['white']"></pos-action-icon>
        </span>
        <span (click)="changeActivityStatus(data)" *ngIf="!data.IsActive" class="vertical-mid ps-pix-3">
            <pos-action-icon [icon]="icons.ban" pTooltip="Click to activate" tooltipPosition="top"
                tooltipStyleClass="custom-tooltip"></pos-action-icon>
        </span>
        <span (click)="changeActivityStatus(data)" *ngIf="data.IsActive" class="vertical-mid ps-pix-3">
            <pos-action-icon [icon]="icons.checkCircleWhite" pTooltip="Click to deactivate" tooltipPosition="top"
                tooltipStyleClass="custom-tooltip">
            </pos-action-icon>
        </span>
    </ng-template>

    <pos-event-configuration-edit *ngIf="loadEdit" [id]="actionId" [subscriptionEventType]="subscriptionEventType"
        [availabelDeliveryChannels]="availabelDeliveryChannels" [eventAction]="selectedAction"
        [eventAttributes]="eventConfiguration ? eventConfiguration.EventAttributes : []"
        [eventIdentifier]="eventConfiguration ? eventConfiguration.EventIdentifier : ''"
        [(selectedDeliveryType)]="selectedDeliveryType" [users]="users" [terminals]="terminals"
        [externalApis]="externalApis" [audioDevices]="audioDevices" [audioFiles]="audioFiles"
        [lightingControllers]="lightingControllers" [audioVoices]="audioVoices" [tasks]="tasks"
        (close)="closeEventConfigurationEdit($event)" [eventSubscription]="selectedSubscription"
        [isRecoverableEvent]="eventConfiguration.IsRecoverableEvent && subscriptionEventType != eventType.Recovery">
    </pos-event-configuration-edit>
</div>
