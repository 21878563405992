<div class="print-ingredients">
    <button class="btn bg-theme-separator white normal col-100 pos-button" (click)="printIngredients()"> <pos-icon
            [icon]="icons.ingredientsInfoPrint" class="pe-pix-5"></pos-icon> Print</button>
</div>
<div class="ingredients-info">
    <ng-table #gridComponent [sort]="false" [paginator]="false" [scrollable]="true"
        [scrollHeight]="screenHeight  + 'px'" [search]="true" [columns]="ingredientsInfoColumns"
        [data]="ingredientsInfo">
    </ng-table>
    <ng-template let-data="rowData" #inventoryProductTemplate>
        <span>{{ data.InventoryProductFriendlyName ? data.InventoryProductFriendlyName: data.InventoryProduct }}</span>
    </ng-template>
</div>
