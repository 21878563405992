import { Component, OnInit, Input, OnChanges, TemplateRef, ViewChild } from '@angular/core';
import { SpinnerService, AlertsService } from 'src/app/shared';
import { GridColumn, TemplateColumn } from '@tarktech/tark-ng-utils';
import { IngredientsDietaryWarning } from '../interface/ingredients-dietary-warning'
import { IngredientsDietaryWarningService } from '../services/ingredients-dietary-warning.service';
import { forEach, orderBy, uniqBy } from 'lodash';
import { check } from 'src/app/shared/components/icon';
declare let $: any;
@Component({
  selector: 'pos-ingredients-dietary-warnings',
  templateUrl: './ingredients-dietary-warnings.component.html'
})
export class IngredientsDietaryWarningsComponent implements OnInit, OnChanges {


  @Input() productId: number;
  dietaryWarningInfo: Array<IngredientsDietaryWarning> = [];
  uniqueDietaryWarnings: Array<IngredientsDietaryWarning> = [];
  dietaryWarningInfoColumns: Array<GridColumn> = [];
  screenHeight :number;
  @ViewChild('inventoryProductDietaryWarningTemplate', { static: true }) inventoryProductDietaryWarningTemplate: TemplateRef<any>;
  public icons = {check};
  constructor(
    private ingredientsDietaryWarningService: IngredientsDietaryWarningService,
    private spinnerService: SpinnerService,
    private alertService: AlertsService
  ) { }

  ngOnInit() {
    this.configureGridColumns();
    this.screenHeight = $(window).height() - 500 < 150 ? 90 : 280;
    this.resizeWindow();
  }

  resizeWindow = () => {
    $(window).resize(() => {
      this.screenHeight = $(window).height() - 500 < 150 ? 90 : 280;
    });
  }
  ngOnChanges() {
    if (this.productId) {
      this.getIngredientsInfo(this.productId);
    }
  }
  configureGridColumns(): any {
    this.dietaryWarningInfoColumns = [
      new TemplateColumn({ HeaderText: 'Inventory Product', itemTemplate: this.inventoryProductDietaryWarningTemplate, Width: '35%' }),
      new GridColumn({ HeaderText: 'Warning', Field: 'DietaryWarning', Width: '35%' }),
      new GridColumn({ HeaderText: 'Warning Type', Field: 'DietaryWarningTypeName', Width: '35%' }),
      new GridColumn({ HeaderText: 'Size', Field: 'SalesSize', Width: '35%' })
    ] as Array<GridColumn>;
  }

  getIngredientsInfo(productId: number) {
    this.spinnerService.show();
    this.ingredientsDietaryWarningService.getIngredientsDietaryWarningInfo(productId).subscribe(
      (response: IngredientsDietaryWarning[]) => {
        this.dietaryWarningInfo = response;
        this.uniqueDietaryWarnings = uniqBy(this.dietaryWarningInfo, 'DietaryWarning');
        this.uniqueDietaryWarnings = orderBy(this.uniqueDietaryWarnings, 'DietaryWarning');
      },
      this.alertService.showApiError,
      () => {
        this.spinnerService.hide();
      }
    );
  }

  highlightRow(warning) {
    forEach(this.dietaryWarningInfo, (war) => {
      if (warning.DietaryWarning === war.DietaryWarning) {
        war.IsSelected = !war.IsSelected;
      }
      if (war.IsSelected) {
        war.color = 'bg-theme-primary';
      } else {
        war.color = '';
      }
    });
    
  }

}
