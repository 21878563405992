import { Component, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { AuthenticationService, Permissions, Levels, DomainConstants, StringUtils } from 'src/app/shared';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { ApplicationStateService } from 'src/app/shared/services/application-state.service';
import { PrintTableService } from 'src/app/shared/services/print-table.service';
import { BaseListComponent } from 'src/app/shared/components/base-list.component';
import { Messages } from 'src/app/shared/constants/ui-messages';
import { Font } from '../../interface/font';
import { GridColumn, TableComponent } from '@tarktech/tark-ng-utils';
import { FontTypeService } from '../../services/font-type.service';
import { ActivatedRoute, Router } from '@angular/router';
import { cloneDeep, remove } from 'lodash';
import { FontEditComponent } from '../font-edit/font-edit.component';
import { font } from 'src/app/shared/components/icon';

@Component({
  selector: 'pos-font-list',
  templateUrl: './font-list.component.html',
  styleUrls: ['./font-list.component.scss']
})
export class FontListComponent extends BaseListComponent<Font> implements OnInit {

  fontColumns: Array<GridColumn> = [];
  fonts: Font[] = [];
  @ViewChild('fontGrid', {static: true}) fontGrid: TableComponent;

  public icons = {
    font
  }

  permission = {
    name: Permissions.SystemConfigurationFonts,
    editLevel: Levels.Edit
  }

  constructor(protected fontService: FontTypeService,
    protected alertService: AlertsService,
    protected spinnerService: SpinnerService,
    protected modalService: ModalService,
    protected applicationStateService: ApplicationStateService,
    protected printService: PrintTableService,
    private authenticationService: AuthenticationService,
    protected route: ActivatedRoute,
    protected router: Router) {
    super(fontService, alertService, spinnerService, modalService, applicationStateService, printService, route);
    this.scrollToTop();
  }

  ngOnInit(): void {
    this.deleteSuccessMessage = Messages.FontDeleted;
    this.fontColumns = this.configureGridColumns();
    this.checkPermission();
    this.loadSubscriptions();
    this.fontGrid.context = this.gridContext;
  }

  private checkPermission(): void {
    if (!this.authenticationService.userHasPermission([{ Name: this.permission.name, Level: this.permission.editLevel }], 'any')) {
      remove(this.fontColumns, (column) => {
        return (column.Type === DomainConstants.GridColumnsTypes.EditColumn
          || column.Type === DomainConstants.GridColumnsTypes.DeleteColumn);
      });
    }
  }
  
  private loadSubscriptions(): void {
    this.data$.subscribe({
      next: (res) => {
        this.fonts = res;
      }
    });
  }

  getGridColumns(): Array<GridColumn> {
    return [
      new GridColumn({HeaderText: 'Name', Field: 'Name', IsSortable: true, Width: '90%'})
    ] as Array<GridColumn>;
  }

  editItem(id: number, data?: Font): void {
    const modalRef = this.modalService.getModalWrapper(FontEditComponent);
    const modal = modalRef.show({
      animated: false,
      class: 'vertical-center',
      initialState: {
        font: cloneDeep(data)
      }
    });
    modal.close.subscribe(res => {
      if(res && res.shouldReload){
        this.reload();
      }
    })
  }
  
  getConfirmDeleteMessage(data: Font): string {
    return StringUtils.format(Messages.ConfirmDeleteFont,
      {'font': data ? data.Name : '' }
    );
  }

  private scrollToTop(): void {
    window.scrollTo(0, 0);
  }

  close() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }
}
