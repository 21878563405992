<div class="row mx-0 mt-2 order-section__bottom__block__buttons relative"
  [ngClass]="{'pe-pix-25 ps-pix-15': functionButtonDetails.length > 12 && showMoreButtonOption, 'pe-0': functionButtonDetails.length <= 12 || !showMoreButtonOption}">
  <div class="more-operation-button btn order-section__bottom__block__buttons__btn pos-button"
    *ngIf="functionButtonDetails.length > 12 && isVisibleFunctionButtonIcon && showMoreButtonOption"
    (click)="moreOperationButtons($event)">
    <div>
      <span class="display-flex">
        <pos-icon [icon]="icons.functionButtonCaretDown"></pos-icon> {{noOfInvisibleButton}}
      </span>
      <ul class="operation-button tl-10 function-button" id="moreOperationButtons"
        [ngClass]="{'display-none-imp': !noOfInvisibleButton}">
        <li *ngFor="let functionButton of functionButtonDetails;let fnIndex = index" [hidden]="fnIndex < 12"
          [ngStyle]="{'padding': noOfInvisibleButton > 1 ? ' 5px 5px 0':'5px 5px 0px 5px'}">
          <div class="order-btn-block col-12 p-0" *ngIf="fnIndex >= 12">
            <ng-container [ngTemplateOutlet]="buttons" [ngTemplateOutletContext]="{functionButton:functionButton}">
            </ng-container>
          </div>
        </li>
      </ul>
    </div>
  </div>

  <ng-template #None let-functionButton="functionButton">
    <div [hidden]="true">
      <button class="btn order-section__bottom__block__buttons__btn pos-button">{{functionButton.Name}}</button>
    </div>
  </ng-template>

  <ng-template #ProductPrint let-functionButton="functionButton">
    <div *ngIf="showProductPrintButton" id="btnLabelPrinting" name="btnLabelPrinting"  #labelPrintingToolTip="bs-tooltip" tooltip="{{toolTipText}}">
      <button class="btn order-section__bottom__block__buttons__btn pos-button"
        permissions='[{"Name":"{{permission.orderDisableLabelPrint}}", "Level":"{{permission.accessLevel}}"}]'
       (click)="productPrint();">
        <pos-icon *ngIf="showProductPrintIcon" [icon]="icons.productPrintToggleOn"></pos-icon>
        <pos-icon *ngIf="!showProductPrintIcon" [icon]="icons.productPrintToggleOff"></pos-icon>
        {{functionButton.Name}}
      </button>
    </div>
  </ng-template>

  <ng-template #Dispatch let-functionButton="functionButton">
    <div permissions='[{"Name":"{{permission.dispatch}}", "Level":"{{permission.accessLevel}}"}]'>
      <button class="btn order-section__bottom__block__buttons__btn pos-button" (click)="dispatch();">
        <pos-icon [icon]="icons.dispatchTruck"></pos-icon>
        {{functionButton.Name}}
      </button>
    </div>
  </ng-template>

  <ng-template #Table let-functionButton="functionButton">
    <div>
      <button class="btn order-section__bottom__block__buttons__btn pos-button" (click)="getMappedLayoutWithTerminal()">
        <pos-icon [icon]="icons.tablesSolarPanel"></pos-icon>
        {{tableTerm}}
      </button>
    </div>
  </ng-template>

  <ng-template #Account let-functionButton="functionButton">
    <div>
      <!-- ng-if="IsShowAccountButton && OrderId > 0" -->
      <button class="btn order-section__bottom__block__buttons__btn pos-button" (click)="redirectToAccount(false)">
        <pos-icon [icon]="icons.accountsTablesIdBadge"></pos-icon>
        {{accountTerm ? accountTerm : functionButton.Name}}
      </button>
    </div>
  </ng-template>

  <ng-template #AddProduct let-functionButton="functionButton">
    <div>
      <button class="btn order-section__bottom__block__buttons__btn pos-button" (click)="addTheseItem()"
        [ngClass]="{'enabled-addproduct-button':canAddProduct}" [disabled]="!canAddProduct || infoMode">
        <pos-icon [icon]="icons.addProductPlusSquare"></pos-icon>
        {{functionButton.Name}}
      </button>
      <!-- ng-disabled="canAddProduct || ProductShow" scrollup -->
    </div>
  </ng-template>

  <ng-template #ToGo let-functionButton="functionButton">
    <div>
      <button class="btn order-section__bottom__block__buttons__btn pos-button"
        permissions='[{"Name":"{{permission.orderEntryToGo}}", "Level":"{{permission.accessLevel}}"}]'
        (click)="addToGoSeat()">
        <!-- permission='[{"Name":"{{$root.permissions.OrderEntryToGo}}", "Level":"{{$root.accessLevels.Access}}"}]' -->
        <pos-icon [icon]="icons.toGoShoppingBag"></pos-icon>
        {{functionButton.Name}}
      </button>
    </div>
  </ng-template>

  <ng-template #PrintCheck let-functionButton="functionButton">
    <div>
      <button class="btn order-section__bottom__block__buttons__btn pos-button" (click)="printCheckPopupOpen()">
        <pos-icon [icon]="icons.printCheckPrinting"></pos-icon>
        {{functionButton.Name}}
      </button>
    </div>
  </ng-template>

  <ng-template #Info let-functionButton="functionButton">
    <div>
      <button class="btn order-section__bottom__block__buttons__btn pos-button" (click)="showInventoryInfoOnProductButtons()"
        [ngClass]="{'bg-theme-separator':infoMode}">
        <pos-icon [icon]="icons.orderinfoInfoCircle"></pos-icon>
        {{functionButton.Name}}
      </button>
    </div>
  </ng-template>

  <ng-template #Messages let-functionButton="functionButton">
    <div>
      <button class="btn order-section__bottom__block__buttons__btn pos-button" id="btnmail" (click)="sendMail()"
        [ngClass]="{'enabled-addproduct-button': newMessage}">
        <pos-icon [icon]="icons.envelopeRegular"></pos-icon> <!-- [hidden]="messageFound" -->
        <!-- <pos-icon [icon]="icons.envelopesolid" [hidden]="!messageFound"></pos-icon> -->
        {{functionButton.Name}}
      </button>
    </div>
  </ng-template>

  <ng-template #ServeOrder let-functionButton="functionButton">
    <div>
      <button class="btn order-section__bottom__block__buttons__btn pos-button" (click)="onServeOrderClick()">
        <pos-icon [icon]="icons.serveOrderCheckSquare"></pos-icon>
        {{functionButton.Name}}
      </button>
    </div>
  </ng-template>

  <ng-template #ScheduledOrder let-functionButton="functionButton">
    <div>
      <button class="btn order-section__bottom__block__buttons__btn pos-button" (click)="scheduleOrder();"
      permissions='[{"Name":"{{permission.orderSchedule}}", "Level":"{{permission.accessLevel}}"}]'>
        <pos-icon [icon]="icons.scheduledOrderClock"></pos-icon>
        {{functionButton.Name}}
      </button>
    </div>
  </ng-template>

  <ng-template #RequireName let-functionButton="functionButton">
    <div [hidden]="!isShowNameDisableButton" >
      <button class="btn order-section__bottom__block__buttons__btn pos-button"
        permissions='[{"Name":"{{permission.orderRequiredName}}", "Level":"{{permission.accessLevel}}"}]'
        (click)="requireNameOnOrderTemporaryDisable()">
        <pos-icon *ngIf="isDisableRequireNameOnOrder" [icon]="icons.productPrintToggleOn"></pos-icon>
        <pos-icon *ngIf="!isDisableRequireNameOnOrder" [icon]="icons.productPrintToggleOff"></pos-icon>
        {{functionButton.Name}}
      </button>
    </div>
  </ng-template>

  <ng-template #LoyalAccountTerm let-functionButton="functionButton">
    <div>
      <button class="btn order-section__bottom__block__buttons__btn pos-button" (click)="redirectToAccount(true)">
        <pos-icon [icon]="icons.loyalAccountTermCrown"></pos-icon>
        {{settingParam.LoyalAccountTerm}}
      </button>
      <pos-set-account #setAccount [hidden]="true" [orderId]="orderDetail.Id.Value" [isTypeAccount]="true"></pos-set-account>
    </div>
  </ng-template>

  <ng-template #Filter let-functionButton="functionButton">
    <div>
      <button class="btn order-section__bottom__block__buttons__btn pos-button" id="btnFilter" (click)="filterProducts()"
        [ngClass]="{'bg-theme-separator': filterMode}">
        <pos-icon [icon]="icons.functionButtonFilter"></pos-icon>
        {{functionButton.Name}}
      </button>
    </div>
  </ng-template>

  <ng-template #OrderKiosk let-functionButton="functionButton">
    <button class="btn order-section__bottom__block__buttons__btn pos-button" id="btnFilter" (click)="openOrderKiosk()"
      [ngClass]="{'bg-theme-separator': filterMode}">
      <pos-icon [icon]="icons.tabletAlt"></pos-icon>
      {{functionButton.Name}}
    </button>
  </ng-template>

  <ng-template #buttons let-functionButton="functionButton">
    <div *ngIf="functionButton.Selection == 'ProductPrint'">
      <ng-container [ngTemplateOutlet]="ProductPrint" [ngTemplateOutletContext]="{functionButton:functionButton}">
      </ng-container>
    </div>
    <div *ngIf="functionButton.Selection == 'Dispatch'">
      <ng-container [ngTemplateOutlet]="Dispatch" [ngTemplateOutletContext]="{functionButton:functionButton}">
      </ng-container>
    </div>
    <div *ngIf="functionButton.Selection == 'ScheduleOrder'">
      <ng-container [ngTemplateOutlet]="ScheduleOrder" [ngTemplateOutletContext]="{functionButton:functionButton}">
      </ng-container>
    </div>
    <div *ngIf="functionButton.Selection == 'Account'">
      <ng-container [ngTemplateOutlet]="Account" [ngTemplateOutletContext]="{functionButton:functionButton}">
      </ng-container>
    </div>
    <div *ngIf="functionButton.Selection == 'AddProduct'">
      <ng-container [ngTemplateOutlet]="AddProduct" [ngTemplateOutletContext]="{functionButton:functionButton}">
      </ng-container>
    </div>
    <div *ngIf="functionButton.Selection == 'ToGo'">
      <ng-container [ngTemplateOutlet]="ToGo" [ngTemplateOutletContext]="{functionButton:functionButton}">
      </ng-container>
    </div>
    <div *ngIf="functionButton.Selection == 'PrintCheck'">
      <ng-container [ngTemplateOutlet]="PrintCheck" [ngTemplateOutletContext]="{functionButton:functionButton}">
      </ng-container>
    </div>
    <div *ngIf="functionButton.Selection == 'Info'">
      <ng-container [ngTemplateOutlet]="Info" [ngTemplateOutletContext]="{functionButton:functionButton}">
      </ng-container>
    </div>
    <div *ngIf="functionButton.Selection == 'Messages'">
      <ng-container [ngTemplateOutlet]="Messages" [ngTemplateOutletContext]="{functionButton:functionButton}">
      </ng-container>
    </div>
    <div *ngIf="functionButton.Selection == 'ServeOrder'">
      <ng-container [ngTemplateOutlet]="ServeOrder" [ngTemplateOutletContext]="{functionButton:functionButton}">
      </ng-container>
    </div>
    <div *ngIf="functionButton.Selection == 'ScheduledOrder'">
      <ng-container [ngTemplateOutlet]="ScheduledOrder" [ngTemplateOutletContext]="{functionButton:functionButton}">
      </ng-container>
    </div>
    <div *ngIf="functionButton.Selection == 'RequireName'">
      <ng-container [ngTemplateOutlet]="RequireName" [ngTemplateOutletContext]="{functionButton:functionButton}">
      </ng-container>
    </div>
    <div *ngIf="functionButton.Selection == 'LoyalAccountTerm'">
      <ng-container [ngTemplateOutlet]="LoyalAccountTerm" [ngTemplateOutletContext]="{functionButton:functionButton}">
      </ng-container>
    </div>
    <div *ngIf="functionButton.Selection == 'Filter'">
      <ng-container [ngTemplateOutlet]="Filter" [ngTemplateOutletContext]="{functionButton:functionButton}">
      </ng-container>
    </div>
    <div *ngIf="functionButton.Selection == 'None'">
      <ng-container [ngTemplateOutlet]="None" [ngTemplateOutletContext]="{functionButton:functionButton}">
      </ng-container>
    </div>
    <div *ngIf="functionButton.Selection == 'Table'">
      <ng-container [ngTemplateOutlet]="Table" [ngTemplateOutletContext]="{functionButton:functionButton}">
      </ng-container>
    </div>
    <div *ngIf="functionButton.Selection == 'OrderKiosk'">
      <ng-container [ngTemplateOutlet]="OrderKiosk" [ngTemplateOutletContext]="{functionButton:functionButton}">
      </ng-container>
    </div>
  </ng-template>


  <!-- <div class="order-btn-block col-sm-3"permission='[{"Name":"{{$root.permissions.OrderEntryReceiptReprint}}", "Level":"{{$root.accessLevels.Access}}"}]'>
                  <button class="btn order-section__bottom__block__buttons__btn text-ellipsis">Re Print</button> 
              </div>  -->
  <div *ngFor="let functionButton of functionButtonDetails;let buttonIndex = index" [hidden]="!(buttonIndex < 12)" 
    class="order-btn-block col-md-2 col-6"> 
    <div>
      <ng-container [ngTemplateOutlet]="buttons" [ngTemplateOutletContext]="{functionButton:functionButton}">
      </ng-container>
    </div>
    <div class="clearfix visible-lg" *ngIf="(buttonIndex + 1) % 6 == 0"></div>
    <div class="clearfix visible-md" *ngIf="(buttonIndex + 1) % 6 == 0"></div>
    <div class="clearfix visible-sm" *ngIf="(buttonIndex + 1) % 6 == 0"></div>
    <div class="clearfix visible-xs" *ngIf="(buttonIndex + 1) % 2 == 0"></div>
  </div>
</div>
