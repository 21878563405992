<div>
  <button class="close me-pix-10" (click)="cancel()">&times;</button>
  <h4 class="modal-content__heading">Gift Card Management</h4>
</div>
<form #giftCardInfForm="ngForm" autocomplete="off">
  <div class="p-pix-15 pb-0 pos-modal-body">
    <div class="overflow-hidden row ">
      <div class="col-lg-8 col-md-8 col-sm-12 col-12 ps-0 ps-md-2">
        <div class="row g-0 col-12 ">
          <div class="col-lg-2 col-md-2 col-sm-2 col-6 bold font-18 mt-pix-8">
            <span class="font-20 pointer">
              <pos-icon [icon]="icons.giftCard" class="pointer">
              </pos-icon>
            </span>
            <span class="p-0 bold font-18">
              {{(giftCardDetails && giftCardDetails.CardNumber ? giftCardDetails.CardNumber : '')}}
            </span>
          </div>
          <div class="w-fit-content bold font-18 mt-pix-10 flex-grow  text-end">
            <span>
              Balance
            </span>
            <span class="p-0 bold font-18">
              {{(giftCardDetails && giftCardDetails.CurrentBalance ? giftCardDetails.CurrentBalance : 0) |
              tark_currency}}
            </span>
          </div>
          <div class="w-fit-content justify-content-end row g-0 flex-grow-2">
            <div class="col-250 float-end ps-2 mb-1 pe-0">
              <div class="btn btn-lg text-center normal width-full bg-theme-separator text-nowrap pointer"
                (click)="addToBalance()">
                <span>
                  <span>{{currencySymbol}}</span>
                </span> Add to Balance
              </div>
            </div>
            <div class="col-250 float-end ps-2 mb-1 pe-0">
              <div class="btn btn-lg text-center normal width-full bg-theme-separator pointer text-nowrap"
                (click)="switchCard()">
                <span>
                  <pos-icon [icon]="icons.switchCard">
                  </pos-icon>
                </span> Switch Cards
              </div>
            </div>
          </div>
        </div>

        <div class="col-12  pe-0">
          <div class="col-12 mt-pix-10 p-0">
            <!-- modal-content__section -->
            <ng-table #gridComponent [paginator]="false" [search]="false" [scrollable]="true" [rowClass]="'Color'"
              [selectionMode]="'single'" (rowSelect)="viewOrderSummary($event)" [columns]="giftCardHistoryColumns"
              [styleClass]="'no-outline'" [scrollHeight]="screenHeight.toString()" [data]="giftCardHistory">
            </ng-table>
            <ng-template let-data="rowData" #transactionTemplate>
              <div>{{data.TransactionType}} <span *ngIf="data.PaymentType">({{data.PaymentType}})</span></div>
              <div class="ps-pix-10" *ngIf="data.OrderId"> Order #{{data.OrderId}} </div>
              <div class="ps-pix-10" *ngIf="data.Memo"> {{data.Memo}} </div>
            </ng-template>
            <ng-template let-data="rowData" #orderLinkTemplate>
              <span class="width-full">
                {{data.OrderId}}
              </span>
            </ng-template>
            <ng-template let-data="rowData" #transactionAmountTemplate>
              <span>
                {{data.TransactionAmount | tark_currency}}
              </span>
            </ng-template>
            <ng-template let-data="rowData" #balanceTemplate>
              <span>
                {{data.Balance | tark_currency}}
              </span>
            </ng-template>
            <ng-template let-data="rowData" #deleteTemplate>
              <div *ngIf="data.TransactionType == giftCardTransactionType.GiftCardRecharge"
                (click)="confirmDelete(data, $event)">
                <pos-action-icon [icon]="icons.deleteWhite"></pos-action-icon>
              </div>
            </ng-template>
          </div>
        </div>

      </div>
      <div class="col-lg-4 col-md-4 col-sm-6 col-12 pl-custom-pix-sm-0">
        <div class="col-12 p-0">
          <pos-read-only-order-summary [orderId]="selectedOrderId" [isShowBlankOrder]="false">
          </pos-read-only-order-summary>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 action-button-container popup-footer pb-pix-10">
    <button class="btn-primary btn-lg btn btn-save pos-button" (click)="printGiftCardBalance()" type="button"
      value="Save">Print</button>
    <button class="btn-secondary btn-lg btn btn-cancel-modal pos-button" (click)="cancel()" type="button" value="Cancel">Close</button>
  </div>
  <div class="clearfix"></div>
</form>
