<pos-edit-wrapper heading="{{ id > 0 ? 'Edit' : 'Add' }} Hardware Configuration" (close)="cancelHardware()" [isPopup]="false"
    [headingIcon]="icons.hardwareToolbox">
    <form (ngSubmit)="saveHardware(hardwareForm.valid)" #hardwareForm="ngForm" class="edit-form">
        <context-provider provide="props">
            <div class="col-12 pos-page-body content-scroll border-top">
                <div class="col-12">
                    <pos-row>
                        <pos-column>
                            <pos-form-field label="Device Type" [fieldInfo]="fieldInfoMessages.HardwareDeviceType"
                                [validationMessages]="{required: 'Please select device type.'}">
                                <ng-select posInput [items]="posHardwareTypes" [hideSelected]="true" appendTo="body"
                                    [ngClass]="{'select-disabled':hardware.Id != 0}" bindLabel="Description"
                                    bindValue="Type" placeholder="Select device type" [disabled]="hardware.Id > 0"
                                    [(ngModel)]="hardware.Type"
                                    (change)="hardware.TypeIdentifier = null; hardwareCategoryChanged(hardware)"
                                    name="deviceType" #deviceType="ngModel" [required]="true"></ng-select>
                            </pos-form-field>
                        </pos-column>

                        <pos-column>
                            <pos-form-field label="Device Model"
                                [validationMessages]="{required: 'Please select device model.'}">
                                <ng-select posInput [items]="filteredHardwareTypes" [hideSelected]="true" appendTo="body"
                                    [ngClass]="{'select-disabled':hardware.Id != 0}" bindLabel="Name"
                                    bindValue="Identifier" placeholder="Select device model"
                                    [disabled]="hardware.Id > 0" [(ngModel)]="hardware.TypeIdentifier"
                                    (change)="setHardwareProperties(hardware)" name="deviceModel" #deviceModel="ngModel"
                                    [required]="true"></ng-select>
                            </pos-form-field>
                        </pos-column>

                        <pos-column>
                            <pos-form-field label="Device Name" [fieldInfo]="fieldInfoMessages.HardwareDeviceName"
                                [validationMessages]="{required: 'Please select device name.'}">
                                <input posInput class="form-control" placeholder="Enter device name" id="deviceName"
                                    name="deviceName" [(ngModel)]="hardware.Name" [required]="true" type="text"
                                    #deviceName="ngModel" />
                            </pos-form-field>
                        </pos-column>

                        <pos-column>
                            <pos-form-field label="Asset Tag">
                                <input posInput class="form-control" placeholder="Enter asset tag" id="assetTag"
                                    name="assetTag" [(ngModel)]="hardware.AssetTag" type="text" #assetTag="ngModel" />
                            </pos-form-field>
                        </pos-column>

                        <pos-column>
                            <pos-form-field label="HIC" [fieldInfo]="fieldInfoMessages.HardwareHIC"
                                [validationMessages]="{required: 'Please select HIC.'}">
                                <ng-select posInput [items]="hardwareInterfaceComputers" [hideSelected]="true"
                                    appendTo="body" bindLabel="Name" bindValue="Id" placeholder="Select HIC"
                                    [(ngModel)]="hardware.HICId" name="hic" #hic="ngModel" [required]="true">
                                </ng-select>
                            </pos-form-field>
                        </pos-column>

                        <pos-column xs="12" class="p-2 overflow-auto"
                            [hidden]="(!hardwareProperties || !hardwareProperties.length) || (hardware.TypeIdentifier == '00950C7F-CF93-4645-B86A-792A60317D49')">
                            <table class="table table-border-none mb-0" style="width: fit-content;">
                                <tbody>
                                    <tr class="text-orange">
                                        <th class="pt-pix-10-important" style="min-width: 200px ; max-width: 300px">
                                            <span class="theme-separator">Property Name</span></th>
                                        <th class="pt-pix-10-important" style="min-width: 200px ; max-width: 300px">
                                            <span class="theme-separator">Property Value</span></th>
                                    </tr>
                                    <tr *ngFor="let property of hardwareProperties"
                                        [hidden]="property.Name == lightHardwareProperties.LightsInfo">
                                        <td class="grey" style="min-width: 200px ; max-width: 300px">
                                            <label
                                                class="edit-form__section__label required">{{property.Description}}</label>
                                        </td>
                                        <td class="col-300" style="min-width:250px; max-width: 300px">
                                            <div
                                                [ngClass]="{'has-error': (property.invalid && hardwareForm.submitted)}">
                                                <pos-editor [submit]="hardwareForm.submitted"
                                                    [propertyValues]="property.AvailableValues"
                                                    [(propertyValue)]="property.ValueObject.Value" [required]="true"
                                                    [propertyName]="property.ValueObject.Name"
                                                    [controlType]="property.AvailableValueType" [bindLabel]="'Value'"
                                                    [bindValue]="'Key'"
                                                    [propertyPlaceHolder]="'Select ' + property.Description"
                                                    (changeValue)="property.ValueObject.Value = $event"
                                                    [requiredMessage]="'Please specify value for ' + property.Description + '.'">
                                                </pos-editor>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </pos-column>
                    </pos-row>
                </div>
                <div class="col-12 form-group "
                    *ngIf="hardware.TypeIdentifier == '00950C7F-CF93-4645-B86A-792A60317D49'">
                    <pos-nanoleaf-hardware-config [typeIdentifier]="'00950C7F-CF93-4645-B86A-792A60317D49'"
                        [HICId]="hardware.HICId" [hardwareId]="id" [hardwareProperties]="hardwareProperties"
                        [isValidForm]="hardwareForm.valid" [isSubmitted]="hardwareForm.submitted" (close)="setLightDetails($event)">
                    </pos-nanoleaf-hardware-config>
                </div>
                <div class="col-12 ps-pix-5 section-border" *ngIf="hardware.Type === 'KitchenPrinter'">
                    <pos-kitchen-printer-config (splitConfig)="splitConfig($event)"
                        [splitConfiguration]="splitConfigValue" [subaccountTerm]="settingParam.SubaccountTerm">
                    </pos-kitchen-printer-config>
                </div>
                <div class="col-12 ps-pix-5 section-border"
                    *ngIf="hardware.Type === 'LightingController' && hardware.TypeIdentifier != '00950C7F-CF93-4645-B86A-792A60317D49' && id > 0">
                    <pos-light-test [hardwareId]="id"></pos-light-test>
                </div>

            </div>
            <div class="col-12 action-button-container page-footer">
                <button type="button" class="btn bg-theme-separator btn-lg white-hover normal me-pix-5 pos-button"
                    *ngIf="hardware.Type == 'LabelPrinter' && id > 0" (click)="testLabelPrint()">
                    <pos-icon [icon]="icons.hardwareLabelPrint"></pos-icon> Test Label Print
                </button>
                <button class="btn btn-primary btn-lg normal btn-save pos-button" scrollup type="submit" value="Save">Save</button>
                <button class="btn btn-secondary btn-lg normalb btn-cancel pos-button" (click)="cancelHardware()" scrollup
                    type="button" value="Cancel">Cancel</button>
            </div>
        </context-provider>
    </form>
</pos-edit-wrapper>
