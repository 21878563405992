import { Injectable } from "@angular/core";
import { LoyaltyFormAttribute } from "../interfaces/loyalty-form-attribute";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { BaseCrudService } from "src/app/shared/services/base-crud.service";

@Injectable({
    providedIn: 'root'
})
export class LoyaltyFormAttributeService extends BaseCrudService<LoyaltyFormAttribute> {

    constructor(private httpClient: HttpClient) {
        super('loyalty-form-attribute', httpClient);
    }

    newLoyaltyFormAttribute(): LoyaltyFormAttribute {
        return {
            Id: 0,
            AccountAttributeTypeId: null,
            Description: '',
            Ordinal: 1,
            AccountAttributeType: null,
            IsSystemDefined: false,
            Name: ''
        };
    }

    updateLoyaltyFormAttributeOrdinal(loyaltyFromAttributes: LoyaltyFormAttribute[]) : Observable<any>{
        return this.httpClient.put(`${this.apiEndpoint}/ordinal`, loyaltyFromAttributes);
    }
}
