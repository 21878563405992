<pos-edit-wrapper (close)="cancel()" [isPopup]="false" [headingIcon]="icons.fileAlt"
    heading="Online Ordering - Terms & Conditions">

    <form #termsConditionsForm="ngForm" autocomplete="off" (ngSubmit)="save(termsConditionsForm.valid)">
        <div class="col-12 container-fluid border-top terms-conditions">
            <ul class="nav nav-tabs inner-tab pos-nav-tabs">
                <li [ngClass]="{'active': selectedTab == tabList.PrivacyPolicy }">
                    <a (click)="selectedTab = tabList.PrivacyPolicy" class="blue">
                        <span>
                            <pos-icon [icon]="icons.fileArchive" class="theme-separator"></pos-icon>
                        </span><span> Privacy Policy </span></a>
                </li>
                <li [ngClass]="{'active': selectedTab == tabList.TermsAndConditions }">
                    <a (click)="selectedTab = tabList.TermsAndConditions" class="blue">
                        <span>
                            <pos-icon [icon]="icons.fileInvoice" class="theme-separator"></pos-icon>
                        </span><span> Terms & Conditions </span></a>
                </li>
                <li [ngClass]="{'active': selectedTab == tabList.RefundPolicy }">
                    <a (click)="selectedTab = tabList.RefundPolicy" class="blue">
                        <span>
                            <pos-icon [icon]="icons.fileInvoiceDollar" class="theme-separator"></pos-icon>
                        </span><span> Refund Policy </span></a>
                </li>
            </ul>

            <div *ngIf="selectedTab == tabList.RefundPolicy" class="col-12 pos-tab-body overflow-tab pt-pix-5">
                <pos-row>
                    <pos-column>
                        <pos-form-field label="Refund Policy" inputStyle="col-12">
                            <p-editor name="refundPolicy" id="refundPolicy" [(ngModel)]="refundPolicy.ParamValue" [style]="{ height: contentHeight }" />
                        </pos-form-field>
                    </pos-column>
                </pos-row>
            </div>
            <div *ngIf="selectedTab == tabList.PrivacyPolicy" class="col-12 pos-tab-body overflow-tab pt-pix-5">
                <pos-row>
                    <pos-column>
                        <pos-form-field label="Privacy Policy" inputStyle="col-12">
                            <p-editor name="privacyPolicy" id="privacyPolicy" [(ngModel)]="privacyPolicy.ParamValue"  [style]="{ height: contentHeight }" />
                        </pos-form-field>
                    </pos-column>
                </pos-row>
            </div>
            <div *ngIf="selectedTab == tabList.TermsAndConditions" class="col-12 pos-tab-body overflow-tab pt-pix-5">
                <pos-row>
                    <pos-column>
                        <pos-form-field label="Terms & Conditions" inputStyle="col-12">
                            <p-editor  name="termsAndConditions" id="termsAndConditions" [(ngModel)]="termsAndConditions.ParamValue"  [style]="{ height: contentHeight }" />
                        </pos-form-field>
                    </pos-column>
                </pos-row>
            </div>
        </div>

        <div class="col-12 action-button-container page-footer">
            <button type="submit" *ngIf="hasPermission" value="Save" class="btn-primary btn-lg btn btn-save pos-button"
                scrollup>Save</button>
            <button type="button" class="btn-secondary btn-lg btn btn-cancel pos-button" (click)="cancel()"
                value="Cancel" scrollup>Cancel</button>
        </div>
    </form>

</pos-edit-wrapper>
