<pos-list-wrapper [headingIcon]="icons.exclamationTriangle" heading="Dietary Warnings" (close)="close()">
    <div class="row">
        <div class="col-12">
            <ng-table [columns]="dietaryWarningColumns"
                [exportFilename]="'dietary-warnings'" [data]="dietaryWarningList">
            </ng-table>

            <ng-template #headerTemplate>
                <div class="text-center align-content-center">
                    <pos-action-icon (click)="editItem(0)" class="inline-block" [icon]="icons.plusWhite" iconText="Add">
                    </pos-action-icon>
                </div>
            </ng-template>

            <ng-template let-data="rowData" #operationTemplate>
                <span (click)="editItem(data.Id, data)" class="pe-pix-3" tooltip title="Edit">
                    <pos-action-icon [icon]="icons.editWhite"></pos-action-icon>
                </span>
            </ng-template>

            <ng-template let-data="rowData" #IsOverridableTemplate>
                <div *ngIf="data.IsOverridable" class="green text-center vertical-mid font-13">
                    <pos-icon [icon]="icons.check"></pos-icon>
                </div>
            </ng-template>

            <ng-template let-data="rowData" #makeTableColorTemplate>
                <div class="col-w-10" [ngStyle]="{'background-color': data.MakeTableColor, 'height': '38px' }">
                </div>
                <div></div>
              </ng-template>
        </div>
    </div>
</pos-list-wrapper>
