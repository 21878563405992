import { Component, Input } from '@angular/core';
import { AlertsService, ColorUtilityService, DomainConstants, Messages, ModalComponent, OrderKioskScreenService, RuntimeConstants, SpinnerService } from 'src/app/shared';
import { ButtonConfig, GridConfig, KioskScreen, KioskScreenChoice, LeftPanelConfig, RightPanelConfig } from '../../interfaces';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs';
import { Dictionary, flatMap, groupBy, remove } from 'lodash';
import { OrderKioskConfigService, OrderKioskScreenChoiceService } from '../../services';
import { KeyValue } from '@angular/common';
import { moveItemInArray } from '@angular/cdk/drag-drop';
declare let $: any;

@Component({
  selector: 'pos-rearrange-kiosk-buttons',
  templateUrl: './rearrange-kiosk-buttons.component.html',
  styleUrls: ['./rearrange-kiosk-buttons.component.scss']
})
export class RearrangeKioskButtonsComponent extends ModalComponent {

  @Input() kioskScreenId: number;
  kioskScreen: KioskScreen;
  imageRootPath = RuntimeConstants.IMAGE_BASE_PATH + "/order-kiosk/buttons/";
  screenHeight = (window.innerHeight > 760 ? window.innerHeight - 350 : window.innerHeight - 200);
  selectedTab = null;
  groupedButtons: Dictionary<Array<KioskScreenChoice>>;
  gridConfig: GridConfig;
  buttonConfig: ButtonConfig;
  buttonWidth: number = 100;
  templateTypes = DomainConstants.KioskScreenTemplateTypes;
  secondaryButtonConfig: ButtonConfig;
  kioskScreenConfigProperties = DomainConstants.KioskScreenConfigProperties;
  leftPanelConfig: LeftPanelConfig;
  rightPanelConfig: RightPanelConfig;
  constructor(private spinnerService: SpinnerService,
    private alertService: AlertsService,
    private kioskScreenService: OrderKioskScreenService,
    private orderKioskScreenChoiceService: OrderKioskScreenChoiceService,
    private orderKioskConfigService: OrderKioskConfigService,
    private colorUtilityService: ColorUtilityService,
    modalRef: BsModalRef
  ) {
    super(modalRef);
    this.kioskScreen = kioskScreenService.newScreen();
    this.gridConfig = orderKioskConfigService.newGridConfig();
    this.buttonConfig = orderKioskConfigService.newButtonConfig();
    this.secondaryButtonConfig = orderKioskConfigService.newButtonConfig(50, 200);
    this.leftPanelConfig = orderKioskConfigService.newLeftPanelConfig();
    this.rightPanelConfig = orderKioskConfigService.newRightPanelConfig();
  }

  ngOnInit() {
    this.getScreenDetails();
  }

  originalOrder = (firstKey: KeyValue<number, string>, secondKey: KeyValue<number, string>): number => {
    return 0;
  }

  getScreenDetails() {
    this.spinnerService.show();
    this.kioskScreenService.get(this.kioskScreenId)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res: KioskScreen) => {
          this.kioskScreen = res;
          this.setConfigProperties();
          this.removeSecondaryButtonsIfHidden();
          this.createButtonsGroupByConfig();
        }, error: this.alertService.showApiError
      });
  }

  private setConfigProperties() {
    const gridConfig = this.kioskScreen.KioskScreenConfigs.find(x => x.Name == this.kioskScreenConfigProperties.Grid);
    this.gridConfig = gridConfig ? JSON.parse(gridConfig.Config) : this.orderKioskConfigService.newGridConfig();

    const buttonConfig = this.kioskScreen.KioskScreenConfigs.find(x => x.Name == this.kioskScreenConfigProperties.ButtonConfig);
    if (buttonConfig) {
      this.buttonConfig = buttonConfig ? JSON.parse(buttonConfig.Config) : this.orderKioskConfigService.newButtonConfig();
      this.buttonConfig.TextBackground = this.colorUtilityService.convertColorToRgba(this.buttonConfig.TextBackground, this.buttonConfig.Opacity);
    }

    this.buttonWidth = this.gridConfig.NoOfCols ? $('.buttons-body').width() / this.gridConfig.NoOfCols - 26 : this.buttonConfig.Width;

    if (this.kioskScreen.TemplateType == this.templateTypes.FluidGridLayout) {
      const secondaryButtonConfig = this.kioskScreen.KioskScreenConfigs.find(x => x.Name == this.kioskScreenConfigProperties.SecondaryButtonConfig);
      if (secondaryButtonConfig) this.secondaryButtonConfig = JSON.parse(secondaryButtonConfig.Config);

      this.buttonWidth = this.buttonConfig.Width ?? this.buttonWidth;
    }

    if (this.kioskScreen.TemplateType == this.templateTypes.SplitScreenLayout || this.kioskScreen.TemplateType == this.templateTypes.WelcomeScreenLayout) {
      const leftPanelConfig = this.kioskScreen.KioskScreenConfigs.find(x => x.Name == this.kioskScreenConfigProperties.LeftPanelConfig);
      if (leftPanelConfig) this.leftPanelConfig = JSON.parse(leftPanelConfig.Config);

      const rightPanelConfig = this.kioskScreen.KioskScreenConfigs.find(x => x.Name == this.kioskScreenConfigProperties.RightPanelConfig);
      if (rightPanelConfig) this.rightPanelConfig = JSON.parse(rightPanelConfig.Config);
    }

    if (this.kioskScreen.TemplateType == this.templateTypes.FixedGridLayout) {
      const timeout = setTimeout(() => {
        this.buttonWidth = this.gridConfig.NoOfCols ? $('.buttons-body').width() / this.gridConfig.NoOfCols - 20 : this.buttonConfig.Width;
        clearTimeout(timeout);
      });
    }
  }

  private removeSecondaryButtonsIfHidden() {
    const secondaryButtonConfig = this.kioskScreen.KioskScreenConfigs.find(x => x.Name == this.kioskScreenConfigProperties.SecondaryButtonConfig);
    if (secondaryButtonConfig) {
      const buttonConfig: ButtonConfig = JSON.parse(secondaryButtonConfig.Config);
      if (!buttonConfig.IsShow) {
        remove(this.kioskScreen.KioskScreenChoices, x => x.ScreenConfig.Name == DomainConstants.KioskScreenConfigTypes.SecondaryButtons);
      }
    }
  }

  private createButtonsGroupByConfig() {
    this.groupedButtons = groupBy(this.kioskScreen.KioskScreenChoices, x => x.ConfigId);
    const firstKey = Object.keys(this.groupedButtons)[0];
    this.selectedTab = this.groupedButtons[firstKey][0].ScreenConfig.Name;
  }

  saveOrdinal() {
    this.spinnerService.show();
    this.kioskScreen.KioskScreenChoices = flatMap(Object.values(this.groupedButtons));
    this.orderKioskScreenChoiceService.updateButtonsOrdinal(this.kioskScreen)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: () => {
          this.alertService.renderSuccessMessage(Messages.KioskButtonsOrdinalUpdated);
          this.hide({ isReload: true });
        }, error: this.alertService.showApiError
      });
  }

  dragEntered(event, buttons) {
    const phContainer = event.container.element.nativeElement;
    const phElement = phContainer.querySelector('.cdk-drag-placeholder');
    phContainer.removeChild(phElement);
    phContainer.parentElement.insertBefore(phElement, phContainer);
    moveItemInArray(buttons, event.item.data, event.container.data);
  }

  onClose() {
    this.hide({});
  }
}
