import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { AlertsService, DomainConstants, RuntimeConstants, SpinnerService, addKioskButton } from 'src/app/shared';
import { ButtonConfig, KioskScreenChoice, KioskScreenConfig } from 'src/app/order-kiosk/interfaces';
import { ScreenConfig } from 'src/app/order-kiosk/interfaces/config/screen-config';
import { OrderKioskConfigService } from 'src/app/order-kiosk/services/order-kiosk-config.service';
import { GridConfig } from 'src/app/order-kiosk/interfaces/config/grid-config';
import { ActivatedRoute } from '@angular/router';
import { OrderKioskScreenChoiceService } from 'src/app/order-kiosk/services';
import { finalize } from 'rxjs/operators';
import { KioskDesignerGridComponent } from '../../kiosk-designer-grid';
declare let $: any;

@Component({
  selector: 'pos-kiosk-fixed-grid-layout-template',
  templateUrl: './kiosk-fixed-grid-layout-template.component.html',
  styleUrls: ['./kiosk-fixed-grid-layout-template.component.scss']
})
export class KioskFixedGridLayoutTemplateComponent implements OnInit, AfterViewInit {
  @Input() screenConfigs: Array<KioskScreenConfig> = [];
  @Input() screenId: number;
  @Input() screenButtons: Array<KioskScreenChoice> = [];
  @ViewChild('designerGrid') designerGridComponnet: KioskDesignerGridComponent;
  @ViewChild('scrollableDiv') scrollableDiv: ElementRef;
  isDesignMode = false;
  screenConfig: ScreenConfig;
  buttonConfig: ButtonConfig;
  grid: GridConfig;
  imageRootPath = RuntimeConstants.IMAGE_BASE_PATH + "/order-kiosk/";
  invoiceHeight: number;
  templateType = DomainConstants.KioskScreenTemplateTypes.FixedGridLayout;
  designerGridId = 1;
  icons = {
    addKioskButton
  }

  constructor(private orderKioskConfigService: OrderKioskConfigService,
    private orderKioskScreenChoiceService: OrderKioskScreenChoiceService,
    private spinnerService: SpinnerService,
    private alertService: AlertsService,
    private route: ActivatedRoute
  ) {
    this.isDesignMode = route.snapshot.params.id;
    this.screenConfig = orderKioskConfigService.newScreenConfig();
    this.grid = orderKioskConfigService.newGridConfig();
    this.buttonConfig = orderKioskConfigService.newButtonConfig(null, 0);
  }

  ngOnInit(): void {
    this.prepareConfigs();
    this.calculateInvoiceHeight();
    this.resizeWindow();
  }

  ngAfterViewInit(): void {
    this.calculateInvoiceHeight();
  }
  
  calculateInvoiceHeight() {
    this.invoiceHeight = this.orderKioskConfigService.calculateInvoiceHeight(this.screenConfig) - (this.isDesignMode ? 30 : 0);
  }

  prepareConfigs() {
    const screenConfig = this.orderKioskConfigService.parseConfigProperties(DomainConstants.KioskScreenConfigProperties.ScreenConfig, this.screenConfigs);
    if (screenConfig) this.screenConfig = screenConfig;

    const grid = this.orderKioskConfigService.parseConfigProperties(DomainConstants.KioskScreenConfigProperties.Grid, this.screenConfigs);
    if (grid) this.grid = grid;

    const buttonConfig = this.orderKioskConfigService.parseConfigProperties(DomainConstants.KioskScreenConfigProperties.ButtonConfig, this.screenConfigs);
    if (buttonConfig) this.buttonConfig = buttonConfig;
  }

  resizeWindow() {
    $(window).resize(() => {
      this.calculateInvoiceHeight();
    });
  }

  addKioskScreenChoice() {
    this.spinnerService.show();
    const kioskChoice = this.orderKioskScreenChoiceService.newScreenChoice();
    kioskChoice.ScreenId = this.screenId;
    kioskChoice.ConfigId = this.screenButtons?.[0]?.ConfigId;
    kioskChoice.Ordinal = Math.max(...this.screenButtons.map(x => x.Ordinal)) + 1;
    this.orderKioskScreenChoiceService.insertAndGet(kioskChoice)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res: KioskScreenChoice) => {
          this.screenButtons = [...this.screenButtons, res];
          $('.designer-grid-parent').animate({scrollTop: this.scrollableDiv.nativeElement.scrollHeight}, 400);
          this.designerGridComponnet.buttonClick(res);
        }, error: this.alertService.showApiError
      });
  }
}
