<pos-edit-wrapper heading="{{font.Id ? 'Edit' : 'Add'}} Font" (close)="cancelEditing()">
    <form #fontForm="ngForm" (ngSubmit)='saveFont(fontForm.valid)'>
        <context-provider provide="props">
            <div class="overflow-hidden col-12 pos-modal-body">
                <pos-row class="g-0">
                    <pos-column>
                        <pos-form-field label="Name" [validationMessages]="{required: 'Please enter font name.'}">
                            <input posInput name="fontName" #fontName="ngModel" type type="text" class="form-control"
                            [(ngModel)]="font.Name" [required]="true"/>
                        </pos-form-field>
                    </pos-column>

                    <pos-column>
                        <pos-form-field label="File" [inputStyle]="{'col-md-10 col-9': true, 'has-error': fontForm.submitted && !this.fontFileName}" >
                            <input posInput type="file" class="form-control" [accept]="acceptableFontExtensions" #fontFile name="fontFile" 
                                id="fontFile" (change)="handleFileInput($event.target.files)" [required]="true"/>
                            <span *ngIf="!(isChooseFile) && font.FileName">
                                <label class="control-label">{{font.FileName}}</label>
                            </span>
                            <span *ngIf="fontForm.submitted && !this.fontFileName">
                                <label class="control-label">Please choose file.</label>
                            </span>
                        </pos-form-field>
                    </pos-column>
                    
                </pos-row>

            </div>
            <div class="col-12 action-button-container popup-footer">
                <button type="submit" class="btn-primary btn-lg btn btn-save pos-button" value="Save">Save</button>
                <button type="button" class="btn-secondary btn-lg btn btn-cancel-modal pos-button" value="Cancel"
                  (click)="cancelEditing()">Cancel</button>
              </div>
        </context-provider>
    </form>
</pos-edit-wrapper>
