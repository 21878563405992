import { Injectable } from '@angular/core';
import { ApplicationStateService } from '../../application-state.service';
import { RxStompConfig } from '@stomp/rx-stomp';
import { RxStompService } from './rx-stomp.service';
import { LoggerService } from '../../logger.service';
import { Configurations } from 'src/app/shared/interface/configurations';
@Injectable({
  providedIn: 'root'
})

export class RabbitMQConfigurationService {

  rabbitMqConnectionStateSubscription = null;
  constructor(private applicationStateService: ApplicationStateService,
    private rxStompService: RxStompService,
    private loggerService: LoggerService) {
  }

  get() {
    return this.applicationStateService.configurations.RabbitMQ;
  }

  getConfig(): RxStompConfig {
    const configuration = this.applicationStateService.configurations ? this.applicationStateService.configurations.RabbitMQ : null;
    return {
      brokerURL: configuration ? (configuration.StompIsUseSSL ? 'wss:' : 'ws:') + '//' + configuration.StompHost + ':' +
        configuration.StompPort + '/ws' : '',
      connectHeaders: {
        login: configuration ? configuration.Username : '',
        passcode: configuration ? configuration.Password : '',
        host: configuration?.VirtualHost ?? ''
      },
      heartbeatIncoming: 30000,
      heartbeatOutgoing: 30000,
      reconnectDelay: 30000,

      debug: (msg: string): void => {
        console.log(msg);
      }
    };
  }

  setConfig() {
    this.rxStompService.configure(this.getConfig());
    this.rxStompService.activate();
  }
  stompActivateTimeOutMilliSeconds = 2000;
  stompActivateTimeout = null;

  initializeConfigurationsObject(configResponse): Configurations {
    return {
      RabbitMQ: configResponse.RabbitMQConfigs,
      promisesMsg: [],
      TemplatePath: '',
      ShiftDuties: '',
      API_PATH: '',
      webSocket: (configResponse.RabbitMQConfigs.StompIsUseSSL ? 'wss:' : 'ws:')
        + '//' + configResponse.RabbitMQConfigs.StompHost + ':' + configResponse.RabbitMQConfigs.StompPort + '/ws',
      UserPreferenceInvoiceLocation: 'right',
      UserPreferenceOrderMaintenanceUser: null,
      TerminalType: '',
      TerminalTypeName: ''
    };
  }

  subscribeConnectionState = () => {
    if (this.rabbitMqConnectionStateSubscription) {
      this.rabbitMqConnectionStateSubscription.unsubscribe();
    }
    this.rabbitMqConnectionStateSubscription = this.rxStompService.connectionState$.subscribe({
      next: (state) => {
        let connectionState = 'UNKNOWN';
        switch (state) {
          case 0:
            connectionState = 'CONNECTING';
            break;
          case 1:
            this.stompActivateTimeOutMilliSeconds = 2000;
            this.stompActivateTimeout = null;
            connectionState = 'OPEN';
            break;
          case 2:
            connectionState = 'CLOSING';
            break;
          case 3:
            connectionState = 'CLOSED';
            break;
          default:
            break;
        }
        if (connectionState === 'CLOSED') {
          this.ReconnectingStomp();
        }
      }
    });
  }


  private ReconnectingStomp() {
    if (!this.stompActivateTimeout && this.stompActivateTimeOutMilliSeconds < 60000) {
      this.stompActivateTimeout = setTimeout(() => {
        if (this.stompActivateTimeout) {
          clearTimeout(this.stompActivateTimeout);
          this.stompActivateTimeout = null;
        }
        if (!this.rxStompService.connected()) {
          this.rxStompService.activate();
          this.stompActivateTimeOutMilliSeconds += 3000;
        }
      }, this.stompActivateTimeOutMilliSeconds);
    }
  }
}
