import { AfterContentInit, Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DisplayGrid, GridsterComponent, GridsterConfig, GridType } from 'angular-gridster2';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { DomainConstants } from 'src/app/shared/constants/domain-constants';
import { Messages } from 'src/app/shared/constants/ui-messages';
import { MenuDisplay, MenuDisplayConfig } from '../../interfaces/menu-display';
import { MenuWidget } from '../../interfaces/menu-widget';
import { MenuDisplayService, MenuWidgetService } from '../../services';
import { plusWhite, rectanglePortrait, rectangleLandscape, handRock, searchPlus, searchMinus, mousePointer, plusMessage, minus, undo, spinner } from 'src/app/shared/components/icon';
import { forEach, orderBy, cloneDeep } from 'lodash';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import panzoom, { PanZoom } from "panzoom";
import { NgForm } from '@angular/forms';
import { ModalService, ModalWrapperComponent } from 'src/app/shared/components';
import { MenuWidgetEditComponent } from '../menu-widget-edit';
import { WidgetDetails } from '../../interfaces';
import { RabbitMQService } from 'src/app/shared/services';
import { UntilDestroy } from '@ngneat/until-destroy';
declare let $: any;

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'pos-menu-display-config',
  templateUrl: './menu-display-config.component.html',
  styleUrls: ['./menu-display-config.component.scss'],
})
export class MenuDisplayConfigComponent implements OnInit, OnDestroy, AfterContentInit {
  screenHeight: number = 10;
  options: GridsterConfig;
  dashboard: Array<MenuWidget>;
  digitalMenu: MenuDisplay;
  menuDisplayConfig: MenuDisplayConfig;
  widgetName: string;
  widgetTypes = [];
  dragMode = false;
  panZoomInstance: PanZoom;
  selectedWidget;
  menuDisplayResolution = {};
  menuId: number;
  panZoomOn = false;
  zoomScale = 1;
  isEditable = true;
  preview = false;
  icons = {
    plusMessage,
    plusWhite,
    rectanglePortrait,
    rectangleLandscape,
    handRock,
    searchPlus,
    searchMinus,
    mousePointer,
    minus,
    undo,
    spinner
  };

  @ViewChild('menuDisplay', { static: true }) menuDisplay: ElementRef;
  @ViewChild('menuDisplayForm', { static: true }) menuDisplayForm: NgForm;
  @ViewChild('gridster') gridster: GridsterComponent;
  @ViewChildren('gridsterItem') gridsterItem: any;
  draggedItem: any = null;
  @ViewChild('newWidget') public newWidget;
  newWidgetHeader = null;
  widgetModalRef: ModalWrapperComponent<any>;
  applyingChanges: boolean;
  widgetData: Array<WidgetDetails> = [];
  isShowGroupWidgets = false;
  groupWidgetName = DomainConstants.DigitalMenuWidgetTypes.Group.Name;

  rabbitMqFilterMenuItemsSubscription: Subscription;
  rabbitMqHighlightMenuItemsSubscription: Subscription;
  rabbitMqRefreshMenuItemsSubscription: Subscription;

  constructor(route: ActivatedRoute,
    private spinnerService: SpinnerService,
    private alertService: AlertsService,
    private router: Router,
    public modalService: ModalService,
    private menuDisplayService: MenuDisplayService,
    public menuWidgetService: MenuWidgetService,
    public rabbitMQService: RabbitMQService) {
    this.menuId = route.snapshot.params.id ? parseInt(route.snapshot.params.id, 10) : 0;
    forEach(DomainConstants.DigitalMenuWidgetTypes, (widget) => {
      this.widgetTypes.push({ Name: widget.Name, Value: widget.Value, Icon: widget.Icon });
    });
    this.widgetTypes = orderBy(this.widgetTypes, 'Name');
    this.selectedWidget = this.widgetTypes[0].Value;
    this.isEditable = route.snapshot?.data?.isEditable ?? this.isEditable;
  }

  ngOnInit(): void {
    this.screenHeight = this.isEditable ? $(window).height() : $(window).height() + 50;
    this.setGridConfigurations();
    this.showGroupWidgets();
    this.resizeWindow();
    this.loadData();
  }

  ngAfterContentInit(): void {
    this.subscribeToFilterMenuItemsBroadcastQueue();
    this.subscribeToHighlightMenuItemsBroadcastQueue();
    this.subscribeToRefreshMenuDisplayBroadcastQueue();
  }

  subscribeToFilterMenuItemsBroadcastQueue() {
    this.rabbitMqFilterMenuItemsSubscription = this.rabbitMQService.subscribeToMenuDisplayFilteredMessage$()
      .subscribe({
        next: (message: any) => {
          if (this.preview) {
            this.menuWidgetService.menuItemChangeSubject.next({ Type: 'FilterMenuItems', Message: message })
          }
        }, error: this.alertService.showApiError
      });
  }

  loadData() {
    const observable: Array<Observable<any>> = [];
    observable.push(this.menuDisplayService.getMenuWidgets(this.menuId));
    observable.push(this.menuDisplayService.get(this.menuId));
    observable.push(this.menuWidgetService.getMenuWidgets(this.menuId));
    this.spinnerService.show();
    forkJoin(observable).pipe(finalize(() => {
      this.spinnerService.hide();
    })).subscribe({
      next: ([dashboard, digitalMenu, widgets]: [Array<MenuWidget>, MenuDisplay, Array<WidgetDetails>]) => {
        this.dashboard = dashboard;
        this.dashboard.forEach((data) => {
          data.GridItem = JSON.parse(data.WidgetDetails);
        });
        this.digitalMenu = digitalMenu;
        this.menuDisplayConfig = JSON.parse(this.digitalMenu.Config);
        this.options.maxCols = ((digitalMenu.Orientation ? digitalMenu.Height : digitalMenu.Width) / this.options.fixedColWidth) * 2;
        this.options.maxRows = ((digitalMenu.Orientation ? digitalMenu.Width : digitalMenu.Height) / this.options.fixedRowHeight) * 2;
        this.options.maxItemArea = (this.options.maxCols * this.options.maxRows);
        this.menuDisplayResolution = this.getMenuDisplayResolution();
        this.widgetData = widgets ?? [];
        this.menuWidgetService.widgetDataSubject.next(this.widgetData);
      },
      error: this.alertService.showApiError
    }
    );
  }

  subscribeToHighlightMenuItemsBroadcastQueue() {
    this.rabbitMqHighlightMenuItemsSubscription = this.rabbitMQService.subscribeToMenuDisplayItemHighlightedMessage$()
      .subscribe({
        next: (message: any) => {
          this.menuWidgetService.menuItemChangeSubject.next({ Type: 'HighlightMenuItems', Message: message })
        }, error: this.alertService.showApiError
      });
  }

  resizeWindow = () => {
    $(window).resize(() => {
      this.screenHeight = this.isEditable ? $(window).height() : $(window).height() + 50;
    });
  }

  subscribeToRefreshMenuDisplayBroadcastQueue() {
    this.rabbitMqRefreshMenuItemsSubscription = this.rabbitMQService.subscribeToRefreshMenuDisplayMessage$()
      .subscribe({
        next: (message) => {
          this.loadData();
        }, error: this.alertService.showApiError
      });
  }

  private setGridConfigurations() {
    this.options = {
      itemResizeCallback: MenuDisplayConfigComponent.itemResize,
      gridType: GridType.Fixed,
      displayGrid: DisplayGrid.Always,
      pushItems: true,
      draggable: {
        enabled: this.isEditable,
        start: (item, itemComponent, event) => {
          this.starDrag(item, this);
        },
        stop: (item, itemComponent, event) => {
          this.onItemChange(item, itemComponent);
        }
      },
      resizable: {
        enabled: this.isEditable
      },
      margin: 0,
      maxItemCols: 1000000,
      maxItemRows: 1000000,
      maxItemArea: 1000000000,
      disableScrollHorizontal: true,
      disableScrollVertical: true,
      setGridSize: false,
      allowMultiLayer: true,
      defaultLayerIndex: 1,
      maxLayerIndex: 50,
      baseLayerIndex: 1,
      fixedColWidth: 5,
      fixedRowHeight: 5,
      maxRows: 1000,
      maxCols: 1000,
    };
  }

  starDrag = (item, self) => {
    if (item) {
      this.draggedItem = cloneDeep(item);
    }
  }

  onItemChange = (item: any, itemComponent: any) => {
    if (itemComponent?.$item) {
      const diffPositionX = this.draggedItem.x - itemComponent?.$item?.x;
      const diffPositionY = this.draggedItem.y - itemComponent?.$item?.y;
      this.dashboard?.forEach((i, index) => {
        if (((i?.GridItem?.layerIndex ?? 1) > (this.draggedItem?.layerIndex ?? 1)) &&
          (i?.GridItem?.x >= this.draggedItem?.x && i?.GridItem?.x <= (this.draggedItem?.cols + this.draggedItem?.x)) &&
          (i?.GridItem?.y >= this.draggedItem?.y && i?.GridItem?.y <= (this.draggedItem?.rows + this.draggedItem?.y))) {
          i.GridItem.x += diffPositionX != 0 ? (diffPositionX * -1) : 0;
          i.GridItem.y += diffPositionY != 0 ? (diffPositionY * -1) : 0;
          if (this.gridsterItem?._results[index] && this.gridsterItem?._results[index]?.$item) {
            this.gridsterItem._results[index].$item = i.GridItem;
            this.gridsterItem._results[index].item = i.GridItem;
            this.gridsterItem._results[index].setSize();
          }
        }
      });
      this.draggedItem = null;
    }
  }

  static itemResize(item: any, itemComponent: any) {
    console.info('itemResized', item, itemComponent);
  }


  save(redirect: boolean = true) {
    if (redirect) {
      this.spinnerService.show();
    }
    this.applyingChanges = true;
    this.dashboard.forEach((data) => {
      delete data.GridItem['dragEnabled'];
      delete data.GridItem['resizeEnabled'];
      data.WidgetDetails = JSON.stringify(data.GridItem);
    });
    this.menuDisplayService.saveUpdateMenuWidgets(this.menuId, this.dashboard).pipe(finalize(() => {
      this.spinnerService.hide();
      this.applyingChanges = false;
    })).subscribe({
      next: (response) => {
        this.onSaveSuccess(redirect);
      }, error: this.alertService.showApiError
    });
  }

  onSaveSuccess(redirect: boolean = true) {
    if (redirect) {
      this.cancel();
    }
    this.alertService.renderSuccessMessage(Messages.MenuConfigurationSaved);
  }

  addWidget(isValid) {
    if (!isValid) {
      return;
    }
    const widget: MenuWidget = {
      Id: 0,
      Name: this.widgetName,
      MenuDisplayId: this.menuId,
      GridItem: { x: 0, y: 0, cols: (this.options.maxCols) * 0.1, rows: (this.options.maxCols) * 0.1 },
      WidgetType: this.selectedWidget,
      WidgetDetails: '',
      WidgetStyle: null,
      WidgetConfig: null
    };
    if (this.selectedWidget == DomainConstants.DigitalMenuWidgetTypes.Group.Name) {
      widget.GridItem.layerIndex = 50;
      widget.WidgetStyle = JSON.stringify(this.menuWidgetService.getGroupWidgetStyleConfig());
    }
    this.saveWidget(widget);
    this.widgetModalRef.hide();
  }

  saveWidget(widget: MenuWidget) {
    this.spinnerService.show();
    widget.WidgetDetails = JSON.stringify(widget.GridItem);
    this.menuDisplayService.saveMenuWidget(widget)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      })).subscribe({
        next: (response: MenuWidget) => {
          response.GridItem = JSON.parse(response.WidgetDetails);
          this.loadWidget(response.Id, response);
        },
        error: this.alertService.showApiError
      })
  }

  copyWidget(item: MenuWidget) {
    const widget = {
      Id: 0,
      Name: item.Name,
      MenuDisplayId: this.menuId,
      GridItem: { x: item.GridItem.x, y: item.GridItem.y, cols: item.GridItem.cols, rows: item.GridItem.rows, layerIndex: item.GridItem.layerIndex },
      WidgetType: item.WidgetType,
      WidgetDetails: item.WidgetDetails,
      WidgetStyle: item.WidgetStyle,
      WidgetConfig: item.WidgetConfig
    };
    this.saveWidget(widget);
  }

  editWidget(item: MenuWidget) {
    const modalRef = this.modalService.getModalWrapper(MenuWidgetEditComponent);
    const modal = modalRef.show({
      animated: false,
      class: 'vertical-center modal-lg',
      backdrop: 'static',
      initialState: {
        widgetId: item.Id,
        menuId: this.menuId
      }
    })

    modal.close.subscribe((res) => {
      if (res?.shouldReload) {
        const widget = res.data as MenuWidget;
        widget.GridItem = JSON.parse(widget.WidgetDetails);
        this.loadWidget(widget.Id, widget);
      }
    })
  }

  loadWidget(widgetId: number, widget: MenuWidget) {
    this.spinnerService.show();
    this.menuWidgetService.getWidget(this.menuId, widgetId)
      .pipe(finalize(() => this.spinnerService.hide()))
      .subscribe({
        next: (res: Array<WidgetDetails>) => {
          const currWidget = res?.length ? res[0] : this.menuWidgetService.getNewWidgetDetails();
          const existingWidget = this.widgetData.find(x => x.Id == widgetId);
          if (existingWidget) {
            this.widgetData.splice(this.widgetData.indexOf(existingWidget), 1);
          }
          this.widgetData.push(currWidget);
          this.addWidgetToDashboard(widget);
        },
        error: this.alertService.showApiError
      })
  }

  addWidgetToDashboard(widget: MenuWidget) {
    const existingWidget = this.dashboard.find(x => x.Id == widget.Id);
    if (existingWidget) {
      this.dashboard.splice(this.dashboard.indexOf(existingWidget), 1);
    }
    const isDraggableAndResizable = (this.isShowGroupWidgets && widget.WidgetType == DomainConstants.DigitalMenuWidgetTypes.Group.Name) || !this.isShowGroupWidgets;
    widget.GridItem.dragEnabled = isDraggableAndResizable;
    widget.GridItem.resizeEnabled = isDraggableAndResizable;
    this.dashboard.push(widget);
  }

  reload(shouldReload) {
    if (shouldReload) {
      this.loadData();
    }
  }

  deleteWidget(index: number) {
    this.dashboard.splice(index, 1);
    this.dashboard = this.dashboard;
  }

  enablePanZoom() {
    this.dragMode = !this.dragMode;
    if (this.dragMode) {
      this.options.draggable.enabled = false;
      this.options.resizable.enabled = false;
      this.options.api.optionsChanged();
      if (this.panZoomInstance?.isPaused && this.panZoomOn) {
        this.panZoomInstance.resume();
      } else {
        this.activatePanZoom();
      }

    } else {
      this.options.draggable.enabled = true;
      this.options.resizable.enabled = true;
      this.options.api.optionsChanged();
      this.panZoomInstance.pause();
    }
  }

  onZoom(isZoom: boolean) {
    if (this.panZoomOn) {
      this.zoomScale = isZoom ? this.zoomScale + 0.25 : (this.zoomScale <= 0.25 ? 0.25 : this.zoomScale - 0.25);
      this.panZoomInstance.zoomAbs(0, 0, this.zoomScale);
    } else {
      this.activatePanZoom();
      this.zoomScale = isZoom ? this.zoomScale + 0.25 : this.zoomScale - 0.25;
      this.panZoomInstance.zoomAbs(0, 0, this.zoomScale);
    }
  }

  getMenuDisplayResolution() {
    return {
      height: this.digitalMenu.Orientation ? this.digitalMenu?.Width + 'px' : this.digitalMenu?.Height + 'px',
      width: this.digitalMenu.Orientation ? this.digitalMenu?.Height + 'px' : this.digitalMenu?.Width + 'px'
    };
  }

  activatePanZoom() {
    this.panZoomInstance = panzoom(document.querySelector('#gridster'), {
      beforeWheel: () => {
        return true;
      }
    });
    this.panZoomOn = true;
  }

  resetPanZoom() {
    if (this.panZoomOn && this.panZoomInstance) {
      this.zoomScale = 1;
      this.panZoomInstance.moveTo(0, 0);
      this.panZoomInstance.zoomAbs(0, 0, this.zoomScale);
      this.panZoomInstance.pause();
      this.panZoomInstance.dispose();
      this.panZoomOn = false;
      this.dragMode = false;
      this.options.draggable.enabled = true;
      this.options.resizable.enabled = true;
      this.options.api.optionsChanged();
    }
  }

  cancel() {
    this.router.navigate(['/manage/menu-display']);
  }

  ngOnDestroy() {
    this.panZoomInstance?.dispose();
  }

  openNewWidgetModal(widgetType) {
    if (widgetType.Name == this.groupWidgetName && !this.isShowGroupWidgets) {
      return;
    }
    this.newWidgetHeader = `Add ${widgetType.Value} Widget`;
    this.widgetName = null;
    this.selectedWidget = widgetType.Name;
    this.widgetModalRef = this.modalService.getModalWrapper(this.newWidget);
    this.widgetModalRef.show({
      'backdrop': 'static',
      'class': 'vertical-center',
      keyboard: false
    });
  }

  closeWidgetModal() {
    this.widgetModalRef.hide();
  }

  showGroupWidgets() {
    this.menuWidgetService.showGroupWidgetsSubject.next({ showGroupWidgets: this.isShowGroupWidgets });
    forEach(this.dashboard, item => {
      const isDraggableAndResizable = (this.isShowGroupWidgets && item.WidgetType == DomainConstants.DigitalMenuWidgetTypes.Group.Name) || !this.isShowGroupWidgets;
      item.GridItem.dragEnabled = isDraggableAndResizable;
      item.GridItem.resizeEnabled = isDraggableAndResizable;
    });
    if (this.options.api?.optionsChanged) {
      this.options.api.optionsChanged();
    }
  }

}
