import { Component, OnInit, EventEmitter, Output, ViewChild } from '@angular/core';
import { FieldInfoMessages } from 'src/app/shared/constants';
import { AlertsService, ApplicationStateService, SpinnerService, Messages, ParameterService, ConfigurationParameter, FormUtilityService, BaseFormComponent, DomainConstants } from 'src/app/shared';
import { SalesTaxService } from 'src/app/shared/services/sales-tax.service';
import { SalesTax } from '../../interface/sales-tax';
import { SalesTaxesParameterModel } from '../../interface/sales-taxes-parameter-model';
import { finalize } from 'rxjs/operators';
import { taxesMoneyBillAlt, times } from 'src/app/shared/components/icon';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'pos-sales-taxes',
  templateUrl: './sales-taxes.component.html',
  styleUrls: ['./sales-taxes.component.scss']
})
export class SalesTaxesComponent extends BaseFormComponent implements OnInit {
  @ViewChild('formTaxes') formTaxes: NgForm;
  get getForm(): NgForm {
    return this.formTaxes
  }
  taxes: Array<SalesTax> = [];
  taxesCopy: Array<SalesTax> = [];
  taxToGo: boolean;
  discountTaxes: boolean;
  taxRoundingMode: string;
  TaxToGo: ConfigurationParameter;
  DiscountTaxes: ConfigurationParameter;
  TaxRoundingMode: ConfigurationParameter;
  isDefaultChecked = false;
  icons = {
    times,
    taxesMoneyBillAlt
  };
  taxRoundingModes = DomainConstants.TaxRoundingMode;
  @Output() close: EventEmitter<any> = new EventEmitter<any>();

  fieldInfoMessages = {
    TaxRoundingType: FieldInfoMessages.TaxRoundingType,
    TaxesName: FieldInfoMessages.TaxesName,
    TaxesToGoOrders: FieldInfoMessages.TaxesToGoOrders,
    TaxesDiscountTax: FieldInfoMessages.TaxesDiscountTax
  };

  constructor(protected alertService: AlertsService,
    protected salesTaxService: SalesTaxService,
    protected applicationStateService: ApplicationStateService,
    protected spinnerService: SpinnerService,
    protected router: Router,
    protected parameterService: ParameterService,
    formUtilityService: FormUtilityService) {
    super(formUtilityService);
  }

  ngOnInit() {
    this.loadData();
  }

  customTrackBy(index: number, obj: any): any {
    return index;
  }

  loadData() {
    this.taxes = [];
    this.spinnerService.show();
    this.salesTaxService.getAllData()
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (response: SalesTaxesParameterModel) => {
          this.taxes = response.SalesTaxes;
          this.TaxToGo = response.TaxToGo;
          this.taxToGo = this.TaxToGo?.value?.toLowerCase() === 'true';
          this.DiscountTaxes = response.DiscountTaxes;
          this.discountTaxes = this.DiscountTaxes?.value?.toLowerCase() === 'true';
          this.TaxRoundingMode = response.TaxRoundingMode;
          this.taxRoundingMode = this.TaxRoundingMode.value;
          this.createSalesTaxRow(true);
        }, error: this.alertService.showApiError
      });
  }

  saveTaxes(isValid: boolean) {
    if (!isValid) {
      return;
    }
    if (this.isDefaultChecked) {
      this.alertService.renderErrorMessage(Messages.ErrorWhileDefaultTaxNotSelected);
      return;
    }
    const arrayOfNames = this.taxes.filter((tax) => tax.Name !== '').map((tax) => (tax.Name));
    if (new Set(arrayOfNames).size < this.taxes.filter((tax) => tax.Name !== '').length) {
      this.alertService.renderErrorMessage(Messages.DuplicateSalesTaxError);
      return;
    }
    this.taxesCopy = [...this.taxes];
    _.forEach(this.taxesCopy, (tax) => {
      if (!tax.Name) {
        this.removeSalesTax(tax, null, true);
      }
    });
    _.remove(this.taxesCopy, (tax) => {
      return tax.Invalidated;
    });
    this.spinnerService.show();
    this.TaxToGo.value = this.taxToGo === true ? 'true' : 'false';
    this.DiscountTaxes.value = this.discountTaxes === true ? 'true' : 'false';
    this.TaxRoundingMode.value = this.taxRoundingMode;
    this.salesTaxService.saveTaxes(this.taxesCopy, this.TaxToGo, this.DiscountTaxes, this.TaxRoundingMode)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res) => {
          this.alertService.renderSuccessMessage(Messages.TaxSaveSuccess);
          this.loadData();
        }, error: this.alertService.showApiError
      });
  }

  createSalesTaxRow(lastRow: boolean) {
    if (!lastRow && this.taxes.length > 1) {
      return;
    }
    const paramsObj = {
      Id: 0,
      Name: '',
      Percent: null,
      IsDefault: false
    };
    this.taxes.push(paramsObj);
  }

  defaultChanged(index: number, isChecked: boolean) {
    this.isDefaultChecked = false;
    _.forEach(this.taxes, (tax) => {
      tax.IsDefault = false;
    });
    if (isChecked) {
      this.taxes[index].IsDefault = true;
    } else {
      this.isDefaultChecked = true;
    }
  }

  removeSalesTax(tax, index, fromSave = false): void {
    if (tax.Id > 0) {
      this.spinnerService.show();
      this.salesTaxService.delete(tax.Id)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: (res) => {
            if (!fromSave) {
              this.loadData();
              this.alertService.renderSuccessMessage(Messages.TaxDeleteSuccess);
            }
          }, error: this.alertService.showApiError
        });
    } else if (!fromSave) {
      this.taxes.splice(index, 1);
    }
    tax.Invalidated = true;
  }

  cancel() {
    this.router.navigate(['/manage/system-configuration']);
  }
}
