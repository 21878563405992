import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AccountsAndTabsDetail } from '../interface/accounts-and-tabs-detail';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import { SettingParam } from 'src/app';


@Injectable({
  providedIn: 'root'
})
export class AccountsAndTabsService {

  accountsandTabsApiBasePath = `${RuntimeConstants.API_PATH}accountsandtabs`;

  constructor(private httpClient: HttpClient) { }

  getAccountsandTabsDetail(terminalId: number): Observable<SettingParam> {
    return this.httpClient.get<SettingParam>(`${this.accountsandTabsApiBasePath}/${terminalId}`);
  }
  saveAccountAndTabsDetail(params: SettingParam): Observable<SettingParam> {
    return this.httpClient.post<SettingParam>(`${this.accountsandTabsApiBasePath}`, params);
  }

  getAccountType(): Observable<Array<string>> {
    return this.httpClient.get<Array<string>>(`${this.accountsandTabsApiBasePath}`);
  }
}
