import { Component, OnInit, ViewChild } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { accountsAndTabsUsersTag, AlertsService, ApplicationStateService, BaseFormComponent, DomainConstants, FieldInfoMessages, FormUtilityService, Messages, ReloadSettingsService, SettingParam, SpinnerService } from 'src/app/shared';
import { AccountsAndTabsService } from '../../services/accounts-and-tabs.service';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { PostSavedConfigurationService } from 'src/app/configurator/post-saved-configuration.service';
import { forkJoin } from 'rxjs';
@Component({
  selector: 'pos-accounts-and-tabs',
  templateUrl: './accounts-and-tabs.component.html',
})
export class AccountsAndTabsComponent extends BaseFormComponent implements OnInit {
  @ViewChild('accountsandTabsform') accountsandTabsform: NgForm;
  get getForm(): NgForm {
    return this.accountsandTabsform
  }
  parameters: SettingParam;
  accountTypes: Array<string> = [];
  fieldInfoMessages = FieldInfoMessages;
  accountSwitches = DomainConstants.AccountSwitches;

  chkAccountOrders = true;
  chkAccountOrders_Account = false;
  chkAccountOrders_AccountDefault = false;
  chkAccountOrders_Table = false;
  chkAccountOrders_TableDefault = false;
  chkAccountOrders_Tab = false;
  chkAccountOrders_TabDefault = false;

  accountToOrderString = '';
  accountToOrderDefaultString = '';
  settingParam: SettingParam;
  loyaltyAccountTerm: string = '';
  tableTerm: string = '';
  accountTerm: string = '';
  icons = {
    accountsAndTabsUsersTag
  }
  props = {
    labelClass: '',
    controlBoxClass: 'col-12'
  }
  constructor(
    private spinnerService: SpinnerService,
    private accountsAndTabsService: AccountsAndTabsService,
    private alertsService: AlertsService,
    private applicationStateService: ApplicationStateService,
    private postSavedConfigurationService: PostSavedConfigurationService,
    private router: Router,
    private reloadSettingService: ReloadSettingsService,
    formUtilityService: FormUtilityService) {
    super(formUtilityService);
    this.parameters = applicationStateService.newSettingParams();
  }

  ngOnInit() {
    this.loadData();
    this.setDefault();
  }

  setDefault() {
    this.settingParam = this.applicationStateService.settingParam;
    this.loyaltyAccountTerm = this.settingParam.LoyalAccountTerm ? this.settingParam.LoyalAccountTerm : 'Account';
    this.tableTerm = this.settingParam.TableTerm ? this.settingParam.TableTerm : 'Table';
    this.accountTerm = this.settingParam.AccountTerm ? this.settingParam.AccountTerm : 'Tab';
  }

  loadData() {
    this.spinnerService.show();
    const accountAndTabsObservable = [];
    accountAndTabsObservable.push(this.accountsAndTabsService.getAccountsandTabsDetail(this.applicationStateService.terminalId));
    accountAndTabsObservable.push(this.accountsAndTabsService.getAccountType())

    forkJoin(accountAndTabsObservable).pipe(finalize(() => {
      this.spinnerService.hide();
    }))
    .subscribe({
      next: (res: any) => {
        if(res){
          this.parameters = res[0]
          this.accountTypes = res[1];
          this.getAccountsandTabsDetailsCompleted();
        }
      }, error: this.alertsService.showApiError
    })
  }

  getAccountsandTabsDetailsCompleted() {
    this.parameters.AccountSwitching = this.parameters.AccountSwitching ? this.parameters.AccountSwitching : null;
    this.accountToOrderString = this.parameters.AccountTypes;

    if (this.accountToOrderString === '') {
      this.chkAccountOrders_Account = false;
      this.chkAccountOrders_Table = false;
      this.chkAccountOrders_Tab = false;
    } else {
      if (this.accountToOrderString.indexOf('Account,') > -1) {
        this.chkAccountOrders_Account = true;
      }
      if (this.accountToOrderString.indexOf('Table,') > -1) {
        this.chkAccountOrders_Table = true;
      }
      if (this.accountToOrderString.indexOf('Tab,') > -1) {
        this.chkAccountOrders_Tab = true;
      }
      if (this.parameters.AccountTypeDefault === 'Account') {
        this.chkAccountOrders_AccountDefault = true;
      } else if (this.parameters.AccountTypeDefault === 'Table') {
        this.chkAccountOrders_TableDefault = true;
      } else if (this.parameters.AccountTypeDefault === 'Tab') {
        this.chkAccountOrders_TabDefault = true;
      }
    }
  }

  saveAccountsAndTabs() {
    if (!this.chkAccountOrders) {
      this.accountToOrderString = '';
      this.parameters.ChooseAccountOptional = '';
    }
    this.parameters.LoyaltyProgramRequiresMemberInformation = this.parameters.IsEnableLoyaltyCardProgram ?
      this.parameters.LoyaltyProgramRequiresMemberInformation : false;
    this.parameters.AccountTypes = this.accountToOrderString;
    this.parameters.AccountTypeDefault = this.accountToOrderDefaultString;
    this.parameters.AccountSwitching = this.parameters.AccountSwitching ? this.parameters.AccountSwitching : '';
    this.spinnerService.show();
    
    this.accountsAndTabsService.saveAccountAndTabsDetail(this.parameters)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: () => {
          this.reloadSettings();
          this.alertsService.renderSuccessMessage(Messages.AccountsandTabsSaveSuccess);
          this.postSavedConfigurationService.showMessage();
        }
      });
  }

  reloadSettings = () => {
    this.reloadSettingService.reloadSettings(this.applicationStateService.terminalId).subscribe({ next: (res) => { } });
  }

  accountToOrders() {
    if (!this.chkAccountOrders) {
      this.chkAccountOrders_Account = false;
      this.chkAccountOrders_AccountDefault = false;
      this.chkAccountOrders_Table = false;
      this.chkAccountOrders_TableDefault = false;
      this.chkAccountOrders_Tab = false;
      this.chkAccountOrders_TabDefault = false;
    }
  }

  generateAccountToOrdersString(account: string) {
    this.accountToOrderDefaultString = '';
    if (account === 'Account,') {
      if (!this.chkAccountOrders_Account) {
        this.chkAccountOrders_AccountDefault = false;
      }
    } else if (account === 'Table,') {
      if (!this.chkAccountOrders_Table) {
        this.chkAccountOrders_TableDefault = false;
      }
    } else if (account === 'Tab,') {
      if (!this.chkAccountOrders_Tab) {
        this.chkAccountOrders_TabDefault = false;
      }
    }
    if (this.accountToOrderString.indexOf(account) === -1) {
      this.accountToOrderString = this.accountToOrderString.replace('', account);
    } else {
      this.accountToOrderString = this.accountToOrderString.replace(account, '');
    }
  }

  setDefaultAccountToOrdersString(accountDefault: string) {
    this.accountToOrderDefaultString = accountDefault;
    if (accountDefault === 'Account') {
      this.chkAccountOrders_TableDefault = false;
      this.chkAccountOrders_TabDefault = false;
    } else if (accountDefault === 'Table') {
      this.chkAccountOrders_AccountDefault = false;
      this.chkAccountOrders_TabDefault = false;
    } else if (accountDefault === 'Tab') {
      this.chkAccountOrders_AccountDefault = false;
      this.chkAccountOrders_TableDefault = false;
    }
  }

  cancelEdit() {
    this.router.navigate(['/manage/system-configuration']);
  }
}
