<div class="width-80 m-0-auto px-pix-10">
    <pos-list-wrapper heading="Gift Cards" (close)="onCancel()">
        <div class="col-12 pb-pix-5">
            <div class="gift-card-active">
                <p-checkbox posInput name="active" [(ngModel)]="isActive"
                  binary="true" label="Active" (onChange)="filterGiftCards()">
                </p-checkbox>
            </div>
            <div>
                <ng-table #giftCardList [columns]="giftCardColumns" [data]="filteredGiftCardsList"
                    [rowsPerPageOptions]="[20, 50, 100]">
                </ng-table>
                <ng-template let-data="rowData" #priceTemplate>
                    <div class="text-right">
                        {{data.CurrentBalance | tark_currency}}
                    </div>
                </ng-template>

                <ng-template let-data="rowData" #giftCardNumberTemplate>
                    <div>
                        {{data.CardNumber}}
                    </div>
                </ng-template>
                <ng-template let-data="rowData" #giftCardNumberEditTemplate>
                    <div (click)="openGiftCardDialog(data.CardNumber)">
                        <a class="pointer">{{data.CardNumber}}</a>
                    </div>
                </ng-template>
            </div>
        </div>
    </pos-list-wrapper>
</div>
