import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { GridsterItem } from 'angular-gridster2';
import { AlertsService, ConfirmDeleteModalComponent, ExtensionValidationService, FormUtilityService, Messages, ModalFormComponent, ModalService, SalesCategoryService, SalesGroupService, SpinnerService, StringUtils, TagService, DomainConstants, RuntimeConstants, SalesSizeService } from 'src/app/shared';
import { cog, infoCircle, colorsPaletter, heading, salesProductsFileInvoiceDollar, plus, trashAltWhite, pencil, deleteWhite, editWhite, times, arrowsAlt, fileSearch, squareLightOrangeStack } from 'src/app/shared/components/icon';
import { WidgetSaveModel, WidgetStyleConfig, WidgetBaseConfig, ImageListWidgetConfig, ItemWidgetConfig, ListWidgetConfig, TextWidgetConfig, ImageWidgetConfig, WidgetHeaderConfig, ComboListWidgetStyleConfig, ImageWidgetStyleConfig, ItemWidgetStyleConfig, ListWidgetStyleConfig, TilesWidgetStyleConfig, WidgetProductGroups } from '../../interfaces';
import { MenuWidgetService, WidgetProductGroupService } from '../../services';
import { finalize } from 'rxjs/operators';
import { BaseWidgetCrudService } from '../../services/base-widget-crud-service.service';
import { SalesCategory, SalesGroup, SalesProduct, SalesProductsService, SalesSize, Tag } from 'src/app/information-management';
import { forkJoin, Observable, of } from 'rxjs';
import { forEach, groupBy, map, mapValues, sortBy, startCase, union } from 'lodash';
import { NgForm } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { WidgetGroupedItem } from '../../interfaces/widget-grouped-item';
import { WidgetGroupConfigComponent } from '../widget-group-config';
import * as moment from 'moment';
import { FileWithTimeStamp } from 'src/app/shared/interface/file-with-time-stamp';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ProductOrdinalConfigComponent } from './product-ordinal-config/product-ordinal-config.component';
import { ProductOrdinal } from '../../interfaces/product-ordinal';
import { VideoWidgetConfig } from '../../interfaces/video-widget-config';

declare let $: any;

@Component({
  selector: 'pos-menu-widget-edit',
  templateUrl: './menu-widget-edit.component.html',
  styleUrls: ['./menu-widget-edit.component.scss'],
})
export class MenuWidgetEditComponent extends ModalFormComponent implements OnInit {
  get getForm(): NgForm {
    return this.formWidgetConfig;
  }
  @Input() widgetId: number;
  @Input() menuId: number;
  @ViewChild('formWidgetConfig') formWidgetConfig: NgForm;
  widgetConfiguration: WidgetSaveModel;
  menuWidgetTypes = DomainConstants.DigitalMenuWidgetTypes;
  groupListBehaviors = [];
  imageTextPositions = DomainConstants.ImageTextPosition;
  displayListStyles = [];
  justifyContent = DomainConstants.JustifyContent;
  alignItemsVertical = DomainConstants.AlignItemsVertical;
  verticalAlign = DomainConstants.VerticalAlign;
  widgetType: any = {};
  widgetDetails: GridsterItem;
  widgetStyle: WidgetStyleConfig;
  widgetConfig: WidgetBaseConfig | ImageListWidgetConfig | ItemWidgetConfig | ListWidgetConfig | TextWidgetConfig | ImageWidgetConfig | VideoWidgetConfig;
  bannerWidgetConfig: WidgetHeaderConfig;

  salesGroupList: Array<SalesGroup> = [];
  salesCategoryList: Array<SalesCategory> = [];
  salesProductList: Array<SalesProduct> = [];
  tagList: Array<Tag> = [];
  salesSizes: Array<SalesSize> = [];

  widgetGroupList: Array<WidgetProductGroups> = [];
  itemsArray: Array<WidgetGroupedItem> = [];
  listSeparatorType = 'Number';
  listSeparators: number | [] = null;
  filesToUpload: FileWithTimeStamp[] = [];
  icons = {
    cog,
    infoCircle,
    colorsPaletter,
    heading,
    salesProductsFileInvoiceDollar,
    plus,
    trashAltWhite,
    pencil,
    editWhite,
    deleteWhite,
    squareLightOrangeStack,
    fileSearch,
    times,
    arrowsAlt
  }
  tabList = {
    Details: 'Details',
    Config: 'Config',
    Style: 'Style',
    Header: 'Header',
    Items: 'Items'
  }
  selectedTab = this.tabList.Details;
  fieldInfo = DomainConstants.WidgetFieldInfo;
  containerHeight: string;
  itemContainerHeight: string;
  menuWidgetPath = RuntimeConstants.IMAGE_BASE_PATH + '/menu-display';
  BorderStyleList = DomainConstants.BorderStyles;
  SeparatorDirectionList = DomainConstants.SeparatorDirection;
  salesItemsLoaded = false;


  constructor(protected menuWidgetService: MenuWidgetService,
    private salesGroupService: SalesGroupService,
    private salesCategoryService: SalesCategoryService,
    private salesProductService: SalesProductsService,
    private tagService: TagService,
    private widgetProductGroupService: WidgetProductGroupService,

    protected spinnerService: SpinnerService,
    protected alertService: AlertsService,
    protected baseWidgetCrudService: BaseWidgetCrudService,
    modalRef: BsModalRef,
    public modalService: ModalService,
    formUtilityService: FormUtilityService,
    private salesSizeService: SalesSizeService,

    protected extensionValidation: ExtensionValidationService) {

    super(modalRef, modalService, formUtilityService);
    forEach(DomainConstants.GroupListBehavior, (behavior) => {
      this.groupListBehaviors.push(behavior);
    });

    forEach(DomainConstants.BulletTypes, (value, key) => {
      this.displayListStyles.push({ name: startCase(key), value: value });
    });
  }

  ngOnInit(): void {
    this.getHeight();
    $(window).resize(() => {
      this.getHeight();
    });
    this.initProperties();
    this.loadData();
  }
  getHeight() {
    this.containerHeight = $(window).height() - (280) + 'px';
    this.itemContainerHeight = $(window).height() - (340) + 'px';
  }

  initProperties() {
    this.widgetConfiguration = this.menuWidgetService.getNewWidgetSaveModel();
    this.widgetDetails = this.menuWidgetService.getNewGridsterItem();
    this.widgetStyle = this.menuWidgetService.getNewWidgetStyleConfig();
    this.widgetConfig = this.menuWidgetService.getNewBaseConfig();
    this.bannerWidgetConfig = this.menuWidgetService.getNewWidgetHeaderConfig();
    this.itemsArray.push({
      widgetGroup: null,
      salesGroupIds: [],
      salesCategoryIds: [],
      SalesTagsIds: [],
      ProductOrdinal: [],
    });
  }

  loadData() {
    let observables: Array<Observable<any>> = [];
    if(this.widgetId) {
      observables.push(this.widgetProductGroupService.getAllProductGroupByWidget(this.widgetId));
      observables.push(this.menuWidgetService.getWidgetDetails(this.widgetId));
    } else {
      observables.push(of(null));
      observables.push(of(null));
    }
    observables.push(this.salesSizeService.getSalesSizes());

    this.spinnerService.show();
    forkJoin(observables)
      .pipe(finalize(() => this.spinnerService.hide()))
      .subscribe({
        next: ([widgetGroupList, widgetSaveModel, salesSizes]:
          [Array<WidgetProductGroups>, WidgetSaveModel, Array<SalesSize>]) => {

          this.salesSizes = salesSizes;
          if (this.widgetId) {
            this.widgetGroupList = widgetGroupList;

            this.widgetConfiguration = widgetSaveModel;
            this.widgetType = this.menuWidgetTypes[this.widgetConfiguration.WidgetType];
            this.setWidgetProperties(this.widgetConfiguration);
          }
        },
        error: this.alertService.showApiError
      })
  }

  loadSalesItems() {
    if(this.salesItemsLoaded)
      return;
    let observables: Array<Observable<any>> = [];
    observables.push(this.salesGroupService.getSalesGroups());
    observables.push(this.salesCategoryService.getSalesCategories(true));
    observables.push(this.salesProductService.getSalesProductsWithTags());
    observables.push(this.tagService.getAll());

    this.spinnerService.show();
    forkJoin(observables)
      .pipe(finalize(() => this.spinnerService.hide()))
      .subscribe({
        next: ([salesGroups, salesCategories, salesProducts, tags]:
          [Array<SalesGroup>, Array<SalesCategory>, Array<SalesProduct>, Array<Tag>]) => {

          this.salesGroupList = salesGroups;
          this.salesCategoryList = salesCategories;
          this.salesProductList = salesProducts;
          this.tagList = tags;
          this.salesItemsLoaded = true;
        },
        error: this.alertService.showApiError
      })
  }

  setWidgetProperties(widget: WidgetSaveModel) {
    this.widgetDetails = JSON.parse(widget.WidgetDetails) as GridsterItem;
    this.setWidgetConfig(widget.WidgetType, JSON.parse(widget.WidgetConfig));
    this.setWidgetStyle(widget.WidgetType, JSON.parse(widget.WidgetStyle));
    this.setItemArray(widget);
    this.widgetConfiguration.SalesProductId = widget.SalesProductId ? widget.SalesProductId : null;
  }

  setWidgetConfig(widgetType: string, widgetConfig: any) {
    switch (widgetType) {
      case this.menuWidgetTypes.ImageList.Name:
        this.widgetConfig = widgetConfig ? widgetConfig as ImageListWidgetConfig : this.menuWidgetService.getNewImageListWidgetConfig();
        break;

      case this.menuWidgetTypes.Item.Name:
        this.widgetConfig = widgetConfig ? widgetConfig as ItemWidgetConfig : this.menuWidgetService.getNewItemWidgetConfig();
        break;

      case this.menuWidgetTypes.List.Name:
        this.widgetConfig = widgetConfig ? widgetConfig as ListWidgetConfig : this.menuWidgetService.getNewListWidgetConfig();
        if(this.widgetConfig['ShowIndentedText'] == null) {
          this.widgetConfig['ShowIndentedText'] = true;
        }
        this.setListSeparator();
        break;

      case this.menuWidgetTypes.Text.Name:
        this.widgetConfig = widgetConfig ? widgetConfig as TextWidgetConfig : this.menuWidgetService.getNewTextWidgetConfig();
        break;

      case this.menuWidgetTypes.Image.Name:
        this.widgetConfig = widgetConfig ? widgetConfig as ImageWidgetConfig : this.menuWidgetService.getNewImageWidgetConfig();
        break;
      
      case this.menuWidgetTypes.Video.Name:
        this.widgetConfig = widgetConfig ? widgetConfig as VideoWidgetConfig : this.menuWidgetService.getNewImageWidgetConfig();
        break;

      case this.menuWidgetTypes.Banner.Name:
        this.bannerWidgetConfig = widgetConfig ? widgetConfig as WidgetHeaderConfig : this.bannerWidgetConfig;
        break;

      default:
        this.widgetConfig = widgetConfig ? widgetConfig as WidgetBaseConfig : this.widgetConfig;
    }
  }

  setListSeparator() {
    if(!this.widgetConfig['Separator']) {
      this.listSeparatorType = 'Number';
      this.listSeparators = null;
    }
    else if(isNaN(parseInt(this.widgetConfig['Separator']))) {
      this.listSeparatorType = 'Range';
      this.listSeparators = this.widgetConfig['Separator'].split(',');
    } else {
      this.listSeparatorType ='Number';
      this.listSeparators = parseInt(this.widgetConfig['Separator']);
    }
  }

  setWidgetStyle(widgetType: string, widgetStyle: any) {
    switch (widgetType) {
      case this.menuWidgetTypes.ComboItemList.Name:
        this.widgetStyle = widgetStyle ? widgetStyle as ComboListWidgetStyleConfig : this.menuWidgetService.getNewComboListWidgetStyleConfig();
        break;

      case this.menuWidgetTypes.Image.Name:
        this.widgetStyle = widgetStyle ? widgetStyle as ImageWidgetStyleConfig : this.menuWidgetService.getNewImageWidgetStyle();
        break;

      case this.menuWidgetTypes.Video.Name:
        this.widgetStyle = widgetStyle ? widgetStyle as ImageWidgetStyleConfig : this.menuWidgetService.getNewImageWidgetStyle();
        break;

      case this.menuWidgetTypes.Item.Name:
        this.widgetStyle = widgetStyle ? widgetStyle as ItemWidgetStyleConfig : this.menuWidgetService.getNewProductWidgetStyle();
        this.widgetStyle['TagConfig'] = this.widgetStyle['TagConfig'] ?? this.menuWidgetService.getNewTagConfig();
        break;

      case this.menuWidgetTypes.List.Name:
        this.widgetStyle = widgetStyle ? widgetStyle as ListWidgetStyleConfig : this.menuWidgetService.getNewListWidgetStyle();
        this.widgetStyle['DisplayListStyle'] = this.widgetStyle['DisplayListStyle']?.toLowerCase();
        this.widgetStyle['SeparatorStyle'] = this.widgetStyle['SeparatorStyle'] ?? this.menuWidgetService.getNewSeparatorStyle();
        this.widgetStyle['PriceTableConfig'] = this.widgetStyle['PriceTableConfig'] ?? this.menuWidgetService.getNewPriceTableConfig();
        this.widgetStyle['TagConfig'] = this.widgetStyle['TagConfig'] ?? this.menuWidgetService.getNewTagConfig();
        break;

      case this.menuWidgetTypes.TileList.Name:
        this.widgetStyle = widgetStyle ? widgetStyle as TilesWidgetStyleConfig : this.menuWidgetService.getNewTilesWidgetStyle();
        break;

      default:
        this.widgetStyle = widgetStyle ? widgetStyle as WidgetStyleConfig : this.menuWidgetService.getNewWidgetStyleConfig();
    }
  }

  setItemArray(widget: WidgetSaveModel) {
    // converts value to { "{{widgetProductGroupId}}": array<number> }
    const groupByGroups = mapValues(groupBy(widget.SalesGroups, 'WidgetProductGroupId'), group => group.map(g => g.SalesGroupId));
    const groupByCategory = mapValues(groupBy(widget.SalesCategories, 'WidgetProductGroupId'), group => group.map(g => g.SalesCategoryId));
    const groupByTags = mapValues(groupBy(widget.SalesTags, 'WidgetProductGroupId'), group => group.map(g => g.SalesTagsId));

    // get groupIds
    const widgetProductGroupIds = ["null", ... this.widgetGroupList.map(g => g.Id.toString())];

    // map
    this.itemsArray = map(widgetProductGroupIds, (id) => {
      return {
        widgetGroup: !isNaN(parseInt(id)) ? this.widgetGroupList.find(g => g.Id == parseInt(id)) : null,
        salesGroupIds: groupByGroups[id] ?? [],
        salesCategoryIds: groupByCategory[id] ?? [],
        SalesTagsIds: groupByTags[id] ?? [],
        ProductOrdinal: this.getProductOrdinalFromConfig(id),
      }
    })
    this.itemsArray = sortBy(this.itemsArray, i => i.widgetGroup?.Ordinal);
  }

  getProductOrdinalFromConfig(id: string): Array<number> {
    const ordinal = this.widgetConfig['ProductDisplayOrdinal']?.find((p:ProductOrdinal) => id == "null" ? p.GroupId == null : p.GroupId?.toString() == id)
    return ordinal?.Ordinal ?? []
  }

  saveWidget(isValid: boolean) {
    if (!isValid) {
      return;
    }

    const preparedData = this.prepareDataToSave();
    this.spinnerService.show();
    this.baseWidgetCrudService.insertOrUpdate(preparedData, this.filesToUpload)
    .pipe(finalize(() => this.spinnerService.hide()))
    .subscribe({
      next: (response) => {
        const responseData = response;
        this.hide({ shouldReload: true, data: responseData })
      },
      error: this.alertService.showApiError
    })
  }

  prepareDataToSave(): WidgetSaveModel {
    const preparedWidget: WidgetSaveModel = this.menuWidgetService.getNewWidgetSaveModel();

    preparedWidget.Id = this.widgetId;
    preparedWidget.MenuDisplayId = this.menuId;
    preparedWidget.Name = this.widgetConfiguration.Name;
    preparedWidget.WidgetType = this.widgetConfiguration.WidgetType;
    preparedWidget.WidgetDetails = JSON.stringify(this.widgetDetails);
    preparedWidget.WidgetStyle = JSON.stringify(this.widgetStyle);
    if (this.widgetConfiguration.WidgetType != this.menuWidgetTypes.Group.Name) {
      this.prepareWidgetProductGroupItems(preparedWidget);
      preparedWidget.SalesProductId = this.widgetConfiguration.SalesProductId ?? 0;
      preparedWidget.ComboItems = this.widgetConfiguration.ComboItems ? this.widgetConfiguration.ComboItems : [];
      preparedWidget.WidgetConfig = this.prepareWidgetConfig(this.widgetType.Name)
    } else {
      preparedWidget.WidgetConfig = null;
    }
    return preparedWidget;
  }

  prepareWidgetConfig(widgetType: string): string {

    if (widgetType === this.menuWidgetTypes.Banner.Name) {
      return JSON.stringify(this.bannerWidgetConfig);
    }
    if (widgetType === this.menuWidgetTypes.List.Name) {
      this.widgetConfig['Separator'] = Array.isArray(this.listSeparators) ? this.listSeparators.join(',') : this.listSeparators;
      this.widgetConfig['ShowInlinePrices'] = this.widgetConfig['ShowInlinePrices'] ? this.widgetConfig['ShowInlinePrices'].map(Number) : [];
    }
    return JSON.stringify(this.widgetConfig);
  }

  prepareWidgetProductGroupItems(widgetSaveModel: WidgetSaveModel) {
    widgetSaveModel.SalesGroups = [];
    widgetSaveModel.SalesCategories = []
    widgetSaveModel.SalesTags = [];
    this.widgetConfig['ProductDisplayOrdinal'] = [];
    forEach(this.itemsArray, item => {
      item.salesGroupIds.forEach(groupId => {
        widgetSaveModel.SalesGroups.push({ SalesGroupId: groupId, WidgetProductGroupId: item.widgetGroup?.Id })
      })

      item.salesCategoryIds.forEach(groupId => {
        widgetSaveModel.SalesCategories.push({ SalesCategoryId: groupId, WidgetProductGroupId: item.widgetGroup?.Id })
      })

      item.SalesTagsIds.forEach(groupId => {
        widgetSaveModel.SalesTags.push({ SalesTagsId: groupId, WidgetProductGroupId: item.widgetGroup?.Id })
      })

      if(item.widgetGroup?.Id) {
        widgetSaveModel.WidgetProductGroups.push(item.widgetGroup);
      }

      if(item.ProductOrdinal.length) {
        this.widgetConfig['ProductDisplayOrdinal'].push({
          GroupId: item.widgetGroup ? item.widgetGroup.Id : null,
          Ordinal: item.ProductOrdinal,
        })
      }
    })
  }

  addNewGroup(item: WidgetGroupedItem = null) {
    const modalRef = this.modalService.getModalWrapper(WidgetGroupConfigComponent);
    const modal = modalRef.show({
      animated: false,
      class: 'vertical-center',
      initialState: {
        widgetProductGroupId: item ? item.widgetGroup.Id : 0,
        widgetId: this.widgetId
      }
    });

    modal.close.subscribe((res) => {
      if (res?.shouldReload) {
        if (item) {
          const currentOrdinal = item.widgetGroup.Ordinal;
          item.widgetGroup = res.widgetProductGroup;
          item.widgetGroup.Ordinal = currentOrdinal;
        } else {
          this.widgetGroupList.push(res.widgetProductGroup)
          this.itemsArray.splice(-1, 0, {
            widgetGroup: res.widgetProductGroup,
            salesGroupIds: [],
            salesCategoryIds: [],
            SalesTagsIds: [],
            ProductOrdinal: [],
          })
        }
      }
    })
  }

  onDeleteItem(index: number, item: WidgetGroupedItem) {
    const confirmDelete = this.modalService.show(ConfirmDeleteModalComponent, {
      animated: false,
      class: 'vertical-center',
      'backdrop': 'static',
      initialState: {
        message: StringUtils.format(Messages.ConfirmDeleteWidgetProductGroup, { groupName: item.widgetGroup.Name })
      }
    });

    confirmDelete.close.subscribe(res => {
      if (res && res.shouldDelete) {
        this.spinnerService.show();
        this.widgetProductGroupService.delete(item.widgetGroup.Id)
        .pipe(finalize(() => this.spinnerService.hide()))
        .subscribe({
          next: (_res) => {
            this.itemsArray.splice(index, 1);
          },
          error: this.alertService.showApiError
        })
      }
    })
  }

  onCancel() {
    this.hide({ shouldReload: false })
  }

  onSeparatorChange() {
    if(this.listSeparatorType == 'Number') {
      this.listSeparators = null;
    } else {
      this.listSeparators = [];
    }
  }

  handleFileInput = (files: FileList, id: string, currentFileName: string): string  => {
    const file = files.item(0);
    let fileToUploadName = file ? file.name : '';

    if (!files && !files[0].name) {
      return currentFileName;
    }

    if (this.extensionValidation.isInvalidExtension(fileToUploadName, ['.jpg', '.bmp', '.png', '.ico', '.gif', ".mp4", ".ogg", ".webm", ".mpg", ".m4v", ".avi"])) {
      this.removeIcon(id, currentFileName);
      return '';
    }

    if (files && files[0].name) {
      const reader = new FileReader();
      reader.onload = function (e) {
        $('#image_' + id).attr('src', reader.result);
      }

      reader.readAsDataURL(files[0]);
    }

    return this.appendTimeStampAndUpdateUploadArray(fileToUploadName, file, currentFileName)
  }

  appendTimeStampAndUpdateUploadArray(fileToUploadName: string, file: File, currentFileName: string) {

    let timeStamp = moment(Date.now()).format('yyyyMMDDHHmmssSSSS');
    fileToUploadName = fileToUploadName ? timeStamp + '-' + fileToUploadName : '';

    let fileAlreadyInUploadIndex = this.filesToUpload.findIndex(x => x.fileNameWithTimeStamp == currentFileName);

    if (fileAlreadyInUploadIndex != -1) {
      this.filesToUpload.splice(fileAlreadyInUploadIndex, 1);
    }
    this.filesToUpload.push({ fileNameWithTimeStamp: fileToUploadName, file: file });

    return fileToUploadName;
  }

  removeIcon = (id: string, currentFileName: string) => {
    $('#' + id).val(null);
    let index = this.filesToUpload.findIndex(x => x.fileNameWithTimeStamp == currentFileName);
    if(index != -1) {
      this.filesToUpload.splice(index, 1);
    }
  }

  trackByIdx(index: number, obj: any): any {
    return index;
  }

  widgetGroupDrop(event: CdkDragDrop<any>) {
    if(event.currentIndex == this.itemsArray.length - 1) {
      return;
    }
    moveItemInArray(this.itemsArray, event.previousIndex, event.currentIndex);
  }

  configOrdinal(item: WidgetGroupedItem) {
    const salesProducts = this.findProductsInWidgetGroup(item);
    const modalRef = this.modalService.getModalWrapper(ProductOrdinalConfigComponent);
    const modal = modalRef.show({
      animated: false,
      class: 'vertical-center',
      initialState: {
        widgetType: this.widgetType,
        productOrdinal: item.ProductOrdinal,
        showInlinePrices: [... this.widgetConfig['ShowInlinePrices'] ?? []],
        salesProducts: salesProducts,
      }
    });
    modal.close.subscribe(res => {
      if(res?.shouldReload) {
        item.ProductOrdinal = res.productOrdinal ?? [];
        if (this.widgetType.Name == this.menuWidgetTypes.List.Name) {
          this.widgetConfig['ShowInlinePrices'] = res.showProductPrices;
        }
      }
    });
  }

  setProductOrdinal(item: WidgetGroupedItem) {
    if(!item.ProductOrdinal?.length) {
      return;
    }
    this.spinnerService.show();

    let tempProductOrdinal = this.findProductsInWidgetGroup(item).map(to => to.id);

    // remove products that no longer belong to list and merge keeping original order.
    item.ProductOrdinal = item.ProductOrdinal.filter(po => tempProductOrdinal.includes(po));
    item.ProductOrdinal = union(item.ProductOrdinal, tempProductOrdinal);

    this.spinnerService.hide();
  }

  findProductsInWidgetGroup(item: WidgetGroupedItem): SalesProduct[] {
    let productOrdinal: SalesProduct[] = [];
    const categories = this.salesCategoryList
      .filter(c => item.salesGroupIds.includes(c.sales_group_id) || item.salesCategoryIds.includes(c.id))
      .map((c) => c.id);

    if (item.SalesTagsIds.length) {
      if (categories.length) {
        productOrdinal = this.salesProductList.filter(p => categories.includes(p.CategoryId) && p.SalesProductTags.some(t => item.SalesTagsIds.includes(t.TagId)))

      } else {
        productOrdinal = this.salesProductList.filter(p => p.SalesProductTags.some(t => item.SalesTagsIds.includes(t.TagId)))
      }
    } else {
      productOrdinal = this.salesProductList.filter(p => categories.includes(p.CategoryId))
    }

    return productOrdinal.sort((a, b) => (a.MenuDisplayText ? a.MenuDisplayText : a.Name).localeCompare(b.MenuDisplayText ? b.MenuDisplayText : b.Name));
  }
}
