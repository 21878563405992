import { Component, OnInit, Output, EventEmitter, Input, OnDestroy, ViewChild } from '@angular/core';
import { ApplicationStateService, SettingParam, DomainConstants, ParameterService, AlertsService, Messages, RabbitMQService, OrderService, Permissions, Levels, InfoModalComponent, ServeMethod, LoggerService } from 'src/app/shared';
import * as _ from 'lodash';
import {
  productPrintToggleOn, productPrintToggleOff, navigateBackward, envelopeSolid, envelopeRegular,
  orderinfoInfoCircle, addProductPlusSquare, requireNameToggleOff, requireNameToggleOn, bars,
  serveOrderCheckSquare, printCheckPrinting, dispatchTruck, scheduledOrderClock, accountsTablesIdBadge,
  toGoShoppingBag, loyalAccountTermCrown, functionButtonCaretDown, functionButtonFilter, tablesSolarPanel,
  tabletAlt
} from 'src/app/shared/components/icon';
import { OrderNavigationButton } from '../../interface/order-navigation-button';
import { PrintCheckComponent } from 'src/app/print-check/components';
import { SetAccountComponent } from 'src/app/set-account';
import { finalize } from 'rxjs/operators';
import { forkJoin, Subscription } from 'rxjs';
import { DispatchComponent } from 'src/app/dispatch/components/dispatch/dispatch.component';
import { OrderEventBroadcastingService } from 'src/app/shared/services/order-event-broadcasting.service';
import { ModalService } from 'src/app/shared/components/modal';
import { SpinnerService } from 'src/app/shared/components/spinner';
import { OrderInvoice } from '../../interface';
import { KeyboardComponent } from '../../../../app/keyboard/components';
import { TerminalsService } from 'src/app/configurator';
import { TableSelectionComponent } from '../../table-selection/table-selection.component';
import { SettleComponent } from '../settle/component';
import { UntilDestroy } from '@ngneat/until-destroy';
import { StringUtils } from 'src/app/shared/string-utils/string-utils';
import { LayoutDesignersService } from 'src/app/shared/services/layout-designers.service';
import { FilterComponent } from 'src/app/filter/filter.component';
import { DietaryWarning, Tag } from 'src/app/information-management';
import { MessageBoxComponent } from 'src/app/inbox-messages/components/message-box/message-box.component';
import { TimeEntryComponent } from '../special-functions/time-entry';
import { find, some } from 'lodash';
import { MenuWidget } from 'src/app/menu-display';
import { KioskListComponent } from 'src/app/order-kiosk/components/kiosk-list/kiosk-list.component';
declare let $: any;
@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'pos-function-buttons',
  templateUrl: './function-buttons.component.html',
  styleUrls: ['./function-buttons.component.scss']
})
export class FunctionButtonsComponent implements OnInit, OnDestroy {
  @Input('buttons') buttons: Array<OrderNavigationButton> = [];

  @Output('addItem') addItem = new EventEmitter<any>();
  @Input('orderDetail') orderDetail;
  @Input('cartOrderDataAccountDetails') cartOrderDataAccountDetails;
  @Input('infoMode') infoMode: boolean = false;
  canAddProduct: boolean = false;
  @Output('infoModeChange') infoModeChange = new EventEmitter<boolean>();
  @Output() filteredData = new EventEmitter<any>();
  noOfInvisibleButton: number = 0;
  accountVisualizations: Array<any> = [];
  @Input() serveMethods: Array<ServeMethod> = [];
  accountTerm = '';
  filterMode = false;
  filteredWarningsAndTags: { Tags: Array<Tag>, DietaryWarnings: Array<DietaryWarning>, Widgets: Array<MenuWidget> } = { Tags: [], DietaryWarnings: [], Widgets: [] };
  tableTerm = '';
  @ViewChild('labelPrintingToolTip') private labelPrintingToolTip: any;
  // rabbitMq subscriptions
  rabbitMqProductLabelPrintSubscription: Subscription;
  rabbitMqRequireNameOnOrderTemporaryDisableSubscription: Subscription;
  newMessage: boolean = false;

  constructor(private applicationStateService: ApplicationStateService,
    private eventBroadcastingService: OrderEventBroadcastingService,
    private modalService: ModalService,
    private parameterService: ParameterService,
    private alertService: AlertsService,
    private spinnerService: SpinnerService,
    private rabbitMQService: RabbitMQService,
    private orderService: OrderService,
    private terminalService: TerminalsService,
    private layoutDesignerService: LayoutDesignersService,
    private loggerService: LoggerService) { }
  settingParam: SettingParam;
  //messageFound: boolean = false;
  modal: any = null;
  productShowInfo: boolean = false;
  functionButtonDetails: any = [];
  eventsSubscription: Array<Subscription> = [];
  saveParameterProductLabelPrinting: any = {};
  saveParameterRequireNameOnOrderTemporaryDisable: any = {};
  showProductPrintButton: boolean = true;
  showProductPrintIcon: boolean = true;
  isDisableRequireNameOnOrder: boolean = false;
  isShowNameDisableButton: boolean = true;
  showMoreButtonOption: boolean = true;
  toolTipText: string = '';
  keyboardValue: string = '';
  mappedLayout: Array<any> = [];
  isAllowCreditCard: boolean = false;
  isCashdrawerMapped: boolean = false;
  @ViewChild('setAccount') setAccount: SetAccountComponent;
  orderKeyboardOptions = { isOpen: false, capsLock: false, title: 'Enter order name', maxLength: 90 };
  public icons = {
    productPrintToggleOff,
    productPrintToggleOn,
    navigateBackward,
    envelopeSolid,
    envelopeRegular,
    orderinfoInfoCircle,
    addProductPlusSquare,
    requireNameToggleOff,
    requireNameToggleOn,
    bars,
    functionButtonCaretDown,
    serveOrderCheckSquare,
    printCheckPrinting,
    dispatchTruck,
    scheduledOrderClock,
    accountsTablesIdBadge,
    toGoShoppingBag,
    loyalAccountTermCrown,
    functionButtonFilter,
    tablesSolarPanel,
    tabletAlt
  };

  permission = {
    orderEntryToGo: Permissions.OrderEntryToGo,
    orderSchedule: Permissions.OrderEntryScheduleOrder,
    dispatch: Permissions.ManageDispatch,
    orderRequiredName: Permissions.OrderRequiredName,
    orderDisableLabelPrint: Permissions.OrderDisableLabelPrint,
    readOnlyLevel: Levels.ReadOnly,
    editLevel: Levels.Edit,
    accessLevel: Levels.Access
  };

  ngOnInit() {
    this.setSettings();
    this.prepareFunctionButtons();
    //this.subscribeMessageButtonEvent();
    this.rabbitMqSubscriptions();
    this.getParameters();
    this.onAddOrderSubscriptions();
    this.onNewMessageSubscription();
    setTimeout(() => {
      this.noOfInvisibleButton = $('.function-button button').length;
    });
  }

  ngOnDestroy() {
    _.forEach(this.eventsSubscription, (subscribe) => {
      if (subscribe) {
        subscribe.unsubscribe();
      }
    });
  }

  setSettings() {
    this.settingParam = this.applicationStateService.settingParam;
    this.accountTerm = this.settingParam.AccountTerm;
    this.tableTerm = this.settingParam.TableTerm;
    this.isShowNameDisableButton = (this.settingParam.RequireNameOnOrder == DomainConstants.RequireNameOnOrder.AT_RING_UP
      || this.settingParam.RequireNameOnOrder == DomainConstants.RequireNameOnOrder.BEFORE_ORDER);
    if (this.settingParam.AllowCreditCards && (this.settingParam.CreditCardTerminal.Id != 0 ||
      this.settingParam.CreditCardType === DomainConstants.CreditCardType.Peripheral)) {
      this.isAllowCreditCard = true;
    }
    if (this.settingParam.CashDrawerPrinter && this.settingParam.CashDrawerPrinter.Id) {
      this.isCashdrawerMapped = true;
    }
  }

  onAddOrderSubscriptions() {
    this.eventsSubscription.push(this.eventBroadcastingService.reloadOrder.subscribe((orderDetail: OrderInvoice) => {
      this.orderDetail = orderDetail;
    }));
    this.eventsSubscription.push(this.eventBroadcastingService.newOrder.subscribe((orderDetail: OrderInvoice) => {
      this.orderDetail = this.orderService.getNewOrderInvoiceDetails();
      this.filterMode = false;
      this.filteredWarningsAndTags = { DietaryWarnings: [], Tags: [], Widgets: [] };
      this.filteredData.emit(this.filteredWarningsAndTags);
    }));
  }

  onNewMessageSubscription() {
    this.eventBroadcastingService.inboxMessage.subscribe({
      next: (res: boolean) => {
        if (res && !this.eventBroadcastingService.isMessageDialogOpen) {
          this.newMessage = true;
        } else {
          this.newMessage = false;
        }
      }
    })
  }

  getParameters() {
    this.spinnerService.show();
    const parametersObservable = [];
    parametersObservable.push(this.parameterService.getParameterForName('ProductLabelPrinting'));
    parametersObservable.push(this.parameterService.getParameterForName('RequireNameOnOrderTemporaryDisable'));
    forkJoin(parametersObservable)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (responses: any) => {
          if (responses) {
            if (responses[0]) {
              this.showProductPrintButton = responses[0].value == 0 ? false : true;
              this.showProductPrintIcon = responses[0].value == 0 ? false : (responses[0].value == 1 ? false : true);
              this.productPrintToolTipText();
              this.saveParameterProductLabelPrinting = {
                Id: responses[0].Id,
                TerminalId: responses[0].TerminalId,
                KeyName: responses[0].key,
                ParamValue: responses[0].value
              };
            }
            if (responses[1]) {
              this.saveParameterRequireNameOnOrderTemporaryDisable = {
                Id: responses[1].Id,
                TerminalId: responses[1].TerminalId,
                KeyName: responses[1].key,
                ParamValue: responses[1].value == 'true' ? true : false
              };
              this.isDisableRequireNameOnOrder = this.saveParameterRequireNameOnOrderTemporaryDisable.ParamValue;
            }
          }
        }, error: this.alertService.showApiError
      });
  }

  ngOnChanges(change) {
    if (change.buttons?.previousValue && change.buttons.currentValue != change.buttons.previousValue && !this.infoMode && this.buttons.length) {
      this.canAddProduct = true;
      _.forEach(this.buttons, (button) => {

        if (!button.Toggle
          && button.SpecialFunction != DomainConstants.ButtonFunctionTypes.COMMENT_ENTRY.Name
          && button.SpecialFunction != DomainConstants.ButtonFunctionTypes.PLACEHOLDER.Code
          && button.SpecialFunction != DomainConstants.ButtonFunctionTypes.NON_FUNCTIONAL.Name
          && button.SpecialFunction != DomainConstants.ButtonFunctionTypes.DEDUCT_INVENTORY_PRODUCT.Name
          && !(some(button.Behaviors, { BehaviorName: DomainConstants.ButtonBehaviors.UncheckAllButtons.Value }))) {
          this.canAddProduct = false;
        }
      });
    }
  }
  prepareFunctionButtons() {
    this.functionButtonDetails = [];
    if (this.settingParam?.FunctionButtons) {
      let functionButtons = this.settingParam.FunctionButtons.split(',');
      _.forEach(functionButtons, (functionButton) => {
        if (functionButton) {
          _.forEach(DomainConstants.FunctionButtons, (button) => {
            if (functionButton == button.Selection) {
              const obj = {
                Name: button.Name,
                Selection: button.Selection
              }
              this.functionButtonDetails.push(obj);
            }
          });
        }
      })
    }
    _.forEach(DomainConstants.FunctionButtons, (button) => {
      const index = _.findLastIndex(this.functionButtonDetails, (functionBtn: any) => {
        return functionBtn.Selection == button.Selection;
      });
      if (index < 0 && button.Selection != 'None') {
        const obj = {
          Name: button.Name,
          Selection: button.Selection
        }
        this.functionButtonDetails.push(obj);
      }
    });
    const isNoneOptionAvailable = _.find(this.functionButtonDetails, (button) => {
      return button.Selection === 'None';
    });
    this.showMoreButtonOption = isNoneOptionAvailable ? false : true;
  }

  showInventoryInfoOnProductButtons() {
    this.infoMode = !this.infoMode;
    this.infoModeChange.emit(this.infoMode);
  }

  sendMail() {
    //this.messageFound = false;
    const modalRef = this.modalService.getModalWrapper(MessageBoxComponent);
    this.modal = modalRef.show({
      animated: false,
      'backdrop': 'static',
      class: 'vertical-center modal-lg'
    });
    this.modal.close.subscribe(res => {
      this.modal = null;
      //this.dataSubject.next(res ? res : {});
    });
  }

  filterProducts() {
    this.filterMode = true;
    const modalRef = this.modalService.getModalWrapper(FilterComponent);
    this.modal = modalRef.show({
      animated: false,
      'backdrop': 'static',
      class: 'vertical-center modal-lg',
      initialState: {
        filteredWarningsAndTags: this.filteredWarningsAndTags
      }
    });
    this.modal.close.subscribe(res => {
      this.modal = null;
      this.filterMode = res?.IsFilterApplied;
      if (res?.filteredData) {
        this.filteredWarningsAndTags = res.filteredData;
        this.filteredData.emit(res.filteredData);
      }
    });
    this.modal.filteredData.subscribe(res => {
      this.filterMode = res?.IsFilterApplied;
      if (res) {
        this.filteredWarningsAndTags = res.filteredData;
        this.filteredData.emit(res.filteredData);
      }
    });
  }

  addTheseItem() {
    this.addItem.emit();
  }

  printCheckPopupOpen() {
    const modalRef = this.modalService.getModalWrapper(PrintCheckComponent);
    const modal = modalRef.show({
      animated: false,
      class: 'vertical-center',
      initialState: {
        orderId: this.orderDetail.Id.Value,
      }
    });
    modal.close.subscribe(res => {
    });

  }

  redirectToAccount(isTypeAccount) {
    if (!this.settingParam.LoyaltyProgramRequiresMemberInformation && this.settingParam.IsEnableLoyaltyCardProgram && isTypeAccount) {
      this.setAccount.addAccountForLoyaltyCard();
    } else {
      this.openAccountSelectionModel(isTypeAccount);
    }
  }

  openAccountSelectionModel(isTypeAccount: boolean) {
    return this.modalService.show(SetAccountComponent, {
      animated: false,
      class: 'vertical-center modal-lg',
      initialState: {
        orderId: this.orderDetail.Id.Value,
        selectedAccountId: isTypeAccount && this.orderDetail.CustomerAccount != null ? this.orderDetail.CustomerAccount.Id : (this.orderDetail.Account != null ? this.orderDetail.Account.Id : 0),
        isTypeAccount: isTypeAccount
      }
    });
  }

  redirectToTable() {
    const modalRef = this.modalService.getModalWrapper(TableSelectionComponent);
    const modal = modalRef.show({
      keyboard: false,
      animated: false,
      class: 'vertical-center modal-max-width-95',
      initialState: {
        orders: this.applicationStateService.unservedOrdersData,
        mappedLayout: this.mappedLayout,
        accounts: this.accountVisualizations
      }
    });
    modal.close.subscribe(res => {
      if (res?.accountId) {
        this.selectTable(res);
      }
    });
  }

  selectTable = (table) => {
    if (this.orderDetail?.Id && this.orderDetail.Id.Value > 0) {
      this.spinnerService.show();
      let updatedAccount = { OrderId: this.orderDetail.Id.Value, AccountId: table.accountId };
      this.orderService.updateAccountId(updatedAccount)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: (response) => {
            this.eventBroadcastingService.onSetAccountCompleted(table);
          }, error: this.alertService.showApiError
        });
    } else {
      this.eventBroadcastingService.onSetAccountCompleted(table);
    }
  }

  getMappedLayoutWithTerminal() {
    this.spinnerService.show();
    this.mappedLayout = [];
    const layoutObservable = [];
    let terminalConfig;
    layoutObservable.push(this.terminalService.getTerminalProperties(this.applicationStateService.terminalId));
    layoutObservable.push(this.layoutDesignerService.getAll());
    layoutObservable.push(this.layoutDesignerService.getAccountVisualizations());
    forkJoin(layoutObservable)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (responses: any) => {
          if (responses) {
            terminalConfig = responses[0] ? responses[0] : [];
            const layouts = [];
            if (responses[1]) {
              _.forEach(responses[1], (layout) => {
                if (layout.IsActive) {
                  const selectedLayout = {
                    id: layout.Id,
                    label: layout.Name
                  };
                  layouts.push(selectedLayout);
                }
              });
            }
            if (responses[2]) {
              this.accountVisualizations = responses[2];
            }
            let availableLayouts;
            _.forEach(terminalConfig, (terminalProperty) => {
              if (DomainConstants.TerminalProperties.TABLE_LAYOUT.Key === terminalProperty.PropertyKey) {
                availableLayouts = terminalProperty.PropertyValue.split(',').map((item) => {
                  return parseInt(item, 10);
                });
              }
            });
            // let mappedLayout = [];
            _.forEach(availableLayouts, (item) => {
              const currentLayout = _.find(layouts, (layout) => {
                return layout.id == item;
              });
              if (currentLayout) {
                this.mappedLayout.push(item);
              }
            });
          }
          this.redirectToTable();
        }, error: this.alertService.showApiError
      });
  }

  productPrintToolTipText() {
    this.toolTipText = '';
    if (this.showProductPrintIcon) {
      this.toolTipText = StringUtils.format(Messages.ProductPrintStatus, { 'currentStatus': 'On', 'nextStatus': 'Off' });
    } else {
      this.toolTipText = StringUtils.format(Messages.ProductPrintStatus, { 'currentStatus': 'Off', 'nextStatus': 'On' });
    }

  }
  productPrint() {
    this.saveParameterProductLabelPrinting.ParamValue = this.showProductPrintIcon ? 1 : 2;
    if (this.showProductPrintIcon) {
      this.showProductPrintIcon = false;
    } else {
      this.showProductPrintIcon = true;
    }
    this.productPrintToolTipText();
    this.labelPrintingToolTip.hide();
    this.parameterService.saveParameter(this.saveParameterProductLabelPrinting)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: response => {

        }, error: this.alertService.showApiError
      });
  }

  requireNameOnOrderTemporaryDisable() {
    this.saveParameterRequireNameOnOrderTemporaryDisable.ParamValue = !this.saveParameterRequireNameOnOrderTemporaryDisable.ParamValue;
    this.isDisableRequireNameOnOrder = this.saveParameterRequireNameOnOrderTemporaryDisable.ParamValue;
    this.parameterService.saveParameter(this.saveParameterRequireNameOnOrderTemporaryDisable)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: response => {

        }, error: this.alertService.showApiError
      });
  }

  getOrderDetails(orderId, callback = null) {
    this.orderService.getOrderDetails(orderId)
      .subscribe({
        next: (res: OrderInvoice) => {
          this.orderDetail = res;
          this.eventBroadcastingService.reloadOrder.emit(res);
          if (callback) {
            callback();
          }
        }
      });
  }

  onServeOrderClick = () => {
    const isOrderScheduled = this.orderDetail.ScheduleDate ? new Date(this.orderDetail.ScheduleDate) > new Date() : false;
    const hasScheduledProducts = find(this.orderDetail.OrderItems,
      orderItem => orderItem.ScheduleTime && new Date(orderItem.ScheduleTime) > new Date());
    if (isOrderScheduled || hasScheduledProducts) {
      const modalRef = this.modalService.getModalWrapper(InfoModalComponent);
      const modal = modalRef.show({
        animated: false,
        class: 'vertical-center',
        initialState: {
          message: isOrderScheduled ? Messages.OrderIsScheduled : Messages.OrderHasScheduledProductsMessage
        }
      });
      return;
    }
    if (this.orderDetail && this.orderDetail.Id.Value) {
      this.getOrderDetails(this.orderDetail.Id.Value, this.serveOrderProcess);
    }
  }

  serveOrderProcess = () => {
    if (this.checkPromptForOrderName()) {
      this.promptForOrderName();
      return;
    }
    this.finalServeOrder();
  }

  addToGoSeat() {
    this.eventBroadcastingService.onAddToGoSeat();
  }

  promptForOrderName = () => {
    this.orderKeyboardOptions.title = "Enter order name";
    this.keyboardValue = '';
    const keyboardModalRef = this.modalService.show(KeyboardComponent, {
      animated: false,
      class: 'vertical-center modal-max-width-95',
      initialState: {
        keyboardId: 'memoKeyboard',
        isOpen: true,
        options: this.orderKeyboardOptions,
        value: this.keyboardValue
      }
    });
    keyboardModalRef.close.subscribe(res => {
      if (res && res.value) {
        this.addNameToOrder(res);
      }
    });
  }

  addNameToOrder(res) {
    this.keyboardValue = res.value;
    let orderCommand = {
      type: DomainConstants.SpecialFunctionBusinessCommand.SetName,
      value: this.keyboardValue,
      screenChoiceId: 0,
      terminalId: this.applicationStateService.terminalId
    };
    this.eventBroadcastingService.onAddItemStart.emit();
    this.orderService.specialFunction(this.orderDetail.Id.Value, orderCommand)
      .subscribe({
        next: (res: any) => {
          this.eventBroadcastingService.onAddItemComplete.emit(res.Id.Value);
          this.finalServeOrder();
        }, error: this.alertService.showApiError
      });
  }

  checkDrawerAndTerminalMapped(): boolean {
    if (!this.isCashdrawerMapped && !this.isAllowCreditCard && this.settingParam.AllowCreditCards) {
      this.modalService.show(InfoModalComponent, {
        animated: false,
        class: 'vertical-center',
        initialState: {
          message: Messages.CashDrawerAndCreditCardTerminalNotMapped
        }
      });
      return false;
    } else if (!this.isAllowCreditCard && this.settingParam.AllowCreditCards) {
      this.modalService.show(InfoModalComponent, {
        animated: false,
        class: 'vertical-center',
        initialState: {
          message: Messages.CreditCardTerminalNotMapped
        }
      });
      return false;
    } else if (!this.isCashdrawerMapped) {
      this.modalService.show(InfoModalComponent, {
        animated: false,
        class: 'vertical-center',
        initialState: {
          message: Messages.CashDrawerNotMapped
        }
      });
      return false;
    } else {
      return true;
    }
  }

  finalServeOrder = () => {
    if (this.orderDetail?.Id?.Value) {
      const dueAmount = this.orderDetail?.DueAmount ?? 0;
      if (dueAmount > 0) {
        if (this.checkDrawerAndTerminalMapped()) {
          const modalRef = this.modalService.getModalWrapper(SettleComponent);
          const modal = modalRef.show({
            animated: false,
            class: 'vertical-center modal-max-width-95',
            initialState: {
              orderId: this.orderDetail?.Id ? this.orderDetail.Id.Value : null,
              serveOrderAfterPayment: true
            }
          });
        }
      } else {
        this.serveOrder();
      }
    }
  }

  checkPromptForOrderName = (): boolean => {
    const requireNameOnOrder = this.settingParam.RequireNameOnOrder;
    if (requireNameOnOrder === DomainConstants.RequireNameOnOrder.AT_RING_UP && this.settingParam.RequireNameOnOrderTemporaryDisable) {
      const orderName = this.orderDetail.OrderAttributes.Name;
      if (!orderName || orderName.length <= 0) {
        return true;
      }
    }
  }


  serveOrder() {
    // this.spinnerService.show();
    if (this.orderDetail?.Id?.Value) {
      this.orderService.serveOrder(this.orderDetail.Id.Value, this.applicationStateService.userDetails.id)
        .pipe(finalize(() => {
          // this.spinnerService.hide();
        }))
        .subscribe({
          next: (res) => {
          }
        });
      this.eventBroadcastingService.onStartNewOrderFromOutSide();
    }
  }

  dispatch() {
    const modalRef = this.modalService.getModalWrapper(DispatchComponent);
    const modal = modalRef.show({
      animated: false,
      class: 'vertical-center modal-max-width-70',
    });
    modal.close.subscribe(res => {
    });
  }

  isVisibleFunctionButtonIcon = () => {
    this.noOfInvisibleButton = $('.function-button button').length;
    return this.noOfInvisibleButton;
  }

  moreOperationButtons(event: Event) {
    event.stopPropagation();
    $('#moreOperationButtons').toggle();
    if (this.functionButtonDetails && this.functionButtonDetails.length > 12) {
      this.noOfInvisibleButton = $('.function-button button').length;
      const isMobile = window.innerWidth <= 768;
      const marginOfPopover = (this.noOfInvisibleButton * 50 + (isMobile ? 0 : 35)) * -1;
      if (marginOfPopover) {
        $('.operation-button').attr('style', function(_i,s) { return (s || '') + `margin-top: ${marginOfPopover}px !important;` })
      }
    }
  }

  rabbitMqSubscriptions() {
    this.rabbitMqProductLabelPrintSubscription = this.rabbitMQService.subscribeToProductLabelPrintingParameterMessage$()
      .subscribe({
        next: (message: any) => {
          if (message.Payload) {
            this.showProductPrintButton = message.Payload.Message == 0 ? false : true;
            this.showProductPrintIcon = message.Payload.Message == 2 ? true : false;
            this.productPrintToolTipText();
            this.saveParameterProductLabelPrinting.ParamValue = message.Payload.Message;
          }
        }, error: () => { },
        complete: () => {
          console.log('error while connecting to RabbitMQ.');
        }
      });
    this.rabbitMqRequireNameOnOrderTemporaryDisableSubscription = this.rabbitMQService.subscribeToRequireNameOnOrderTemporaryDisableParameterChangeMessage$()
      .subscribe({
        next: (message: any) => {
          if (message.Payload) {
            this.saveParameterRequireNameOnOrderTemporaryDisable.ParamValue = message.Payload.Message == "true" ? true : false;
            this.isDisableRequireNameOnOrder = this.saveParameterRequireNameOnOrderTemporaryDisable.ParamValue;
          }
        }, error: () => {
          console.log('error while connecting to RabbitMQ.');
        }
      });

  }

  scheduleOrder() {
    if (this.orderDetail?.Id?.Value) {
      const modalRef = this.modalService.getModalWrapper(TimeEntryComponent);
      const modal = modalRef.show({
        animated: false,
        class: 'vertical-center modal-lg',
        initialState: {
          title: 'Schedule Order',
          scheduleDate: this.orderDetail.ScheduleDate ?? null,
          isRescheduleOrder: (this.orderDetail.ScheduleDate && new Date(this.orderDetail.ScheduleDate) > new Date())
        }
      });
      modal.close.subscribe((response) => {
        if (response && response.enteredTime) {
          this.updateScheduleDate(response.enteredTime);
        }
      });
    }
  }

  updateScheduleDate(scheduleDate: Date) {
    this.spinnerService.show();
    this.orderService.scheduleOrder(this.orderDetail.Id.Value, scheduleDate)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (response: OrderInvoice) => {
          this.orderDetail.ScheduleDate = response.ScheduleDate;
        }, error: this.alertService.showApiError
      });
  }

  openOrderKiosk() {
    const modalRef = this.modalService.getModalWrapper(KioskListComponent);
    const modal = modalRef.show({
      animated: false,
      'backdrop': 'static',
      class: 'vertical-center modal-lg'
    })
  }
}
