<div class="col-12 ps-0 py-1 row mx-0" *ngIf="orderData.OrderAttributes.IsCallIn">
  <div class="col-1 p-0 col-40" *ngIf="isShowIcon"></div>
  <div class="col-9 pe-0 ps-pix-5">
    <strong>
      Call In: {{ orderData.OrderAttributes.CallInOrderTime }}
    </strong>
  </div>
</div>
<div class="col-12 ps-0 py-1 row mx-0" *ngIf="orderData.OrderAttributes.Name">
  <div class="col-1 p-0 col-40" *ngIf="isShowIcon"></div>
  <div class="col-9 pe-0 ps-pix-5 text-ellipsis">
    <strong>
      Name: {{ orderData.OrderAttributes.Name }}
    </strong>
  </div>
</div>

<div class="col-12 ps-0 py-1 row mx-0" *ngIf="orderData.OrderAttributes.PagerNumber">
  <div class="col-1 p-0 col-40" *ngIf="isShowIcon"></div>
  <div class="col-9 pe-0 ps-pix-5">
    <strong>
      Pager Number: {{ orderData.OrderAttributes.PagerNumber }}
    </strong>
  </div>
</div>
<div class="col-12 ps-0 py-1 row mx-0" *ngIf="orderData.OrderAttributes.PhoneNumber">
  <div class="col-1 p-0 col-40" *ngIf="isShowIcon"></div>
  <div class="col-9 pe-0 ps-pix-5">
    <strong>
      Phone: {{ orderData.OrderAttributes.PhoneNumber | phoneMaskPipe }}
    </strong>
  </div>
</div>
<div class="row mx-0 py-1 ps-0" *ngIf="orderData.DiscountPct > 0">
  <div class="col-1 p-0 col-40" *ngIf="isShowIcon"></div>
  <div class="col-8 pe-0 ps-pix-5 bold" [ngClass]="{'col-9': !isShowIcon}">
    Discount {{orderData.DiscountPct}}%
  </div>
  <div class="col-3 bold text-end" [ngClass]="{'pe-0': isShowIcon}">
    {{ -1 * (orderData.DiscountAmount) | tark_currency}}
  </div>
</div>
<div class="row mx-0 py-1 ps-0" *ngIf="orderData.MarkupPct > 0">
  <div class="col-1 p-0 col-40" *ngIf="isShowIcon"></div>
  <div class="col-8 pe-0 ps-pix-5 bold" [ngClass]="{'col-9': !isShowIcon}">
    Markup {{orderData.MarkupPct}}%
  </div>
  <div class="col-3 bold text-end" [ngClass]="{'pe-0': isShowIcon}">
    {{ orderData.MarkupAmount | tark_currency}}
  </div>
</div>
<div class="row mx-0 py-1 ps-0" *ngIf="orderData.DeliveryFee > 0">
  <div class="col-1 p-0 col-40" *ngIf="isShowIcon"></div>
  <div class="col-8 pe-0 ps-pix-5 bold" [ngClass]="{'col-9': !isShowIcon}">
    Delivery Fee
  </div>
  <div class="col-3 bold text-end" [ngClass]="{'pe-0': isShowIcon}">
    {{orderData.DeliveryFee | tark_currency}}
  </div>
</div>

<div *ngFor="let item of seats" class="h-fit-content">
  <!-- | keyvalue: originalOrder -->
  <div class="text-center col-12 p-0 bg-theme-primary"
    *ngIf="((item.SubAccountOrdinal) || isDietaryRestrictionsAdded) && item.SubAccountOrdinal > 0">
    <label class="mb-0" *ngIf="item.SubAccountOrdinal != 99"> {{item.SubaccountName ? item.SubaccountName :
      (subAccountTerm + " " + item.SubAccountOrdinal)}}
    </label>

    <label class="white mb-0" *ngIf="item.SubAccountOrdinal == 99"> To Go </label>
  </div>
  <div class="col-md-12 order-item-border dietary-warning row mx-0"
    *ngIf="dietaryRestrictions && dietaryRestrictions[item.SubAccountOrdinal]">
    <div class="col-1 p-0 col-40" *ngIf="isShowIcon"></div>
    <div class="col-md-10 col-sm-2 col-1 p-0">
      <strong>
        Dietary Warnings
      </strong>
      <div *ngFor="let dietaryRestriction of dietaryRestrictions | keyvalue">
        <div *ngIf="dietaryRestriction.key === item.SubAccountOrdinal">
          <div class="col-lg-3 col-md-4 col-sm-6 ps-pix-15" *ngFor="let warning of dietaryRestriction.value">
            {{ warning.Name }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 ps-0 row mx-0 py-1"
    [ngClass]="{'bg-grey-90': product.IsSelected, 'pointer': isSelectableProduct, 
  'disable-unpaid-products': product.StatusId != orderProductStatus.Paid && isSelectableProduct && (product.Price > 0 && item.SubAccountOrdinal)}"
    (click)="selectProduct(product)" *ngFor="let product of item.OrderItems">
    <div class="col-1 p-0 col-40" *ngIf="isShowIcon">
      <span
        *ngIf="product.IsSentToKitchen && product.SalesProductId && product.SalesProductId.Value > 0 && !product.IsDirty">
        <a class="btn-sent-to-kitchen pe-pix-6 ps-pix-6">
          <pos-icon [icon]="icons.check" class="white"></pos-icon>
        </a>
      </span>
    </div>
    <div class="col-9 pe-0 ps-pix-5">
      <div class="col-12 p-0 d-flex">
        <strong class="d-flex">{{product.MakeTableText}}
          <span class="ps-pix-5"
            *ngIf="product.Qty && ((product.Qty - (product.RefundedQty ? product.RefundedQty : 0) > 0) || !orderData.Id.Value)">
            ({{(product.RefundedQty && orderData.Id.Value ? product.Qty - product.RefundedQty : product.Qty) +
            (product.SalesProductBusinessEntity && product.SalesProductBusinessEntity.SalesUnit &&
            product.SalesProductBusinessEntity.SalesUnit.AbbreviationName ? (' ' +
            product.SalesProductBusinessEntity.SalesUnit.AbbreviationName) : '')}})
          </span>
          <span class="refunded-qty" *ngIf="product.RefundedQty && orderData.Id.Value">
            (ordered {{product.Qty}})
          </span>
          <span *ngIf="settingParam.IsUseMakeTable && product.OrderItemType == orderItemTypes.Product">
            <pos-icon [icon]="icons.userNameCircle" class="pe-pix-5 ps-pix-5 font-15 pointer" (mouseenter)="prepareTooltipMessage(product)" [pTooltip]="product.TooltipData"  tooltipPosition="top"></pos-icon>
          </span>
        </strong>
      </div>
      <div class="col-12 p-0" *ngIf="item.SubAccountOrdinal > 0">
        <div class="ps-pix-15" *ngFor="let component of product.OrderProductComponents">
          <div class="order-page__comment__text">{{component.MakeTableText}}</div>
        </div>
      </div>
    </div>
    <div class="col-2 flex-grow text-end bold">
      {{product.Price * (product.Qty ? product.Qty : 1) | tark_currency }}
    </div>
  </div>

  <div class="col-12 row mx-0 bold" *ngIf="item.Discount > 0 && item.SubAccountOrdinal > 0 && isShowSeatTotal">
    <div class="col-1 p-0"> </div>
    <div class="col-9 pe-0 ps-pix-5">
      <div class="offset-8 pe-pix-10">
        Discount</div>
    </div>
    <div class="col-2 ps-0 text-end">
      {{item.Discount | tark_currency }}
    </div>
  </div>
  <div class="col-12 row mx-0 bold" *ngIf="item.Markup > 0 && item.SubAccountOrdinal > 0 && isShowSeatTotal">
    <div class="col-1 p-0"> </div>
    <div class="col-9 pe-0 ps-pix-5">
      <div class="offset-8 pe-pix-10">
        Markup</div>
    </div>
    <div class="col-2 ps-0 text-end">
      {{item.Markup | tark_currency }}
    </div>
  </div>
  <div class="col-12 row mx-0 bold" *ngIf="item.SubAccountOrdinal > 0 && isShowSeatTotal">
    <div class="col-1 p-0"> </div>
    <div class="col-9 pe-0 ps-pix-5">
      <div class="offset-8 pe-pix-10">{{subAccountTerm}} Tax:</div>
    </div>
    <div class="col-2 ps-0 text-end">
      {{item.Tax | tark_currency }}
    </div>
  </div>
  <div class="col-12 row mx-0 bold" *ngIf="item.SubAccountOrdinal > 0 && isShowSeatTotal">
    <div class="col-1 p-0"> </div>
    <div class="col-9 pe-0 ps-pix-5">
      <div class="offset-8 pe-pix-10">{{subAccountTerm}} Total:</div>
    </div>
    <div class="col-2 ps-0 text-end">
      {{item.SeatTotal | tark_currency}}
    </div>
  </div>
</div>
