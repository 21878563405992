
import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { finalize } from 'rxjs/internal/operators/finalize';
import { ApplicationLoginService } from './shared/services/application-login.service';
import { MainService } from './header-entry/services/main.service';
import { AuthenticationService } from './shared/auth/services/authentication.service';
import { UserDetails } from './shared/interface/user-details';
import { ApplicationStateService } from './shared/services/application-state.service';
import { PermissionService } from './shared/services/permission.service';
import { RabbitMQConfigurationService } from './shared/services/rabbitmq/services/rabbitmq-configuration.service';
import { ReloadSettingsService } from './shared/services/reload-settings.service';
import { UserLogInService } from './shared/services/user-log-in.service';
import { Location } from '@angular/common';
import { find, forEach } from 'lodash';
import { ApplicationFeaturesService } from './shared/services/application-features.service';
import { CameraAnnotationService } from './shared/services/camera-annotation.service';
import { CameraAnnotations } from './shared/interface/camera-annotations';
import { FontTypeService } from './configurator/fonts/services/font-type.service';
import { RuntimeConstants } from './shared/constants/runtime-constants'
declare let $: any;
declare let clearGlobalLoaderInterval: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {

  isAuthenticate: boolean;
  constructor(
    private applicationLoginService: ApplicationLoginService,
    private applicationStateService: ApplicationStateService,
    private permissionService: PermissionService,
    private mainService: MainService,
    private reloadSettingsService: ReloadSettingsService,
    private rabbitMQConfigurationService: RabbitMQConfigurationService,
    private authenticationService: AuthenticationService,
    private userLoginService: UserLogInService,
    private location: Location,
    private applicationFeatureService: ApplicationFeaturesService,
    private cameraAnnotationService: CameraAnnotationService,
    private fontTypeService: FontTypeService 
  ) {
  }

  ngOnInit(): void {
    const isLoggedIn = this.authenticationService.isLoggedIn();
    const userCode = this.applicationStateService.userCode;
    const currentURL = this.location.path();

    this.loadFonts();
    if (!isLoggedIn && userCode && (currentURL !== '' && currentURL !== '/login')) {
      this.loginUser(userCode);
    } else {
      this.clearGlobalLoaderTimerInterval();
    }
  }

  private loadFonts(){
    this.fontTypeService.getAll()
    .subscribe({
      next: (res) => {
        const fonts = res; 
        fonts.forEach(font => {
          this.loadFont(font.FileName, font.Name);
        });
      }, error: () => {
        this.userLoginService.dataSubject.next(Promise.resolve(false));
        this.clearGlobalLoaderTimerInterval();
      }
    });
  }

  private loadFont(fontFileName: string, fontName: string) {
    const existingStyle = document.getElementById(fontName);
    if (!existingStyle) {
        const newStyle = document.createElement('style');
        newStyle.setAttribute('id', fontName);
        const font = RuntimeConstants.FONT_BASE_PATH + '/' + fontFileName;
        newStyle.appendChild(document.createTextNode('@font-face{font-family: ' + fontName + '; src: url("' + font + '");}'));
        document.head.appendChild(newStyle);
    }
  }

  clearGlobalLoaderTimerInterval = () => {
    if ($('#globalLoaderParent').length) {
      $('#globalLoaderParent').remove();
      clearGlobalLoaderInterval();
    }
  }

  loginUser = (userCode) => {
    const customerCredential = {
      Password: userCode,
      ExternalPassword: '',
      Username: ''
    };
    this.applicationStateService.isAuthenticated = true;
    this.applicationLoginService.getCustomer(customerCredential).pipe(finalize(() => {
    })).subscribe({
      next: (response) => {
        if (response) {
          const userDetails = response.UserTerminalDetails.UserDetails;
          const terminals = response.UserTerminalDetails.TerminalSummary;
          if (userDetails) {
            this.loginCompleted(userDetails);
          }
          this.setTerminalDetails(terminals);
        }
      }, error: () => {
        this.userLoginService.dataSubject.next(Promise.resolve(false));
        this.clearGlobalLoaderTimerInterval();
      }
    });
  }

  loginCompleted = (userDetails: UserDetails) => {
    if (userDetails) {
      const loginObservables = [];
      loginObservables.push(this.permissionService.getUserPermission(this.applicationStateService.impersonateUserDetails?.id));
      loginObservables.push(this.mainService.getApplicationVersion());
      loginObservables.push(this.mainService.initializeApplication());
      loginObservables.push(this.reloadSettingsService.reloadSettings(this.applicationStateService.terminalId ?? 0));
      loginObservables.push(this.mainService.getConfigurationsForWebApp());
      loginObservables.push(this.mainService.getApplicationInitalizationConfiguration());
      loginObservables.push(this.applicationFeatureService.getApplicationFeatures());
      loginObservables.push(this.cameraAnnotationService.getActiveCameraAnnotation());
      loginObservables.push(this.mainService.getContactSupportKey());
      forkJoin(loginObservables).pipe(finalize(() => {
      }))
        .subscribe({
          next: (response: any) => {
            if (response) {
              this.setSettingsToLocalStorage(userDetails);
              const permissionData = response[0] ? response[0] : null;
              const appVersion = response[1] && response[1].CurrentVersion ? response[1].CurrentVersion : null;
              const licenseInfo = response[2] && response[2].LicenseInfo ? response[2].LicenseInfo : null;
              const cameraAnnotationsResponse: Array<CameraAnnotations> = response[7];
              forEach(cameraAnnotationsResponse, (annotations) => {
                this.applicationStateService.cameraAnnotations[annotations.KeyName] = annotations.Annotation;
              });
              this.applicationStateService.applicationInitializationConfigurations = appVersion;
              this.applicationStateService.userPermissions = permissionData;
              this.applicationStateService.licenseInfo = licenseInfo;
              this.applicationStateService.settingParam = response[3];
              this.applicationStateService.setLocalizationSettings(this.applicationStateService.settingParam);
              const configResponse = response[4];
              const applicationConfigResponse = response[5];
              if (configResponse) {
                this.applicationStateService.configurations = this.rabbitMQConfigurationService.initializeConfigurationsObject(configResponse);
                this.getUserPreference();
                this.rabbitMQConfigurationService.setConfig();
                this.rabbitMQConfigurationService.subscribeConnectionState();
                document.title = configResponse.CustomerName;

              }
              if (applicationConfigResponse) {
                this.applicationStateService.applicationInitializationConfigurations = applicationConfigResponse;
              }
              this.applicationStateService.contactSupportKey = response[8] ? response[8].Key : null;
              this.userLoginService.dataSubject.next(Promise.resolve(true));
              this.clearGlobalLoaderTimerInterval();
            }
          }, error: () => {
            this.userLoginService.dataSubject.next(Promise.resolve(false));
            this.clearGlobalLoaderTimerInterval();
          }
        });
    }
  }

  private setTerminalDetails(terminals) {
    if (terminals) {
      const terminalDetails = find(terminals, (terminal) => {
        return terminal.TerminalId === this.applicationStateService.terminalId;
      });
      if (terminalDetails) {
        this.applicationStateService.terminalName = terminalDetails.TerminalName;
        this.applicationStateService.terminalType = terminalDetails.TerminalType;
        this.applicationStateService.cookieTerminalName = terminalDetails.TerminalName;
      }
    }
  }

  private setSettingsToLocalStorage(userDetails: UserDetails): void {
    if (userDetails) {
      this.applicationStateService.userId = userDetails.id;
      this.applicationStateService.cookieUserId = userDetails.id;
      this.applicationStateService.userDetails = userDetails;
      this.applicationStateService.sessionApplicationMode = 'application';
    }
  }

  private getUserPreference() {
    this.applicationLoginService.getUserPreference(this.applicationStateService.userId)
    .subscribe({
      next: (response) => {
        this.applicationLoginService.setUserPreferences(response?.UserPreferenceModel);
      }
    });
  }
}
