<div
  class="align-items-center bg-theme-primary d-flex justify-content-center loyalty-form-container"
>
  <div
    class="d-flex align-items-center justify-content-center flex-direction-column h-100 w-100 outer-container"
  >
    <div class="image-container">
      <img class="logo pb-pix-20" src="{{ imagePath }}/logo/logo.png" />
    </div>
    <form #registrationForm="ngForm" class="d-flex flex-direction-column form-container" (ngSubmit)="submit(registrationForm.valid)"
      *ngIf="!isFormSubmited">
      <div class="form-header">
        <h2 class="font-weight-600">{{ loyaltyFormSettings.HeaderText }}</h2>
      </div>
      <div class="col-12 p-0 scrollable-fields">
        <div class="d-flex justify-content-center" *ngIf="systemDefinedNameAttribute">
          <pos-row>
            <pos-column xs="12" class="pb-pix-10">
              <pos-form-field inputStyle="col-12" [validationMessages]="{ required: 'Please enter ' + (systemDefinedNameAttribute.Name | lowercase) }" >
                <div>
                  <pos-column xs="12">
                    <label class="mb-0" [class.required]="true"><b>{{systemDefinedNameAttribute.Name}}</b></label>
                    <span class="d-block mb-pix-5 font-12"><i>{{systemDefinedNameAttribute.Description}}</i></span>
                  </pos-column>
                  <pos-column xs="12">
                    <input  posInput type="text" name="inp_name" #name="ngModel" class="form-control"
                      [(ngModel)]="account.Name" [required]="true"/>
                  </pos-column>
                </div>
              </pos-form-field>
            </pos-column>
          </pos-row>
        </div>
        <div class="p-0 dynamic-fields" *ngIf="loyaltyFormAttributes.length">
          <pos-row>
            <pos-column xs="12" class="pb-pix-10"
              *ngFor="let loyaltyFormAttribute of loyaltyFormAttributes">
              <pos-form-field inputStyle="col-12">
                <div>
                  <pos-column xs="12">
                    <label class="mb-0" [class.required]="loyaltyFormAttribute.AccountAttributeType.IsRequired">
                      <b>{{loyaltyFormAttribute.Name}}</b>
                    </label>
                    <span class="d-block mb-pix-5 font-12 white-space-preline"><i>{{ loyaltyFormAttribute.Description }}</i></span>
                  </pos-column>
                  <pos-column xs="12" [ngClass]="{ 'has-error': shouldShowError(loyaltyFormAttribute, registrationForm.submitted) }">
                    <pos-account-attribute [attributeType]="loyaltyFormAttribute.AccountAttributeType"
                      [applyRequired]="true"></pos-account-attribute>
                    <p class="control-label" *ngIf="shouldShowError(loyaltyFormAttribute, registrationForm.submitted)">
                      Please {{ getAction(loyaltyFormAttribute.AccountAttributeType.ValueType) }} {{ loyaltyFormAttribute.Name | lowercase }}
                    </p>
                  </pos-column>
                </div>
              </pos-form-field>
            </pos-column>
          </pos-row>
        </div>
        <div class="d-flex justify-content-center captcha" *ngIf="loyaltyFormSettings.UseRecapcha && siteKey">
          <pos-row>
            <pos-column class="pb-pix-10">
             <pos-form-field>
              <re-captcha id="recaptcha" name="recaptcha" #recaptcha="ngModel" [(ngModel)]="token"
                [required]="true" [class.is-invalid]=" recaptcha.invalid && registrationForm.submitted" [siteKey]="siteKey">
              </re-captcha>
             </pos-form-field>
            </pos-column>
          </pos-row>
        </div>
      </div>
      <div class="form-footer">
        <button
          type="submit"
          class="submit bg-theme-separator loyalty-page-button"
        >
          Register
        </button>
      </div>
    </form>
    <div class="align-items-center d-flex flex-direction-column justify-content-center form-container" *ngIf="isFormSubmited">
      <div
        class="d-flex flex-direction-column justify-content-center align-items-center h-100 w-100 p-pix-20"
      >
        <div class="success-icon pb-pix-10">
          <pos-icon [icon]="icons.checkCircleGreen"></pos-icon>
        </div>
        <div class="registered-heading font-weight-600 text-align-center">
          {{ loyaltyFormSettings.FormSuccessHeader }}
        </div>
        <div class="registerd-description text-align-center">
          {{ loyaltyFormSettings.FormSuccessDescription }}
        </div>
      </div>
    </div>
  </div>
</div>
<div class="confetti-container" *ngIf="isFormSubmited">
  <div
    class="confetti"
    *ngFor="let confetti of confettiArray"
    [style.backgroundColor]="confetti.color"
    [style.left]="confetti.x + '%'"
    [style.animationDelay]="confetti.delay + 's'"
    [style.animationDuration]="confetti.duration + 's'"
  ></div>
</div>
