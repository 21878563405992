import { Component, OnInit, Input } from '@angular/core';
import { Observable, interval as observableInterval } from 'rxjs';
import * as _ from 'lodash';
import {
  ModalComponent, AlertsService, SpinnerService, EventBroadcastingService, ApplicationStateService,
  UserDetails, Messages, ModalService, DataFilterComponent, Levels, Permissions,
  ConfirmDeleteModalComponent
} from 'src/app/shared';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/operators';
import { TaskComponent } from 'src/app/task';
import {
  plus, taskAll, taskToDo, taskSkipped, taskCompleted, checkCircleTask, cogWhite, taskHelp, taskSkip,
  taskCollapseCaretDown, taskCollapseCaretRight, taskTodo, squareSolidCog, undo, hourglassTask
} from 'src/app/shared/components/icon';
import { ManagesService } from 'src/app/shared/services/manages.service';
import { cloneDeep, forEach, groupBy, orderBy } from 'lodash';
import { CameraAnnotationService } from 'src/app/shared/services/camera-annotation.service';
import { SignInComponent } from 'src/app/orders/order-entry/sign-in';
import { DomainConstants } from 'src/app/shared/constants';

@Component({
  selector: 'pos-shift-duties',
  templateUrl: './shift-duties.component.html',
  styleUrls: ['./shift-duties.component.scss']
})
export class ShiftDutiesComponent extends ModalComponent implements OnInit {
  icons = {
    plus, taskAll, taskToDo, taskSkipped, taskCompleted, checkCircleTask, cogWhite, taskHelp, taskSkip,
    taskCollapseCaretDown, taskCollapseCaretRight, taskTodo, squareSolidCog, undo, hourglassTask
  };
  timer: Observable<number>;
  timerList: any = [];
  timerSubscription: any;
  taskList: Array<any> = [];
  groupedTask: any;
  taskDetail: any;
  userDetails: UserDetails;
  shiftDutiesUserName: string = '';
  @Input('shiftDutiesDue') shiftDutiesDue: boolean = false;
  taskDetails: any;
  tabList = {
    AllTasks: 'AllTasks',
    ToDoTasks: 'ToDoTasks',
    SkippedTasks: 'SkippedTasks',
    CompletedTasks: 'CompletedTasks'
  };
  selectedTab = this.tabList.ToDoTasks;
  popup: any;
  statusList = [
    { Name: 'To Do', Value: 'Due', Icon: 'far fa-circle light-grey', Color: '' },
    { Name: 'Started', Value: 'Started', Icon: 'far fa-hourglass-half fa-lg medium-violet', Color: 'medium-violet'},
    { Name: 'Completed', Value: 'Completed', Icon: 'far fa-check-circle green', Color: 'green' },
    { Name: 'Skipped', Value: 'Skipped', Icon: 'fas fa-ban warning-color', Color: 'warning-color' },
    { Name: 'All', Value: 'All', Icon: 'fas fa-tasks', Color: '' },
  ];
  selectedStatus: string = '';
  permission = {
    name: Permissions.LinkCreateTask,
    editLevel: Levels.Access,
  };
  constructor(shiftDutiesModalRef: BsModalRef,
    private managesService: ManagesService,
    private spinnerService: SpinnerService,
    private alertService: AlertsService,
    private eventBroadcastingService: EventBroadcastingService,
    private applicationStateService: ApplicationStateService,
    private modalService: ModalService,
    private dataFilter: DataFilterComponent,
    private cameraAnnotationService: CameraAnnotationService) {
    super(shiftDutiesModalRef);
  }

  ngOnInit() {
    this.userDetails = this.applicationStateService.userDetails;
    this.shiftDutiesUserName = this.userDetails ? this.userDetails.firstname + ' ' + this.userDetails.lastname : '';
    this.getTaskDetails();
    this.shiftDutiesInterval();
    this.eventBroadcastingSubscriptions();
    this.selectedStatus = 'Due';
  }

  getTaskDetails(silentCall: boolean = false) {
    if (!silentCall) {
      this.spinnerService.show();
    }
    this.managesService.getTaskDetails()
      .pipe(finalize(() => {
        if (!silentCall) {
          this.spinnerService.hide();
        }
      }))
      .subscribe({
        next: (res) => {
          this.taskList = [];
          if (res && res.TaskDetailModel) {
            _.forEach(res.TaskDetailModel, (task) => {
              task.date = new Date((task.due_on));
              task.date = new Date(task.date).setMinutes(0);
              if (!task.signed_off_on && !task.skipped && !task.IsStarted) {
                task.Comment = 'Due by ';
                this.taskList.push(task);
                this.eventBroadcastingService.onShiftDutiesDue(true);
              } else if (task.signed_off_on && task.skipped) {
                task.Comment = 'Skipped by ' + task.user + ' on ';
                this.taskList.push(task);
              } else if (task.IsStarted) {
                task.Comment = 'Started by ' + task.user;
                this.taskList.push(task);
              } else {
                task.Comment = 'Completed by ' + task.user + ' on ';
                this.taskList.push(task);
              }
            });
            this.filterData(this.selectedStatus);
          }
        }, error: this.alertService.showApiError
      });
  }

  shiftDutiesInterval() {
    this.timer = observableInterval(30000);
    this.timerSubscription = this.timer.subscribe({
      next: (t) => {
        this.getTaskDetails(true);
      }
    });
  }

  ngOnDestroy() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }
  filterData = (status) => {
    this.taskList = orderBy(this.taskList, (t) => new Date(t.date));
    let tasks = cloneDeep(this.taskList);
    tasks = this.dataFilter.transform(tasks, status && status != 'All' ? { 'Comment': status } : null);
    this.groupedTask = groupBy(tasks, (t) => {
      return ('0' + new Date(t.date).getHours()).slice(-2);
    });
    this.groupedTask = orderBy(this.groupedTask, (x) => {
      return x?.length ? x[0]?.due_on : new Date();
    });
  }

  updateTaskList = (taskDetail) => {
    forEach(this.groupedTask, (taskList) => {
      forEach(taskList, (t) => {
        if (t.id === taskDetail.id) {
          t.user_id = taskDetail.user_id;
          t.skipped = taskDetail.skipped;
          t.user = taskDetail.user;
          t.Comment = taskDetail.Comment;
          t.signed_off_on = taskDetail.signed_off_on;
          t.IsStarted = taskDetail.IsStarted;
        }
      });
    });
    forEach(this.taskList, (t) => {
      if (t.id === taskDetail.id) {
        t.user_id = taskDetail.user_id;
        t.skipped = taskDetail.skipped;
        t.user = taskDetail.user;
        t.Comment = taskDetail.Comment;
        t.signed_off_on = taskDetail.signed_off_on;
        t.IsStarted = taskDetail.IsStarted;
      }
    });
  }

  // tslint:disable-next-line: member-ordering
  updateShiftDuties = _.debounce(
    (taskDetailsModel) => {
      if (!taskDetailsModel.signed_off_on || taskDetailsModel.skipped || taskDetailsModel.IsStarted) {
        taskDetailsModel.user_id = this.userDetails.id;
        taskDetailsModel.skipped = false;
        taskDetailsModel.user = this.shiftDutiesUserName;
        if (!taskDetailsModel.IsStarted && taskDetailsModel.IsStart) {
          taskDetailsModel.IsStarted = true;
          taskDetailsModel.Comment = 'Started by ' + this.shiftDutiesUserName;
        } else {
          taskDetailsModel.IsStarted = false; 
          taskDetailsModel.signed_off_on = new Date();
          taskDetailsModel.Comment = 'Completed by ' + this.shiftDutiesUserName + ' on ';
          this.cameraAnnotationService.addAnnotationToCamera(
            this.applicationStateService.cameraAnnotations.TaskComplete,
            {
              TaskName: taskDetailsModel.taskname,
            }
          );
        }
        this.updateTask(taskDetailsModel);
        this.updateTaskList(taskDetailsModel);
      } else {
        this.taskDetail = taskDetailsModel;
        this.updateTaskUndone();
      }
      this.popup?.hide();
    },
    750, {
    'leading': true,
    'trailing': false,
    'maxWait': 750
  });

  // tslint:disable-next-line: member-ordering
  skippedShiftDuties = _.debounce((taskDetailsModel, isSkipped) => {
    if (!taskDetailsModel.skipped) {
      taskDetailsModel.user_id = this.userDetails.id;
      taskDetailsModel.skipped = true;
      taskDetailsModel.user = this.shiftDutiesUserName;
      taskDetailsModel.IsStarted = false;
      this.updateTask(taskDetailsModel);
      taskDetailsModel.signed_off_on = new Date();
      taskDetailsModel.Comment = 'Skipped by ' + this.shiftDutiesUserName + ' on ';
      this.cameraAnnotationService.addAnnotationToCamera(
        this.applicationStateService.cameraAnnotations.TaskSkipped,
        {
          TaskName: taskDetailsModel.taskname,
        }
      );
    } else {
      this.taskDetail = taskDetailsModel;
      this.updateTaskUndone();
    }
    this.taskDetails = null;
    this.updateTaskList(taskDetailsModel);
    this.popup?.hide();
  }, 750, {
    'leading': true,
    'trailing': false,
    'maxWait': 750
  });

  openShiftDutyConfirmationModal(item) {
    var modal = this.modalService.show(ConfirmDeleteModalComponent, {
      animated: false,
      class: 'vertical-center',
      keyboard: false,
      initialState: {
        message: Messages.MarkUndoneTask
      }
    })

    modal.close.subscribe({
      next: (res) => {
        if (res?.shouldDelete)
          this.performUpdateAction(item, true);
      }
    })
  }

  updateTask(taskDetails: any) {
    this.spinnerService.show();
    this.managesService.updateTaskCompleted(taskDetails)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res) => {
          this.updateTaskDetailsCompleted();
        }, error: this.alertService.showApiError
      });
  }

  updateTaskDetailsCompleted() {
    this.eventBroadcastingService.onShiftDutiesDue(false);
    _.forEach(this.taskList, (task) => {
      if (!task.signed_off_on && !task.skipped) {
        this.shiftDutiesDue = true;
        this.eventBroadcastingService.onShiftDutiesDue(true);
      }
    });

    if (!this.shiftDutiesDue) {
      this.createRabbitMqMessageForShiftDuties('false');
      return;
    }
    this.createRabbitMqMessageForShiftDuties('true');
  }

  taskHelpRequest(taskDetailsModel) {
    taskDetailsModel.user_id = this.userDetails.id;
    taskDetailsModel.user = this.shiftDutiesUserName;
    this.spinnerService.show();
    this.managesService.taskHelpRequest(taskDetailsModel)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res) => {
        }, error: this.alertService.showApiError
      });
    this.taskDetails = null;
    this.popup?.hide();
  }

  updateTaskUndone() {
    this.spinnerService.show();
    this.managesService.updateTaskUndone(this.taskDetail)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res) => {
          this.updateTaskDetailsCompleted();
        }, error: this.alertService.showApiError
      });
    this.taskDetail.imageScr = 'images/icons/icon-checklist.png';
    this.taskDetail.Comment = 'Due by';
    this.taskDetail.user_id = null;
    this.taskDetail.signed_off_on = null;
    this.taskDetail.skipped = false;
    this.taskDetail.IsStarted = false;
    this.updateTaskList(this.taskDetail);
  }

  createRabbitMqMessageForShiftDuties(shiftDuty: string) {
    this.managesService.createRabbitMqMessageForShiftDuties(shiftDuty)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res) => {
        }, error: this.alertService.showApiError
      });
  }

  eventBroadcastingSubscriptions() {
    this.eventBroadcastingService.shiftDutiesDue.subscribe(
      (shiftDutiesDue: any) => {
        this.shiftDutiesDue = shiftDutiesDue;
      }
    );
  }

  addNewTask() {
    const shiftModalRef = this.modalService.show(TaskComponent, {
      animated: false,
      class: 'vertical-center',
      initialState: {
        shiftDutiesDue: this.shiftDutiesDue
        // commentWarningId: data
      }
    });
    shiftModalRef.close.subscribe(res => {
      if (res && res.shouldReload) {
        this.getTaskDetails();
      }
    });
  }

  onClose() {
    this.hide({});
  }
  onLinkPopOver(data, popup: any) {
    this.taskDetails = data;
    this.popup = popup;
  }

  clickOnTimeGroup = (tasks) => {
    tasks.value[0].isClosed = !tasks?.value[0]?.isClosed
  }

  performSkipAction(taskDetails: any, isSkipped: boolean) {
    this.taskActionPerformed(() => this.skippedShiftDuties(taskDetails, isSkipped));
  }

  performUpdateAction(item: any, acknowledged = false) {
    if ((item.signed_off_on && !item.skipped && !item.IsStarted) && !acknowledged) {
      this.openShiftDutyConfirmationModal(item);
    } else {
      this.taskActionPerformed(() => this.updateShiftDuties(item));
    }
  }

  performTaskHelpAction(taskDetails) {
    this.taskActionPerformed(() => this.taskHelpRequest(taskDetails));
  }

  taskActionPerformed(callBack: () => void) {
    if (this.isGenericUserSignInRequired())
      this.promptForSignIn(callBack);
    else
      callBack();
  }

  promptForSignIn(callBack: () => void){
    const modalRef = this.modalService.show(SignInComponent, {
      animated: false,
      class: 'vertical-center modal-max-width-65',
      initialState: {
        signInCompletedCallBack: (userDetails: UserDetails) => this.onSignInCompleted(userDetails, callBack),
      }
    });
    const userCode = this.applicationStateService.userCode;
    modalRef.close.subscribe({
      next: () => {
        this.applicationStateService.userCode = userCode;
      }
    });
  }

  onSignInCompleted(userDetails: UserDetails, callBack: () => void) {
    this.userDetails = userDetails;
    this.shiftDutiesUserName = this.userDetails ? this.userDetails.firstname + ' ' + this.userDetails.lastname : '';
    callBack();
  }

  isGenericUserSignInRequired() {
    return this.applicationStateService.userDetails.Roles.some(role => role.RoleName === DomainConstants.GENERIC_USER);
  }
}
