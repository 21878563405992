<div class="row mx-0 kiosk-screen kiosk-background" [style.height]="'100vh'" [style.background-color]="backgroundColor"
    [style.background-image]="backgroundImage ? 'url(\'' + imagePath + backgroundImage + '\')' : null">
    <div class="col-xl-8 col-7 d-flex flex-direction-column justify-content-center align-items-center">
        <div class="col-12 d-flex flex-direction-column align-items-center">
            <div class="serve-all-checkbox pointer"
                (click)="orderKioskStorageService.isServeAllTogether = !orderKioskStorageService.isServeAllTogether">
                <span class="col-25" [ngStyle]="{'vertical-align': orderKioskStorageService.isServeAllTogether ? '' : 'middle'}">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                        *ngIf="orderKioskStorageService.isServeAllTogether" fill="currentColor"
                        class="bi bi-check2-square" viewBox="0 0 16 16">
                        <path
                            d="M3 14.5A1.5 1.5 0 0 1 1.5 13V3A1.5 1.5 0 0 1 3 1.5h8a.5.5 0 0 1 0 1H3a.5.5 0 0 0-.5.5v10a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V8a.5.5 0 0 1 1 0v5a1.5 1.5 0 0 1-1.5 1.5z" />
                        <path
                            d="m8.354 10.354 7-7a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0" />
                    </svg>
                    <pos-icon [icon]="icons.squareLight" class="font-19 line-height-normal"
                        *ngIf="!orderKioskStorageService.isServeAllTogether">
                    </pos-icon>
                </span>
                <span class="ms-pix-3">Serve All Together</span>
            </div>
            <button class="btn-lg kiosk-checkout-buttons add-button mt-5" type="button" (click)="addNewItem()">
                Add additional items
            </button>
            <button class="btn-lg kiosk-checkout-buttons mt-5 complete-order-button" type="button"
                (click)="onOrderConfirmed()">
                Complete order
            </button>
        </div>
        <div class="mt-5">
            <button class="cancel-order-button" (click)="confirmCancelOrder()">CANCEL ORDER</button>
        </div>
    </div>
    <div class="col-xl-4 col-5 p-3" [ngStyle]="{'height': 'calc(100vh)'}">
        <pos-kiosk-invoice #kioskInvoiceComponent></pos-kiosk-invoice>
    </div>
</div>
