import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ModalFormComponent, ModalService } from 'src/app/shared/components';
import { AlertsService, ExtensionValidationService, FormUtilityService } from 'src/app/shared/services';
import { SalesCategorySize } from '../../interface';
import { Messages, RuntimeConstants } from 'src/app/shared/constants';
import { times } from 'src/app/shared/components/icon';
import * as $ from 'jquery';

@Component({
  selector: 'pos-sales-category-size-edit',
  templateUrl: './sales-category-size-edit.component.html',
  styleUrls: ['./sales-category-size-edit.component.scss']
})
export class SalesCategorySizeEditComponent extends ModalFormComponent {

  @Input() salesCategorySizeId: number = 0;
  @Input() salesCategoryId: number = 0;
  @Input() salesCategorySize: SalesCategorySize;
  @Input() sizes = [];
  @Input() excludeSizes: number[] = [];
  showImageIcon = false;
  @Input() image: File;
  imagePath = RuntimeConstants.IMAGE_BASE_PATH + '/salesCategorySizes';
  icons = {
    times,
  }
  
  @ViewChild('salesCategoriesSizesForm', { static: true }) salesCategoriesSizesForm: NgForm;

  get getForm(): NgForm {
    return this.salesCategoriesSizesForm;
  }

  constructor(
    modalRef: BsModalRef,
    modalService: ModalService, 
    formUtilityService: FormUtilityService,
    private extensionValidation: ExtensionValidationService,
    private alertService: AlertsService,
  ) {
    super(modalRef, modalService, formUtilityService);
    
  }

  ngOnInit() {
    this.sizes = this.sizes.filter(x => !this.excludeSizes.includes(x.Id))
    if (!this.salesCategorySizeId) {
      this.salesCategorySize = {
        Id: 0,
        SizeId: null,
        SalesCategoryId: this.salesCategoryId,
        SaleSizes: null,
        Image: null,
      }
    }
    this.showImageIcon = !!this.salesCategorySize.Image;
    if (this.image) {
      this.setImageInUI(this.image);
    }
  }

  handleFileInput(files: FileList){
    this.image = files.item(0);
    const fileToUpload = this.image ? this.image.name : '';
    if (this.extensionValidation.isInvalidExtension(fileToUpload, ['.jpg', '.bmp', '.png', '.gif', '.webp'])) {
      if (fileToUpload) {
        this.alertService.renderErrorMessage(Messages.InvalidCategorySizeFileType)
      }
      this.removeIcon();
      return;
    }

    if (files && files[0]) {
      this.setImageInUI(files[0])
    }
  }

  setImageInUI(file: File) {
    const reader = new FileReader();
      reader.onload = (e) => {
        $('#image').attr('src', reader.result);
        this.showImageIcon = true;
      }
      reader.readAsDataURL(file);
      this.salesCategorySize.Image = this.image ? this.image.name : '';
  }

  removeIcon(): void {
    this.salesCategorySize.Image = '';
    this.showImageIcon = false;
    $('#image').val('')
  }

  save(isValid: boolean) {
    if (!this.salesCategorySize.SizeId) return;
    this.salesCategorySize.SaleSizes = this.sizes.find(x => this.salesCategorySize.SizeId == x.Id);
    this.cancelEditing({ shouldReload: true, salesCategorySize: this.salesCategorySize, file: this.image });
  }

  cancelEditing(res = {}) {
    this.hide(res);
  }

}
