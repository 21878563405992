import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventBroadcastingService, ComponentsModule, DataFilterComponent, TableConfiguration } from '../shared';
import { HeaderEntryMainComponent } from './component/header-entry-main/header-entry-main.component';
import { TooltipModule } from 'primeng/tooltip';
import { ClockInComponent } from './component/clock-in/clock-in.component';
import { FormsModule } from '@angular/forms';
import { ShiftDutiesComponent } from './component/shift-duties/shift-duties.component';
import { NgPipesModule } from 'ngx-pipes';
import { LogViewerComponent } from './component/log-viewer/log-viewer.component';
import { LogService } from './services/log.service';
import { FeatureDetectionService } from './services/feature-detection.service';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { AngularDraggableModule } from 'angular2-draggable';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { InventoryExhaustionModule } from '../information-management/inventory-products/components/inventory-exhaustion/inventory-exhaustion.module';
import { InboxMessagesModule } from '../inbox-messages/inbox-messages.module';
import { OrderEventBroadcastingService } from '../shared/services/order-event-broadcasting.service';
import { TaskModule } from '../task/task.module';
import { BsModalRef, ModalModule } from 'ngx-bootstrap/modal';
import { NgSelectModule } from '@ng-select/ng-select';
import { AccordionModule } from 'primeng/accordion';
import { ClockInStatusComponent } from './component/clock-in/clock-in-status/clock-in-status.component';
import { TableModule } from '@tarktech/tark-ng-utils';
import { NgIdleModule } from '@ng-idle/core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
@NgModule({
  imports: [
    CommonModule,
    NgSelectModule,
    AccordionModule,
    TooltipModule,
    ComponentsModule,
    FormsModule,
    NgPipesModule,
    // OrdersModule,
    InboxMessagesModule,
    PopoverModule.forRoot(),
    ModalModule.forRoot(),
    TableModule.forRoot(TableConfiguration),
    LoggerModule.forRoot({serverLoggingUrl: '/api/logs', level: NgxLoggerLevel.DEBUG, serverLogLevel: NgxLoggerLevel.ERROR}),
    AngularDraggableModule,
    InventoryExhaustionModule,
    TaskModule,
    NgIdleModule.forRoot(),
    NgbDropdownModule
  ],
  providers: [EventBroadcastingService, LogService, FeatureDetectionService, BsModalRef, DataFilterComponent],
  declarations: [HeaderEntryMainComponent, ClockInComponent, ShiftDutiesComponent, LogViewerComponent, ClockInStatusComponent],
  exports: [HeaderEntryMainComponent]
})
export class HeaderEntryModule { }
