<pos-edit-wrapper heading="Select AI Shake" (close)="cancel()">
    <div class="overflow-hidden col-12 pos-modal-body">
        <div class="mb-3 pti-5">
            <input class="form-control" type="text" [(ngModel)]="searchTerm" placeholder="Search..." #aiShakeSearch
            (ngModelChange)="onSearchChange($event)"/>
        </div>
        
        <div class="overflow-auto" [style.maxHeight]="screenHeight + 'px'" [style.height]="screenHeight + 'px'">
            <div *ngIf="(filteredShakeList$ | async) as shakes">
                <ng-container *ngIf="shakes.length > 0; else noShakesMessage">
                    <div *ngFor="let shake of shakes"
                        class="select-ai-tile pointer font-16 display-flex justify-content-between"
                        [ngClass]="{'disabled': !shake.IsInStock }" (click)="addAiShake(shake)">
                        <div>{{shake.Name}} <span class="italic fw-normal fs-6" *ngIf="!shake.IsInStock">(out of stock)</span></div>
                        <div>
                            <pos-icon *ngIf="!shake.IsInStock" [icon]="icons.minusSquare"></pos-icon>
                        </div>
                    </div>
                </ng-container>
                <ng-template #noShakesMessage>
                    <div class="no-shakes-message">
                        <ng-container *ngIf="searchTerm; else startSearchMessage">
                          No shakes found. Please refine your search.
                        </ng-container>
                        <ng-template #startSearchMessage>
                          Start typing to search for shakes.
                        </ng-template>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
    <div class="col-12 action-button-container popup-footer">
      <button type="button" class="btn btn-secondary btn-lg btn-cancel-modal pos-button" (click)="cancel()" value="Close"
        >Cancel</button>
    </div>
</pos-edit-wrapper>
