export const FieldInfoMessages = {
    // Accounts and Tabs
    AccountsAndTabsAssignAccountToOrders: 'If checked, requires the assignment of an account to every order.',
    AccountsAndTabsAutoCloseAccount: 'If checked, close an account after it is settled.',
    AccountAndTabsAutoCloseTabs: 'If checked, close a tab after it is settled.',
    AccountsAndTabsDisplaySubAccount: 'If checked, shows each subaccount on receipts.',
    AccountsAndTabsEnableLoyaltyCard: 'If checked, allow the capture of customer information and orders for loyalty programs.',
    AccountsAndTabsOneActiveAccountPerAccount: 'If checked, permit only one active order per account – otherwise, add to the existing order on the account.',
    AccountsAndTabsOneActiveAccountPerTab: 'If checked, permit only one active order per tab – otherwise, add to the existing order on the tab.',

    // Baseline Expenses
    BaselineExpenses: 'Name of the expense to be estimated.',
    BaselineExpensesAmount: 'The estimated daily cost for this expense.',

    // Branding
    BrandingCompanyName: 'Sets your company name as it can appear on receipts.',
    BrandingLogo: 'Sets the logo which appears in the heading of the POS.',
    BrandingReceiptFooter: 'Sets an optional footer text which prints on all customer receipts.',
    BrandingReceiptLogo: 'Sets the logo which can appear on your receipts.',

    // Cardbased Logins
    CardbasedLoginsRFIDEndingSequence: 'These are the characters that terminate a card swipe sequence.',
    CardbasedLoginsRFIDErrorCode: 'The text that the card swiper produces if the card was not successfully read.',
    CardbasedLoginsRFIDInitiatorSequence: 'These are the characters that the system recognizes as a card swipe (vs. direct keyboard input).',

    // Cash Drawer
    CashDrawerCash: 'Open the drawer when payment is made in cash.',
    CashDrawerCheck: 'Open the drawer when payment is made via check.',
    CashDrawerCreditCard: 'Open the drawer when payment is made via credit card.',
    CashDrawerDebitCard: 'Open the drawer when payment is made via debit card.',
    CashDrawerGiftCardCertificate: 'Open the drawer when payment is made via gift certificate.',
    CashDrawerGiftCard: 'Open the drawer when payment is made via gift card.',
    CashDrawerHouseAccount: 'Open the drawer when payment is made via house account.',
    CashDrawerDiscount: 'Open the drawer when a discount is applied.',
    CashDrawerAssignDrawerToSingleUser: 'Limit activity with a cash drawer to a single user at a time.',
    CashDrawerDeductCreditTips: 'Remove tips paid by credit card from drawer calculations.',
    CashDrawerDisconnectedDrawer: 'Permit taking orders without an electronically-connected cash drawer, such as a manual cash box.',
    CashDrawerPreventSignOut: 'Do not permit the user to sign out until the cash drawer has been closed.',
    CashDrawerPrintShiftReport: 'Upon closing the drawer, print the end of shift report to the local receipt printer.',
    CashDrawerVarianceThreshold: 'The percentage within which error may occur between drawer load and close.',
    CashDrawerExpectedTotal: '<span>Beginning Balance:</span> <span class="pull-right">{currencySymbol}{balance} </span><br/><span>Cash Sales:</span> <span class="pull-right">{currencySymbol}{cashSales}</span><br/><span>Cash Gift Card Purchases:</span><span class="pull-right"> {currencySymbol}{giftCardPurchases}</span>',
    // Colors
    ColorsHeading: 'Sets the heading color in the POS.',
    ColorsMakeTableAccount: 'The color to use to indicate an account on the make table.',
    ColorsMakeTableCompleted: 'The color to use on a product which has been completed on the make table.',
    ColorsMakeTableCustomColor: 'The color to use to designate custom values on a product on the make table.',
    ColorsMakeTableInProgress: 'The color to use on an in-process product on the make table.',
    ColorsMakeTableQuantity: 'The color to show various quantities on the make table.',
    ColorsMakeTableReady: 'The color to use to indicate a ready-to-serve product on the make table.',
    ColorsMakeTableThresholds: 'Set the time thresholds on when to change header colors on orders.',
    ColorsProductFontSize: 'Sets the font size to use on products in the invoice.',
    ColorsSubaccountFontSize: 'Sets the subaccount font size in the invoice.',
    ColorsSubaccountHighlight: 'Sets the color to indicate a selected subaccount in the invoice.',
    ColorsSubaccountMain: 'Sets the color to indicate a subaccount in the invoice.',
    ColorsTrim: 'Sets the secondary (trim) color in the POS.',
    ColorsMakeTableCallIn: 'The color to indicate a call-in on the make table.',
    ColorsMakeTablePager: 'The color to indicate a pager on the make table.',

    // Database
    DatabaseHICHistorySize: 'This is the number of items that the HIC maintains to show you sufficient history of activity when its dashboard is opened.',

    // Device Mapping
    DeviceMappingTerminalName: 'This is the terminal you are connecting device(s) to.',
    DeviceMappingDeviceType: 'Select the type of device that you will connect to the terminal.',
    DeviceMappingDeviceName: 'Select the specific device that you choose to connect to the terminal.',

    // Delivery
    DeliveryCountInDrawer: 'Determines whether the delivery fee is counted as cash in the drawer. For some restaurants, the delivery fee is paid directly to the driver, while others, it is collected by the restaurant and reimbursed differently.',
    DeliveryFee: 'Sets the amount to be added to an order if it is for delivery.',

    // Digital Signage
    DigitalSignageFileName: 'The file which will play in the slide show.',
    DigitalSignageHoldSeconds: 'The number of seconds to allow this file to play before moving to the next.',
    DigitalSignageName: 'Designate the name of this slide show.',

    // Dj Schemes
    DJScheme: 'A scheme is a schedule that lets you play playlists based on time of day.',

    //Data Source (Export)
    DataSourceFileNameTimeStamp: 'Timestamp will be appended to the prefix in the filename.',

    // End of Day
    EndOfDayTime: 'This is the hour and minute at which the day divide occurs. The day divide represents the daily cutoff between days, and is not necessarily at midnight, although often it is.',

    // Export Script
    ExportScriptName: 'Name of the SQL Script.',
    ExportScriptScheduleExpression: 'CRON expression that determines the frequency of the execution and export of the script.',
    ExportScriptSetupScript: 'A setup script is run before all other scripts, every time.  Most scripts should not be setup scripts.',

    // Export Engine
    ExportEngineScheduleExpression: 'CRON expression that determines the frequency of the execution of data subscription.',

    // External Api
    ExternalApiName: 'Description name of the API.',
    ExternalApiUrl: 'The path to the API.',
    ExternalApiVerb: 'The HTTP verb and Path to be used for the API invocation.',

    // Hardware
    HardwareActive: 'You can activate and deactivate this hardware with this selector.',
    HardwareDeviceName: 'Give the device a name so that you can refer to it easily throughout the system.',
    HardwareDeviceType: 'Select the type of hardware that you are configuring.',
    HardwareHIC: 'Choose the HIC to which you are linking this hardware to.',

    // Hardware Interface
    HardwareInterfaceName: 'Provide a friendly name for your Hardware Interface Computer.',

    // Layout
    LayoutFunctionButtons: 'Determines the visibility and layout of functional buttons at the bottom of the Order Entry terminals.',
    LayoutReturnHomeAfterProductSelection: 'After adding a product to the invoice, the user will navigate either to the home screen (if this is checked) or to the secondary screen (if not checked).',
    LayoutShowBreadcrumbs: 'If checked, show breadcrumbs as the product is being added to the order.',
    LayoutShowInactiveButton: 'If checked, inactive buttons are shown, only disabled and grayed. If not checked, then inactive buttons are hidden altogether.',
    LayoutShowMenuSearch: 'If checked, show the search box beneath the menu.',
    LayoutShowShiftTask: 'If checked, show Tasks button in the heading of the POS.',
    LayoutSortProduct: 'When components are added to a product, sort them in alpha order (if checked) or in order of addition (if not checked).',
    LayoutToasterPosition: 'Sets the location of toaster messages as they occur.',
    LayoutToasterShowClose: 'If checked, show a close button, allowing the user to close a toaster message on demand.',
    LayoutToasterTimeout: 'Sets how long for the toaster message to display before auto-hiding.',

    // License
    LicenseAccountId: 'This is the account number assigned by your license to the system. Do not change this unless instructed by your customer service specialist.',

    // Order Status
    OrderStatusPending: 'The product’s preparation has not started.',
    OrderStatusRetrievingIngredients: 'The product’s ingredients are being assembled.',
    OrderStatusCooking: 'The product is being heated, fried, grilled, etc.',
    OrderStatusCrafting: 'The product is being assembled and for presentation.',
    OrderStatusReady: 'The product is ready to be served to the customer.',
    OrderStatusDone: 'The product has been served to the customer.',
    OrderStatusPartiallyInProgress: 'The product is partially in progress',

    // Payments
    PaymentsAllowGiftCardBalance: 'If you want to permit your establishment to purchase gift cards using credit cards, check this setting.',
    PaymentsAlwaysDeferPayment: 'Generally a dine-in establishment does not expect payment at the time of order (unlike quick service) – this checkbox changes the behavior of the system to send orders to the operations without expecting payment at the same time.',
    PaymentsGiftCardMask: 'This is a character sequence that tells the system whether the gift card number is considered valid.',
    PaymentsRingupImmediately: 'For quick service, this option builds workflow around order follow by immediate payment.',

    // Permissions
    PermissionTags: 'Type and press enter to add tag(s)',

    // Playlist
    PlaylistAddTrack: 'This allows you to manage tracks to a playlist.',
    PlayListName: 'Name of the playlist.  A playlist is a way to group tracks for use on schedules that you set in DJ Schemes.',

    // Printing
    PrintingCardSignature: 'Set the minimum transaction amount in which a signature is required by the user.',
    PrintingCustomerAddress: 'If the customer has an address in the system, add it to their receipt.',
    PrintingCustomerAddressOnOrderReceipt: 'Include customer addresses on Kitchen Receipts when address is available.',
    PrintingCustomerReceipt: 'Print a copy of the customer receipt automatically.',
    PrintingDeliveryReceiptsXCopies: 'Sets the number of delivery receipts to print when the order is a Delivery.',
    PrintingEachOrderToPrinter: 'When an order is "Sent" it will be printed to the Kitchen Printer.',
    PrintingIgnorePrintReceipt: 'Allow the user to bypass signatures even when required by the parameters.',
    PrintingMerchantReceipt: 'Print a merchant copy of the receipt automatically (for credit and debit cards).',
    PrintingOnlySelectedItems: 'Allow the user to select which items are printed.',
    PrintingPerformRecallsOnModifiedOrders: 'When an order is modified after being sent to the kitchen, send a recall print job.',
    PrintingPromptCashReceipt: 'If set, prompt the user to print a receipt when paid in cash.',
    PrintingRecallOnClose: 'Print a drawer summary upon drawer close.',
    PrintingReceiptSeconds: 'The number of seconds to prompt the user to print the receipt before automatically hiding the prompt.',
    PrintingZoneBinOnReceiving: 'When inventory is received, print zone and bin information.',

    // online ordering
    DaypartMinWaitTimeOverride: 'Daypart maximum orders overrides the "Max orders per time slot" configuration.',

    // Report
    ReportScheduleExpression: 'CRON expression that determines the frequency of the execution of report subscription.',

    // Taxonomy
    TaxonomyAccountTerm: 'The name that your business will use to refer to an Account. Examples: Account, party, table, lot, customer.',
    TaxonomyServerTerm: 'The name that your business will use to refer to a Server. Examples: Waiter, Server, Attendant',
    TaxonomySessionTerm: 'The name that your business will use to refer to an order session. Examples: Order, tab',
    TaxonomySubAccountTerm: 'The name that your business will use to refer to a Subaccount. Examples: Seat, party, department.',

    // Taxs
    TaxesDiscountTax: 'When checked, taxes are applied to the discounted order amounts, otherwise they are applied to the total order prior to discounts.',
    TaxesName: 'Allows you to set multiple tax rates that can be applied to different products, as some jurisdictions charge varying rates on different types of items.',
    TaxesToGoOrders: 'Determines whether the system will apply tax to To Go orders, as some jurisdictions require and others do not.',
    TaxRoundingType: 'While rounding the tax, selected type of rounding method is used.',

    // Terminals
    TerminalsName: 'The name you and your users will use to refer to this terminal.',
    TerminalsTypes: 'You are licensed to specific number of licenses of various types.  This setting determines the behavior and structure of the terminal.',

    // Tracks
    TracksFileName: 'Name of the music file.',
    TracksCategoryName: 'The category allows you to designate the ordering and grouping of playlists.',

    // User Setting
    UserSettingPrintClockoutTime: 'Prints the user’s clock in and clock out times to the receipt printer upon clock out.',
    UserSettingTimeManagement: 'Enables time keeping and enforcement.',

    // Whitelisted Addresses
    WhitelistedIPAddresses: 'Each address provided is a range from which access to the POS is automatically granted.',

    // Workflow
    ShowSubaccountInMakeTable: 'When checked, will show {subAccountTerm} in make table. To Go will always be displayed.',
    ShowWhenMoreThanOneSubaccountInMakeTable: 'When checked, will show {subAccountTerm} in make table when there are more than one {subAccountTerm} in the order.',
    WorkflowAllowOverrideDietaryRestrictions: 'Permit the user to continue with an order even if there is a dietary restriction warning.',
    WorkflowDelayBetweenStateChange: 'This sets the time between tapping items on the make table – used to prevent the user from double tapping and skipping steps inadvertently.',
    WorkflowMaximumAllowedDiscount: 'Governs the maximum percent off that may be set on a button.',
    WorkflowServiceModel: 'This setting affects much of the operation of the POS and is based upon how your establishment serves customers.',
    WorkflowUserMakeTable: 'This option turns on the workflow of make table driven order fulfillment.',
    WorkflowWeekStart: 'In calendars, choose which day of the week is leftmost (in the US, Sunday is often selected, elsewhere, Monday)',
    WorkflowSendOrderToKitchenImmediately: 'When checked, products will start appearing on make table and/or printed on kitchen printer as soon as the item is added to order. If unchecked, items will be sent to kitchen using "Send Order" button in order entry or on full payment.',
    WorkflowFontStyleShadowSequence: 'e.g. 2px 3px 4px blue. The first argument (2px) represents length of the horizontal shadow, 2nd (3px) represents length of vertical shadow, 3rd (4px) represents shadow radius and 4th property (blue) specifies color of the shadow.',
};

