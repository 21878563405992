export const Permissions = {
    // informationManagmentBase
ApprovedTraining: 'TRN_010002',
     // User Interface
UserInterface: 'ADM_050002',
UIButtons: 'ADM_050003',
UIScreens: 'ADM_050004',
MultipleProductGroups: 'ADM_050064',
// sales
Sales: 'ADM_050005',
SalesGroups: 'ADM_050006',
SalesCategories: 'ADM_050007',
SalesProducts: 'ADM_050009',
SalesSizes: 'ADM_050010',
SalesUnits: 'ADM_050008',


// Inventory
ManageInventory: 'ADM_050012',
InventoryVendors: 'ADM_050013',
InventoryStorageUnits: 'ADM_050014',
InventoryCategories: 'ADM_050015',
InventorySubcategories: 'ADM_050016',
InventoryProducts: 'ADM_050017',
InventoryZones: 'ADM_050018',
InventoryRawIngredients: 'ADM_050019',
OffSiteInventory: 'ADM_050057',
DietaryWarnings: 'ADM_050065',

// Team
StaffManagement: 'ADM_050021',
Permissions: 'ADM_050056',
ManageSecurityRoles: 'ADM_050022',
ManageEmployees: 'ADM_050023',
ManageInfoLinkSections: 'ADM_050024',
ManageInfoLinks: 'ADM_050025',
ManageInfractions: 'ADM_050026',
ManageWorkSchedule: 'ADM_050027',
ManageLoginMessages: 'ADM_050028',
ManageTimeClockEntries: 'ADM_050029',
ManageSystemConfiguration: 'ADM_060001',
UserImpersonateUser: 'USR_010001',
UserUnblockUser: 'USR_010002',
// Equipments
Equipments: 'ADM_050041',
EquipmentsTypes: 'ADM_050042',
EquipmentsProperties: 'ADM_050043',
EquipmentsEquipment: 'ADM_050044',
// operation
AppConfigurationOperations: 'ADM_050030',
OperationsJobCodes: 'ADM_050031',
OperationsTasks: 'ADM_050032',
OperationsCashDrawerEntries: 'ADM_050033',
OperationsCashPayouts: 'ADM_050055',
CommentWarnings: 'ADM_050034',
InventoryLossReason: 'ADM_050035',
SalesTags: 'ADM_050036',
// Customers
AppConfigurationCustomers: 'ADM_050037',
CustomersAccountCategories: 'ADM_050038',
CustomersAccounts: 'ADM_050039',
CustomersAccountAttributeTypes: 'ADM_050040',
// Training
Training: 'ADM_050045',
TrainingAreas: 'ADM_050046',
TrainingTopics: 'TRN_020001',
TrainingBadges: 'ADM_050047',
TrainingTopicLevels: 'TRN_020002',

// headerEntryMain
ManageConsole: 'ADM_030001',

// Report
// sales
SalesReports: 'RPT_020002',
// employee
EmployeeReports: 'RPT_020007',
// inventory
InventoryReports: 'RPT_020003',
// opertion
OperationsReports: 'RPT_020004',
// Customers
CustomerReports: 'RPT_020005',
// Troubleshooting
TroubleshootingReports: 'RPT_020006',

// Screen-BackUp
ManageScreenBackups: 'ODE_020007',

// Order
OrderEntryOverridePrice: 'ODE_020001',
OrderEntryToGo: 'ODE_020002',
CancelOrder: 'ODE_020003',
EnterOrder: 'Enter Order',
OrderEntryReceiptReprint: 'Order Entry - Receipt Reprint',
OrderEntryRemoveItem: 'ODE_020004',
OrderEntryRemoveNotSentItem: 'ODE_020005',
OrderEntryScheduleOrder: 'ODE_020006',
OrderRequiredName: 'ODE_020008',
OrderDisableLabelPrint: 'ODE_020009',

// Promotions
ManagePromotions: 'PRO_010001',
PromotionsGiftCard: 'PRO_010002',
PromotionsOther: 'PRO_010003',

// Terminal
SystemConfigurationTerminals: 'ADM_060004',
TerminalsandLicensing: 'ADM_060002',

// Taxonomy
SystemConfigurationTaxonomy: 'ADM_060024',
Operations: 'ADM_060023',

// System Configuration - Taxes
SystemConfigurationTaxes: 'ADM_060026',

// SlideShowList
SystemConfigurationDigitalSignage: 'ADM_060016',

// OrderManagement
OrderManagementVoidOrder: 'ODM_010003',
OrderManagementRefunds: 'ODM_010004',
OrderManagementMarkUnserved: 'ODM_010002',

// ManageConsole
// Inventory
Inventory: 'INV_010001',
InventoryLogInventory: 'INV_010002',
InventoryOrderReceiving: 'INV_010003',
InventoryInventoryLoss: 'INV_010004',
InventoryInventoryPrep: 'INV_010005',
InventoryQuickSearch: 'INV_010006',
InventoryBatchMarkAsOrdered: 'INV_010007',
InventoryInventoryTransfer: 'INV_010008',
InventoryScheduleEditor: 'ADM_050020',
// Drawer
ManageCountDrawer: 'ADM_030002',
ManageOpenDrawer: 'ADM_030003',
ManageOpenCashDrawer: 'ADM_030004',
ManageEndofDay: 'ADM_030005',
ManageTerminalReports: 'RPT_010001',
ManageOrderMaintenance: 'ODM_010001',
ManageEventManager: 'ADM_030007',
ManageGiftCards: 'GFT_010001',
GiftCards: 'GFT_010002',
ManageGiftCardGratis: 'GFT_010005',
ViewGiftCards: 'GFT_010003',
ManageDrivers: 'DLV_010002',
ManageDispatch: 'DLV_010003',
ManageDelivery: 'DLV_010001',
ManageManagementReports: 'RPT_020001',
ManageAppConfiguration: 'ADM_050001',
ManageOnlineBackup: 'ADM_030008',
ManageHelp: 'Manage - Help',
ManagePrintout: 'ADM_030009',
ManageLogs: 'ADM_030012',
ManageBackgroundJobs: 'ADM_030013',
ManageOrderFulfillment: 'ADM_030014',
ManageAutomatedExports: 'EXP_010001',
ManageTraining: 'TRN_010001',
ManageDesignScreens: 'DSN_010001',
ManageScreenTurnScreenDesignModeOnOff: "DSN_010002",
ManageHICDashboard: 'ADM_030010',
ManageLayoutDesigner: 'ADM_030011',
ManageDashboards: 'DSB_010001',
ManageInactiveButtons: 'DSN_010003',
ManageUnlinkedScreens: 'DSN_010004',
ManageUnusedImages: 'DSN_010005',

// Automated Exports
AutomatedExportsConfiguration: 'EXP_010002',
AutomatedExportsSubscriptions: 'EXP_010003',
ReleaseQueue: 'EXP_010004',

// Links
LinkReportSubscriptions: 'LNK_010001',
LinkCustomLinks: 'LNK_010003',
LinkWorkschedule: 'LNK_010004',
CopyReportSubscriptions: 'LNK_010002',
LinkDesignMode: 'LNK_010005',
LinkTask: 'LNK_010006',
LinkCreateTask: 'LNK_010007',
LinkContactSupport: 'LNK_010008',

// Dashboard
DashboardSalesProduct: 'DSB_010002',
DashboardInventoryProduct: 'DSB_010003',
DashboardTemplates: 'DSB_010004',

// Reports
ReportsConfiguration: 'ADM_060048',
SystemConfigurationReports: 'ADM_060050',
SystemConfigurationReportGroups: 'ADM_060049',

// hardwareInterface
SystemConfigurationHardwareInterfaces: 'ADM_060010',


// hardware
SystemConfigurationHardware: 'ADM_060009',
SystemConfigurationCashDrawer: "ADM_060011",


// EndOfDay
SystemConfigurationEndOfDay: 'ADM_060028',


// PlayList
SystemConfigurationPlaylists: 'ADM_060021',

// DJSchemelist
SystemConfigurationDJSchemes: 'ADM_060022',


// Delivery
SystemConfigurationDelivery: 'ADM_060025',

// Logs
LogDetails: 'ADM_030015',
CapturedAudio: 'ADM_030016',

// System Configuration
SystemConfigurationLicensing: 'ADM_060003',
SystemConfigurationRabbitMQConfiguration: 'ADM_060005',
SystemConfigurationCentralAPIConfiguration: 'ADM_060006',
SystemConfigurationDatabase: 'ADM_060007',
SystemConfigurationRecaptchaConfiguration: 'ADM_060071', 
// HardwareandPeripherals
HardwareandPeripherals: 'ADM_060008',
SystemConfigurationPrinting: 'ADM_060012',
// UI and Styling
UIandStyling: 'ADM_060014',
SystemConfigurationBranding: 'ADM_060015',
SystemConfigurationColors: 'ADM_060017',
SystemConfigurationLayout: 'ADM_060018',
SystemConfigurationIcons: 'ADM_060019',
// Localization
SystemConfigurationLocalization: 'ADM_060062',
SystemConfigurationFormats: 'ADM_060063',
// Dj
DJ: 'ADM_060020',
// operations
SystemConfigurationUserSettings: 'ADM_060027',
SystemConfigurationBaselineExpenses: 'ADM_060029',
SystemConfigurationDayparts: 'ADM_060030',
SystemConfigurationCameraAnnotation: 'ADM_060013',
// Account
SystemConfigurationAccountsAndTab: 'ADM_060040',
// customer
Customers: 'ADM_060039',
SystemConfigurationPayments: 'ADM_060041',
SystemConfigurationRevenueCenters: 'ADM_060042',
// security
SecurityWhitelistedAddresses: 'ADM_060044',
SecurityBlacklistedAddresses: 'ADM_060045',
SecurityCardBasedLogins: 'ADM_060046',
SecurityExternalLogins: 'ADM_060047',
SystemConfigurationApiManager: 'ADM_060052',
ApiManager: 'ADM_060051',


// Caching
SystemConfigurationCaching: 'ADM_060054',
FlushCache: 'ADM_060053',

// Email
Email: 'ADM_060055',
SystemConfigurationEmailTemplates: 'ADM_060056',
SystemConfigurationSMSTemplates: 'ADM_060057',
//Loyalty Program
LoyaltyProgram:'ADM_060068',
SystemConfigurationLoyaltyProgramSelfRegistrationForm: 'ADM_060069',
SystemConfigurationLoyaltyProgramAttributes: 'ADM_060070',
// Setup
Setup: 'ADM_060058',
SystemConfigurationNotification: 'ADM_060059',
SystemConfigurationFeatures: 'ADM_060065',
SystemConfigurationFonts: 'ADM_060067',

// QuickBooks
ManageQuickBooks: 'QBE_010001',
ManageCashSalesQuickBooks: 'QBE_010002',


//Menu Display 
MenuDisplay: 'MDS_010001',
MenuDisplayList: 'MDS_010002',

// Custom Data source
CustomDataSource: 'ADM_060060',
DashboardDataSource: 'ADM_060061',

// ManageState.js
Security: 'ADM_060043',

// Online Ordering
OnlineOrdering: 'ADM_050048',
OnlineOrderingSettings: 'ADM_050049',
OnlineOrderingCategories: 'ADM_050050',
OnlineOrderingOptionLists: 'ADM_050051',
OnlineOrderingOptionConfiguration: 'ADM_050052',
OnlineOrderingTermsAndConditions: 'ADM_050065',

// Wait time
WaitTime: 'ADM_050058',
Global: 'ADM_050059',
Order: 'ADM_050060',

// Audio Notifier
AudioNotifier: 'ADM_050053',
AudioClips: 'ADM_050054',

// Gift Card
DeleteGiftCardRecharge: 'GFT_010002',

// workflow
Workflow: 'ADM_060031',
SystemConfigurationGeneralWorkflow: 'ADM_060032',
SystemConfigurationOrderWorkflow: 'ADM_060033',
SystemConfigurationInventoryWorkflow: 'ADM_060037',
SystemConfigurationMakeTableWorkflow: 'ADM_060034',
SystemConfigurationPickScreenWorkflow: 'ADM_060035',
SystemConfigurationGiftCardWorkflow: 'ADM_060038',
SystemConfigurationConfirmationScreenWorkflow: 'ADM_060036',


// Order Kiosk
ManageOrderKiosk: 'ADM_070001',
OrderKioskSection: 'ADM_070002',
OrderKioskButtons: 'ADM_070003',
OrderKioskScreens: 'ADM_070004',
OrderKioskConfigurationSection: 'ADM_070005',
OrderKioskGeneralConfig: 'ADM_070006',
OrderKioskCancelOrderConfig: 'ADM_070007',
OrderKioskIdleTimeoutConfig: 'ADM_070008',
OrderKioskOrderConfirmConfig: 'ADM_070009',
OrderKioskPaymentConfig: 'ADM_070010',
OrderKioskCheckoutConfig: 'ADM_070011',
OrderKioskAIShakeConfig: 'ADM_070012',
OrderKioskTerminal: 'TRM_010018',
SystemConfigurationServeScreenWorkflow: 'ADM_060066',
};

