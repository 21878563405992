// tslint:disable: no-output-on-prefix
import {
    Component, Input, EventEmitter, Output, ViewChild, ElementRef,
    SimpleChanges, Inject, OnChanges, TemplateRef, AfterViewInit, HostListener, HostBinding
} from '@angular/core';
import * as _ from 'lodash';
import { PaginationInfo } from './pagination-info.model';
import { GridColumnTypes } from './grid-column-types';
import { GridColumn } from './grid-column';
import { LinkColumn } from './link-column';
import { TableConfigService } from './service/table-config.service';
import { TextAlign } from './text-align';
import { PaginatorPosition } from './paginator-position';
import { TableReorderableValue } from './table-reorderable';
import { RowSelectionMode } from './row-selection-mode';
import { Table } from 'primeng/table';
import { forEach } from 'lodash';
import { LazyLoadEvent } from 'primeng/api';
import { Format } from './format-type';
import { TarkCurrencyPipe, TarkDatePipe, TarkDateTimePipe, TarkTimePipe } from '@tarktech/tark-ng-utils';

export const EDIT_COLUMN_TOOLTIP = 'Edit';
export const DELETE_COLUMN_TOOLTIP = 'Delete';

@Component({
    selector: 'ng-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnChanges, AfterViewInit {
    public context = {
        PageNumber: 1,
        PageSize: 20,
        Filter: '',
        SortProperty: '',
        SortDirection: 'Asc'
    } as PaginationInfo;

    public gridColumnTypes = GridColumnTypes;
    public formatType = Format;
    @Input('exportData') exportData = false;
    @Input('exportFilename') exportFilename = 'data';

    @Input('columns') columns: Array<GridColumn> = [];
    @Input('data') records: Array<any> = [];
    @Input('rows') rows = 20;

    @Input('search') allowSearch = true;
    @Input('sort') allowSort = true;

    @Input('paginator') paginator = true;

    @Input('serverSidePaginator') serverSidePaginator = false;

    @Input('rowsPerPageOptions') rowsPerPageOptions: Array<number> = null;
    @Input('autoFitColumn') autoFitColumn = true;
    @Input('rowClass') rowClass: string = null;
    @Input('paginatorPosition') paginatorPosition: PaginatorPosition = 'bottom';
    @Input('isReorderableTable') isReorderableTable = false;
    @Input('ReorderableColumnRow') ReorderableColumnRow: TableReorderableValue;
    @Input('emptyMessageTemplate') emptyMessageTemplate: TemplateRef<any>;
    @Input('footerTemplate') footerTemplate: TemplateRef<any>;
    @Input('scrollable') scrollable = false;
    @Input('scrollHeight') scrollHeight: string = null;
    @Input('searchPlaceholder') searchPlaceholder = 'Search';
    @Input('selectionMode') selectionMode: RowSelectionMode;
    @Input('tableStyle') tableStyle: any;
    @Input('rowBgColor') rowBgColor: string = null;
    @Input('rowFontColor') rowFontColor: string = null;
    @Input() export: boolean = true;
    @Input() groupCategory: string = null;
    @Input() lazy = false;
    @Input() virtualScroll = false;
    @Input() styleClass = 'data-list';
    // outputs///
    @Output('onPaginationChange') onPaginationChange = new EventEmitter();
    @Output('onRowReorder') onRowReorder = new EventEmitter();
    @Output('onColumnReorder') onColumnReorder = new EventEmitter();
    @Output('rowSelect') rowSelect = new EventEmitter();
    @Output('rowUnselect') rowUnselect = new EventEmitter();

    @Output('onFilter') onFilter = new EventEmitter<any>();
    @Output() onCustomFilter = new EventEmitter<any>();
    @Output() lazyLoad = new EventEmitter<any>();

    toolTip = 'Edit';
    icon = '';
    @Input('totalRecords') totalRecords = 0;
    @Input('customFilterColumns') customFilterColumns: Array<string>;
    linkColumnCount: number = 0;
    // public scrollHeight: string;
    public globalfilterColumns: Array<string> = [];

    @ViewChild('ptable', { static: true }) public ptable: Table;

    public isExporting = false;

    public previousPageNumber = 1;
    public previousPageSize = 20;

    public editIcon = 'fa-pencil-alt font-16';
    public deleteIcon = 'fa-trash-alt font-16';
    public addIcon = 'fa-plus';
    public printIcon = 'fa-print';
    public exportIcon = 'fa-file-export';
    public actionIcon = 'fa-clipboard-list';
    public showSearch = true;
    isReorderableRow = false;
    isReorderableColumn = false;
    ColumnLength: number;
    selectedRowData: any;
    btnExport = false;
    first = 0;
    rowGroupMetadata: Array<{ index: number; size: number; }>;
    format = 'Format';

    @HostBinding('style.--p-table-wrapper-height') get pTableWrapperHeight() {
        return this.scrollHeight ?? 'unset';
    }

    public onFilterChanged = _.debounce(() => {
        if (this.paginator && this.serverSidePaginator) {
            this.onTableChanged();
        }
    }, 500);

    ngAfterViewInit() {
        this.linkColumnCount = this.getLinkColumns()?.length;
        this.ptable.filterGlobal(this.context.Filter, 'contains');
        this.rows = this.rows && this.rows !== 20 ? this.rows : this.context.PageSize;
        if (this.context.PageNumber > 0) {
            setTimeout(() => {
                this.setPageNumber();
            }, 300);
        }
    }

    @HostListener('document:click', ['$event'])
    onClickEvent(event: MouseEvent) {
        if (event.target['id'] !== 'tarkDropdown' && event.target['id'] !== 'dropdownAction' && event.target['id'] !== 'dropdownCaret') {
            this.btnExport = false;
        }
    }

    constructor(private elementRef: ElementRef,
        private datePipe: TarkDatePipe,
        private dateTimePipe: TarkDateTimePipe,
        private timePipe: TarkTimePipe,
        private currencyPipe: TarkCurrencyPipe,
        @Inject(TableConfigService) private tableConfig) {
        this.initializeTable();
    }

    /**
   * Initializes the client
   */
    normalizePageSize(pageSize) {
        let predefinedSize = this.rowsPerPageOptions.find(val => val >= pageSize);

        if (!predefinedSize) {
            predefinedSize = pageSize;
        }

        return predefinedSize;
    }

    setPageNumber() {
        this.ptable.first = (this.context.PageNumber - 1) * this.context.PageSize;
        this.first = (this.context.PageNumber - 1) * this.context.PageSize;
    }

    getLinkColumns = (): Array<any> => {
        return this.columns.filter(col => this.IsLinkColumn(col));
    }

    getLinkColumnWidth = (): string => {
        const linkWidth = this.linkColumnCount * 45;
        let headerWidth = 0;
        this.getLinkColumns().forEach(x => {
            if(x.AddIcon){
                headerWidth += 52;
            }
            if(x.PrintIcon){
                headerWidth += 88;
            }
        });
        return ((linkWidth > headerWidth) ? linkWidth  : headerWidth) + 'px';
    }

    ngOnChanges(changes: SimpleChanges) {

        if (changes['context.PageSize']) {
            this.context.PageSize = this.normalizePageSize(this.context.PageSize);
        }
        if (changes['records']) {
            if (this.columns.length > 0) {
                if (this.serverSidePaginator) {
                    if (this.context.SortProperty === '') {
                        this.context.SortProperty = this.columns[0].Field;
                    }
                }
            }
            const allRecords = this.serverSidePaginator ? this.totalRecords : this.records.length;
            this.rowsPerPageOptions = this.rowsPerPageOptions ?? this.tableConfig.rowsPerPageOptions;
            if (allRecords > Math.max.apply(null, this.rowsPerPageOptions)) {
                this.rowsPerPageOptions = this.rowsPerPageOptions.concat(allRecords);
            }
            this.context.PageSize = this.context.PageSize <= 20 ? this.context.PageSize : this.rowsPerPageOptions[this.rowsPerPageOptions.length - 1];
            this.rows = this.rows && this.rows !== 20 ? this.rows : this.context.PageSize;
            const filterableColumns = this.columns.filter(col => col.Type === GridColumnTypes.GridColumn
                || col.Type === GridColumnTypes.PrimaryColumn
                || col.Type === GridColumnTypes.TemplateColumn
                || col.Type === GridColumnTypes.CurrencyColumn
                || col.Type === GridColumnTypes.DateColumn
                || col.Type === GridColumnTypes.DecimalColumn
                || col.Type === GridColumnTypes.LinkTemplateColumn);
            this.globalfilterColumns = [];
            filterableColumns.forEach(col => {

                if (col.Type === GridColumnTypes.DateColumn) {
                    this.records.forEach((data) => {
                        if (col[this.format] === this.formatType.Date) {
                            data[col.Field + 1] = this.datePipe.transform(data[col.Field]);
                        } else if (col[this.format] === this.formatType.DateTime) {
                            data[col.Field + 1] = this.dateTimePipe.transform(data[col.Field]);
                        } else if (col[this.format] === this.formatType.Time) {
                            data[col.Field + 1] = this.timePipe.transform(data[col.Field]);
                        }
                    });
                    this.globalfilterColumns.push(col.Field + 1);
                } else if (col.Type === GridColumnTypes.CurrencyColumn) {
                    this.records.forEach((data) => {
                        data[col.Field + 1] = this.currencyPipe.transform(data[col.Field]);
                    });
                    this.globalfilterColumns.push(col.Field + 1);
                } else if (col.Field && col.Field !== '') {
                    this.globalfilterColumns.push(col.Field);
                }
            });
            if (this.customFilterColumns !== undefined && this.customFilterColumns !== null) {
                this.customFilterColumns.forEach(col => {
                    this.globalfilterColumns.push(col);
                });
            }
            setTimeout(() => {
                this.setPageNumber();
            }, 300);
        }
        if (changes['rows']) {
            this.rows = this.normalizePageSize(this.rows);
        }
        if (this.serverSidePaginator && this.isExporting) {
            this.isExporting = false;
            setTimeout(() => {
                this.ptable.exportCSV();
                this.setPageSize(this.previousPageSize, this.previousPageNumber);
                this.onTableChanged();
            }, 50);
        }

        // check if row/column is reorderble or not
        if (this.isReorderableTable && (this.ReorderableColumnRow === 'row' || this.ReorderableColumnRow === 'both')) {
            this.isReorderableRow = true;
        } else {
            this.isReorderableRow = false;
        }

        if (this.isReorderableTable && (this.ReorderableColumnRow === 'column' || this.ReorderableColumnRow === 'both')) {
            this.isReorderableColumn = true;
        } else {
            this.isReorderableColumn = false;
        }
        /// End

        if (changes['context.Filter']) {
            if (this.context.Filter) {
                this.ptable.filterGlobal(this.context.Filter, 'contains');
            }
        }

        if (changes['context.PageNumber']) {
            if (this.context.PageNumber > 0) {
                this.first = (this.context.PageNumber - 1) * this.context.PageSize;
            }
        }

        if (this.groupCategory) {
            this.prepareGroupColumns();
        }
    }
    public paginate(event) {
        this.context.PageNumber = event.page + 1;
        this.context.PageSize = event.rows;
        this.onTableChanged();
    }

    public onSortChanged(event) {
        if (this.paginator && this.serverSidePaginator) {
            this.context.SortProperty = event.sortField;
            this.context.SortDirection = event.sortOrder === 1 ? 'Asc' : 'Desc';
            this.onTableChanged();
        }
    }

    public onPageChange(event) {
        this.context.PageSize = event.rows;
        this.context.PageNumber = Math.ceil((event.first + 1) / this.context.PageSize);
    }

    public onSort(event) {
        this.context.SortProperty = event.field;
        this.context.SortDirection = event.order === 1 ? 'Asc' : 'Desc';
    }

    public initializeTable() {
        if (this.tableConfig.editIcon !== undefined && this.tableConfig.editIcon !== '') {
            this.editIcon = this.tableConfig.editIcon;
        }
        if (this.tableConfig.deleteIcon !== undefined && this.tableConfig.deleteIcon !== '') {
            this.deleteIcon = this.tableConfig.deleteIcon;
        }
        if (this.tableConfig.addIcon !== undefined && this.tableConfig.addIcon !== '') {
            this.addIcon = this.tableConfig.addIcon;
        }
        if (this.tableConfig.printIcon !== undefined && this.tableConfig.printIcon !== '') {
            this.printIcon = this.tableConfig.printIcon;
        }
        if (this.tableConfig.pageSize !== undefined && this.tableConfig.pageSize !== '') {
            this.rows = this.tableConfig.pageSize;
            this.context.PageSize = this.tableConfig.pageSize;
        }

        if (this.rowsPerPageOptions?.length > 0) {
            this.rowsPerPageOptions = this.tableConfig.rowsPerPageOptions;
        }
        if (this.tableConfig.exportData !== undefined) {
            this.exportData = this.tableConfig.exportData;
        }
        if (this.tableConfig.paginatorPosition !== undefined) {
            this.paginatorPosition = this.tableConfig.paginatorPosition;
        }
        if (this.tableConfig.searchPlaceholder !== undefined && this.tableConfig.searchPlaceholder !== '') {
            this.searchPlaceholder = this.tableConfig.searchPlaceholder;
        }
    }

    public onTableChanged() {
        this.onPaginationChange.emit();
    }

    public IsLinkColumn(data: GridColumn): Boolean {

        if (data.Type === GridColumnTypes.LinkColumn
            || data.Type === GridColumnTypes.EditColumn || data.Type === GridColumnTypes.DeleteColumn || data.Type === GridColumnTypes.LinkTemplateColumn) {
            return true;
        } else {
            return false;
        }


    }
    public getToolTip(data: LinkColumn): string {
        if (data.Type === GridColumnTypes.EditColumn) {
            return EDIT_COLUMN_TOOLTIP;
        } else if (data.Type === GridColumnTypes.DeleteColumn) {
            return DELETE_COLUMN_TOOLTIP;
        } else {
            return data.ToolTip;
        }
    }

    public getIcon(data: LinkColumn): string {
        if (data.Type === GridColumnTypes.EditColumn && data.Icon === undefined) {
            return this.editIcon;
        } else if (data.Type === GridColumnTypes.DeleteColumn && data.Icon === undefined) {
            return this.deleteIcon;
        } else {
            return data.Icon;
        }
    }
    public DisplayTitle(data: any): string {
        if (data.Type === GridColumnTypes.EditColumn) {
            return 'Edit';
        } else if (data.Type === GridColumnTypes.DeleteColumn) {
            return 'Delete';
        } else {
            return data.ToolTip;
        }
    }

    public getPropertyValue(data: any, propertyName: string): any {
        return _.get(data, propertyName);
    }

    public getTextAlignClass(columnSpec: GridColumn): string {
        if (columnSpec.TextAlign === TextAlign.Center) {
            return 'text-center';
        } else if (columnSpec.TextAlign === TextAlign.Left) {
            return 'text-left';
        } else if (columnSpec.TextAlign === TextAlign.Right) {
            return 'text-right';
        }
    }

    public getWidth(columnSpec: GridColumn): string {
        if (columnSpec.Width && columnSpec.Width !== '') {
            return columnSpec.Width;
        } else {
            return 'auto';
        }
    }

    public exportCSVData(): void {
        this.previousPageNumber = this.context.PageNumber;
        this.previousPageSize = this.context.PageSize;
        this.isExporting = true;
        this.setPageSize(this.totalRecords, 1);
        this.onTableChanged();
    }
    public setPageSize(pageSize: number, pageNumber: number): void {
        this.context.PageNumber = pageNumber;
        this.context.PageSize = pageSize;
    }

    public rowReorder(event) {
        this.onRowReorder.emit({ dragIndex: event.dragIndex, dropIndex: event.dropIndex });
    }

    public columnReorder(event) {
        // event.columns: Columns array after reorder
        this.onColumnReorder.emit({ dragIndex: event.dragIndex, dropIndex: event.dropIndex, columns: event.columns });
    }

    getColumnLength(columnLength: number) {
        if (this.isReorderableRow) {
            return this.ColumnLength = columnLength + 1;
        } else {
            return columnLength;
        }
    }

    public filter(value: any, field: any, matchMode: any) {
        this.ptable.filter(value, field, matchMode);
    }

    public reset() {
        this.ptable.reset();
    }

    onRowSelect(event) {
        this.rowSelect.emit(event);
    }

    onRowUnselect(event) {
        this.rowUnselect.emit(event);
    }

    onTableFiltered(event) {
        this.onFilter.emit(event);
        if (event.filteredValue && event.filteredValue.length &&
            ((this.rows * (this.context.PageNumber - 1)) + 1) > event.filteredValue.length) {
            this.context.PageNumber = 1;

        }
        const allRecords = event.filteredValue?.length ? event.filteredValue.length : this.records?.length;
        this.rowsPerPageOptions = this.rowsPerPageOptions ?? this.tableConfig.rowsPerPageOptions;
        if (allRecords > Math.max.apply(null, this.rowsPerPageOptions)) {
            this.rowsPerPageOptions = this.rowsPerPageOptions.concat(allRecords);
        }
    }

    prepareGroupColumns() {
        this.rowGroupMetadata = [];
        if (this.records) {
            forEach(this.records, (record, index) => {
                if (record) {
                    const representativeName = record[this.groupCategory];
                    if (!index) {
                        this.rowGroupMetadata[representativeName] = { index: 0, size: 1 };
                    } else {
                        const previousRowData = this.records[index - 1];
                        const previousRowGroup = previousRowData[this.groupCategory];
                        if (representativeName === previousRowGroup) {
                            this.rowGroupMetadata[representativeName].size++;
                        } else {
                            this.rowGroupMetadata[representativeName] = { index: index, size: 1 };
                        }
                    }
                }
            });
        }
    }

    loadLazyRecords(event: LazyLoadEvent) {
        if (this.lazy && !this.context.PageNumber) {
            this.context.PageNumber = 1;
        }
        this.lazyLoad.emit(event);
    }
}
