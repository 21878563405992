<pos-edit-wrapper [isPopup]="isScroll" heading="{{!isScroll 
    ? salesProductId > 0 ? 'Sales Product: ' + salesProduct.Name: 'Sales Product' 
    : salesProductId > 0 ? 'Edit Sales Product: ' + salesProduct.Name: 'Add Sales Product' }}"
  (close)="onCancel(false)" [headingIcon]="icons.salesProductsFileInvoiceDollar">
  <form #formSalesProduct="ngForm" (ngSubmit)='saveProductDetails(formSalesProduct.valid)'
    [posValidate]="validateSalesProductEdit">
    <div *ngIf="isScroll" class="clearfix"></div>
    <div class="alert condensed inherit-position alert-success" id="divSalesProductSuccessErrorMsg"
      style="display: none;"></div>
    <context-provider provide="props">
      <div class="col-12 container-fluid tab-parent" [ngClass]="{'border-top': !isScroll}">
        <ul class="nav nav-tabs inner-tab pos-nav-tabs pt-pix-10">
          <li [ngClass]="{'active': selectedTab == tabList.ProductsInfo }">
            <a (click)="selectedTab = tabList.ProductsInfo" class="blue" [ngClass]="{'required':productInfo.invalid}">
              <!-- [ngClass]="{'required':!childFormValidCheck}"> -->
              <span>
                <pos-icon class="theme-separator pe-pix-5" [icon]="icons.salesProductInfo"></pos-icon>
              </span>
              <span>Info</span>
            </a>
          </li>
          <li [ngClass]="{'active': selectedTab == tabList.ProductPrice }">
            <a (click)="selectedTab = tabList.ProductPrice" class="blue">
              <span class="pe-pix-5">
                <pos-icon class="theme-separator" [icon]="icons.salesProductPricing"></pos-icon>
              </span> <span>Pricing</span> </a>
          </li>
          <li [ngClass]="{'active': selectedTab == tabList.ProductWorkflow }">
            <a (click)="selectedTab = tabList.ProductWorkflow" class="blue">
              <span class="pe-pix-5">
                <pos-icon class="theme-separator" [icon]="icons.salesProductWorkflow"></pos-icon>
              </span> <span>Workflow</span> </a>
          </li>
          <li [ngClass]="{'active': selectedTab == tabList.ProductDefaultButtons }">
            <a (click)="selectedTab = tabList.ProductDefaultButtons" class="blue">
              <span class="pe-pix-5">
                <pos-icon class="theme-separator" [icon]="icons.salesProductButtonsDiceOne"></pos-icon>
              </span> <span>Buttons</span> </a>
          </li>
          <li [ngClass]="{'active': selectedTab == tabList.ProductRecipeCard }">
            <a (click)="selectedTab = tabList.ProductRecipeCard" class="blue" (click)="productIngredient()">
              <span class="pe-pix-5">
                <pos-icon class="theme-separator" [icon]="icons.salesProductRecipe"></pos-icon>
              </span> <span>Recipe</span> </a>
          </li>
          <li [ngClass]="{'active': selectedTab == tabList.ProductQuickReport }">
            <a (click)="selectedTab = tabList.ProductQuickReport" class="blue">
              <span class="pe-pix-5">
                <pos-icon class="theme-separator" [icon]="icons.salesProductQuickReportFileAlt"></pos-icon>
              </span> <span>Sales Report</span> </a>
          </li>
          <li [ngClass]="{'active': selectedTab == tabList.ProductTimer }">
            <a (click)="selectedTab = tabList.ProductTimer" class="blue"
              [ngClass]="{'required':productTimer.invalid}">
              <span class="pe-pix-5">
                <pos-icon class="theme-separator" [icon]="icons.salesProductClock"></pos-icon>
              </span> <span>Timer</span> </a>
          </li>
          <li [ngClass]="{'active': selectedTab == tabList.ProductAliases }">
            <a (click)="selectedTab = tabList.ProductAliases" class="blue">
              <span class="pe-pix-5">
                <i class="theme-separator fab fa-simplybuilt"></i>
                <!-- <pos-icon class="theme-separator" [icon]="icons.productSimplyBuilt"></pos-icon> -->
              </span> <span>Aliases</span> </a>
          </li>
          <li [ngClass]="{'active': selectedTab == tabList.MenuDisplay }">
            <a (click)="selectedTab = tabList.MenuDisplay" class="blue">
              <span class="pe-pix-5">
                <pos-icon class="theme-separator" [icon]="icons.list"></pos-icon>
              </span> <span>Menu Display</span> </a>
          </li>
          <li [ngClass]="{'active': selectedTab == tabList.Barcodes }">
            <a (click)="selectedTab = tabList.Barcodes" class="blue">
              <span class="pe-pix-5">
                <pos-icon class="theme-separator" [icon]="icons.barcode"></pos-icon>
              </span> <span>Barcodes</span> </a>
          </li>
        </ul>
        <div class="overflow-auto-tabs screen-scroll" [ngStyle]="{'height': outerContainerHeight}" style="width:100%">
          <div [hidden]="selectedTab != tabList.ProductsInfo" ngModelGroup="productsInfo" #productInfo="ngModelGroup">
            <div
              class="pos-tab-body border-top-0 tab-top-border form-horizontal screen-scroll pt-pix-5"
              [ngStyle]="{'max-height': innerContainerHeight}">

              <pos-row colXs="12"> 
                <pos-column>
                  <pos-form-field label="Product Name"
                    [validationMessages]="{required: 'Please enter product name.'}">
                    <input posInput name="productName" [posValidationGroup]="formGroups.SalesProductsSettings"
                      id="productName" #productName="ngModel" type="text" class="form-control"
                      [(ngModel)]="salesProduct.Name" [required]="true" />
                  </pos-form-field>
                </pos-column>

                <pos-column>
                  <pos-form-field>
                    <p-checkbox posInput name="active" #active="ngModel" [(ngModel)]="salesProduct.Active"
                      binary="true" label="Active Product">
                    </p-checkbox>
                  </pos-form-field>
                </pos-column>

                <pos-column>
                  <pos-form-field label="Category" [validationMessages]="{required: 'Please select category.'}"
                    inputStyle="col-12">

                    <pos-row colMd="4" colSm="6" colXs="12" class="align-items-center">
                      <pos-column>
                        <ng-select posInput appendTo="body" [items]="categoryList"
                          [posValidationGroup]="formGroups.SalesProductsSettings" bindLabel="name" bindValue="id"
                          (change)="reloadProductSizes(salesProduct.CategoryId); getSalesProductKitchenPrintersForInfo(salesProduct.CategoryId)"
                          placeholder="Select category" [(ngModel)]="salesProduct.CategoryId"
                          id="selectedRestrictions" name="selectedRestrictions" [required]="true">
                        </ng-select>
                      </pos-column>
                      <pos-column permissions='[{"Name":"{{permission.salesCategory}}", "Level":"{{permission.editLevel}}"}]' class="pt-pix-6 pt-pix-sm-0">
                        <span (click)="addNewCategory(0)">
                          <pos-action-icon [icon]="icons.plusWhite" iconText="Add"></pos-action-icon>
                        </span>
                        <span (click)="addNewCategory(salesProduct.CategoryId)">
                          <pos-action-icon class="text-nowrap ms-pix-5" [icon]="icons.editWhiteWithText" iconText="Edit">
                          </pos-action-icon>
                        </span>
                      </pos-column>
                    </pos-row>
                  </pos-form-field>
                </pos-column>

                <pos-column>
                  <pos-form-field>
                    <p-checkbox posInput name="taxable" #taxable="ngModel" [(ngModel)]="salesProduct.Taxable"
                      binary="true" label="Taxable" (onChange)="salesProduct.Taxable ? '' : salesProduct.SalesTaxId = null">
                    </p-checkbox>
                  </pos-form-field>
                </pos-column>

                <pos-column>
                  <pos-form-field label="Tax Category" [fieldInfo]="fieldInfoMessages.Category"
                    [validationMessages]="{required: 'Please select tax category.'}"
                    inputStyle="col-lg-2 col-md-3 col-sm-3 col-12">
                    <ng-select posInput appendTo="body" [items]="salesTaxList" bindLabel="Name" bindValue="Id"
                      placeholder="Select sales tax" [(ngModel)]="salesProduct.SalesTaxId" id="selectedSalesTax"
                      name="selectedSalesTax" [posValidationGroup]="formGroups.SalesProductsSettings"
                      [required]="salesProduct.Taxable" [disabled]="!salesProduct.Taxable">
                    </ng-select>
                  </pos-form-field>
                </pos-column>
                <pos-column *ngIf="settingParams.AllowOrderingFractionalQty">
                  <pos-row colXs="12" class="p-0">
                    <pos-column>
                      <pos-form-field label="Increment">
                        <input type="number" id="increment" name="increment" min="0"
                          class="form-control text-right col-120" [(ngModel)]="salesProduct.Increment" posDecimal
                          posInput />
                      </pos-form-field>
                    </pos-column>
                    <pos-column>
                      <pos-row class="p-0">
                        <pos-column lg="3" md="3" sm="6" xs="12">
                          <pos-form-field label="Default Quantity"
                            [validationMessages]="{checkDefaultQty: 'Default Quantity must be multiple of ' + salesProduct.Increment + '.'}">
                            <input type="number" id="defaultQty" name="defaultQty" min="0"
                              class="form-control text-right col-120" [step]="salesProduct.Increment"
                              [posValidationGroup]="formGroups.SalesProductsSettings" [isIgnoreModel]="true"
                              [exValidation]="!(salesProduct.Increment && salesProduct.DefaultQty && checkDefaultQty)"
                              [(ngModel)]="salesProduct.DefaultQty" (ngModelChange)="checkDefaultQuantity()"
                              posDecimal posInput />
                          </pos-form-field>
                        </pos-column>
                        <pos-column lg="4" md="3" sm="6" xs="12">
                          <pos-form-field label="Unit">
                            <ng-select posInput appendTo="body" class="col-150" [items]="salesUnitList"
                              bindLabel="Name" bindValue="Id" placeholder="Select unit"
                              [(ngModel)]="salesProduct.UnitId" id="selectedSalesUnit" name="selectedSalesUnit">
                            </ng-select>
                          </pos-form-field>
                        </pos-column>
                      </pos-row>
                    </pos-column>
                  </pos-row>
                </pos-column>
                <pos-column>
                  <pos-form-field label="Type" [fieldInfo]="fieldInfoMessages.Type" [validationMessages]="{required: 'Please select type.'}">
                    <ng-select posInput #drpProductRevenue="ngModel" name="drpProductRevenue" id="drpProductRevenue"
                      [(ngModel)]="salesProduct.revenue" appendTo="body" [items]="revenueType" bindValue="value"
                      bindLabel="type" [required]="true">
                    </ng-select>
                  </pos-form-field>
                </pos-column>
                <pos-column>
                  <pos-form-field label="Information" [fieldInfo]="fieldInfoMessages.Information" inputStyle="col-lg-4 col-md-6 col-sm-8 col-12">
                    <textarea posInput class="form-control h-200" maxlength="120" name="instructions"
                      #instructions="ngModel" [(ngModel)]="salesProduct.Information" rows="4"></textarea>
                  </pos-form-field>
                </pos-column>
                <pos-column>
                  <pos-form-field>
                    <p-checkbox name="noTags" #noTags="ngModel" (onChange)="onNoTagsChange()"
                      [(ngModel)]="salesProduct.NoTags" binary="true" posInput label="No Tags">
                    </p-checkbox>
                  </pos-form-field>
                </pos-column>
                <pos-column *ngIf="tags" xs="12">
                  <pos-ng-select-tag #tagSelection [items]="tags" [(disable)]="salesProduct.NoTags"
                    controlLabel="Tags" [selectedTags]="selectedTags" [label]="'Name'" [hideSelected]="true"
                    [multiple]="true" dropdownPosition="top">
                  </pos-ng-select-tag>
                </pos-column>
                <pos-column>
                  <pos-form-field label="Product Image">
                    <div class="row">
                      <div class="col-9">
                        <input posInput type="file" class="form-control" accept=".png,.jpg,.jpeg,.bmp"
                          name="productImage" id="productImage" (change)="handleFileInput($event.target.files, true)"
                          ngModel #productImage />
                      </div>
                      <div class="col-md-1 col-3 p-0 col-50 mt-pix-n1" [hidden]="!salesProduct.ProductImage">
                        <img class="icon" src="{{productImagePath}}/{{salesProduct.ProductImage}}"
                          #productImagePreview id="productImagePreview" alt=""/>
                        <div class="relative float-right mt-pix-n5" (click)="removeIcon(true)">
                          <pos-icon [icon]="icons.times" class="pointer ms-pix-n7 remove-icon-badge"></pos-icon>
                        </div>
                      </div>
                    </div>
                  </pos-form-field>
                </pos-column>
                <pos-column>
                  <pos-form-field label="Order Fulfillment Workflow">
                    <ng-select posInput dropdownPosition="top" [items]="orderFulfillmentType" bindLabel="Label"
                      bindValue="Value" placeholder="Select order fulfillment workflow" [multiple]="true"
                      [(ngModel)]="salesProduct.OrderFulfillments" id="selectedSalesUnit" name="orderFulfillment">
                    </ng-select>
                  </pos-form-field>
                </pos-column>
              </pos-row>
            </div>
          </div>
          <div [hidden]="selectedTab != tabList.ProductPrice">
            <div
              class="pos-tab-body border-top-0 tab-top-border form-horizontal screen-scroll pt-pix-5"
              [ngStyle]="{'max-height': innerContainerHeight}">
              <pos-sales-product-price #salesProductPrice [salesCategoryId]="salesCategoryId" [productId]="salesProductId"
                [defaultPriceId]="defaultPriceId" [(salesProductPrices)]="salesProductPrices"
                (onDelete)="deletePrice($event)"></pos-sales-product-price>
            </div>
          </div>
          <div [hidden]="selectedTab != tabList.ProductWorkflow">
            <div
              class="pos-tab-body border-top-0 tab-top-border form-horizontal pt-pix-5">
              <pos-row>
                <pos-column>
                  <pos-form-field label="Kitchen Printer" [fieldInfo]="kitchenPrinterInfo">
                    <ng-select posInput [items]="kitchenPrinters" [multiple]="true" bindLabel="Name"
                      placeholder="Select kitchen printers" appendTo="body" [hideSelected]="true"
                      [(ngModel)]="selectedSalesProductKitchenPrinters" name="salesProductKitchenPrinter">
                    </ng-select>
                  </pos-form-field>
                </pos-column>

                <pos-column>
                  <pos-form-field label="Make Table Color" [fieldInfo]="fieldInfoMessages.MakeTableColor">
                    <pos-color-picker posInput name="MakeTableColor" [(ngModel)]="salesProduct.MakeTableColor">
                    </pos-color-picker>
                  </pos-form-field>
                </pos-column>
                
                <pos-column>
                  <pos-form-field>
                    <div class="display-flex">
                      <p-checkbox posInput name="pickScreen" [disabled]="!isMakeTableRetrievingIngredientsState"
                        #pickScreen="ngModel" [(ngModel)]="salesProduct.UsePickScreen" binary="true"
                        label="Use Pick Screen">
                      </p-checkbox>
                      <field-info info="{{fieldInfoMessages.UsePickScreen}}" [styleClass]="'pt-0'">
                      </field-info>
                      <field-info *ngIf="!isMakeTableRetrievingIngredientsState"
                        info="{{infoMakeTableRetrievingIngredientsState}}" [styleClass]="'pt-0'">
                      </field-info>
                    </div>
                  </pos-form-field>
                </pos-column>

                <pos-column>
                  <pos-form-field>
                    <div class="display-flex">
                      <p-checkbox posInput name="isShowInstructions" [disabled]="!isMakeTableCraftingState"
                        #isShowInstructions="ngModel" [(ngModel)]="salesProduct.IsShowInstructions" binary="true"
                        label="Instruction Screen">
                      </p-checkbox>
                      <field-info info="{{fieldInfoMessages.UseInstructionScreen}}" [styleClass]="'pt-0'">
                      </field-info>
                      <field-info *ngIf="!isMakeTableCraftingState" info="{{infoMakeTableCraftingState}}"
                        [styleClass]="'pt-0'">
                      </field-info>
                    </div>
                  </pos-form-field>
                </pos-column>

                <pos-column>
                  <pos-form-field>
                    <div class="display-flex">
                      <p-triStateCheckbox posInput name="isSkipReadyState" [disabled]="!isMakeTableCraftingState"
                        #isSkipReadyState="ngModel" label="Skip Ready State"
                        [(ngModel)]="salesProduct.IsSkipReadyState"></p-triStateCheckbox>
                      <field-info info="{{fieldInfoMessages.IsReadyState}}" [styleClass]="'pt-0'">
                      </field-info>
                      <field-info *ngIf="!isMakeTableReadyState" info="{{infoMakeTableReadyState}}"
                        [styleClass]="'pt-0'">
                      </field-info>
                    </div>
                  </pos-form-field>
                </pos-column>

                <pos-column>
                  <pos-form-field>
                    <p-checkbox posInput name="isAllowOutOfStockOrder" #isAllowOutOfStockOrder="ngModel"
                      [(ngModel)]="salesProduct.IsAllowOutOfStockOrder" binary="true"
                      label="Permit Out of Stock Orders">
                    </p-checkbox>
                  </pos-form-field>
                </pos-column>

                <pos-column>
                  <pos-form-field label="Use Parallel Pick Screen"
                    [fieldInfo]="fieldInfoMessages.UseParallelPickScreen" inputStyle="col-md-6 col-200">
                    <ng-select posInput #drpProductUseParallelPickScreen="ngModel"
                      name="drpProductUseParallelPickScreen" id="drpProductUseParallelPickScreen"
                      placeholder="Default" [(ngModel)]="salesProduct.UseParallelPickScreen" appendTo="body"
                      [items]="useParallelPickScreen" bindValue="value" bindLabel="name">
                    </ng-select>
                  </pos-form-field>
                </pos-column>
              </pos-row>
            </div>
          </div>
          <div [hidden]="selectedTab != tabList.ProductDefaultButtons">
            <div
              class="pos-tab-body border-top-0 tab-top-border form-horizontal screen-scroll pt-pix-5"
              [ngStyle]="{'max-height': innerContainerHeight}">
              <div *ngIf="!salesProductId" class="alert condensed inherit-position alert-warning mt-pix-10">
                {{saveButtonPressToProceed}}
              </div>
              <pos-sales-product-default-button *ngIf="salesProductId" [productId]="salesProductId" [isPopup]="">
              </pos-sales-product-default-button>
            </div>
          </div>
          <div [hidden]="selectedTab != tabList.ProductRecipeCard">
            <div
              class="pos-tab-body border-top-0 tab-top-border form-horizontal screen-scroll pt-pix-5"
              [ngStyle]="{'max-height': innerContainerHeight}">
              <div *ngIf="!salesProductId" class="alert condensed inherit-position alert-warning mt-pix-10">
                {{saveButtonPressToProceed}}
              </div>
              <div *ngIf="salesProductId && isDeletePrice">
                <pos-sales-product-recipe-list [productId]="salesProductId" [productName]="salesProductName"
                  [isDefaultQtyFraction]="isDefaultQtyFraction"
                  (changeDirectLinkState)="changeDirectLinkStateForSalesPrice($event)"
                  (recipeUpdated)="getProductSizesWithPrice($event)">
                </pos-sales-product-recipe-list>
              </div>
            </div>
          </div>
          <div [hidden]="selectedTab != tabList.ProductQuickReport">
            <div
              class="pos-tab-body border-top-0 tab-top-border form-horizontal screen-scroll pt-pix-5"
              [ngStyle]="{'max-height': innerContainerHeight}">
              <div *ngIf="!salesProductId" class="alert condensed inherit-position alert-warning mt-pix-10">
                {{saveButtonPressToProceed}}
              </div>
              <pos-sales-product-quick-report *ngIf="salesProductId" [productId]="salesProductId" [isPopup]="false">
              </pos-sales-product-quick-report>
            </div>
          </div>
          <div [hidden]="selectedTab != tabList.ProductTimer" ngModelGroup="productsTimer"
            #productTimer="ngModelGroup">

            <div
              class="pos-tab-body border-top-0 tab-top-border form-horizontal pt-pix-5"
              [ngStyle]="{'max-height': innerContainerHeight}">
              <pos-row>
                <pos-column>
                  <pos-form-field label="Prep Group" [fieldInfo]="fieldInfoMessages.UseWorkflowTimerPrepGroup">
                    <input posInput name="PrepGroupName" #PrepGroupName="ngModel" type="text" class="form-control"
                      [(ngModel)]="salesProduct.PrepGroupName" />
                  </pos-form-field>
                </pos-column>
                <pos-column>
                  <pos-form-field>
                    <div class="display-flex">
                      <p-checkbox posInput [disabled]="!isMakeTableCookingState" name="timePrep" #timePrep="ngModel"
                        [(ngModel)]="salesProduct.IsTimedPrep" binary="true" label="Timed Prep">
                      </p-checkbox>
                      <field-info info="{{fieldInfoMessages.TimedPrep}}" [styleClass]="'pt-0 pull-right'">
                      </field-info>
                    </div>
                  </pos-form-field>
                </pos-column>
                <pos-column>
                  <pos-form-field>
                    <p-checkbox posInput [disabled]="!salesProduct.IsTimedPrep || !isMakeTableCookingState"
                      name="userWorkflowTimer" #userWorkflowTimer="ngModel"
                      [(ngModel)]="salesProduct.IsUseWorkflowTimer" binary="true" label="Use Workflow Timer">
                    </p-checkbox>
                  </pos-form-field>
                </pos-column>
                <pos-column>
                  <pos-form-field label="Prep Time" [fieldInfo]="fieldInfoMessages.UseWorkflowTimerPrepTime"
                    [validationMessages]="{min: 'Prep time must be greater than zero.'}">
                    <div class="row row-cols-2">
                      <div class="col-120">
                        <input posInput [posValidationGroup]="formGroups.TimerSetting" [isIgnoreModel]="true" type="number"
                          [exValidation]="!(salesProduct.IsTimedPrep && isMakeTableCookingState && salesProduct.PrepTime != null && salesProduct.PrepTime <= 0)"
                          id="prepTime" name="prepTime" min="1" class="form-control"
                          [disabled]="!salesProduct.IsTimedPrep || !isMakeTableCookingState" [(ngModel)]="salesProduct.PrepTime"
                          posOnlyNumbers (ngModelChange)="secondsConvertToTime(salesProduct.PrepTime)" />
                      </div>
                      <div>
                        <label class="label-section">{{prepSecondsConvertToTime | date:'HH:mm:ss'}}
                        </label>
                      </div>
                    </div>
                  </pos-form-field>
                </pos-column>
                <pos-column>
                  <pos-form-field label="Prep Button Color"
                    [fieldInfo]="fieldInfoMessages.UseWorkflowTimerPrepButtonColor">
                    <pos-color-picker posInput [disabled]="!salesProduct.IsTimedPrep || !isMakeTableCookingState"
                      [(ngModel)]="salesProduct.PrepButtonColor" name="PrepButtonColor">
                    </pos-color-picker>
                  </pos-form-field>
                </pos-column>
              </pos-row>
            </div>
          </div>
          <div [hidden]="selectedTab != tabList.ProductAliases">
            <div
              class="pos-tab-body border-top-0 tab-top-border form-horizontal screen-scroll pt-pix-5"
              [ngStyle]="{'max-height': innerContainerHeight}">
              <div *ngIf="!salesProductId" class="alert condensed inherit-position alert-warning mt-pix-10">
                {{saveButtonPressToProceed}}
              </div>
              <div *ngIf="salesProductId > 0">
                <sales-product-alias [salesProductId]="salesProductId"></sales-product-alias>
              </div>
            </div>
          </div>
          <div [hidden]="selectedTab != tabList.MenuDisplay">
            <div
              class="pos-tab-body border-top-0 tab-top-border form-horizontal screen-scroll pt-pix-5"
              [ngStyle]="{'max-height': innerContainerHeight}">

              <pos-row>
                <pos-column>
                  <pos-form-field label="Text">
                    <input posInput name="displayText" id="displayText" #displayText="ngModel" type="text"
                      class="form-control" [(ngModel)]="salesProduct.MenuDisplayText" />
                  </pos-form-field>
                </pos-column>

                <pos-column>
                  <pos-form-field label="Description">
                    <textarea posInput class="form-control h-200" maxlength="120" name="menuDisplayDescription"
                      #menuDisplayDescription="ngModel" [(ngModel)]="salesProduct.MenuDisplayDescription"
                      rows="4"></textarea>
                  </pos-form-field>
                </pos-column>

                <pos-column>
                  <pos-form-field label="Image">
                    <div class="row">
                      <div class="col-9">
                        <input posInput type="file" class="form-control" accept=".png,.jpg,.jpeg,.bmp" name="image"
                          id="image" (change)="handleFileInput($event.target.files)" ngModel #image />
                      </div>
                      <div class="col-md-1 col-3 p-0 col-50 mt-pix-n1" [hidden]="!salesProduct.Image">
                        <img class="icon" src="{{imagePath}}/{{salesProduct.Image}}" name="imagePreview" #imagePreview
                          id="imagePreview" />
                        <div class="relative float-right" (click)="removeIcon()">
                          <pos-icon [icon]="icons.times" class="pointer ms-pix-n7 remove-icon-badge"></pos-icon>
                        </div>
                      </div>
                    </div>
                  </pos-form-field>
                </pos-column>
              </pos-row>
            </div>
          </div>
          <div [hidden]="selectedTab != tabList.Barcodes">
            <div [hidden]="selectedTab != tabList.Barcodes">
              <div class="pos-tab-body border-top-0 tab-top-border pt-pix-5"
                [ngStyle]="{'max-height': innerContainerHeight}">
                <div *ngIf="!salesProductId" class="alert condensed inherit-position alert-warning mt-pix-10">
                  {{saveButtonPressToProceed}}
                </div>
                <div *ngIf="salesProductId > 0">
                  <pos-sales-product-barcodes [salesProductId]="salesProductId" [salesProductSizes]="salesProductPrices"></pos-sales-product-barcodes>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </context-provider>
    <div class="col-12 action-button-container"
      [ngClass]="{'popup-footer': isScroll, 'page-footer': !isScroll}">
      <button class="btn-lg btn-success btn btn-save pos-button" type="submit" value="Apply" (click)="isSaveAndContinue = true"
        *ngIf="!isScroll && !salesProductId"> Apply </button>
      <button class="btn-lg btn-primary btn btn-save pos-button" type="submit" value="Save"> Save </button>
      <button class="btn-secondary btn-lg btn pos-button" [ngClass]="{'btn-cancel-modal': isScroll, 'btn-cancel':!isScroll}"
        (click)="onCancel(false)" type="button" value="Cancel">
        Cancel </button>
    </div>
    <div class="clearfix"></div>
    <div class="clearfix"></div>
    <div class="clearfix"></div>
  </form>
</pos-edit-wrapper>
