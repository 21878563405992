import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { forEach, orderBy, remove } from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable, finalize, forkJoin } from 'rxjs';
import { ButtonFunctionParametersModel, DomainConstants, InventoryProductDetails } from 'src/app';
import { OrderNavigationButton } from 'src/app/orders/interface';
import { DeductInventoryProduct } from 'src/app/orders/interface/deduct-inventory-product';
import { ModalFormComponent, ModalService, SpinnerService } from 'src/app/shared/components';
import { AlertsService, ButtonService, FormUtilityService, InventoryProductService } from 'src/app/shared/services';
import Pluralize from 'pluralize';
import { NgSelectComponent } from '@ng-select/ng-select';
import { DeleteColumn, GridColumn, TemplateColumn, TextAlign } from '@tarktech/tark-ng-utils';

@Component({
  selector: 'pos-deduct-inventory-product',
  templateUrl: './deduct-inventory-product.component.html'
})
export class DeductInventoryProductComponent extends ModalFormComponent implements OnInit {
  @ViewChild('deductInventoryProductForm', { static: true }) form: NgForm;
  
  @ViewChild('unitQtyTemplate', { static: true }) unitQtyTemplate: TemplateRef<any>;
  @ViewChild('inventoryProductSelection') inventoryProductSelection: NgSelectComponent;
  @Input() button: OrderNavigationButton = null;
  @Input() additionalInventories: Array<DeductInventoryProduct> = []
  selectedInventories: Array<DeductInventoryProduct> = []
  inventoryColumns: Array<GridColumn> = [];

  inventoryProducts: Array<InventoryProductDetails> = [];
  selectedProduct: InventoryProductDetails;
  buttonFunctionParameters: Array<ButtonFunctionParametersModel> = [];
  inventoryProductToDeduct: DeductInventoryProduct = { InventoryProductId: null, Qty: null, ScreenChoiceId: null, InventoryProductName: '', Unit: '' };
  selectedUnit: string = null;
  Pluralize = Pluralize;
  doNotAllowOverridingQty = false;
  
  availableQty = 9999;
  defaultConsumptionQty = 1;
  get getForm(): NgForm {
    return this.form;
  }
  constructor(modalRef: BsModalRef,
    private alertService: AlertsService,
    private spinnerService: SpinnerService,
    private buttonService: ButtonService,
    private inventoryProductService: InventoryProductService,
    modalService: ModalService,
    formUtilityService: FormUtilityService) {
    super(modalRef, modalService, formUtilityService);
  }

  ngOnInit(): void {
    this.isDetectUnsavedChanges = false;
    this.loadDependencies();
    const deleteColumn = new DeleteColumn({
      Clicked: (data) => {
        this.removeInventoryProduct(data);
      },
      Width: '83px'
    });
    const unitQtyColumn = new TemplateColumn({
      HeaderText: 'Qty',
      itemTemplate: this.unitQtyTemplate,
      TextAlign: TextAlign.Right,
      Width: '110px'
    });
    this.inventoryColumns = [
      new GridColumn({ HeaderText: 'Product', Field: 'InventoryProductName', IsSortable: false }),
      unitQtyColumn,
      deleteColumn
    ];
  }
  removeInventoryProduct(data) {
    remove(this.selectedInventories, x => x.InventoryProductId == data.InventoryProductId);
    remove(this.additionalInventories, x => x.InventoryProductId == data.InventoryProductId);
  }

  loadDependencies() {
    this.spinnerService.show();
    const observables: Array<Observable<any>> = [];
    observables.push(this.inventoryProductService.getInventoryProductsForButtonFunction());
    if (this.button) {
      observables.push(this.buttonService.getButtonFunctionParameters(this.button.Id))
    }
    forkJoin(observables)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: ([inventoryProducts, functionParameters]: [Array<InventoryProductDetails>, Array<ButtonFunctionParametersModel>]) => {
          this.inventoryProducts = inventoryProducts;
          
          this.selectedInventories = this.additionalInventories
            .filter(i => i.ScreenChoiceId == this.button.ScreenChoiceId)
            .map(i => {
              const selectedProduct = this.inventoryProducts.find(x => x.Id == i.InventoryProductId);
              const qty = this.convertQtyToMappedQty(i.Qty, selectedProduct);
              const unit = Pluralize(selectedProduct?.DirectInventorySellInstructionMappedUnit ?? i.Unit, qty);
              return {
                ...i,
                Qty: qty,
                Unit: unit,
              };
            });

          if (this.button) {
            this.buttonFunctionParameters = functionParameters;
            const inventorySubcategoriesParam = this.buttonFunctionParameters.find(x => x.Key == DomainConstants.ButtonFunctionTypes.DEDUCT_INVENTORY_PRODUCT.InventorySubcategories);
            const inventorySubcategories = inventorySubcategoriesParam?.Value?.split(',').map(x => parseInt(x, 10));

            const doNotAllowOverridingQtyParam = this.buttonFunctionParameters.find(x => x.Key == DomainConstants.ButtonFunctionTypes.DEDUCT_INVENTORY_PRODUCT.DoNotAllowOverridingQty);
            this.doNotAllowOverridingQty = doNotAllowOverridingQtyParam?.Value == "true";
            if (inventorySubcategories?.length) {
              this.inventoryProducts = orderBy(this.inventoryProducts.filter(x => inventorySubcategories.includes(x.InventorySubcategoryId)), 'Name');
            }
          }
          forEach(this.inventoryProducts, x => {
            if (!(x.CalculatedQty > 0 || x.IsAllowOutOfStockSales)) {
              x.disabled = true;
              x.Name += " <em class='font-12'>(out of stock)</em>";
            }
          });
          this.inventoryProductToDeduct.ScreenChoiceId = this.button ? this.button.ScreenChoiceId : null;
          this.inventoryProductSelection.searchInput.nativeElement.focus();
        }, error: this.alertService.showApiError
      });
  }

  addDeductInventoryProduct(isValid: boolean) {
    this.inventoryProductToDeduct.InventoryProductName = this.inventoryProductSelection?.selectedValues[0]?.Name;

    this.inventoryProductToDeduct.Qty = this.convertToOriginalQty(this.inventoryProductToDeduct.Qty, this.selectedProduct);
    this.selectedUnit = this.selectedProduct.Unit?.Name
    this.availableQty = this.convertToOriginalQty(this.availableQty, this.selectedProduct);
    this.defaultConsumptionQty = this.convertToOriginalQty(this.defaultConsumptionQty, this.selectedProduct);

    this.inventoryProductToDeduct.Unit = Pluralize(this.selectedUnit, this.inventoryProductToDeduct?.Qty);
    if (!isValid) {
      return;
    }
    this.hide({ InventoryProductToDeduct: this.inventoryProductToDeduct });
  }

  changeInventoryProduct() {
    this.selectedProduct = this.inventoryProducts.find(x => x.Id == this.inventoryProductToDeduct.InventoryProductId);
    this.availableQty = 9999;

    const qty = this.convertQtyToMappedQty(this.selectedProduct.DefaultConsumptionQty ?? 0, this.selectedProduct);
    const availableQty = this.convertQtyToMappedQty(this.selectedProduct.CalculatedQty, this.selectedProduct);
    this.defaultConsumptionQty = this.convertQtyToMappedQty(this.selectedProduct.DefaultConsumptionQty, this.selectedProduct);

    this.inventoryProductToDeduct.Qty = null;
    if (this.selectedProduct) {
      this.inventoryProductToDeduct.Qty = qty ?? null;
      this.selectedUnit = this.selectedProduct.DirectInventorySellInstructionMappedUnit ?? this.selectedProduct.Unit?.Name;
      this.availableQty = availableQty;
      this.doNotAllowOverridingQty = this.doNotAllowOverridingQty && !!this.inventoryProductToDeduct.Qty;
    }
  }

  convertQtyToMappedQty(qty: number, product: InventoryProductDetails) {
    if (product?.DirectInventorySellInstructionBaseQty) {
      return this.customRound((qty / product.DirectInventorySellInstructionBaseQty) * product.DirectInventorySellInstructionMappedQty)
    }
    return qty;
  }

  convertToOriginalQty(mappedQty: number, product: InventoryProductDetails) {
    if (product?.DirectInventorySellInstructionBaseQty) {
      return (mappedQty * product.DirectInventorySellInstructionBaseQty) / product.DirectInventorySellInstructionMappedQty;
    }
    return mappedQty;
  }

  customRound(value: number): number {
    return Math.round(value * 10000) / 10000;
  }

  cancel() {
    this.hide({});
  }

}
