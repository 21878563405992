import { SettingParam } from '../../../shared/interface/setting-param';
import { DomainConstants } from 'src/app/shared/constants/domain-constants';
import { comments } from '../../../shared/components/icon/icons';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { RabbitMQService, ApplicationStateService, AlertsService, UserDetails } from 'src/app/shared';
import * as _ from 'lodash';
import { InboxMessageService } from '../../services';
import { InboxMessagesEditComponent } from '../inbox-messages-edit';
import { ViewMessageComponent } from '../view-message';
import { OrderEventBroadcastingService } from 'src/app/shared/services/order-event-broadcasting.service';
import { ModalService } from 'src/app/shared/components/modal';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';
import { SlicePipe } from '@angular/common';
declare let $: any;
@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'pos-inbox-message-popover',
    templateUrl: './inbox-message-popover.component.html',
    styleUrls: ['./inbox-message-popover.component.scss']
})
export class InboxMessagePopoverComponent implements OnInit, OnDestroy {
    terminalMessageSubscription: Subscription;
    messageMarkAsReadSubscription: Subscription;
    userDetails: UserDetails;
    terminalType: string;
    isShowAll = false;
    isReadAllMessages = false;

    settingParam: SettingParam;
    isOrderEntryTerminal: boolean = false;
    constructor(private rabbitMQService: RabbitMQService,
        private inboxMessageService: InboxMessageService,
        private alertService: AlertsService,
        private modalService: ModalService,
        private slicePipe: SlicePipe,
        private eventBroadcastingService: OrderEventBroadcastingService,
        private applicationStateService: ApplicationStateService) {

        this.subscribeInboxMessages();
        this.terminalType = this.applicationStateService.terminalType;
        this.settingParam = this.applicationStateService.settingParam;
        if (this.terminalType == DomainConstants.TerminalTypes.ORDER_ENTRY.Name) {
            this.isOrderEntryTerminal = true;
        }
    }
    public icons = {
        comments
    };
    ngOnInit() {

    }
    ngOnDestroy() {
    }
    dismissAll() {
        this.isShowAll = false;
        this.inboxMessages = [];
    }

    inboxMessages: any = [];
    currentTimeOut: any = [];

    subscribeInboxMessages = () => {
        this.userDetails = this.applicationStateService.userDetails;
        const self = this;

        this.terminalMessageSubscription = this.rabbitMQService.subscribeToTerminalInboxMessage$()
            .subscribe({
                next: (message: any) => {
                    // Message notification when unread messages in inbox.
                    const objMessage = message.Payload.MessageInfo;
                    if ((objMessage.RecipientUserId == self.userDetails.id) ||
                        (objMessage.RecipientTerminalId == self.applicationStateService.terminalId)) {
                        const currentDate = new Date();
                        const mailMessage = $.extend({}, objMessage);

                        mailMessage.ReceivedOn = currentDate;
                        mailMessage.Viewed = false;
                        this.isReadAllMessages = false;
                        let unreadPopOutTimeOut;
                        self.inboxMessages.splice(0, 0, mailMessage);
                        if (self.terminalType !== DomainConstants.TerminalTypes.MAKE_TABLE.Name) {
                            if (self.inboxMessages.length <= self.settingParam.MaxUnreadMessagePopoutCount) {
                                if (self.settingParam.AutoHidePopoutMessageInterval > 0) {
                                    self.currentTimeOut.push(setTimeout(() => {
                                        self.inboxMessages.splice(0, 1);
                                    }, (self.settingParam.AutoHidePopoutMessageInterval * 1000)));
                                }
                            } else {
                                if (self.settingParam.AutoHidePopoutMessageInterval > 0) {
                                    self.currentTimeOut.forEach(time => {
                                        clearTimeout(time);
                                    });
                                    self.currentTimeOut = [];
                                    if (unreadPopOutTimeOut) {
                                        clearTimeout(unreadPopOutTimeOut);
                                    }

                                    unreadPopOutTimeOut = setTimeout(() => {
                                        self.inboxMessages = [];
                                    }, (self.settingParam.AutoHidePopoutMessageInterval * 1000));
                                }
                            }
                            self.eventBroadcastingService.inboxMessage.next(true);
                        }
                    }
                }, error: () => {
                    console.log("Error occurred while subscribing for terminal messages.");
                }
            });

        this.messageMarkAsReadSubscription = this.rabbitMQService.subscribeToTerminalMessageMarkAsReadMessage$()
            .subscribe({
                next: (message: any) => {
                    const objMessage = message.Payload.MessageInfo;
                    const mailMessage = $.extend({}, objMessage);
                    const messageIndex = _.findIndex(self.inboxMessages, (msg: any) => {
                        return msg.Id == mailMessage.Id;
                    });
                    self.inboxMessages.splice(messageIndex, 1);
                }, error: () => {
                    console.log('error while connecting to RabbitMQ.');
                }
            });
    }


    sliceMessage(message) {
        if (message.MessageText.length > 40) {
            const messageArray = [...this.slicePipe.transform(message.MessageText, 0, 160)];
            const shortMessage = messageArray.splice(0, messageArray.length - 1);
            let finalMessage = '';
            shortMessage.forEach((data) => {
                finalMessage = finalMessage + data;
            });
            finalMessage = finalMessage + '...';
            return finalMessage;
        }
        return message?.MessageText;
    }

    // getMessages() {
    //     return _.filter(this.inboxMessages, (message) => {
    //         return !message.Marked;
    //     })
    // }

    showAll() {
        if (this.terminalType == DomainConstants.TerminalTypes.MAKE_TABLE.Name) {
            this.isShowAll = true;
        }
    }



    isDismissAllDisplay() {
        if (this.inboxMessages && this.inboxMessages.length > 1) {
            let markedMessages = _.filter(this.inboxMessages, (message) => {
                return !message.Marked;
            });
            if (markedMessages && markedMessages.length > 1) {
                return true;
            }
            return false;
        }
        this.isShowAll = false;
        return false;
    }
    markAsRead(message) {
        this.hideMessage(message);
        this.eventBroadcastingService.inboxMessage.next(false);
        const terminalMessageModel = {
            SenderId: '',
            RecipientTerminalId: '',
            RecipientUserId: '',
            MessageText: '',
            FromEmailId: '',
            RecipientEmailId: '',
            FromUserId: 0,
            FromTerminalId: 0,
            Id: 0
        };
        terminalMessageModel.FromUserId = this.applicationStateService.userDetails.id;
        terminalMessageModel.FromTerminalId = this.applicationStateService.terminalId;
        terminalMessageModel.Id = message.Id;
        this.inboxMessageService.markAsRead(terminalMessageModel).subscribe(
            {
                next: res => {
                }, error: this.alertService.showApiError
            });
    }

    hideMessage(message) {
        var messageIndex = _.findIndex(this.inboxMessages, (inboxMessage: any) => {
            return message.Id === inboxMessage.Id;
        });
        this.inboxMessages[messageIndex].Marked = true;
        this.isReadAllMessages = this.inboxMessages.filter(x => !x.Marked).length ? false : true;
    }

    viewMessage(message) {
        this.hideMessage(message);
        const modalRef = this.modalService.getModalWrapper(ViewMessageComponent);
        modalRef.show({
            animated: false,
            class: 'vertical-center modal-lg',
            initialState: {
                terminalMessageModel: message,
                isRequiredMarkAsRead: false
            }
        });
    }

    replyMessage(data: any) {
        this.hideMessage(data);
        this.modalService.show(InboxMessagesEditComponent, {
            animated: false,
            keyboard: true,
            backdrop: true,
            ignoreBackdropClick: false,
            class: 'vertical-center modal-lg',
            initialState: {
                inboxMessage: data
            }
        });
    }
}
