import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SalesTax } from '../../configurator/taxes/interface/sales-tax';
import { Observable } from 'rxjs';
import { RuntimeConstants } from '../constants/runtime-constants';
import { BaseCrudService } from './base-crud.service';

@Injectable()
export class SalesTaxService extends BaseCrudService<SalesTax> {

  salesTaxApiPath = `${RuntimeConstants.API_PATH}salesTaxes`;

  constructor(private httpClient: HttpClient) {
    super('salesTaxes', httpClient);
  }

  newSalesTax() {
    const salesTax: SalesTax = {
      Id: 0,
      Name: '',
      Percent: 0,
      IsDefault: false
    };
    return salesTax;
  }

  getAllData() {
    return this.httpClient.get(`${this.salesTaxApiPath}/getData`);
  }

  saveTaxes(salesTaxes: SalesTax[], taxToGo: {}, discountTaxes: {}, taxRoundingMode: {}): Observable<any> {
    const params = { SalesTaxes: salesTaxes, TaxToGo: taxToGo, DiscountTaxes: discountTaxes, TaxRoundingMode: taxRoundingMode };
    return this.httpClient.post(`${this.salesTaxApiPath}`, params);
  }

}

