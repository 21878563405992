<pos-edit-wrapper (close)="onCancel(false)" heading="Import Work Schedule">
    <form autocomplete="off" #importWorkScheduleForm="ngForm">
        <div class="col-12 pos-modal-body form-horizontal screen-scroll">
            <pos-row>
                <pos-column>
                    <pos-form-field label="File"
                        [inputStyle]="{'col-sm-10 col-xs-12': true, 'has-error': (excelError.isError)}">
                        <input posInput type="file" class="form-control" accept=".ods,.xls,.xlsx,.csv"
                            name="workScheduleFile" ngModel id="workScheduleFile" #workScheduleFile
                            (change)="onImportWorkSchedule($event)" />
                        <span *ngIf="excelError.isError">
                            <label class="control-label align-left">{{excelError.errorMessage}}</label>
                        </span>
                    </pos-form-field>
                </pos-column>
                <pos-column class="pt-pix-10">
                    <div class="text-success"><pos-icon [icon]="icons.fieldInfoCircle" class="pe-pix-4"></pos-icon>
                        <span class="vertical-mid">{{workScheduleMessage}}</span></div>
                </pos-column>
            </pos-row>
        </div>
        <div class="clearfix"></div>
        <div class="col-12 action-button-container popup-footer">
            <button *ngIf="excelError.isConflicted" type="submit" class="btn-warning btn-lg white btn btn-save pos-button"
                (click)="import(true, false)">
                <pos-icon [icon]="icons.forwardFad" class="font-0-5em"></pos-icon> Skip </button>
            <button *ngIf="excelError.isConflicted" type="submit" class="btn-info btn-lg white btn btn-save pos-button"
                (click)="import(false, true)">
                <pos-icon [icon]="icons.fileImport" class="font-0-5em"></pos-icon> Overwrite </button>
            <button *ngIf="!excelError.isConflicted" type="submit" class="btn-primary btn-lg btn btn-save pos-button"
                (click)="import()">Import </button>
            <button type="button" class="btn-secondary  btn-lg btn btn-cancel-modal pos-button"
                (click)="onCancel(false)">Cancel</button>
        </div>
    </form>
</pos-edit-wrapper>
