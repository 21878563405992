<pos-edit-wrapper [isPopup]="true" heading="{{id > 0 ? 'Edit Button: ' + buttonName: 'Add Button'}}"
  (close)="cancelEditing()" class="overflow-hidden display-contents form-button-edit">
  <form #formButton="ngForm" (ngSubmit)='submit(formButton.valid)' class="edit-form" autocomplete="off">
    <context-provider provide="props">
      <div class="col-12 container-fluid">
        <div class="vertical-tabs">
          <ul class=" nav nav-tabs inner-tab pos-nav-tabs list">
            <li [ngClass]="{'active': selectedTab == tabList.Details }">
              <a (click)="selectedTab = tabList.Details" [ngClass]="{'required':!formGroups.Details.$valid}">
                <span class="pe-pix-5">
                  <pos-icon class="theme-separator" [icon]="icons.infoCircle"></pos-icon>
                </span>
                <span>Details</span>
              </a>
            </li>
            <li [ngClass]="{'active': selectedTab == tabList.Design }">
              <a (click)="selectedTab = tabList.Design">
                <span class="pe-pix-5">
                  <pos-icon class="theme-separator" [icon]="icons.th"></pos-icon>
                </span> <span>Design</span>
              </a>
            </li>
            <li [ngClass]="{'active': selectedTab == tabList.Scheduling }">
              <a (click)="selectedTab = tabList.Scheduling" [ngClass]="{'required':!formGroups.Scheduling.$valid}">
                <span class="pe-pix-5">
                  <pos-icon class="theme-separator" [icon]="icons.calendar"></pos-icon>
                </span> <span>Scheduling</span>
              </a>
            </li>
            <li [ngClass]="{'active': selectedTab == tabList.Behaviors }">
              <a (click)="selectedTab = tabList.Behaviors">
                <span class="pe-pix-5">
                  <pos-icon class="theme-separator" [icon]="icons.box"></pos-icon>
                </span> <span>Behaviors</span>
              </a>
            </li>
          </ul>
        </div>
        <div class="row g-0 tab-content overflow-auto-tabs" [ngStyle]="{'height': getHeight() + 'px'}">
          <div [hidden]="selectedTab != tabList.Details" [ngStyle]="{'height': getHeight() + 'px'}"
            class="col-12 pos-tab-body tab-top-border screen-scroll pt-pix-5">
            <div class="col-12 p-0 pt-pix-10">
              <legend class="reset-style bold">
                <span class="bold"> Text </span>
              </legend>
              <pos-row class="g-0 ps-3">
                <pos-column>
                  <pos-form-field label="Button Text"
                    [validationMessages]="{required: 'Please enter button text or assign an image to this button.'}"
                    inputStyle="col-12">
                    <pos-row>
                      <pos-column sm="6" xs="10" class="pe-0">
                        <input posInput type="text" name="buttonSelection" #ButtonSelection="ngModel"
                          class="form-control" [(ngModel)]="button.Selection" (blur)="copyButtonText(false)"
                          [required]="selectedFunction != buttonFunctionType.PLACEHOLDER.Code && !showIcon"
                          [posValidationGroup]="formGroups.Details" id="buttonSelection" [isIgnoreModel]="true"
                          [exValidation]="!(selectedFunction != buttonFunctionType.PLACEHOLDER.Code && !showIcon && !button.Selection)" />
                      </pos-column>
                      <pos-column xs="2" class="ps-3">
                        <button type="button" class="btn-secondary  btn normal pos-button"
                          (click)="copyButtonText(true)">Copy</button>
                      </pos-column>
                    </pos-row>
                  </pos-form-field>
                </pos-column>

                <pos-column>
                  <pos-form-field label="Receipt Text">
                    <input posInput type="text" name="buttonReceiptText" #ButtonReceiptText="ngModel"
                      class="form-control" [(ngModel)]="button.ReceiptText" />
                  </pos-form-field>
                </pos-column>

                <pos-column>
                  <pos-form-field label="Kitchen Text">
                    <input posInput type="text" name="buttonKitchen" #ButtonKitchen="ngModel" class="form-control"
                      [(ngModel)]="button.InternalText" />
                  </pos-form-field>
                </pos-column>
              </pos-row>
            </div>
            <div class="row g-0 section-border">
              <div class="col-lg-7 col-12 ps-0 pt-pix-10">
                <legend class="reset-style bold">
                  <span class="bold"> Configuration </span>
                </legend>
                <pos-row class="ps-3 g-0">
                  <pos-column>
                    <pos-form-field inputStyle="row g-0 ps-pix-10">
                      <div class="col-md-3 col-sm-4 col-12">
                        <label class="remove-padding">Type</label>
                      </div>
                      <div class="col-md-6 col-sm-5 col-10"
                        [ngClass]="{'has-error': formButton.submitted && !button.ButtonType}">
                        <div class="col-12 p-0 line-height"
                          *ngFor="let i=index; let item of button.ButtonTypes | keyvalue: originalOrder">
                          <label class="remove-padding">
                            <input type="radio" class="chk-section__input btn-chk-box" name="chkButtonType"
                              value="{{item.key}}" [(ngModel)]="button.ButtonType" required
                              (change)="changeButtonType(button.ButtonType)" />
                            {{buttonTypes[i]}}</label>
                        </div>
                        <span *ngIf="formButton.submitted && !button.ButtonType">
                          <label class="control-label">Please select button type.</label>
                        </span>
                      </div>
                    </pos-form-field>
                  </pos-column>

                  <pos-column class="pt-pix-5">
                    <pos-form-field label="Function" [appendPaddingTop]="false"
                      inputStyle="col-md-8 col-sm-10 col-12">
                      <ng-select posInput appendTo="body" [items]="buttonFunctions" bindLabel="name" bindValue="code"
                        placeholder="Select function" [(ngModel)]="selectedFunction" (change)="changeSpecialFunction()"
                        [disabled]="button.ButtonType == 'NavigationOnly'" name="selectedFunction">
                      </ng-select>
                    </pos-form-field>
                  </pos-column>
                </pos-row>
                <pos-row *ngIf="hasFunctionParameters && button.ButtonType == 'Functional'" class="ps-2">
                  <pos-column>
                    <fieldset class="fieldset">
                      <legend class="reset-style"><span class="px-pix-5">Function Parameters</span></legend>
                      <div class="px-2">
                        <pos-column>
                          <pos-form-field *ngIf="selectedFunction == buttonFunctionType.NUMERIC_MARKUP.Code"
                            label="Taxable" [appendPaddingTop]="false">
                            <div class="btn-group">
                              <label class="btn btn-radio"
                                [ngClass]="{'active btn-success normal': functionParameter.IsTaxable == 'Taxable', 'btn-secondary': functionParameter.IsTaxable != 'Taxable'}">
                                <input posInput type="radio" name="taxable" class="form-check-input"
                                  [(ngModel)]="functionParameter.IsTaxable" value="Taxable">Taxable</label>
                              <label class="btn btn-radio"
                                [ngClass]="{'active btn-primary normal': functionParameter.IsTaxable == 'NonTaxable' || !functionParameter.IsTaxable,
                                            'btn-secondary': !(functionParameter.IsTaxable == 'NonTaxable' || !functionParameter.IsTaxable)}">
                                <input type="radio" name="taxable" class="form-check-input"
                                  [(ngModel)]="functionParameter.IsTaxable" value="NonTaxable">Non Taxable</label>
                            </div>
                          </pos-form-field>
                        </pos-column>

                        <pos-column>
                          <pos-form-field label="Size" *ngIf="selectedFunction == buttonFunctionType.SET_SIZE.Code"
                            [validationMessages]="{required: 'Please select size.'}" inputStyle="col-md-6 col-9"
                            [appendPaddingTop]="false">
                            <ng-select posInput appendTo="body" [items]="salesSizes" bindLabel="Name" bindValue="Id"
                              placeholder="Select size" [(ngModel)]="functionParameter.Size" name="selectedSize"
                              [required]="true">
                            </ng-select>
                          </pos-form-field>
                        </pos-column>

                        <pos-column>
                          <pos-form-field label="Restrictions"
                            *ngIf="selectedFunction == buttonFunctionType.SET_DIETARY_RESTRICTION.Code"
                            [validationMessages]="{required: 'Please select dietary restriction.'}"
                            [appendPaddingTop]="false" inputStyle="col-md-6 col-9">
                            <ng-select posInput appendTo="body" [items]="dietaryWarnings" bindLabel="Name"
                              bindValue="Id" placeholder="Select dietary restriction"
                              [(ngModel)]="functionParameter.DietaryWarning" name="selectedRestrictions" posInput
                              [required]="true">
                            </ng-select>
                          </pos-form-field>
                        </pos-column>

                        <pos-column>
                          <pos-form-field label="Comment"
                            *ngIf="selectedFunction == buttonFunctionType.ADD_COMMENT_TO_ALL.Code"
                            [appendPaddingTop]="false" [validationMessages]="{required: 'Please add comment text.'}"
                            inputStyle="col-md-12 col-12">
                            <input posInput type="text" name="addCommentToAll" #addCommentToAll="ngModel"
                              class="form-control" [(ngModel)]="functionParameter.AddCommentToAll" posInput
                              [required]="true" />
                          </pos-form-field>
                        </pos-column>

                        <pos-column>
                          <pos-form-field label="Comment"
                            *ngIf="selectedFunction == buttonFunctionType.COMMENT_ENTRY.Code" [appendPaddingTop]="false"
                            inputStyle="col-12">
                            <input posInput type="text" name="commentEntry" #commentEntry="ngModel" class="form-control"
                              [(ngModel)]="functionParameter.CommentEntry" posInput />
                          </pos-form-field>
                        </pos-column>

                        <pos-column>
                          <pos-form-field *ngIf="selectedFunction == buttonFunctionType.PERCENT_DISCOUNT.Code ||
                      selectedFunction == buttonFunctionType.PERCENT_MARKUP.Code ||
                      selectedFunction == buttonFunctionType.NUMERIC_MARKUP.Code" label="Type"
                            [appendPaddingTop]="selectedFunction == buttonFunctionType.NUMERIC_MARKUP.Code">
                            <div class="btn-group">
                              <label class="btn btn-radio" [ngClass]="{'active btn-success normal': functionParameter.Type == 'Auto',
                                            'btn-secondary': functionParameter.Type != 'Auto'}">
                                <input posInput type="radio" name="typeAuto" class="form-check-input"
                                  [(ngModel)]="functionParameter.Type" value="Auto">Auto</label>
                              <label class="btn btn-secondary btn-radio"
                                [ngClass]="{'active btn-primary normal': functionParameter.Type == 'Manual' || !functionParameter.Type,
                                            'btn-secondary': !(functionParameter.Type == 'Manual' || !functionParameter.Type)}">
                                <input type="radio" name="typeAuto" class="form-check-input"
                                  [(ngModel)]="functionParameter.Type" value="Manual">Manual</label>
                            </div>
                          </pos-form-field>
                        </pos-column>

                        <pos-column>
                          <pos-form-field [label]="manualValueForSpecialFunction" [appendPaddingTop]="functionParameter.Type == 'Auto'"
                            *ngIf="selectedFunction == buttonFunctionType.GRATUITY_MARKUP_AUTO.Code || functionParameter.Type == 'Auto'"
                            [validationMessages]="{required: requiredManualValueForSpecialFunction, range: 'Please enter value between 0 to 100.'}"
                            inputStyle="col-lg-7 col-md-6 col-9">
                            <p class="input-group col-150">
                              <span class="input-group-addon"
                                *ngIf="selectedFunction == buttonFunctionType.NUMERIC_MARKUP.Code">{{currencySymbol}}</span>
                              <input posInput type="number" min="0" max="100" name="percent" #Percent="ngModel"
                                class="form-control" [(ngModel)]="functionParameter.Percent" [required]="true"
                                [range]="[0, 100]" />
                              <span class="input-group-addon" *ngIf="selectedFunction == buttonFunctionType.PERCENT_MARKUP.Code
                        || selectedFunction == buttonFunctionType.GRATUITY_MARKUP_AUTO.Code
                        || selectedFunction == buttonFunctionType.PERCENT_DISCOUNT.Code">
                                %</span>
                            </p>
                          </pos-form-field>
                        </pos-column>

                        <pos-column>
                          <pos-form-field label="Discount"
                            *ngIf="selectedFunction == buttonFunctionType.GRATUITY_MARKUP_AUTO.Code"
                            inputStyle="col-md-6 col-9">
                            <input type="number" name="percent" #Percent="ngModel" class="form-control col-90"
                              [(ngModel)]="functionParameter.PercentDiscount" />
                          </pos-form-field>
                        </pos-column>

                        <pos-column>
                          <pos-form-field label="API" [appendPaddingTop]="false"
                            *ngIf="selectedFunction == buttonFunctionType.CALL_API.Code && isLoadCallApi"
                            inputStyle="col-md-6 col-9">
                            <pos-external-api-view (externalApiChange)="externalApiChange($event)"
                              [selectedExternalApi]="selectedExternalApi"
                              [selectedParameters]="selectedExternalApiParameters"
                              (externalApiParamChange)="externalApiParameterChange($event)"></pos-external-api-view>
                          </pos-form-field>
                        </pos-column>

                        <pos-column>
                          <pos-form-field label="Tax Type" *ngIf="selectedFunction == buttonFunctionType.NUMERIC_DISCOUNT_MANUAL.Code ||
                            selectedFunction == buttonFunctionType.PERCENT_DISCOUNT.Code"
                            inputStyle="col-md-6 col-9"
                            [appendPaddingTop]="selectedFunction == buttonFunctionType.PERCENT_DISCOUNT.Code">
                            <ng-select posInput appendTo="body" [items]="taxTypes" bindLabel="Name" bindValue="Value"
                              placeholder="Select tax type" [(ngModel)]="functionParameter.TaxType" name="selectedType">
                            </ng-select>
                          </pos-form-field>
                        </pos-column>

                        <pos-column>
                          <pos-form-field [appendPaddingTop]="false"
                            *ngIf="selectedFunction == buttonFunctionType.DEDUCT_INVENTORY_PRODUCT.Code"
                            inputStyle="col-md-10 col-12">
                            <p-checkbox posInput name="doNotAllowOverridingQty" [(ngModel)]="functionParameter.DoNotAllowOverridingQty"
                              binary="true" (onChange)="toggleButtonChanged()" label="Do not allow overriding qty">
                            </p-checkbox>
                          </pos-form-field>
                        </pos-column>

                        <pos-column>
                          <pos-form-field label="Inventory Subcategories"
                            *ngIf="selectedFunction == buttonFunctionType.DEDUCT_INVENTORY_PRODUCT.Code"
                            inputStyle="col-md-10 col-12">
                            <ng-select posInput appendTo="body" [items]="inventorySubcategories"
                              bindLabel="InventoryCategoryName" bindValue="id"
                              placeholder="Select inventory subcategory"
                              [(ngModel)]="functionParameter.InventorySubcategories" name="subcategories" posInput
                              [multiple]="true">
                            </ng-select>
                          </pos-form-field>
                        </pos-column>

                        <pos-column>
                          <pos-form-field label="Min Price"
                            *ngIf="selectedFunction == buttonFunctionType.DEDUCT_INVENTORY_PRODUCT.Code">
                            <input type="number" name="minPrice" #minPrice="ngModel" class="form-control col-120"
                              [(ngModel)]="functionParameter.MinPrice" />
                          </pos-form-field>
                        </pos-column>

                        <pos-column>
                          <pos-form-field label="Margin Percent"
                            *ngIf="selectedFunction == buttonFunctionType.DEDUCT_INVENTORY_PRODUCT.Code"
                            [validationMessages]="{ required: 'Please enter margin percent.', range: 'Please enter value between 0 to 100.'}">
                            <p class="input-group col-150">
                              <input posInput type="number" min="0" max="100" name="marginPercent"
                                #marginPercent="ngModel" class="form-control"
                                [(ngModel)]="functionParameter.MarginPercent" [range]="[0, 100]" [required]="true" />
                              <span class="input-group-addon">%</span>
                            </p>
                          </pos-form-field>
                        </pos-column>
                      </div>

                    </fieldset>
                  </pos-column>
                </pos-row>
                <pos-row class="g-0 ps-3">
                  <pos-column>
                    <pos-form-field label="Product" class="row" [inputStyle]="{'col-12 pe-0':true}"
                      [validationMessages]="{required: 'Please select sales product.'}">
                      <div class="col-12 row">
                        <div class="col-md-8 col-12">
                          <ng-select posInput [items]="salesProductList" bindLabel="Name" bindValue="id"
                            [disabled]="button.ButtonType == 'NavigationOnly' || button.ButtonType == 'Functional'"
                            dropdownPosition="top" placeholder="Select product" [(ngModel)]="selectedProducts"
                            name="selectedProduct" [required]="button.ButtonType == 'MainProduct'">
                          </ng-select>
                        </div>
                        <div class="col-md-4 col-12 pe-0 pt-pix-6 ps-10 ps-pix-md-0"
                          *ngIf="!(button.ButtonType == 'NavigationOnly' || button.ButtonType == 'Functional')">
                          <span class="pe-pix-5 pointer" (click)="editSalesProduct(0)" data-toggle="tooltip" title="Add"
                            data-placement="top"
                            permissions='[{"Name":"{{permission.salesProduct}}", "Level":"{{permission.editLevel}}"}]'>
                            <pos-action-icon [icon]="icons.plusWhite" iconText="Add"></pos-action-icon>
                          </span>
                          <span class="pointer" (click)="editSalesProduct(selectedProducts)" data-toggle="tooltip"
                            title="Edit" data-placement="top"
                            permissions='[{"Name":"{{permission.salesProduct}}", "Level":"{{permission.editLevel}}"}]'
                            [class.disabled]="!selectedProducts">
                            <pos-action-icon class="text-nowrap" [icon]="icons.editWhiteWithText" iconText="Edit">
                            </pos-action-icon>
                          </span>
                        </div>
                      </div>
                    </pos-form-field>
                  </pos-column>
                </pos-row>
              </div>
              <div class="col-lg-5 col-12 p-0 pt-pix-10">
                <legend class="reset-style bold">
                  <span class="bold"> Actions </span>
                </legend>
                <pos-row colXs="12" class="ps-3 g-0">
                  <pos-column class="pt-pix-10 ">
                    <pos-row>
                      <pos-column md="3" sm="4" xs="12">
                        <label class="remove-padding">Flow</label>
                      </pos-column>
                      <pos-column md="9" sm="8" xs="12" class="row">
                        <pos-form-field inputStyle="col-12" [appendPaddingTop]="false">
                          <p-checkbox posInput name="buttonToggle" #buttonToggle="ngModel" [(ngModel)]="button.Toggle"
                            binary="true" (onChange)="toggleButtonChanged()" label="Toggle">
                          </p-checkbox>
                        </pos-form-field>

                        <pos-form-field inputStyle="col-12 ps-pix-30" *ngIf="button.Toggle && screenId">
                          <p-checkbox posInput name="buttonIsDefaultChecked" #buttonIsDefaultChecked="ngModel"
                            [(ngModel)]="button.IsDefaultChecked" binary="true" label="Default Checked">
                          </p-checkbox>
                        </pos-form-field>

                        <pos-form-field inputStyle="col-12">
                          <p-checkbox posInput name="buttonManagerOnly" #buttonManagerOnly="ngModel"
                            [(ngModel)]="button.ManagerOnly" binary="true" label="Manager Only">
                          </p-checkbox>
                        </pos-form-field>
                      </pos-column>
                    </pos-row>
                  </pos-column>
                  <pos-column>
                    <pos-form-field class="col-12 p-0" label="Print to Kitchen/Show on Make Table"
                      [validationMessages]="{required: 'Please select kitchen printing.'}"
                      inputStyle="col-md-7 col-10">
                      <ng-select posInput appendTo="body" [items]="printToKitchenMode" bindLabel="name" bindValue="id"
                        placeholder="Select kitchen printing" [(ngModel)]="button.PrintToKitchen"
                        name="selectedKitchenPrinting" [required]="true">
                      </ng-select>
                    </pos-form-field>
                  </pos-column>
                </pos-row>
              </div>
            </div>
            <div class="row g-0 section-border pt-pix-10">
              <legend class="reset-style bold">
                <span class="bold"> CLI Settings </span>
              </legend>
              <pos-form-field label="Code Names" class="ps-3" inputStyle="col-md-7 col-10">
                <ng-select posInput class="custom-theme code-name" [items]="[]"
                  placeholder="{{codeNames.length ? '' : 'Add code name(s) '}}" [addTag]="true" name="tags"
                  [(ngModel)]='codeNames' #tags="ngModel" [multiple]="true" [hideSelected]="true" [isOpen]="false">
                </ng-select>
              </pos-form-field>
            </div>
            <div>
            </div>
          </div>

          <div [hidden]="selectedTab != tabList.Design" [ngStyle]="{'height': getHeight() + 'px'}"
            class="col-12 pos-tab-body tab-top-border screen-scroll pt-pix-5">
            <pos-row>
              <pos-column>
                <pos-form-field label="Button Color">
                  <pos-color-picker posInput [(ngModel)]="button.Color" name="buttonColor"></pos-color-picker>
                </pos-form-field>
              </pos-column>

              <pos-column>
                <pos-form-field label="Border Color">
                  <pos-color-picker posInput [(ngModel)]="button.BorderColor" name="buttonBorderColor">
                  </pos-color-picker>
                </pos-form-field>
              </pos-column>

              <pos-column *ngIf="screenId">
                <pos-form-field label="Background Color">
                  <pos-color-picker posInput [(ngModel)]="button.BackgroundColor" name="buttonBackgroundColor">
                  </pos-color-picker>
                </pos-form-field>
              </pos-column>

              <pos-column>
                <pos-form-field label="Text Color">
                  <pos-color-picker posInput [(ngModel)]="button.TextColor" name="buttonTextColor"></pos-color-picker>
                </pos-form-field>
              </pos-column>

              <pos-column>
                <pos-form-field label="Make Table Color">
                  <pos-color-picker posInput [(ngModel)]="button.MakeTableBgColor" name="buttonMakeTableBgColor">
                  </pos-color-picker>
                </pos-form-field>
              </pos-column>

              <pos-column>
                <pos-form-field label="Make Table Text Color">
                  <pos-color-picker posInput [(ngModel)]="button.MakeTableFgColor" name="buttonMakeTableFgColor">
                  </pos-color-picker>
                </pos-form-field>
              </pos-column>

              <pos-column>
                <pos-form-field label="Pick Screen Text" inputStyle="col-md-6 col-sm-5 col-10">
                  <input type="text" name="buttonPickScreenText" #ButtonPickScreenText="ngModel" class="form-control"
                    [(ngModel)]="button.PickScreenText" />
                </pos-form-field>
              </pos-column>

              <pos-column>
                <pos-form-field label="Pick Screen Text Color">
                  <pos-color-picker posInput [(ngModel)]="button.PickScreenTextBgColor"
                    name="buttonPickScreenTextBgColor" cpPosition="top"></pos-color-picker>
                </pos-form-field>
              </pos-column>

              <pos-column *ngIf="button.Toggle">
                <pos-form-field label="Toggle Color">
                  <pos-color-picker posInput [(ngModel)]="button.ToggleColor" name="ToggleColor"
                    cpPosition="top"></pos-color-picker>
                </pos-form-field>
              </pos-column>

              <pos-column>
                <pos-form-field label="Toggle Group" inputStyle="p-0 px-pix-10">
                  <pos-row class="g-0">
                    <pos-column md="3" xs="12" class="pe-3">
                      <input type="text" name="buttonGroupName" #buttonGroupName="ngModel" class="form-control"
                        [(ngModel)]="button.GroupName" [disabled]="!button.Toggle" />
                    </pos-column>
                    <pos-column md="2" xs="6" class="px-3 pt-pix-10 pt-pix-md-0">
                      <pos-form-field label="Min" labelStyle="col-4 pt-pix-5" inputStyle="col-8"
                        [appendPaddingTop]="false">
                        <input type="number" name="buttonGroupMin" #buttonGroupMin="ngModel" class="form-control"
                          [(ngModel)]="button.ButtonGroupMin" [disabled]="!button.GroupName" />
                      </pos-form-field>
                    </pos-column>
                    <pos-column md="2" xs="6" class="px-3 pt-pix-10 pt-pix-md-0">
                      <pos-form-field label="Max" labelStyle="col-4 pt-pix-5" inputStyle="col-8"
                        [appendPaddingTop]="false">
                        <input type="number" name="buttonGroupMax" #buttonGroupMax="ngModel" class="form-control"
                          [(ngModel)]="button.ButtonGroupMax" [disabled]="!button.GroupName" />
                      </pos-form-field>
                    </pos-column>
                  </pos-row>
                </pos-form-field>
              </pos-column>

              <pos-column>
                <pos-form-field label="Icon" inputStyle="col-12">
                  <pos-row>
                    <pos-column md="5" sm="8" xs="12">
                      <input type="file" class="form-control" accept=".png,.jpg,.jpeg,.gif,.bmp" #image name="image"
                        id="image" (change)="handleFileInput($event.target.files)">
                    </pos-column>
                    <pos-column md="3" sm="4" xs="6">
                      <pos-row class="p-0">
                        <pos-column md="3" sm="5" xs="8" class="text-right pe-0" [hidden]="!showIcon">
                          <img src="{{imagePath}}/{{button.Image}}" height="50px" #buttonImage id="buttonImage" />
                        </pos-column>
                        <pos-column sm="4" xs="2" class="mt-pix-n5" (click)="removeIcon()" [hidden]="!showIcon">
                          <pos-icon [icon]="icons.times" class="pointer remove-icon-badge"></pos-icon>
                        </pos-column>
                      </pos-row>
                    </pos-column>
                  </pos-row>
                </pos-form-field>
              </pos-column>
            </pos-row>
          </div>

          <div [hidden]="selectedTab != tabList.Scheduling" [ngStyle]="{'height': getHeight() + 'px'}"
            class="col-12 pos-tab-body tab-top-border screen-scroll pt-pix-5">
            <pos-row colXs="12">
              <pos-column>
                <pos-form-field inputStyle="col-12">
                  <p-checkbox posInput name="buttonDateCheckBox" #buttonDateCheckBox="ngModel"
                    [(ngModel)]="dateCheckBox" binary="true" label="Dates">
                  </p-checkbox>
                </pos-form-field>
              </pos-column>
              <pos-column class="ps-pix-30" *ngIf="dateCheckBox">
                <pos-row colLg="4" colSm="6" colXs="12">
                  <pos-column>
                    <pos-form-field inputStyle="col-12" [validationMessages]="{required: 'Please select from date.'}">
                      <p-calendar posInput #startDate="ngModel" [readonlyInput]="true" [dateFormat]="dateFormat"
                        [ngClass]="{'has-error': (startDate.invalid && formButton.submitted)}" [required]="dateCheckBox"
                        placeholder="From date" appendTo="body" inputStyleClass="form-control" [monthNavigator]=true
                        [showIcon]="true" name="startDate" [(ngModel)]="dateFrom"
                        [posValidationGroup]="formGroups.Scheduling" id="startDate"
                        [exValidation]="!(dateCheckBox && !dateFrom)" [minDate]="minDate">
                      </p-calendar>
                    </pos-form-field>
                  </pos-column>
                  <pos-column>
                    <pos-form-field inputStyle="col-12" [validationMessages]="{required: 'Please select to date.'}">
                      <p-calendar posInput #endDate="ngModel" [readonlyInput]="true" [dateFormat]="dateFormat"
                        [ngClass]="{'has-error': (endDate.invalid && formButton.submitted)}" [required]="dateCheckBox"
                        placeholder="To date" appendTo="body" inputStyleClass="form-control" [monthNavigator]=true
                        [showIcon]="true" name="endDate" [(ngModel)]="dateTo"
                        [posValidationGroup]="formGroups.Scheduling" id="endDate"
                        [exValidation]="!(dateCheckBox && !dateTo)" [minDate]="minDate">
                      </p-calendar>
                    </pos-form-field>
                  </pos-column>
                </pos-row>
              </pos-column>

              <pos-column>
                <pos-form-field inputStyle="col-12">
                  <p-checkbox posInput name="buttonTimeCheckBox" #buttonTimeCheckBox="ngModel"
                    [(ngModel)]="timeCheckBox" binary="true" label="Time">
                  </p-checkbox>
                </pos-form-field>
              </pos-column>

              <pos-column class="ps-pix-30" *ngIf="timeCheckBox">
                <pos-row colLg="4" colSm="6" colXs="12" class="p-0">
                  <pos-column>
                    <pos-form-field inputStyle="col-12" [validationMessages]="{required: 'Please enter start time.'}">
                      <input posInput type="time" placeholder="HH:mm:ss" class="form-control col-170"
                        name="buttonStartTime" #buttonStartTime="ngModel" [(ngModel)]="button.ScheduleStartTime"
                        [required]="timeCheckBox" invalid>
                    </pos-form-field>
                  </pos-column>
                  <pos-column>
                    <pos-form-field inputStyle="col-12" [validationMessages]="{required: 'Please enter end time.'}">
                      <input posInput type="time" placeholder="HH:mm:ss" class="form-control col-170"
                        name="buttonEndTime" #buttonEndTime="ngModel" [(ngModel)]="button.ScheduleEndTime"
                        [required]="timeCheckBox" invalid>
                    </pos-form-field>
                  </pos-column>
                </pos-row>
              </pos-column>

              <pos-column>
                <pos-form-field>
                  <p-checkbox posInput name="buttonDaysCheckBox" #buttonDaysCheckBox="ngModel"
                    [(ngModel)]="daysCheckBox" binary="true" label="Days">
                  </p-checkbox>
                </pos-form-field>
              </pos-column>

              <pos-column class="ps-pix-30">
                <pos-row colMd="1" colSm="4" colXs="12" class="g-0 p-0 pt-pix-10">
                  <pos-column *ngFor="let day of daysOfWeek">
                    <p-checkbox name="chk_{{day.Name}}" [disabled]="!daysCheckBox" #chk_{{day.Name}}="ngModel"
                      [(ngModel)]="day.IsChecked" binary="true" [label]="day.Name">
                    </p-checkbox>
                  </pos-column>
                </pos-row>
              </pos-column>
            </pos-row>
          </div>

          <div [hidden]="selectedTab != tabList.Behaviors" [ngStyle]="{'height': getHeight() + 'px'}"
            class="col-12 pos-tab-body tab-top-border screen-scroll pt-pix-5">
            <div class="pt-pix-10">
              <div *ngIf="!id" class="alert condensed inherit-position alert-warning">
                {{pressApplyToProceed}}</div>
              <div *ngIf="id">
                <ng-table [sort]="false" [paginator]="false" [columns]="behaviorColumns" [data]="buttonBehaviors"
                  [isReorderableTable]="true" [ReorderableColumnRow]="'row'" [search]="false"
                  (onRowReorder)="gridRowReorder($event)" [scrollable]="true" [scrollHeight]="getHeight() - 20 + 'px'">
                </ng-table>
              </div>
            </div>
          </div>


        </div>
      </div>
      <div class="col-12 action-button-container popup-footer">
        <button class="btn-lg btn-success btn btn-save pos-button" type="submit" (click)="isSaveAndContinue = true"
          *ngIf="!id">
          Apply </button>
        <button type="submit" class="btn-primary btn-lg btn btn-save pos-button" value="Save">Save</button>
        <button type="button" class="btn-secondary btn-lg btn btn-cancel-modal pos-button" value="Cancel"
          (click)="cancelEditing()">Cancel</button>
      </div>
    </context-provider>
  </form>
</pos-edit-wrapper>

<ng-template let-data="rowData" #operationTemplate>
  <span  class="col-30 d-inline-block col-lg-1 pr-2">
    <pos-action-icon [icon]="icons.editWhite" (click)="editBehavior(data)" 
    *ngIf="data.BehaviorName !== buttonBehaviorTypes.UncheckAllButtons.Value
      && data.BehaviorName !== buttonBehaviorTypes.SendPressEvent.Value
      && data.BehaviorName !== buttonBehaviorTypes.SignIn.Value
      && data.BehaviorName !== buttonBehaviorTypes.AddAiShake.Value"></pos-action-icon>
  </span>
  <span class="d-inline-block" (click)="confirmDelete(data)">
    <pos-action-icon [icon]="icons.deleteWhite"></pos-action-icon>
  </span>
</ng-template>

<ng-template #headerTemplate>
  <div class="dropdown position-fixed">
    <button id="behaviorMenu" class="btn btn-xs text-white normal pos-button btn-primary dropdown-toggle py-0 px-pix-2"
      type="button" data-bs-toggle="dropdown" aria-expanded="false">
      <pos-icon [icon]="icons.buttonBehaviorPlus" class="plus-icon text-white"></pos-icon>
      <span class="pt-pix-5 font-13 text-white "> Behavior </span>
    </button>
    <ul aria-labelledby="behaviorMenu" class="dropdown-menu dropdown-menu-start" role="menu">
      <li role="presentation" *ngFor="let behaviorType of behaviorTypes; last as lastIndex"
        (click)="addBehavior(behaviorType)">
        <a class="pointer dropdown-item py-2" role="menuitem" tabindex="-1">
          <fa-icon class="{{behaviorType.Icon}} me-pix-3 width-10 text-center"></fa-icon>
          {{ behaviorType.Text }}
        </a>
        <div *ngIf="!lastIndex" class="line-separator"></div>
      </li>
    </ul>
  </div>
</ng-template>
<ng-template let-data="rowData" #behaviorTemplate>
  <fa-icon class="{{data.Icon}} me-pix-3 width-10 text-center"></fa-icon>
  {{ data.DisplayName }}
</ng-template>
<pos-button-behavior-edit *ngIf="loadEdit" [selectedButtonBehaviorType]="selectedButtonBehaviorType" [buttonId]="id"
  [buttonType]="button.ButtonType" [selectedFunction]="selectedFunction" [buttonBehavior]="buttonBehavior"
  [buttonList]="buttonList" [screenToggleButtons]="screenToggleButtons" (close)="closeButtonBehaviorEdit($event)" (onSave)="saveButtonBehavior($event)">
</pos-button-behavior-edit>
