import { Component, Input, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { KioskScreenTemplateDirective } from 'src/app/order-kiosk/directives/kiosk-screen-template.directive';
import { KioskScreen, KioskScreenChoice, KioskScreenConfig, ScreenConfig } from 'src/app/order-kiosk/interfaces';
import { DomainConstants, RuntimeConstants } from 'src/app/shared';
import { KioskFixedGridLayoutTemplateComponent } from '../kiosk-fixed-grid-layout-template';
import { KioskFluidGridLayoutTemplateComponent } from '../kiosk-fluid-grid-layout-template/kiosk-fluid-grid-layout-template.component';
import { KioskSplitScreenLayoutTemplateComponent } from '../kiosk-split-screen-layout-template/kiosk-split-screen-layout-template.component';
import { KioskScreenTemplateProperties } from 'src/app/order-kiosk/interfaces/kiosk-screen-template-properties';
import { KioskNavigationBroadcastingService } from 'src/app/order-kiosk/services/kiosk-navigation-broadcasting.service';
import { Subscription } from 'rxjs';
import { WelcomeScreenTwoComponent } from '../welcome-screen-two/welcome-screen-two.component';
import { PromptTemplateComponent } from '../prompt-template/prompt-template.component';
import { AllergensScreenOneComponent } from '../allergens-screen-one';
import { AllergensScreenTwoComponent } from '../allergens-screen-two';
import { UntilDestroy } from '@ngneat/until-destroy';
import { AllergensInquiryComponent } from '../allergens-inquiry/allergens-inquiry.component';
import { AIShakeGroupSelectionComponent } from '../ai-shake-group-selection/ai-shake-group-selection.component';
import { AIShakeCreatedComponent } from '../ai-shake-created/ai-shake-created.component';
import { ActivatedRoute } from '@angular/router';
import { OrderKioskConfigService } from 'src/app/order-kiosk/services';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'pos-kiosk-screen-template',
  templateUrl: './kiosk-screen-template.component.html',
  styleUrls: ['./kiosk-screen-template.component.scss']
})
export class KioskScreenTemplateComponent {
  @Input() templateType: string = '';
  @Input() screenId: number = 0;
  @Input() screenConfigs: Array<KioskScreenConfig> = [];
  @Input() screenButtons: Array<KioskScreenChoice> = [];
  filteredScreenButtons: Array<KioskScreenChoice> = [];
  @ViewChild(KioskScreenTemplateDirective, { static: true }) kioskScreenTemplate: KioskScreenTemplateDirective;
  templateTypes = DomainConstants.KioskScreenTemplateTypes;
  navigationChangeSubscription: Subscription;
  isDesignMode = false;


  constructor(
    private readonly kioskNavigationBroadcastingService: KioskNavigationBroadcastingService,
    private readonly orderKioskConfigService: OrderKioskConfigService,
    route: ActivatedRoute
  ) {
    this.isDesignMode = route.snapshot.params.id;
  }
  ngOnInit(): void {
    const viewContainerRef = this.kioskScreenTemplate.viewContainerRef;
    this.filterScreenButtons(this.screenConfigs, this.screenButtons);
    this.loadTemplate(viewContainerRef);
    this.subscribeNavigationChange();
    document.documentElement.style.setProperty('--green-check-path', `url(${RuntimeConstants.IMAGE_BASE_PATH}/order-kiosk/green-check.png)`);
  }

  filterScreenButtons(screenConfigs: Array<KioskScreenConfig>, screenButtons: Array<KioskScreenChoice>) {
    const screenConfig = this.orderKioskConfigService.parseConfigProperties(DomainConstants.KioskScreenConfigProperties.ScreenConfig, screenConfigs) as ScreenConfig;
    const hideKioskOutOfStockBehaviorType = DomainConstants.KioskOutOfStockBehaviorTypes.find(x => x.Name == DomainConstants.KioskOutOfStockBehaviorType.Hide).Value;
    if (screenConfig?.OutOfStockBehavior == hideKioskOutOfStockBehaviorType) {
      this.filteredScreenButtons = screenButtons.filter(x => this.isDesignMode || x.Button?.SalesProduct == null || x.Button?.SalesProduct?.IsInStock);
    }
    else {
      this.filteredScreenButtons = screenButtons;
    }
  }

  ngOnDestroy() {
    this.navigationChangeSubscription?.unsubscribe();
  }

  private subscribeNavigationChange = () => {
    this.navigationChangeSubscription = this.kioskNavigationBroadcastingService.navigationChange.subscribe(
      (screen: KioskScreen) => {
        // if (this.screenId != screen.Id) {
        this.templateType = screen.TemplateType;
        this.screenId = screen.Id;
        this.screenConfigs = screen.KioskScreenConfigs;
        this.filterScreenButtons(screen.KioskScreenConfigs, screen.KioskScreenChoices)
        this.loadTemplate(this.kioskScreenTemplate.viewContainerRef);
        // }
      }
    );
  }

  loadTemplate(vcr: ViewContainerRef) {
    let component: Type<KioskScreenTemplateProperties>;
    switch (this.templateType) {
      case this.templateTypes.FixedGridLayout:
        component = KioskFixedGridLayoutTemplateComponent;
        break;
      case this.templateTypes.FluidGridLayout:
        component = KioskFluidGridLayoutTemplateComponent;
        break;
      case this.templateTypes.SplitScreenLayout:
        component = KioskSplitScreenLayoutTemplateComponent;
        break;
      case this.templateTypes.WelcomeScreenLayout:
        component = WelcomeScreenTwoComponent;
        break;
      case this.templateTypes.AllergensInquiryLayout:
        component = AllergensInquiryComponent;
        break;
      case this.templateTypes.AllergensSelectionScreenLayout:
        component = AllergensScreenOneComponent;
        break;
      case this.templateTypes.AllergensVerifyScreenLayout:
        component = AllergensScreenTwoComponent;
        break;
      case this.templateTypes.AIShakeGroupSelectionTemplate:
        component = AIShakeGroupSelectionComponent;
        break;
      case this.templateTypes.AIShakeCreatedTemplate:
        component = AIShakeCreatedComponent;
        break;
      default:
        component = PromptTemplateComponent;
    }
    if (component) {
      vcr.clear();
      const componentRef = vcr.createComponent<KioskScreenTemplateProperties>(component);
      componentRef.instance.screenId = this.screenId;
      componentRef.instance.screenButtons = this.filteredScreenButtons;
      componentRef.instance.screenConfigs = this.screenConfigs;
    }
  }
}
