import { Component, Input, ViewChild } from '@angular/core';
import { GridConfig, KioskScreenChoice, KioskScreenConfig, ScreenConfig } from 'src/app/order-kiosk/interfaces';
import { OrderKioskConfigService, OrderKioskScreenChoiceService } from 'src/app/order-kiosk/services';
import { AlertsService, ApplicationStateService, ColorUtilityService, DomainConstants, RuntimeConstants, SpinnerService, addKioskButton } from 'src/app/shared';
import { LeftPanelConfig, RightPanelConfig } from 'src/app/order-kiosk/interfaces/config/split-screen-config';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs';
import { KioskDesignerGridComponent } from '../../kiosk-designer-grid';
declare let $: any;

@Component({
  selector: 'pos-kiosk-split-screen-layout-template',
  templateUrl: './kiosk-split-screen-layout-template.component.html',
  styleUrls: ['./kiosk-split-screen-layout-template.component.scss']
})
export class KioskSplitScreenLayoutTemplateComponent {
  @Input() screenConfigs: Array<KioskScreenConfig> = [];
  @Input() screenId: number;
  @Input() screenButtons: Array<KioskScreenChoice> = [];
  @ViewChild('designerGrid') designerGridComponnet: KioskDesignerGridComponent;
  isDesignMode = false;
  leftPanelButtons: Array<KioskScreenChoice> = [];
  rightPanelButtons: Array<KioskScreenChoice> = [];
  screenConfig: ScreenConfig;
  leftGrid: GridConfig;
  rightGrid: GridConfig;
  leftPanelConfig: LeftPanelConfig;
  rightPanelConfig: RightPanelConfig;
  imageRootPath = RuntimeConstants.IMAGE_BASE_PATH + "/order-kiosk/";
  invoiceHeight: number;
  templateType = DomainConstants.KioskScreenTemplateTypes.SplitScreenLayout;
  primaryDesignerGridId = 1;
  secondaryDesignerGridId = 2;
  icons = {
    addKioskButton
  }
  constructor(private orderKioskConfigService: OrderKioskConfigService,
    private orderKioskScreenChoiceService: OrderKioskScreenChoiceService,
    private spinnerService: SpinnerService,
    private alertsService: AlertsService,
    private appStateService: ApplicationStateService,
    private colorUtilityService: ColorUtilityService,
    private route: ActivatedRoute
  ) {
    this.isDesignMode = route.snapshot.params.id;
    this.screenConfig = orderKioskConfigService.newScreenConfig();
    this.leftGrid = {
      NoOfCols: 1,
      NoOfRows: 1
    };
    this.rightGrid = {
      NoOfCols: 1,
      NoOfRows: 1
    }
    this.leftPanelConfig = orderKioskConfigService.newLeftPanelConfig();
    this.rightPanelConfig = orderKioskConfigService.newRightPanelConfig();
  }

  ngOnInit(): void {
    this.resizeWindow();
    this.prepareConfigs();
    this.calculateInvoiceHeight();
    // TODO: change variable
    document.documentElement.style.setProperty('--grid-bg', this.colorUtilityService.convertColorToRgba(this.appStateService.settingParam.KioskButtonsBackgroundColor ?? '#ffa800', this.appStateService.settingParam.KioskButtonsBackgroundOpacity ?? 0.4));
  }

  ngAfterViewInit(): void {
    this.calculateInvoiceHeight();
  }

  calculateInvoiceHeight() {
    this.invoiceHeight = this.orderKioskConfigService.calculateInvoiceHeight(this.screenConfig);
  }

  prepareConfigs() {
    const screenConfig = this.orderKioskConfigService.parseConfigProperties(DomainConstants.KioskScreenConfigProperties.ScreenConfig, this.screenConfigs);
    if (screenConfig) this.screenConfig = screenConfig;

    const grid = this.orderKioskConfigService.parseConfigProperties(DomainConstants.KioskScreenConfigProperties.Grid, this.screenConfigs);
    if (grid) this.leftGrid = grid;

    const leftPanelConfig = this.orderKioskConfigService.parseConfigProperties(DomainConstants.KioskScreenConfigProperties.LeftPanelConfig, this.screenConfigs);
    if (leftPanelConfig) this.leftPanelConfig = leftPanelConfig;

    const rightPanelConfig = this.orderKioskConfigService.parseConfigProperties(DomainConstants.KioskScreenConfigProperties.RightPanelConfig, this.screenConfigs);
    if (rightPanelConfig) {
      this.rightPanelConfig = rightPanelConfig;
    }

    this.leftPanelButtons = this.screenButtons.filter(x => x.ScreenConfig.Name == 'LeftPanelButtons');
    this.rightPanelButtons = this.screenButtons.filter(x => x.ScreenConfig.Name == 'RightPanelButtons');

    this.prepareLeftGrid();
    this.prepareRightGrid();
  }

  private prepareLeftGrid() {
    this.leftGrid.NoOfCols = this.leftPanelButtons.length;
  }

  private prepareRightGrid() {
    this.rightGrid.NoOfRows = this.rightPanelButtons.length;
  }

  resizeWindow() {
    $(window).resize(() => {
      this.calculateInvoiceHeight();
    });
  }

  addKioskScreenChoice(isLeftPanel = true) {
    this.spinnerService.show();
    const kioskChoice = this.orderKioskScreenChoiceService.newScreenChoice();
    kioskChoice.ScreenId = this.screenId;
    kioskChoice.ConfigId = isLeftPanel ? this.leftPanelButtons?.[0]?.ConfigId : this.rightPanelButtons?.[0]?.ConfigId;
    kioskChoice.Ordinal = isLeftPanel ? Math.max(...this.leftPanelButtons.map(x => x.Ordinal)) + 1 : Math.max(...this.rightPanelButtons.map(x => x.Ordinal)) + 1;
    this.orderKioskScreenChoiceService.insertAndGet(kioskChoice)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res: KioskScreenChoice) => {
          if (isLeftPanel)
            this.leftPanelButtons = [...this.leftPanelButtons, res];
          else
            this.rightPanelButtons = [...this.rightPanelButtons, res];
          this.designerGridComponnet.buttonClick(res);
        }, error: this.alertsService.showApiError
      });
  }
}
