import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { LoyaltyFormSettings } from '../../interface/loyalty-form-settings';
import { AlertsService, BaseFormComponent, FormUtilityService, loyaltyFormConfigurationCogs, Messages, SpinnerService } from 'src/app/shared';
import { LoyaltyFormSettingsService } from '../../service/loyalty-form-settings.service';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs';
import { PostSavedConfigurationService } from 'src/app/configurator/post-saved-configuration.service';

@Component({
  selector: 'pos-loyalty-form-settings',
  templateUrl: './loyalty-form-settings.component.html',
  styleUrls: ['./loyalty-form-settings.component.scss']
})
export class LoyaltyFormSettingsComponent extends BaseFormComponent implements OnInit{
  get getForm(): NgForm {
    return this.form
  }
  @ViewChild('loyaltyFormConfigForm') form: NgForm;
  loyaltyFormSettings: LoyaltyFormSettings;
  icons = {
    loyaltyFormConfigurationCogs
  };
  props = {
    controlBoxClass: 'col-lg-3 col-md-5 col-sm-8 col-xs-12'
  }
  captchaWarning = '';
  constructor(private loyaltyFormSettingsService: LoyaltyFormSettingsService,
    private alertService: AlertsService,
    private spinnerService: SpinnerService,
    private router: Router,
    private route: ActivatedRoute,
    private postSavedConfigurationService: PostSavedConfigurationService, 
    formUtilityService: FormUtilityService) {
    super(formUtilityService);
  }

  ngOnInit() {
    this.loyaltyFormSettings = this.loyaltyFormSettingsService.newLoyaltyFormSettings();
    this.getLoyaltyFormSettings();
    this.captchaWarning = Messages.UseRecaptchaWarning
  }


  getLoyaltyFormSettings() {
    this.spinnerService.show();
    this.loyaltyFormSettingsService.getLoyaltyFormSettings().pipe(finalize(() => {
      this.spinnerService.hide();
    })).subscribe({
      next: (res) => {
        this.loyaltyFormSettings = res;
          this.onFormChange(this.form);
      }, error: this.alertService.showApiError
    });
  }

  saveLoyaltyFormSettings(isValid: boolean) {
    if (!isValid) {
      return;
    }

    this.spinnerService.show();
    this.loyaltyFormSettingsService.saveLoyaltyFormSettings(this.loyaltyFormSettings).pipe(finalize(() => {
      this.spinnerService.hide()
    })).subscribe({
      next: (response) => {
        this.alertService.renderSuccessMessage(Messages.LoyaltyFormConfigSaveSuccess);
        this.postSavedConfigurationService.showMessage();
      }, error: this.alertService.showApiError
    });
  }

  onFormChange($event: NgForm) {
    this.form = $event;
    this.setInitialValue();
  }

  onCancel() {
    this.router.navigate(['..'], { relativeTo: this.route });
  }
}
