import { OrderDietaryRestrictionService } from './services/order-dietary-restriction.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentsModule, TableConfiguration } from '../shared';
import { ModalModule } from 'ngx-bootstrap/modal';
import { FormsModule } from '@angular/forms';
import { TableModule } from '@tarktech/tark-ng-utils';
import { ORDER_STATES } from './order.routes';
import { CalendarModule } from 'primeng/calendar';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CheckboxModule } from 'primeng/checkbox';
import { NgSelectModule } from '@ng-select/ng-select';
import { CKEditorModule } from 'ckeditor4-angular';
import { KeyboardComponent } from '../keyboard/components/keyboard.component';
import { TableModule as PrimengTableModule } from 'primeng/table';
import { QuantityChangeComponent } from '../shared/components/quantity-change/quantity-change.component';
import { QuantityChangePopupComponent } from '../shared/components/quantity-change-popup/quantity-change-popup.component';
import { ChooseNextScreenComponent } from './choose-next-screens/components/choose-next-screen/choose-next-screen.component';
import { ScreenPreviewComponent } from './choose-next-screens';
import {
    OrderNavigationComponent, IngredientsInfoService, IngredientsDietaryWarningService,
    IngredientsDietaryWarningsComponent, IngredientsInfoComponent, ScanQrCodeComponent
} from './order-entry';
import { SetOrderTypeComponent } from './order-entry/set-order-type/set-order-type.component';
import { LayoutService } from './services/layout.service';

import { ManualAmountComponent } from './order-entry/special-functions/manual-amount/manual-amount.component';
import { MenuResolveFactoryService } from './services/menu-resolve-factory.service';
import { ScreenContainingListComponent } from './screen-containing-list/screen-containing-list.component';
import { SetProductForButtonComponent } from './set-product-for-button/set-product-for-button.component';
import { AssignPagerComponent } from './order-entry/special-functions/assign-pager/assign-pager.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ManualDiscountComponent } from './order-entry/special-functions/manual-discount/manual-discount.component';
import { OrderEntryInfoModeComponent } from './order-entry/order-entry-info-mode/order-entry-info-mode/order-entry-info-mode.component';
import { OrderEntryInventoryInfoComponent } from './order-entry/order-entry-info-mode/order-entry-inventory-info/order-entry-inventory-info.component';
import { OrderEntryRecipeInfoComponent } from './order-entry/order-entry-info-mode/order-entry-recipe-info/order-entry-recipe-info.component';
import { FunctionButtonsComponent } from './order-entry/function-buttons/function-buttons.component';
import { TimeEntryComponent } from './order-entry/special-functions/time-entry/time-entry.component';
import { OrderInvoiceComponent } from './order-entry/order-invoice/order-invoice.component';
import { OrderComponent } from './order-entry/order/order.component';
import { ScreenSetupComponent } from './order-entry/screen-setup/screen-setup.component';
import { ChooseColorScreenComponent } from './order-entry/screen-setup/components/choose-color-screen/choose-color-screen.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { AddExistingButtonComponent } from './order-entry/screen-setup/components/add-existing-button/add-existing-button.component';
import { CopyButtonsComponent } from './order-entry/screen-setup/components/copy-buttons/copy-buttons.component';
import { ScreenBackupComponent } from './order-entry/screen-setup/components/screen-backup/screen-backup.component';
import { BackupService } from './order-entry/screen-setup/services/backup.service';
import { ScreenBackupEditComponent } from
    './order-entry/screen-setup/components/screen-backup/screen-backup-edit/screen-backup-edit.component';
import { ReArrangeButtonComponent } from './order-entry/screen-setup/components/re-arrange-button/re-arrange-button.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ReArrangeService } from './order-entry/screen-setup/services/re-arrange.service';
import { HeaderEntryModule } from '../header-entry';
import { InboxMessagesModule } from '../inbox-messages/inbox-messages.module';
import { RingupComponent } from './order-entry/ringup/ringup.component';
import { DispatchModule } from '../dispatch/dispatch.module';
import { SettleComponent } from './order-entry/settle/component/settle/settle.component';
import { SettleAccountsComponent } from './order-entry/settle/component/settle-accounts/settle-accounts.component';
import { NgPipesModule } from 'ngx-pipes';
import { SettleSeatComponent } from './order-entry/settle/component/settle-seat/settle-seat.component';
import { SettleOrderInvoiceComponent } from './order-entry/settle/component/settle-order-invoice/settle-order-invoice.component';
import { SettleSeatSplitsComponent } from './order-entry/settle/component/settle-seat-splits/settle-seat-splits.component';
import { SettlePaymentsComponent } from './order-entry/settle/component/settle-payments/settle-payments.component';
import { GiftCardPaymentComponent } from './order-entry/gift-card-payment/gift-card-payment.component';
import { ReviewOrderComponent } from './order-entry/review-order/review-order.component';
import { TableSelectionComponent } from './table-selection/table-selection.component';
import { LayoutDesignerModule } from '../layout-designer';
import { OrderDietaryRestrictionComponent } from './order-entry/order-dietary-restriction/order-dietary-restriction.component';
import { EbtButtonComponent } from './order-entry/ebt-button/ebt-button.component';
import { RingupNumpadComponent } from './order-entry/ringup-numpad/ringup-numpad.component';
import { SettleTotalComponent } from './order-entry/settle/component/settle-total/settle-total.component';
import { OrderSummaryModule } from '../order-summary/order-summary.module';
import { PrintCheckModule } from '../print-check/print-check.module';
import { SetAccountModule } from '../set-account/set-account.module';
import { Route, RouterModule } from '@angular/router';
import { forEach } from 'lodash';
import { AuthGuard } from '../app-guard';
import { PeripheralModelComponent } from './order-entry/peripheral-model/peripheral-model.component';
import { FilterComponent } from '../filter/filter.component';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ButtonWarningsAndTagsComponent } from './order-entry/button-warnings-and-tags/button-warnings-and-tags.component';

import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { SignInComponent } from './order-entry/sign-in/sign-in.component';
import { EmbedScreenComponent } from './order-entry/screen-setup/components/embed-screen/embed-screen.component';
import { DeductInventoryProductComponent } from './order-entry/deduct-inventory-product/deduct-inventory-product/deduct-inventory-product.component';

import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { CreditCardResponseModalComponent } from './order-entry/settle/component/settle/credit-card-response-modal/credit-card-response-modal.component';
import { PickupTimeEntryComponent } from './order-entry/special-functions/pickup-time-entry/pickup-time-entry.component';
import { SelectAiShakeComponent } from './order-entry/select-ai-shake/select-ai-shake.component';

const states = forEach(ORDER_STATES, (state: Route) => {
    state.canActivate = [AuthGuard];
});

@NgModule({
    imports: [
        NgPipesModule,
        OrderSummaryModule,
        CommonModule,
        FormsModule,
        ModalModule.forRoot(),
        RouterModule.forChild(states),
        TableModule.forRoot(TableConfiguration),
        NgSelectModule,
        CKEditorModule,
        PrimengTableModule,
        CalendarModule,
        AutoCompleteModule,
        ColorPickerModule,
        CheckboxModule,
        DragDropModule,
        InboxMessagesModule,
        HeaderEntryModule,
        DispatchModule,
        TooltipModule.forRoot(),
        ComponentsModule,
        PrintCheckModule,
        SetAccountModule,
        LayoutDesignerModule,
        PopoverModule.forRoot(),
        BsDropdownModule.forRoot(),
        NgbDropdownModule
    ],
    providers: [IngredientsInfoService, IngredientsDietaryWarningService, OrderDietaryRestrictionService, LayoutService,
        MenuResolveFactoryService, BackupService, ReArrangeService],
    declarations: [OrderComponent, IngredientsInfoComponent, IngredientsDietaryWarningsComponent, KeyboardComponent,
        QuantityChangeComponent, QuantityChangePopupComponent, ScreenPreviewComponent, ChooseNextScreenComponent,
        OrderNavigationComponent, SetOrderTypeComponent, ManualAmountComponent, AssignPagerComponent, ManualDiscountComponent,
        OrderEntryInventoryInfoComponent, OrderEntryRecipeInfoComponent, RingupComponent,
        ScreenContainingListComponent, SetProductForButtonComponent, OrderEntryInfoModeComponent, FunctionButtonsComponent,
        TimeEntryComponent, OrderInvoiceComponent, ScreenSetupComponent, ChooseColorScreenComponent, AddExistingButtonComponent,
        CopyButtonsComponent, ScreenBackupComponent, ScreenBackupEditComponent, ReArrangeButtonComponent, SettleAccountsComponent,
        SettleComponent, SettleSeatComponent, SettleOrderInvoiceComponent, SettleSeatSplitsComponent, SettlePaymentsComponent,
        GiftCardPaymentComponent, ReviewOrderComponent, TableSelectionComponent, OrderDietaryRestrictionComponent, EbtButtonComponent,
        RingupNumpadComponent, SettleTotalComponent, PeripheralModelComponent, FilterComponent, ButtonWarningsAndTagsComponent, SignInComponent, 
        EmbedScreenComponent, DeductInventoryProductComponent, CreditCardResponseModalComponent, ScanQrCodeComponent,
        PickupTimeEntryComponent, SelectAiShakeComponent],
    exports: [KeyboardComponent, ScreenSetupComponent, ChooseColorScreenComponent, AddExistingButtonComponent,
        CopyButtonsComponent, ScreenBackupComponent, ScreenBackupEditComponent, ReArrangeButtonComponent]
})
export class OrderModule { }
