<pos-kiosk-header *ngIf="screenConfig.Header.IsShow" [header]="screenConfig.Header"></pos-kiosk-header>
<div class="row mx-0 designer-area"
    [ngStyle]="{'background-color': screenConfig.BackgroundColor, 'background-image': screenConfig.BackgroundImage ? 'url(\''+ imageRootPath + screenConfig.BackgroundImage + '\')' : ''}">
    <div class="overflow-auto d-flex p-0" [ngClass]="screenConfig.ShowInvoice ? 'col-xl-9 col-8' : 'col-12'"
        [ngStyle]="{'height': invoiceHeight + 'px'}">
        <div class="width-80">
            <div class="d-flex h-100 relative" [ngStyle]="{'align-items': leftPanelConfig.Alignment?.Vertical,
                'justify-content': leftPanelConfig.Alignment?.Horizontal}" *ngIf="leftGrid">
                <div class="grid-background" *ngIf="isDesignMode || leftPanelButtons?.[0]?.Button"
                    [ngStyle]="{'top': leftPanelConfig.VerticalPosition + 'px', 'position': 'absolute', 'border-bottom-width': leftPanelConfig.VerticalPosition || leftPanelConfig.Alignment?.Vertical != 'bottom' ? '4px' : 0}">
                    <div class="product-description" *ngIf="leftPanelButtons?.[0]?.Button?.SalesProduct?.Description" [innerHTML]="leftPanelButtons[0].Button.SalesProduct.Description">
                        </div>
                    <pos-kiosk-designer-grid [grid]="leftGrid" class="kiosk-designer-grid-{{screenId}}-{{primaryDesignerGridId}}" [screenId]="screenId" [isDesignMode]="isDesignMode"
                        [designerGridId]="primaryDesignerGridId" [(screenButtons)]="leftPanelButtons" [isAllowDelete]="true" [buttonConfig]="leftPanelConfig"
                        [templateType]="templateType"></pos-kiosk-designer-grid>
                </div>
            </div>
        </div>
        <div class="right-panel-config m-2" [style.width]="rightPanelConfig.Width ?? '25%'">
            <div class="right-panel-title"
                [ngStyle]="{'height': rightPanelConfig.Header.Height + 'px', 'background-color': rightPanelConfig.Header.BackgroundColor, 'color': rightPanelConfig.Header.FontColor, 'font-size': rightPanelConfig.Header.FontSize }"
                [innerHTML]="rightPanelConfig.Header?.Title"></div>
            <pos-action-icon [icon]="icons.addKioskButton" iconText="Add" *ngIf="isDesignMode"
                (click)="addKioskScreenChoice(false)" class="text-nowrap text-right m-pix-5"></pos-action-icon>
            <div class="buttons-grid overflow-auto"
                [ngStyle]="{'align-items': rightPanelConfig.ButtonAlignment?.Vertical}" *ngIf="rightGrid">
                <pos-kiosk-designer-grid class="kiosk-designer-grid-{{screenId}}-{{secondaryDesignerGridId}}" [grid]="rightGrid" [screenId]="screenId" [isDesignMode]="isDesignMode" #designerGrid
                    [designerGridId]="secondaryDesignerGridId" [(screenButtons)]="rightPanelButtons" [isAllowDelete]="true" [buttonConfig]="rightPanelConfig"
                    [templateType]="templateType"></pos-kiosk-designer-grid>
            </div>
        </div>
    </div>
    <div class="col-xl-3 col-4 p-3" *ngIf="screenConfig.ShowInvoice" [ngStyle]="{'height': invoiceHeight - 3 + 'px'}">
        <pos-kiosk-invoice></pos-kiosk-invoice>
    </div>
</div>
<pos-kiosk-footer *ngIf="screenConfig.Footer.IsShow" [buttons]="screenButtons" [footer]="screenConfig.Footer"></pos-kiosk-footer>
