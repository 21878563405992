<div>
    <button (click)="closeDialog()" class="close me-pix-10" type="button">×</button>
    <h4 class="modal-content__heading">Order Maintenance</h4>
</div>
<div class="order-maintenance-model overflow-hidden">
    <div class="row g-0" [ngStyle]="{'min-height': popupHeight + 'px'}">
        <div class="row g-0 ps-3 pe-2 col-xl-8 col-12 mt-pix-5 relative">
            <div class="col-12 col-sm-12 col-md-10 col-lg-9 div-order-maintenance mt-pix-2">
                <p class="input-group">
                    <span class="input-group-btn">
                        <button class="btn btn-secondary p-0 col-50 pe-pix-5 pos-button" (click)="backOneDay()" type="button">
                            <pos-icon [icon]="icons.angleLeft" class="font-1-7em arrows"></pos-icon>
                        </button>
                    </span>
                    <span class="input-group-btn">
                        <p-calendar [required]="true" class="ps-pix-5 pe-pix-5" [dateFormat]="dateFormat" [readonlyInput]="true"
                            (onSelect)="getAllOrdersBetweenDate()" appendTo="body"
                            [inputStyle]="{'width':'unset','display':'unset'}" inputStyleClass="form-control"
                            [monthNavigator]="true" [showIcon]="true" name="startDate" [(ngModel)]="dateToFilter"
                            yearNavigator="true" yearRange="2000:2100" showButtonBar="true"></p-calendar>
                    </span>
                    <span class="input-group-btn">
                        <button class="btn btn-secondary p-0 col-50 pos-button" (click)="forwardOneDay()" type="button">
                            <pos-icon [icon]="icons.angleRight" class="arrows font-1-7em"></pos-icon>
                        </button>
                    </span>
                    <span class="">
                        <ng-select posInput (change)="getAllOrdersBetweenDate()" [clearable]="false" [items]="userList"
                            name="user" bindLabel="name" placeholder="Select user" bindValue="id"
                            [(ngModel)]="currentUserId" class="users-dropdown col-250 ms-pix-5">
                        </ng-select>
                    </span>
                </p>
            </div>
            <div class="col-12">
                <div class="mt-pix-n8">
                    <ng-table [data]="orderList" [columns]="orderColumns" [paginator]="false" [selectionMode]="'single'"
                        (rowSelect)="loadOrderSummary($event)" [rowClass]="'Color'" [styleClass]="'no-outline'"
                        [scrollable]="true" [scrollHeight]="height" [customFilterColumns]="customFilterColumns">
                    </ng-table>
                    <ng-template let-data="rowData" #nameTemplate>
                        <div class="name-ellipsis">{{data.Name}}</div>
                    </ng-template>
                    <ng-template let-data="rowData" #totalTemplate>
                        <span>{{data.Total | tark_currency}}</span>
                    </ng-template>
                    <ng-template let-data="rowData" #paidAmountTemplate>
                        <span>{{data.PaidAmount | tark_currency}}</span>
                    </ng-template>
                    <ng-template let-data="rowData" #gratuityTemplate>
                        <span>{{data.Gratuity | tark_currency}}</span>
                    </ng-template>
                    <ng-template let-data="rowData" #cashTenderedTemplate>
                        <span>{{data.CashTendered | tark_currency}}</span>
                    </ng-template>
                    <ng-template let-data="rowData" #settingTemplate>
                        <span>
                            <pos-icon [icon]="icons.checkCircleSolid" *ngIf="!data.IsUnserved" class="font-16">
                            </pos-icon>
                            <pos-icon [icon]="icons.cogs" *ngIf="data.IsUnserved" class="font-16"></pos-icon>
                            <pos-icon [icon]="icons.clock" *ngIf="data.IsScheduledOrder"
                                class="ps-pix-10 font-15"></pos-icon>
                            <pos-icon [icon]="icons.fileAudio" class="ps-pix-10 font-15 popover-audio-recordings" tooltip title="Click to see audio recordings"
                                [outsideClick]="true" placement="left" [popover]="orderMaintenanceAudioRecordings" #audioPop="bs-popover" (click)="updatePopoverRef(audioPop)"
                                (click)="setOrderId(data)" *ngIf="isShowAudioFileIcon" container="body"></pos-icon>
                            <pos-icon *ngIf="data.RefundedQty > 0" class="ps-pix-10 font-15 popover-order-wise-recordings" (click)="setOrderId(data);updatePopoverRef(refundPop);"
                                [icon]="icons.undo" [popover]="orderWiseRefunds" #refundPop="bs-popover" placement="left" [outsideClick]="true" container="body">
                            </pos-icon>
                        </span>
                    </ng-template>

                    <ng-template let-data="rowData" #paymentType>
                        <span *ngIf="data.Type !== 'None'" #pop="bs-popover" (click)="updatePopoverRef(pop)"
                            class="popover-order-maintenance pointerStyle pointer" container="body"
                            (click)="popoverCreditDetail(data,$event)" [outsideClick]="true"
                            [popover]="orderMaintenanceCardDetails" triggers="click" #popoverTooltip placement="left">
                            <a tooltip="Click to see transaction details" class="link" placement="top">{{data.Type}}</a>
                        </span>
                        <span *ngIf="data.Type === 'None'">{{data.Type}}</span>
                    </ng-template>
                    <ng-template let-data="rowData" #orderMethod>
                        <a class="link" (click)="changeOrderMethod(data)">{{data.OrderMethod}}</a>
                    </ng-template>

                    <!-- credit detail template -->
                    <ng-template #orderMaintenanceCardDetails>
                        <pos-credit-card-transactions [transactions]="selectedTransactionDetails"
                            [total]="selectedOrderTotal" [orderTypeId]="selectedOrderTypeId" (closePopOver)="bsPopover.hide()">
                        </pos-credit-card-transactions>
                    </ng-template>

                    <ng-template #orderMaintenanceAudioRecordings>
                        <pos-order-recordings [orderId]="orderId" (closePopOver)="bsPopover.hide()"></pos-order-recordings>
                    </ng-template>

                    <ng-template #orderWiseRefunds>
                        <pos-order-wise-refund class="order-wise-refund" [orderId]="orderId" (closePopOver)="bsPopover.hide()">
                        </pos-order-wise-refund>
                    </ng-template>

                    <ng-template #orderMaintenancePopoverTemplate>
                        <div class="">
                            <ul>
                                <li class="listbullet">
                                    <a class="grey" (click)="removeMember()">
                                        <pos-icon [icon]="icons.listAlt" class="pe-pix-3 m-l-5">
                                        </pos-icon>
                                        data
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>

        <div class="ps-2 col-xl-4 col-12 ps-0 mt-pix-5 ps-0 mt-pix-5 pe-3">
            <pos-read-only-order-summary [orderId]="orderId" [isShowBlankOrder]="false"
                [isUnloadOrderSummary]="isUnloadOrderSummary" [refundedOrderAmount]="refundedOrderAmount"
                (summaryLoaded)="addedProductToOrderCompleted($event.orderData)"></pos-read-only-order-summary>
        </div>
    </div>

    <div class="col-md-12 col-12 pt-pix-10 pb-pix-10 text-center">
        <div class="ps-3">
            <button class="btn-lg btn-primary btn normal me-pix-5 float-start  white pos-button" (click)="printCheckOfOrder()">
                <pos-icon [icon]="icons.printOrderManagement" class="font-0-5em"></pos-icon> Print
            </button>
        </div>
        <div>
            <button class="btn-lg btn-secondary btn normal me-pix-5 pos-button" (click)="markUnservedOrder()"
                [disabled]="!orderId || isUnserved || orderRefunded"
                permissions='[{"Name":"{{permissions.OrderManagementMarkUnserved}}", "Level":"{{accessLevels.Access}}"}]'>
                <pos-icon [icon]="icons.undo" class="font-0-9em"></pos-icon> Mark Unserved
            </button>
            <button class="btn-lg btn-secondary btn normal me-pix-5 pos-button" (click)="onMemberOperation()" [disabled]="!orderId">
                <pos-icon [icon]="icons.orderManagementLinkMember" class="font-0-9em"></pos-icon> {{ memberOperation }}
                {{loyalAccountTerm}}
            </button>
            <button class="btn-lg btn-secondary btn normal me-pix-5 pos-button" (click)="voidOrder()" [disabled]="!orderId || orderRefunded"
                permissions='[{"Name":"{{permissionVoidOrder.name}}", "Level":"{{permissionVoidOrder.access}}"}]'>
                <pos-icon [icon]="icons.minusCircle" class="font-0-9em"></pos-icon> Void
            </button>
            <button class="btn-lg btn-secondary btn normal me-pix-5 pos-button" (click)="refundAmount()" [disabled]="orderData?.PaidAmount <= 0"
                permissions='[{"Name":"{{permissionRefunds.name}}", "Level":"{{permissionRefunds.access}}"}]'>
                <pos-icon [icon]="icons.replyAll" class="font-0-9em"></pos-icon> Refund
            </button>
            <button class="btn-lg btn-secondary btn normal me-pix-5 pos-button" (click)="mergeOrders()" [disabled]="!isUnserved">
                <pos-icon [icon]="icons.codeBranch" class="font-0-9em"></pos-icon> Merge
            </button>
            <button class="btn-lg btn-secondary btn normal me-pix-5 pos-button" (click)="addTip()"
                *ngIf="settingParam.AllowCreditCardTip" [disabled]="!isDisabledAddTip || !orderId">
                <pos-icon [icon]="icons.conciergeBell" class="font-0-9em"></pos-icon> Add Tip
            </button>
            <button class="btn-lg btn-secondary btn normal me-pix-5 pos-button" (click)="rescheduleOrder()" *ngIf="isScheduledOrder">
                <pos-icon [icon]="icons.clock" class="font-0-9em"></pos-icon> Reschedule
            </button>
            <button class="btn-lg btn-secondary btn normal me-pix-5 pos-button" (click)="closeDialog()">
                <pos-icon [icon]="icons.times"></pos-icon> Close
            </button>
        </div>
    </div>
</div>
<pos-set-account #setAccount [hidden]="true" [orderId]="orderId" [isTypeAccount]="true" [fromOrderManagement]="true">
</pos-set-account>
<ng-template #tipInfoModal>
    <div>
        <h4 class="modal-content__heading">{{tipInfoModalHeader}}</h4>
    </div>
    <div class="pos-modal-body pt-pix-20 text-center">
        {{tipInfoModalMessage}}
    </div>
    <div class="col-12 action-button-container popup-footer pb-pix-10">
        <button type="button" (click)="cancelTipInfoModal()" class="btn btn-secondary btn-lg btn-cancel-modal pos-button">
            {{ tipInfoModalButtonText }}
        </button>
    </div>
</ng-template>
