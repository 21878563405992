<div class="page-content">
    <div class="tab-pane fade show active tab-pane-m-p">
        <div #ingredientsFormGroup="ngModelGroup" novalidate ngModelGroup="ingredients">
            <div class="row mx-0">
            <div class="col-12">
                <button type="button" class="btn-primary btn normal mb-pix-3 float-right pos-button white" (click)="importRawIngredient()">
                    <pos-icon [icon]="icons.ingredientImport"></pos-icon> Import
                </button>
            </div>
            <div class="col-12">
                <p-table *ngIf="loadTable" [value]="ingredients" #pEditor (onRowReorder)="gridRowReorder($event)">
                    <ng-template pTemplate="header">
                        <tr>
                            <th style="width: 2em"></th>
                            <th style="width: 45%">Raw Ingredient</th>
                            <th style="width: 43%" class="no-right-border">Parent Ingredient</th>
                            <th style="width: 25px" class="no-left-border"></th>
                            <th style="width: 8%">
                                < 2%</th>
                            <th style="width: 45px">
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-i="rowIndex">
                        <tr [pReorderableRow]="i">
                            <td style="width: 2em" class="vertical-mid-imp">
                                <i [ngClass]="icons.ordinalBars.iconClasses" pReorderableRowHandle></i>
                            </td>
                            <td [ngStyle]="{'padding-left': (rowData.Level * 10) + 10 + 'px'}">
                                <p-autoComplete appendTo="body" name="rawIngredient{{i}} - {{rowData?.OrdinalString}}" #rawIngredient="ngModel"
                                    [(ngModel)]="rowData.RawIngredient"
                                    (keyup)="createIngredientRow(i+1== ingredients.length)"
                                    [suggestions]="suggestedRawIngredients"
                                    (completeMethod)="prepareRawIngredientsForSuggestions($event)" [dropdown]="false"
                                    [minLength]="2" [style]="{'width':'100%'}" [inputStyle]="{'width':'100%'}"
                                    [required]="rowData.ParentIngredient || rowData.LessThan2Percent || ingredients[i + 1]">
                                </p-autoComplete>
                                <span [ngClass]="{'has-error': (rawIngredient.invalid && rawIngredient.touched && ingredients[i + 1])}"
                                    *ngIf="rawIngredient.invalid && rawIngredient.touched && ingredients[i + 1]">
                                    <label class="control-label" *ngIf="rawIngredient.errors?.required">Please
                                        enter raw ingredient.</label>
                                </span>
                            </td>
                            <td class="no-right-border">
                                <ng-select posInput [items]="parentIngredients" [multiple]="false" name="parentIngredient{{i}} - {{rowData?.OrdinalString}}"
                                    [selectOnTab]="true" (focus)="onParentIngredientFocus(i, rowData)" bindLabel="RawIngredient"
                                    placeholder="Select parent ingredient" [(ngModel)]="rowData.ParentIngredient"
                                    [clearable]="false"
                                    (change)="createIngredientRow(i+1== ingredients.length); onChangeParentIngredient(rowData);"
                                    appendTo='body' [style]="{'width':'100%'}">
                                </ng-select>
                            </td>
                            <td class="pointer no-left-border vertical-mid-imp" (click)="removeParentIngredient(rowData, i)">
                                <span class='float-right' *ngIf='rowData.ParentIngredient'>
                                    <pos-icon [icon]='icons.times'></pos-icon>
                                </span>
                            </td>
                            <td class="text-center vertical-mid-imp">
                                <p-checkbox name="lessThan2Percent.{{i}}" id="lessThan2Percent.{{i}}" #item.i="ngModel"
                                    [(ngModel)]="rowData.LessThan2Percent" binary="true"></p-checkbox>
                            </td>
                            <td class="text-center vertical-mid-imp">
                                <a (click)="deleteIngredient(rowData, i)" title="Delete"
                                    *ngIf="!(i+1== ingredients.length)">
                                    <pos-action-icon [icon]="icons.deleteWhite"></pos-action-icon>
                                </a>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
            </div>
        </div>
    </div>
</div>
