import { Component, ElementRef, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { catchError, debounceTime, distinctUntilChanged, finalize, Observable, of, startWith, Subject, switchMap } from 'rxjs';
import { AlertsService, SpinnerService } from 'src/app/shared';
import { ModalComponent } from 'src/app/shared/components/modal';
import { AiShake } from 'src/app/shared/interface/ai-shake';
import { AiShakeService } from 'src/app/shared/services/ai-shake.service';
import { minusSquare } from 'src/app/shared/components/icon';
import * as $ from 'jquery';

@Component({
  selector: 'pos-select-ai-shake',
  templateUrl: './select-ai-shake.component.html',
  styleUrls: ['./select-ai-shake.component.scss']
})
export class SelectAiShakeComponent extends ModalComponent {
  
  private searchTermSubject = new Subject<string>();
  filteredShakeList$: Observable<AiShake[]> = new Observable<AiShake[]>();
  searchTerm = '';
  screenHeight = $(window).height() - 500 < 150 ? 200 : 370;
  @ViewChild('aiShakeSearch') aiShakeSearch: ElementRef;

  icons = { minusSquare }

  constructor(protected bsModalRef: BsModalRef,
    private aiShakeService: AiShakeService,
    private spinnerService: SpinnerService,
    private alertsService: AlertsService,
  ) {
    super(bsModalRef);
  }

  ngOnInit() {
    this.resizeWindow();
    this.filteredShakeList$ = this.searchTermSubject.pipe(
      startWith(''),
      debounceTime(500), // Wait for 500ms pause in events
      distinctUntilChanged(),
      switchMap(term => this.searchShakes(term))
    );
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.aiShakeSearch?.nativeElement?.focus();
    })
  }

  resizeWindow() {
    $(window).resize(() => {
      this.screenHeight = $(window).height() - 500 < 150 ? 200 : 370;
    });
  }

  onSearchChange(term: string) {
    this.searchTermSubject.next(term);
  }

  searchShakes(term: string): Observable<AiShake[]> {
    if (!term.trim()) return of([]);
    this.spinnerService.show();
    return this.aiShakeService.searchAiShakes(term)
    .pipe(
      finalize(() => this.spinnerService.hide()),
      catchError((error) => {
        this.alertsService.showApiError(error);
        return of([]);
      }),
    );
  }

  addAiShake(shake: AiShake) {
    this.close.emit({ shakeId: shake.Id })
  }

  cancel() {
    this.close.emit();
  }
}
