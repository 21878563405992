import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { RuntimeConstants } from 'src/app/shared';
import { OnlineOrderingSettings, OnlineOrderingDiscountSetting } from '../interface/online-ordering';

@Injectable({
  providedIn: 'root'
})
export class OnlineOrderingSettingsService {

  constructor(private httpService: HttpClient) { }

  newOnlineOrderingDiscount(): OnlineOrderingDiscountSetting {
    const discount: OnlineOrderingDiscountSetting = {
      Id: 0,
      Code: '',
      Value: '',
      Description: '',
      Type: '',
      IsChanged: false,
      Index: null,
      KeyName: ''
    }
    return discount;
  }

  newOnlineOrderingSetting(keyName: string = null): OnlineOrderingSettings {
    const setting: OnlineOrderingSettings = {
      Id: 0,
      KeyName: keyName ?? '',
      ParamValue: '',
      IsChanged: false,
      SettingType: '',
      Description: '',
      SettingName: '',
      iconName: '',
      DateAdded: new Date(),
      DateEdited: null,
      Ordinal: 0
    }
    return setting;
  }

  getOnlineOrderingSettings(): Observable<OnlineOrderingSettings[]> {
    return this.httpService.get<OnlineOrderingSettings[]>(
      `${RuntimeConstants.API_PATH}onlineorderingsettings`
    );
  }

  getTimeZones(): Observable<any> {
    return this.httpService.get(`${RuntimeConstants.API_PATH}onlineorderingsettings/timezones`);
  }

  saveOnlineOrderingSettings(settings, images): Observable<any> {
    const formData = this.prepareIconFormData(settings, images);
    return this.httpService.post(
      `${RuntimeConstants.API_PATH}onlineorderingsettings`, formData
    );
  }

  private prepareIconFormData(param, images: File[]): FormData {
    const formData = new FormData();
    if (images) {
        images.forEach(image => {
          formData.append('file', image);
        });
    }
    formData.append('model', JSON.stringify(param));
    return formData;
}
}
