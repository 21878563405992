import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { AlertsService, ApplicationStateService, DomainConstants, OrderService, RuntimeConstants, SettingParam, SpinnerService } from 'src/app/shared';
import { finalize } from 'rxjs/operators';
import { OrderInvoice, OrderInvoiceOrderProduct } from 'src/app/orders';
import { clock } from 'src/app/shared/components/icon';
declare let $: any;

@Component({
  selector: 'pos-read-only-order-summary',
  templateUrl: './read-only-order-summary.component.html',
  styleUrls: ['./read-only-order-summary.component.scss']
})
export class ReadOnlyOrderSummaryComponent implements OnInit, OnChanges {

  @Input() orderId: number;
  @Input() refundedOrderAmount: number;
  @Output() summaryLoaded: EventEmitter<any> = new EventEmitter<any>();
  productsHeight: number;
  @Input() orderData?: OrderInvoice;
  @Input() isSelectableProduct?: boolean;
  @Output() changeProductSelection: EventEmitter<OrderInvoiceOrderProduct> = new EventEmitter<OrderInvoiceOrderProduct>();
  @Input() isShowBlankOrder?: boolean = true;
  @Input() seats: [];
  @Input() isUnloadOrderSummary = false;
  today = new Date().toLocaleDateString();
  @ViewChild('orderSummaryProduct') public orderSummaryProduct: any;
  pickupDate: string = null;
  settingParam: SettingParam;
  loyaltyAccountTerm: string = '';
  tableTerm: string = '';
  accountTerm: string = '';
  accountTypes = DomainConstants.AccountTypes;
  icons = {
    clock
  };
  constructor(private spinnerService: SpinnerService,
    private alertService: AlertsService,
    private orderService: OrderService,
    private applicationStateService: ApplicationStateService) {
    this.orderData = this.orderService.getNewOrderInvoiceDetails();
  }

  ngOnInit(): void {
    this.setDefault();
  }

  setDefault() {
    this.settingParam = this.applicationStateService.settingParam;
    this.loyaltyAccountTerm = this.settingParam.LoyalAccountTerm ? this.settingParam.LoyalAccountTerm : 'Account';
    this.tableTerm = this.settingParam.TableTerm ? this.settingParam.TableTerm : 'Table';
    this.accountTerm = this.settingParam.AccountTerm ? this.settingParam.AccountTerm : 'Tab';
  }

  ngOnChanges() {
    if (this.orderId && !this.isSelectableProduct) {
      this.getOrderDetails();
    } else if (!this.orderId && !this.isShowBlankOrder) {
      this.orderData = null;
    }
    this.productsHeight = $(window).height() - 550;
  }

  getOrderDetails() {
    this.spinnerService.show();
    this.orderService.getOrderDetails(this.orderId)
      .pipe(finalize(() => this.spinnerService.hide()))
      .subscribe({ next: (response: OrderInvoice) => {
        this.orderData = response;
        this.pickupDate = new Date(this.orderData.OrderAttributes.PickupTime).toLocaleDateString();
        this.summaryLoaded.emit({ orderData: this.orderData });
        this.seats = [];
      }, error: this.alertService.showApiError });
  }

  changeSelection = (product: OrderInvoiceOrderProduct) => {
    this.orderSummaryProduct.selectProduct(product);
  }

  changeOrderProductSelection = (product: OrderInvoiceOrderProduct) => {
    this.changeProductSelection.emit(product);
  }
}
