import { Component, OnInit, ViewChild } from '@angular/core';
import { debounce, filter, find, forEach } from 'lodash';
import { finalize } from 'rxjs/operators';
import { InventoryZone } from 'src/app/information-management/inventory-zone/interface/inventory-zone';
import { InventoryZoneService } from 'src/app/shared/services/inventory-zone.service';
import {
  ApplicationStateService, BaseFormComponent, DomainConstants, FormUtilityService, InfoModalComponent, InventoryLogStockSalesProduct, MathsUtilityService, Messages, ModalBackdropService,
  ModalService, SettingParam, UserDetails
} from 'src/app/shared';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { InvCategory, InvVendor, VendorAndCategory } from '../interfaces/vendor-and-category';
import { InventoryLogService } from '../../../../shared/services/inventory-log.service';
import { inventorylogupdate, times, inventorylogprint } from 'src/app/shared/components/icon';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { SalesProductsService } from 'src/app/information-management/sales-products/services/sales-products.service';
import { InventoryLog } from '../interfaces/inventory-log';
declare let $: any;
import { StringUtils } from 'src/app/shared/string-utils/string-utils';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { InventoryOffSiteLocation } from 'src/app/information-management/inventory-off-site-locations/interfaces/inventory-offsite-location';
import { InventoryOffSiteLocationService } from 'src/app/information-management/inventory-off-site-locations/services/inventory-offsite-location.service';
@Component({
  selector: 'pos-inventory-log',
  templateUrl: './inventory-log.component.html',
  styleUrls: ['./inventory-log.component.scss']
})
export class InventoryLogComponent extends BaseFormComponent implements OnInit {
  get getForm(): NgForm {
    return this.inventoryLogForm;
  }
  @ViewChild('inventoryLogForm') inventoryLogForm: NgForm;
  updateType: string;
  salesInventoryProducts: Array<InventoryLogStockSalesProduct> = [];
  updateId: number;
  settingParam: SettingParam;
  filterProduct: string = '';
  updateFlagForItem: boolean;
  updateFlag: boolean;
  logInventories: [];
  inventoryZones: Array<InventoryZone> = [];
  vendors: Array<InvVendor> = [];
  selectedVendor = null;
  idForDropDown: number;
  checkOverdueProducts: boolean = false;
  checkAllProducts: boolean = false;
  checkAllSections: boolean = false;
  selectedCategory = null;
  selectedZone = null;
  typeForDropDown: string = '';
  inventoryFinalLog: Array<InventoryLog> = [];
  selectedStatus = null;
  salesProductStockWarningModelRef: BsModalRef;
  noRecordMessage: string = '';
  userDetails: UserDetails;
  inventoryCategories: Array<InvCategory> = [];
  LogProductFilter = { VENDOR: 'Vendor', CATEGORY: 'Category', INVENTORY_ZONE: 'InventoryZone' };
  @ViewChild('salesProductStockWarning') private salesProductStockWarning: any;
  icons = { inventorylogupdate, times, inventorylogprint };
  inventoryStatus = [
    { 'Id': 1, 'Name': 'Inventory Overdue' },
    { 'Id': 2, 'Name': 'Inventory Near Due' },
    { 'Id': 3, 'Name': 'Level Affecting Products' },
    { 'Id': 4, 'Name': 'Scheduled'}
  ];
  inventoryLocations: Array<InventoryOffSiteLocation> = [];
  selectedLocation = 0;
  selectedLocationName = DomainConstants.OnSiteInventoryText;
  constructor(private inventoryZoneService: InventoryZoneService,
    private inventoryLogService: InventoryLogService,
    private salesProductService: SalesProductsService,
    private applicationStateService: ApplicationStateService,
    private mathsUtilityService: MathsUtilityService,
    private alertService: AlertsService,
    private bsModalService: BsModalService,
    private modalBackdropService: ModalBackdropService,
    private modalService: ModalService,
    private router: Router,
    private route: ActivatedRoute,
    private inventoryOffSiteLocationService: InventoryOffSiteLocationService,
    private spinnerService: SpinnerService,
    formUtilityService: FormUtilityService) {
    super(formUtilityService);
  }

  ngOnInit(): void {
    this.getInventoryZones();
    this.getVendorAndCategory();
    this.setDefaults();
    this.resizeWindow();
    this.resetProductAreaHeight();
    this.getInventoryLocations();
  }

  resetProductAreaHeight = () => {
    const scrollableHeight = $(window).height() - 355;
    $('.inner_remaining').height(scrollableHeight);
  }

  resizeWindow() {
    $(window).resize(() => {
      this.resetProductAreaHeight();
    });
  }

  setDefaults = () => {
    this.settingParam = this.applicationStateService.settingParam;
    this.userDetails = this.applicationStateService.userDetails;
  }

  getInventoryZones() {
    this.spinnerService.show();
    this.inventoryZoneService.getInventoryZones()
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (response: Array<InventoryZone>) => {
          this.inventoryZones = response;
        }, error: this.alertService.showApiError
      });

  }

  getInventoryLocations() {
    this.spinnerService.show();
    this.inventoryOffSiteLocationService.getActiveLocations()
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (response: Array<InventoryOffSiteLocation>) => {
          this.inventoryLocations = response;
        }, error: this.alertService.showApiError
      });
  }

  resetCheck() {
    this.checkAllSections = false;
    this.checkAllProducts = false;
    this.checkOverdueProducts = false;
    this.noRecordMessage = '';
  }

  showInventories(id, type) {
    $(".chk-inventory-product").attr("checked", false);
    this.selectedLocationName = this.inventoryLocations.find(x => x.Id == this.selectedLocation)?.Name;
    let itemUpdated = false;
    forEach(this.inventoryFinalLog, (log) => {
      const updatedItems = filter(log.CategoryList, (item) => {
        return item.IsUpdated;
      });
      if (!itemUpdated && updatedItems && updatedItems.length) {
        itemUpdated = true;
      }
    })
    if (itemUpdated || ((!this.isAnySectionItemUnSelected()) && this.inventoryFinalLog.length)) {
      this.updateType = type;
      this.updateId = id;
      this.openConfirmationModel(Messages.ConfirmAbandonChanges, () => {
        this.updateFlagData();
        this.resetCheck();
      }, () => {
        this.resetDropdown(this.updateType);
      });
    }
    else {
      this.resetCheck();
      this.setDropDowns(type);
      this.getInventories(id, type);
    }
  }

  getInventories(id, type) {
    const inventoryModel = { Id: id, Type: type, InventoryLocationId: this.selectedLocation ? this.selectedLocation : null };
    if (id) {
      this.spinnerService.show();
      this.inventoryLogService.getInventoryLogging(inventoryModel)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: (res) => {
            this.inventoryLogCompleted(res);
            this.setInitialValue();
          }, error: this.alertService.showApiError
        });
    } else {
      this.inventoryFinalLog = [];
    }
    this.inventoryLogForm.resetForm();
    this.setInitialValue();
    this.idForDropDown = id;
    this.typeForDropDown = (id == null) ? null : type;
  }

  isAnySectionItemUnSelected() {
    let anySectionItemUnSelected = false;
    forEach(this.inventoryFinalLog, (log) => {
      if (!log.IsChecked) {
        anySectionItemUnSelected = true;
      }
    });
    return anySectionItemUnSelected;
  }

  updateFlagData = () => {
    this.setDropDowns(this.updateType);
    this.idForDropDown = this.updateId;
    this.typeForDropDown = this.updateType;
    this.getInventories(this.updateId, this.updateType);
  }

  checkChange = (selector, value) => {
    this.resetCheck();
    if (selector === 'section') {
      this.checkAllSections = value;
      !this.isAnyItemUnSelected() ? this.selectAllProducts() : null;
      !this.isAnyOverDueItemUnselected() ? this.selectOverdueProducts() : null;
      this.selectAllSections();
    }
    else if (selector === 'product') {
      this.checkAllProducts = value;
      !this.isAnySectionItemUnSelected() ? this.selectAllSections() : null;
      this.selectAllProducts();
    }
    else if (selector === 'overdue') {
      this.checkOverdueProducts = value;
      !this.isAnySectionItemUnSelected() ? this.selectAllSections() : null;
      this.selectOverdueProducts();
    }
  }

  selectAllSections() {
    const unCheckedItemExists = this.isAnySectionItemUnSelected();
    forEach(this.inventoryFinalLog, (inventoryItem) => {
      inventoryItem.IsChecked = unCheckedItemExists;
    });
  }

  selectOverdueProducts() {
    const nonOverDueItemsChecked = this.unCheckNonOverDueItems();
    const unCheckedItemExists = this.isAnyOverDueItemUnselected();
    forEach(this.inventoryFinalLog, (inventoryItem) => {
      forEach(inventoryItem.CategoryList, (category) => {
        if (category.DaysUntilDue <= 0) {
          category.IsUpdated = (unCheckedItemExists || nonOverDueItemsChecked);
        }
      });
    });
  }

  isAnyOverDueItemUnselected() {
    let isAnyOverDueItem = false;
    forEach(this.inventoryFinalLog, (inventoryItem) => {
      forEach(inventoryItem.CategoryList, (category) => {
        if ((!category.IsUpdated && category.DaysUntilDue <= 0) || (category.IsUpdated && this.checkOverdueProducts)) {
          isAnyOverDueItem = true;
        }
      });
    });
    return isAnyOverDueItem;
  }

  productStateChange = (currentState) => {
    this.checkAllProducts = !currentState ? false : this.checkAllProducts;
    this.checkOverdueProducts = !currentState ? false : this.checkOverdueProducts;
  }

  unCheckNonOverDueItems() {
    let nonOverDueItemChecked = false;
    forEach(this.inventoryFinalLog, (log) => {
      forEach(log.CategoryList, (product) => {
        if (product.IsUpdated && product.DaysUntilDue > 0) {
          product.IsUpdated = false;
          nonOverDueItemChecked = true;
        }
      })
    });
    return nonOverDueItemChecked;
  }
  selectAllProducts() {
    const unCheckedItemExists = this.isAnyItemUnSelected();
    forEach(this.inventoryFinalLog, (inventoryItem) => {
      forEach(inventoryItem.CategoryList, (category) => {
        category.IsUpdated = unCheckedItemExists;
      });
    });
  }
  isAnyItemUnSelected() {
    for (let product = 0; product < this.inventoryFinalLog.length; product++) {
      for (let category = 0; category < this.inventoryFinalLog[product].CategoryList.length; category++) {
        if (!this.inventoryFinalLog[product].CategoryList[category].IsUpdated ||
          (this.inventoryFinalLog[product].CategoryList[category].IsUpdated && this.checkAllProducts)) {
          return true;
        }
      }
    }
  }
  searchProduct = debounce(() => {
    this.noRecordMessage = '';
    if (this.filterProduct) {
      if (!this.selectedVendor && !this.selectedCategory && !this.selectedZone && !this.selectedStatus) {
        this.spinnerService.show();
        const inventoryModel = { type: "SearchedText", SearchedText: this.filterProduct, InventoryLocationId: this.selectedLocation ? this.selectedLocation : null };
        this.inventoryLogService.getInventoryLogging(inventoryModel)
          .pipe(finalize(() => {
            this.spinnerService.hide();
          }))
          .subscribe({
            next: (res) => {
              $("#searchFilter").focus();
              this.inventoryLogCompleted(res);
              this.setInitialValue();
            }
          });
      }
    }
    else if (!this.selectedVendor && !this.selectedCategory && !this.selectedZone && !this.selectedStatus) {
      this.inventoryFinalLog = [];
    }

  }, 1000);

  setDropDowns(type) {
    this.filterProduct = '';
    if (type === this.LogProductFilter.VENDOR) {
      this.selectedCategory = null;
      this.selectedZone = null;
      this.selectedStatus = null;
    }
    else if (type === this.LogProductFilter.CATEGORY) {
      this.selectedVendor = null;
      this.selectedZone = null;
      this.selectedStatus = null;
    } else if (type === this.LogProductFilter.INVENTORY_ZONE) {
      this.selectedVendor = null;
      this.selectedCategory = null;
      this.selectedStatus = null;
    } else {
      this.selectedVendor = null;
      this.selectedCategory = null;
      this.selectedZone = null;
    }
  }

  resetDropdown(type) {
    this.filterProduct = '';
    const oldDDLValue = this.typeForDropDown == type ? this.idForDropDown : null;
    if (type === this.LogProductFilter.VENDOR) {
      this.selectedVendor = oldDDLValue;
    }
    else if (type === this.LogProductFilter.CATEGORY) {
      this.selectedCategory = oldDDLValue;
    } else if (type === this.LogProductFilter.INVENTORY_ZONE) {
      this.selectedZone = oldDDLValue;
    } else {
      this.selectedStatus = oldDDLValue;
    }
  }

  checkUpdatedProducts() {
    let products = [];
    let unChangedProducts = [];
    const locationId = this.selectedLocation ? this.selectedLocation : null;
    forEach(this.inventoryFinalLog, (item) => {
      forEach(item.CategoryList, (product) => {
        if (product.IsUpdated) {
          let objProduct = {
            InventoryProductId: product.Id, Qty: product.Qty,
            InventoryLocationId: locationId
          };
          product.InventoryLocationId = locationId;
          products.push(objProduct);
          if (!product.IsChanged) {
            unChangedProducts.push(objProduct);
          }
        }
      });
    });
    const selectedProductCount = products.length;
    const unChangedProductCount = unChangedProducts.length;
    const minUnchangedQtyWarningMinimumThreshold = this.settingParam?.InventoryLogUnchangedQtyWarningMinimumThreshold ? this.settingParam.InventoryLogUnchangedQtyWarningMinimumThreshold : 0;
    const minPercent = this.settingParam?.InventoryLogUnchangedQtyWarningMinimumPercent ? this.settingParam.InventoryLogUnchangedQtyWarningMinimumPercent : 0;
    if (selectedProductCount && minPercent) {
      this.confirmUnchangedInventoryLog(selectedProductCount, minPercent, unChangedProductCount, minUnchangedQtyWarningMinimumThreshold);
    } else {
      this.updateInventory();
    }
  }

  openConfirmationModel = (message: string, callBackFunction: Function, notConfirmCallback: Function) => {
    const modalRef = this.modalService.getModalWrapper(InfoModalComponent);
    const modal = modalRef.show({
      animated: false,
      class: 'vertical-center',
      initialState: {
        message: message,
        confirmButtonText: 'Yes',
        rejectButtonText: 'No',
        modalHeaderText: 'Confirm'
      }
    });

    modal.close.subscribe(res => {
      if (res && (res.shouldConfirm)) {
        callBackFunction();
      } else {
        notConfirmCallback();
      }
    });
  }

  private confirmUnchangedInventoryLog(selectedProductCount: number, minPercent: number, unChangedProductCount: number, minUnchangedQtyWarningMinimumThreshold: number) {
    const unChangedProductPercent = Math.ceil((selectedProductCount * minPercent) / 100);
    if (unChangedProductCount >= minUnchangedQtyWarningMinimumThreshold && unChangedProductCount >= unChangedProductPercent) {
      const message = StringUtils.format(Messages.ConfirmUnchangedInventoryLog,
        { 'productCount': unChangedProductCount });
      this.openConfirmationModel(message, this.updateInventory, () => { });
    } else {
      this.updateInventory();
    }
  }

  updateInventory = () => {
    const products = [];
    forEach(this.inventoryFinalLog, (item) => {
      forEach(item.CategoryList, (product) => {
        if (product.IsUpdated) {
          const objProduct = {
            InventoryProductId: product.Id,
            Qty: product.Qty,
            InventoryLocationId: this.selectedLocation ? this.selectedLocation : null
          };
          product.InventoryLocationId = this.selectedLocation ? this.selectedLocation : null;
          product.InventoryLocation = find(this.inventoryLocations, x => x.Id == this.selectedLocation)?.Name;
          products.push(objProduct);
        }
      });
    });
    if (!this.selectedLocation) {
      this.spinnerService.show();
      this.salesProductService.getInventoryLogStockSalesProducts(products)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: (response: Array<InventoryLogStockSalesProduct>) => {
            this.salesInventoryProducts = response ? response : [];
            if (response && response.length > 0 && response.length >= this.settingParam.InventoryLogStockWarningMinimum) {
              this.openWarningModel();
            } else {
              this.updateInventoryQty();
            }
          }, error: this.alertService.showApiError
        });
    } else {
      this.updateInventoryQty();
    }

  }
  closeWarningModel = () => {
    if (this.salesProductStockWarningModelRef) {
      this.salesProductStockWarningModelRef.hide();
      this.modalBackdropService.removeBackdrop();
    }
  }
  validateProductList() {
    let hasErrorProduct = [];
    if (this.inventoryFinalLog && !this.inventoryFinalLog.length) {
      return true;
    }

    forEach(this.inventoryFinalLog, (category) => {
      if (hasErrorProduct.length > 0) {
        return;
      }
      hasErrorProduct = filter(category.CategoryList, (item) => {
        return (item.IsUpdated && ((item.UnitQtyIncrement != null && this.mathsUtilityService.floatSafeModulus(item.Qty, item.UnitQtyIncrement) != 0) || item.Qty < 0));
      });
    });
    if (hasErrorProduct.length > 0) {
      const txtQtyId = '#txtQty' + hasErrorProduct[0]?.id;
      this.showToolTip(txtQtyId, true);
      return true;
    } else {
      return false;
    }
  }
  showToolTip(controlId, hasError) {
    if (hasError) {
      $(controlId).parent().addClass('has-error');
      $(controlId).tooltip('show');
      $(controlId).focus();
    } else {
      $(controlId).parent().removeClass('has-error');
      $(controlId).tooltip('hide');
    }
  }
  updateInventoryQty() {
    this.closeWarningModel();
    const hasError = this.validateProductList();
    if (!hasError) {
      this.closeWarningModel();
      const inventoryLogModel = {
        InventoryList: this.inventoryFinalLog,
        UserName: this.userDetails.username,
        UserId: this.userDetails.id,
        FilterName: (this.typeForDropDown ? this.typeForDropDown : "SearchedText"),
        FilterValue: this.getFilteredByText(),
        VendorName: 'true'
      };
      this.spinnerService.show();
      this.inventoryLogService.updateInventoryLog(inventoryLogModel)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: (res) => {
            this.updateFlagForItem = false;
            this.updateFlag = false;
            this.selectedVendor = null;
            this.selectedCategory = null;
            this.selectedZone = null;
            this.selectedStatus = null;
            this.inventoryFinalLog = [];
            this.resetCheck();
            this.filterProduct = "";
            this.idForDropDown = null;
            this.typeForDropDown = '';
            this.applicationStateService.invId = this.idForDropDown;
            this.applicationStateService.invType = this.typeForDropDown;
            this.applicationStateService.updateFlag = true;
            this.inventoryLogForm.reset();
          }, error: this.alertService.showApiError
        });
    }
  }
  openWarningModel = () => {
    this.salesProductStockWarningModelRef = this.bsModalService.show(this.salesProductStockWarning, {
      'backdrop': 'static',
      'class': 'vertical-center',
      keyboard: false
    });
    this.modalBackdropService.addBackDrop();
  }
  printInventory() {
    const printingInventory = filter(this.inventoryFinalLog, (inventoryItem) => {
      return (inventoryItem.IsChecked);
    });

    if (printingInventory.length > 0) {

      let params: any = this.settingParam;
      params.UserId = this.userDetails.id;
      params.CurrentUser = this.userDetails.username;
      const printInventoryModel = {
        InventoryList: printingInventory,
        TerminalName: this.settingParam.TerminalName,
        PrinterName: this.settingParam.ReceiptPrinter.Name,
        TerminalId: this.applicationStateService.terminalId,
        FilterType: this.getFilterType(),
        FilteredBy: this.getFilteredByText(),
        objParam: params
      };
      this.spinnerService.show();
      this.inventoryLogService.printInventory(printInventoryModel)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: () => {
          }, error: this.alertService.showApiError
        });
    }
    else {
      const modalRef = this.modalService.getModalWrapper(InfoModalComponent);
      const modal = modalRef.show({
        animated: false,
        class: 'vertical-center',
        initialState: {
          message: Messages.SelectAtLeastOneCheckbox
        }
      });

      modal.close.subscribe(res => {
      });
    }
  }
  getFilterType() {
    if (this.typeForDropDown) {
      return (this.typeForDropDown === this.LogProductFilter.INVENTORY_ZONE) ? "Zone" : this.typeForDropDown;
    }
    else if (this.filterProduct) {
      return "SearchedText";
    }
  }
  getFilteredByText() {
    if (this.typeForDropDown === this.LogProductFilter.INVENTORY_ZONE) {
      const zone = find(this.inventoryZones, (item) => {
        return (item.Id === this.idForDropDown);
      });
      return zone ? zone.Name : ''
    } else if (this.typeForDropDown === this.LogProductFilter.VENDOR) {
      const vendor = find(this.vendors, (item) => {
        return (item.Id === this.idForDropDown);
      });
      return vendor ? vendor.Name : ''
    }
    else if (this.typeForDropDown === this.LogProductFilter.CATEGORY) {
      const category = find(this.inventoryCategories, (item) => {
        return (item.Id === this.idForDropDown);
      });
      return category ? category.Name : ''
    }
    else if (this.typeForDropDown === "Status") {
      const status = find(this.inventoryStatus, (item) => {
        if (this.idForDropDown) {
          return (item.Id === this.idForDropDown);
        }
        else {
          return (item.Id === this.idForDropDown);
        }
      });
      return status ? status.Name : '';
    } else if (this.filterProduct) {
      return this.filterProduct;
    }
  }

  inventoryLogCompleted(inventoryLog) {
    this.logInventories = [];
    let categoryItemList = [];
    let categoryName = '';
    this.inventoryFinalLog = [];
    let inventoryFinal = { CategoryName: '', IsChecked: false, CategoryList: [] };
    if (this.typeForDropDown !== this.LogProductFilter.INVENTORY_ZONE) {
      forEach(inventoryLog, (item) => {

        if (categoryName === item.Category + " - " + item.SubCategory || item.Id === inventoryLog[0].Id) {
          categoryItemList.push(item);
          inventoryFinal.CategoryName = item.Category + " - " + item.SubCategory;
          inventoryFinal.IsChecked = false;
          categoryName = item.Category + " - " + item.SubCategory;
        } else {
          inventoryFinal.CategoryList = categoryItemList;
          inventoryFinal.IsChecked = false;
          this.inventoryFinalLog.push(inventoryFinal);
          inventoryFinal = { CategoryName: '', IsChecked: false, CategoryList: [] };
          inventoryFinal.CategoryList = [];
          categoryItemList = [];
          categoryItemList.push(item);
          inventoryFinal.CategoryName = item.Category + " - " + item.SubCategory;
          inventoryFinal.IsChecked = false;
          categoryName = item.Category + " - " + item.SubCategory;
        }
      });
    } else {

      forEach(inventoryLog, (item) => {
        if (categoryName === item.InventoryBin || item.Id === inventoryLog[0].Id) {
          categoryItemList.push(item);
          inventoryFinal.CategoryName = item.InventoryBin;
          inventoryFinal.IsChecked = false;
          categoryName = item.InventoryBin;
        } else {
          inventoryFinal.CategoryList = categoryItemList;
          inventoryFinal.IsChecked = false;
          this.inventoryFinalLog.push(inventoryFinal);
          inventoryFinal = { CategoryName: '', IsChecked: false, CategoryList: [] };
          inventoryFinal.CategoryList = [];
          categoryItemList = [];
          categoryItemList.push(item);
          inventoryFinal.CategoryName = item.InventoryBin;
          inventoryFinal.IsChecked = false;
          categoryName = item.InventoryBin;
        }
      });
    }
    if ((this.idForDropDown != null || this.filterProduct) && inventoryLog.length > 0) {
      inventoryFinal.CategoryList = categoryItemList;
      this.inventoryFinalLog.push(inventoryFinal);
      this.resetCheck();
    } else {
      if (this.typeForDropDown === this.LogProductFilter.INVENTORY_ZONE) {
        forEach(this.inventoryZones, (item) => {
          if (item.Id === this.idForDropDown) {
            this.noRecordMessage = StringUtils.format(Messages.noInventoryRecord,
              { 'message': 'at ' + item.Name });
          }
        });
      } else if (this.typeForDropDown === this.LogProductFilter.VENDOR) {
        forEach(this.vendors, (item) => {
          if (item.Id === this.idForDropDown) {
            this.noRecordMessage = StringUtils.format(Messages.noInventoryRecord,
              { 'message': 'for ' + item.Name });
          }
        });
      }
      else if (this.typeForDropDown === this.LogProductFilter.CATEGORY) {
        forEach(this.inventoryCategories, (item) => {
          if (item.Id === this.idForDropDown) {
            this.noRecordMessage = StringUtils.format(Messages.noInventoryRecord,
              { 'message': 'for ' + item.Name });
          }
        });
      }
    }
    this.applicationStateService.invId = this.idForDropDown;
    this.applicationStateService.invType = this.typeForDropDown;
    this.applicationStateService.updateFlag = true;
  }

  clearLogSearchFilter() {
    this.filterProduct = '';
    if (!this.selectedVendor && !this.selectedCategory && !this.selectedZone && !this.selectedStatus) {
      this.resetCheck();
      this.inventoryFinalLog = [];
    }
  }

  getVendorAndCategory() {
    this.spinnerService.show();
    this.inventoryLogService.getVendorAndCategory()
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (response: VendorAndCategory) => {
          this.vendors = response.VendorModel;
          this.inventoryCategories = response.CategoryModel;

          if (this.applicationStateService.updateFlag) {
            this.typeForDropDown = this.applicationStateService.invType;
            this.idForDropDown = this.applicationStateService.invId;

            if (this.typeForDropDown === this.LogProductFilter.VENDOR) {
              this.selectedCategory = null;
              this.selectedZone = null;
              this.selectedStatus = null;
              this.selectedVendor = this.idForDropDown;
              this.showInventories(this.idForDropDown, this.LogProductFilter.VENDOR);
            }
            else if (this.typeForDropDown === this.LogProductFilter.CATEGORY) {
              this.selectedVendor = null;
              this.selectedZone = null;
              this.selectedStatus = null;
              this.selectedCategory = this.idForDropDown;
              this.showInventories(this.idForDropDown, this.LogProductFilter.CATEGORY);
            } else if (this.typeForDropDown === this.LogProductFilter.INVENTORY_ZONE) {
              this.selectedVendor = null;
              this.selectedCategory = null;
              this.selectedStatus = null;
              this.selectedZone = this.idForDropDown;
              this.showInventories(this.idForDropDown, this.LogProductFilter.INVENTORY_ZONE);
            } else {
              this.selectedVendor = null;
              this.selectedCategory = null;
              this.selectedZone = null;
              if (!this.idForDropDown) {
                this.selectedStatus = null;
              } else {
                this.selectedStatus = this.idForDropDown;
              }
              this.showInventories(this.idForDropDown, "Status");
            }

          }
        }, error: this.alertService.showApiError
      });
  }

  cancel() {
    this.router.navigate(['/manage/console'], { relativeTo: this.route });
  }
}
