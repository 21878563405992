
//
import {
    SalesInventoryActivationComponent
} from './sales-products/component/sales-inventory-activation/sales-inventory-activation.component';
import { AccountAttributeTypeService } from './accounts/account-attribute-types/services/account-attribute-type.service';
import { AccountAttributesComponent } from './accounts/account-attributes/components/account-attributes.component';
import { AccordionModule } from 'primeng/accordion';
import { MergeRawIngredientComponent } from './raw-ingredients/component/merge-raw-ingredient/merge-raw-ingredient.component';
import {
    InventoryProductsIngredientComponent
} from './raw-ingredients/component/inventory-products-ingredient/inventory-products-ingredient.component';
import {
    SalesProductsIngredientComponent
} from './raw-ingredients/component/sales-products-ingredient/sales-products-ingredient.component';
import { SalesProductAliasComponent } from './sales-products/component/sales-product-alias/sales-product-alias.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SalesSizeListComponent } from './sales-sizes/components/sales-size-list/sales-size-list.component';
import { SalesSizeEditComponent } from './sales-sizes';
import { ComponentsModule, TableConfiguration } from '../shared';
import { ModalModule, BsModalRef } from 'ngx-bootstrap/modal';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { CommentWarningsListComponent } from './comment-warnings/components/comment-warnings-list/comment-warnings-list.component';
import { CommentWarningEditComponent } from './comment-warnings/components/comment-warning-edit/comment-warning-edit.component';
import { NgPipesModule } from 'ngx-pipes';
import { INFORMATION_MANAGEMENT_CHILDREN, INFORMATION_MANAGEMENT_STATES } from './information-management.routes';
import { ProductLossReasonsComponent, ProductLossReasonService, ProductLossReasonEditComponent } from './product-loss-reasons';
import { RawIngredientsEditComponent, RawIngredientsListComponent } from './raw-ingredients/component';
import { RawIngredientsService } from './raw-ingredients/service';
import { TableModule } from '@tarktech/tark-ng-utils';
import { CalendarModule } from 'primeng/calendar';
import { TableModule as PrimengTableModule } from 'primeng/table';
import { InventoryProductIngredientsService } from './inventory-product-ingredients';
import { DropdownModule } from 'primeng/dropdown';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CheckboxModule } from 'primeng/checkbox';
import {
    ServicingInformationService
} from './nutrition-facts/serving-information';
import { TaskListComponent } from './tasks/component';
import { TaskEditComponent } from './tasks/component/task-edit/task-edit.component';
import { TaskScheduleComponent } from './tasks/component/task-schedule/task-schedule.component';
import { InventoryProductNutritionService } from './nutrition-facts/nutrition-information';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TrainingAreasListComponent } from './training-areas/components/training-areas-list/training-areas-list.component';
import { TrainingAreasEditComponent } from './training-areas/components/training-areas-edit/training-areas-edit.component';
import { TrainingTopicListComponent } from './training-topics/components/training-topic-list/training-topic-list.component';
import { TrainingTopicEditComponent } from './training-topics/components/training-topic-edit/training-topic-edit.component';
import { TrainingBadgeListComponent } from './training-badges/components/training-badge-list/training-badge-list.component';
import { TrainingBadgeEditComponent } from './training-badges/components/training-badge-edit/training-badge-edit.component';
import { TrainingBadgeService } from './training-badges/services/training-badge.service';
import { TrainingAreaModelComponent } from './training-areas/components/training-area-model/training-area-model.component';
import { TrainingAreaPageComponent } from './training-areas/components/training-area-page/training-area-page.component';
import {
    TrainingTopicLevelListComponent
} from './training-topic-levels/components/training-topic-level-list/training-topic-level-list.component';
import {
    TrainingTopicLevelEditComponent
} from './training-topic-levels/components/training-topic-level-edit/training-topic-level-edit.component';
import { TrainingLevelMoveComponent } from './training-topic-levels';
import { TrainingTopicComponent } from './training-topics/components/training-topic/training-topic.component';
import { TrainingAreaComponent } from './training-areas/components/training-area/training-area.component';
import { OnlineOrderingSettingsComponent, OnlineOrderingCategoriesComponent } from './online-ordering';
import {
    OnlineOrderingCategoryEditComponent
} from './online-ordering/component/online-ordering-categories/online-ordering-category-edit/online-ordering-category-edit.component';
import {
    OnlineOrderingOptionListComponent
} from './online-ordering/component/online-ordering-options/online-ordering-option-list/online-ordering-option-list.component';
import {
    OnlineOrderingOptionEditComponent
} from './online-ordering/component/online-ordering-options/online-ordering-option-edit/online-ordering-option-edit.component';
import {
    OnlineOrderingOptionProductConfigurationComponent
} from './online-ordering/component/online-ordering-options/online-ordering-option-product-configuration/online-ordering-option-product-configuration.component';
import {
    OnlineOrderingCategoryProductComponent
} from './online-ordering/component/online-ordering-categories/online-ordering-category-product/online-ordering-category-product.component';
import {
    OnlineOrderingConfigurationsComponent
} from './online-ordering/component/online-ordering-configurations/online-ordering-configurations.component';

import { ColorPickerModule } from 'ngx-color-picker';
import { CKEditorModule } from 'ckeditor4-angular';
import { InventoryScheduleEditorComponent } from './inventory-schedule-editor/inventory-schedule-editor.component';
import {
    CopyInventoryIngredientsInfoComponent
} from './copy-inventory-ingredients-info/component/copy-inventory-ingredients-info.component';
import { AudioNotificationComponent } from './audio-notification-files/components/audio-notification/audio-notification.component';
import {
    AudioNotificationEditComponent
} from './audio-notification-files/components/audio-notification-edit/audio-notification-edit.component';
import {
    AccountAttributesViewComponent
} from './accounts/account-attributes/components/account-attributes-view/account-attributes-view.component';
import { AccountOrdersComponent } from './accounts/account-orders/account-orders.component';
import { ScreenEditComponent } from './screen/components/screen-edit/screen-edit.component';
import { TrainingTopicMoveComponent } from './training-topics';
import { CopySecurityRoleComponent } from './security-roles/component/copy-security-role/copy-security-role.component';
import { SecurityRoleService } from './security-roles/service/security-role.service';
import {
    AccountAttributeTypesComponent
} from './customers/account-attribute-types/components/account-attribute-types/account-attribute-types.component';
import {
    AccountAttributeTypeEditComponent
} from './customers/account-attribute-types/components/account-attribute-type-edit/account-attribute-type-edit.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PrepLabelPrintComponent } from './prep-label-print/component/prep-label-print.component';
import { SalesGroupsComponent } from './sales-groups/component/sales-groups/sales-groups.component';
import { SalesGroupEditComponent } from './sales-groups/component/sales-group-edit/sales-group-edit.component';
import { TerminalsService } from '../configurator/terminals/services/terminals.service';
import { SalesCategoriesComponent } from './sales-categories/component/sales-categories/sales-categories.component';
import { SalesCategoryEditComponent } from './sales-categories/component/sales-category-edit/sales-category-edit.component';
import { VendorEditComponent } from './vendors/component/vendor-edit/vendor-edit.component';
import { VendorsListComponent } from './vendors/component/vendors-list/vendors-list.component';
import { VendorService } from './vendors/service/vendor.service';
import {
    InventoryLogDueDateOverrideEditComponent
} from './vendors/component/inventory-log-due-date-override-edit/inventory-log-due-date-override-edit.component';
import { InventoryProductService } from '../shared/services/inventory-product.service';
import { InventoryCategoryListComponent } from './inventory-categories/component/inventory-category-list/inventory-category-list.component';
import { InventoryCategoryEditComponent } from './inventory-categories/component/inventory-category-edit/inventory-category-edit.component';
import { InventoryCategoryService } from './inventory-categories/service/inventory-category.service';
import { UnitEditComponent } from './units/components/unit-edit/unit-edit.component';
import { UnitsListComponent } from './units/components/units-list/units-list.component';
import { UnitService } from './units';
import { SalesGroupEditWrapComponent } from './sales-groups/component/sales-group-edit-wrap/sales-group-edit-wrap.component';
import { SalesGroupEditModelComponent } from './sales-groups/component/sales-group-edit-model/sales-group-edit-model.component';
import { InventoryZoneListComponent } from './inventory-zone/components/inventory-zone-list/inventory-zone-list.component';
import {
    InventoryProductsForZoneComponent
} from './inventory-zone/components/inventory-products-for-zone/inventory-products-for-zone.component';
import { InventorySubCategoryService } from './inventory-subcategories/service/inventory-subcategory.service';
import {
    InventorySubcategoryListComponent
} from './inventory-subcategories/component/inventory-subcategory-list/inventory-subcategory-list.component';
import {
    InventorySubcategoryEditComponent
} from './inventory-subcategories/component/inventory-subcategory-edit/inventory-subcategory-edit.component';
import { PrintingService } from './inventory-subcategories/service/printing.service';
import { InventoryZoneEditComponent } from './inventory-zone/components/inventory-zone-edit/inventory-zone-edit.component';
import { InventoryZoneEditProductsComponent } from './inventory-zone/components/inventory-zone-edit/inventory-zone-edit-products/inventory-zone-edit-products.component';
import { InventoryZoneEditDetailsComponent } from './inventory-zone/components/inventory-zone-edit/inventory-zone-edit-details/inventory-zone-edit-details.component';
import { InventoryZoneEditBinsComponent } from './inventory-zone/components/inventory-zone-edit/inventory-zone-edit-bins/inventory-zone-edit-bins.component';
import { InventoryZoneBinLightComponent } from './inventory-zone/components/inventory-zone-edit/inventory-zone-edit-bins/inventory-zone-bin-light/inventory-zone-bin-light.component';
import { InventoryZoneBinsEditModelComponent } from './inventory-zone/components/inventory-zone-edit/inventory-zone-edit-bins/inventory-zone-bins-edit-model/inventory-zone-bins-edit-model.component';
import { SecurityRolesListComponent } from './security-roles/component/security-roles-list/security-roles-list.component';
import { SecurityRoleEditComponent } from './security-roles/component/security-role-edit/security-role-edit.component';
import { ButtonListComponent } from './buttons/component/button-list/button-list.component';
import { ButtonEditComponent } from './buttons/component/button-edit/button-edit.component';
import { DaypartsService, ExternalApiViewComponent } from '../configurator';
import { InfoLinkSectionsComponent } from './info-link-section/components/info-link-sections/info-link-sections.component';
import { InfoLinkSectionsEditComponent } from './info-link-section/components/info-link-sections-edit/info-link-sections-edit.component';
import { InfoLinkSectionsService } from './info-link-section/service';
import { InfoLinksComponent } from './info-link/components/info-links/info-links.component';
import { InfoLinksEditComponent } from './info-link/components/info-links-edit/info-links-edit.component';
import { InfoLinkService } from './info-link';
import { UserListComponent } from './users/component/user-list/user-list.component';
import { UserEditComponent } from './users/component/user-edit/user-edit.component';
import { UserPayRatesAddEditComponent } from './users/component/user-pay-rates-add-edit/user-pay-rates-add-edit.component';
import { InfractionService } from './infractions/service/infraction.service';
import { InfractionsListComponent } from './infractions/component/infractions-list/infractions-list.component';
import { InfractionEditComponent } from './infractions/component/infraction-edit/infraction-edit.component';
import { LoginMessagesComponent } from './login-messages/components/login-messages/login-messages.component';
import { LoginMessagesEditComponent } from './login-messages/components/login-messages-edit/login-messages-edit.component';
import { LoginMessagesService } from './login-messages';
import { ImportWorkScheduleComponent } from './work-schedule/components/import-work-schedule/import-work-schedule.component';
import { WorkScheduleListComponent } from './work-schedule/components/work-schedule-list/work-schedule-list.component';
import { WorkScheduleEditComponent } from './work-schedule/components/work-schedule-edit/work-schedule-edit.component';
import { WorkScheduleTagListComponent } from './work-schedule/components/work-schedule-tag-list/work-schedule-tag-list.component';
import { WorkScheduleTagEditComponent } from './work-schedule/components/work-schedule-tag-edit/work-schedule-tag-edit.component';
import { WorkScheduleBatchEntryComponent } from './work-schedule/components/work-schedule-batch-entry/work-schedule-batch-entry.component';
import { UserWorkScheduleComponent } from './work-schedule/components/user-work-schedule/user-work-schedule.component';
import { JobCodeListComponent } from './job-code/component/job-code-list/job-code-list.component';
import { JobCodeEditComponent } from './job-code/component/job-code-edit/job-code-edit.component';
import { JobCodeService } from './job-code/service/job-code.service';
import { CashPayoutListComponent } from './cash-payout/component/cash-payout-list/cash-payout-list.component';
import { EditCashPayoutComponent } from './cash-payout/component/edit-cash-payout/edit-cash-payout.component';
import { CashPayoutService } from './cash-payout/service/cash-payout.service';
import { CashDrawerEntriesService } from './cash-drawer-entries/service/cash-drawer-entries.service';
import { CashDrawerEntriesComponent } from './cash-drawer-entries/component/cash-drawer-entries/cash-drawer-entries.component';
import { AccountCategoryListComponent } from './account-categories/component/account-category-list/account-category-list.component';
import { EditAccountCategoryComponent } from './account-categories/component/edit-account-category/edit-account-category.component';
import { AccountsListComponent } from './account/component/accounts-list/accounts-list.component';
import { AccountEditComponent } from './account/component/account-edit/account-edit.component';
import { EquipmentTypeService } from './equipment-types/service/equipment-type.service';
import { EquipmentTypeListComponent } from './equipment-types/component/equipment-type-list/equipment-type-list.component';
import { EquipmentTypeEditComponent } from './equipment-types/component/equipment-type-edit/equipment-type-edit.component';
import { EquipmentPropertyService } from './equipment-properties/service/equipment-property.service';
import { EquipmentPropertyListComponent } from './equipment-properties/component/equipment-property-list/equipment-property-list.component';
import { EquipmentPropertyEditComponent } from './equipment-properties/component/equipment-property-edit/equipment-property-edit.component';
import { SalesProductEditComponent } from './sales-products/component/sales-product-edit/sales-product-edit.component';
import { SalesProductQuickReportComponent } from './sales-products/component/sales-product-quick-report/sales-product-quick-report.component';
import { SalesProductDefaultButtonComponent } from './sales-products/component/sales-product-default-button/sales-product-default-button.component';
import { SalesProductRecipeListComponent } from './sales-products/component/sales-product-recipe-list/sales-product-recipe-list.component';
import { EquipmentListComponent } from './equipment/component/equipment-list/equipment-list.component';
import { EquipmentEditComponent } from './equipment/component/equipment-edit/equipment-edit.component';
import { ScreenListComponent } from './screen/components/screen-list/screen-list.component';
import { InventoryExhaustionModule } from './inventory-products/components/inventory-exhaustion/inventory-exhaustion.module';

import { TimeClockEntriesListComponent } from './time-clock-entries/components/time-clock-entries-list/time-clock-entries-list.component';
import { TimeClockEntriesEditComponent } from './time-clock-entries/components/time-clock-entries-edit/time-clock-entries-edit.component';
import { TimeClockEntriesService } from './time-clock-entries/service/time-clock-entries.service';
import { SalesProductRecipeEditComponent } from './sales-products/component/sales-product-recipe-edit/sales-product-recipe-edit.component';
import { SalesProductPriceComponent } from './sales-products/component/sales-product-price/sales-product-price.component';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { SalesCategoryEditWrapComponent } from './sales-categories/component/sales-category-edit-wrap/sales-category-edit-wrap.component';
import { SalesCategoryEditModelComponent } from './sales-categories/component/sales-category-edit-model/sales-category-edit-model.component';
import { SalesProductListComponent } from './sales-products/component/sales-product-list/sales-product-list.component';
import { SalesProductShowRecipeComponent } from './sales-products/component/sales-product-show-recipe/sales-product-show-recipe.component';
import { PrepRecipeListComponent } from './inventory-products/components/prep-recipe-list/prep-recipe-list.component';
import { PrepRecipeEditComponent } from './inventory-products/components/prep-recipe-edit/prep-recipe-edit.component';
import { InventoryReceivedComponent } from './inventory-products/components/inventory-received/inventory-received.component';
import { RecipesIncludedComponent } from './inventory-products/components/recipes-included/recipes-included.component';
import { MergeProductComponent } from './inventory-products/components/merge-product/merge-product.component';
import { RecipeEditorComponent } from './inventory-products/components/recipe-editor/recipe-editor.component';
import { MergeSalesProductComponent } from './sales-products/component/merge-sales-product/merge-sales-product.component';
import { CopySalesProductComponent } from './sales-products/component/copy-sales-product/copy-sales-product.component';
import { InventoryProductListComponent } from './inventory-products/components/inventory-product-list/inventory-product-list.component';
import { SalesProductListAllButtonsComponent } from './sales-products/component/sales-product-list-all-buttons/sales-product-list-all-buttons.component';
import { SalesProductEditWrapComponent } from './sales-products/component/sales-product-edit-wrap/sales-product-edit-wrap.component';
import { SalesProductEditModelComponent } from './sales-products/component/sales-product-edit-model/sales-product-edit-model.component';
import { InventoryProductEditWrapComponent } from './inventory-products/components/inventory-product-edit-wrap/inventory-product-edit-wrap.component';
import { ShowConsumptionComponent } from './inventory-products/components/show-consumption/show-consumption.component';
import { InventoryProductsForBinComponent } from './inventory-zone/components/inventory-zone-edit/inventory-products-for-bin/inventory-products-for-bin.component';
import { InformationManagementBaseComponent } from './information-management-base/information-management-base.component';
import { SalesProductInstructionConfigurationComponent } from './sales-products/component/sales-product-instruction-configuration/sales-product-instruction-configuration.component';
import { ImportRecipeComponent } from './sales-products/component/import-recipe/import-recipe.component';
import { CopyInstructionsComponent } from './sales-products/component/copy-instructions/copy-instructions.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AccountEditModelComponent } from './account/component/account-edit-model/account-edit-model.component';
import { AccountEditWrapperComponent } from './account/component/account-edit-wrapper/account-edit-wrapper.component';
import { AccountDetailViewComponent } from './account/component/account-detail-view/account-detail-view.component';
import { DuplicateIngredientListComponent } from './inventory-products/components/duplicate-ingredient-list/duplicate-ingredient-list.component';
import { LabelPrintIntermediaryComponent } from './inventory-products/components/label-print-intermediary/label-print-intermediary.component';

import { SalesUnitsListComponent } from './sales-units/components/sales-units-list/sales-units-list.component';
import { SalesUnitEditComponent } from './sales-units/components/sales-unit-edit/sales-unit-edit.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { SalesProductDashboardComponent } from './sales-products/component/sales-product-dashboard/sales-product-dashboard.component';

import { InventoryProductDashboardComponent } from './inventory-products/components/inventory-product-dashboard/inventory-product-dashboard.component';
import { ManagementDashboardModule } from '../management-dashboard';
import { InventoryProductEditModelModule } from './inventory-products/components/inventory-product-edit-model/inventory-product-edit-model.module';
import { ImportRawIngredientsComponent } from './inventory-product-ingredients/component/import-raw-ingredients/import-raw-ingredients.component';
import { ImportIngredientPreviewComponent } from './inventory-product-ingredients/component/import-ingredient-preview/import-ingredient-preview.component';
import { TagsListComponent } from './tags/component/tags-list/tags-list.component';
import { TagsEditComponent } from './tags/component/tags-edit/tags-edit.component';
import { DisplayMessageComponent } from './buttons/component/display-message/display-message.component';
import { SelectButtonComponent } from './buttons/component/select-button/select-button.component';
import { FlashButtonComponent } from './buttons/component/flash-button/flash-button.component';
import { TemporaryNavigationComponent } from './buttons/component/temporary-navigation/temporary-navigation.component';
import { ButtonInventoryIncrementDecrementComponent } from './buttons/component/button-inventory-increment-decrement/button-inventory-increment-decrement.component';
import { ButtonBehaviorEditComponent } from './buttons/component/button-behavior-edit/button-behavior-edit.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { RevenueCenterComponent } from './buttons/component/revenue-center/revenue-center.component';
import { InventoryVendorProfileProductsComponent } from './vendors/component/inventory-vendor-profile-products/inventory-vendor-profile-products.component';
import { InventoryVendorProfilesComponent } from './vendors/component/inventory-vendor-profiles/inventory-vendor-profiles.component';
import { InventoryVendorProfileEditComponent } from './vendors/component/inventory-vendor-profile-edit/inventory-vendor-profile-edit.component';
import { InventoryVendorProfileProductEditComponent } from './vendors/component/inventory-vendor-profile-product-edit/inventory-vendor-profile-product-edit.component';
import { InventoryProductHistoryListComponent } from './inventory-products/components/inventory-product-history-list/inventory-product-history-list.component';
import { InventoryProductHistoryEditComponent } from './inventory-products/components/inventory-product-history-edit/inventory-product-history-edit.component';
import { ScheduleOverridesComponent } from './online-ordering/component/schedule-overrides/schedule-overrides.component';
import { ScheduleOverrideEditComponent } from './online-ordering/component/schedule-override-edit/schedule-override-edit.component';
import { SalesProductRecipeSwapComponent } from './sales-products/component/sales-product-recipe-swap/sales-product-recipe-swap.component';
import { ButtonHistoryComponent } from './buttons/component/button-history/button-history.component';
import { ScreenHistoryComponent } from './screen/components/screen-history/screen-history.component';
import { SalesProductHistoryComponent } from './sales-products/component/sales-product-history/sales-product-history.component';
import { ButtonHistoryEditComponent } from './buttons/component/button-history-edit/button-history-edit.component';
import { SalesProductHistoryEditComponent } from './sales-products/component/sales-product-history-edit/sales-product-history-edit.component';
import { SalesProductSizeHistoryComponent } from './sales-products/component/sales-product-size-history/sales-product-size-history.component';
import { ScreenHistoryEditComponent } from './screen/components/screen-history-edit/screen-history-edit.component';
import { SalesProductSizeHistoryEditComponent } from './sales-products/component/sales-product-size-history-edit/sales-product-size-history-edit.component';
import { InventoryProductsBulkUpdateComponent } from './inventory-products/components/inventory-products-bulk-update/inventory-products-bulk-update.component';
import { SalesProductsBulkUpdateComponent } from './sales-products/component/sales-products-bulk-update/sales-products-bulk-update.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { SingularFeeComponent } from './buttons/component/singular-fee/singular-fee.component';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouteConfigService } from '../shared/services/route-config.service';
import { CopyProductRecipeModalComponent } from './sales-products/component/copy-product-recipe-modal/copy-product-recipe-modal.component';
import { PrepRecipeEditModalComponent } from './inventory-products/components/prep-recipe-edit/prep-recipe-edit-modal/prep-recipe-edit-modal.component';
import { InventoryProductMemoComponent } from './inventory-products/components/inventory-product-memo/inventory-product-memo.component';
import { InventoryProductLogModalComponent } from './inventory-products/components/inventory-product-log-modal/inventory-product-log-modal.component';
import { PermissionNodeComponent } from './security-roles/component/permission-node/permission-node.component';
import { NgxContextModule } from '@tarktech/ngx-context';
import { TooltipModule as PTooltipModule } from 'primeng/tooltip';
import { PermissionsListComponent } from './permissions/components/permissions-list/permissions-list.component';
import { PermissionsEditComponent } from './permissions/components/permissions-edit/permissions-edit.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { WaitTimeRulesComponent, WaitTimeRuleEditComponent } from './wait-time/components';
import { InventoryOffSiteLocationsComponent } from './inventory-off-site-locations/components/inventory-off-site-locations/inventory-off-site-locations.component';
import { InventoryOffSiteLocationEditComponent } from './inventory-off-site-locations/components/inventory-off-site-location-edit/inventory-off-site-location-edit.component';
import { OffsiteInventoryProductSettingsComponent } from './inventory-off-site-locations/components/offsite-inventory-product-settings/offsite-inventory-product-settings.component';
import { MultipleProductComponent } from './buttons/component/multiple-product/multiple-product.component';
import { AnnouncementScheduleComponent } from './online-ordering/component/announcement-schedule/announcement-schedule.component';
import { AnnouncementScheduleEditComponent } from './online-ordering/component/announcement-schedule-edit/announcement-schedule-edit.component';
import { MultiProductNextScreenComponent } from './screen/components/multi-product-next-screen/multi-product-next-screen.component';
import { MultiProductGroupComponent } from './screen/components/multi-product-group/multi-product-group.component';
import { MultiProductGroupListComponent } from './screen/components/multi-product-group-list/multi-product-group-list.component';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { GooglePayConfigurationComponent } from './online-ordering/component/google-pay-configuration/google-pay-configuration.component';
import { ApplePayConfigurationComponent } from './online-ordering/component/apple-pay-configuration/apple-pay-configuration.component';
import { StepsModule } from 'primeng/steps';
import { SalesProductBarcodesComponent } from './sales-products/component/sales-product-barcodes/sales-product-barcodes.component';
import { BarcodeProductSelectionComponent } from './sales-products/component/barcode-product-selection/barcode-product-selection.component';
import { AutoDiscountComponent } from './buttons/component/auto-discount/auto-discount.component';
import { AutoMarkupComponent } from './buttons/component/auto-markup/auto-markup.component';
import { SetSizeComponent } from './buttons/component/set-size/set-size.component';
import { MainProductComponent } from './buttons/component/main-product/main-product.component';
import { DetailEntryComponent } from './buttons/component/detail-entry/detail-entry.component';
import { LocationAddressComponent } from './online-ordering/component/location-address/location-address.component';
import { LocationAddressEditComponent } from './online-ordering/component/location-address/location-address-edit/location-address-edit.component';
import { AddCommentComponent } from './buttons/component/add-comment/add-comment.component';
import { FilterProductComponent } from './buttons/component/filter-product/filter-product.component';
import { OnlineOrderingTermsConditionsComponent } from './online-ordering/component/online-ordering-terms-conditions/online-ordering-terms-conditions.component';
import { EditorModule } from 'primeng/editor';
import { InventorySalesStockWarningComponent } from './inventory-products/components/inventory-sales-stock-warning/inventory-sales-stock-warning.component';
import { DietaryWarningListComponent } from './dietary-warnings/components/dietary-warning-list/dietary-warning-list.component';
import { DietaryWarningEditComponent } from './dietary-warnings/components/dietary-warning-edit/dietary-warning-edit.component';
import { SalesCategorySizeEditComponent } from './sales-categories/component/sales-category-size-edit/sales-category-size-edit.component';

RouteConfigService.addGuardsToRoutes(INFORMATION_MANAGEMENT_CHILDREN);

@NgModule({
    schemas:[CUSTOM_ELEMENTS_SCHEMA],
    imports: [
        InventoryExhaustionModule,
        InventoryProductEditModelModule,
        CommonModule,
        NgSelectModule,
        FormsModule,
        ModalModule.forRoot(),
        ComponentsModule,
        RouterModule.forChild(INFORMATION_MANAGEMENT_STATES),
        NgPipesModule,
        TableModule.forRoot(TableConfiguration),
        CalendarModule,
        ColorPickerModule,
        PrimengTableModule,
        DropdownModule,
        AutoCompleteModule,
        CheckboxModule,
        PopoverModule.forRoot(),
        AccordionModule,
        CKEditorModule,
        NgxSliderModule,
        TooltipModule.forRoot(),
        TriStateCheckboxModule,
        DragDropModule,
        MultiSelectModule,
        // AdfModule
        ManagementDashboardModule,
        BsDropdownModule.forRoot(),
        NgxContextModule,
        PTooltipModule,
        FullCalendarModule,
        NgbDropdownModule,
        StepsModule,
        ButtonBehaviorEditComponent,
        EditorModule
    ],
    providers: [ProductLossReasonService, RawIngredientsService, ServicingInformationService, InventoryProductIngredientsService,
        InventoryProductNutritionService, TrainingBadgeService, AccountAttributeTypeService, SecurityRoleService,
        TerminalsService, VendorService, InventoryProductService, InventoryCategoryService, UnitService, InventorySubCategoryService,
        PrintingService, InfoLinkSectionsService, InfoLinkService, InfractionService, LoginMessagesService, JobCodeService,
        CashPayoutService, CashDrawerEntriesService, EquipmentTypeService, EquipmentPropertyService,
        TimeClockEntriesService, TerminalsService, DaypartsService,
        { provide: BsModalRef, useClass: BsModalRef }],
    declarations: [SalesSizeListComponent, SalesSizeEditComponent, CommentWarningsListComponent,
        CommentWarningEditComponent, ProductLossReasonsComponent, ProductLossReasonEditComponent,
        TaskListComponent, TaskEditComponent, TaskScheduleComponent, SalesProductAliasComponent,
        InventoryProductsIngredientComponent, SalesProductsIngredientComponent, MergeRawIngredientComponent,
        RawIngredientsListComponent, RawIngredientsEditComponent,
        TrainingAreasListComponent, TrainingAreasEditComponent, TrainingTopicListComponent, TrainingTopicEditComponent,
        TrainingBadgeListComponent, TrainingBadgeEditComponent, TrainingAreaComponent, TrainingTopicComponent,
        TrainingTopicLevelListComponent, TrainingTopicLevelEditComponent, TrainingAreaModelComponent, TrainingAreaPageComponent,
        TrainingTopicMoveComponent, OnlineOrderingSettingsComponent, OnlineOrderingCategoriesComponent,
        OnlineOrderingCategoryEditComponent, OnlineOrderingOptionListComponent, OnlineOrderingOptionEditComponent,
        OnlineOrderingOptionProductConfigurationComponent, OnlineOrderingCategoryProductComponent, OnlineOrderingConfigurationsComponent,
        InventoryScheduleEditorComponent, CopyInventoryIngredientsInfoComponent, AccountAttributesComponent, AudioNotificationComponent,
        AudioNotificationEditComponent, AccountAttributesViewComponent,
        AccountOrdersComponent, ScreenEditComponent, TrainingLevelMoveComponent, SalesInventoryActivationComponent,
        CopySecurityRoleComponent, PrepLabelPrintComponent,
        AccountAttributeTypesComponent, AccountAttributeTypeEditComponent,
        SalesGroupsComponent, SalesGroupEditComponent, SalesCategoriesComponent, SalesCategoryEditComponent, VendorsListComponent,
        VendorEditComponent, InventoryLogDueDateOverrideEditComponent, InventoryCategoryListComponent, InventoryCategoryEditComponent,
        UnitsListComponent, UnitEditComponent, SalesGroupEditWrapComponent, SalesGroupEditModelComponent, ImportWorkScheduleComponent,
        InventoryZoneListComponent, InventoryProductsForZoneComponent, InventorySubcategoryListComponent, InventorySubcategoryEditComponent,
        SecurityRolesListComponent, SecurityRoleEditComponent, InventoryZoneEditComponent, InventoryZoneEditProductsComponent,
        InventoryZoneEditDetailsComponent, InventoryZoneEditBinsComponent, InventoryZoneBinLightComponent,
        InventoryZoneBinsEditModelComponent, InfoLinkSectionsComponent, InfoLinkSectionsEditComponent,
        InfoLinksComponent, InfoLinksEditComponent, InfractionsListComponent, InfractionEditComponent, ButtonListComponent,
        ButtonEditComponent, ExternalApiViewComponent, UserListComponent, UserEditComponent, UserPayRatesAddEditComponent,
        WorkScheduleListComponent, WorkScheduleEditComponent, WorkScheduleTagListComponent, WorkScheduleTagEditComponent,
        WorkScheduleBatchEntryComponent, UserWorkScheduleComponent, LoginMessagesComponent, LoginMessagesEditComponent,
        JobCodeListComponent, JobCodeEditComponent, CashPayoutListComponent, EditCashPayoutComponent, CashDrawerEntriesComponent,
        AccountCategoryListComponent, EditAccountCategoryComponent, AccountsListComponent, AccountEditComponent,
        EquipmentTypeListComponent, EquipmentTypeEditComponent, EquipmentPropertyListComponent, EquipmentPropertyEditComponent,
        EquipmentListComponent, EquipmentEditComponent, TimeClockEntriesListComponent, TimeClockEntriesEditComponent,
        ScreenListComponent, PrepRecipeListComponent, PrepRecipeEditComponent, InventoryReceivedComponent,
        RecipesIncludedComponent, MergeProductComponent, RecipeEditorComponent, MergeSalesProductComponent, CopySalesProductComponent,
        InventoryProductListComponent, SalesProductEditComponent, SalesProductQuickReportComponent, SalesProductDefaultButtonComponent,
        SalesProductRecipeListComponent, SalesProductRecipeEditComponent, SalesProductPriceComponent, SalesCategoryEditWrapComponent,
        SalesCategoryEditModelComponent, SalesProductListComponent, SalesProductShowRecipeComponent, SalesProductListAllButtonsComponent,
        SalesProductEditWrapComponent, SalesProductEditModelComponent, ShowConsumptionComponent, SalesProductRecipeSwapComponent,
        InventoryProductsForBinComponent, InventoryProductEditWrapComponent, InformationManagementBaseComponent,
        SalesProductInstructionConfigurationComponent, ImportRecipeComponent, CopyInstructionsComponent, AccountEditModelComponent,
        AccountEditWrapperComponent, AccountDetailViewComponent, DuplicateIngredientListComponent, LabelPrintIntermediaryComponent,
        SalesUnitsListComponent, SalesUnitEditComponent, SalesProductDashboardComponent, InventoryProductDashboardComponent,
        ImportRawIngredientsComponent, ImportIngredientPreviewComponent, TagsListComponent, TagsEditComponent,
        DisplayMessageComponent, SelectButtonComponent, FlashButtonComponent, TemporaryNavigationComponent,
        ButtonInventoryIncrementDecrementComponent, RevenueCenterComponent, InventoryVendorProfileProductsComponent,
        InventoryVendorProfilesComponent, InventoryVendorProfileEditComponent, InventoryVendorProfileProductEditComponent,
        InventoryProductHistoryListComponent, InventoryProductHistoryEditComponent, ScheduleOverridesComponent,
        ScheduleOverrideEditComponent, ButtonHistoryComponent, ScreenHistoryComponent, SalesProductHistoryComponent,
        ButtonHistoryEditComponent, SalesProductHistoryEditComponent, SalesProductSizeHistoryComponent, ScreenHistoryEditComponent,
        SalesProductSizeHistoryEditComponent, InventoryProductsBulkUpdateComponent, SalesProductsBulkUpdateComponent, SingularFeeComponent,
        CopyProductRecipeModalComponent, PrepRecipeEditModalComponent, InventoryProductMemoComponent, InventoryProductLogModalComponent, PermissionNodeComponent,
        PermissionsListComponent, PermissionsEditComponent, InventoryOffSiteLocationsComponent, InventoryOffSiteLocationEditComponent,
        OffsiteInventoryProductSettingsComponent, WaitTimeRulesComponent, WaitTimeRuleEditComponent, MultipleProductComponent, AnnouncementScheduleComponent,
        AnnouncementScheduleEditComponent, MultiProductNextScreenComponent, MultiProductGroupComponent, MultiProductGroupListComponent,
        GooglePayConfigurationComponent, ApplePayConfigurationComponent, SalesProductBarcodesComponent, BarcodeProductSelectionComponent,
        AutoDiscountComponent, AutoMarkupComponent, SetSizeComponent, MainProductComponent, DetailEntryComponent, LocationAddressComponent,
        LocationAddressEditComponent, AddCommentComponent, FilterProductComponent, OnlineOrderingTermsConditionsComponent,
        DietaryWarningListComponent, DietaryWarningEditComponent, InventorySalesStockWarningComponent, SalesCategorySizeEditComponent],
})
export class InformationManagementModule { }
