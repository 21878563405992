import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GridColumn } from '@tarktech/tark-ng-utils';
import { LoyaltyFormAttributeService } from 'src/app/account-registration/services/loyalty-form-attribute.service';
import { ApplicationStateService, BaseGridComponent, ConfirmDeleteComponent, deleteWhite, editWhite, ExportService, loyaltyProgramAttribute, Messages, ModalService, ordinalBars, plusWhite, PrintTableService, SpinnerService } from 'src/app/shared';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { LoyaltyFormAttributeEditComponent } from '../loyalty-form-attribute-edit/loyalty-form-attribute-edit.component';
import { LoyaltyFormAttribute } from 'src/app/account-registration/interfaces/loyalty-form-attribute';
import { StringUtils } from 'src/app/shared/string-utils/string-utils';
import { Permissions, Levels } from '../../../../shared/constants';
import { cloneDeep } from 'lodash';
import { Table } from 'primeng/table';
import { AuthenticationService } from 'src/app/shared/auth/services/authentication.service';

@Component({
  selector: 'pos-loyalty-form-attributes',
  templateUrl: './loyalty-form-attributes.component.html',
  styleUrls: ['./loyalty-form-attributes.component.scss']
})
export class LoyaltyFormAttributesComponent extends BaseGridComponent implements OnInit {

  constructor(private loyaltyFormAttributeService: LoyaltyFormAttributeService,
    private alertService: AlertsService,
    private spinnerService: SpinnerService,
    private modalService: ModalService,
    private authenticationService: AuthenticationService,
    protected applicationStateService: ApplicationStateService,
    private exportService: ExportService,
    protected route: ActivatedRoute,
    protected router: Router,
    private printService: PrintTableService) {
      super(applicationStateService, route);
  }
  
  loyaltyFormAttributes: LoyaltyFormAttribute[] = [];
  loyaltyFormAttributeId = 0;
  @ViewChild('confirmAccountAttributeTypeDelete') private confirmAccountAttributeTypeDelete: ConfirmDeleteComponent;
  @ViewChild('ptable') public ptable: Table;
  loyaltyFormAttributeColumns: Array<GridColumn> = [];
  printLoyaltyFormAttributeColumns: Array<GridColumn> = [];
  icons = {
    plusWhite,
    ordinalBars,
    loyaltyProgramAttribute,
    deleteWhite,
    editWhite
  };

  editPermission = false;
  readOnlyPermission = false;
  permission = {
    name: Permissions.SystemConfigurationLoyaltyProgramAttributes,
    readOnlyLevel: Levels.ReadOnly,
    editLevel: Levels.Edit
  };

  ngOnInit() {
    this.configureGridColumns();
    this.reloadLoyaltyFormAttributes();
  }

  private configureGridColumns() {
    if (this.authenticationService.userHasPermission([{ Name: this.permission.name, Level: this.permission.editLevel }], 'any')) {
      this.editPermission = true;
    }

    if (this.authenticationService.userHasPermission([{ Name: this.permission.name, Level: this.permission.readOnlyLevel }], 'any')) {
      this.readOnlyPermission = true;
    }

    this.loyaltyFormAttributeColumns = [
      new GridColumn({ HeaderText: 'Name', Field: 'Name', IsSortable: true, Width: '30%' }),
      new GridColumn({ HeaderText: 'Description', Field: 'Description', IsSortable: true, Width: '60%' }),
    ] as Array<GridColumn>;

    this.printLoyaltyFormAttributeColumns = [...this.loyaltyFormAttributeColumns];
  }

  reloadLoyaltyFormAttributes() {
    this.spinnerService.show();
    this.loyaltyFormAttributeService.getAll()
      .subscribe(
          res => {
            this.loyaltyFormAttributes = res ? res : [];
          },
          this.alertService.showApiError,
          () => {
              this.spinnerService.hide();
          }
      );
  }

  editLoyaltyFormAttribute(data) {
    const modalRef = this.modalService.getModalWrapper(LoyaltyFormAttributeEditComponent);
    const modal = modalRef.show({
      animated: true,
      class: 'vertical-center',
      initialState: {
        loyaltyAttributeId: data ? cloneDeep(data.Id) : 0,
        selectedLoyaltyAttributes: this.loyaltyFormAttributes
      }
    });
    modal.close.subscribe(res => {
      if (res && res.shouldReload) {
        this.reloadLoyaltyFormAttributes();
      }
    });
  }

  deleteAccountAttributeType(data) {
    this.loyaltyFormAttributeId = data.Id;
    this.confirmAccountAttributeTypeDelete.Show(
      StringUtils.format(Messages.ConfirmDeleteLoyaltyFormAttribute, { 'loyaltyFormAttribute': data.Name }));
  }
  
  public printDiv() {
    this.printService.printEmitter.next({
      gridColumns: this.printLoyaltyFormAttributeColumns,
      gridData: this.loyaltyFormAttributes
    });
  }

  public exportCSV() {
    this.exportService.exportCSV('loyalty-attributes', this.loyaltyFormAttributes, null, this.printLoyaltyFormAttributeColumns);
  }

  close() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

  gridRowReorder(event) {
    this.loyaltyFormAttributeService.updateLoyaltyFormAttributeOrdinal(this.loyaltyFormAttributes).subscribe({
      next: (res) => { 
        this.loyaltyFormAttributes = res ? res : this.loyaltyFormAttributes; 
      },
      error: this.alertService.showApiError
    });
  }

  onConfirmAcknowledge() {
    this.spinnerService.show();
    this.loyaltyFormAttributeService.delete(this.loyaltyFormAttributeId)
      .subscribe(
          () => {
              this.alertService.renderSuccessMessage(
                  Messages.AccountAttributeTypeDeleteSuccess
              );
              this.reloadLoyaltyFormAttributes();
          },
          this.alertService.showApiError,
          () => {
              this.spinnerService.hide();
          }
      );
  }

  isSystemDefinedAttribute(loyaltyAttribute: any): boolean {
    return loyaltyAttribute.IsSystemDefined && loyaltyAttribute.AccountAttributeTypeId === null;
  }
}
