<pos-edit-wrapper heading="Run Report" (close)="closeModal()">
    <div class="col-12 overflow-auto-tabs pos-modal-body screen-scroll">
        <div *ngFor="let param of parameters">
            <pos-row *ngIf="param.AllowUserInput">
                <pos-column>
                    <pos-form-field [label]="param.DataType=='BIT' ? '' : param.Label">
                        <span *ngIf="param.DataType=='DATE'">
                            <p-calendar posInput inputStyleClass="form-control" [showIcon]="true" appendTo="body"
                                name="dateParam" [dateFormat]="dateFormat" [(ngModel)]="param.InitialValue"
                                [monthNavigator]="true" yearNavigator="true" yearRange="2000:2099"
                                [required]="param.IsRequired"></p-calendar>
                        </span>
                        <span *ngIf="param.DataType=='INT'">
                            <span *ngIf="param.QueryValues">
                                <ng-select posInput [items]="param.ParameterQueryValues" bindLabel="Name" bindValue="Id"
                                    appendTo="body" [(ngModel)]="param.InitialValue" name="selectInt"
                                    [required]="param.IsRequired"></ng-select>
                            </span>
                            <span *ngIf="!param.QueryValues">
                                <input posInput class="form-control" [(ngModel)]="param.InitialValue" type="text"
                                    name="paramInt" [required]="param.IsRequired" />
                            </span>
                        </span>
                        <span *ngIf="param.DataType=='CSV'">
                            <ng-select posInput [items]="param.ParameterQueryValues" bindLabel="Name" bindValue="Id"
                                appendTo="body" [(ngModel)]="param.InitialValue" name="selectCsv"
                                [required]="param.IsRequired"></ng-select>
                        </span>
                        <span *ngIf="param.DataType=='BIT'">
                            <p-checkbox posInput name="paramCheckbox" [(ngModel)]="param.InitialValue" binary="true"
                                [label]="param.Label">
                            </p-checkbox>
                        </span>
                        <span *ngIf="param.DataType=='TEXT'">
                            <input posInput class="form-control" [(ngModel)]="param.InitialValue" type="text"
                                name="paramText" [maxlength]="param.DataLength ? param.DataLength : null"
                                [required]="param.IsRequired" />
                        </span>
                        <span *ngIf="param.DataType=='DROPDOWN'">
                            <ng-select posInput [items]="param.ParameterQueryValues" bindLabel="Name" bindValue="Id"
                                appendTo="body" [(ngModel)]="param.InitialValue" name="selectDropdown"
                                [required]="param.IsRequired" placeholder="Select {{param.Label}}"></ng-select>
                        </span>
                        <span *ngIf="param.DataType=='MULTISELECT' && param.ParameterQueryValues?.length">
                            <ng-select posInput [items]="param.ParameterQueryValues" [multiple]="true" appendTo="body"
                                [hideSelected]="true" bindValue="Id" bindLabel="Name"
                                placeholder="Select {{param.Label}}" [(ngModel)]="param.InitialValue" name="multiselect"
                                [required]="param.IsRequired">
                            </ng-select>
                        </span>
                    </pos-form-field>
                </pos-column>
            </pos-row>
        </div>
    </div>
    <div class="col-12 action-button-container popup-footer">
        <button class="btn btn-primary btn-lg btn-save pos-button" type="button" (click)="runReport()">
            <pos-icon [icon]="icons.runReport"></pos-icon>
            Run Report
        </button>
        <button class="btn btn-secondary btn-lg btn-cancel-modal pos-button" (click)="closeModal()">
            <pos-icon [icon]="icons.reportBan"></pos-icon>
            Cancel
        </button>
    </div>
</pos-edit-wrapper>
<div class="clearfix"></div>
