import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { find } from 'lodash';
import { finalize } from 'rxjs/operators';
import { OnlineOrderingSettings, OnlineOrderingSettingsService } from 'src/app/information-management';
import { AlertsService, AuthenticationService, BaseFormComponent, DomainConstants, fileAlt, fileArchive, fileInvoice, fileInvoiceDollar, FormUtilityService, Levels, Messages, Permissions, SpinnerService } from 'src/app/shared';
import 'codemirror/mode/htmlmixed/htmlmixed';
declare let $: any;

@Component({
  selector: 'pos-online-ordering-terms-conditions',
  templateUrl: './online-ordering-terms-conditions.component.html',
  styleUrls: ['./online-ordering-terms-conditions.component.scss']
})
export class OnlineOrderingTermsConditionsComponent extends BaseFormComponent {

  @ViewChild('termsConditionsForm') termsConditionsForm: NgForm;
  contentHeight;
  text: string;
  icons = {
    fileAlt,
    fileInvoiceDollar, fileArchive, fileInvoice
  }

  tabList = {
    RefundPolicy: 'RefundPolicy',
    PrivacyPolicy: 'PrivacyPolicy',
    TermsAndConditions: 'TermsAndConditions'
  }
  selectedTab = this.tabList.PrivacyPolicy;
  hasPermission = false;

  get getForm(): NgForm {
    return this.termsConditionsForm;
  }

  permission = {
    name: Permissions.OnlineOrderingTermsAndConditions,
    readOnlyLevel: Levels.ReadOnly,
    editLevel: Levels.Edit
  };

  refundPolicy: OnlineOrderingSettings = null;
  privacyPolicy: OnlineOrderingSettings = null;
  termsAndConditions: OnlineOrderingSettings = null;
  onlineOrderingSettingsKeyName = DomainConstants.OnlineOrderingSettingNames;
  codeMirrorOptions = {
    lineNumbers: true,
    name: 'htmlmixed'
  };
  constructor(private router: Router,
    private route: ActivatedRoute,
    formUtilityService: FormUtilityService,
    private authenticationService: AuthenticationService,
    private onlineOrderingSettingsService: OnlineOrderingSettingsService,
    private spinnerService: SpinnerService,
    private alertsService: AlertsService
  ) {
    super(formUtilityService);
    this.refundPolicy = onlineOrderingSettingsService.newOnlineOrderingSetting(this.onlineOrderingSettingsKeyName.RefundPolicy);
    this.privacyPolicy = onlineOrderingSettingsService.newOnlineOrderingSetting(this.onlineOrderingSettingsKeyName.PrivacyPolicy);
    this.termsAndConditions = onlineOrderingSettingsService.newOnlineOrderingSetting(this.onlineOrderingSettingsKeyName.TermsAndConditions);
  }

  ngOnInit() {
    if (this.authenticationService.userHasPermission([{ Name: this.permission.name, Level: this.permission.editLevel }], 'any')) {
      this.hasPermission = true;
    }
    this.contentHeight = ($(window).height() - 360) + 'px';
    this.getTermsAndConditions();
  }

  getTermsAndConditions() {
    this.spinnerService.show();
    this.onlineOrderingSettingsService.getOnlineOrderingSettings()
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (settings: OnlineOrderingSettings[]) => {
          this.refundPolicy = find(settings, (setting) => {
            return setting.KeyName == this.onlineOrderingSettingsKeyName.RefundPolicy;
          }) ?? this.refundPolicy;
          this.privacyPolicy = find(settings, (setting) => {
            return setting.KeyName == this.onlineOrderingSettingsKeyName.PrivacyPolicy;
          }) ?? this.privacyPolicy;
          this.termsAndConditions = find(settings, (setting) => {
            return setting.KeyName == this.onlineOrderingSettingsKeyName.TermsAndConditions;
          }) ?? this.termsAndConditions;
        }, error: this.alertsService.showApiError
      })
  }

  save(isValid: boolean) {
    if (!isValid) {
      return;
    }
    this.spinnerService.show();
    const settings = [this.refundPolicy, this.privacyPolicy, this.termsAndConditions];
    this.onlineOrderingSettingsService.saveOnlineOrderingSettings(settings, null)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: () => {
          this.cancel();
          this.alertsService.renderSuccessMessage(Messages.OnlineOrderingTermsAndConditionsSaveSuccess);
        }, error: this.alertsService.showApiError
      });
  }

  cancel() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }
}
