import { Directive } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';

@Directive({
  selector: 'ng-select[posInput]'
})
export class SelectOptionOnTabDirective {

  constructor(
    private ngSelect: NgSelectComponent
  ) {
  }

  ngAfterViewInit() {
    this.ngSelect.selectOnTab = true;
  }
}
