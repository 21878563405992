import { Injectable } from "@angular/core";
import { find, forEach } from "lodash";
import { AllergenInquiryScreenConfig, KioskChoice, KioskNote, KioskScreenChoice, KioskScreenConfig } from "../interfaces";
import { KioskFooter, KioskHeader, ScreenConfig } from "../interfaces/config/screen-config";
import { GridConfig } from "../interfaces/config/grid-config";
import { DomainConstants } from "src/app/shared/constants";
import { ButtonConfig } from "../interfaces/config/button-config";
import { LeftPanelConfig, RightPanelConfig } from "../interfaces/config/split-screen-config";
import { BaseCrudService, ColorUtilityService } from "src/app/shared";
import { HttpClient } from "@angular/common/http";
import { ShakeButtonConfig } from "../interfaces/config/shake-button-config";
import { CreatedShakeScreenConfig } from "../interfaces/config/created-shake-screen-config";
declare let $: any;

@Injectable({
    providedIn: 'root'
})
export class OrderKioskConfigService extends BaseCrudService<KioskScreenConfig> {

    constructor(private colorUtilityService: ColorUtilityService,
        private httpClient: HttpClient
    ) {
        super('kiosk-screen-config', httpClient);
    }

    newKioskScreenConfig(configName: string, type: string = null, screenId = 0): KioskScreenConfig {
        if (!type) type = DomainConstants.KioskScreenConfigTypes.JSON;
        return {
            Id: 0,
            Name: configName,
            Type: type,
            Config: null,
            ScreenId: screenId
        };
    }

    newScreenConfig(): ScreenConfig {
        return {
            Header: this.newKioskHeader(),
            Footer: this.newKioskFooter(),
            ShowInvoice: false,
            BackgroundColor: '',
            BackgroundImage: '',
            Note: this.newNote()
        };
    }

    newNote(): KioskNote {
        return {
            Text: '',
            Alignment: {
                Horizontal: null,
                Vertical: null
            }
        }
    }

    newAllergenInquiry(): AllergenInquiryScreenConfig {
        return {
            ...this.newScreenConfig(),
            ConfirmButtonText: 'Yes',
            ConfirmButtonOpacity: 1,
            RejectButtonText: 'No',
            RejectButtonOpacity: 1
        };
    }

    newKioskHeader(): KioskHeader {
        return {
            IsShow: true,
            BackgroundColor: '#f3f3f3',
            BackgroundImage: null,
            Alignment: { Horizontal: '', Vertical: '' },
            Title: 'Header'
        }
    }

    newKioskFooter(): KioskFooter {
        return {
            IsShow: true,
            BackgroundColor: '#f3f3f3',
            BackgroundImage: null,
            IsShowBackButton: true
        }
    }

    newGridConfig(isShowTagsAndPrice = true): GridConfig {
        return {
            NoOfRows: null,
            NoOfCols: null,
            IsShowTagsAndPrice: isShowTagsAndPrice,
            IsStretchImage: false
        };
    }

    newButtonConfig(height: number = null, width: number = null): ButtonConfig {
        return {
            IsShow: true,
            IsShowButtonText: true,
            Alignment: {
                Horizontal: null,
                Vertical: null
            },
            Height: height,
            Width: width ?? 100,
            Opacity: 1
        };
    }

    newShakeButtonConfig(): ShakeButtonConfig {
        return {
            BackgroundColor: '',
            FontColor: ''
        }
    }

    newLeftPanelConfig(): LeftPanelConfig {
        return {
            Alignment: {
                Horizontal: 'center',
                Vertical: 'center'
            },
            Height: 50,
            Width: 200,
            Opacity: 1,
            IsShowButtonText: true
        }
    }

    newRightPanelConfig(): RightPanelConfig {
        return {
            PanelWidth: '',
            Header: {
                Title: 'Title',
                Height: 50,
                Image: null,
                BackgroundColor: '#ffffff',
                FontColor: '#111111',
            },
            Alignment: {
                Vertical: 'center'
            },
            Height: 50,
            Opacity: 1,
            IsShowButtonText: true
        }
    }

    newCreatedShakeScreenConfig(): CreatedShakeScreenConfig {
        return {
            Buttons: { ContainerWidth: '100%', Alignment: { Horizontal: 'center', Vertical: null } },
            ShakeDetails: { ContainerWidth: '85%', Alignment: { Horizontal: 'end', Vertical: null } },
            AddToCart: { BackgroundColor: '', Color: '' },
            TryAgain: { BackgroundColor: '', Color: '' }
        }
    }

    save(kioskScreenConfig: KioskScreenConfig, image: Blob, imageName: string) {
        const formData = new FormData();
        if (image) {
            formData.append('file', image, imageName);
        }
        formData.append('kioskConfig', JSON.stringify(kioskScreenConfig));
        return this.httpClient.post(`${this.apiEndpoint}`, formData);
    }

    parseConfigProperties(propertyName: string, configs: Array<KioskScreenConfig>) {
        const screenConfig = find(configs, x => x.Name == propertyName)?.Config;
        if (screenConfig) return JSON.parse(screenConfig);
        return null;
    }

    calculateInvoiceHeight(screenConfig: ScreenConfig) {
        return $('.kiosk-screen').last().height() - this.getHeaderHeight(screenConfig) - this.getFooterHeight(screenConfig);
    }

    getHeaderHeight = (screenConfig: ScreenConfig): number => screenConfig.Header?.IsShow ? $('.header').outerHeight() ?? 80 : 0;
    getFooterHeight = (screenConfig: ScreenConfig): number => screenConfig.Footer?.IsShow ? $('.footer').outerHeight() ?? 80 : 0;

    setScreenButtonsBackgroundColor(screenButtons: KioskScreenChoice[], opacity: number) {
        forEach(screenButtons, screenButton => {
            if (screenButton?.Button?.BackgroundColor) {
                screenButton.Button.BackgroundColor = this.colorUtilityService.convertColorToRgba(screenButton?.Button?.BackgroundColor, opacity);
            }
        });
    }

    getBackgroundColor(button: KioskChoice, opacity: number) {
        return this.colorUtilityService.convertColorToRgba(button.BackgroundColor, opacity);
    }
}
