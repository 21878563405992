<div class="row mx-0">
  <div *ngFor="let war of uniqueDietaryWarnings" class="col-lg-2 col-md-3 col-sm-4 col-6 p-0 pe-pix-5 mb-pix-5">
    <button
      class="btn bg-theme-separator white normal btn width-full bg-theme-separator information-modal-size pos-button"
      (click)="highlightRow(war)">
      <span *ngIf="war.IsSelected"><pos-icon [icon]="icons.check"></pos-icon></span>
      {{war.DietaryWarning}}
    </button>
  </div>
</div>
<div class="col-12 p-0">
  <ng-table #gridComponent [sort]="false" [paginator]="false" [scrollable]="true" [scrollHeight]="screenHeight + 'px'"
    [search]="false" [columns]="dietaryWarningInfoColumns" [rowClass]="'color'" [data]="dietaryWarningInfo">
  </ng-table>
  <ng-template let-data="rowData" #inventoryProductDietaryWarningTemplate>
    <span>{{ data.InventoryProductFriendlyName ? data.InventoryProductFriendlyName: data.InventoryProductName }}</span>
</ng-template>
</div>
