import { Injectable } from '@angular/core';
import { KioskOrderVersionVal, KioskScreenButtons } from '../interfaces/kiosk-order-version-val';
import { KioskOrder, KioskOrderProduct, KioskOrderProductComponent } from '../interfaces';
import { OrderKioskScreenService, RuntimeConstants } from 'src/app/shared';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class KioskOrderService {

  apiUrl = `${RuntimeConstants.API_PATH}kiosk-order`;
  constructor(private kioskScreenService: OrderKioskScreenService,
    private httpClient: HttpClient
  ) { }

  newVersionValObject(orderId: number = null): KioskOrderVersionVal {
    return {
      Order: this.newKioskOrder(orderId),
      ScreenButtons: this.newScreenButtons(),
      filterProductTags: []
    }
  }

  newKioskOrder(orderId: number = null): KioskOrder {
    return {
      Id: orderId ?? 0,
      OrderTypeId: null,
      Products: [],
      Product: this.newOrderProduct()
    }
  }

  newOrderProduct(): KioskOrderProduct {
    return {
      Components: [],
      Comment: '',
      Qty: 1,
      SizeId: null,
      SalesProductId: null
    };
  }

  newOrderProductComponent(): KioskOrderProductComponent {
    return {
      SalesProductId: null,
      ButtonId: null,
      SizeId: null
    };
  }

  newScreenButtons(): KioskScreenButtons {
    return {
      Buttons: [],
      Screen: this.kioskScreenService.newScreen()
    }
  }

  createOrder(kioskOrder: KioskOrder) {
    return this.httpClient.post(`${this.apiUrl}`, kioskOrder);
  }

  addItemToOrder(orderId: number, kioskOrderProduct: KioskOrderProduct) {
    return this.httpClient.post(`${this.apiUrl}/${orderId}`, kioskOrderProduct);
  }

  addCommentToOrder(orderId: number, comment: string, isAddCommentPrefix = false) {
    return this.httpClient.post(`${this.apiUrl}/${orderId}/comment`, { Comment: comment, IsAddCommentPrefix: isAddCommentPrefix });
  }

}
