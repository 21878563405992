<div class="page-content ">
    <div class="tab-content">
        <div class="tab-pane fade in active show px-3">
            <div class="col-12 pb-pix-5">
                <a class="close" (click)="onCancel()">×</a>
                <div class="row g-0">
                    <div class="col-md-10 col-xs-9">
                        <h3 class="page-content__header">
                            {{this.id? 'Edit': 'Add'}} Screen History{{this.id? ': ' + screenName:
                            ''}}
                        </h3>
                    </div>
                </div>
                <div class="row">
                    <form #screenHistoryForm="ngForm" (ngSubmit)="saveScreenHistory(screenHistoryForm.valid)"
                        autocomplete="off">
                        <div class="col-md-12 col-xs-12 ">
                            <div class="edit-form__section">
                                <h3 class="edit-form__section__heading">
                                    Screen History
                                </h3>
                                <div class="row pt-pix-10">
                                    <div class="col-md-1 label-section required col-150 pe-0">
                                        <label>
                                            Title
                                        </label>
                                    </div>
                                    <div class="col-md-3"
                                        [ngClass]="{'has-error': (name.invalid && screenHistoryForm.submitted)}">
                                        <input class="form-control" name="name" #name="ngModel"
                                            [(ngModel)]="screenHistory.Name" required>
                                        <span *ngIf="name.invalid && screenHistoryForm.submitted">
                                            <label class="control-label" *ngIf="name.errors?.required">Please
                                                enter title.</label>
                                        </span>
                                    </div>
                                </div>

                                <div class="row pt-pix-10">
                                    <div class="col-md-1 label-section col-150 pe-0">
                                        <label>
                                            Min Selection
                                        </label>
                                    </div>
                                    <div class="col-md-2 col-120">
                                        <input class="form-control" name="minSelections" #minSelections="ngModel"
                                            [(ngModel)]="screenHistory.MinSelections" type="number">
                                    </div>

                                    <div class="col-md-1 label-section col-130 pe-0">
                                        <label>
                                            Max Selection
                                        </label>
                                    </div>
                                    <div class="col-md-2 col-120">
                                        <input class="form-control" name="manSelections" #manSelections="ngModel"
                                            [(ngModel)]="screenHistory.MaxSelections" type="number">
                                    </div>
                                </div>

                                <div class="row pt-pix-10">
                                    <div class="col-md-2 label-section  col-150 pe-0">
                                        <label>
                                            Default Next
                                        </label>
                                    </div>
                                    <div class="col-md-3">
                                        <ng-select posInput name="defaultNextScreen" [items]="screenList" bindLabel="Title"
                                            bindValue="Id" #defaultNextScreen="ngModel"
                                            [(ngModel)]="screenHistory.DefaultNextScreenId"
                                            placeholder="Select default next screen">
                                        </ng-select>
                                    </div>
                                </div>

                                <div class="row pt-pix-10">
                                    <div class="col-md-1 label-section  col-150 pe-0">
                                        <label>
                                            Layout
                                        </label>
                                    </div>
                                    <div class="col-md-4 btn-group">
                                        <label class="btn btn-default btn-radio"
                                            [ngClass]="screenHistory.Layout == screenLayouts.Fluid ? 'active btn-primary normal': 'btn-secondary'">
                                            <input name="selectedLayout" (change)="setNoOfColumns()"
                                                [(ngModel)]="screenHistory.Layout" type="radio"
                                                [value]="screenLayouts.Fluid" /> {{screenLayouts.Fluid}}
                                        </label>
                                        <label class="btn btn-default btn-radio"
                                            [ngClass]="screenHistory.Layout == screenLayouts.Fixed? 'active btn-primary normal': 'btn-secondary'">
                                            <input name="selectedLayout" (change)="setNoOfColumns()"
                                                [(ngModel)]="screenHistory.Layout" type="radio"
                                                [value]="screenLayouts.Fixed" /> {{screenLayouts.Fixed}}
                                        </label>

                                    </div>
                                </div>

                                <div class="row pt-pix-10" *ngIf="screenHistory.Layout == screenLayouts.Fixed">
                                    <div class="col-md-1 label-section required col-150 pe-0">
                                        <label>
                                            Columns
                                        </label>
                                    </div>
                                    <div class="col-md-1"
                                        [ngClass]="{'has-error': (noOfColumns.invalid && screenHistoryForm.submitted)}">
                                        <input class="form-control" name="noOfColumns" #noOfColumns="ngModel"
                                            type="number" [(ngModel)]="screenHistory.ColumnCount" required>
                                        <span *ngIf="noOfColumns.invalid && screenHistoryForm.submitted">
                                            <label class="control-label" *ngIf="noOfColumns.errors?.required">Please
                                                enter columns.</label>
                                        </span>
                                    </div>
                                </div>

                                <div class="row pt-pix-10" *ngIf="screenHistory.Layout == screenLayouts.Fluid">
                                    <div class="col-md-1 label-section col-150 pe-0">
                                        <label>
                                            Buttons Size
                                        </label>
                                    </div>
                                    <div class="col-md-2">
                                        <ng-select posInput name="buttonSize" [items]="buttonSizes" bindLabel="Text"
                                            bindValue="Value" #buttonSize="ngModel"
                                            [(ngModel)]="screenHistory.ButtonSize" placeholder="Select button size"
                                            [clearable]="false" [clearOnBackspace]="false">
                                        </ng-select>
                                    </div>
                                </div>

                                <div class="row pt-pix-10" *ngIf="!id">
                                    <div class="col-md-1 label-section col-150 pe-0">
                                        <label>
                                            Edited Date
                                        </label>
                                    </div>
                                    <div class="col-md-2">
                                        <p-calendar [(ngModel)]="screenHistory.DateAdded" [showIcon]="true"
                                            [dateFormat]="dateFormat" [readonlyInput]="true" name="dateEdited"
                                            yearNavigator="true" [inputStyle]="{'width':'unset','display':'unset'}"
                                            inputStyleClass="form-control" yearRange="2000:2099" [showTime]="true"
                                            [hourFormat]="hourFormat" #dateEdited="ngModel" [monthNavigator]=true
                                            [required]="true" [maxDate]="today"
                                            [ngClass]="{'has-error': (dateEdited.invalid && screenHistoryForm.submitted)}">
                                        </p-calendar>
                                    </div>
                                </div>
                            </div>
                            <div class="clearfix"></div>
                            <div class="col-12 page-footer action-button-container">
                                <button type="submit" class="btn-primary btn-lg btn btn-save me-pix-5 pos-button">Save </button>
                                <button type="button" class="btn-secondary btn-lg btn normal pos-button"
                                    (click)="onCancel()">Cancel</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
