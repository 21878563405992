import { Component, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GridColumn, TemplateColumn, TextAlign } from '@tarktech/tark-ng-utils';
import { check, editWhite, exclamationTriangle, plusWhite } from 'src/app/shared/components/icon';
import { Levels, Permissions } from 'src/app/shared/constants';
import { AuthenticationService } from 'src/app/shared/auth';
import { AlertsService } from 'src/app/shared/services';
import { SpinnerService } from 'src/app/shared/components';
import { DietaryWarningsService } from '../../services/dietary-warnings.service';
import { DietaryWarningModel } from '../../interface/dietary-warning-model';

@Component({
  selector: 'pos-dietary-warning-list',
  templateUrl: './dietary-warning-list.component.html',
  styleUrls: ['./dietary-warning-list.component.scss']
})
export class DietaryWarningListComponent {

  icons = { exclamationTriangle, plusWhite, editWhite, check }
  dietaryWarningList: Array<DietaryWarningModel> = [];
  dietaryWarningColumns: Array<GridColumn> = [];

  @ViewChild('operationTemplate', { static: true }) private operationTemplate: TemplateRef<any>;
  @ViewChild('headerTemplate', { static: true }) private headerTemplate: TemplateRef<any>;
  @ViewChild('IsOverridableTemplate', { static: true }) private isOverridableTemplate: TemplateRef<any>;
  @ViewChild('makeTableColorTemplate', {static: true}) private makeTableColorTemplate: TemplateRef<any>;
  permission = {
    name: Permissions.DietaryWarnings,
    editLevel: Levels.Edit
  }

  constructor(
    private router: Router,
    protected route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private spinnerService: SpinnerService,
    private alertsService: AlertsService,
    private dietaryWarningsService: DietaryWarningsService,
  ){}

  ngOnInit() {
    this.loadData();
    this.configureGridColumns();
  }

  loadData() {
    this.spinnerService.show();
    this.dietaryWarningsService.getDietaryWarnings()
    .subscribe({
      next: (res: DietaryWarningModel[]) => {
        this.dietaryWarningList = res;
      },
      error: this.alertsService.showApiError,
      complete: () => this.spinnerService.hide(),
    });
  }

  configureGridColumns() {
    const gridColumns: Array<GridColumn> = [];
    const operationColumn = new TemplateColumn({
      itemTemplate: this.operationTemplate,
      Width: '147px',
      headerTemplate: this.headerTemplate,
      CellClass: 'cell-padding',
      TextAlign: TextAlign.Center
    });
    const isOverridableColumn = new TemplateColumn({
      itemTemplate: this.isOverridableTemplate,
      Width: '147px',
      HeaderText: 'Overridable',
      CellClass: 'cell-padding',
      TextAlign: TextAlign.Center
    });
    const makeTableColorColumn = new TemplateColumn({
      itemTemplate: this.makeTableColorTemplate,
      Width: '10px',
      CellClass: 'pi-0 no-right-border'
    })
    gridColumns.push(makeTableColorColumn);
    gridColumns.push(new GridColumn({ HeaderText: 'Name', Field: 'Name', IsSortable: true, CellClass: 'no-left-border' }));
    gridColumns.push(isOverridableColumn)

    if (this.authenticationService.userHasPermission([{ Name: this.permission.name, Level: this.permission.editLevel }], 'any')) {
      gridColumns.push(operationColumn);
    }
    this.dietaryWarningColumns = [...gridColumns];
  }

  editItem(id: number) {
    this.router.navigate([id], { relativeTo: this.route });
  }

  close() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }
}
