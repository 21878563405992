<ng-table #gridComponent [search]="false" [sort]="false" [paginator]="false" [columns]="columns" [data]="parameters"
    [isReorderableTable]="true" [ReorderableColumnRow]="'row'" [tableStyle]="{ 'min-width': '1300px' }" >
</ng-table>
<ng-template let-data="rowData" let-rowIndex="rowIndex" #labelTemplate>
    <span [ngClass]="{'has-error': (paramLabel.invalid && isFormSubmitted && rowIndex !== (parameters.length - 1))}">
        <input type="text" name="label{{rowIndex+updateIndex}}" #paramLabel="ngModel"
            (ngModelChange)="createParameterRow(rowIndex)" (keyup)="createParameterRow(rowIndex)" class="form-control"
            [(ngModel)]="data.Label" [required]="rowIndex !== (parameters.length - 1)" />
        <span *ngIf="paramLabel.invalid && isFormSubmitted && rowIndex !== (parameters.length - 1)">
            <label class="control-label" *ngIf="paramLabel.errors?.required">Please enter parameter.</label>
        </span>
    </span>
</ng-template>
<ng-template let-data="rowData" let-rowIndex="rowIndex" #nameTemplate>
    <span [ngClass]="{'has-error': (paramName.invalid && isFormSubmitted && rowIndex !== (parameters.length - 1))}">
        <input type="text" name="name{{rowIndex+updateIndex}}" #paramName="ngModel"
            (ngModelChange)="createParameterRow(rowIndex)" class="form-control" [(ngModel)]="data.Name"
            (keyup)="createParameterRow(rowIndex)" [required]="rowIndex !== (parameters.length - 1)" />
        <span *ngIf="paramName.invalid && isFormSubmitted && rowIndex !== (parameters.length - 1)">
            <label class="control-label" *ngIf="paramName.errors?.required">Please enter field name.</label>
        </span>
    </span>
</ng-template>
<ng-template let-data="rowData" let-rowIndex="rowIndex" #dataTypeTemplate>
    <span [ngClass]="{'has-error': (dataType.invalid && isFormSubmitted && rowIndex !== (parameters.length - 1))}">
        <ng-select posInput [items]="dataTypes" bindLabel="Text" bindValue="Value" placeholder="Select type" appendTo="body"
            [(ngModel)]="data.DataType" name="dataType{{rowIndex+updateIndex}}"
            [required]="rowIndex !== (parameters.length - 1)" #dataType="ngModel"
            (change)="dataTypeChanged(data,true); createParameterRow(rowIndex)"></ng-select>
        <span *ngIf="dataType.invalid && isFormSubmitted && rowIndex !== (parameters.length - 1)">
            <label class="control-label" *ngIf="dataType.errors?.required">Please select type.</label>
        </span>
    </span>
</ng-template>
<ng-template let-data="rowData" let-rowIndex="rowIndex" #dataLengthTemplate>
    <input type="number" name="dataLength{{rowIndex+updateIndex}}" #paramDataLength="ngModel"
        [disabled]="data.DataType !== 'TEXT'" min="0" posOnlyPositiveNumbers
        (ngModelChange)="createParameterRow(rowIndex)" (keyup)="createParameterRow(rowIndex)" class="form-control"
        [(ngModel)]="data.DataLength" />
</ng-template>
<ng-template let-data="rowData" let-rowIndex="rowIndex" #initialValueTemplate>
    <input type="text" name="initialValue{{rowIndex+updateIndex}}" #paramInitialValue="ngModel"
        (keyup)="createParameterRow(rowIndex)" (ngModelChange)="createParameterRow(rowIndex)" class="form-control"
        [(ngModel)]="data.InitialValue" *ngIf="!data.DataType || data.DataType !== 'BIT'" />
    <p-triStateCheckbox name="initialValue{{rowIndex}}" [(ngModel)]="data.InitialValue" *ngIf="data.DataType == 'BIT'">
    </p-triStateCheckbox>
</ng-template>
<ng-template let-data="rowData" let-rowIndex="rowIndex" #isRequiredTemplate>
    <p-checkbox name="isRequired{{rowIndex+updateIndex}}" #isRequired="ngModel" [(ngModel)]="data.IsRequired"
        binary="true">
    </p-checkbox>
</ng-template>
<ng-template let-data="rowData" let-rowIndex="rowIndex" #allowUserInputTemplate>
    <p-checkbox name="allowUserInput{{rowIndex+updateIndex}}" #allowUserInput="ngModel"
        [(ngModel)]="data.AllowUserInput" binary="true"></p-checkbox>
</ng-template>
<ng-template let-data="rowData" let-rowIndex="rowIndex" #operationTemplate>
    <div class="d-flex">
        <div  class="col-lg-1 col-30 p-0 pr-2" tooltip
            title="Manual Values">
            <pos-action-icon [icon]="icons.listWhite" (click)="openValuesModal(data, queryType.Manual)" *ngIf="data.HasDropdown"></pos-action-icon>
        </div>
        <div class="col-lg-1 col-30 p-0 pr-2" tooltip
            title="Query Values">
            <pos-action-icon [icon]="icons.queryValuesDatabase" (click)="openValuesModal(data, queryType.Query)" *ngIf="data.HasDropdown"></pos-action-icon>
        </div>
        <div  class="col-lg-1 col-30 p-0" tooltip title="Delete"
            *ngIf="rowIndex !== (parameters.length - 1)">
            <pos-action-icon [icon]="icons.deleteWhite" (click)="removeParameter(data, rowIndex)"></pos-action-icon>
        </div>
    </div>
</ng-template>
