<div class="item-vertical-center flex-flow-column">
    <div *ngIf="listWidgetConfig?.TitleText" class="p-pix-3 text-center" [innerHTML]="listWidgetConfig?.TitleText"
        [ngStyle]="listWidgetStyleConfig?.TitleFontStyle"></div>
    <div *ngIf="preparedMenuDisplayItems.length > 0" class="p-pix-5 display-flex" [ngStyle]="{'flex-direction': listWidgetStyleConfig?.SeparatorStyle?.Direction ?? 'column'}">
        <div *ngFor="let rowItems of preparedMenuDisplayItems; let last = last;" [ngStyle]="{
            'background': listWidgetStyleConfig?.SeparatorStyle?.BackgroundColor ?? 'transparent',
            'margin': listWidgetStyleConfig?.SeparatorStyle?.Margin ?? '',
            'padding': listWidgetStyleConfig?.SeparatorStyle?.Padding ?? '',
            'flex': '1 1 0'
        }">
            <div class="display-flex">
                <div *ngFor="let items of rowItems;"
                    [ngStyle]="{'width': listWidgetConfig?.NoOfColumns ? (100/listWidgetConfig.NoOfColumns) + '%' : '100%'}">
                    <div *ngFor="let item of items;" class="display-flex mb-pix-1" [ngClass]="item?.InteractiveStyleClass"
                        [ngStyle]="listWidgetStyleConfig?.ListItemStyle">
                        <span class="flex-grow-2" [ngStyle]="displayListStyle" style="{{listWidgetConfig.ShowIndentedText ? 'text-indent: -0.5em;padding-left: 0.5em;': ''}}" [hidden]="!item?.IsInStock &&
                            listWidgetConfig?.OutOfStockBehavior?.BehaviorType === outOfStockBehaviorTypes.Hide"
                            [style.width]="listWidgetStyleConfig?.PriceTableConfig?.ShowPriceAsTable ? (listWidgetStyleConfig?.PriceTableConfig?.Width ? (100 - listWidgetStyleConfig?.PriceTableConfig?.Width) + '%' : '' ) : ''">
                            <span [ngStyle]="listWidgetStyleConfig?.ItemFontStyle" *ngIf="!item?.WidgetProductGroupId"
                                [innerHTML]="(item?.Index ? item?.Index + '. ' : '')"></span>
                            <pos-menu-image [imagePath]="imagePath" [image]="item?.Image" [height]="productImageSize"
                                [width]="productImageSize"
                                *ngIf="item?.Image && listWidgetConfig?.IsShowProductImage" class="pe-pix-3">
                            </pos-menu-image>

                            <span [ngStyle]="listWidgetStyleConfig?.ItemFontStyle" *ngIf="!item?.WidgetProductGroupId">
                                <span [innerHTML]="item?.Text"
                                    [ngStyle]="{'color': item?.IsInStock ? (listWidgetStyleConfig?.ItemFontStyle?.color ? listWidgetStyleConfig.ItemFontStyle.color 
                                       : (listWidgetConfig?.IsUseMakeTableColor ? item.Color : '')) : listWidgetConfig?.OutOfStockBehavior?.ItemColor ? listWidgetConfig.OutOfStockBehavior.ItemColor : 'lightgrey',
                                       'text-decoration': (item?.IsInStock ? '' : (listWidgetConfig?.OutOfStockBehavior?.TextDecoration))}">
                                </span>
                            </span>
                            <span *ngIf="item?.WidgetProductGroupId">
                                <div class="p-0 display-flex" [ngStyle]="{'flex-direction': 'column' , 'text-indent': 'initial',
                                        'line-height': listWidgetStyleConfig?.GroupedItemStyle?.fontSize}">
                                    <span [ngStyle]="listWidgetStyleConfig?.ItemFontStyle" *ngIf="item?.Text">
                                        <span [ngStyle]="listWidgetStyleConfig?.ItemFontStyle"
                                            [innerHTML]="(item?.Index ? item?.Index + '. ' : '')"></span>
                                        <span [innerHTML]="item?.Text" class="me-pix-3"
                                            [ngStyle]="{'color': listWidgetStyleConfig?.ItemFontStyle?.color ? listWidgetStyleConfig.ItemFontStyle.color 
                                               : (listWidgetConfig?.IsUseMakeTableColor ? item.Color : '')}">
                                        </span>
                                    </span>

                                    <ul class="display-flex flex-wrap"
                                        [ngStyle]="{'display': listWidgetStyleConfig?.GroupListBehavior? listWidgetStyleConfig?.GroupListBehavior: ''}">
                                        <li *ngFor="let subItem of item?.SubItems; let index=index;"
                                            [hidden]="!subItem?.IsInStock &&
                                        listWidgetConfig?.OutOfStockBehavior?.BehaviorType === outOfStockBehaviorTypes.Hide">
                                            <span [ngStyle]="listWidgetStyleConfig?.GroupedItemStyle">
                                                <span *ngIf="index || listWidgetStyleConfig?.GroupListBehavior != null"
                                                    class="ps-pix-1 pe-pix-1">&bull;</span>
                                                <span [innerHTML]="subItem.Text" [ngClass]="subItem?.InteractiveStyleClass"
                                                    [ngStyle]="{
                                                        'color': (subItem?.IsInStock ? '' : (listWidgetConfig?.OutOfStockBehavior?.ItemColor ? listWidgetConfig.OutOfStockBehavior.ItemColor :'lightgrey')),
                                                        'text-decoration': (subItem?.IsInStock ? '' : (listWidgetConfig?.OutOfStockBehavior?.TextDecoration))
                                                    }"></span>
                                                <span *ngIf="subItem.IsShowPrice" class="ps-pix-4 pe-pix-4">
                                                    <span *ngFor="let price of subItem.Prices; let last = last;">
                                                        <span
                                                            [ngClass]="subItem?.InteractiveStyleClass"
                                                            [ngStyle]="{'color': subItem?.IsInStock ? listWidgetStyleConfig?.PriceStyle?.color : (listWidgetConfig?.OutOfStockBehavior?.ItemColor ? listWidgetConfig.OutOfStockBehavior.ItemColor : 'lightgrey') }"
                                                            [innerHTML]="(price.Price | number: '0.2-2') + (last ? '' : '/')">
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </span>
                            <span [ngStyle]="listWidgetStyleConfig.TagConfig" *ngIf="item?.Tags?.length > 0 && !listWidgetConfig?.HideTags">
                                <span *ngFor="let tag of item.Tags">
                                    <pos-menu-image [imagePath]="tagIconPath" [image]="tag.Icon" [height]="tagIconSize"
                                        [width]="tagIconSize" *ngIf="tag.Icon" class="ps-pix-2"></pos-menu-image>
                                </span>
                            </span>

                            <span class="ps-pix-2" [innerHTML]="listWidgetConfig?.OutOfStockBehavior?.Text"
                                [ngStyle]="listWidgetConfig?.OutOfStockBehavior?.TextStyleConfig"
                                *ngIf="!item?.IsInStock && listWidgetConfig?.OutOfStockBehavior?.Text && !item?.WidgetProductGroupId">
                            </span>
                            
                            <div *ngIf="item?.Description && listWidgetConfig?.IsShowItemDescription"
                                [ngStyle]="{'line-height': listWidgetStyleConfig?.DescriptionStyle?.fontSize }">
                                <div [innerHTML]="item.Description" [ngStyle]="listWidgetStyleConfig?.DescriptionStyle">
                                </div>
                            </div>
                        </span>

                        <span *ngIf="item?.Prices?.length"
                            [hidden]="(!item?.IsInStock && listWidgetConfig?.OutOfStockBehavior?.BehaviorType === outOfStockBehaviorTypes.Hide)"
                            [ngStyle]="{'min-width': (!item.Prices?.length && listWidgetConfig?.IsShowProductPrice) ? '30px' : '',
                                'line-height': listWidgetStyleConfig?.PriceStyle?.fontSize,
                                'width': listWidgetStyleConfig?.PriceTableConfig?.ShowPriceAsTable ? (listWidgetStyleConfig?.PriceTableConfig?.Width ? listWidgetStyleConfig?.PriceTableConfig?.Width + '%' : '' ) : ''}">
                            <span class="float-end text-nowrap pe-pix-5" [ngStyle]="listWidgetStyleConfig?.PriceStyle ?? listWidgetStyleConfig?.ItemFontStyle"
                                *ngIf="item.IsShowPrice || this.listWidgetConfig?.IsShowProductPrice" style="width: 100%;">
                                <span *ngIf="!listWidgetStyleConfig?.PriceTableConfig?.ShowPriceAsTable">
                                    <span *ngFor="let price of item.Prices | filterSize: this.listWidgetConfig.ShowPriceOfSize; let last = last;">
                                        <span [ngStyle]="{'color':price?.IsInStock?'': listWidgetConfig?.OutOfStockBehavior?.ItemColor ? listWidgetConfig.OutOfStockBehavior.ItemColor : 'grey'}"
                                            [innerHTML]="(listWidgetConfig?.IsShowPriceSize ? price.MakeTableSize + ' ' : '') + (price.Price | number: '0.2-2') + (last ? '' : '/')"> </span>
                                    </span>
                                </span>

                                <span class="price-table" *ngIf="listWidgetStyleConfig?.PriceTableConfig?.ShowPriceAsTable">
                                    <span *ngFor="let price of item.SizePrice; let last = last;" class="price-table-cell"
                                        [ngStyle]="{'--price-table-cell-width': ( (100 / item.SizePrice.length) + '%'),
                                            'padding': listWidgetStyleConfig?.PriceTableConfig?.CellPadding
                                        }">
                                        <span
                                            [ngStyle]="{'color':price?.IsInStock?'': listWidgetConfig?.OutOfStockBehavior?.ItemColor ? listWidgetConfig.OutOfStockBehavior.ItemColor : 'grey'}"
                                            [innerHTML]="(price?.Price | number: '0.2-2')"> </span>
                                    </span>
                                </span>
                            </span>
                        </span>
                        <span *ngIf="!item?.Prices?.length" [innerHTML]="item?.Price"
                            [ngClass]="item?.InteractiveStyleClass"
                            [ngStyle]="listWidgetStyleConfig?.PriceStyle ?? listWidgetStyleConfig?.ItemFontStyle" class="pe-pix-5">
                        </span>
                    </div>
                </div>
            </div>
            <hr *ngIf="!last && (preparedMenuDisplayItems?.length > 1) && listWidgetStyleConfig?.SeparatorStyle?.BorderStyle != 'none'"
                [ngStyle]="{'border-style': listWidgetStyleConfig?.SeparatorStyle?.BorderStyle ?? 'solid'}">
        </div>
    </div>
    <div *ngIf="listWidgetConfig?.FooterText" class="p-pix-3 text-center" [innerHTML]="listWidgetConfig?.FooterText"
        [ngStyle]="listWidgetStyleConfig?.FooterFontStyle"></div>
</div>
