<pos-edit-wrapper heading="{{this.salesCategoryId > 0 ? 'Edit Sales Category' : 'Add Sales Category'}}"
    [isPopup]="isPopup" (close)="cancelEditing()" [headingIcon]="icons.salesCategoriesCommentDollar">
    <form #salesCategoryForm="ngForm" (ngSubmit)="saveSalesCategory(salesCategoryForm.valid)" class="edit-form"
        autocomplete="off">
        <context-provider provide="props">
            <div class="col-12 container-fluid" [ngClass]="{'border-top pt-pix-10': !isPopup}">
                <div class="vertical-tabs">
                    <ul class="nav nav-tabs inner-tab pos-nav-tabs">
                        <li [ngClass]="{'active': selectedTab == tabList.SalesCategorySettings }">
                            <a (click)="selectedTab = tabList.SalesCategorySettings" class="blue"
                                [ngClass]="{'required': salesCategorySettings.invalid }">
                                <pos-icon [icon]="icons.salesCategoriesTh" class="pe-pix-5 theme-separator"></pos-icon> Sales
                                Category
                            </a>
                        </li>
                        <li [ngClass]="{'active': selectedTab == tabList.SalesCategorySizes }">
                            <a (click)="selectedTab = tabList.SalesCategorySizes" class="blue"
                                [ngClass]="{'required': salesCategorySizes.invalid }">
                                <pos-icon [icon]="icons.salesSizesFunnelDollar" class="pe-pix-5 theme-separator"></pos-icon>
                                Sizes
                            </a>
                        </li>
                        <li [ngClass]="{'active': selectedTab == tabList.TimeSetting }">
                            <a (click)="selectedTab = tabList.TimeSetting" class="blue">
                                <pos-icon [icon]="icons.salesCategoriesClock" class="pe-pix-5 theme-separator"></pos-icon>Timer
                                Setting
                            </a>
                        </li>
                    </ul>
                </div>
                <div>
                    <div [hidden]="selectedTab != tabList.SalesCategorySettings" ngModelGroup="salesCategorySettings"
                        #salesCategorySettings="ngModelGroup">
                        <div class="overflow-auto-tabs width-full row pos-tab-body"
                            [ngStyle]="{'height': getHeight()}">
                            <div class="col-12">
                                <pos-row>
                                    <pos-column>
                                        <pos-form-field label="Category Name"
                                            [validationMessages]="{required: 'Please enter group name.'}">
                                            <input posInput name="salesCategoryName" id="salesCategoryName"
                                                #salesCategoryName="ngModel" class="form-control"
                                                [(ngModel)]="salesCategory.name" [required]="true" />
                                        </pos-form-field>
                                    </pos-column>
                                    <pos-column>
                                        <pos-form-field label="Make Table Color">
                                            <pos-color-picker posInput [(ngModel)]="salesCategory.make_table_color"
                                                name="makeTableColor"></pos-color-picker>
                                        </pos-form-field>
                                    </pos-column>
                                    <pos-column>
                                        <pos-form-field label="Sales Group"
                                            [validationMessages]="{required:'Please select sales group.'}">
                                            <div class="row row-cols-sm-2 row-cols-1 mx-0">
                                                <div class="ps-0">
                                                    <ng-select posInput [items]="salesGroupList" bindLabel="name"
                                                        bindValue="Id" placeholder="Select sales group" appendTo="body"
                                                        [(ngModel)]="salesCategory.sales_group_id" name="salesGroup"
                                                        id="salesGroup" #salesGroup="ngModel"
                                                        (change)="getSalesCategoryKitchenPrintersForInfo(salesCategory.sales_group_id)"
                                                        [required]="true">
                                                    </ng-select>
                                                </div>
                                                <div class="pt-pix-6 ps-0" permissions='[{"Name":"{{permission.salesGroup}}", "Level":"{{permission.editLevel}}"}]'>
                                                    <span (click)="addEditGroup(0)">
                                                        <pos-action-icon [icon]="icons.plusWhite" iconText="Add">
                                                        </pos-action-icon>
                                                    </span>
                                                    <span (click)="addEditGroup(salesCategory.sales_group_id)">
                                                        <pos-action-icon class="text-nowrap ms-pix-5"
                                                            [icon]="icons.editWhiteWithText" iconText="Edit">
                                                        </pos-action-icon>
                                                    </span>
                                                </div>
                                            </div>
                                        </pos-form-field>
                                    </pos-column>
                                    <pos-column>
                                        <pos-form-field>
                                            <div class="display-flex">
                                                <p-checkbox posInput name="isSkipReadyState"
                                                    [(ngModel)]="salesCategory.IsSkipReadyState" binary="true"
                                                    [disabled]="!isMakeTableReadyState" label="Skip Ready State">
                                                </p-checkbox>
                                                <field-info *ngIf="!isMakeTableReadyState" info="{{infoMakeTableReadyState}}"
                                                    class="pull-right pt-0">
                                                </field-info>
                                                <field-info info="{{infoSalesCategory.isReadyState}}"
                                                    class="pull-right pt-0"></field-info>
                                            </div>
                                        </pos-form-field>
                                    </pos-column>
                                    <pos-column>
                                        <pos-form-field label="Kitchen Printer"
                                            [fieldInfo]="salesCategory.sales_group_id ? kitchenPrinterInfo : ''">
                                            <ng-select posInput [items]="kitchenPrinters" name="kitchenPrinters"
                                                [multiple]="true" [hideSelected]="true" bindLabel="Name"
                                                placeholder="Select kitchen printer(s)" appendTo="body"
                                                [(ngModel)]="selectedSalesCategoryKitchenPrinters">
                                            </ng-select>
                                        </pos-form-field>
                                    </pos-column>
                                    <pos-column>
                                        <pos-form-field>
                                            <p-checkbox posInput name="isPrintToLabels"
                                                [(ngModel)]="salesCategory.IsPrintToLabels" binary="true"
                                                label="Print Product Labels">
                                            </p-checkbox>
                                        </pos-form-field>
                                    </pos-column>
                                    <pos-column>
                                        <pos-form-field label="Label Printer"
                                            [validationMessages]="{required: 'Please select label printer.'}">
                                            <ng-select posInput [required]="true" [items]="labelPrinters" bindLabel="Name"
                                                bindValue="Id" [disabled]="!salesCategory.IsPrintToLabels" appendTo="body"
                                                placeholder="Select label printer" [(ngModel)]="salesCategory.LabelPrinter"
                                                name="labelPrinter">
                                            </ng-select>
                                        </pos-form-field>
                                    </pos-column>
                                    <pos-column>
                                        <pos-form-field>
                                            <div class="btn-group">
                                                <label class="btn btn-secondary btn-radio"
                                                    [ngClass]="{'active btn-primary normal': salesCategory.IsActive, 'btn-secondary': !salesCategory.IsActive}">
                                                    <input posInput [(ngModel)]="salesCategory.IsActive" [value]="true"
                                                        type="radio" name="isActive"> Active
                                                </label>
                                                <label class="btn btn-radio"
                                                    [ngClass]="{'active btn-danger normal': !salesCategory.IsActive, 'btn-secondary': salesCategory.IsActive}">
                                                    <input [(ngModel)]="salesCategory.IsActive" [value]="false" type="radio"
                                                        name="isActive">
                                                    Inactive
                                                </label>
                                            </div>
                                        </pos-form-field>
                                    </pos-column>
                                </pos-row>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="selectedTab == tabList.TimeSetting">
                        <div class="overflow-auto-tabs width-full row pos-tab-body" [ngStyle]="{'height': getHeight()}">
                            <div class="col-12">
                                <pos-row>
                                    <pos-column>
                                        <pos-form-field label="Prep Group">
                                            <input posInput class="form-control" name="prepGroupName"
                                                [(ngModel)]="salesCategory.PrepGroupName" type="text" />
                                        </pos-form-field>
                                    </pos-column>
                                    <pos-column>
                                        <pos-form-field>
                                            <p-checkbox posInput name="isTimedPrep" label="Timed Prep"
                                                [(ngModel)]="salesCategory.IsTimedPrep" binary="true"
                                                [disabled]="!isMakeTableCookingState">
                                            </p-checkbox>
                                        </pos-form-field>
                                    </pos-column>
                                    <pos-column>
                                        <pos-form-field>
                                            <p-checkbox posInput name="isUseWorkflowTimer" label="Use Workflow Timer"
                                                [(ngModel)]="salesCategory.IsUseWorkflowTimer" binary="true"
                                                [disabled]="!salesCategory.IsTimedPrep || !isMakeTableCookingState">
                                            </p-checkbox>
                                        </pos-form-field>
                                    </pos-column>
                                    <pos-column>
                                        <pos-form-field label="Prep Time"
                                            [validationMessages]="{min: 'Prep time must be greater than zero.'}">
                                            <div class="row row-cols-2">
                                                <div class="col-120">
                                                    <input posInput type="number" id="prepTime" name="prepTime" min="1"
                                                        class="form-control"
                                                        [disabled]="!salesCategory.IsTimedPrep || !isMakeTableCookingState"
                                                        [(ngModel)]="salesCategory.PrepTime"
                                                        (ngModelChange)="secondsConvertToTime(salesCategory.PrepTime)" />
                                                </div>
                                                <div>
                                                    <label class="edit-form__section__label ps-pix-10">{{prepSecondsConvertToTime | date:'HH:mm:ss'}}</label>
                                                </div>
                                            </div>
                                        </pos-form-field>
                                    </pos-column>
                                    <pos-column>
                                        <pos-form-field label="Prep Button Color">
                                            <pos-color-picker posInput [(ngModel)]="salesCategory.PrepButtonColor"
                                                [disabled]="!salesCategory.IsTimedPrep || !isMakeTableCookingState"
                                                name="prepButtonColor"></pos-color-picker>
                                        </pos-form-field>
                                    </pos-column>
                                </pos-row>
                            </div>
                        </div>
                    </div>
                    <div [hidden]="selectedTab != tabList.SalesCategorySizes"  ngModelGroup="salesCategorySizes" #salesCategorySizes="ngModelGroup">
                        <div class="overflow-auto-tabs width-full row pos-tab-body" [ngStyle]="{'height': getHeight()}">
                            <div class="col-12 pt-pix-10">
                                <pos-row>

                                    <pos-column sm="6" xs="12">
                                        <ng-table 
                                            [data]="selectedSalesCategorySizes" 
                                            [columns]="salesCategorySizesColumns"
                                            [paginator]="false"
                                            [search]="false">
                                        </ng-table>

                                        <ng-template let-data="rowData" #operationTemplate>
                                            <div class="d-flex justify-content-center">
                                                <span tooltip title="Edit" (click)="editSalesCategorySize(data.SizeId, data)" scrollup>
                                                    <pos-action-icon [icon]="icons.editWhite"></pos-action-icon>
                                                </span>
                                                <span tooltip title="Delete" (click)="deleteSalesCategorySize(data)">
                                                    <pos-action-icon [icon]="icons.deleteWhite"></pos-action-icon>
                                                </span>
                                            </div>
                                        </ng-template>
                                
                                        <ng-template let-data="rowData" #operationHeaderTemplate>
                                            <div class="text-center">
                                                <pos-action-icon [icon]="icons.plusWhite" (click)="editSalesCategorySize(0)"
                                                    iconText="Add"></pos-action-icon>
                                            </div>
                                        </ng-template>
                                    </pos-column>

                                    <pos-column>
                                        <pos-form-field label="Default Size"
                                            [validationMessages]="{required: 'Please select default size.'}"
                                            inputStyle="col-lg-3 col-md-3 col-sm-5 col-xs-12">
                                            <ng-select posInput [required]="true" [items]="selectedSalesCategorySizes"
                                                bindLabel="SaleSizes.Name" bindValue="SizeId" appendTo="body"
                                                placeholder="Select sales size"
                                                [(ngModel)]="salesCategory.DefaultSizeId" name="defaultSize">
                                            </ng-select>
                                        </pos-form-field>
                                    </pos-column>
                                </pos-row>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 action-button-container"
                [ngClass]="!isPopup ? 'page-footer' : 'popup-footer'">
                <button class="btn-primary btn-lg btn btn-save pos-button" type="submit" value="Save">Save</button>
                <button class="btn-secondary btn-lg btn btn-cancel pos-button" [ngClass]="{'btn-cancel-modal': isPopup}"
                    (click)="cancelEditing()" type="button" value="Cancel">Cancel</button>
            </div>
        </context-provider>
    </form>
</pos-edit-wrapper>

<ng-template #deleteSizeConfirmation>
    <pos-edit-wrapper heading="Confirm Delete Size(s)" (close)="cancelConflictedSizes()">
        <div class="col-12 pos-modal-body screen-scroll">
            <div>
                {{confirmConflictDeleteSize}}
            </div>
            <div *ngIf="activeProductsConflictedSizes.length > 0">
                <h3 class="text-center theme-separator pb-pix-5">Active Products</h3>
                <ng-table [sort]="false" [search]="false" [paginator]="false" [columns]="conflictedSizeColumns"
                    [data]="activeProductsConflictedSizes" [scrollable]="true" [scrollHeight]="'200px'">
                </ng-table>
            </div>
            <div [ngClass]="{'pt-pix-10': activeProductsConflictedSizes.length > 0}"
                *ngIf="inactiveProductsConflictedSizes.length > 0">
                <h3 class="text-center theme-separator pb-pix-5">Inactive Products</h3>
                <ng-table [sort]="false" [search]="false" [paginator]="false" [columns]="conflictedSizeColumns"
                    [data]="inactiveProductsConflictedSizes" [scrollable]="true" [scrollHeight]="'200px'">
                </ng-table>
            </div>
        </div>
        <div class="col-xs-12 action-button-container popup-footer">
            <button class="btn-primary btn-lg white btn btn-save pos-button" type="button" (click)="confirmDeleteConflictedSizes()"
                value="Continue">Continue</button>
            <button class="btn-secondary btn-lg btn btn-cancel pos-button" (click)="cancelConflictedSizes()" type="button"
                value="Cancel">Cancel</button>
        </div>
    </pos-edit-wrapper>
</ng-template>
