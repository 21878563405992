import { Injectable } from '@angular/core';
import { ExchangeInfo } from '../models/exchange-info';
import { ExchangeNames, ExchangeTypes, RoutingKeys } from '../constant/RabbitMQExchangeFactoryConstants';

@Injectable({
    providedIn: 'root'
})
export class RabbitMQExchangeFactoryService {

    getMakeTableActivityExchangeByMakeTable(makeTableTerminalId) {
        return new ExchangeInfo(ExchangeNames.TERMINALS_MAKETABLEACTIVITY,
            ExchangeTypes.TOPIC,
            makeTableTerminalId
        );
    }

    getMakeTableActivityExchangeForAllMakeTables() {
        return new ExchangeInfo(ExchangeNames.TERMINALS_MAKETABLEACTIVITY,
            ExchangeTypes.TOPIC,
            '*'
        );
    }

    getInvoiceExchange(orderEntryTerminalId) {
        return new ExchangeInfo(ExchangeNames.TERMINALS_INVOICE,
            ExchangeTypes.TOPIC,
            orderEntryTerminalId
        );
    }

    getAudioRecordingsExchange(terminalId) {
        return new ExchangeInfo(ExchangeNames.TERMINALS,
            ExchangeTypes.DIRECT,
            `${RoutingKeys.AudioRecordings}.${terminalId}`
        );
    }

    getAudioRecordingsForOrderExchange(terminalId) {
        return new ExchangeInfo(ExchangeNames.TERMINALS,
            ExchangeTypes.DIRECT,
            `${RoutingKeys.AudioRecordingsForOrder}.${terminalId}`
        );
    }

    getAudioFileExchange(terminalId) {
        return new ExchangeInfo(ExchangeNames.TERMINALS,
            ExchangeTypes.DIRECT,
            `${RoutingKeys.AudioRecordFile}.${terminalId}`
        );
    }

    getOrderEntryTerminalExchange(terminalId) {
        return new ExchangeInfo(ExchangeNames.ORDER_PAYMENT_RESPONSE,
            ExchangeTypes.TOPIC,
            terminalId
        );
    }
    
    getHICRequestExchange(hicGuid: string, requestTypes: string, hardwareId: number) {
        return new ExchangeInfo(ExchangeNames.HIC_REQUEST,
            ExchangeTypes.TOPIC,
            hicGuid.toLowerCase() + "." + requestTypes + "." + hardwareId.toString()
        );
    }

    getMakeTableExchange(orderEntryTerminalId) {
        return new ExchangeInfo(ExchangeNames.TERMINALS_MAKETABLE,
            ExchangeTypes.TOPIC,
            orderEntryTerminalId
        );
    }

    getHICResponseToTerminalExchange(terminalId) {
        return new ExchangeInfo(ExchangeNames.HIC_RESPONSE,
            ExchangeTypes.TOPIC,
            terminalId
        );
    }

    getActivityReportingExchange(service, activityType) {
        return new ExchangeInfo(ExchangeNames.ACTIVITY_REPORTING,
            ExchangeTypes.TOPIC,
            service + '.' + activityType
        );
    }

    getTimerTerminalBroadcastExchange() {
        return new ExchangeInfo(ExchangeNames.TERMINALS_TIMER,
            ExchangeTypes.TOPIC,
            RoutingKeys.ALL
        );
    }

    getInstructionsTerminalBroadcastExchange() {
        return new ExchangeInfo(ExchangeNames.TERMINALS_INSTRUCTION,
            ExchangeTypes.TOPIC,
            RoutingKeys.ALL
        );
    }

    getUserTrainingBadgeExchange() {
        return new ExchangeInfo(ExchangeNames.USER_TRAINING_BADGE,
            ExchangeTypes.TOPIC,
            RoutingKeys.ALL);
    }
    
    exchangeInfo(exchangeName, exchangeType, routingKey) {
        return new ExchangeInfo(exchangeName,
            exchangeType,
            routingKey
        );
    }
    getSalesProductOutOfStockEventUpdate() {
        return new ExchangeInfo(ExchangeNames.EVENT,
            ExchangeTypes.TOPIC,
            RoutingKeys.SalesProductOutOfStockEvent
        );
    }

    getSalesProductReplenishedEventUpdate() {
        return new ExchangeInfo(ExchangeNames.EVENT,
            ExchangeTypes.TOPIC,
            RoutingKeys.SalesProductReplenishedEvent
        );
    }

    getCloseCashDrawerEventUpdate() {
        return new ExchangeInfo(ExchangeNames.EVENT,
            ExchangeTypes.TOPIC,
            RoutingKeys.CashDrawerClosedEvent
        );
    }

    getCashDrawerOpenedEventUpdate() {
        return new ExchangeInfo(ExchangeNames.EVENT,
            ExchangeTypes.TOPIC,
            RoutingKeys.CashDrawerOpenedEvent
        );
    }

    getInventoryExhaustionEventUpdate() {
        return new ExchangeInfo(ExchangeNames.EVENT,
            ExchangeTypes.TOPIC,
            RoutingKeys.InventoryExhaustionEvent
        );
    }
    
    getOrderDeletedExchange(orderEntryTerminalId) {
        return new ExchangeInfo(ExchangeNames.TERMINALS_MAKETABLE,
            ExchangeTypes.TOPIC,
            RoutingKeys.OrderDelete + '.' + orderEntryTerminalId    
        );
    }

    getOrderDeletedForAllOrderEntryExchange() {
        return new ExchangeInfo(ExchangeNames.TERMINALS_MAKETABLE,
            ExchangeTypes.TOPIC,
            `${RoutingKeys.OrderDelete}.*`
        );
    }

    getOrderServedForAllOrderEntryExchange() {
        return new ExchangeInfo(ExchangeNames.TERMINALS,
            ExchangeTypes.DIRECT,
            `${RoutingKeys.OrderServed}.${RoutingKeys.ALL}`
        );
    }

    getOrderUpdatedExchange(orderEntryTerminalId) {
        return new ExchangeInfo(ExchangeNames.TERMINALS_MAKETABLE,
            ExchangeTypes.TOPIC,
            `${RoutingKeys.OrderUpdate}.${orderEntryTerminalId}`
        );
    }

    getOrderUpdatedForAllOrderEntryExchange() {
        return new ExchangeInfo(ExchangeNames.TERMINALS_MAKETABLE,
            ExchangeTypes.TOPIC,
            `${RoutingKeys.OrderUpdate}.*`
        );
    }

    getOrderItemDeleteExchange() {
        return new ExchangeInfo(ExchangeNames.TERMINALS,
            ExchangeTypes.DIRECT,
            `${RoutingKeys.OrderItemDelete}.${RoutingKeys.ALL}`
        );
    }

    getOrderItemStateChangedExchange(orderEntryTerminalId) {
        return new ExchangeInfo(ExchangeNames.TERMINALS_MAKETABLE,
            ExchangeTypes.TOPIC,
            `${RoutingKeys.OrderItemStateChange}.${orderEntryTerminalId}`
        );
    }

    getOrderItemStateChangedAllOrderEntryExchange() {
        return new ExchangeInfo(ExchangeNames.TERMINALS_MAKETABLE,
            ExchangeTypes.TOPIC,
            `${RoutingKeys.OrderItemStateChange}.*`
        );
    }

    getOrderItemQtyChangedExchange() {
        return new ExchangeInfo(ExchangeNames.TERMINALS,
            ExchangeTypes.DIRECT,
            `${RoutingKeys.OrderItemQtyChange}.${RoutingKeys.ALL}`
        );
    }

    getTerminalUseResponseExchange(terminalId) {
        return new ExchangeInfo(ExchangeNames.TERMINALS,
            ExchangeTypes.DIRECT,
            `${RoutingKeys.TerminalUseResponse}.${terminalId}`
        );
    }

    getOrderKioskStateChangeExchange() {
        return new ExchangeInfo(ExchangeNames.TERMINAL_ORDER_KIOSK,
            ExchangeTypes.TOPIC,
            `${RoutingKeys.OrderKioskStateUpdated}`
        )
    }


    getTerminalUseExchange() {
        return new ExchangeInfo(ExchangeNames.TERMINALS,
            ExchangeTypes.DIRECT,
            `${RoutingKeys.TerminalUse}.${RoutingKeys.ALL}`
        );
    }


    getTerminalLogoutExchange(terminalId) {
        return new ExchangeInfo(ExchangeNames.TERMINALS,
            ExchangeTypes.DIRECT,
            `${RoutingKeys.TerminalLogout}.${terminalId}`
        );
    }

    getProductLabelPrintingParameterExchange() {
        return new ExchangeInfo(ExchangeNames.TERMINALS_ORDERENTRY,
            ExchangeTypes.TOPIC,
            `${RoutingKeys.LabelPrintingParameterChange}.${RoutingKeys.ALL}`);
    }

    getRefreshActiveOrderExchange() {
        return new ExchangeInfo(ExchangeNames.TERMINALS,
            ExchangeTypes.DIRECT,
            `${RoutingKeys.RefreshActiveOrder}.${RoutingKeys.ALL}`
            
        );
    }

    getGlobalLogoffExchange() {
        return new ExchangeInfo(ExchangeNames.TERMINALS,
            ExchangeTypes.DIRECT,
            `${RoutingKeys.GlobalLogoff}.${RoutingKeys.ALL}`
        );
    }

    getClockOutExchange() {
        return new ExchangeInfo(ExchangeNames.TERMINALS,
            ExchangeTypes.DIRECT,
            `${RoutingKeys.ClockOut}.${RoutingKeys.ALL}`
        );
    }

    getClockInEventExchange() {
        return new ExchangeInfo(ExchangeNames.EVENT,
            ExchangeTypes.TOPIC,
            RoutingKeys.UserClockInEvent
        );
    }

    getTerminalMessageExchange() {
        return new ExchangeInfo(ExchangeNames.TERMINALS,
            ExchangeTypes.DIRECT,
            `${RoutingKeys.TerminalMessage}.${RoutingKeys.ALL}`
        );
    }

    getTerminalMessageMarkAsReadExchange() {
        return new ExchangeInfo(ExchangeNames.TERMINALS,
            ExchangeTypes.DIRECT,
            `${RoutingKeys.TerminalMessageMarkAsRead}.${RoutingKeys.ALL}`
        );
    }
    
    getRequireNameOnOrderTemporaryDisableParameterExchange() {
        return new ExchangeInfo(ExchangeNames.TERMINALS_ORDERENTRY, 
            ExchangeTypes.TOPIC, 
            `${RoutingKeys.RequireNameOnOrderTemporaryDisableParameter}.${RoutingKeys.ALL}`
        );
    }

    getDJSpeakTextExchange(terminalId) {
        return new ExchangeInfo(ExchangeNames.TERMINALS,
            ExchangeTypes.DIRECT,
            `${RoutingKeys.DJSpeakText}.${terminalId}`
        );
    }

    getScheduleOrderUpdateExchange() {
        return new ExchangeInfo(ExchangeNames.TERMINALS_MAKETABLE,
            ExchangeTypes.TOPIC,
            `${RoutingKeys.ScheduleOrderUpdate}.*`
        );
    }

    getOrderAlreadyOpenExchange(terminalId) {
        return new ExchangeInfo(ExchangeNames.TERMINALS_ORDERENTRY,
            ExchangeTypes.TOPIC,
            `${RoutingKeys.OrderAlreadyOpen}.${terminalId}`
        );
    }

    getOrderCloseRequestExchange(terminalId) {
        return new ExchangeInfo(ExchangeNames.TERMINALS_ORDERENTRY,
            ExchangeTypes.TOPIC,
            `${RoutingKeys.OrderCloseRequest}.${terminalId}`
        );
    }

    getHICCreditCardTransactionResponseExchange() {
        return new ExchangeInfo(ExchangeNames.HIC_RESPONSE,
            ExchangeTypes.TOPIC,
            `${RoutingKeys.HICCreditCardTransactionResponse}.*`
        );
    } 

    getOrderIsOpenCheckExchange() {
        return new ExchangeInfo(ExchangeNames.TERMINALS_ORDERENTRY,
            ExchangeTypes.TOPIC,
            `${RoutingKeys.OrderIsOpen}.*`
        );
    }

    getTerminalWarningExchange() {
        return new ExchangeInfo(ExchangeNames.TERMINALS_ORDERENTRY,
            ExchangeTypes.TOPIC,
            `${RoutingKeys.TerminalWarning}.*`
        );
    }

    getOrderReviewedExchange(orderEntryTerminalId) {
        return new ExchangeInfo(ExchangeNames.TERMINALS_INVOICE,
            ExchangeTypes.TOPIC,
            `${RoutingKeys.OrderReviewed}.${orderEntryTerminalId}`
        );
    }

    getReviewOrderExchange(orderEntryTerminalId) {
        return new ExchangeInfo(ExchangeNames.TERMINALS_INVOICE,
            ExchangeTypes.TOPIC,
            `${RoutingKeys.ReviewOrder}.${orderEntryTerminalId}`
        );
    }

    getCloseReviewOrderModalExchange(orderEntryTerminalId) {
        return new ExchangeInfo(ExchangeNames.TERMINALS_INVOICE,
            ExchangeTypes.TOPIC,
            `${RoutingKeys.CloseReviewOrderModal}.${orderEntryTerminalId}`
        );
    }

    getRefreshMenuDisplayExchange() {
        return new ExchangeInfo(ExchangeNames.TERMINAL_MENU_DISPLAY_ACTIVITY,
            ExchangeTypes.TOPIC,
            `${RoutingKeys.RefreshMenuDisplay}.${RoutingKeys.ALL}`
        );
    }

    getMenuDisplayHighlightItemExchange() {
        return new ExchangeInfo(ExchangeNames.TERMINAL_MENU_DISPLAY_ACTIVITY,
            ExchangeTypes.TOPIC,
            `${RoutingKeys.MenuDisplayHighlightItem}.${RoutingKeys.ALL}`
        );
    }

    getMenuDisplayFilterExchange() {
        return new ExchangeInfo(ExchangeNames.TERMINAL_MENU_DISPLAY_ACTIVITY,
            ExchangeTypes.TOPIC,
            `${RoutingKeys.MenuDisplayFilter}.${RoutingKeys.ALL}`
        );
    }

    getRefreshDashboardExchange() {
        return new ExchangeInfo(ExchangeNames.TERMINALS,
            ExchangeTypes.DIRECT,
            `${RoutingKeys.RefreshDashboard}.${RoutingKeys.ALL}`
        );
    }

    getTerminalRefreshExchange(terminalId) {
        return new ExchangeInfo(ExchangeNames.TERMINALS,
            ExchangeTypes.TOPIC,
            `${RoutingKeys.RefreshTerminal}.${terminalId}`
        );
    }
    getAutoSignOutUserExchange() {
        return new ExchangeInfo(ExchangeNames.TERMINALS,
            ExchangeTypes.DIRECT,
            `${RoutingKeys.AutoSignOutUser}.${RoutingKeys.ALL}`
        );
    }

    getTerminalsStatusCheckInfo(terminalId) {
        return new ExchangeInfo(ExchangeNames.RPC,
            ExchangeTypes.TOPIC,
            `${RoutingKeys.TerminalStatusCheck}.${terminalId}`  
        );
    }

    getRpcServerExchange(correlationId: string) {
        return new ExchangeInfo(ExchangeNames.RPC,
            ExchangeTypes.TOPIC,
            correlationId
        );
    }

    getSalesProductChangedExchange() {
        return new ExchangeInfo(ExchangeNames.SALES_PRODUCT,
            ExchangeTypes.TOPIC,
            `${RoutingKeys.SalesProductUpdated}.${RoutingKeys.ALL}`
        )
    }

    getOrderPhoneNumberModalCloseExchangeInfo(orderEntryTerminalId) {
        return new ExchangeInfo(ExchangeNames.TERMINALS,
            ExchangeTypes.TOPIC,
            `${RoutingKeys.OrderPhoneNumberModalClose}.${orderEntryTerminalId}`
        )
    }

    getOrderPhoneNumberModalOpenExchangeInfo(orderEntryTerminalId) {
        return new ExchangeInfo(ExchangeNames.TERMINALS,
            ExchangeTypes.TOPIC,
            `${RoutingKeys.OrderPhoneNumberModalOpen}.${orderEntryTerminalId}`
        )
    }
}
