<div class="m-0-auto px-pix-10">
    <pos-list-wrapper [headingIcon]="icons.buttonsDiceOne" heading="Kiosk Button" (close)="cancel()">
        <div class="col-12 pb-pix-5">
            <ng-table [columns]="gridColumns" [data]="buttons" #kioskChoices>
            </ng-table>
        </div>

        <ng-template let-data="rowData" #nameTemplate>
            <span class="col-30 d-inline-block text-center pe-pix-3"><img src="{{imageRootPath}}{{data.Image}}" height="20" *ngIf="data.Image" /></span>&nbsp; {{ data.ButtonText }}
        </ng-template>

        <ng-template let-data="rowData" #operationHeaderTemplate>
            <span (click)="editItem(0)" tooltip title="Add">
                <pos-action-icon [icon]="icons.plusWhite" iconText="Add"></pos-action-icon>
            </span>
            <pos-export-dropdown class="inline-block ps-pix-5" (onPrint)="printData()"
                (onCSV)="exportCSV()"></pos-export-dropdown>
        </ng-template>

        <ng-template let-data="rowData" #operationTemplate>
            <span class="pe-pix-3" (click)="editItem(data.Id)" tooltip title="Edit">
                <pos-action-icon [icon]="icons.editWhite"></pos-action-icon>
            </span>
            <span class="pe-pix-3" (click)="confirmDelete(data)" tooltip title="Delete">
                <pos-action-icon [icon]="icons.deleteWhite"></pos-action-icon>
            </span>
        </ng-template>
    </pos-list-wrapper>
</div>
<pos-print-table [hidden]="true"></pos-print-table>
