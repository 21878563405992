<ng-template #countDrawerPopoverTemplate>
	<div class="form-group" *ngIf="showDrawerPopover">
		<ul>
			<li>
				<a class="inventory-menu-link" (click)="drawerCheckPoint()">
					<img class="inventory-menu-link__icon" src="images/admin/manage-loginventory.png" />Drawer
					Checkpoint
				</a>
			</li>
			<li>
				<a class="inventory-menu-link" (click)="closeDrawer()">
					<img class="inventory-menu-link__icon" src="images/admin/manage-close-drawer.png" />Close Drawer
				</a>
			</li>
		</ul>
	</div>
</ng-template>

<ng-template #inventoryModulePopoverTemplate>
	<div class="col-12" *ngIf="showInventoryPopover">
		<ul>
			<li permissions='[{"Name":"{{permissions.InventoryLogInventory}}", "Level":"{{accessLevels.Access}}"}]'>
				<a (click)="loadLogInventory()" class="inventory-menu-link no-decoration">
					<img src="images/admin/manage-loginventory.png" class="inventory-menu-link__icon" />Inventory Log
				</a>
			</li>
			<li permissions='[{"Name":"{{permissions.InventoryLogInventory}}", "Level":"{{accessLevels.Access}}"}]'>
				<a (click)="loadInventoryBarcodeScanner()" class="inventory-menu-link no-decoration">
					<pos-icon class="inventory-menu-link__icon font-20 grey vertical-mid"
						[icon]="icons.barcode"></pos-icon>Inventory Scanning
				</a>
			</li>
			<li permissions='[{"Name":"{{permissions.InventoryOrderReceiving}}", "Level":"{{accessLevels.Access}}"}]'>
				<a (click)="loadInventoryReceiving()" class="inventory-menu-link no-decoration">
					<img src="images/admin/inventory-receiving.png" class="inventory-menu-link__icon" />Inventory
					Receiving
				</a>
			</li>
			<li permissions='[{"Name":"{{permissions.InventoryInventoryLoss}}", "Level":"{{accessLevels.Access}}"}]'>
				<a (click)="loadInventoryProductLoss()" class="inventory-menu-link no-decoration">
					<img src="images/admin/inventory-loss.png" class="inventory-menu-link__icon" />Inventory Loss
				</a>
			</li>
			<li permissions='[{"Name":"{{permissions.InventoryInventoryPrep}}", "Level":"{{accessLevels.Access}}"}]'>
				<a (click)="loadInventoryProductPrep()" class="inventory-menu-link no-decoration">
					<img src="images/admin/inventory-prep.png" class="inventory-menu-link__icon" />Inventory Prep
				</a>
			</li>
			<li permissions='[{"Name":"{{permissions.InventoryQuickSearch}}", "Level":"{{accessLevels.Access}}"}]'>
				<a (click)="loadQuickSearch()" class="inventory-menu-link no-decoration">
					<img src="images/admin/manage-magnifying-glass.png" class="inventory-menu-link__icon"
						style="height:22px" />Quick Search
				</a>
			</li>
			<li
				permissions='[{"Name":"{{permissions.InventoryBatchMarkAsOrdered}}", "Level":"{{accessLevels.Access}}"}]'>
				<a (click)="loadBatchMarkAsOrdered()" class="inventory-menu-link no-decoration">
					<pos-icon class="inventory-menu-link__icon font-20 grey vertical-mid"
						[icon]="icons.checkCircleSolid"></pos-icon>
					<span class="vertical-mid"> Batch Mark as Ordered </span>
				</a>
			</li>
			<li
				permissions='[{"Name":"{{permissions.InventoryInventoryTransfer}}", "Level":"{{accessLevels.Access}}"}]'>
				<a (click)="transferInventory()" class="inventory-menu-link no-decoration">
					<pos-icon class="inventory-menu-link__icon font-17 grey vertical-mid pt-pix-2"
						[icon]="icons.dollyFlatbedAlt"></pos-icon>
					<span class="vertical-mid"> Inventory Transfer </span>
				</a>
			</li>
		</ul>
	</div>
</ng-template>

<ng-template #dashboardModulePopoverTemplate>
	<div class="form-group mb-0">
		<ul>
			<li>
				<a (click)="loadProductDashboard('Sales Product Dashboard')"
					permissions='[{"Name":"{{permissions.DashboardSalesProduct}}", "Level":"{{accessLevels.Access}}"}]'
					class="inventory-menu-link">
					<img src='images/admin/manage-loginventory.png' class='inventory-menu-link__icon' />Sales Product
					Dashboard
				</a>
			</li>
			<li>
				<a (click)="loadProductDashboard('Inventory Product Dashboard')"
					permissions='[{"Name":"{{permissions.DashboardInventoryProduct}}", "Level":"{{accessLevels.Access}}"}]'
					class="inventory-menu-link">
					<img src='images/admin/inventory-general.png' class='inventory-menu-link__icon' />Inventory Product
					Dashboard
				</a>
			</li>
			<li>
				<a (click)="loadDashboardList()"
					permissions='[{"Name":"{{permissions.DashboardTemplates}}", "Level":"{{accessLevels.Access}}"}]'
					class="inventory-menu-link">
					<img src='images/admin/manage-layout.png' class='inventory-menu-link__icon' />Dashboard Templates
				</a>
			</li>
		</ul>
	</div>
</ng-template>

<ng-template #deliveryModulePopoverTemplate>
	<div class="form-group mb-0" *ngIf="showDeliveryPopover">
		<ul>
			<li permissions='[{"Name":"{{permissions.ManageDrivers}}", "Level":"{{accessLevels.Access}}"}]'>
				<a (click)="loadDrivers()" class="inventory-menu-link">
					<img src='images/admin/drivers.svg' class='inventory-menu-link__icon' />Drivers
				</a>
			</li>
			<li permissions='[{"Name":"{{permissions.ManageDispatch}}", "Level":"{{accessLevels.Access}}"}]'>
				<a (click)="loadDispatch()" class="inventory-menu-link">
					<img src='images/admin/dispatch.png' class='inventory-menu-link__icon' />Dispatch
				</a>
			</li>
		</ul>
	</div>
</ng-template>

<ng-template #menuSetupModulePopoverTemplate>
	<div class="col-12 console-popover">
		<ul>
			<li class="row">
				<a (click)="designMode()" class="pointer"
					permissions='[{"Name":"{{permissions.ManageScreenTurnScreenDesignModeOnOff}}", "Level":"{{accessLevels.Access}}"}]'>
					<pos-icon class="col-2 option-icon" [icon]="designModeIcon"></pos-icon>
					<span class="col-10 option-title"> {{designModeText}} </span>
				</a>
			</li>
			<li class="row">
				<a permissions='[{"Name":"{{permissions.ManageInactiveButtons}}", "Level":"{{accessLevels.Access}}"},{"Name":"{{permissions.ManageDesignScreens}}", "Level":"{{accessLevels.Access}}"}]'
					class="pointer" (click)="manageInactiveProducts()" grantif="all">
					<pos-icon class="col-2 option-icon" [icon]="icons.inactiveButtonsDiceOne"></pos-icon>
					<span class="col-10 option-title"> Buttons with inactive products
					</span>
				</a>
			</li>
			<li class="row">
				<a permissions='[{"Name":"{{permissions.ManageUnlinkedScreens}}", "Level":"{{accessLevels.Access}}"},{"Name":"{{permissions.ManageDesignScreens}}", "Level":"{{accessLevels.Access}}"}]'
					class="pointer" (click)="manageUnlinkedScreen()" grantif="all">
					<pos-icon class="col-2 option-icon" [icon]="icons.unlinkedScreensDesktop"></pos-icon>
					<span class="col-10 option-title"> Screens not linked </span>
				</a>
			</li>
			<li class="row">
				<a permissions='[{"Name":"{{permissions.ManageUnusedImages}}", "Level":"{{accessLevels.Access}}"},{"Name":"{{permissions.ManageDesignScreens}}", "Level":"{{accessLevels.Access}}"}]'
					class="pointer" (click)="unusedImages()" grantif="all">
					<pos-icon class="col-2 option-icon" [icon]="icons.menuSetupUnusedImage"></pos-icon>
					<span class="col-10 option-title"> Unused images </span>
				</a>
			</li>
		</ul>
	</div>
</ng-template>

<ng-template #trainingModulePopoverTemplate>
	<div class="col-12 console-popover" *ngIf="showTrainingPopover">
		<ul>
			<li class="row">
				<a (click)="trainingMode()" class="pointer">
					<pos-icon class="col-2 option-icon" [icon]="trainingIcon"></pos-icon>
					<span class="col-10 option-title">{{trainingModeText}}</span>
				</a>
			</li>
			<li class="row">
				<a (click)="loadTrainingTopics()" class="pointer">
					<pos-icon class="col-2 option-icon" [icon]="icons.infoCircle"></pos-icon>
					<span class="col-10 option-title">Training Topics</span>
				</a>
			</li>
			<li permissions='[{"Name":"{{permissions.ApprovedTraining}}", "Level":"{{accessLevels.Access}}"}]'
				class="row">
				<a (click)="loadTrainingApprove()" class="pointer">
					<pos-icon class="col-2 option-icon" [icon]="icons.trainingApproveThumbsUp"></pos-icon>
					<span class="col-10 option-title">Training Approval</span>
				</a>
			</li>
		</ul>
	</div>
</ng-template>

<ng-template #logViewerTemplate>
	<div class="col-12 console-popover" *ngIf="showLogViewerPopover">
		<ul>
			<li class="row" permissions='[{"Name":"{{permissions.LogDetails}}", "Level":"{{accessLevels.Access}}"}]'>
				<a (click)="loadLogViewer()" class="pointer">
					<pos-icon class="col-2 option-icon" [icon]="icons.bug"></pos-icon>
					<span class="col-10 option-title"> Details </span>
				</a>
			</li>
			<li class="row" *ngIf="applicationStateService.settingParam.EnableAudioRecording"
				permissions='[{"Name":"{{permissions.CapturedAudio}}", "Level":"{{accessLevels.Access}}"}]'>
				<a (click)="loadAudioRecordings()" class="pointer">
					<pos-icon class="col-2 option-icon" [icon]="icons.fileAudio"></pos-icon>
					<span class="col-10 option-title"> Captured Audio </span>
				</a>
			</li>
		</ul>
	</div>
</ng-template>

<ng-template #automatedModulePopoverTemplate>
	<div class="col-12 console-popover" *ngIf="showAutomatedPopover">
		<ul>
			<li class="row"
				permissions='[{"Name":"{{permissions.AutomatedExportsConfiguration}}", "Level":"{{accessLevels.Access}}"}]'>
				<a (click)="loadConfiguration()" class="pointer">
					<pos-icon class="col-2 option-icon" [icon]="icons.cog"></pos-icon>
					<span class="col-10 option-title"> Configuration </span>
				</a>
			</li>
			<li class="row"
				permissions='[{"Name":"{{permissions.AutomatedExportsSubscriptions}}", "Level":"{{accessLevels.Access}}"}]'>
				<a (click)="loadSubscriptions()" class="pointer">
					<pos-icon class="col-2 option-icon" [icon]="icons.rssSquare"></pos-icon>
					<span class="col-10 option-title"> Subscriptions </span>
				</a>
			</li>
			<li class="row" permissions='[{"Name":"{{permissions.ReleaseQueue}}", "Level":"{{accessLevels.Access}}"}]'>
				<a (click)="loadManualSubscriptions()" class="pointer">
					<pos-icon class="col-2 option-icon" [icon]="icons.upload"></pos-icon>
					<span class="col-10 option-title"> Release Queue </span>
				</a>
			</li>
		</ul>
	</div>
</ng-template>

<ng-template #quickBooksPopoverTemplate>
	<div class="col-12 p-0 pb-10" *ngIf="showQuickBooksPopover">
		<ul>
			<li class="row pl-10"
				permissions='[{"Name":"{{permissions.ManageCashSalesQuickBooks}}", "Level":"{{accessLevels.Access}}"}]'>
				<a (click)="loadQuickBooksCashSalesConfig()" class="pointer">
					<pos-icon class="col-1 text-center p-0 col-28 black font-17"
						[icon]="icons.ringupMoneyBill"></pos-icon>
					<span class="col-10 pr-0 pl-5"> Cash Sales </span>
				</a>
			</li>
		</ul>
	</div>
</ng-template>

<div class="management-console-page page relative">
	<h3 class="page__title ps-pix-15"> Management Console </h3>
	<section class="pull-left width-full row mx-0">
		<div class="col-lg-9 col-md-9 col-sm-12 col-12">
			<fieldset class="fieldset mb-pix-10 pl-075em pr-075em">
				<legend class="reset-style bold">
					<span class="bold"> Orders </span>
				</legend>
				<div class="row p-pix-10 pt-0">
					<div class="col-lg-2 col-md-3 col-sm-4 col-6" *ngIf="drawerButtonName == 'Close Drawer'">
						<div class="manage-console-item"
							permissions='[{"Name":"{{permissions.ManageCountDrawer}}", "Level":"{{accessLevels.Access}}"}]'
							#pop="bs-popover" placement="right" [outsideClick]="true" popoverTitle="Count Drawer"
							[popover]="countDrawerPopoverTemplate" triggers="click" (click)="toggleDrawerPopover()">
							<img alt="closedrawer" src="images/admin/manage-close-drawer.png" />
							<h6>Count Drawer</h6>
						</div>
					</div>
					<div class="col-lg-2 col-md-3 col-sm-4 col-6" *ngIf="drawerButtonName == 'Load Drawer'">
						<div class="manage-console-item" (click)="loadCashDrawer()"
							permissions='[{"Name":"{{permissions.ManageOpenDrawer}}", "Level":"{{accessLevels.Access}}"}]'>
							<img alt="loadrawer" src="images/admin/manage-load-drawer.png" />
							<h6>Load Drawer</h6>
						</div>
					</div>
					<div class="col-lg-2 col-md-3 col-sm-4 col-6 p-0">
						<div class="manage-console-item"
							permissions='[{"Name":"{{permissions.ManageOpenCashDrawer}}", "Level":"{{accessLevels.Access}}"}]'
							(click)="openCashDrawerClicked()">
							<img alt="Open Drawer" src="images/admin/manage-cashregister.png" />
							<h6>Open Cash Drawer</h6>
						</div>
					</div>
					<div class="col-lg-2 col-md-3 col-sm-4 col-6">
						<div class="manage-console-item"
							permissions='[{"Name":"{{permissions.ManageEndofDay}}", "Level":"{{accessLevels.Access}}"}]'
							(click)="closeDay()">
							<img alt="End of Day" src="images/icons/icon-sun.png" />
							<h6>End Of Day</h6>
						</div>
					</div>
					<div class="col-lg-2 col-md-3 col-sm-4 col-6 p-0">
						<div class="manage-console-item"
							permissions='[{"Name":"{{permissions.ManageTerminalReports}}", "Level":"{{accessLevels.Access}}"}]'
							(click)="loadTerminalReports()">
							<img alt="Terminal Reports" src="images/admin/manage-terminal-reports.png" />
							<h6>Terminal Reports</h6>
						</div>
					</div>
					<div class="col-lg-2 col-md-3 col-sm-4 col-6 p-0">
						<div class="manage-console-item"
							permissions='[{"Name":"{{permissions.ManageOrderMaintenance}}", "Level":"{{accessLevels.Access}}"}]'
							(click)="loadOrderMaintenance()">
							<img alt="Orders" src="images/admin/manage-order-maintenance.png" />
							<h6>Order Maintenance</h6>
						</div>
					</div>
				</div>
			</fieldset>
			<fieldset class="fieldset mb-pix-10 pl-075em pr-075em">
				<legend class="reset-style bold">
					<span class="bold"> Functions </span>
				</legend>
				<div class="row p-pix-10 pt-0">
					<div class="col-lg-2 col-md-3 col-sm-4 col-6">
						<div class="manage-console-item" permissions='[{"Name":"{{permissions.InventoryLogInventory}}", "Level":"{{accessLevels.Access}}"}, 
										  {"Name":"{{permissions.InventoryOrderReceiving}}", "Level":"{{accessLevels.Access}}"},
										  {"Name":"{{permissions.InventoryInventoryLoss}}", "Level":"{{accessLevels.Access}}"},
										  {"Name":"{{permissions.InventoryInventoryPrep}}", "Level":"{{accessLevels.Access}}"}]' #pop="bs-popover"
							placement="right" [outsideClick]="true" [popover]="inventoryModulePopoverTemplate"
							triggers="click" popoverTitle="Inventory" (click)="toggleInventoryPopover()">
							<img alt="Inventory" src="images/admin/inventory-general.png" />
							<h6>Inventory</h6>
						</div>
					</div>
					<div class="col-lg-2 col-md-3 col-sm-4 col-6">
						<div class="manage-console-item"
							permissions='[{"Name":"{{permissions.ManagePromotions}}", "Level":"{{accessLevels.Access}}"}]'
							(click)="loadPromotion()">
							<pos-icon class="inventory-menu-link__icon font-40" [icon]="icons.piggyBankLight">
							</pos-icon>
							<h6 class="pti-0">Promotion</h6>
						</div>
					</div>
					<div class="col-lg-2 col-md-3 col-sm-4 col-6 p-0">
						<div class="manage-console-item" #pop="bs-popover" placement="right" [outsideClick]="true"
							[popover]="eventPopoverTemplate" triggers="click" popoverTitle="Event Manager"
							(click)="showEventsPopover = true"
							permissions='[{"Name":"{{permissions.ManageEventManager}}", "Level":"{{accessLevels.Access}}"}]'>
							<img alt="Event Manager" src="images/admin/manage-events.png" />
							<h6>Event Manager</h6>
						</div>
					</div>
					<div class="col-lg-2 col-md-3 col-sm-4 col-6">
						<div class="manage-console-item" #pop="bs-popover" placement="right" [outsideClick]="true"
							[popover]="giftCardPopoverTemplate" triggers="click" popoverTitle="Gift Card"
							(click)="showGiftCardPopover = true"
							permissions='[{"Name":"{{permissions.ManageGiftCards}}", "Level":"{{accessLevels.Access}}"}]'>
							<img alt="Gift Cards" src="images/admin/manage-giftcard.png" />
							<h6>Gift Card</h6>
						</div>
					</div>
					<div class="col-lg-2 col-md-3 col-sm-4 col-6">
						<div class="manage-console-item"
							permissions='[{"Name":"{{permissions.ManageDelivery}}", "Level":"{{accessLevels.Access}}"}]'
							#pop="bs-popover" placement="right" [outsideClick]="true" popoverTitle="Delivery"
							[popover]="deliveryModulePopoverTemplate" triggers="click"
							(click)="toggleDeliveryPopover()">
							<img alt="driver" src="images/admin/manage-driver.png" />
							<h6>Delivery</h6>
						</div>
					</div>
				</div>
			</fieldset>
			<fieldset class="fieldset mb-pix-10 pl-075em pr-075em">
				<legend class="reset-style bold">
					<span class="bold"> Management </span>
				</legend>
				<div class="row p-pix-10 pt-0">
					<div class="col-lg-2 col-md-3 col-sm-4 col-6 p-0">
						<div class="manage-console-item" (click)="loadReports()"
							permissions='[{"Name":"{{permissions.ManageManagementReports}}", "Level":"{{accessLevels.Access}}"}]'>
							<img alt="Management Report" src="images/admin/manage-managementreports.png" />
							<h6>Management Reports</h6>
						</div>
					</div>
					<div class="col-lg-2 col-md-3 col-sm-4 col-6 p-0">
						<div class="manage-console-item"
							permissions='[{"Name":"{{permissions.ManageAppConfiguration}}", "Level":"{{accessLevels.Access}}"}]'
							(click)="loadAppConfiguration()">
							<img alt="App Configuration" src="images/admin/manage-app-configuration.png" />
							<h6>App Configuration</h6>
						</div>
					</div>
					<div class="col-lg-2 col-md-3 col-sm-4 col-6">
						<div class="manage-console-item"
							permissions='[{"Name":"{{permissions.ManageOnlineBackup}}", "Level":"{{accessLevels.Access}}"}]'
							(click)="createOnlineBackup()">
							<img alt="Online Backup" src="images/admin/manage-backup.png" />
							<h6>Online Backup</h6>
						</div>
					</div>
					<div class="col-lg-2 col-md-3 col-sm-4 col-6 p-0">
						<div class="manage-console-item"
						permissions='[{"Name":"{{permissions.MenuDisplay}}", "Level":"{{accessLevels.Access}}"}]'
						 (click)="loadMenuDisplay()">
							<pos-icon class="inventory-menu-link__icon font-33" [icon]="icons.displayMenu">
							</pos-icon>
							<h6>Menu Display</h6>
						</div>
					</div>
					<div class="col-lg-2 col-md-3 col-sm-4 col-6 p-0">
						<div class="manage-console-item"
							permissions='[{"Name":"{{permissions.ManagePrintout}}", "Level":"{{accessLevels.Access}}"}]'
							(click)="loadPrintoutDesigner()">
							<img alt="Receipt Designer" src="images/admin/manage-receiptsample.png" />
							<h6>Printout Designer</h6>
						</div>
					</div>
					<div class="col-lg-2 col-md-3 col-sm-4 col-6 p-0">
						<div class="manage-console-item"
							permissions='[{"Name":"{{permissions.ManageOrderKiosk}}", "Level":"{{accessLevels.Access}}"}]'
							(click)="loadOrderKiosk()">
							<pos-icon class="inventory-menu-link__icon font-33" [icon]="icons.tabletAlt">
							</pos-icon>
							<h6>Order Kiosk</h6>
						</div>
					</div>
				</div>
			</fieldset>
			<fieldset class="fieldset mb-pix-10 pl-075em pr-075em">
				<legend class="reset-style bold">
					<span class="bold"> Utilities </span>
				</legend>
				<div class="row p-pix-10 pt-0">
					<div class="col-lg-2 col-md-3 col-sm-4 col-6">
						<div class="manage-console-item"
							permissions='[{"Name":"{{permissions.ManageLogs}}", "Level":"{{accessLevels.Access}}"}]'
							#pop="bs-popover" placement="right" [outsideClick]="true" popoverTitle="Logs"
							[popover]="logViewerTemplate" (click)="toggleLogViewerPopover()">
							<img alt="Debugging Utilities" src="images/admin/manage-debug.png" />
							<h6>Logs</h6>
						</div>
					</div>
					<div class="col-lg-2 col-md-3 col-sm-4 col-6 p-0">
						<div class="manage-console-item"
							permissions='[{"Name":"{{permissions.ManageAutomatedExports}}", "Level":"{{accessLevels.Access}}"}]'
							#pop="bs-popover" placement="right" [outsideClick]="true" popoverTitle="Automated Exports"
							[popover]="automatedModulePopoverTemplate" triggers="click"
							(click)="toggleAutomatedPopover()">
							<img alt="Automated Exports" src="images/admin/manage-automated-exports.png" />
							<h6>Automated Exports </h6>
						</div>
					</div>
					<div class="col-lg-2 col-md-3 col-sm-4 col-6" *ngIf="!isBackOffice">
						<div class="manage-console-item"
							permissions='[{"Name":"{{permissions.ManageTraining}}", "Level":"{{accessLevels.Access}}"}]'
							#pop="bs-popover" placement="right" [outsideClick]="true" popoverTitle="Training"
							[popover]="trainingModulePopoverTemplate" triggers="click"
							(click)="toggleTrainingPopover()">
							<img alt="Training Mode" src="images/admin/manage-training-mode.png" />
							<h6>Training</h6>
						</div>
					</div>
					<div class="col-lg-2 col-md-3 col-sm-4 col-6" *ngIf="!isBackOffice">
						<div class="manage-console-item"
							permissions='[{"Name":"{{permissions.ManageDesignScreens}}", "Level":"{{accessLevels.Access}}"}]'
							#pop="bs-popover" placement="right" [outsideClick]="true" popoverTitle="Menu Setup"
							[popover]="menuSetupModulePopoverTemplate" triggers="click">
							<img alt="Design Mode" src="images/admin/manage-design.png" />
							<h6>Menu Setup</h6>
						</div>
					</div>
					<div class="col-lg-2 col-md-3 col-sm-4 col-6">
						<div class=" manage-console-item"
							permissions='[{"Name":"{{permissions.ManageSystemConfiguration}}", "Level":"{{accessLevels.Access}}"}]'
							(click)="loadSystemConfiguration()">
							<img alt="System Configuration" src="images/admin/manage-system-configuration.png" />
							<h6>System Configuration</h6>
						</div>
					</div>
					<div class="col-lg-2 col-md-3 col-sm-4 col-6 p-0">
						<div class="manage-console-item"
							permissions='[{"Name":"{{permissions.ManageHICDashboard}}", "Level":"{{accessLevels.Access}}"}]'
							(click)="loadHICDashboard()">
							<img alt="HIC Dashboard" src="images/admin/manage-hic-dashboard.png" />
							<h6>HIC Dashboard</h6>
						</div>
					</div>
					<div class="col-lg-2 col-md-3 col-sm-4 col-6 p-0">
						<div class="manage-console-item"
							permissions='[{"Name":"{{permissions.ManageLayoutDesigner}}", "Level":"{{accessLevels.Access}}"}]'
							(click)="loadLayoutDesigner()">
							<img alt="Layout Designer" src="images/admin/manage-layout.png" />
							<h6>Layout Designer</h6>
						</div>
					</div>
					<div class="col-lg-2 col-md-3 col-sm-4 col-6">
						<div class="manage-console-item"
							permissions='[{"Name":"{{permissions.ManageDashboards}}", "Level":"{{accessLevels.Access}}"}]'
							#pop="bs-popover" placement="right" [outsideClick]="true" popoverTitle="Dashboards"
							[popover]="dashboardModulePopoverTemplate" triggers="click">
							<img alt="Dashboards" src="images/admin/manage-admin-dashboards.png" />
							<h6>Dashboards</h6>
						</div>
					</div>
					<div class="col-lg-2 col-md-3 col-sm-4 col-6" [hidden]="true">
						<div class="manage-console-item">
							<img alt="Instructions" src="images/admin/manage-receiptsample.png" />
							<h6>Instructions</h6>
						</div>
					</div>
					<div class="col-lg-2 col-md-3 col-sm-4 col-6">
						<div class="manage-console-item" (click)="loadHangFireJobs()"
							permissions='[{"Name":"{{permissions.ManageBackgroundJobs}}", "Level":"{{accessLevels.Access}}"}]'>
							<pos-icon class="inventory-menu-link__icon font-33" [icon]="icons.backgroundJobs">
							</pos-icon>
							<h6>Background Jobs</h6>
						</div>
					</div>
					<div class="col-lg-2 col-md-3 col-sm-4 col-6">
						<div class="manage-console-item" (click)="loadOrders()"
							permissions='[{"Name":"{{permissions.ManageOrderFulfillment}}", "Level":"{{accessLevels.Access}}"}]'>
							<pos-icon class="inventory-menu-link__icon font-33" [icon]="icons.shippingFast">
							</pos-icon>
							<h6>Order Fulfillment</h6>
						</div>
					</div>
				</div>
			</fieldset>
			<fieldset class="fieldset mb-pix-10 pl-075em pr-075em">
				<legend class="reset-style bold">
					<span class="bold"> Integrations </span>
				</legend>
				<div class="col-12 p-10 pt-0">
					<div class="col-md-2 col-sm-3 col-4 p-0">
						<div class="manage-console-item"
							permissions='[{"Name":"{{permissions.ManageQuickBooks}}", "Level":"{{accessLevels.Access}}"}]'
							#pop="bs-popover" placement="right" [outsideClick]="true" popoverTitle="QuickBooks"
							[popover]="quickBooksPopoverTemplate" triggers="click" (click)="toggleQuickBooksPopover()">
							<img alt="Quick Books" src="images/admin/quick-books.png" />
							<h6>QuickBooks </h6>
						</div>
					</div>
				</div>
			</fieldset>
		</div>
		<div class="col-lg-3 col-md-3 col-sm-12 col-12 mt-pix-10 ps-pix-10">
			<pos-terminal-info #terminalInfo [drawerInfo]="drawerAmountModel" *ngIf="isDrawerAmountLoaded">
			</pos-terminal-info>
		</div>
	</section>
</div>
<ng-template #eventPopoverTemplate>
	<div *ngIf="showEventsPopover" class="col-12 console-popover">
		<ul>

			<li class="row">
				<a (click)="loadEventManager()" class="pointer">
					<pos-icon class="col-2 option-icon" [icon]="icons.megaphone"></pos-icon>
					<span class="col-10 option-title"> On Action </span>
				</a>
			</li>
			<li class="row">
				<a (click)="loadScheduledEventManager()" class="pointer">
					<pos-icon class="col-2 option-icon" [icon]="icons.calendar"></pos-icon>
					<span class="col-10 option-title"> Scheduled </span>
				</a>
			</li>
		</ul>
	</div>
</ng-template>

<ng-template #giftCardPopoverTemplate>
	<div class="col-12 console-popover" *ngIf="showGiftCardPopover">
		<ul>
			<li class="row pl-10"
				permissions='[{"Name":"{{permissions.GiftCards}}", "Level":"{{accessLevels.Access}}"}]'>
				<a (click)="loadGiftCard()" class="pointer">
					<pos-icon class="col-2 option-icon" [icon]="icons.informationManagementUser"></pos-icon>
					<span class="col-10 pr-0 pl-5"> Manage </span>
				</a>
			</li>
			<li class="row pl-10"
				permissions='[{"Name":"{{permissions.ViewGiftCards}}", "Level":"{{accessLevels.Access}}"}]'>
				<a (click)="loadGiftCardList()" class="pointer">
					<pos-icon class="col-2 option-icon" [icon]="icons.list"></pos-icon>
					<span class="col-10 pr-0 pl-5"> View All </span>
				</a>
			</li>
		</ul>
	</div>
</ng-template>
