import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterSize'
})
export class FilterSizePipe implements PipeTransform {

  transform(prices: any[], sizesToFilter: any[]): any[] {
    if (!prices || !sizesToFilter || sizesToFilter.length === 0) {
      return prices;
    }

    return prices.filter(price => sizesToFilter.includes(price.MakeTableSize.trim()));
  }

}
