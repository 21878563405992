<pos-edit-wrapper heading="{{ id > 0 ? 'Edit' : 'Add' }} DJ Scheme" (close)="cancel()"
  [headingIcon]="icons.djSchemesRoad" [isPopup]="false">
  <form (ngSubmit)="saveDjScheme(formDJScheme.valid)" #formDJScheme="ngForm" class="edit-form">
    <context-provider provide="props">
      <div class="col-12 border-top pt-pix-5">
        <div class="col-12 pos-page-body content-scroll">
          <pos-row colXs="12" class="p-0">
            <pos-column>
              <pos-form-field label="Name" [validationMessages]="{required: 'Please enter name.'}">
                <input posInput class="form-control" name="Name" [(ngModel)]="djScheme.Name" [required]="true"
                  type="text" #name="ngModel" />
              </pos-form-field>
            </pos-column>
            <pos-column>
              <pos-form-field label="Playlist" [validationMessages]="{required: 'Please select playlist.'}">
                <ng-select posInput [items]="playlists" appendTo="body" placeholder="Select playlist"
                  [(ngModel)]="djScheme.PlaylistId" (change)="reloadPlaylistTracks()" name="playlist" bindLabel="Name"
                  bindValue="Id" #playlist="ngModel" [required]="true"></ng-select>
              </pos-form-field>
            </pos-column>
            <pos-column>
              <pos-form-field>
                <p-checkbox posInput name="chkIsUseDefaultVolume" #chkIsUseDefaultVolume="ngModel"
                  [(ngModel)]="isUseDefaultVolume" binary="true" (onChange)="onChangeIsUseDefaultVolume()"
                  label="Use default volume">
                </p-checkbox>
              </pos-form-field>
            </pos-column>
            <pos-column class="ps-4" xs="12">
              <pos-form-field label="Volume">
                <pos-row>
                  <pos-column xs="11">
                    <ngx-slider [(value)]="djScheme.Volume" [options]="volumeOptions"
                      [ngClass]="{'pointers-none disable-volume-slider': isUseDefaultVolume}"></ngx-slider>
                  </pos-column>
                  <pos-column xs="1" class="p-0">
                    <span class="badge mt-pix-n2 volume-circle">{{djScheme.Volume ? djScheme.Volume : 0}}</span>
                  </pos-column>
                </pos-row>
              </pos-form-field>
            </pos-column>
            <pos-column>
              <pos-form-field>
                <p-checkbox posInput name="scheduled" #scheduled="ngModel" [(ngModel)]="djScheme.Scheduled"
                  binary="true" label="Scheduled">
                </p-checkbox>
              </pos-form-field>
            </pos-column>

            <pos-column class="ps-4">
              <div class="d-flex">
                <a class="mt-pix-10 pointer underline" [class.disabled]="!djScheme.Scheduled" style="background-color: unset !important;" (click)="selectAllDays()">All Days</a>
                <a class="mt-pix-10 pointer underline ms-5" [class.disabled]="!djScheme.Scheduled" style="background-color: unset !important;" (click)="selectWeekdays()">Weekdays</a>
                <a class="mt-pix-10 pointer underline ms-5" [class.disabled]="!djScheme.Scheduled" style="background-color: unset !important;" (click)="selectWeekends()">Weekends</a>
              </div>
            </pos-column>

            <pos-column class="ps-4 pt-2">
              <div class="d-flex">
                <pos-form-field inputStyle="col-85" *ngFor="let day of daysOfWeek">
                  <p-checkbox posInput name="chk_{{day.Id}}"
                    [(ngModel)]="day.IsChecked" binary="true" [label]="day.Name" [disabled]="!djScheme.Scheduled">
                  </p-checkbox>
                </pos-form-field>
              </div>
            </pos-column>
            <pos-column class="ps-4 pt-2">
              <pos-row>
                <pos-column xs="6" class="col-200">
                  <pos-form-field inputStyle="col-12">
                    <input posInput type="time" class="form-control" name="startTime" #startTime="ngModel"
                      [(ngModel)]="djScheme.StartTime" [disabled]="!djScheme.Scheduled">
                  </pos-form-field>
                </pos-column>
                <pos-column xs="6" class="col-200">
                  <pos-form-field inputStyle="col-12">
                    <input posInput type="time" class="form-control" name="endTime" #endTime="ngModel"
                      [(ngModel)]="djScheme.EndTime" [disabled]="!djScheme.Scheduled">
                  </pos-form-field>
                </pos-column>
              </pos-row>
            </pos-column>
            <pos-column>
              <pos-form-field>
                <p-checkbox posInput name="startFade" #startFade="ngModel" [(ngModel)]="djScheme.StartFade"
                  binary="true" label="Start Fade">
                </p-checkbox>
              </pos-form-field>
            </pos-column>
            <pos-column class="ps-4">
              <pos-row>
                <pos-column lg="3" md="4" sm="6">
                  <pos-form-field label="Fade In Seconds">
                    <input posInput class="form-control col-120" name="StartFadeSeconds"
                      [disabled]="!djScheme.StartFade" [(ngModel)]="djScheme.StartFadeSeconds" type="number" />
                  </pos-form-field>
                </pos-column>
                <pos-column md="3" sm="6" class="px-pix-10">
                  <pos-form-field label="Fade In Volume" inputStyle="col-12">
                    <pos-row class="g-0 mt-2">
                      <pos-column xs="11" class="pe-2">
                        <ngx-slider [(value)]="djScheme.FadeInVolumeIncrement" [options]="options"
                          [ngClass]="{'pointers-none disable-volume-slider': !djScheme.StartFade}"></ngx-slider>
                      </pos-column>
                      <pos-column xs="1" class="p-0">
                        <span class="badge mt-pix-n2 volume-circle">{{djScheme.FadeInVolumeIncrement ?
                          djScheme.FadeInVolumeIncrement : 0}}</span>
                      </pos-column>
                    </pos-row>
                  </pos-form-field>
                </pos-column>
              </pos-row>
            </pos-column>
            <pos-column>
              <pos-row>
                <pos-column lg="3" md="4" sm="6" styleCLass="pe-0">
                  <pos-form-field label="End Actions" inputStyle="col-12">
                    <div class="btn-group">
                      <label class="btn btn-radio"
                        [ngClass]=" endAction == 'None' ?'active btn-primary font-nobold': 'btn-secondary'">
                        <input [(ngModel)]="endAction" type="radio" value="None" name="None"> None
                      </label>
                      <label class="btn btn-radio"
                        [ngClass]="endAction == 'Play' ? 'active btn-primary font-nobold': 'btn-secondary'">
                        <input [(ngModel)]="endAction" type="radio" value="Play" name="Play"> Play
                      </label>
                      <label class="btn btn-radio"
                        [ngClass]="endAction == 'Repeat' ? 'active btn-primary font-nobold': 'btn-secondary'">
                        <input [(ngModel)]="endAction" type="radio" value="Repeat" name="Repeat"> Repeat
                      </label>
                    </div>
                  </pos-form-field>
                </pos-column>
                <pos-column md="3" sm="6">
                  <pos-form-field label="On Complete" [validationMessages]="{required: 'Please select DJ schemes.'}"
                    inputStyle="col-12">
                    <ng-select posInput [items]="djSchemes" [hideSelected]="true" appendTo="body" bindLabel="Name"
                      bindValue="Id" placeholder="Select DJ schemes" [(ngModel)]="djScheme.OnComplete" name="onComplete"
                      #onComplete="ngModel" [required]="endAction == 'Play'" [disabled]="endAction != 'Play'"></ng-select>
                  </pos-form-field>
                </pos-column>
              </pos-row>
            </pos-column>

            <pos-column>
              <pos-form-field label="Play Order" inputStyle="col-xs-10">
                <div class="btn-group">
                  <label class="btn btn-radio"
                    [ngClass]="djScheme.PlayOrder == 'Literal'? 'active btn-primary font-nobold':'btn-secondary'">
                    <input [(ngModel)]="djScheme.PlayOrder" type="radio" value="Literal" name="Literal"> Literal
                  </label>
                  <label class="btn btn-radio"
                    [ngClass]="djScheme.PlayOrder == 'Alpha' ?'active btn-primary font-nobold': 'btn-secondary'">
                    <input [(ngModel)]="djScheme.PlayOrder" type="radio" value="Alpha" name="Alpha"> Alpha
                  </label>
                  <label class="btn btn-radio"
                    [ngClass]="djScheme.PlayOrder == 'Random' ?'active btn-primary font-nobold': 'btn-secondary'">
                    <input [(ngModel)]="djScheme.PlayOrder" type="radio" value="Random" name="Random"> Random
                  </label>
                </div>
              </pos-form-field>
            </pos-column>
            <pos-column>
              <pos-form-field>
                <p-checkbox posInput name="chkPattern" #chkPattern="ngModel" [(ngModel)]="djScheme.Pattern"
                  binary="true" label="Pattern">
                </p-checkbox>
              </pos-form-field>
            </pos-column>
            <pos-column class="ps-4">
              <pos-form-field inputStyle="col-12 mb-pix-2">
                <pos-row>
                  <pos-column md="4" sm="9">
                    <ng-select posInput class="custom-theme" [items]="playlistTracks" [disabled]="!djScheme.Pattern"
                      bindLabel="Category" bindValue="Category" [addTag]="true" name="tags" [hideSelected]="true"
                      [ngClass]="{'select-disabled': !djScheme.Pattern}" [(ngModel)]="pattern" #tags="ngModel"
                      [multiple]="true" appendTo="body">
                    </ng-select>
                  </pos-column>
                  <pos-column md="2" sm="3">
                    <button class="btn btn-warning white normal pos-button" [ngClass]="{'mt-pix-2': pattern.length}"
                      (click)="reorderTags()" [disabled]="!djScheme.Pattern || !pattern.length" type="button">
                      Reorder
                    </button>
                  </pos-column>
                </pos-row>
              </pos-form-field>
            </pos-column>
          </pos-row>
        </div>
      </div>
      <div class="col-12 action-button-container page-footer">
        <button class="btn-lg btn-primary btn btn-save pos-button" scrollup type="submit" value="Save">Save</button>
        <button class="btn-secondary btn-lg btn btn-cancel pos-button" routerLink="../" scrollup type="button">
          Cancel
        </button>
      </div>
    </context-provider>
  </form>
</pos-edit-wrapper>
