<!-- New Design -->
<div class="inventory-product">

  <div
    class="col-12 align-items-center display-flex flex-grow p-0 pb-pix-5 inventory-head" [ngClass]="{'pt-pix-5': isFromInventoryExhaustion}">

    <div class="display-flex mb-0 col-lg-7 col-md-7 col-sm-8 col-7 p-0"
      *ngIf="!isPopup || !isFromInventoryExhaustion">
      <div class="col-40 pt-pix-1 align-self-start p-0 ps-pix-7 mt-pix-3" *ngIf="!isPopup">
        <p-checkbox name="updateInventoryLog_{{index}}" class="inventory-product-log" [style]="{'width': '26px'}"
          #updateInventoryLog="ngModel" [(ngModel)]="inventoryProduct.IsUpdated" binary="true"
          (onChange)="productSelectionStateChange()" inputId="product-item-{{index}}"></p-checkbox>
      </div>
      <label for="product-item-{{index}}">
        <div *ngIf="!isFromInventoryExhaustion" class="display-flex flex-direction-column">
        <span class="font-weight-600 font-16 opacity-09 in">{{inventoryProduct.Product}}</span>
        <div class="opacity-07"
        [ngClass]="{'yellow-bg': inventoryProduct.LastLogSevenDays<inventoryProduct.CurrentDate}">
            {{inventoryProduct.LastLog | tark_date_time}}
          </div>
        </div>
      </label>
    </div>

    <div class="d-flex flex-direction-column col-lg-5 col-md-5 col-sm-4 col-5" [ngClass]="{'col-lg-4 col-md-4':isPopup, 'col-lg-3 col-md-3': isFromInventoryExhaustion}">
      <div class="inventory-input pe-0">
        <div class="max-col-120" [ngClass]="{ 
          'checked':inventoryProduct.IsUpdated,
          'inventory-alert': (
            (inventoryProduct.varianceExceedsLowThreshold || inventoryProduct.varianceExceedsHighThreshold) 
              && inventoryProduct.IsWarnUnexpectedInventoryQty)}">
          <div class="inventory-product-input">
            <input class="text-right form-control h-38" id="{{'txtQty'+inventoryProduct.Id}}" [autoFocus]="focus" [selectOnFocus]
              name="inventoryLog_{{index}}" (input)="onChangeQty(inventoryProduct);" [(ngModel)]="inventoryProduct.Qty"
              type="number" tooltip="{{'Value should be in multiple of '+inventoryProduct.UnitQtyIncrement}}"
              triggers="" min="0" placement="bottom" #inputLog="bs-tooltip" />
            <pos-icon [icon]="icons.inventoryLogWarning" [hidden]="!((inventoryProduct.varianceExceedsHighThreshold ||inventoryProduct.varianceExceedsLowThreshold) 
                            && inventoryProduct.IsWarnUnexpectedInventoryQty)"
              tooltip="Calculated quantity differs by more than {{inventoryProduct.DifferedBy}}" placement="bottom">
            </pos-icon>
          </div>
        </div>
        <div class="ms-pix-5 col-85 opacity-09 align-self-center">
          {{inventoryProduct.Unit}}(s)
        </div>
      </div>
      <div class="italic pt-pix-5" *ngIf="inventoryProduct.PurchaseUnit">
        1 {{inventoryProduct.PurchaseUnit}} = {{inventoryProduct.UnitQty}} {{inventoryProduct.Unit}}(s)
      </div>
    </div>
  </div>

  <div class="row mx-0 inventory-description">
    <div class="col-lg-7 col-md-7 col-sm-8 col-7 p-0 overflow-auto sleek-scroll unit-details"
      [ngClass]="{'col-lg-5 col-md-5':isPopup, 'ps-pix-7': !isPopup}" [ngStyle]="{'padding-top':(isPopup ? '0px' :'3px')}">
      <span class="break-word" [ngClass]="{'pt-pix-8': isPopup}">
        {{inventoryProduct.UnitDetails}}
      </span>
    </div>

    <div class="col-lg-5 col-md-5 col-sm-4 col-5 ps-0 pe-0 display-flex align-items-center inventory-icons">
      <div class="flex-grow justify-content-end-flex p-0">
        <div class="ms-pix-5" *ngIf="inventoryProduct.TotalPrice >300"
          [ngClass]="{'width-110': isFromInventoryExhaustion }">
          <span class="inventory-block__items__price italic p-pix-3">Value: {{inventoryProduct.TotalPrice |
            tark_currency}}</span>
        </div>

        <div class="ms-pix-5" [ngClass]="{ 'width-90': isFromInventoryExhaustion }"
          *ngIf="inventoryProduct.Qty>inventoryProduct.ActualQty * 5 && inventoryProduct.TotalPrice <300 ">
          <span class="inventory-block__items__price italic p-pix-3">Last Entry: {{inventoryProduct.ActualQty }}</span>
        </div>
      </div>

      <div class="p-0 me-pix-20 align-left display-flex">
        <div class="width-30-px me-pix-5 text-align-center">
          <span [hidden]="inventoryProduct.DaysUntilDue>0" class="font-16 pointer">
            <pos-icon [icon]="icons.inventoryLogClock"
              tooltip="Inventory has not been logged since {{inventoryProduct.LastLog | tark_date_time}}"
              [placement]="isPopup ? 'left' : 'bottom'">
            </pos-icon>
          </span>
        </div>

        <div class="width-30-px text-align-center">
          <span class="pointer" [ngClass]="{'pt-pix-10':isPopup}">
            <pos-icon [icon]="icons.inventoryLogInfoCircle" (click)="showMemoPopup(inventoryProduct)" tooltip="Add Memo"
              placement="bottom"></pos-icon>
          </span>
        </div>
      </div>

    </div>
  </div>
</div>
<!-- New Design Ends -->

<pos-column *ngIf="isPopup && isToggleMemo">
  <pos-form-field label="Memo" [appendPaddingTop]="false" inputStyle="col-md-8 col-12">
    <input posInput type="text" name="inventoryMemo" class="form-control" [(ngModel)]="this.inventoryProduct.Memo" />
  </pos-form-field>
</pos-column>
