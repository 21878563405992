<pos-edit-wrapper heading="Make Table" (close)="cancel()" [headingIcon]="icons.makeTable" [isPopup]="false">
  <form (ngSubmit)="saveMaketableWorkflow(maketableWorkflow.valid)" #maketableWorkflow="ngForm" class="edit-form">
    <context-provider provide="props">
      <div class="col-12 border-top pt-pix-5">
        <div class="col-12 pos-modal-body content-scroll">
          <pos-row>
            <pos-column>
              <pos-form-field inputStyle="d-flex">
                <p-checkbox posInput name="chkUseMakeTable" #chkUseMakeTable="ngModel" label="Use Make Table"
                  [(ngModel)]="maketableWorkflowParameters.IsUseMakeTable" binary="true">
                </p-checkbox>
                <field-info [info]="fieldInfoMessages.WorkflowUserMakeTable" class="pt-0">
                </field-info>
              </pos-form-field>
            </pos-column>
            <pos-column class="pt-pix-10">
              <b>Make Table States</b>
            </pos-column>
            <pos-column>
              <div class="row g-0 pos-sub-content">
                <ng-container
                  *ngFor="let makeTableWorkflowState of maketableWorkflowParameters.MakeTableWorkflowStates; let i = index;">
                  <span
                    [ngClass]="{'col-150 col-lg-2 col-md-2 col-sm-3 col-12 p-0': makeTableWorkflowState.Code === makeTableStates.PENDING}">
                    <pos-form-field inputStyle="col-12 d-flex flex-nowrap align-items-center ps-0">
                      <p-checkbox posInput name="chkWorkflowState_{{i}}"
                        [(ngModel)]="makeTableWorkflowState.IsSelected" binary="true" class="pe-pix-5"
                        [disabled]="makeTableWorkflowState.Code == makeTableStates.PENDING || makeTableWorkflowState.Code == makeTableStates.DONE "
                        inputId="chkWorkflowState_{{i}}">
                      </p-checkbox>
                      <label for="chkWorkflowState_{{i}}" class="mb-0">
                        <pos-icon [icon]="makeTableWorkflowState.Icon" class="font-0-9em pe-2">
                        </pos-icon> 
                        {{makeTableWorkflowState.Name}}
                      </label>
                      <field-info [info]="makeTableWorkflowState.FieldInfo" class="pt-0">
                      </field-info>
                    </pos-form-field>
                  </span>
                  <pos-form-field *ngIf="makeTableWorkflowState.Code === makeTableStates.PENDING"
                    class="col-md-8  col-sm-7 col-12 ps-0">
                    <p-checkbox posInput name="chkShowMakeTablePendingState" #chkShowMakeTablePendingState="ngModel"
                      [(ngModel)]="maketableWorkflowParameters.IsShowMakeTablePendingState" binary="true"
                      label="Show icon in make table">
                    </p-checkbox>
                  </pos-form-field>
                </ng-container>
              </div>
            </pos-column>
            <pos-column>
              <pos-form-field label="Sort make table orders by" [validationMessages]="{required: 'Please select sort property'}" >
                <ng-select posInput name="shortMakeTableOrdersBy" class="col-250" [(ngModel)]="maketableWorkflowParameters.ShortMakeTableOrdersBy"
                [items]="MakeTableSortProperties" bindLabel="Key" bindValue="Value" [required]="true"></ng-select>
              </pos-form-field>
            </pos-column>
            <pos-column>
              <pos-form-field class="row">
                <div class="d-flex">
                  <p-checkbox name="chkShowSubaccountInMakeTable" #chkShowSubaccountInMakeTable="ngModel"
                    [(ngModel)]="maketableWorkflowParameters.ShowSubaccountInMakeTable" binary="true"
                    label="Show {{subAccountTerm}} in make table">
                  </p-checkbox>
                  <field-info [info]="showSubaccountInMakeTableMessage" class="pt-0">
                  </field-info>
                </div>
              </pos-form-field>
            </pos-column>
            <pos-column class="pos-sub-content">
              <pos-form-field class="row">
                <div class="d-flex">
                  <p-checkbox name="chkShowWhenMoreThanOneSubaccountInMakeTable"
                    #chkShowWhenMoreThanOneSubaccountInMakeTable="ngModel"
                    [(ngModel)]="maketableWorkflowParameters.ShowWhenMoreThanOneSubaccountInMakeTable" binary="true"
                    label="Only if there are more than one {{subAccountTerm}}"
                    [disabled]="!maketableWorkflowParameters.ShowSubaccountInMakeTable">
                  </p-checkbox>
                  <field-info [info]="showWhenMoreThanOneSubaccountMessage" class="pt-0">
                  </field-info>
                </div>
              </pos-form-field>
            </pos-column>
            <pos-column>
              <pos-form-field>
                <p-checkbox name="chkUseParallelPickScreenAndTimer" #chkUseParallelPickScreenAndTimer="ngModel"
                  [(ngModel)]="maketableWorkflowParameters.UseParallelPickScreenAndTimer" binary="true"
                  label="Use Parallel Pick Screen & Timer">
                </p-checkbox>
              </pos-form-field>
            </pos-column>
            <pos-column>
              <pos-form-field label="Delay between state changes"
                [fieldInfo]="fieldInfoMessages.WorkflowDelayBetweenStateChange"
                [validationMessages]="{required: 'Please enter value.', min: 'Please enter positive value.'}">
                <div class="d-flex flex-nowrap">
                  <input posInput class="col-3 col-120 form-control" [required]="true"
                    name="MakeTableStateChangeDelayMsec"
                    [(ngModel)]="maketableWorkflowParameters.MakeTableStateChangeDelayMsec" type="number" min="0"
                    #makeTableStateChangeDelayMsec="ngModel" />
                  <label class="edit-form__section__label ms-pix-10"> Millisecond(s)
                  </label>
                </div>
              </pos-form-field>
            </pos-column>
            <pos-column>
              <pos-form-field>
                <p-checkbox name="autoCollapseOnlineOrders" #autoCollapseOnlineOrders="ngModel"
                  label="Auto collapse online orders" [(ngModel)]="maketableWorkflowParameters.AutoCollapseOnlineOrders"
                  binary="true">
                </p-checkbox>
              </pos-form-field>
            </pos-column>
            <pos-column>
              <pos-form-field inputStyle="col-12 row"
                [validationMessages]="{required: 'Please enter value.', min: 'Please enter positive value.'}">
                <pos-column xs="3" class="col-225 pe-0 mb-1 edit-form__section__label">
                  <p-checkbox name="chkAutoExpand" #chkAutoExpand="ngModel"
                    [(ngModel)]="chkAutoExpandMinutesOnlineOrder" binary="true"
                    (onChange)="changeAutoExpandMinutesOnlineOrder()" label="Automatically expand within">
                  </p-checkbox>
                </pos-column>
                <pos-column xs="3" class="col-130 pe-0">
                  <input posInput class="form-control col-120" min="0" step="1" name="autoExpandMinutesOnlineOrder"
                    [(ngModel)]="maketableWorkflowParameters.AutoExpandMinutesOnlineOrder" type="number"
                    [disabled]="!chkAutoExpandMinutesOnlineOrder" posOnlyNumbers [required]="true" />
                </pos-column>
                <pos-column xs="3" class="no-wrap">
                  <label class="edit-form__section__label">minutes of pickup time</label>
                </pos-column>
              </pos-form-field>
            </pos-column>
            <pos-column>
              <pos-form-field>
                <p-checkbox posInput name="showTipOnMakeTable" #isShowTipOnMakeTable="ngModel"
                  label="Show tip on make table" [(ngModel)]="maketableWorkflowParameters.IsShowTipOnMakeTable"
                  binary="true">
                </p-checkbox>
              </pos-form-field>
            </pos-column>
            <pos-column>
              <pos-form-field>
                <p-checkbox posInput name="IsShowCookingPanel" #IsShowCookingPanel="ngModel"
                  label="Show cooking panel on make table" [(ngModel)]="maketableWorkflowParameters.IsShowCookingPanel"
                  binary="true">
                </p-checkbox>
              </pos-form-field>
            </pos-column>
            <pos-column>
              <pos-form-field>
                <p-checkbox posInput name="PromptToChooseUsernameOnMakeTable" #IsShowCookingPanel="ngModel"
                  label="Prompt to choose username on make table" [(ngModel)]="maketableWorkflowParameters.PromptToChooseUsernameOnMakeTable"
                  binary="true">
                </p-checkbox>
              </pos-form-field>
            </pos-column>
            <pos-column>
              <pos-form-field inputStyle="col-12">
                <p-checkbox name="showNotifyButton"
                  [(ngModel)]="maketableWorkflowParameters.OrderReadyShowNotifyButtonOnMaketable" binary="true"
                  #showNotifyButton="ngModel" label="Show Notify button on Make Table orders"></p-checkbox>
              </pos-form-field>
            </pos-column>


            <!-- Notify via SMS -->
            <pos-column class="pos-sub-content row g-0">
              <pos-column>
                <pos-row [feature]="features.SMSNotification">
                  <pos-column>
                    <pos-form-field>
                      <p-checkbox name="notifyViaSMS" [(ngModel)]="chkSendViaSMS" binary="true" #notifyViaSMS="ngModel"
                        [disabled]="!maketableWorkflowParameters.OrderReadyShowNotifyButtonOnMaketable"
                        label="Notify via SMS">
                      </p-checkbox>
                    </pos-form-field>
                  </pos-column>
                  <pos-column class="pos-sub-content">
                    <pos-form-field label="Text to send" [validationMessages]="{required: 'Please enter text for SMS.'}"
                      class="position-relative">
                      <textarea posInput class="form-control" name="sms" rows="5" #smsText="ngModel"
                        (click)="setCaretPosition('smsText')" (keyup)="setCaretPosition('smsText')"
                        [(ngModel)]="maketableWorkflowParameters.OrderReadyNotifyViaSMSText"
                        [disabled]="!chkSendViaSMS || !maketableWorkflowParameters.OrderReadyShowNotifyButtonOnMaketable"
                        maxlength="2000" id="smsText"
                        [required]="chkSendViaSMS && maketableWorkflowParameters.OrderReadyShowNotifyButtonOnMaketable"></textarea>
                      <span class="me-pix-20 mt-pix-n30 position-relative float-end">
                        <span class="font-20 pointer grey"
                          [ngClass]="{'disabled': !chkSendViaSMS || !maketableWorkflowParameters.OrderReadyShowNotifyButtonOnMaketable}">
                          <pos-icon [icon]="icons.code" (click)="isOpenVariableForSMS = !isOpenVariableForSMS">
                          </pos-icon>
                        </span>
                        <div *ngIf="isOpenVariableForSMS" class="col-200 order-variable-select">
                          <ng-select posInput [items]="orderVariables" placeholder="Select order variable" #orderVariableForSMS
                            id="orderVariableForSMS" name="orderVariableForSMS"
                            (change)="changeOrderVariable(selectedOrderVariable, maketableWorkflowParameters.OrderReadyNotifyViaSMSText, 'smsText')"
                            [isOpen]="true" [(ngModel)]="selectedOrderVariable">
                          </ng-select>
                        </div>
                      </span>
                    </pos-form-field>
                  </pos-column>
                </pos-row>
              </pos-column>

              <!-- Notify via DJ terminal -->
              <pos-column>
                <pos-row>
                  <pos-column>
                    <pos-form-field>
                      <p-checkbox posInput name="notifyViaDJ" [(ngModel)]="chkSendViaDJ" binary="true"
                        #notifyViaDJ="ngModel"
                        [disabled]="!maketableWorkflowParameters.OrderReadyShowNotifyButtonOnMaketable"
                        label="Notify via DJ Terminal">
                      </p-checkbox>
                    </pos-form-field>
                  </pos-column>
                  <pos-column class="pos-sub-content">
                    <pos-form-field [validationMessages]="{ required: 'Please select dj terminal.' }">
                      <ng-select posInput name="djTerminal" appendTo="body" class="col-300"
                        [ngClass]="{'select-disabled': !chkSendViaDJ || !maketableWorkflowParameters.OrderReadyShowNotifyButtonOnMaketable}"
                        placeholder="Select DJ terminal" [items]="djTerminals" bindLabel="TerminalName"
                        [required]="chkSendViaDJ && maketableWorkflowParameters.OrderReadyShowNotifyButtonOnMaketable"
                        [(ngModel)]="maketableWorkflowParameters.OrderReadyNotifyViaDJTerminal" bindValue="TerminalId">
                      </ng-select>
                    </pos-form-field>
                  </pos-column>

                  <pos-column class="pos-sub-content">
                    <pos-form-field label="Text to speak"
                      [validationMessages]="{required: 'Please enter text for DJ.'}">
                      <textarea posInput class="form-control" name="djText" rows="5" id="djTerminalText"
                        (click)="setCaretPosition('djTerminalText')" (keyup)="setCaretPosition('djTerminalText')"
                        [(ngModel)]="maketableWorkflowParameters.OrderReadyNotifyViaDJText" #djTerminalText="ngModel"
                        [required]="chkSendViaDJ && maketableWorkflowParameters.OrderReadyShowNotifyButtonOnMaketable"
                        [disabled]="!chkSendViaDJ || !maketableWorkflowParameters.OrderReadyShowNotifyButtonOnMaketable"
                        maxlength="2000"></textarea>
                      <span class="me-pix-20 mt-pix-n30 position-relative float-end">
                        <span class="font-20 pointer grey"
                          [ngClass]="{'disabled': !chkSendViaDJ || !maketableWorkflowParameters.OrderReadyShowNotifyButtonOnMaketable}">
                          <pos-icon [icon]="icons.code"
                            (click)="isOpenVariableForDJTerminal = !isOpenVariableForDJTerminal"></pos-icon>
                        </span>
                        <div *ngIf="isOpenVariableForDJTerminal" class="col-200 order-variable-select">
                          <ng-select  posInput [items]="orderVariables" placeholder="Select order variable"
                            #orderVariableForDJTerminal id="orderVariableForDJTerminal"
                            name="orderVariableForDJTerminal" [isOpen]="true" [(ngModel)]="selectedOrderVariable"
                            (change)="changeOrderVariable(selectedOrderVariable, maketableWorkflowParameters.OrderReadyNotifyViaDJText, 'djTerminalText')">
                          </ng-select>
                        </div>
                      </span>
                    </pos-form-field>
                  </pos-column>

                  <pos-column class="pos-sub-content">
                    <pos-form-field label="Voice" [validationMessages]="{required: 'Please select voice.'}">
                      <ng-select posInput name="djAudioVoices" class="col-300"
                        [ngClass]="{'select-disabled': !chkSendViaDJ || !maketableWorkflowParameters.OrderReadyShowNotifyButtonOnMaketable}"
                        placeholder="Select voice" [items]="audioVoices" bindLabel="Name"
                        [required]="chkSendViaDJ && maketableWorkflowParameters.OrderReadyShowNotifyButtonOnMaketable"
                        [(ngModel)]="maketableWorkflowParameters.OrderReadyNotifyViaDJVoice">
                      </ng-select>
                    </pos-form-field>
                  </pos-column>

                  <pos-column md="6" xs="12" class="pos-sub-content">
                    <pos-form-field label="Volume">
                      <div class="row">
                        <pos-column xs="11">
                          <ngx-slider [(value)]="maketableWorkflowParameters.OrderReadyNotifyViaDJVolume"
                            [options]="options"
                            [ngClass]="{'pointers-none disable-volume-slider': !chkSendViaDJ || !maketableWorkflowParameters.OrderReadyShowNotifyButtonOnMaketable}">
                          </ngx-slider>
                        </pos-column>
                        <pos-column xs="1" class="p-0">
                          <span
                            class="badge mt-pix-n2 volume-circle">{{maketableWorkflowParameters.OrderReadyNotifyViaDJVolume}}</span>
                        </pos-column>
                      </div>
                    </pos-form-field>
                  </pos-column>
                </pos-row>
              </pos-column>

              <!-- Notify via Audio Notifier -->
              <pos-column>
                <pos-row>
                  <pos-column>
                    <pos-form-field>
                      <p-checkbox posInput name="notifyViaAudioNotifier" [(ngModel)]="chkSendViaAudioNotifier"
                        binary="true" #notifyViaAudioNotifier="ngModel" label="Notify via Audio Notifier"
                        [disabled]="!maketableWorkflowParameters.OrderReadyShowNotifyButtonOnMaketable">
                      </p-checkbox>
                    </pos-form-field>
                  </pos-column>

                  <pos-column class="pos-sub-content">
                    <pos-form-field [validationMessages]="{ required: 'Please select audio notifier.' }">
                      <ng-select posInput name="audioNotifier" class="col-300"
                        [ngClass]="{'select-disabled': !chkSendViaAudioNotifier || !maketableWorkflowParameters.OrderReadyShowNotifyButtonOnMaketable}"
                        appendTo="body" placeholder="Select audio notifier" [items]="audioDevices" bindLabel="Name"
                        [required]="chkSendViaAudioNotifier && maketableWorkflowParameters.OrderReadyShowNotifyButtonOnMaketable"
                        [(ngModel)]="maketableWorkflowParameters.OrderReadyNotifyViaAudioNotifier" bindValue="Id">
                      </ng-select>
                    </pos-form-field>
                  </pos-column>

                  <pos-column class="pos-sub-content">
                    <pos-form-field label="Text to speak"
                      [validationMessages]="{required: 'Please enter text for audio.'}">
                      <textarea posInput class="form-control" name="audioTextarea" rows="5" id="audioText"
                        (click)="setCaretPosition('audioText')" (keyup)="setCaretPosition('audioText')"
                        [(ngModel)]="maketableWorkflowParameters.OrderReadyNotifyViaAudioNotifierText"
                        #audioText="ngModel"
                        [required]="chkSendViaAudioNotifier && maketableWorkflowParameters.OrderReadyShowNotifyButtonOnMaketable"
                        [disabled]="!chkSendViaAudioNotifier || !maketableWorkflowParameters.OrderReadyShowNotifyButtonOnMaketable"
                        maxlength="2000"></textarea>
                      <span class="me-pix-20 mt-pix-n30 position-relative float-end">
                        <span class="font-20 pointer grey"
                          [ngClass]="{'disabled': !chkSendViaAudioNotifier || !maketableWorkflowParameters.OrderReadyShowNotifyButtonOnMaketable}">
                          <pos-icon [icon]="icons.code"
                            (click)="isOpenVariableForAudioNotifier = !isOpenVariableForAudioNotifier"></pos-icon>
                        </span>
                        <div *ngIf="isOpenVariableForAudioNotifier" class="col-200 order-variable-select">
                          <ng-select posInput [items]="orderVariables" placeholder="Select order variable"
                            name="orderVariableForAudioNotifier" [isOpen]="true"
                            (change)="changeOrderVariable(selectedOrderVariable, maketableWorkflowParameters.OrderReadyNotifyViaAudioNotifierText, 'audioText')"
                            [(ngModel)]="selectedOrderVariable">
                          </ng-select>
                        </div>
                      </span>
                    </pos-form-field>
                  </pos-column>

                  <pos-column class="pos-sub-content">
                    <pos-form-field label="Voice" [validationMessages]="{required: 'Please select voice.'}">
                      <ng-select posInput name="audioVoices" class="col-300"
                        [ngClass]="{'select-disabled': !chkSendViaAudioNotifier || !maketableWorkflowParameters.OrderReadyShowNotifyButtonOnMaketable}"
                        placeholder="Select voice" [items]="audioVoices" bindLabel="Name"
                        [required]="chkSendViaAudioNotifier && maketableWorkflowParameters.OrderReadyShowNotifyButtonOnMaketable"
                        [(ngModel)]="maketableWorkflowParameters.OrderReadyNotifyViaAudioNotifierVoice">
                      </ng-select>
                    </pos-form-field>
                  </pos-column>

                  <pos-column md="6" xs="12" class="pos-sub-content">
                    <pos-form-field label="Volume">
                      <div class="row">
                        <pos-column xs="11">
                          <ngx-slider [(value)]="maketableWorkflowParameters.OrderReadyNotifyViaAudioNotifierVolume"
                            [options]="options"
                            [ngClass]="{'pointers-none disable-volume-slider': !chkSendViaAudioNotifier || !maketableWorkflowParameters.OrderReadyShowNotifyButtonOnMaketable}">
                          </ngx-slider>
                        </pos-column>
                        <pos-column xs="1" class="p-0">
                          <span
                            class="badge mt-pix-n2 volume-circle">{{maketableWorkflowParameters.OrderReadyNotifyViaAudioNotifierVolume}}</span>
                        </pos-column>
                      </div>
                    </pos-form-field>
                  </pos-column>
                </pos-row>
              </pos-column>
            </pos-column>

          </pos-row>
        </div>
      </div>
      <div class="col-12 action-button-container page-footer">
        <button class="btn-primary btn-lg btn btn-save pos-button" scrollup type="submit" value="Save">Save</button>
        <button class="btn-secondary btn-lg btn btn-cancel pos-button" routerLink="../" scrollup type="button"
          value="Cancel">Cancel</button>
      </div>
    </context-provider>
  </form>
</pos-edit-wrapper>
