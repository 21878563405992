import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { FontTypeService } from 'src/app/configurator/fonts/services';
import { Font } from 'src/app/configurator/fonts/interface';
import { BaseCrudModelComponent } from 'src/app/shared/components/base-crud-model.component';
import { SpinnerService } from 'src/app/shared/components';
import { AlertsService, Messages } from 'src/app/shared';
import { FormUtilityService } from 'src/app/shared/services/form-utility.service';
import { ExtensionValidationService } from 'src/app/shared/services/extension-validation.service';
declare let $: any;
@Component({
  selector: 'pos-font-edit',
  templateUrl: './font-edit.component.html',
  styleUrls: ['./font-edit.component.scss']
})
export class FontEditComponent extends BaseCrudModelComponent<Font>{
  font: Font;
  @ViewChild('fontForm') form: NgForm;
  isChooseFile: boolean = false;
  fontFile: File;
  fontFileName: string = null;
  fontFileExtensions: string[] =  [".ttf", ".otf", ".woff", ".woff2", ".eot", ".svg", ".fon"];
  acceptableFontExtensions: string;

  props = {
    labelClass: '',
    controlBoxClass: 'col-md-10 col-xs-9'
  }

  constructor(
    spinnerService: SpinnerService,
    private fontService: FontTypeService,
    alertService: AlertsService,
    formUtilityService: FormUtilityService,
    private extensionValidation: ExtensionValidationService) {
    super(fontService, alertService, spinnerService, formUtilityService);
  }

  ngOnInit() {
    this.font = this.font ? this.font : this.fontService.newFont();
    this.fontFileName = this.font.FileName;
    this.saveSuccessMessage = Messages.FontSaveSuccess;
    this.acceptableFontExtensions = this.fontFileExtensions.join(',');
  }

  get getForm(): NgForm {
    return this.form;
  }

  onSaveSuccess(model: Font): void {
    this.hide({ shouldReload: true })
  }

  loadDependencies(): void { }

  cancelEditing() {
    this.hide({ shouldReload: false });
  }

  saveFont(isValid: boolean){
    if(!isValid || !this.fontFileName){
      return;
    }
    this.save(this.font, this.fontFile, true);
  }

  handleFileInput(files: FileList) {
    this.isChooseFile = true;
    this.fontFile = files.item(0);
    const fileToUpload = this.fontFile ? this.fontFile.name : '';
    if(this.extensionValidation.isInvalidExtension(fileToUpload, this.fontFileExtensions)){
      if(fileToUpload){
        this.alertService.renderErrorMessage(Messages.InvalidFontFileType);
      }
      $("#fontFile").val('');
      this.fontFileName = '';
      return;
    }

    if (files && files[0]) {
      this.fontFileName = this.fontFile.name;
    }
  }
}
