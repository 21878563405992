<pos-edit-wrapper heading="{{loyaltyAttributeId ? 'Edit' : 'Add'}} Attribute{{loyaltyAttributeId ?': ' + loyaltyAttribute.Name : ''}}" (close)="cancelEditing()">
    <form #revenueCenterForm="ngForm" (ngSubmit)='saveLoyaltyFormAttribute(revenueCenterForm.valid)'>
      <div class="col-xs-12 pos-modal-body">
        <context-provider provide="props">
          <pos-row>
            <pos-column>
              <pos-form-field label="Name" [validationMessages]="{required: 'Please enter name'}">
                <input posInput name="name" #name="ngModel" class="form-control" [(ngModel)]="loyaltyAttribute.Name" [required]="true" [class.disabled]="isSystemDefinedAttribute(loyaltyAttribute)"/>
              </pos-form-field>
            </pos-column>

            <pos-column *ngIf="!isSystemDefinedAttribute(loyaltyAttribute)">
              <pos-form-field label="Select Account Attribute" [validationMessages]="{required: 'Please select account attribute'}">
                <ng-select posInput appendTo="body" [items]="accountAttributeTypes" bindLabel="Name" bindValue="Id" 
                  placeholder="Select account attribute" [(ngModel)]="loyaltyAttribute.AccountAttributeTypeId" id="loyaltyFormAttribute"
                  name="loyaltyFormAttribute" [required]="true">
                </ng-select>
              </pos-form-field>
            </pos-column>

            <pos-column>
              <pos-form-field label="Description">
                <textarea posInput name="description" #description="ngModel" class="form-control"
                  [(ngModel)]="loyaltyAttribute.Description">
                </textarea>
              </pos-form-field>
            </pos-column>
          </pos-row>
        </context-provider>
      </div>
      <div class="col-xs-12 action-button-container popup-footer">
        <button type="submit" class="btn-primary btn-lg btn btn-save pos-button" value="Save">Save</button>
        <button type="button" class="btn-secondary btn-lg btn btn-cancel-modal pos-button" value="Cancel"
          (click)="cancelEditing()">Cancel</button>
      </div>
    </form>
  </pos-edit-wrapper>
  