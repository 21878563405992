import { Component, OnDestroy } from '@angular/core';
import { KioskExcludedDietaryWarning, KioskSalesProductDietaryWarning, KioskScreen } from '../../interfaces/kiosk-screen';
import { Observable, Subscription, finalize, forkJoin } from 'rxjs';
import { SlideShowDetails, TerminalProperty, TerminalsService } from 'src/app/configurator';
import { OrderKioskScreenService, SpinnerService, AlertsService, ApplicationStateService, DomainConstants, SlideShowData, RuntimeConstants, InventoryProductDietaryWarningService, RabbitMQService, TerminalStatusCheckService, ModalService, ForceLogoutModalComponent, ApplicationService, ICloseable, OrderService, SettingParam } from 'src/app/shared';
import { KioskNavigationService } from '../../services/kiosk-navigation.service';
import { filter, forEach } from 'lodash';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DietaryWarning } from 'src/app/information-management/dietary-warnings/interface';
import { OrderKioskStorageService } from '../../services/order-kiosk-storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ImageInterceptorService } from '../../services/image-interceptor.service';
import { TerminalDetails } from 'src/app/orders';
import { ShakeFlavorGroupService } from '../../services/shake/shake-flavor-group.service';
import { ShakeFlavorGroup } from '../../interfaces/shake-flavor-group';
import { KioskNavigationBroadcastingService } from '../../services';
import { OrderKioskExcludedDietaryService } from 'src/app/shared/services/order-kiosk-excluded-dietary.service';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'pos-order-kiosk-terminal',
  templateUrl: './order-kiosk-terminal.component.html',
  styleUrls: ['./order-kiosk-terminal.component.scss']
})
export class OrderKioskTerminalComponent implements OnDestroy {
  terminalId: number;
  slideShowData: Array<SlideShowData> = [];
  kioskScreen: KioskScreen;
  userClockOutSubscription: Subscription;
  refreshTerminalRequestSubscription: Subscription;
  rabbitMQTerminalStatusCheckSubscription: Subscription;
  terminalInUseSubscription: Subscription;
  globalLogoffSubscription: Subscription;
  terminalLogoutRequestSubscription: Subscription;
  rabbitMqOrderKioskTerminalStateChangeSubscription: Subscription;
  forceLogOutMessage: string = '';
  forceLogout: boolean = false;
  forceLogoutInterval: NodeJS.Timeout;
  forceLogoutModalRef: ICloseable;
  isEnabled = true;
  kioskImagePath = `${RuntimeConstants.IMAGE_BASE_PATH}/order-kiosk`;
  settingParam: SettingParam;
  excludedDietaries: Array<number> = [];
  navigationChangeSubscription: Subscription;

  constructor(private kioskScreenService: OrderKioskScreenService,
    private applicationStateService: ApplicationStateService,
    private applicationService: ApplicationService,
    private terminalService: TerminalsService,
    private rabbitMQService: RabbitMQService,
    private spinnerService: SpinnerService,
    private alertsService: AlertsService,
    private route: ActivatedRoute,
    private router: Router,
    private kioskNavigationService: KioskNavigationService,
    private orderKioskStorageService: OrderKioskStorageService,
    private orderService: OrderService,
    private shakeFlavorGroupService: ShakeFlavorGroupService,
    private modalService: ModalService,
    private inventoryProductDietaryWarningService: InventoryProductDietaryWarningService,
    private terminalStatusChecker: TerminalStatusCheckService,
    private imageInterceptorService: ImageInterceptorService,
    private kioskNavigationBroadcastingService: KioskNavigationBroadcastingService,
    private kioskExcludedDietaryWarningService: OrderKioskExcludedDietaryService) {
    this.terminalId = this.applicationStateService.terminalId;
    this.settingParam = this.applicationStateService.settingParam;
  }

  ngOnInit() {
    this.imageInterceptorService.setupImageInterception();
    this.loadData();
    this.subscribeToTerminalEvents();
  }

  private subscribeToTerminalEvents() {
    this.subscribeToTerminalLogoutMessage();
    this.subscribeTerminalRefreshMessage();
    this.subscribeToTerminalStatusCheck();
    this.subscribeToTerminalInUse();
    this.subscribeToGlobalLogOff();
    this.subscribeToOrderKioskTerminalStateChangeExchange();
    this.subscribeKioskTerminalStateChangeChange();
  }

  ngOnDestroy() {
    this.imageInterceptorService.disconnectObserver();
    this.navigationChangeSubscription?.unsubscribe();
  }

  private subscribeKioskTerminalStateChangeChange = () => {
    this.navigationChangeSubscription = this.kioskNavigationBroadcastingService.kioskTerminalStateChange.subscribe(
      () => {
        this.isEnabled = this.applicationStateService.kioskTerminalState;
      }
    );
  }


  loadData() {
    this.spinnerService.show();
    const terminalObservables: Observable<any>[] = [];
    terminalObservables.push(this.kioskScreenService.getTerminalSlideshowDetails(this.terminalId))
    terminalObservables.push(this.terminalService.getTerminalProperties(this.terminalId));
    terminalObservables.push(this.kioskScreenService.getAllScreenWithNavigation());
    terminalObservables.push(this.kioskScreenService.getSalesProductDietaryWarnings());
    terminalObservables.push(this.inventoryProductDietaryWarningService.getDietaryWarnings());
    terminalObservables.push(this.orderService.getMappedTerminalsByType(this.applicationStateService.terminalId, "Make Table"));
    terminalObservables.push(this.shakeFlavorGroupService.getAll());
    terminalObservables.push(this.kioskExcludedDietaryWarningService.getAll())
    forkJoin(terminalObservables)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
          next: ([slideShowDetails, terminalProperty, kioskScreens, kioskProductDietaryWarnings, dietaryWarnings, terminalDetails, flavorGroups, excludedDietaryWarnings]: [Array<SlideShowDetails>, Array<TerminalProperty>, Array<KioskScreen>, Array<KioskSalesProductDietaryWarning>, Array<DietaryWarning>, Array<TerminalDetails>, Array<ShakeFlavorGroup>, Array<KioskExcludedDietaryWarning>]) => {
          const terminalProperties: Array<TerminalProperty> = terminalProperty ?? [];
          const homeScreenProperty = terminalProperties.find(x => x.PropertyKey == DomainConstants.TerminalProperties.HOME_SCREEN_ID.Key);
          const continueOrderScreenProperty = terminalProperties.find(x => x.PropertyKey == DomainConstants.TerminalProperties.CONTINUE_ORDER_SCREEN_ID.Key);
          const homeScreenId = parseInt(homeScreenProperty.PropertyValue, 10);
          const continueOrderScreenId = parseInt(continueOrderScreenProperty?.PropertyValue, 10);
          kioskScreens = this.setDietaryWarningsInSalesProduct(kioskScreens, kioskProductDietaryWarnings);
          this.kioskScreen = kioskScreens.find(x => x.Id == homeScreenId) ?? this.kioskScreenService.newScreen();
          this.excludedDietaries = excludedDietaryWarnings?.map(x=> x.DietaryWarningId);
          this.kioskNavigationService.allDietaryWarnings = filter(dietaryWarnings, d => !this.excludedDietaries.includes(d.Id));
          this.kioskNavigationService.mappedTerminals = terminalDetails;
          const fontFamily = terminalProperties.find(x => x.PropertyKey == DomainConstants.TerminalProperties.FONT_FAMILY.Key);
          if (fontFamily?.PropertyValue) {
            document.documentElement.style.setProperty('--terminal-font-family', fontFamily.PropertyValue);
          }
          const userIdleTimeout = parseInt(terminalProperties.find(x => x.PropertyKey == DomainConstants.TerminalProperties.USER_IDLE_TIMEOUT.Key)?.PropertyValue ?? '0');
          const enableProperty = terminalProperties.find(x => x.PropertyKey == DomainConstants.TerminalProperties.IS_ENABLED.Key)?.PropertyValue ?? 'True'
          this.isEnabled = enableProperty == 'True' || enableProperty == 'true' ? true : false;
          this.applicationStateService.kioskTerminalState = this.isEnabled;
          this.kioskNavigationService.userIdleTimeout = userIdleTimeout;
          this.setSlideShow(slideShowDetails);
          this.setDataIntoStorageService(homeScreenId, kioskScreens, continueOrderScreenId, flavorGroups);
          this.router.navigate(['home'], { relativeTo: this.route });
        }, error: this.alertsService.showApiError
      });
  }

  setDietaryWarningsInSalesProduct(kioskScreens: Array<KioskScreen>, kioskProductDietaryWarnings: KioskSalesProductDietaryWarning[]) {
    forEach(kioskScreens, (screen: KioskScreen) => {
      forEach(screen.KioskScreenChoices, button => {
        if (button.Button?.SalesProductId) {
          button.DietaryWarningIds = kioskProductDietaryWarnings.find(x => x.SalesProductId == button.Button.SalesProductId)?.DietaryWarningIds ?? [];
        }
      });
    });
    return kioskScreens;
  }

  setSlideShow(slideShowDetails: Array<SlideShowDetails>) {
    forEach(slideShowDetails, (slideShow) => {
      slideShow.ImagePath = `${RuntimeConstants.IMAGE_BASE_PATH}/${DomainConstants.ImageDirectories.slideImages}/${slideShow.ImagePath}`;
      const slideShowObj: SlideShowData = {
        source: slideShow.ImagePath,
        duration: slideShow.HoldSeconds * 1000,
        id: slideShow.Id
      };
      this.slideShowData.push(slideShowObj);
    })
  }

  setDataIntoStorageService(homeScreenId: number, kioskScreens: Array<KioskScreen>, continueOrderScreenId: number, flavorGroups: ShakeFlavorGroup[]) {
    this.orderKioskStorageService.slideShowData = this.slideShowData;
    this.orderKioskStorageService.homeScreenId = homeScreenId;
    this.orderKioskStorageService.kioskScreens = kioskScreens;
    this.orderKioskStorageService.kioskScreen = this.kioskScreen;
    this.orderKioskStorageService.continueOrderScreenId = continueOrderScreenId;
    this.orderKioskStorageService.shakeFlavorGroups = flavorGroups;
  }

  subscribeToOrderKioskTerminalStateChangeExchange = () => {
    this.rabbitMqOrderKioskTerminalStateChangeSubscription = this.rabbitMQService.subscribeToOrderKioskStateUpdatedMessage$()
    .subscribe({
      next: (message: any) => {
          console.warn(message);
          if(message?.Payload?.TerminalId == this.applicationStateService.terminalId || message?.Payload?.TerminalId == 0)
          {
            if((!this.kioskNavigationService?.orderNavigation || this.kioskNavigationService?.orderNavigation?.Id == this.kioskNavigationService.homeScreenId) && !this.kioskNavigationService.orderDetails) {
              this.isEnabled = message.Payload.State;
            }
            this.applicationStateService.kioskTerminalState = message.Payload.State; 
          }
      }, error: (e) => {

      }
    })
  }

  subscribeToTerminalLogoutMessage() {
    this.userClockOutSubscription = this.rabbitMQService.subscribeToUserClockOutMessage$()
      .subscribe({
        next: (message: any) => {
          if (message.Payload.UserId === this.applicationStateService.userId) {
            if (parseInt(message.Source.Id) !== this.applicationStateService.terminalId) {
              this.forceLogOutMessage = 'This user has clocked out.  Signing out...';
              this.forceLogout = true;
              this.openForceLogoutModal();
            }
          }
        }, error: () => {
          console.log('error while connecting to RabbitMQ.');
        }
      });
  }
  

  subscribeToTerminalInUse() {
    this.terminalInUseSubscription = this.rabbitMQService.subscribeToTerminalUseMessage$()
      .subscribe({
        next: (message: any) => {
          this.rabbitMQService.sendConfirmTerminalUseMessage(message.Source.Id, this.applicationStateService.terminalId,
            this.applicationStateService.terminalName, this.applicationStateService.terminalType);
        }, error: () => {
          console.log('error while connecting to RabbitMQ.');
        }
      });

    this.terminalLogoutRequestSubscription = this.rabbitMQService.subscribeToTerminalLogoutMessage$(this.applicationStateService.terminalId)
      .subscribe({
        next: (message) => {
          if (message.Payload.TerminalId === this.applicationStateService.terminalId) {
            this.forceLogOutMessage = ' Another user has signed into the ' + message.Source.Name + ' terminal.  Logging out...';
            this.forceLogout = true;
            this.openForceLogoutModal();
          }
        }, error: () => {
          console.log('error while connecting to RabbitMQ.');
        }
      });
  }

  subscribeTerminalRefreshMessage() {
    this.refreshTerminalRequestSubscription = this.rabbitMQService.subscribeToRefreshTerminal$(this.applicationStateService.terminalId)
      .subscribe({
        next: () => {
          window.location.reload();
        }
      })
  }

  subscribeToTerminalStatusCheck() {
    this.rabbitMQTerminalStatusCheckSubscription = this.terminalStatusChecker.subscribeTerminalStatusCheck(this.applicationStateService.terminalId)
      .subscribe({
        next: (res) => this.terminalStatusChecker.publishTerminalStatusResponse(res)
      })
  }

  subscribeToGlobalLogOff() {
    this.globalLogoffSubscription = this.rabbitMQService.subscribeToGlobalLogoffMessage$()
      .subscribe({
        next: (message: any) => {
          this.forceLogOutMessage = message.Payload.CommandArguments.Message;
          this.forceLogout = true;
          this.openForceLogoutModal();
        }, error: () => {
          console.log('error while connecting to RabbitMQ.');
        }
      });
  }

  openForceLogoutModal() {
    this.forceLogoutModalRef = this.modalService.show(ForceLogoutModalComponent, {
      'backdrop': 'static',
      'class': 'vertical-center',
      'keyboard': false,
      initialState: {
        isForceLogout: this.forceLogout,
        forceLogOutMessage: this.forceLogOutMessage
      }
    });
    this.forceLogoutInterval = setTimeout(() => {
      this.closeForceLogOutModal();
    }, 3000);
  }

  closeForceLogOutModal() {
    this.forceLogoutModalRef.close.emit();
    clearInterval(this.forceLogoutInterval);
    this.applicationService.logout(this.forceLogout, true);
  }

} 
