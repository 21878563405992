import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoyaltyFormSettings } from '../interface/loyalty-form-settings';
import { Observable } from 'rxjs';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';

@Injectable({
  providedIn: 'root'
})
export class LoyaltyFormSettingsService {

  constructor(private httpService: HttpClient) { }
    newLoyaltyFormSettings(): LoyaltyFormSettings {
        const loyaltyFormSettingsObj: LoyaltyFormSettings = {
            HeaderText: '',
            UseRecapcha: true,
            FormSuccessHeader: '',
            FormSuccessDescription: ''
        };
        return loyaltyFormSettingsObj;
    }

    getLoyaltyFormSettings(): Observable<LoyaltyFormSettings> {
        return this.httpService.get<LoyaltyFormSettings>(
            `${RuntimeConstants.API_PATH}loyaltyFormSettings`
        );
    }
    saveLoyaltyFormSettings(centralApiConfig: LoyaltyFormSettings): Observable<LoyaltyFormSettings> {
        return this.httpService.post<LoyaltyFormSettings>(
            `${RuntimeConstants.API_PATH}loyaltyFormSettings`, centralApiConfig
        );
    }
}
