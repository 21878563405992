

import { Component, OnInit, ViewChild, Input, EventEmitter, Output, TemplateRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import {
  AlertsService, ModalComponent, SpinnerService, DomainConstants, ApplicationStateService, SettingParam, ModalService,
  AuthenticationService, Permissions, Levels, ConfirmDeleteModalComponent, InfoModalComponent, NumpadWrapperComponent
} from 'src/app/shared';
import { Messages } from 'src/app/shared/constants/ui-messages';
import { zip } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { GiftCardService } from '../services/gift-card.service';
import { GiftCard, GiftCardHistory } from '../interface';
import { GridColumn, TemplateColumn, DateColumn, TextAlign } from '@tarktech/tark-ng-utils';
import { messageCode, newAudioFile, keyboard, switchCard, addBalanceToCard, giftCard, deleteWhite } from '../../../../app/shared/components/icon';
import { AddToBalanceComponent } from './add-to-balance/add-to-balance.component';
declare const $: any;
import { StringUtils } from 'src/app/shared/string-utils/string-utils';
import { forEach } from 'lodash';
import moment from 'moment';

@Component({
  selector: 'pos-gift-card',
  templateUrl: './gift-card.component.html',
  styleUrls: ['./gift-card.component.scss']
})
export class GiftCardComponent extends ModalComponent implements OnInit {

  public icons = {
    newAudioFile,
    messageCode,
    keyboard,
    switchCard,
    addBalanceToCard,
    giftCard,
    deleteWhite
  };
  @ViewChild('giftCardInfForm') giftCardInfForm: NgForm;
  @Input() giftCardNumber: number;
  @Output() onCancel: EventEmitter<any> = new EventEmitter();
  @Output() onSave: EventEmitter<any> = new EventEmitter();
  @ViewChild('orderLinkTemplate', { static: true }) private orderLinkTemplate: TemplateRef<any>;
  @ViewChild('transactionTemplate', { static: true }) private transactionTemplate: TemplateRef<any>;
  @ViewChild('transactionAmountTemplate', { static: true }) private transactionAmountTemplate: TemplateRef<any>;
  @ViewChild('balanceTemplate', { static: true }) private balanceTemplate: TemplateRef<any>;
  @ViewChild('deleteTemplate', { static: true }) public deleteTemplate: TemplateRef<any>;
  settingParams: SettingParam;
  giftCardDetails: GiftCard;
  giftCardHistory: Array<GiftCardHistory> = [];
  giftCardHistoryColumns: Array<GridColumn> = [];
  amount = 0;
  memo = '';
  selectedOrderId?: number = null;
  giftCardTransactionType = DomainConstants.GiftCardTransactionTypes;

  screenHeight = '100px';
  elementInnerContainer: string;
  currencySymbol: string;
  permission = {
    name: Permissions.DeleteGiftCardRecharge,
    accessLevel: Levels.Access
  };
  constructor(
    private alertService: AlertsService,
    private spinnerService: SpinnerService,
    private giftCardService: GiftCardService,
    private applicationStateService: ApplicationStateService,
    private modalOpenService: ModalService,
    screenModalRef: BsModalRef,
    private authenticationService: AuthenticationService) {
    super(screenModalRef);
    this.currencySymbol = applicationStateService.settingParam.CurrencySymbol;
  }

  ngOnInit() {
    this.settingParams = this.applicationStateService.settingParam;
    this.configureGridColumns();
    this.reloadGiftCardDetails();
    this.setElementsHeight();
  }


  setElementsHeight() {
    const elementHeight = ($(window).height() - 400) < 300 ? 350 : ($(window).height() - 350);
    if ($(window).height() > 776) {
      this.screenHeight = elementHeight - 30 + 'px';
    } else {
      this.screenHeight = elementHeight + 'px';
    }
  }

  configureGridColumns() {

    const orderTemplate = new TemplateColumn({
      HeaderText: 'Order',
      itemTemplate: this.orderLinkTemplate,
      Field: 'OrderId',
      IsSortable: true,
      Width: '20%'
    });

    const transactionAmountTemplate = new TemplateColumn({
      HeaderText: 'Amount',
      itemTemplate: this.transactionAmountTemplate,
      Field: 'TransactionAmount',
      TextAlign: TextAlign.Right,
      IsSortable: true,
      Width: '25%'
    });

    const balanceTemplate = new TemplateColumn({
      HeaderText: 'Balance',
      itemTemplate: this.balanceTemplate,
      Field: 'Balance',
      TextAlign: TextAlign.Right,
      IsSortable: true,
      Width: '25%'
    });


    const deleteColumn = new TemplateColumn({
      itemTemplate: this.deleteTemplate,
      Width: '45px'
    });

    const transaction = new TemplateColumn({
      HeaderText: 'Transaction',
      itemTemplate: this.transactionTemplate,
      Field: 'TransactionType',
      // TextAlign: TextAlign.Right,
      IsSortable: true,
      Width: '30%'
    });

    this.giftCardHistoryColumns = [
      new DateColumn({ HeaderText: 'Date', Field: 'LogDate', IsSortable: true, TextAlign: TextAlign.Left, Width: '200px' }),
      transaction,
      // orderTemplate,
      // new GridColumn({ HeaderText: 'Memo', Field: 'Memo', IsSortable: true }),
      transactionAmountTemplate,
      balanceTemplate,
    ] as Array<GridColumn>;

    if (this.authenticationService.userHasPermission([{ Name: this.permission.name, Level: this.permission.accessLevel }], 'any')) {
      this.giftCardHistoryColumns.push(deleteColumn);
    }
  }

  confirmDelete = (transaction: GiftCardHistory, event: Event) => {
    event.stopPropagation();
    if (transaction && transaction.LogId) {
      const modalRef = this.modalOpenService.getModalWrapper(ConfirmDeleteModalComponent);
      const confirmDelete = modalRef.show({
        keyboard: false,
        animated: false,
        class: 'vertical-center',
        initialState: {
          message: Messages.ConfirmDeleteGiftCardRecharge
        }
      });
      confirmDelete.close.subscribe(res => {
        if (res && res.shouldDelete) {
          if ((this.giftCardDetails.CurrentBalance - transaction.TransactionAmount) < 0) {
            this.showTransactionDeleteInfoModal();
          } else {
            this.deleteGiftCardRecharge(transaction.LogId);
          }
        }
      });
    }
  }

  showTransactionDeleteInfoModal() {
    const modalRef = this.modalOpenService.getModalWrapper(InfoModalComponent);
    const infoDelete = modalRef.show({
      keyboard: false,
      animated: false,
      class: 'vertical-center',
      initialState: {
        message: Messages.TransactionDeleteInfo
      }
    });
  }

  deleteGiftCardRecharge(transactionId) {
    this.spinnerService.show();
    this.giftCardService.deleteGiftCardRecharge(transactionId)
      .subscribe(
        (res) => {
          this.reloadGiftCardDetails();
        }, this.alertService.showApiError,
        () => {
          this.spinnerService.hide();
        }
      );
  }

  reloadGiftCardDetails(shouldAddCard = true) {
    this.spinnerService.show();
    zip(
      this.giftCardService.getGiftCardDetails(this.giftCardNumber),
      this.giftCardService.getGiftCardHistory(this.giftCardNumber),
      (
        cardDetails: any,
        cardHistory: any,
      ) => {
        if (cardDetails && cardDetails.Id > 0) {
          this.giftCardDetails = cardDetails;
        } else if (shouldAddCard) {
          const giftCardBalanceRequest = this.prepareGiftCashBalanceRequest(1);
          this.addBalanceToGiftCard(giftCardBalanceRequest);
        }
        if (cardHistory) {
          cardHistory.forEach(x => {
            if (x.LogDate) {
              x.LogDate = moment.parseZone(x.LogDate).format('YYYY-MM-DDTHH:mm:ss[Z]');
            }
          });
          this.giftCardHistory = cardHistory;
        } else {
          this.giftCardHistory = [];
        }

      }
    ).subscribe({
      next: () => { },
      error: this.alertService.showApiError,
      complete: () => {
        this.spinnerService.hide();
      }
    });
  }

  prepareGiftCashBalanceRequest(type) {
    return {
      CardNumber: this.giftCardNumber,
      Amount: this.amount,
      UserId: this.applicationStateService.userId,
      DrawerID: this.settingParams.CashDrawerPrinter.Id,
      Type: type,
      TerminalId: this.applicationStateService.terminalId,
      TerminalName: this.applicationStateService.terminalName,
      Memo: this.memo,
      CreditCardTerminalId: this.settingParams.CreditCardTerminal.Id,
      PaymentMethod: 1
    };
  }


  addBalanceToGiftCard(giftCardRequestModal) {
    this.spinnerService.show();
    this.giftCardService.addGiftCardBalance(giftCardRequestModal)
      .subscribe(
        () => {
          this.reloadGiftCardDetails(false);
        }, this.alertService.showApiError,
        () => {
          this.spinnerService.hide();
        }
      );
  }

  viewOrderSummary(event: any) {
    forEach(this.giftCardHistory, (data) => {
      data.Color = '';
    });
    event.data.Color = 'bg-theme-primary';
    if (event.data && event.data.OrderId) {
      this.selectedOrderId = event.data.OrderId;
    } else {
      this.selectedOrderId = null;
    }
  }

  cancel() {
    this.onCancel.emit();
    this.hide({ shouldReload: false });
  }

  switchCard() {
    const modalRef = this.modalOpenService.show(NumpadWrapperComponent, {
      'backdrop': 'static',
      keyboard: false,
      class: 'vertical-center',
      initialState: {
        numpadTitle: 'Gift Card Number Entry',
        numpadOption: {
          allowDecimal: false, allowLeadingZero: true, allowAlphabets: true, doubleZero: false
        }
      }
    });
    modalRef.close.subscribe((res) => {
      this.onSwitchCardSubmit(res);
    });
  }

  addToBalance() {
    const modalRef = this.modalOpenService.show(AddToBalanceComponent, {
      'backdrop': 'static',
      keyboard: false,
      class: 'vertical-center modal-max-width-lg-35',
      initialState: {
        giftCardDetail: this.giftCardDetails,
      }
    });
    modalRef.close.subscribe(res => {
      if (res?.shouldReload) {
        this.reloadGiftCardDetails();
        this.alertService.renderSuccessMessage(StringUtils.format(Messages.GiftCardBalanceAddedSuccess,
          { 'balance': parseFloat(res.balance).toFixed(2), 'currencySymbol': this.settingParams.CurrencySymbol }));
      }
    });
  }

  onSwitchCardSubmit(event: any) {
    if (event && event.value != null && event.value !== undefined) {
      if (this.giftCardNumber !== event.value) {
        this.giftCardNumber = event.value;
        this.selectedOrderId = null;
        this.reloadGiftCardDetails();
      }
    }
  }

  printGiftCardBalance() {
    if (this.applicationStateService.settingParam.ReceiptPrinter?.Id) {
      this.spinnerService.show();
      const giftCardPrintObj = this.prepareGiftCashBalanceRequest(1);
      giftCardPrintObj.Amount = this.giftCardDetails.CurrentBalance;
      this.giftCardService.printGiftCardBalance(giftCardPrintObj)
        .subscribe({
          next: (res) => {

          }, error: this.alertService.showApiError,
          complete: () => {
            this.spinnerService.hide();
          }
        });
    } else {
      this.alertService.renderErrorMessage(Messages.ReceiptPrinterNotMapped);
    }
  }
}
