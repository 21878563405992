<ng-select posInput [items]="externalApis" bindLabel="Name" bindValue="Id" placeholder="Select external API"
  [(ngModel)]="selectedExternalApi" (change)="changeExternalApis()" appendTo="body">
</ng-select>

<div class="col-md-12 p-0" *ngIf="externalApiParameters && externalApiParameters.length > 0">
  <div class="page-content pt-pix-10 external-api-event">
    <div class="tab-pane fade in active show tab-pane-m-p">
      <form #externalApisForm="ngForm" novalidate>
        <p-table [value]="externalApiParameters">
          <ng-template pTemplate="header">
            <tr>
              <th style="width: 45%">Parameter</th>
              <th style="width: 30%">Value</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-i="rowIndex">
            <tr [pReorderableRow]="i">
              <td class="vertical-mid-imp">
                {{rowData.ParamName}}
              </td>
              <td class="vertical-mid-imp">
                  <input pInputText type="text" class="form-control" name="ParamValue{{i}}" #ParamValue="ngModel"
                    [(ngModel)]="rowData.ParamValue" (change)="changeParameter()"/>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </form>
    </div>
  </div>
</div>
