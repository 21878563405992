<pos-list-wrapper [headingIcon]="icons.infoManageTablesSolarPanel" heading="Accounts" (close)="close()">
    <div class="row">
        <div class="col-12 text-right">
            <div class="inline-block ps-pix-10 float-end label-section">
                <p-checkbox name="chkIsActiveAccounts" #chkIsActiveAccounts="ngModel" [(ngModel)]="isActiveAccounts"
                    binary="true" (onChange)="filterAccounts()" label="Active">
                </p-checkbox>
            </div>
            <div class="inline-block ps-pix-10 float-right col-200">
                <ng-select posInput [items]="accountTypes" bindLabel="AccountTerm" bindValue="Id"
                    placeholder="Select account type" appendTo="body" [(ngModel)]="accountTypeId" name="accountType"
                    (change)="filterAccounts()">
                </ng-select>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <ng-table id="account" #accountGrid [sort]="true" [columns]="accountColumns" [exportFilename]="'account'"
                [data]="filteredAccounts">
            </ng-table>
            <ng-template let-data="rowData" #iconTemplate>
                <span *ngIf="data.IsActive" class="green text-center vertical-mid font-13">
                    <pos-icon [icon]="icons.check"></pos-icon>
                </span>
            </ng-template>
            <ng-template let-data="rowData" #phoneTemplate>
                <span *ngIf="data.Phone">
                    {{data.Phone | phoneMaskPipe }}
                </span>
            </ng-template>
        </div>
    </div>
</pos-list-wrapper>
<pos-print-table [hidden]="true"></pos-print-table>
