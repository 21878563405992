<span>
    <input posInput type="text" name="Name{{attributeType.Id}}{{attributeType.Ordinal}}" #sectionName="ngModel"
        (input)="changeCurrentAttribute()" class="form-control" [(ngModel)]="attributeType.Value"
        *ngIf="attributeType.ValueType == accountAttributeValueType.Text"
        [required]="attributeType.IsRequired && applyRequired" />

    <input posInput type="number" name="Name{{attributeType.Id}}{{attributeType.Ordinal}}" #sectionName="ngModel"
        (input)="changeCurrentAttribute()" class="form-control" [(ngModel)]="attributeType.Value"
        *ngIf="attributeType.ValueType == accountAttributeValueType.Number"
        [required]="attributeType.IsRequired && applyRequired" />

    <textarea posInput name="Name{{attributeType.Id}}{{attributeType.Ordinal}}" rows="3" #sectionName="ngModel"
        (input)="changeCurrentAttribute()" class="form-control" [(ngModel)]="attributeType.Value"
        *ngIf="attributeType.ValueType == accountAttributeValueType.Paragraph"
        [required]="attributeType.IsRequired && applyRequired"></textarea>

    <ng-select posInput id="Name{{attributeType.Id}}{{attributeType.Ordinal}}" appendTo="body"
        name="Name{{attributeType.Id}}{{attributeType.Ordinal}}" (change)="changeCurrentAttribute()"
        [(ngModel)]="attributeType.Value" [items]="attributeType.DropdownValues" style="height: 34px;"
        *ngIf="attributeType.ValueType == accountAttributeValueType.Dropdown"
        [required]="attributeType.IsRequired && applyRequired">
    </ng-select>


    <p-calendar posInput #sectionName="ngModel" inputStyleClass="form-control" [showIcon]="true" appendTo="body"
        [dateFormat]="dateFormat" name="Name{{attributeType.Id}}{{attributeType.Ordinal}}"
        [(ngModel)]="attributeType.Value" *ngIf="attributeType.ValueType == accountAttributeValueType.Date"
        (onSelect)="changeCurrentAttribute()" (onBlur)="changeCurrentAttribute()"
        [required]="attributeType.IsRequired && applyRequired"></p-calendar>

    <span *ngIf="attributeType.ValueType == accountAttributeValueType.Radio">
        <label class="pe-pix-10 pt-pix-5" *ngFor="let option of attributeType.DropdownValues">
            <input type="radio" class="btn-chk-box" (change)="changeCurrentAttribute()"
                name="Name{{attributeType.Id}}{{attributeType.Ordinal}}" [(ngModel)]="attributeType.Value"
                value="{{option}}"> <span class="vertical-mid"> {{option}} </span>
        </label>
    </span>
</span>
