import { Component, EventEmitter } from '@angular/core';
import { ApplicationStateService, ICloseable } from 'src/app/shared';

@Component({
  selector: 'pos-pickup-time-entry',
  templateUrl: './pickup-time-entry.component.html',
  styleUrls: ['./pickup-time-entry.component.scss']
})
export class PickupTimeEntryComponent implements ICloseable {
  
  close: EventEmitter<any> = new EventEmitter();
  hourFormat = '12';
  selectedTime: Date;
  title: string;
  timeSlots = [
    { text: '10 min', value: 10 },
    { text: '15 min', value: 15 },
    { text: '20 min', value: 20 },
    { text: '30 min', value: 30 },
    { text: '45 min', value: 45 },
    { text: '60 min', value: 60 },
  ]

  constructor(private applicationStateService: ApplicationStateService){}

  ngOnInit() {
    this.hourFormat = this.applicationStateService.settingParam.PCalendarHourFormat;
  }
  
  closeTimeEntry() {
    this.close.emit();
  }

  changeTime() {
    const currentDate = new Date();
    currentDate.setSeconds(0, 0);
    this.selectedTime.setSeconds(0, 0);
    this.selectedTime = this.selectedTime <= currentDate ? currentDate : new Date(this.selectedTime);
  }

  addHour(hour: number) {
    const newHour = this.selectedTime.getHours() + hour;
    
    // Use modulo to ensure hour stays within 0-23
    this.selectedTime.setHours((newHour + 24) % 24);
    this.changeTime();
  }

  addMinutes(minute: number) {
    const newMinute = this.selectedTime.getMinutes() + minute;
    const newHour = this.selectedTime.getHours() + Math.floor(newMinute / 60);

    this.selectedTime.setMinutes((newMinute + 60) % 60);
    this.selectedTime.setHours((newHour + 24) % 24);
    this.changeTime();
  }

  enterCallInTime() {

    this.close.emit({
      enteredTime: this.selectedTime,
    });
  }

  setAfterXMinute(minute) {
    const currentDate = new Date();
    currentDate.setSeconds(0);
    currentDate.setMinutes(currentDate.getMinutes() + parseInt(minute, 10));
    this.selectedTime = currentDate;
    this.changeTime();
  }
}
