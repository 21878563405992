<div *ngIf="order">
    <div class="make-table-page__content">
        <div [ngStyle]="getColor(order.MakeTableDateTime)" pTooltip="{{order.TerminalName}}" tooltipPosition="top"
            (click)="collapseToggle()" class="pb-pix-1">
            <span class="position-absolute mt-pix-n8">
                <pos-icon [icon]="icons.makeTableCollapseCaretRight" class="font-24"
                    *ngIf="uiStates[order.Id].IsCollapsed && uiStates[order.Id].IsCollapsible"></pos-icon>
                <pos-icon [icon]="icons.makeTableCollapseCaretDown" class="font-24"
                    *ngIf="!uiStates[order.Id].IsCollapsed && uiStates[order.Id].IsCollapsible"></pos-icon>

            </span>
            <span class="ms-2 ps-pix-20">
                <span class="bold">
                    {{order.SurrogateOrderId}}
                    
                </span>
                <span *ngIf="uiStates[order.Id].IsCollapsed">
                    (Qty: {{uiStates[order.Id].TotalOrderProducts}})
                </span>
            </span>
            <span class="me-2 float-end card-header">                
                <span *ngIf="settingParam.IsShowTipOnMakeTable && order.Gratuity > 0" class="badge pb-pix-3 bg-black me-pix-5 vertical-text-bottom">Tip: {{order.Gratuity | tark_currency }}</span>
                <pos-icon [icon]="icons.check" pTooltip="Paid" tooltipPosition="top" class="green pe-pix-5" *ngIf="order.GrandTotal > 0 && order.GrandTotal <= order.PaidAmount"></pos-icon>
                <pos-icon [icon]="icons.exclamationTriangle" class="me-pix-5" *ngIf="!order.IsVerified"></pos-icon>
                <pos-icon [icon]="icons.clock" class="me-pix-5" *ngIf="order.IsVerified"></pos-icon>
                <span formattedTime='A'>{{uiStates[order.Id].FormattedTime}}</span>
            </span>

        </div>
        <div class="make-table-page__content__block" *ngIf="order.OrderAttributes.ArrivalTime">
            <div class="white ps-pix-20" [ngStyle]="{'background-color': 'black'}">
                <span class="ms-2 width-full">
                    Customer arriving at: {{order.OrderAttributes.ArrivalTime | tark_time}}
                </span>
            </div>
        </div>
        <div class="make-table-page__content__block" *ngIf="order.OrderAttributes.PagerNumber">
            <div class="ps-pix-20"
                [ngStyle]="{'background-color':makeTableColorConfig.MakeTablePagerColor, 'color': getFontColor(makeTableColorConfig.MakeTablePagerColor)}">
                <span class="ms-2 width-full">
                    PAGER {{order.OrderAttributes.PagerNumber}}
                </span>
            </div>
        </div>
        <div class="make-table-page__content__block" *ngIf="order.CustomerAccount">
            <div class="ps-pix-20"
                [ngStyle]="{'background-color':makeTableColorConfig.MakeTableAccountColor, 'color': getFontColor(makeTableColorConfig.MakeTableAccountColor)}">
                <span class="ms-2 width-full">
                    {{loyaltyAccountTerm}}: {{order.CustomerAccount.Name}}
                </span>
            </div>
        </div>
        <div class="make-table-page__content__block"
            *ngIf="(order.Account && order.Account.Name) || order.LoyaltyCardNumber">
            <div class="ps-pix-20"
                [ngStyle]="{'background-color':makeTableColorConfig.MakeTableAccountColor, 'color': getFontColor(makeTableColorConfig.MakeTableAccountColor)}">
                <span class="ms-2 width-ful ml-20l">
                    {{order.Account && order.Account.Name && order.Account.Name != "--" ? order.Account.Name :
                    order.LoyaltyCardNumber}}
                </span>
            </div>
        </div>
        <div class="make-table-page__content__block" *ngIf="order.OrderName">
            <div class="ps-pix-20"
                [ngStyle]="{'background-color':order.CommentDefaultColor, 'color': order.CommentDefaultForeColor}">
                <span class="ms-2 width-full">
                    Name: {{order.OrderName}}
                </span>
            </div>
        </div>
        <div class="make-table-page__content__block" *ngIf="order.OrderAttributes.PickupTime">
            <div class="ps-pix-20"
                [ngStyle]="{'background-color':order.CommentDefaultColor, 'color': order.CommentDefaultForeColor}">
                <span class="ms-2 width-full">
                    Online Order - Pickup:
                    {{ today === pickupDate ? (order.OrderAttributes.PickupTime | tark_time) : (order.OrderAttributes.PickupTime | tark_date_time)}}
                </span>
            </div>
        </div>
        <div class="make-table-page__content__block" *ngIf="order.ContactNumber">
            <div class="ps-pix-20"
                [ngStyle]="{'background-color': makeTableColorConfig.MakeTableAccountColor,  'color': getFontColor(makeTableColorConfig.MakeTableAccountColor)}">
                <span class="ms-2 width-full vertical-mid">
                    Phone: {{order.ContactNumber | phoneMaskPipe}}
                </span>
            </div>
        </div>
        <div class="make-table-page__content__block" *ngIf="order.OrderAttributes.IsCallIn">
            <div class="ps-pix-20"
                [ngStyle]="{'background-color': makeTableColorConfig.MakeTableCallInColor,  'color': getFontColor(makeTableColorConfig.MakeTableCallInColor)}">
                <span class="ms-2 width-full vertical-mid">
                    Call-In: {{order.OrderAttributes.CallInOrderTime}}
                </span>
            </div>
        </div>
        <div *ngFor="let item of order.OrderItems | groupBy: 'SubAccountOrdinal'  | keyvalue: originalOrder; let c = count">
            <div *ngIf="((item.key)) && item.key > 0 && !uiStates[order.Id].IsCollapsed 
                    && (settingParam.ShowSubaccountInMakeTable && (!settingParam.ShowWhenMoreThanOneSubaccountInMakeTable || c > 1) || item.key == 99)"
                class="text-center bg-theme-primary">
                <label class="mb-pix-2 sub-account-name text-ellipsis vertical-mid width-90" *ngIf="item.key != 99"> {{item?.value[0]?.SubaccountName ? item?.value[0]?.SubaccountName : (subAccountTerm + " " + item.key)}}
                </label>
                <label class="white mb-0" *ngIf="item.key == 99"> To Go </label>
            </div>

            <div class="make-table-page__content__block" *ngFor="let product of item.value; let i = index" [hidden]="(!product.OrderProductComponents[0].IsShowOnMakeTable ||
            (product.OrderItemType == orderItemType.Product && showProductsWithState && !showProductsWithState.includes(product.MakeTableState))
             || (product.OrderItemType == orderItemType.Product && !product.IsSentToKitchen) || product.IsScheduledProduct)">
                <div *ngIf="product.IsFiltered || !isFilteredProductsApplied">
                    <div class="text-center" (click)="changeOrderUpdateState(product,order);"
                        *ngIf="isShowOrderUpdates && product.OrderUpdate">
                        <button type="button"
                            class="btn-primary make-table-page__content__block__update">Update</button>
                    </div>
                    <div *ngIf="!uiStates[order.Id].IsCollapsed">
                        <div class="pt-pix-2 warning-popover {{order.Id}} align-content-center" [style.backgroundColor]="product.MakeTableBgColor"
                            [style.minHeight]="isShowNotifyButtonOnEachItem ? '30px' : '26px'" (click)="orderItemStateChange(product, order)"
                            data-toggle="popover" data-container="body" data-placement="top" data-trigger="manual"
                            id="{{product.Id}}">
                            <span class="ms-2 float-start text-ellipsis"
                                [ngClass]="{'width-75': product.OrderItemType == orderItemType.Product && !isShowNotifyButtonOnEachItem,'width-full':product.OrderItemType != orderItemType.Product
                                    ,'width-62':product.OrderItemType == orderItemType.Product && isShowNotifyButtonOnEachItem}"
                                [ngStyle]="{'color': product.MakeTableFgColor ? product.MakeTableFgColor : product.FontColor}">
                                <span *ngIf="product.OrderItemType == orderItemType.Product"
                                    style="vertical-align: middle;"
                                    class="badge make-table-page__content__block__qty me-pix-3"
                                    [ngClass]="{'qty-circle-notify-present': isShowNotifyButtonOnEachItem, 'qty-circle': !isShowNotifyButtonOnEachItem}"
                                    [ngStyle]="{'background-color': product.QtyBgColor,'color': product.QtyFontColor, 'border-radius': settingParam.AllowOrderingFractionalQty ? '5px':''? '5px':''}">
                                    {{product.Qty | productQty}}
                                </span>
                                <span
                                    *ngIf="product.OrderItemType == orderItemType.Name || product.OrderItemType == orderItemType.Comment"
                                    style="vertical-align: middle;">
                                </span>
                                <span class="bold me-pix-3">{{product.SizeName}}</span>
                                <span [ngClass]="{'ps-pix-17': product.OrderItemType == orderItemType.Comment}">{{product.MakeTableText}}</span>
                            </span>
                            <span class="make-table-page__content__block__icon"
                                *ngIf="product.OrderItemType == orderItemType.Product">
                                <button class="item-ready-notify-button"  *ngIf="isShowNotifyButtonOnEachItem"
                                    [ngStyle]="{'background-color': notifyButtonColor, 'color': notifyButtonFontColor}"
                                    (click)="onNotifyOrderItemReady(order, product, $event)">
                                    <pos-icon [icon]="icons.bellOn" *ngIf="product?.IsNotified"></pos-icon>
                                    <pos-icon [icon]="icons.bell" *ngIf="!product?.IsNotified"></pos-icon>
                                </button>
                                <span class="position-absolute font-11 white px-pix-3 py-pix-2 mt-pix-1 radius-10 bold order-section__bottom__block__buttons__btn text-ellipsis bg-black" *ngIf="!product.IsMakeTableStateChangeByGenericUser && product.MakeTableStateByUserLastName && product.MakeTableStateByUserFirstName">
                                    {{product.MakeTableStateByUserFirstName?.charAt(0)?.toUpperCase()}}{{product.MakeTableStateByUserLastName?.charAt(0)?.toUpperCase()}}
                                 </span>
                                <pos-icon [icon]="icons.star" *ngIf="product.MakeTableState == makeTableStates.PENDING && settingParam.IsShowMakeTablePendingState 
              && product.OrderItemType == orderItemType.Product" class="make-table-page__content__block__icon--i"
                                    [ngStyle]="{'color': isStarColor ? '#D4AF37':'black'}"></pos-icon>
                                <pos-icon [icon]="icons.checkCircleMakeTable"
                                    class="make-table-page__content__block__icon--i font-15"
                                    *ngIf="product.MakeTableState==makeTableStates.DONE"
                                    [ngStyle]="{'color':product.FontColor}">
                                </pos-icon>

                                <pos-icon [icon]="icons.clock" class="make-table-page__content__block__icon--i font-15"
                                    [ngStyle]="{'color':product.FontColor}"
                                    *ngIf="product.MakeTableState==makeTableStates.COOKING">
                                </pos-icon>

                                <pos-icon [icon]="icons.cogs" class="bold make-table-page__content__block__icon--i pt-pix-1"
                                    [ngStyle]="{'color':product.FontColor}"
                                    *ngIf="product.MakeTableState==makeTableStates.RETRIEVING_INGREDIENTS"></pos-icon>

                                <pos-icon [icon]="icons.magic" class="make-table-page__content__block__icon--i pt-pix-1"
                                    [ngStyle]="{'color':product.FontColor}"
                                    *ngIf="product.MakeTableState==makeTableStates.CRAFTING">
                                </pos-icon>
                                <pos-icon [icon]="icons.rocket" class="make-table-page__content__block__icon--i pt-pix-1"
                                    [ngStyle]="{'color':product.FontColor}"
                                    *ngIf="product.MakeTableState==makeTableStates.READY">
                                </pos-icon>
                                <pos-icon [icon]="icons.tasks" class="make-table-page__content__block__icon--i pt-pix-1"
                                    [ngStyle]="{'color':product.FontColor}"
                                    *ngIf="product.MakeTableState==makeTableStates.PARTIALLY_IN_PROGRESS">
                                </pos-icon>
                            </span>
                            <div class="clearfix"></div>
                        </div>
                        <div *ngFor="let component of product.OrderProductComponents">
                            <div
                                *ngIf="((component.SalesProductId != product.SalesProductId && product.SalesProductId > 0)
                        || (product.SalesProductId < 0 && product.MakeTableText != component.MakeTableText)) && component.IsShowOnMakeTable">
                                <div class="make-table-page__content__block__info"
                                    *ngIf="component.MakeTableText.indexOf('C:')==0 && !component.IsSizeButton">
                                    {{component.MakeTableText}}</div>
                                <div class="make-table-page__content__block__info"
                                    [ngStyle]="{'background-color': component.MakeTableBgColor, 'color':component.MakeTableFgColor} "
                                    *ngIf="component.MakeTableText.indexOf('-')==0 && !component.IsSizeButton">
                                    <pos-icon [icon]="icons.times" class="red-glyph red"></pos-icon>
                                    <span class="ms-pix-3">
                                        {{component.MakeTableText | noMinusSign }}
                                    </span>
                                </div>
                                <div class="make-table-page__content__block__info display-flex"
                                    [ngStyle]="{'background-color': component.MakeTableBgColor, 'color':component.MakeTableFgColor}"
                                    *ngIf="component.MakeTableText.indexOf('-')!=0 && component.MakeTableText.indexOf('C:')!=0 && !component.IsSizeButton">
                                    <pos-icon [icon]="icons.plusMessage" style="font-size: 13px"
                                        class="blue-glyph green">
                                    </pos-icon>
                                    <span class="component-bottom-margin ms-pix-4">
                                        {{component.MakeTableText}}
                                    </span>
                                    <div class="pt-pix-1 ps-pix-5">
                                        <pos-icon [icon]="icons.clock" class="white-glyph"
                                            *ngIf="component.TimerState=='Cooking'">
                                        </pos-icon>
                                        <pos-icon [icon]="icons.check" class="green-glyph"
                                            *ngIf="component.TimerState=='Done'">
                                        </pos-icon>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="make-table-page__content__block"
                            *ngIf="order.DietaryRestrictions && order.DietaryRestrictions.length && product?.DietaryWarnings?.length && product.OrderItemType == orderItemType.Product">
                            <div class="make-table-page__content__block__info white bg-black">
                                <pos-icon [icon]="icons.exclamationTriangle" style="font-size: 13px"
                                    class="blue-glyph white">
                                </pos-icon>
                                <span
                                    *ngFor="let dietaryRestriction of (product?.DietaryWarnings.length ? product.DietaryWarnings : []);let last = last">
                                    <span *ngIf="dietaryRestriction.SubaccountOrdinal === product.SubAccountOrdinal">
                                        {{dietaryRestriction.Name + (last ? '' :', ')}}
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="make-table-page__content__block text-center mt-pix-5" *ngIf="order.ScheduledProductCount">
            <span class="bg-theme-separator ps-pix-5 pe-pix-5">
                {{ order.ScheduledProductCount }} Scheduled {{ order.ScheduledProductCount > 1 ? 'products' : 'product' }}
            </span>
        </div>
    </div>
