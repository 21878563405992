<pos-list-wrapper [headingIcon]="icons.djSchemesRoad" heading="DJ Schemes" (close)="close()">
  <div class="row">
    <div class="col-12">
      <ng-table [columns]="djSchemeColumns" [data]="djSchemes" [paginator]="false" [search]="false"></ng-table>
      <ng-template let-data="rowData" #nameHeaderTemplate>
        <label class="mb-0 d-flex">
          <span class="bold pointer" (click)="sortData()"> Scheme
            <pos-icon [icon]="icons.sort"></pos-icon>
          </span>
          <field-info [info]="fieldInfoMessages.DJScheme" styleClass="pt-pix-2 pull-right mt-pix-n3"></field-info>
        </label>
      </ng-template>
      <ng-template let-data="rowData" #nameTemplate>
        {{ data.Name }}
      </ng-template>
      <ng-template let-data="rowData" #operationHeaderTemplate>
        <div class="text-center">
          <pos-action-icon (click)="editItem(0)" [icon]="icons.plusWhite" iconText="Add"></pos-action-icon>
        </div>
      </ng-template>
      <ng-template let-data="rowData" #operationTemplate>
        <div class="d-flex justify-content-center">
          <span class="pointer" (click)="editItem(data.Id, data)" tooltip title="Edit">
            <pos-action-icon [icon]="icons.editWhite"></pos-action-icon>
          </span>
          <span class="pointer" (click)="deleteScheme(data)" tooltip title="Delete">
            <pos-action-icon [icon]="icons.deleteWhite"></pos-action-icon>
          </span>
          <span>
            <a class="action-btn grey pointer" #pop="bs-popover" placement="left" (click)="onLinkPopOver(data,pop)"
              [outsideClick]="true" [popover]="schemePopoverTemplate" triggers="click">
              <pos-action-icon [icon]="icons.barsWhite"></pos-action-icon>
            </a>
          </span>
        </div>
      </ng-template>
      <ng-template #schemePopoverTemplate>
        <ul>
          <li class="listbullet pointer">
            <a class="grey" (click)="copyDJScheme(selectedScheme.Id)">
              <pos-icon [icon]="icons.clone" class="pe-pix-8"></pos-icon> Copy DJ Scheme
            </a>
          </li>
        </ul>
      </ng-template>
    </div>
  </div>
</pos-list-wrapper>
