<div class="row mt-pix-20">
    <div class="col-md-4" *ngIf="chartOptions">
        <highcharts-chart [Highcharts]="highcharts" [options]="chartOptions"></highcharts-chart>
        <div class="col-md-12 text-center font-20" [ngStyle]="{'color': color}">
            {{chartConfig.metricsName}}
        </div>
    </div>
    <div class="col-md-4" style="height: 200px;" *ngIf="gaugeChartOptions">
        <highcharts-chart [Highcharts]="highcharts" [options]="gaugeChartOptions">
        </highcharts-chart>
        <div class="col-md-12 text-center font-20" [ngStyle]="{'color': color}">
            {{gaugeConfig.metricsName}}
        </div>
    </div>
    <div class="col-md-4" *ngIf="tableData">
        <ng-table [columns]="tableColumns" [data]="tableData" [paginator]="false" [exportData]="true"
            [rowBgColor]="'Meta_Color'" [rowFontColor]="'FontColor'"></ng-table>
        <div class="col-md-12 text-center font-20">
            {{tableConfig.metricsName}}
        </div>
    </div>
</div>
<div class="text-widget col-lg-4 col-md-4 col-sm-4 col-xs-4 p-0 full-height vertical-mid" *ngIf="textData"
    [ngStyle]="{'color':color,'background-color':bgColor,'border':'solid 2px '+(borderColor || bgColor || '#fff')}">
    <div class="col-12 ps-pix-15 pe-pix-15 pt-pix-5 pb-pix-5">
        <div class="text-widget__stat">
            {{ textData }}
        </div>
        <div class="text-center pt-pix-15 font-20">{{textConfig.metricsName}}</div>
    </div>
</div>
