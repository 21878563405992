<div class="width-80 m-0-auto">
    <pos-list-wrapper [headingIcon]="icons.fileAudio" heading="Captured Audio" (close)="close()">
        <div class="position-relative">
            <div class="audio-recording pe-0">
                <ng-select posInput [items]="audioRecorders" [(ngModel)]="mappedAudioRecorder" bindLabel="Name"
                    bindValue="Id" (change)="getAudioRecordings()" class="col-200"
                    placeholder="Select audio recorder"></ng-select>
                <button class="btn btn-secondary text-nowrap col-100 ms-2" (click)="loadAudioRecordings()">
                    <pos-icon [icon]="icons.sync"></pos-icon> Refresh
                </button>
            </div>
            <div class="col-md-1 col-sm-1"></div>
            <div class="col-md-12 col-sm-12">
                <ng-table #gridComponent [scrollable]="true" [scrollHeight]="screenHeight.toString()"
                    [paginator]="true" [columns]="audioRecordingColumns" [data]="recordings">
                </ng-table>
            </div>
        </div>
        <ng-template let-data="rowData" #operationTemplate>
            <div>
                <span class="pe-pix-3" tooltip title="Play" class="inline-block">
                    <pos-action-icon [icon]="icons.playGreen"
                        (click)="sendPlayAudioRequest(data)"></pos-action-icon>
                </span>
                <span class="pointer pe-pix-3 inline-block" (click)="downloadAudio(data)" tooltip
                    title="Download">
                    <pos-action-icon [icon]="icons.downloadWhite"></pos-action-icon>
                </span>
            </div>
        </ng-template>
    </pos-list-wrapper>
</div>

