import { Route } from '@angular/router';
import { SalesSizeListComponent } from './sales-sizes/components/sales-size-list';
import { CommentWarningsListComponent } from './comment-warnings/components/comment-warnings-list';
import { ProductLossReasonsComponent } from './product-loss-reasons';
import { RawIngredientsListComponent, RawIngredientsEditComponent } from './raw-ingredients/component';
import { Permissions, Levels, DomainConstants } from '../shared/constants';
import { TaskListComponent } from './tasks';
import { TrainingAreasListComponent } from './training-areas/components/training-areas-list/training-areas-list.component';
import { TrainingTopicListComponent } from './training-topics/components/training-topic-list/training-topic-list.component';
import { TrainingTopicEditComponent } from './training-topics/components/training-topic-edit/training-topic-edit.component';
import { TrainingBadgeListComponent } from './training-badges/components/training-badge-list/training-badge-list.component';
import { TrainingBadgeEditComponent } from './training-badges/components/training-badge-edit/training-badge-edit.component';
import { TrainingAreaPageComponent } from './training-areas/components/training-area-page/training-area-page.component';
import {
    TrainingTopicLevelEditComponent
} from './training-topic-levels/components/training-topic-level-edit/training-topic-level-edit.component';
import {
    TrainingTopicLevelListComponent
} from './training-topic-levels/components/training-topic-level-list/training-topic-level-list.component';
import { OnlineOrderingSettingsComponent, OnlineOrderingCategoriesComponent } from './online-ordering';
import {
    OnlineOrderingCategoryEditComponent
} from './online-ordering/component/online-ordering-categories/online-ordering-category-edit/online-ordering-category-edit.component';
import {
    OnlineOrderingOptionListComponent
} from './online-ordering/component/online-ordering-options/online-ordering-option-list/online-ordering-option-list.component';
import {
    OnlineOrderingOptionEditComponent
} from './online-ordering/component/online-ordering-options/online-ordering-option-edit/online-ordering-option-edit.component';
import {
    OnlineOrderingConfigurationsComponent
} from './online-ordering/component/online-ordering-configurations/online-ordering-configurations.component';
import { InventoryScheduleEditorComponent } from './inventory-schedule-editor/inventory-schedule-editor.component';
import { AudioNotificationComponent, AudioNotificationEditComponent } from './audio-notification-files';
import {
    AccountAttributeTypesComponent
} from './customers/account-attribute-types/components/account-attribute-types/account-attribute-types.component';
import {
    AccountAttributeTypeEditComponent
} from './customers/account-attribute-types/components/account-attribute-type-edit/account-attribute-type-edit.component';
import { UnitsListComponent } from './units';
import { InventoryZoneListComponent, InventoryZoneEditComponent } from './inventory-zone';
import {
    InventorySubcategoryListComponent
} from './inventory-subcategories/component/inventory-subcategory-list/inventory-subcategory-list.component';
import { SalesGroupEditModelComponent, SalesGroupsComponent } from './sales-groups';
import { SalesCategoriesComponent } from './sales-categories/component/sales-categories/sales-categories.component';
import { SalesGroupEditWrapComponent } from './sales-groups/component/sales-group-edit-wrap/sales-group-edit-wrap.component';
import {
    InventoryVendorProfileEditComponent, InventoryVendorProfileProductEditComponent,
    InventoryVendorProfileProductsComponent, InventoryVendorProfilesComponent, VendorEditComponent, VendorsListComponent
} from './vendors';
import { InventoryCategoryListComponent } from './inventory-categories';
import { SecurityRolesListComponent } from './security-roles/component/security-roles-list/security-roles-list.component';
import { InfoLinkSectionsComponent } from './info-link-section/components/info-link-sections/info-link-sections.component';
import { InfoLinksComponent } from './info-link/components/info-links/info-links.component';
import { UserListComponent } from './users/component/user-list/user-list.component';
import { UserEditComponent } from './users/component/user-edit/user-edit.component';
import { ButtonListComponent } from './buttons/component/button-list/button-list.component';
import { InfractionsListComponent } from './infractions/component/infractions-list/infractions-list.component';
import { InfractionEditComponent } from './infractions/component/infraction-edit/infraction-edit.component';
import { LoginMessagesComponent } from './login-messages/components/login-messages/login-messages.component';
import { LoginMessagesEditComponent } from './login-messages';
import { WorkScheduleListComponent } from './work-schedule/components/work-schedule-list/work-schedule-list.component';
import { WorkScheduleEditComponent } from './work-schedule/components/work-schedule-edit/work-schedule-edit.component';
import { WorkScheduleTagListComponent } from './work-schedule/components/work-schedule-tag-list/work-schedule-tag-list.component';
import { JobCodeListComponent } from './job-code/component/job-code-list/job-code-list.component';
import { JobCodeEditComponent } from './job-code/component/job-code-edit/job-code-edit.component';
import { CashPayoutListComponent } from './cash-payout/component/cash-payout-list/cash-payout-list.component';
import { EditCashPayoutComponent } from './cash-payout/component/edit-cash-payout/edit-cash-payout.component';
import { CashDrawerEntriesComponent } from './cash-drawer-entries/component/cash-drawer-entries/cash-drawer-entries.component';
import { AccountCategoryListComponent } from './account-categories/component/account-category-list/account-category-list.component';
import { EditAccountCategoryComponent } from './account-categories/component/edit-account-category/edit-account-category.component';
import { AccountsListComponent } from './account/component/accounts-list/accounts-list.component';
import { EquipmentTypeListComponent } from './equipment-types/component/equipment-type-list/equipment-type-list.component';
import { EquipmentPropertyListComponent } from './equipment-properties/component/equipment-property-list/equipment-property-list.component';
import { EquipmentListComponent } from './equipment/component/equipment-list/equipment-list.component';
import { TimeClockEntriesListComponent, TimeClockEntriesEditComponent } from './time-clock-entries';
import { ScreenListComponent } from './screen/components/screen-list/screen-list.component';
import { SalesCategoryEditWrapComponent } from './sales-categories/component/sales-category-edit-wrap/sales-category-edit-wrap.component';
import { SalesProductEditWrapComponent, SalesProductListComponent, SalesProductsBulkUpdateComponent } from './sales-products';
import { InventoryProductListComponent } from './inventory-products/components/inventory-product-list/inventory-product-list.component';
import {
    InventoryProductEditWrapComponent
} from './inventory-products/components/inventory-product-edit-wrap/inventory-product-edit-wrap.component';
import { AccountEditWrapperComponent } from './account/component/account-edit-wrapper/account-edit-wrapper.component';
import { SalesUnitEditComponent } from './sales-units/components/sales-unit-edit/sales-unit-edit.component';
import { SalesUnitsListComponent } from './sales-units/components/sales-units-list/sales-units-list.component';
import { InformationManagementBaseComponent } from './information-management-base/information-management-base.component';
import { TagsEditComponent } from './tags/component/tags-edit/tags-edit.component';
import { TagsListComponent } from './tags/component/tags-list/tags-list.component';
import { InventoryProductHistoryListComponent } from './inventory-products/components/inventory-product-history-list/inventory-product-history-list.component';
import { InventoryProductHistoryEditComponent } from './inventory-products/components/inventory-product-history-edit/inventory-product-history-edit.component';
import { ButtonHistoryComponent } from './buttons/component/button-history/button-history.component';
import { ScreenHistoryComponent } from './screen/components/screen-history/screen-history.component';
import { SalesProductHistoryComponent } from './sales-products/component/sales-product-history/sales-product-history.component';
import { ButtonHistoryEditComponent } from './buttons/component/button-history-edit/button-history-edit.component';
import { SalesProductHistoryEditComponent } from './sales-products/component/sales-product-history-edit/sales-product-history-edit.component';
import { ScreenHistoryEditComponent } from './screen/components/screen-history-edit/screen-history-edit.component';
import { SalesProductSizeHistoryComponent } from './sales-products/component/sales-product-size-history/sales-product-size-history.component';
import { SalesProductSizeHistoryEditComponent } from './sales-products/component/sales-product-size-history-edit/sales-product-size-history-edit.component';
import { InventoryProductsBulkUpdateComponent } from './inventory-products/components/inventory-products-bulk-update/inventory-products-bulk-update.component';
import { EquipmentTypeEditComponent } from './equipment-types/component/equipment-type-edit';
import { EquipmentEditComponent } from './equipment/component/equipment-edit';
import { SecurityRoleEditComponent } from './security-roles/component/security-role-edit';
import { PermissionsListComponent } from './permissions/components/permissions-list/permissions-list.component';
import { PermissionsEditComponent } from './permissions/components/permissions-edit/permissions-edit.component';
import { InventoryOffSiteLocationsComponent, OffsiteInventoryProductSettingsComponent } from './inventory-off-site-locations';
import { forEach } from 'lodash';
import { WaitTimeRuleEditComponent, WaitTimeRulesComponent } from './wait-time/components';
import { MultiProductGroupListComponent } from './screen/components/multi-product-group-list/multi-product-group-list.component';
import { OnlineOrderingTermsConditionsComponent } from './online-ordering/component/online-ordering-terms-conditions/online-ordering-terms-conditions.component';
import { DietaryWarningListComponent } from './dietary-warnings/components';
import { DietaryWarningEditComponent } from './dietary-warnings/components/dietary-warning-edit/dietary-warning-edit.component';

export const INFORMATION_MANAGEMENT_CHILDREN: Array<Route> = [
    {
        path: 'sales-size',
        component: SalesSizeListComponent,
        data: {
            group: 'sales-size',
            permissions: [{ 'Name': Permissions.SalesSizes, 'Level': Levels.Access }]
        }
    },
    {
        path: 'comment-warning',
        component: CommentWarningsListComponent,
        data: {
            group: 'comment-warning',
            permissions: [{ 'Name': Permissions.CommentWarnings, 'Level': Levels.Access }]
        }
    },
    {
        path: 'product-loss-reason',
        component: ProductLossReasonsComponent,
        data: {
            group: 'product-loss-reason',
            permissions: [{ 'Name': Permissions.InventoryLossReason, 'Level': Levels.Access }]
        }
    },
    {
        path: 'raw-ingredients',
        component: RawIngredientsListComponent,
        data: {
            group: 'raw-ingredient',
            permissions: [{ 'Name': Permissions.InventoryRawIngredients, 'Level': Levels.Access }]
        }
    },
    {
        path: 'raw-ingredients/:id',
        component: RawIngredientsEditComponent,
        data: {
            group: 'raw-ingredient',
            permissions: [{ 'Name': Permissions.InventoryRawIngredients, 'Level': Levels.Edit }]
        }
    },
    {
        path: 'tasks',
        component: TaskListComponent,
        data: {
            group: 'task',
        }
    },
    {
        path: 'audio-notification-files',
        component: AudioNotificationComponent,
        data: {
            group: 'audio-notification-file',
            permissions: [{ 'Name': Permissions.AudioClips, 'Level': Levels.Access }]
        }
    },
    {
        path: 'audio-notification-files/:id',
        component: AudioNotificationEditComponent,
        data: {
            group: 'audio-notification-file',
            permissions: [{ 'Name': Permissions.AudioClips, 'Level': Levels.Edit }]
        }
    },
    {
        path: 'training-areas',
        component: TrainingAreasListComponent,
        data: {
            group: 'training',
            permissions: [{ 'Name': Permissions.TrainingAreas, 'Level': Levels.ReadOnly }]
        }
    },
    {
        path: 'training-areas/:id',
        component: TrainingAreaPageComponent,
        data: {
            group: 'training',
            permissions: [{ 'Name': Permissions.TrainingAreas, 'Level': Levels.Edit }]
        }
    },
    {
        path: 'training-areas/:areaId/topics',
        component: TrainingTopicListComponent,
        data: {
            group: 'training-topic',
            permissions: [{ 'Name': Permissions.TrainingTopics, 'Level': Levels.ReadOnly }],
        }
    },
    {
        path: 'training-areas/:areaId/topics/:id',
        component: TrainingTopicEditComponent,
        data: {
            group: 'training-topic',
            permissions: [{ 'Name': Permissions.TrainingTopics, 'Level': Levels.Edit }],
        }
    },
    {
        path: 'training-badges',
        component: TrainingBadgeListComponent,
        data: {
            group: 'training-badge',
            permissions: [{ 'Name': Permissions.TrainingBadges, 'Level': Levels.ReadOnly }]
        }
    },
    {
        path: 'training-badges/:id',
        component: TrainingBadgeEditComponent,
        data: {
            group: 'training-badge',
            permissions: [{ 'Name': Permissions.TrainingBadges, 'Level': Levels.Edit }],
        }
    },
    {
        path: 'training-areas/:areaId/topics/:topicId/levels',
        component: TrainingTopicLevelListComponent,
        data: {
            group: 'training-level',
            permissions: [{ 'Name': Permissions.TrainingTopicLevels, 'Level': Levels.ReadOnly }],
        }
    },
    {
        path: 'training-areas/:areaId/topics/:topicId/levels/:id',
        component: TrainingTopicLevelEditComponent,
        data: {
            group: 'training-level',
            permissions: [{ 'Name': Permissions.TrainingTopicLevels, 'Level': Levels.Edit }],
        }
    },
    {
        path: 'online-ordering-settings',
        component: OnlineOrderingSettingsComponent,
        data: {
            group: 'online-ordering-setting',
            permissions: [{ 'Name': Permissions.OnlineOrderingSettings, 'Level': Levels.ReadOnly }],
        }
    },
    {
        path: 'online-ordering-categories',
        component: OnlineOrderingCategoriesComponent,
        data: {
            group: 'online-ordering-category',
            permissions: [{ 'Name': Permissions.OnlineOrderingCategories, 'Level': Levels.ReadOnly }],
        }
    },
    {
        component: OnlineOrderingCategoryEditComponent,
        path: 'online-ordering-categories/:id',
        data: {
            group: 'online-ordering-category',
            permissions: [{ 'Name': Permissions.OnlineOrderingCategories, 'Level': Levels.Edit }],
        }
    },
    {
        path: 'online-ordering-options',
        component: OnlineOrderingOptionListComponent,
        data: {
            group: 'online-ordering-option',
            permissions: [{ 'Name': Permissions.OnlineOrderingOptionLists, 'Level': Levels.ReadOnly }],
        }
    },
    {
        component: OnlineOrderingOptionEditComponent,
        path: 'online-ordering-options/:id',
        data: {
            group: 'online-ordering-option',
            permissions: [{ 'Name': Permissions.OnlineOrderingOptionLists, 'Level': Levels.Edit }],
        }
    },
    {
        path: 'online-ordering-configuration',
        component: OnlineOrderingConfigurationsComponent,
        data: {
            group: 'online-ordering-configuration',
        }
    },
    {
        path: 'online-ordering-terms-conditions',
        component: OnlineOrderingTermsConditionsComponent,
        data: {
            permissions: [{ 'Name': Permissions.OnlineOrderingTermsAndConditions, 'Level': Levels.ReadOnly }],
        }
    },
    {
        path: 'inventory-schedule-editor',
        component: InventoryScheduleEditorComponent,
        data: {
            group: 'inventory-schedule-editor',
            permissions: [{ 'Name': Permissions.InventoryScheduleEditor, 'Level': Levels.ReadOnly }]
        }
    },
    {
        path: 'account-attribute-types',
        component: AccountAttributeTypesComponent,
        data: {
            group: 'account-attribute-type',
            permissions: [{ 'Name': Permissions.CustomersAccountAttributeTypes, 'Level': Levels.Access }]
        }
    },
    {
        path: 'account-attribute-types/:id',
        component: AccountAttributeTypeEditComponent,
        data: {
            group: 'account-attribute-type',
            permissions: [{ 'Name': Permissions.CustomersAccountAttributeTypes, 'Level': Levels.Edit }]
        }
    },
    {
        path: 'units',
        component: UnitsListComponent,
        data: {
            group: 'unit',
            permissions: [{ 'Name': Permissions.InventoryStorageUnits, 'Level': Levels.ReadOnly }]
        }
    },
    {
        path: 'sales-group',
        component: SalesGroupsComponent,
        data: {
            group: 'sales-group',
            permissions: [{ 'Name': Permissions.SalesGroups, 'Level': Levels.ReadOnly }]
        }
    },
    {
        path: 'sales-group/:id',
        component: SalesGroupEditWrapComponent,
        data: {
            group: 'sales-group',
            permissions: [{ 'Name': Permissions.SalesGroups, 'Level': Levels.Edit }]
        }
    },
    {
        path: 'sales-category',
        component: SalesCategoriesComponent,
        data: {
            group: 'sales-category',
            permissions: [{ 'Name': Permissions.SalesCategories, 'Level': Levels.ReadOnly }]
        }
    },
    {
        path: 'sales-category/:id',
        component: SalesCategoryEditWrapComponent,
        data: {
            group: 'sales-category',
            permissions: [{ 'Name': Permissions.SalesCategories, 'Level': Levels.Edit }],
        }
    },
    {
        path: 'sales-units',
        component: SalesUnitsListComponent,
        data: {
            group: 'sales-units',
            permissions: [{ 'Name': Permissions.SalesUnits, 'Level': Levels.ReadOnly }]
        }
    },
    {
        path: 'sales-units/:id',
        component: SalesUnitEditComponent,
        data: {
            group: 'sales-units',
            permissions: [{ 'Name': Permissions.SalesUnits, 'Level': Levels.Edit }],
        }
    },
    {
        path: 'tags',
        component: TagsListComponent,
        data: {
            group: 'tags',
            permissions: [{ 'Name': Permissions.SalesTags, 'Level': Levels.ReadOnly }],
        },
    },
    {
        path: 'tags/:id',
        component: TagsEditComponent,
        data: {
            group: 'tags',
            permissions: [{ 'Name': Permissions.SalesTags, 'Level': Levels.Edit }],
        },
    },
    {
        path: 'vendors',
        component: VendorsListComponent,
        data: {
            group: 'vendor',
            permissions: [{ 'Name': Permissions.InventoryVendors, 'Level': Levels.ReadOnly }]
        }
    },
    {
        path: 'vendors/:id',
        component: VendorEditComponent,
        data: {
            group: 'vendor',
            permissions: [{ 'Name': Permissions.InventoryVendors, 'Level': Levels.ReadOnly }]
        }
    },
    {
        path: 'vendors/:id/profiles',
        component: InventoryVendorProfilesComponent,
        data: {
            group: 'vendor-profile',
            permissions: [{ 'Name': Permissions.InventoryVendors, 'Level': Levels.ReadOnly }]
        }
    },
    {
        path: 'vendors/:id/profiles/:profileId',
        component: InventoryVendorProfileEditComponent,
        data: {
            group: 'vendor-profile-edit',
            permissions: [{ 'Name': Permissions.InventoryVendors, 'Level': Levels.ReadOnly }]
        }
    },
    {
        path: 'vendors/:id/profiles/:profileId/products',
        component: InventoryVendorProfileProductsComponent,
        data: {
            group: 'profile-products',
            permissions: [{ 'Name': Permissions.InventoryVendors, 'Level': Levels.ReadOnly }]
        }
    },
    {
        path: 'vendors/:id/profiles/:profileId/products/productId',
        component: InventoryVendorProfileProductEditComponent,
        data: {
            group: 'profile-products',
            permissions: [{ 'Name': Permissions.InventoryVendors, 'Level': Levels.ReadOnly }]
        }
    },
    {
        path: 'inventory-categories',
        component: InventoryCategoryListComponent,
        data: {
            group: 'inventory-category',
            permissions: [{ 'Name': Permissions.InventoryCategories, 'Level': Levels.ReadOnly }]
        }
    },
    {
        path: 'inventory-zones',
        component: InventoryZoneListComponent,
        data: {
            group: 'inventory-zone',
            permissions: [{ 'Name': Permissions.InventoryZones, 'Level': Levels.ReadOnly }]
        }
    },
    {
        path: 'inventory-zones/:id',
        component: InventoryZoneEditComponent,
        data: {
            group: 'inventory-zone',
            permissions: [{ 'Name': Permissions.InventoryZones, 'Level': Levels.Edit }],
        }
    },
    {
        path: 'inventory-subcategories',
        component: InventorySubcategoryListComponent,
        data: {
            group: 'inventory-subcategory',
            permissions: [{ 'Name': Permissions.InventorySubcategories, 'Level': Levels.ReadOnly }]
        }
    },
    {
        path: 'security-roles',
        component: SecurityRolesListComponent,
        data: {
            group: 'security-role',
            permissions: [{ 'Name': Permissions.ManageSecurityRoles, 'Level': Levels.ReadOnly }]
        }
    },
    {
        path: 'security-roles/:id',
        component: SecurityRoleEditComponent,
        data: {
            group: 'security-role',
            permissions: [{ 'Name': Permissions.ManageSecurityRoles, 'Level': Levels.Edit }]
        }
    },
    {
        path: 'buttons',
        component: ButtonListComponent,
        data: {
            group: 'button',
            permissions: [{ 'Name': Permissions.UIButtons, 'Level': Levels.ReadOnly }]
        }
    },
    {
        path: 'buttons/:id/button-history',
        component: ButtonHistoryComponent,
    },
    {
        path: 'buttons/:buttonId/button-history/:id',
        component: ButtonHistoryEditComponent,
    },
    {
        path: 'info-link-sections',
        component: InfoLinkSectionsComponent,
        data: {
            group: 'info-link-section',
            permissions: [{ 'Name': Permissions.ManageInfoLinkSections, 'Level': Levels.ReadOnly }]
        }
    },
    {
        path: 'info-links',
        component: InfoLinksComponent,
        data: {
            group: 'info-link',
            permissions: [{ 'Name': Permissions.ManageInfoLinks, 'Level': Levels.ReadOnly }]
        }
    },
    {
        path: 'users',
        component: UserListComponent,
        data: {
            group: 'user',
            permissions: [{ 'Name': Permissions.ManageEmployees, 'Level': Levels.ReadOnly }]
        }
    },
    {
        path: 'users/:id',
        component: UserEditComponent,
        data: {
            group: 'user',
            permissions: [{ 'Name': Permissions.ManageEmployees, 'Level': Levels.Edit }],
        }
    },
    {
        path: 'infractions',
        component: InfractionsListComponent,
        data: {
            group: 'infraction',
            permissions: [{ 'Name': Permissions.ManageInfractions, 'Level': Levels.ReadOnly }]
        }
    },
    {
        path: 'infractions/:id',
        component: InfractionEditComponent,
        data: {
            group: 'infraction',
            permissions: [{ 'Name': Permissions.ManageInfractions, 'Level': Levels.Edit }],
        }
    },
    {
        path: 'work-schedule',
        component: WorkScheduleListComponent,
        data: {
            group: 'work-schedule',
            permissions: [{ 'Name': Permissions.ManageWorkSchedule, 'Level': Levels.ReadOnly }]
        }
    },
    {
        path: 'work-schedule/:id',
        component: WorkScheduleEditComponent,
        data: {
            group: 'work-schedule',
            permissions: [{ 'Name': Permissions.ManageWorkSchedule, 'Level': Levels.Edit }],
        }
    },
    {
        path: 'login-messages',
        component: LoginMessagesComponent,
        data: {
            group: 'login-message',
            permissions: [{ 'Name': Permissions.ManageLoginMessages, 'Level': Levels.ReadOnly }]
        }
    },
    {
        path: 'login-messages/:id',
        component: LoginMessagesEditComponent,
        data: {
            group: 'login-message',
            permissions: [{ 'Name': Permissions.ManageLoginMessages, 'Level': Levels.Edit }],
        }
    },
    {
        path: 'work-schedule-tags',
        component: WorkScheduleTagListComponent,
        data: {
            group: 'work-schedule',
        }
    },
    {
        path: 'job-codes',
        component: JobCodeListComponent,
        data: {
            group: 'job-code',
            permissions: [{ 'Name': Permissions.OperationsJobCodes, 'Level': Levels.ReadOnly }]
        }
    },
    {
        path: 'job-codes/:id',
        component: JobCodeEditComponent,
        data: {
            group: 'job-code',
            permissions: [{ 'Name': Permissions.OperationsJobCodes, 'Level': Levels.Edit }],
        }
    },
    {
        path: 'cash-payouts',
        component: CashPayoutListComponent,
        data: {
            group: 'cash-payout',
            permissions: [{ 'Name': Permissions.OperationsCashPayouts, 'Level': Levels.ReadOnly }]
        }
    },
    {
        path: 'cash-payouts/:id',
        component: EditCashPayoutComponent,
        data: {
            group: 'cash-payout',
            permissions: [{ 'Name': Permissions.OperationsCashPayouts, 'Level': Levels.Edit }],
        }
    },
    {
        path: 'cash-drawer-entries',
        component: CashDrawerEntriesComponent,
        data: {
            group: 'cash-drawer-entry',
            permissions: [{ 'Name': Permissions.OperationsCashDrawerEntries, 'Level': Levels.ReadOnly }]
        }
    },
    {
        path: 'account-categories',
        component: AccountCategoryListComponent,
        data: {
            group: 'account-category',
            permissions: [{ 'Name': Permissions.CustomersAccountCategories, 'Level': Levels.ReadOnly }]
        }
    },
    {
        path: 'account-categories/:id',
        component: EditAccountCategoryComponent,
        data: {
            group: 'account-category',
            permissions: [{ 'Name': Permissions.CustomersAccountCategories, 'Level': Levels.Edit }],
        }
    },
    {
        path: 'accounts',
        component: AccountsListComponent,
        data: {
            group: 'account',
            permissions: [{ 'Name': Permissions.CustomersAccounts, 'Level': Levels.ReadOnly }]
        }
    },
    {
        path: 'accounts/:id',
        component: AccountEditWrapperComponent,
        data: {
            group: 'account',
            permissions: [{ 'Name': Permissions.CustomersAccounts, 'Level': Levels.Edit }],
        }
    },
    {
        path: 'equipment-types',
        component: EquipmentTypeListComponent,
        data: {
            group: 'equipment-type',
            permissions: [{ 'Name': Permissions.EquipmentsTypes, 'Level': Levels.ReadOnly }]
        }
    },
    {
        path: 'equipment-types/:id',
        component: EquipmentTypeEditComponent,
        data: {
            group: 'equipment-type',
            permissions: [{ 'Name': Permissions.EquipmentsTypes, 'Level': Levels.Edit }]
        }
    },
    {
        path: 'equipment-properties',
        component: EquipmentPropertyListComponent,
        data: {
            group: 'equipment-property',
            permissions: [{ 'Name': Permissions.EquipmentsProperties, 'Level': Levels.ReadOnly }]
        }
    },
    {
        path: 'equipments',
        component: EquipmentListComponent,
        data: {
            group: 'equipment',
            permissions: [{ 'Name': Permissions.EquipmentsEquipment, 'Level': Levels.ReadOnly }]
        }
    },
    {
        path: 'equipments/:id',
        component: EquipmentEditComponent,
        data: {
            group: 'equipment',
            permissions: [{ 'Name': Permissions.EquipmentsEquipment, 'Level': Levels.Edit }]
        }
    },
    {
        path: 'time-clock-entries',
        component: TimeClockEntriesListComponent,
        data: {
            group: 'time-clock-entry',
            permissions: [{ 'Name': Permissions.ManageTimeClockEntries, 'Level': Levels.ReadOnly }]
        }
    },
    {
        path: 'time-clock-entries/:id',
        component: TimeClockEntriesEditComponent,
        data: {
            group: 'time-clock-entry',
            permissions: [{ 'Name': Permissions.ManageTimeClockEntries, 'Level': Levels.Edit }],
        }
    },
    {
        path: 'screens',
        component: ScreenListComponent,
        data: {
            group: 'screen',
            permissions: [{ 'Name': Permissions.UIScreens, 'Level': Levels.ReadOnly }]
        }
    },
    {
        path: 'screens/:id/screen-history',
        component: ScreenHistoryComponent,
    },
    {
        path: 'screens/:screenId/screen-history/:id',
        component: ScreenHistoryEditComponent,
    },
    {
        path: 'multiple-product-groups',
        component: MultiProductGroupListComponent,
        data: {
            group: 'screen',
            permissions: [{ 'Name': Permissions.MultipleProductGroups, 'Level': Levels.ReadOnly }]
        }
    },
    {
        path: 'sales-products',
        component: SalesProductListComponent,
        data: {
            group: 'sales-product',
            permissions: [{ 'Name': Permissions.SalesProducts, 'Level': Levels.ReadOnly }]
        }
    },
    {
        path: 'sales-products/bulk-update',
        component: SalesProductsBulkUpdateComponent,
        data: {
            group: 'sales-product-bulk-update',
        }
    },
    {
        path: 'sales-products/:id/sales-product-history',
        component: SalesProductHistoryComponent,
        data: {
            group: 'sales-product-history'
        }
    },
    {
        path: 'sales-products/:id/sales-product-size-history',
        component: SalesProductSizeHistoryComponent,
        data: {
            group: 'sales-product-price-history'
        }
    },
    {
        path: 'sales-products/:salesProductId/sales-product-size-history/:id',
        component: SalesProductSizeHistoryEditComponent,
        data: {
            group: 'sales-product-history'
        }
    },
    {
        path: 'sales-products/:salesProductId/sales-product-history/:id',
        component: SalesProductHistoryEditComponent,
        data: {
            group: 'sales-product-price-history'
        }
    },
    {
        path: 'sales-products/:id',
        component: SalesProductEditWrapComponent,
        data: {
            group: 'sales-product',
            permissions: [{ 'Name': Permissions.SalesProducts, 'Level': Levels.Edit }]
        }
    },
    {
        path: 'inventory-products',
        component: InventoryProductListComponent,
        data: {
            group: 'inventory-product',
            permissions: [{ 'Name': Permissions.InventoryProducts, 'Level': Levels.ReadOnly }]
        }
    },
    {
        path: 'inventory-products/bulk-update',
        component: InventoryProductsBulkUpdateComponent,
        data: {
            group: 'inventory-product',
        }
    },
    {
        path: 'inventory-products/:inventoryProductId/product-history',
        component: InventoryProductHistoryListComponent,
        data: {
            group: 'inventory-product-history',
        }
    },
    {
        path: 'inventory-products/:inventoryProductId/product-history/:productHistoryId',
        component: InventoryProductHistoryEditComponent,
        data: {
            group: 'inventory-product-history',
        }
    },
    {
        path: 'inventory-products/:id',
        component: InventoryProductEditWrapComponent,
        data: {
            group: 'inventory-product',
            permissions: [{ 'Name': Permissions.InventoryProducts, 'Level': Levels.Edit }]
        }
    },
    {
        path: 'permissions',
        component: PermissionsListComponent,
        data: {
            group: 'permission',
            permissions: [{ 'Name': Permissions.Permissions, 'Level': Levels.ReadOnly }]
        }
    },
    {
        path: 'permissions/:id',
        component: PermissionsEditComponent,
        data: {
            group: 'permission',
            permissions: [{ 'Name': Permissions.Permissions, 'Level': Levels.Edit }]
        }
    },
    {
        path: 'off-site-locations',
        component: InventoryOffSiteLocationsComponent,
        data: {
            group: 'off-site-locations',
            permissions: [{ 'Name': Permissions.OffSiteInventory, 'Level': Levels.ReadOnly }]
        }
    },
    {
        path: 'off-site-locations/:id/inventory-settings',
        component: OffsiteInventoryProductSettingsComponent,
        data: {
            group: 'off-site-locations',
            permissions: [{ 'Name': Permissions.OffSiteInventory, 'Level': Levels.Edit }]
        }
    },
    {
        path: 'dietary-warnings',
        component: DietaryWarningListComponent,
        data: {
            group: 'dietary-warnings',
            Permission: [{ 'Name': Permissions.DietaryWarnings, 'Level': Levels.ReadOnly }]
        }
    },
    {
        path: 'dietary-warnings/:id',
        component: DietaryWarningEditComponent,
        data: {
            group: 'dietary-warnings',
            Permission: [{ 'Name': Permissions.DietaryWarnings, 'Level': Levels.Edit }]
        }
    }
];

forEach(DomainConstants.WaitTimeRuleTypes, ruleType => {
    INFORMATION_MANAGEMENT_CHILDREN.push(...[{
        path: ruleType.RoutLink,
        component: WaitTimeRulesComponent,
        data: {
            permissions: [{ 'Name': ruleType.Permission, 'Level': Levels.Access }]
        }
    }, {
        path: ruleType.RoutLink + '/:id',
        component: WaitTimeRuleEditComponent,
        data: {
            permissions: [{ 'Name': ruleType.Permission, 'Level': Levels.Edit }]
        }
    }]);
});


export const INFORMATION_MANAGEMENT_STATES: Array<Route> = [
    {
        path: '',
        component: InformationManagementBaseComponent,
        children: INFORMATION_MANAGEMENT_CHILDREN,
        data: {
            permissions: [{ 'Name': Permissions.ManageAppConfiguration, 'Level': Levels.Access }, { 'Name': Permissions.ManageConsole, 'Level': Levels.Access }]
        }
    }];
