import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { finalize, forkJoin, Observable } from 'rxjs';
import { LoyaltyFormAttribute } from 'src/app/account-registration/interfaces/loyalty-form-attribute';
import { LoyaltyFormAttributeService } from 'src/app/account-registration/services/loyalty-form-attribute.service';
import { AccountAttributeTypeService } from 'src/app/information-management/accounts/account-attribute-types/services/account-attribute-type.service';
import { SpinnerService, AccountAttributeTypes, BaseCrudModelComponent, Messages } from 'src/app/shared';
import { AlertsService, FormUtilityService } from 'src/app/shared/services';

@Component({
  selector: 'pos-loyalty-form-attribute-edit',
  templateUrl: './loyalty-form-attribute-edit.component.html',
  styleUrls: ['./loyalty-form-attribute-edit.component.scss']
})
export class LoyaltyFormAttributeEditComponent extends BaseCrudModelComponent<LoyaltyFormAttribute> implements OnInit {

  constructor(protected loyaltyFormAttributeService: LoyaltyFormAttributeService,
    protected alertService: AlertsService,
    protected spinnerService: SpinnerService,
    formUtilityService: FormUtilityService,
    private accountAttributeTypeService: AccountAttributeTypeService
  ) {
    super(loyaltyFormAttributeService, alertService, spinnerService, formUtilityService);
  }

  loyaltyAttributeId: number;
  loyaltyAttribute: LoyaltyFormAttribute;
  selectedLoyaltyAttributes: LoyaltyFormAttribute[];
  get getForm(): NgForm {
    return this.form
  }
  @ViewChild('loyaltyFormAttributeForm', {static: true}) form: NgForm;
  accountAttributeTypes: AccountAttributeTypes[];

  props = {
    labelClass: '',
    controlBoxClass: 'col-sm-9 col-xs-12'
  }

  ngOnInit(): void {
    this.loyaltyAttribute = this.loyaltyFormAttributeService.newLoyaltyFormAttribute();
    this.saveSuccessMessage = Messages.LoyaltyAttributeSaveSuccess;
  }

  saveLoyaltyFormAttribute(isValid: boolean) {
    if (!isValid) {
      return;
    }
    
    const selectedAttributes = this.loyaltyAttributeId 
    ? this.selectedLoyaltyAttributes.filter(x => x.Id !== this.loyaltyAttributeId) : this.selectedLoyaltyAttributes;

    const existingAttribute = selectedAttributes.find(
      x => x.AccountAttributeTypeId === this.loyaltyAttribute.AccountAttributeTypeId
    );

    if(existingAttribute){
      this.alertService.renderErrorMessage(`"${existingAttribute.AccountAttributeType.Name}" attribute already exists.`);
      return;
    }

    this.save(this.loyaltyAttribute);
  }

  onSaveSuccess(model: LoyaltyFormAttribute): void {
    this.hide({ shouldReload: true });
  }

  cancelEditing() {
    this.hide({ shouldReload: false });
  }

  loadDependencies(): void {
    const loyaltyAttributeObservable: Array<Observable<any>> = [];
    this.spinnerService.show();

    loyaltyAttributeObservable.push(this.accountAttributeTypeService.getAccountAttributeTypes());
    if (this.loyaltyAttributeId) {
      loyaltyAttributeObservable.push(this.loyaltyFormAttributeService.get(this.loyaltyAttributeId));
    }

    forkJoin(loyaltyAttributeObservable)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: ([accountAttributeTypes, loyaltyAttribute]:
          [Array<AccountAttributeTypes>, LoyaltyFormAttribute]) => {
          this.accountAttributeTypes = accountAttributeTypes;
          if (loyaltyAttribute) {
            this.loyaltyAttribute = loyaltyAttribute;
          }
        },
        error: this.alertService.showApiError
      });
  }

  isSystemDefinedAttribute(loyaltyAttribute: any): boolean {
    return loyaltyAttribute.IsSystemDefined && loyaltyAttribute.AccountAttributeTypeId === null;
  }
}
