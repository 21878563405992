import { DatePipe } from '@angular/common';
import { Inject, Pipe, PipeTransform } from '@angular/core';
import { TarkLocalProvider, LOCAL_PROVIDER } from '../service/tark-local-provider.service';
import { LoggerService } from 'src/app/shared/services';

@Pipe({
  name: 'tark_date'
})
export class TarkDatePipe implements PipeTransform {

  constructor(@Inject(LOCAL_PROVIDER) private tarkLocalProvider: TarkLocalProvider,
    private datePipe: DatePipe, private loggerService: LoggerService) {  }

  transform(value: number): string | null {
    try {
      return this.datePipe.transform(value, this.tarkLocalProvider.getDateFormat(), null, this.tarkLocalProvider.getLocale());
    }
    catch(err) {
      this.loggerService.logError(`Exception occurred in date pipe for value: ${value}, exception: ${err}`);
      return null;
    }
  }

}
