import { Component, Input, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { OrderKioskScreenService } from '../../../shared/services/order-kiosk-screen.service';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs';
import { KioskScreen, KioskScreenImage } from '../../interfaces/kiosk-screen';
import { AlertsService, DomainConstants, FormUtilityService, ModalFormComponent, ModalService, SpinnerService } from 'src/app/shared';
import { arrowsAlt, cog, fileSearchPreview, tabletAlt } from 'src/app/shared/components/icon'
import { KioskScreenConfigEditComponent } from '../kiosk-screen-config-edit/kiosk-screen-config-edit.component';
import { KioskNavigationService } from '../../services/kiosk-navigation.service';
import { OrderKioskPreviewComponent } from '../order-kiosk-preview/order-kiosk-preview.component';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { cloneDeep } from 'lodash';
import { RearrangeKioskButtonsComponent } from '../rearrange-kiosk-buttons/rearrange-kiosk-buttons.component';

@Component({
  selector: 'pos-kiosk-screen-config',
  templateUrl: './kiosk-screen-config.component.html',
  styleUrls: ['./kiosk-screen-config.component.scss']
})
export class KioskScreenConfigComponent extends ModalFormComponent {
  @ViewChild('kioskScreenForm') kioskScreenForm: NgForm;
  @Input() id = 0;
  @Input() isPopup = false;
  @Input() isDesignMode = true;
  kioskScreen: KioskScreen;
  kioskImages: Array<KioskScreenImage> = [];
  icons = {
    arrowsAlt, cog, fileSearchPreview, tabletAlt
  }
  templateTypes = DomainConstants.KioskScreenTemplateTypes;
  isShowRearrangeButton = false;

  constructor(private kioskScreenService: OrderKioskScreenService,
    private spinnerService: SpinnerService,
    private alertsService: AlertsService,
    private route: ActivatedRoute,
    public modalService: ModalService,
    public modalRef: BsModalRef,
    protected formUtilityService: FormUtilityService,
    private router: Router,
    private kioskNavigationService: KioskNavigationService
  ) {
    super(modalRef, modalService, formUtilityService);
    this.id = !this.id && route.snapshot.params.id ? parseInt(route.snapshot.params.id, 10) : this.id;
  }

  get getForm(): NgForm {
    return this.kioskScreenForm;
  }

  ngOnInit() {
    this.loadData();
    this.kioskNavigationService.reloadNavigationButtons.subscribe(() => {
      this.loadData();
    });
  }

  loadData() {
    this.kioskScreen = this.kioskScreenService.newScreen();
    this.spinnerService.show();
    this.kioskScreenService.get(this.id)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res: KioskScreen) => {
          this.kioskScreen = res;
          const templatesToShowRearrange = [this.templateTypes.FixedGridLayout, this.templateTypes.FluidGridLayout];
          this.isShowRearrangeButton = templatesToShowRearrange.includes(this.kioskScreen.TemplateType);
        }, error: this.alertsService.showApiError
      });
  }

  changeConfiguration() {
    const modalRef = this.modalService.show(KioskScreenConfigEditComponent, {
      backdrop: 'static',
      class: 'vertical-center modal-max-width-65',
      animated: false,
      keyboard: false,
      initialState: {
        kioskScreen: this.kioskScreen,
      }
    });
    modalRef.close.subscribe((res) => {
      if (res?.shouldReload) {
        this.loadData();
      }
    });
  }

  previewTerminal() {
    const screen = cloneDeep(this.kioskScreen);
    const modalRef = this.modalService.show(OrderKioskPreviewComponent, {
      backdrop: 'static',
      class: 'vertical-center modal-max-width-98',
      animated: false,
      keyboard: false,
      initialState: {
        kioskScreenId: cloneDeep(this.kioskScreen.Id),
      }
    });
    modalRef.close.subscribe((screenData) => {
      this.kioskScreen.Id = 0;
      const reloadTimeout = setTimeout(() => {
        this.kioskScreen = screenData;
        this.kioskNavigationService.refreshOrderNavigation();
        clearTimeout(reloadTimeout);
      });
    });
  }

  rearrangeButtons() {
    const modalRef = this.modalService.show(RearrangeKioskButtonsComponent, {
      backdrop: 'static',
      class: 'vertical-center modal-max-width-70',
      animated: false,
      keyboard: false,
      initialState: {
        kioskScreenId: this.kioskScreen.Id
      }
    });
    modalRef.close.subscribe((res) => {
      if (res?.isReload) {
        this.loadData();
      }
    });
  }

  cancel() {
    if (this.isPopup) {
      this.hide({ screenId: this.id });
    } else {
      this.router.navigate(['screens'], { relativeTo: this.route.parent });
    }
  }

}
