import { Messages } from 'src/app/shared/constants/ui-messages';
import { AlertsService, FormUtilityService } from 'src/app/shared/services';
import { NgForm } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Component, OnInit, ViewChild } from '@angular/core';
import { SpinnerService, ModalFormComponent, DomainConstants, ModalService, AccountAttributeTypes } from 'src/app/shared';
import * as _ from 'lodash';
import {
    AccountAttributeTypeService
} from 'src/app/information-management/accounts/account-attribute-types/services/account-attribute-type.service';

@Component({
    selector: 'pos-account-attribute-type-edit',
    templateUrl: './account-attribute-type-edit.component.html',
})
export class AccountAttributeTypeEditComponent extends ModalFormComponent implements OnInit {

    get getForm(): NgForm {
        return this.form
    }
                
    @ViewChild('accountAttributeTypeForm', {static: true}) form: NgForm;

    accountAttributeValueTypes = Object.keys(DomainConstants.AccountAttributeValueTypes).map(key => ({ type: key, value: DomainConstants.AccountAttributeValueTypes[key] }));
    accountAttributeValueTypesObject = DomainConstants.AccountAttributeValueTypes;

    accountAttributeTypeValue: any;
    attributeValues: any;
    accountAttributeType: AccountAttributeTypes;

    props = {
        labelClass: '',
        controlBoxClass: 'col-sm-8 col-xs-12'
    }
    constructor(accountAttributeModalRef: BsModalRef,
        private alertService: AlertsService,
        private spinnerService: SpinnerService,
        private accountAttributeTypeService: AccountAttributeTypeService,
        modalService: ModalService,
        formUtilityService: FormUtilityService
    ) {
        super(accountAttributeModalRef, modalService,formUtilityService);
    }

    ngOnInit() {
        this.getAccountAttributeType();
        this.attributeValues = this.accountAttributeType.QueryValue ? this.accountAttributeType.QueryValue.split(',') : [];
        this.setInitialValue();
    }

    getAccountAttributeType() {
        if (!this.accountAttributeType.Id) {
            this.accountAttributeTypeService.newAccountAttributeType();
        }
    }    

    saveAccountAttributeType(isValid) {
        if (!isValid) {
            return;
        }
        this.spinnerService.show();
        if (!(this.accountAttributeType.ValueType == this.accountAttributeValueTypesObject.Dropdown
            || this.accountAttributeType.ValueType == this.accountAttributeValueTypesObject.Radio)) {
            this.accountAttributeType.QueryValue = '';
        } else {
            this.attributeValues = _.filter(this.attributeValues, (value) => {
                return value.trim();
            });
            this.accountAttributeType.QueryValue = this.attributeValues.filter(Boolean).join();
        }
        if (this.accountAttributeType.Id > 0) {
            this.accountAttributeTypeService.updateAccountAttributeType(this.accountAttributeType).subscribe(
                () => {
                    this.alertService.renderSuccessMessage(Messages.AccountAttributeTypeUpdateSuccess);
                    this.onCancel(true);
                },
                this.alertService.showApiError,
                () => {
                    this.spinnerService.hide();
                }
            );
        } else {
            this.accountAttributeTypeService.addAccountAttributeType(this.accountAttributeType).subscribe(
                (response) => {
                    this.alertService.renderSuccessMessage(Messages.AccountAttributeTypeSaveSuccess);
                    this.onCancel(true, response.Id);
                },
                this.alertService.showApiError,
                () => {
                    this.spinnerService.hide();
                }
            );
        }
    }

    onCancel(reload: boolean, Id: number = 0) {
        this.hide({ shouldReload: reload, Id: Id });
    }
}
