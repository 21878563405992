import { FieldInfoMessages } from './field-info-messages';
import {
  stackMagic, stackRocket, stackTachometer, stackCircleGreenLight, stackHeartRate, stackTv, stackCircleDarkPurple, stackFasBurgerSoda,
  stackCircleGreen, stackCircleDeepSkyBlue, stackCircleMediumViolet, stackTh, stackCirclePortage, stackFasListAlt, stackCircleLightSalmon, stackCircleNotification, notificationStack, promotionGiftCard, piggyBank,
  browser, text, listUl, images, imageLight, boxesLight, cloneLight, burgerSoda, globe, objectGroup, bagsShopping, diceOneDuotone,
  orderKioskStack, stackCircleOrderKiosk, solidVideo,
  stackCircleWaitTimeTerminal as stackCircleWaitListTerminal,
  waitTimeTerminalStack as waitListTerminalStack
} from 'src/app/shared/components/icon';
import { ButtonFunctionType } from '../interface';
import { Permissions } from './permissions';
import { Levels } from './accessLevel';

export const DomainConstants = {
  apiConsumerId: 'https://pos.jonesshakes.com',
  httpHeaders: {
    ApplicationName: 'X-Application-Name',
    TerminalId: 'X-Terminal-Id',
    IsAnonymousRequest: 'X-Is-Anonymous-Request'
  },
  PermissionLevels: {
    NONE: { key: 'None', value: 0 },
    ACCESS: { key: 'Access', value: 1, displayName: 'Allow' },
    READONLY: { key: 'Read_Only', value: 2, displayName: 'Read-Only' },
    EDIT: { key: 'Edit', value: 3 },
    LEVEL: { key: 'Level', value: 4 }
  },
  PermissionTypes: {
    Access: 0,
    Level: 1
  },
  TerminalTypes: {
    ORDER_ENTRY: { Name: 'Order Entry', Permission: 'TRM_010001', Code: 'OrderEntry', Image: 'order-entry.png', Icon: null },
    BACK_OFFICE: { Name: 'Back Office', Permission: 'TRM_010002', Code: 'BackOffice', Image: 'back-office.png', Icon: null },
    TIME_ENTRY: { Name: 'Time Entry', Permission: 'TRM_010003', Code: 'TimeEntry', Image: 'time-entry.png', Icon: null },
    MAKE_TABLE: { Name: 'Make Table', Permission: 'TRM_010004', Code: 'MakeTable', Image: 'make-table.png', Icon: null },
    ORDER_CONFIRMATION_SCREEN: {
      Name: 'Order Confirmation Screen', Permission: 'TRM_010005', Code: 'OrderConfirmation',
      Image: 'order-confirmation.png',
      Icon: null
    },
    PICK_SCREEN: { Name: 'Pick Screen', Permission: 'TRM_010006', Code: 'PickScreen', Image: 'pick-screen.png', Icon: null },
    ORDER_STATUS: {
      Name: 'Order Status', Permission: 'TRM_010007', Code: 'OrderStatus',
      Image: null, Icon: [stackCircleGreenLight, stackTv, stackHeartRate]
    },
    TIMER: { Name: 'Timer', Permission: 'TRM_010008', Code: 'Timer', Image: 'timer.png', Icon: null },
    DIGITAL_SIGNAGE: {
      Name: 'Digital Signage', Permission: 'TRM_010009', Code: 'DigitalSignage', Image: 'digital-signage.png',
      Icon: null
    },
    DJ: { Name: 'DJ', Permission: 'TRM_010011', Code: 'Dj', Image: 'dj.png', Icon: null },
    INSTRUCTION_SCREEN: {
      Name: 'Instruction Screen', Permission: 'TRM_010010',
      Code: 'InstructionScreen', Image: '', Icon: [stackCircleGreen, stackMagic]
    },
    MANAGEMENT_DASHBOARD: {
      Name: 'Management Dashboard', Permission: 'TRM_010016', Code: 'ManagementDashboard', Image: '',
      Icon: [stackCircleMediumViolet, stackTachometer]
    },
    SERVE_SCREEN: {
      Name: 'Serve Screen', Permission: 'TRM_010012', Code: 'ServeScreen',
      Image: '', Icon: [stackCircleDeepSkyBlue, stackRocket]
    },
    PRODUCT_BUILD_QUEUE: {
      Name: 'Product Build Queue',
      Permission: 'TRM_010013', Code: 'ProductBuildQueue', Image: '', Icon: [stackCirclePortage, stackTh]
    },
    MENU_DISPLAY: {
      Name: 'Menu Display',
      Permission: 'TRM_010014', Code: 'MenuDisplay', Image: '', Icon: [stackCircleLightSalmon, stackFasListAlt]
    },
    MENU_EXPLORER: {
      Name: 'Menu Explorer',
      Permission: 'TRM_010015', Code: 'MenuExplorer', Image: '', Icon: [stackCircleDarkPurple, stackFasBurgerSoda]
    },
    NOTIFICATION: {
      Name: 'Notification',
      Permission: 'TRM_010017', Code: 'Menu Explorer', Image: '', Icon: [stackCircleNotification, notificationStack]
    },
    ORDER_KIOSK: {
      Name: 'Order Kiosk',
      Permission: 'TRM_010018', Code: 'OrderKiosk', Image: '', Icon: [stackCircleOrderKiosk, orderKioskStack]
    },
    WAITLIST: {
      Name: 'Waitlist',
      Permission: 'TRM_010019', Code: 'WaitList', Image: '', Icon: [stackCircleWaitListTerminal, waitListTerminalStack]
    },
  },

  ServeMethods: {
    Carryout: 1,
    DineIn: 2,
    Delivery: 3,
    DriveThru: 5,
    Online: 6
  },
  MakeTableStates: {
    PENDING: 'Pending',
    RETRIEVING_INGREDIENTS: 'RetrievingIngredients',
    COOKING: 'Cooking',
    CRAFTING: 'Crafting',
    READY: 'Ready',
    DONE: 'Done',
    PARTIALLY_IN_PROGRESS: 'PartiallyInProgress',
  },
  TimerStates: {
    QUEUED: 'Queued',
    COOKING: 'Cooking',
    DONE: 'Done'
  },
  AdjustmentTypes: {
    Discount: 'Discount',
    DeliveryFee: 'DeliveryFee',
    Markup: 'Markup',
    PagerNumber: 'PagerNumber'
  },
  AlertTypes: {
    SUCCESS: 'success',
    INFO: 'info',
    WARNING: 'warn',
    ERROR: 'danger'
  },
  ProductSizes: {
    SMALL_DEFAULT: {
      Text: 'Small/Default',
      SizeId: 1,
      Code: 'SM'
    },
    MEDIUM: {
      Text: 'Medium',
      SizeId: 2,
      Code: 'MED'
    },
    LARGE: {
      Text: 'Large',
      SizeId: 3,
      Code: 'LG'
    },
    BABY: {
      Text: 'Baby',
      SizeId: 4,
      Code: 'BABY'
    },
    SMALL: {
      Text: 'Small',
      SizeId: 1,
      Code: 'SM'
    }
  },
  ClockInOut: {
    CLOCK_IN: 'Clock In',
    CLOCK_OUT: 'Clock Out'
  },
  TerminalProperties: {
    ORDER_CONFIRMATION_SCROLL_SPEED: {
      Key: 'OrderConfirmationScrollSpeed',
      Text: 'Scroll Speed (1-99)'
    },
    ORDER_CONFIRMATION_IDLE_TIMEOUT: {
      Key: 'OrderConfirmationIdelTimeout',
      Text: 'Idle Timeout'
    },
    HOME_SCREEN_ID: {
      Key: 'HomeScreenId',
      Text: 'Home Screen Id'
    },
    CONTINUE_ORDER_SCREEN_ID: {
      Key: 'ContinueOrderScreenId',
      Text: 'Continue Order Screen Id'
    },
    TIMER_OPTIONS: {
      Key: 'TimerOptions',
      Text: 'Timer Options'
    },
    TIMER_SOUND: {
      Key: 'TimerSounds',
      Text: 'Timer Sound'
    },
    TIMER_NUMBER_OF_SLOT: {
      Key: 'NoofSlot',
      Text: 'Number of Slots'
    },
    TIMER_PREPGROUP: {
      Key: 'PrepGroup',
      Text: 'Prep Group'
    },
    TIMER_SLOT_TIMES: {
      Key: 'SlotTimes',
      Text: 'Slot Times'
    },
    MANUAL_ADJUSTMENT: {
      Key: 'ManualAdjustment',
      Text: 'ManualAdjustment'
    },
    DASHBOARD_ID: {
      Key: 'DashboardId',
      Text: 'Dashboard Id'
    },
    LAYOUT: {
      Key: 'Layout',
      Text: 'Layout'
    },
    ORDER_ENTRY_TERMINALS: {
      Key: 'OrderEntryTerminalIds',
      Text: 'Order Entry Terminal'
    },
    READ_ONLY: {
      Key: 'IsReadOnly',
      Text: 'Read Only'
    },
    DJ_SCHEME: {
      Key: 'DjScheme',
      Text: 'DJ Scheme'
    },
    INSTRUCTION_MAX_PRODUCTS: {
      Key: 'MaxProducts',
      Text: 'Max Products'
    },
    TABLE_LAYOUT: {
      Key: 'TableLayout',
      Text: 'TableLayout'
    },
    ORDER_PROMPT: {
      Key: 'OrderPrompts',
      Text: 'OrderPrompts'
    },
    FONT_SIZE: {
      Key: 'FontSize',
      Text: 'FontSize'
    },
    VOLUME: {
      Key: 'Volume',
      Text: 'Volume'
    },
    DjScheme: {
      Key: 'DjScheme',
      Text: 'DjScheme'
    },
    GRID_CONFIG: {
      Key: 'GridConfiguration',
      Text: 'GridConfiguration'
    },
    PRODUCT_BUILD_QUEUE_GRID: {
      Key: 'TerminalGrid',
      Text: 'TerminalGrid'
    },
    FILTERED_PRODUCTS: {
      Key: 'FilteredProducts',
      Text: 'Filter Products'
    },
    MENU_DISPLAY_ID: {
      Key: 'MenuDisplayId',
      Text: 'Menu Display'
    },
    IP_ADDRESS: {
      Key: 'IpAddress',
      Text: 'Ip Address'
    },
    IP_ADDRESS_LEVEL: {
      Key: 'IpAddressLevel',
      Text: 'Ip Address Level'
    },
    IDLE_TIMEOUT: {
      Key: 'IdleTimeout',
      Text: 'Idle Timeout'
    },
    TAG_AUDIO_TYPE: {
      Key: 'TagAudioRequestType',
      Text: 'Audio Request Type'
    },
    AUDIO_INTERACTION_MODE: {
      Key: 'AudioInteractionMode',
      Text: 'Audio Interaction Mode'
    },
    MONITOR_FOR_CONNECTION: {
      Key: 'MonitorForConnection',
      Text: 'Monitor For Connection'
    },
    SCREEN_WIDTH: {
      Key: 'ScreenWidth',
      Text: 'Screen Width'
    },
    SCREEN_HEIGHT: {
      Key: 'ScreenHeight',
      Text: 'Screen Height'
    },
    FONT_FAMILY: {
      Key: 'FontFamily',
      Text: 'Font Family'
    },
    USER_IDLE_TIMEOUT: {
      Key: 'UserIdleTimeout',
      Text: 'User Idle Timeout'
    },
    IS_ENABLED: {
      Key: 'IsEnabled',
      Text: 'Enabled'
    },
    MAX_ENTRIES: {
      Key: 'MaxEntries',
      Text: 'Max Entries',
    },
    MAX_WAIT_TIME: {
      Key: 'MaxWaitTime',
      Text: 'Max Wait Time',
    },
    NOTIFICATION_TEXT: {
      Key: 'NotificationText',
      Text: 'Notification Text',
    },
  },
  TimerTerminalOptions: {
    ALL: 'All',
    TIMER_ONLY: 'Timer Only',
    WORKFLOW_TIMERS: 'Workflow Timers',
    PRECONFIGURED_TIMERS: 'Preconfigured Timers'
  },
  TagAudioRequestTypes: [
    { Key: 'Context', Value: 'context' },
    { Key: 'Tag', Value: 'tag' },
  ],
  AudioInteractionModes: [
    { Key: 'Access Existing Order', Value: 'accessExistingOrder' },
    { Key: 'Customer Interaction', Value: 'customerInteraction' },
    { Key: 'New Order', Value: 'newOrder' },
  ],
  AudioInteractions: {
    AccessExistingOrder: 'accessExistingOrder',
    CustomerInteraction: 'customerInteraction',
    NewOrder: 'newOrder',
  },
  AutoDecrementBy: {
    NONE: 'None',
    DAY: 'Day',
    ORDER: 'Order'
  },
  PaymentModes: {
    CASH: 'Cash',
    CREDITCARD: 'Credit Card',
    GIFTCARD: 'Gift Card',
    EBT: 'EBT'
  },
  LoyalAccountTermPrefix: {
    Link: 'Link',
    Unlink: 'Unlink'
  },
  HardwareTypes: {
    CashDrawer: 'CashDrawer',
    Camera: 'Camera',
    CreditCardTerminal: 'CreditCardTerminal',
    ReceiptPrinter: 'ReceiptPrinter',
    KitchenPrinter: 'KitchenPrinter',
    LabelPrinter: 'LabelPrinter',
    NetworkedDevice: 'NetworkedDevice',
    LightingController: 'LightingController',
    AudioRecorder: 'AudioRecorder'
  },
  DashboardDataSourceParameterName: {
    StoredProcedure: 'sproc',
    Method: 'Method',
    Url: 'Url'
  },
  ApiMethods: {
    POST: 'POST',
    PUT: 'PUT',
    DELETE: 'DELETE',
    GET: 'GET',
  },
  DashboardDataSourceOutputType: {
    TabularValue: 'DashboardWidgets/GetTabularValue',
    ScalarValue: 'DashboardWidgets/GetScalarValue'
  },
  KitchenPrinterSplitByConfig: {
    PrintWholeOrder: 'PrintWholeOrder',
    SplitBySubaccount: 'SplitBySubaccount',
    SplitByProduct: 'SplitByProduct'
  },
  Page: {
    MaxSize: 10
  },
  LocalStorageKeys: {
    TerminalId: 'TerminalId',
    AuthToken: 'AuthToken',
    TerminalName: 'TerminalName'
  },
  ImageDirectories: {
    slideImages: 'SlideImages'
  },
  DjDirectories: {
    dj: '/DJ/tracks',
    speakText: '/DJ/speakTextFiles'
  },
  QuantityChange: {
    PLUS_MINUS_BUTTONS: '+/- Buttons',
    HASH_BUTTON: '# Button'
  },
  RequireNameOnOrder: {
    NEVER: 'Never',
    BEFORE_ORDER: 'Before Order',
    AT_RING_UP: 'At Ring Up'
  },
  UseParallelPickScreen: [
    { value: true, name: 'Yes' },
    { value: false, name: 'No' }
  ],
  OutputTypes: [
    { Value: 'Currency', Name: 'Currency' },
    { Value: 'Int', Name: 'Int' },
    { Value: 'List', Name: 'List' },
    { Value: 'Time', Name: 'Time' }
  ],
  SourceTypes: [
    { Value: 'SQL', Name: 'Stored Procedure' },
  ],
  ExportFormats: [{ Name: 'CSV' }, { Name: 'JSON' }, { Name: 'IIF' }],
  DashboardWidgetSelectOptions: {
    DayRange: [
      { value: 'D', label: 'Today' },
      { value: 'W', label: 'This Week' },
      { value: 'M', label: 'This Month' },
      { value: 'Y', label: 'This Year' }
    ],
    PeriodRange: [
      { value: 'D', label: 'Daily' },
      { value: 'W', label: 'Weekly' },
      { value: 'M', label: 'Monthly' },
      { value: 'Y', label: 'Yearly' }
    ],
    ChartTypes: [
      { value: 'column', label: 'Bar Chart' },
      { value: 'line', label: 'Line Chart' },
      { value: 'area', label: 'Area Chart' },
      { value: 'pie', label: 'Pie Chart' }
    ],
    GaugeTypes: [{ value: 'solidgauge', label: 'Radial Gauge' }],
    Operators: [
      { value: '<', label: '<' },
      { value: '>', label: '>' },
      { value: '<=', label: '<=' },
      { value: '>=', label: '>=' },
      { value: '==', label: '=' },
      { value: '!=', label: '!=' }
    ]
  },
  Comparator: [
    { Text: 'Less than', Value: '<' },
    { Text: 'Greater than', Value: '>' },
    { Text: 'Less than or equal to', Value: '<=' },
    { Text: 'Greater than or equal to', Value: '>=' },
    { Text: 'Is same as', Value: '=' },
  ],
  DashboardDataType: [
    { Text: 'Integer', Value: 'Int' },
  ],
  Align: [
    { Text: 'Center', Value: 'Center' },
    { Text: 'Left', Value: 'Left' },
    { Text: 'Right', Value: 'Right' }
  ],
  OrderNavigationFilterActions: { DISABLE: 'Disable', HIDE: 'Hide' },
  queryType: {
    Manual: 'Manual',
    Query: 'Query'
  },
  WeekDay: {
    Sunday: '0',
    Monday: '1',
    Tuesday: '2',
    Wednesday: '3',
    Thursday: '4',
    Friday: '5',
    Saturday: '6'
  },
  MaketableView: {
    Grid: 'Grid',
    BestFit: 'Best Fit'
  },
  CLICommandTypes: {
    LoadOrder: 'LoadOrder',
    AddItemToOrder: 'Add',
    DeleteOrder: 'Delete',
    RemoveItemFromOrder: 'Remove',
    New: 'New',
    Clear: 'Clear',
    QtyChange: 'Qty',
    ModifyProduct: 'Modify',
    UpdateProduct: 'Update',
    View: 'View',
    RingUp: 'RingUp',
    List: 'List',
    Alias: 'Alias',
    NewOrder: 'NewOrder'
  },
  CLIResponseCode: {
    Success: 'Success',
    Warning: 'Warning',
    ParseError: 'ParseError',
    ExecutionError: 'ExecutionError',
    ValidationError: 'ValidationError',
    Info: 'Info'
  },
  ProductSizeList: [
    { Name: 'Small/Default', SizeId: 1 },
    { Name: 'Medium', SizeId: 2 },
    { Name: 'Large', SizeId: 3 },
    { Name: 'Baby', SizeId: 4 }
  ],
  ButtonSize: {
    Tiny: 'Tiny',
    Small: 'Small',
    Normal: 'Normal',
    Large: 'Large'
  },
  ScreenButtonLayouts: {
    Fluid: 'Fluid',
    Fixed: 'Fixed'
  },
  TipType: {
    Percent: 'Percent',
    Manual: 'Manual'
  },
  ButtonConfigurationActions: {
    EditButton: 'EditButton',
    DuplicateButton: 'DuplicaateButton'
  },
  ButtonTypes: {
    NavigationOnly: 'NavigationOnly',
    MainProduct: 'MainProduct',
    DetailEntry: 'DetailEntry',
    Functional: 'Functional'
  },
  KioskChoiceType: {
    Standard: 'Standard',
    Toggle: 'Toggle',
    Placeholder: 'Placeholder'
  },
  PromptServeMethods: [
    { Name: 'Carryout', id: '1', isSelected: false },
    { Name: 'Dine in', id: '2', isSelected: false },
    { Name: 'Delivery', id: '3', isSelected: false },
    { Name: 'Drive Thru', id: '5', isSelected: false },
    { Name: 'Online', id: '6', isSelected: false }
  ],
  AccountTypes: {
    Account: 'Account',
    Table: 'Table',
    Tab: 'Tab',
    LoyaltyCard: 'Loyalty Card'
  },
  AccountOptions: {
    Required: 'Required',
    Optional: 'Optional',
    NotRequired: 'Not Required'
  },
  MarkupDiscountConfigurationType: {
    Auto: 'Auto',
    Manual: 'Manual',
    Taxable: 'Taxable',
    NonTaxable: 'NonTaxable'
  },
  ButtonFunctionTypes: {
    ADD_COMMENT: {
      Code: 'AddComment',
      Name: 'Add Comment'
    } as ButtonFunctionType,
    ADD_COMMENT_TO_ALL: {
      Code: 'AddCommentToAll',
      Name: 'Add Comment to All',
      IsSingularFeeApplicable: false
    } as ButtonFunctionType,
    ADD_COMMENT_UNRESTRICTED: {
      Code: 'ADD_COMMENT_UNRESTRICTED',
      Name: 'Add Comment (Unrestricted)'
    } as ButtonFunctionType,
    ASSIGN_PAGER: {
      Code: 'AssignPager',
      Name: 'Assign Pager'
    } as ButtonFunctionType,
    CALL_API: {
      Code: 'CallAPI',
      Name: 'Call API',
      IsSingularFeeApplicable: false
    } as ButtonFunctionType,
    COMMENT_ENTRY: {
      Code: 'CommentEntry',
      Name: 'Comment Entry'
    } as ButtonFunctionType,
    CUSTOMER_NAME: {
      Code: 'CustomerName',
      Name: 'Customer Name'
    } as ButtonFunctionType,
    END_MULTIPLE_PRODUCTS: {
      Code: 'EndMultipleProducts',
      Name: 'End Multiple Products',
      IsSingularFeeApplicable: false
    } as ButtonFunctionType,
    GRATUITY_MARKUP_AUTO: {
      Code: 'GratuityMarkupAuto',
      Name: 'Gratuity Markup (Auto)',
      IsSingularFeeApplicable: false
    } as ButtonFunctionType,
    MULTIPLE_PRODUCTS: {
      Code: 'MultipleProducts',
      Name: 'Multiple Products',
      IsSingularFeeApplicable: false
    } as ButtonFunctionType,
    NON_FUNCTIONAL: {
      Code: 'NonFunctional',
      Name: 'Non Functional',
      IsSingularFeeApplicable: false
    } as ButtonFunctionType,
    NUMERIC_MARKUP: {
      Code: 'NumericMarkup',
      Name: 'Numeric Markup',
      Taxable: 'NumericMarkupTaxable',
      NumericMarkupType: 'NumericMarkupType',
      AllowOnce: 'AllowOnce',
      NumericMarkupAmount: 'NumericMarkupAmount'
    },
    NUMERIC_DISCOUNT_MANUAL: {
      Code: 'NumericDiscountManual',
      Name: 'Numeric Discount (Manual)'
    } as ButtonFunctionType,
    PERCENT_DISCOUNT: {
      Code: 'PercentDiscount',
      Name: 'Percent Discount'
    } as ButtonFunctionType,
    PERCENT_MARKUP: {
      Code: 'PercentMarkup',
      Name: 'Percent Markup'
    } as ButtonFunctionType,
    PLACEHOLDER: {
      Code: 'Placeholder',
      Name: 'Placeholder',
      IsSingularFeeApplicable: false
    } as ButtonFunctionType,
    PRICE_SET: {
      Code: 'PriceSet',
      Name: 'Price Set',
      IsSingularFeeApplicable: false
    } as ButtonFunctionType,
    REMOVE_TAX: {
      Code: 'RemoveTax',
      Name: 'Remove Tax'
    } as ButtonFunctionType,
    SET_DIETARY_RESTRICTION: {
      Code: 'SetDietaryRestriction',
      Name: 'Set Dietary Restriction'
    } as ButtonFunctionType,
    SET_PHONE_NUMBER: {
      Code: 'SetPhoneNumber',
      Name: 'Set Phone Number'
    } as ButtonFunctionType,
    SET_SIZE: {
      Code: 'SetSize',
      Name: 'Set Size'
    } as ButtonFunctionType,
    TIME_ENTRY: {
      Code: 'TimeEntry',
      Name: 'Time Entry'
    } as ButtonFunctionType,
    DEDUCT_INVENTORY_PRODUCT: {
      Code: 'DeductInventoryProduct',
      Name: 'Deduct Inventory Product',
      InventorySubcategories: 'InventorySubcategories',
      MinPrice: 'MinPrice',
      MarginPercent: 'MarginPercent',
      DoNotAllowOverridingQty: 'DoNotAllowOverridingQty',
    }
  },
  ApiVerbs: [
    { Name: 'GET', id: 1 },
    { Name: 'POST', id: 2 },
    { Name: 'PUT', id: 3 },
    { Name: 'DELETE', id: 4 }
  ],
  ParameterTypes: [
    { Name: 'Body', id: 1 },
    { Name: 'Header', id: 2 },
    { Name: 'QueryString', id: 3 }
  ],
  AuthorizationTypes: [
    { Name: 'No Authentication', id: 0 },
    { Name: 'Username/Password', id: 1 },
    { Name: 'Token', id: 2 }
  ],

  OrderClosurePrintAction: {
    DO_NOT_PRINT: 'Do not print',
    PROMPT_USER: 'Prompt user',
    PRINT_ALWAYS: 'Print always'
  },

  PrintCheckOrderStatus: {
    ALL: 'All',
    PAID: 'Paid',
    UNPAID: 'Unpaid'
  },

  SystemPermissionRoles:
  {
    Administrator: 'Administrator',
    Manager: 'Manager'
  },

  ExportScriptType: {
    Query: 'Query',
    Menu: 'Menu'
  },
  ServiceMode: {
    DineIn: 1,
    Carryout: 2
  },
  InventoryProductRawInfo: {
    Ingredients: 'Ingredients',
    Servings: 'Servings',
    Nutrition: 'Nutrition',
    DietaryWarnings: 'Dietary Warnings'
  },
  TenderTypes: {
    CASH: {
      Code: 'Cash',
      Name: 'Cash'
    },
    CHECK: {
      Code: 'Check',
      Name: 'Check'
    },
    CREDIT_CARD: {
      Code: 'CreditCard',
      Name: 'Credit Card'
    },
    DEBIT_CARD: {
      Code: 'DebitCard',
      Name: 'Debit Card'
    },
    GIFT_CERTIFICATE: {
      Code: 'GiftCertificate',
      Name: 'Gift Certificate'
    },
    GIFT_CARD: {
      Code: 'GiftCard',
      Name: 'Gift Card'
    },
    HOUSE_ACCOUNT: {
      Code: 'HouseAccount',
      Name: 'House Account'
    },
    DISCOUNT: {
      Code: 'Discount',
      Name: 'Discount'
    },
    EBT: {
      Code: 'EBT',
      Name: 'EBT'
    },
    GRATIS: {
      Code: 'Gratis',
      Name: 'Gratis'
    }
  },
  FunctionButtons: [
    { Name: 'None', Selection: 'None' },
    { Name: 'Account', Selection: 'Account' },
    { Name: 'Add Product', Selection: 'AddProduct' },
    { Name: 'Dispatch', Selection: 'Dispatch' },
    { Name: 'Filter', Selection: 'Filter' },
    { Name: 'Info', Selection: 'Info' },
    { Name: 'Loyal Account Term', Selection: 'LoyalAccountTerm' },
    { Name: 'Messages', Selection: 'Messages' },
    { Name: 'Order Kiosk', Selection: 'OrderKiosk' },
    { Name: 'Print Check', Selection: 'PrintCheck' },
    { Name: 'Product Print', Selection: 'ProductPrint' },
    { Name: 'Require Name', Selection: 'RequireName' },
    { Name: 'Schedule Order', Selection: 'ScheduledOrder' },
    { Name: 'Serve Order', Selection: 'ServeOrder' },
    { Name: 'Table', Selection: 'Table' },
    { Name: 'To Go', Selection: 'ToGo' },
  ],
  InventoryScheduleOptions: {
    ByDate: 'Date',
    BySubcategory: 'Subcategory',
    ByVendor: 'Vendor',
    ByZone: 'Zone'
  },
  InventoryScheduleDateOptions: {
    NextInventoryDate: 'LogDueDateOverride',
    BaseLineDate: 'InventoryBaselineDate'
  },
  InventoryLogPopulateQuantityUsing: [
    { Name: 'Last recorded quantity' },
    { Name: 'Currently calculated quantity' }
  ],
  TenderType: [
    { Name: 'Cash', Code: 'Cash', FieldInfo: FieldInfoMessages.CashDrawerCash },
    { Name: 'Check', Code: 'Check', FieldInfo: FieldInfoMessages.CashDrawerCheck },
    { Name: 'Credit Card', Code: 'CreditCard', FieldInfo: FieldInfoMessages.CashDrawerCreditCard },
    { Name: 'Debit Card', Code: 'DebitCard', FieldInfo: FieldInfoMessages.CashDrawerDebitCard },
    { Name: 'Gift Certificate', Code: 'GiftCertificate', FieldInfo: FieldInfoMessages.CashDrawerGiftCardCertificate },
    { Name: 'Gift Card', Code: 'GiftCard', FieldInfo: FieldInfoMessages.CashDrawerGiftCard },
    { Name: 'House Account', Code: 'HouseAccount', FieldInfo: FieldInfoMessages.CashDrawerHouseAccount },
    { Name: 'Discount', Code: 'Discount', FieldInfo: FieldInfoMessages.CashDrawerDiscount }
  ],
  AccountAttributeValueTypes: {
    Date: 'Date',
    Dropdown: 'Dropdown',
    Paragraph: 'Paragraph',
    Number: 'Number',
    Radio: 'Radio',
    Text: 'Text'
  },
  GridColumnsTypes: {
    EditColumn: 'EditColumn',
    DeleteColumn: 'DeleteColumn'
  },
  OrderItemTypes: {
    Product: 'Product',
    Comment: 'Comment',
    Discount: 'Discount',
    Markup: 'Markup',
    Name: 'Name',
    RemoveTax: 'RemoveTax',
    SingularFee: 'SingularFee',
    Promotion: 'Promotion'
  },
  ButtonMenu: {
    ButtonEdit: 'ButtonEdit',
    DuplicateButtonOnScreen: 'DuplicateButtonOnScreen',
    FindScreenContainingThisButton: 'FindScreenContainingThisButton',
    RemoveButtonFromScreen: 'RemoveButtonFromScreen',
    ChooseExistingNextScreen: 'ChooseExistingNextScreen',
    SetProduct: 'SetProduct',
    RemoveProductFromButton: 'RemoveProductFromButton',
    CopyToNewButton: 'CopyToNewButton',
    RemoveEmbeddedScreen: 'RemoveEmbeddedScreen',
    EmbeddedScreenHeading: 'EmbeddedScreenHeading'
  },
  LOG_LEVEL: {
    'Error': 0,
    'Warn': 1,
    'Info': 2,
    'Log': 3,
    'Debug': 4
  },
  SpecialFunctionBusinessCommand: {
    AssignPager: 'AssignPager',
    CallAPI: 'CallAPI',
    AddComment: 'AddComment',
    AddCommentToAll: 'AddCommentToAll',
    SetName: 'SetName',
    SetMarkup: 'SetMarkup',
    SetDiscount: 'SetDiscount',
    SetTime: 'SetTime',
    SetPhoneNumber: 'SetPhoneNumber',
    DietaryRestriction: 'DietaryRestriction',
    RemoveTax: 'RemoveTax',
    SetPickupTime: 'SetPickupTime',
  },
  CreditCardType: {
    Peripheral: 'Peripheral',
    Integrated: 'Integrated'
  },
  EBTTypes: {
    Peripheral: 'Peripheral',
    Integrated: 'Integrated'
  },
  HICResponseStatus: {
    Connected: 'Connected',
    Busy: 'Busy',
    Error: 'Error'
  },
  PaymentTypes: {
    CASH: 1,
    CREDITCARD: 2,
    GIFTCARD: 3,
    EBT: 11,
    ONLINEORDER: 8
  },
  GiftCardTransactionTypes: {
    GiftCardRecharge: 'Add to Balance',
    Payment: 'Payment',
    GiftCardSettlement: 'Settle',
  },
  OnlineOrderPaymentTypes: [
    { Name: 'Paypal', Value: 'Paypal' },
    { Name: 'Authorize.Net', Value: 'Authorize.Net' }
  ],
  OnlineOrderPaymentModes: [
    { Name: 'Authorize Only', Value: 'AuthorizeOnly' },
    { Name: 'Authorize With Capture', Value: 'AuthorizeWithCapture' }
  ],
  ReportDaysOfTheWeek: [
    { Value: 'Mon', Text: 'Monday' },
    { Value: 'Tue', Text: 'Tuesday' },
    { Value: 'Wed', Text: 'Wednesday' },
    { Value: 'Thu', Text: 'Thursday' },
    { Value: 'Fri', Text: 'Friday' },
    { Value: 'Sat', Text: 'Saturday' },
    { Value: 'Sun', Text: 'Sunday' },
    { Value: 'Daily', Text: 'Daily' }
  ],
  ToasterPositions: [
    { Name: 'Top Right', Value: 'toast-top-right' },
    { Name: 'Top Left', Value: 'toast-top-left' },
    { Name: 'Bottom Right', Value: 'toast-bottom-right' },
    { Name: 'Bottom Left', Value: 'toast-bottom-left' },
  ],
  WhitelistedAddressTypes: [
    { Name: 'Single IP Address', Value: '0' },
    { Name: 'IP Address Range', Value: '1' }
  ],
  MakeTableLayout: [
    { Name: 'Grid', Value: 'Grid' },
    { Name: 'Best Fit', Value: 'Best Fit' }
  ],
  DefaultTerms: [
    { Name: 'Campground', Value: 'Campground' },
    { Name: 'Restaurant', Value: 'Restaurant' },
    { Name: 'Retail', Value: 'Retail' }
  ],
  ServiceModels: [
    { Name: 'Dine In' },
    { Name: 'Carryout' }
  ],
  NewInventoryRecordOnDuplicateAmount: [
    { Name: 'Record new entry', Value: true },
    { Name: 'Update date of last entry', Value: false }
  ],
  AccountSwitches: [
    { Name: 'Allow' },
    { Name: 'Prompt' },
    { Name: 'Prohibit' }
  ],
  CreditCardTypes: [
    { Name: 'Integrated' },
    { Name: 'Browser' },
    { Name: 'Peripheral' }
  ],
  TaxRoundingMode: [
    { Name: 'Round Up Always', Value: 'RoundUp' },
    { Name: 'Round Half-Up', Value: 'Round' }
  ],
  TaxType: [
    { Name: 'Pre Tax', Value: 'PreTax' },
    { Name: 'Post Tax', Value: 'PostTax' }
  ],
  DrawerCheckPointPrice: {
    Cent1: { Name: '1 ¢', Value: 0, CurrencyInDollar: 0.01 },
    Cent5: { Name: '5 ¢', Value: 0, CurrencyInDollar: 0.05 },
    Cent10: { Name: '10 ¢', Value: 0, CurrencyInDollar: 0.10 },
    Cent25: { Name: '25 ¢', Value: 0, CurrencyInDollar: 0.25 },
    Cent50: { Name: '50 ¢', Value: 0, CurrencyInDollar: 0.50 },
    Dollar1: { Name: '$1', Value: 0, CurrencyInDollar: 1 },
    Dollar5: { Name: '$5', Value: 0, CurrencyInDollar: 5 },
    Dollar10: { Name: '$10', Value: 0, CurrencyInDollar: 10 },
    Dollar20: { Name: '$20', Value: 0, CurrencyInDollar: 20 },
    Dollar50: { Name: '$50', Value: 0, CurrencyInDollar: 50 },
    Dollar100: { Name: '$100', Value: 0, CurrencyInDollar: 100 },
  },
  ProductType: {
    InventoryProduct: 'Inventory Product',
    SalesProduct: 'Sales Product'
  },
  EventTypes: {
    'TaskSkippedEvent': 'TaskSkippedEvent',
    'TaskHelpRequestedEvent': 'TaskHelpRequestedEvent',
    'EndOfDayEvent': 'EndOfDayEvent',
    'GlobalLogoffEvent': 'GlobalLogoffEvent',
    'GlobalShutoffStateChangeEvent': 'GlobalShutoffStateChangeEvent',
    'AudioNotificationFailedEvent': 'AudioNotificationFailedEvent',
    'CashDrawerClosedEvent': 'CashDrawerClosedEvent',
    'CashDrawerClosureAmountDiscrepancyEvent': 'CashDrawerClosureAmountDiscrepancyEvent',
    'CashDrawerOpenedEvent': 'CashDrawerOpenedEvent',
    'InventoryQuantityChangedEvent': 'InventoryQuantityChangedEvent',
    'SalesProductOutOfStockEvent': 'SalesProductOutOfStockEvent',
    'SalesProductReplenishedEvent': 'SalesProductReplenishedEvent',
    'InventoryQtyMinimumReachedEvent': 'InventoryQtyMinimumReachedEvent',
    'InventoryLoggedEvent': 'InventoryLoggedEvent',
    'UnexpectedInventoryLoggedEvent': 'UnexpectedInventoryLoggedEvent',
    'InventoryExhaustionEvent': 'InventoryExhaustionEvent',
    'InventoryProductReplenishedEvent': 'InventoryProductReplenishedEvent',
    'ActiveOrderCountChangedEvent': 'ActiveOrderCountChangedEvent',
    'OrderStatusChangedEvent': 'OrderStatusChangedEvent',
    'ButtonPressedEvent': 'ButtonPressedEvent',
    'PriceOverriddenEvent': 'PriceOverriddenEvent',
    'ProductOrderedEvent': 'ProductOrderedEvent',
    'GiftCardPaymentEvent': 'GiftCardPaymentEvent',
    'NewOnlineOrderEvent': 'NewOnlineOrderEvent',
    'FirstClockInEvent': 'FirstClockInEvent',
    'LastClockOutEvent': 'LastClockOutEvent',
    'UserClockInEvent': 'UserClockInEvent',
    'UserClockOutEvent': 'UserClockOutEvent',
    'UserClockedInOverXHoursEvent': 'UserClockedInOverXHoursEvent',
    'NoOfActiveOrderExceedsRecoverEvent': 'NoOfActiveOrderExceedsRecoverEvent',
    'NoOfActiveOrderFallsEvent': 'NoOfActiveOrderFallsEvent',
    'CreditCardTerminalConnectedEvent': 'CreditCardTerminalConnectedEvent',
    'CreditCardTerminalDisconnectedEvent': 'CreditCardTerminalDisconnectedEvent',
    'UnservedOrderMinutesEvent': 'UnservedOrderMinutesEvent',
    'MissingRecipeEvent': 'MissingRecipeEvent',
    'ExternalWarningEvent': 'ExternalWarningEvent',
    'SyncFailureEvent': 'SyncFailureEvent',
    'EquipmentTemperatureExceptionEvent': 'EquipmentTemperatureExceptionEvent',
    'HardwareConnectedEvent': 'HardwareConnectedEvent',
    'InventoryQtyCriticalLevelReachedEvent': 'InventoryQtyCriticalLevelReachedEvent',
    'InventoryPrepNeededEvent': 'InventoryPrepNeededEvent',
    'PrinterErrorEvent': 'PrinterErrorEvent',
    'UserClockedInOverXHourFromXPeriodEvent': 'UserClockedInOverXHourFromXPeriodEvent',
    'OnlineOrderWaitTimeChangedEvent': 'OnlineOrderWaitTimeChangedEvent',
    'TerminalConnectedEvent': 'TerminalConnectedEvent'
  },
  EventDeliveryChannels: {
    'Email': {
      'type': 'Email',
      'description': 'Email',
      'icon': 'fas fa-envelope'
    },
    'Task': {
      'type': 'Task',
      'description': 'Task',
      'icon': 'fas fa-check-double'
    },
    'TerminalMessage': {
      'type': 'Terminal Message',
      'description': 'Terminal Message',
      'icon': 'fas fa-tv'
    },
    'ExternalAPIDelivery': {
      'type': 'External API',
      'description': 'Call API',
      'icon': 'fas fa-plug'
    },
    'AudioNotification': {
      'type': 'Audio Notification',
      'description': 'Audio Notification',
      'icon': 'fas fa-bullhorn'
    },
    'Delay': {
      'type': 'Delay',
      'description': 'Delay',
      'icon': 'fas fa-clock'
    },
    'OrderEntryTerminalWarning': {
      'type': 'Order Entry Terminal Warning',
      'description': 'Order Entry Terminal Warning',
      'icon': 'fas fa-exclamation-triangle'
    },
    'SMS': {
      'type': 'SMS',
      'description': 'SMS',
      'icon': 'fas fa-sms'
    },
    'DJ': {
      'type': 'DJ Audio',
      'description': 'DJ Audio',
      'icon': 'fas fa-music'
    },
    'PushNotification': {
      'type': 'Push Notification',
      'description': 'Push Notification',
      'icon': 'fas fa-bell'
    },
    'Light': {
      'type': 'Light',
      'description': 'Light',
      'icon': 'far fa-lightbulb'
    },
    'EnableOrDisableKiosk': {
      'type': 'Enable/Disable Kiosk',
      'description': 'Enable/Disable Kiosk',
      'icon': 'fas fa-tablet-alt'
    }
  },
  EventSubscriptionAttributes: [
    { EventName: 'ButtonPressedEvent', AttributeName: 'Buttons' },
    { EventName: 'ProductOrderedEvent', AttributeName: 'Products' },
    { EventName: 'NoOfActiveOrderExceedsRecoverEvent', AttributeName: 'ActiveOrderCountThreshold' },
    { EventName: 'NoOfActiveOrderFallsEvent', AttributeName: 'ActiveOrderCountThreshold' },
    { EventName: 'UserClockedInOverXHoursEvent', AttributeName: 'Hours' },
    { EventName: 'UnservedOrderMinutesEvent', AttributeName: 'UnservedOrderMinutes' },
    { EventName: 'CreditCardTerminalDisconnectedEvent', AttributeName: 'DisconnectedForMinutes' },
    { EventName: 'InventoryQtyMinimumReachedEvent', AttributeName: 'IsNotifyDNIproduct' },
    { EventName: 'UnexpectedInventoryLoggedEvent', AttributeName: 'IsNotifyDNIproduct' },
    { EventName: 'EquipmentTemperatureExceptionEvent', AttributeName: 'Equipments' },
    { EventName: 'EquipmentTemperatureExceptionEvent', AttributeName: 'TemperatureExceptionState' },
    { EventName: 'EquipmentTemperatureExceptionEvent', AttributeName: 'TemperatureExceptionMinutes' },
    { EventName: 'HardwareConnectedEvent', AttributeName: 'HardwareDevices' },
    { EventName: 'HardwareConnectedEvent', AttributeName: 'HardwareStateMinutes' },
    { EventName: 'PrinterErrorEvent', AttributeName: 'PrinterDevices' },
    { EventName: 'InventoryQtyCriticalLevelReachedEvent', AttributeName: 'IsNotifyDNIproduct' },
    { EventName: 'UserClockedInOverXHourFromXPeriodEvent', AttributeName: 'Hours' },
    { EventName: 'UserClockedInOverXHourFromXPeriodEvent', AttributeName: 'Period' },
    { EventName: 'UserClockedInOverXHourFromXPeriodEvent', AttributeName: 'Day' },
    { EventName: 'UserClockedInOverXHourFromXPeriodEvent', AttributeName: 'Week' },
    { EventName: 'UserClockedInOverXHourFromXPeriodEvent', AttributeName: 'Month' },
    { EventName: 'InventoryPrepNeededEvent', AttributeName: 'QtyStatus' },
    { EventName: 'InventoryPrepNeededEvent', AttributeName: 'WithinPercentOfMinimum' },
    { EventName: 'TerminalConnectedEvent', AttributeName: 'Terminals' },
  ],
  EventDeliveryAttributes: {
    'Email': {
      'Recipients': 'Recipients',
      'Repeat': 'Repeat'
    },
    'TerminalMessage': {
      'Users': 'Users',
      'Terminals': 'Terminals',
      'Repeat': 'Repeat'
    },
    'ExternalAPIDelivery': {
      'ApiParameters': 'ApiParameters',
      'ExternalApi': 'ExternalApi',
      'ExternalApiDelay': 'ExternalApiDelay',
      'Repeat': 'Repeat'
    },
    'AudioNotification': {
      'AudioDevices': 'AudioDevices',
      'AudioFile': 'AudioFile',
      'SpeakText': 'SpeakText',
      'SpeakVoiceId': 'SpeakVoiceId',
      'AudioVolume': 'AudioVolume',
      'Repeat': 'Repeat'
    },
    'Task': {
      'TaskId': 'TaskId',
      'TaskNameTemplate': 'TaskNameTemplate',
      'Repeat': 'Repeat'
    },
    'Delay': {
      'Seconds': 'Seconds'
    },
    'OrderEntryTerminalWarning': {},
    'SMS': {
      'RecipientPhoneNumbers': 'RecipientPhoneNumbers',
      'SMSText': 'SMSText',
      'Repeat': 'Repeat'
    },
    'DJ': {
      'Terminals': 'Terminals',
      'SpeakText': 'SpeakText',
      'SpeakVoiceId': 'SpeakVoiceId',
      'AudioVolume': 'AudioVolume',
      'Repeat': 'Repeat'
    },
    'Light': {
      'IndicationType': 'IndicationType',
      'LightsConfiguration': 'LightsConfiguration',
      'Duration': 'Duration',
      'LightingControllerId': 'LightingControllerId',
      'Repeat': 'Repeat'
    },
    'PushNotification': {
      'AudioFile': 'AudioFile',
      'NotificationSpeakText': 'NotificationSpeakText',
      'SpeakVoiceId': 'SpeakVoiceId',
      'NotificationText': 'NotificationText',
      'Repeat': 'Repeat'
    },
    'EnableOrDisableKiosk': {
      'Terminals': 'Terminals',
      'Status': 'Status',
      'Repeat': 'Repeat'
    },
  },
  ReportDateRangeType: [
    { Text: 'days', Value: 'd' },
    { Text: 'hours', Value: 'h' },
    { Text: 'minutes', Value: 'm' }
  ],
  ReportDateRangeOffsetType: [
    { Text: 'before', Value: '-' },
    { Text: 'after', Value: '+' },
  ],
  DataTypes: [
    { Text: 'Text', Value: 'TEXT', HasDropdown: false },
    { Text: 'CSV', Value: 'CSV', HasDropdown: true },
    { Text: 'Integer', Value: 'INT', HasDropdown: true },
    { Text: 'Multiselect', Value: 'MULTISELECT', HasDropdown: true },
    { Text: 'Date', Value: 'DATE', HasDropdown: false },
    { Text: 'Bit', Value: 'BIT', HasDropdown: false },
    { Text: 'Dropdown', Value: 'DROPDOWN', HasDropdown: true }
  ],

  EventRepeatActionDurationUnit: [
    'Seconds',
    'Minutes',
    'Hours',
    'Days'
  ],
  EventRepeatUntilDurationType: {
    Times: 'Times',
    Duration: 'Duration',
    Recovery: 'Recovery'
  },
  ReportDataTypes: {
    Varchar: 'varchar',
    NVarchar: 'nvarchar',
    Int: 'int',
    Decimal: 'decimal',
    Money: 'money',
    DateTime: 'datetime',
    Date: 'date',
    Bit: 'bit',
    Real: 'real'
  },
  SyncOrderOn: {
    Immediately: { Text: 'When order is received', Value: 'Immediately' },
    OnPickupDate: { Text: 'On the day of fullfillment', Value: 'OnPickupDate' }
  },
  PlayOrder: {
    Literal: 'Literal',
    Alpha: 'Alpha',
    Random: 'Random'
  },
  ButtonBehaviors: {
    DisplayMessage: {
      Value: 'DisplayMessage',
      Text: 'Display Message',
      IsAllowMultipleBehavior: true,
      Icon: 'fas fa-envelope'
    },
    DisplayToaster: {
      Value: 'DisplayToaster',
      Text: 'Display Toaster',
      IsAllowMultipleBehavior: true,
      Icon: 'fas fa-comment-dots'
    },
    UncheckButtons: {
      Value: 'UncheckButtons',
      Text: 'Uncheck Button(s)',
      IsAllowMultipleBehavior: true,
      Icon: 'fas fa-dice-one'
    },
    UncheckAllButtons: {
      Value: 'UncheckAllButtons',
      Text: 'Uncheck All Button(s)',
      IsAllowMultipleBehavior: false,
      Icon: 'fas fa-border-all'
    },
    SelectButtons: {
      Value: 'SelectButtons',
      Text: 'Select Button(s)',
      IsAllowMultipleBehavior: true,
      Icon: 'fas fa-check-square'
    },
    FlashButtons: {
      Value: 'FlashButtons',
      Text: 'Flash Button(s)',
      IsAllowMultipleBehavior: true,
      Icon: 'fas fa-lightbulb'
    },
    TemporaryNav: {
      Value: 'TemporaryNav',
      Text: 'Temporary Navigation',
      IsAllowMultipleBehavior: false,
      Icon: 'fas fa-map-pin'
    },
    IncrementInventory: {
      Value: 'IncreaseInventory',
      Text: 'Increase Inventory',
      IsAllowMultipleBehavior: true,
      Icon: 'fas fa-plus-square'
    },
    DecrementInventory: {
      Value: 'DecrementInventory',
      Text: 'Decrease Inventory',
      IsAllowMultipleBehavior: true,
      Icon: 'fas fa-minus-square'
    },
    SendPressEvent: {
      Value: 'SendPressEvent',
      Text: 'Send Press Event',
      IsAllowMultipleBehavior: false,
      Icon: 'fas fa-concierge-bell'
    },
    // RevenueCenter: {
    //   Value: 'RevenueCenter',
    //   Text: 'Set Revenue Center',
    //   IsAllowMultipleBehavior: false,
    //   Icon: 'fas fa-money-check-alt'
    // },
    SignIn: {
      Value: 'SignIn',
      Text: 'Sign In',
      IsAllowMultipleBehavior: false,
      Icon: 'fas fa-sign-in-alt'
    },
    SingularFee: {
      Value: 'SingularFee',
      Text: 'Singular Fee',
      IsAllowMultipleBehavior: true,
      Icon: 'fas fa-money-bill-alt'
    },
    MultipleProduct: {
      Value: 'MultipleProduct',
      Text: 'Multiple Products',
      IsAllowMultipleBehavior: false,
      Icon: 'fas fa-duotone fa-layer-group'
    },
    AddAiShake: {
      Value: 'AddAIShake',
      Text: 'Add AI Shake',
      IsAllowMultipleBehavior: false,
      Icon: 'fas fa-magic'
    }
  },

  KioskButtonBehaviors: {
    DecrementInventory: {
      Value: 'DecrementInventory',
      Text: 'Decrease Inventory',
      IsAllowMultipleBehavior: true,
      Icon: 'fas fa-minus-square'
    },
    IncrementInventory: {
      Value: 'IncreaseInventory',
      Text: 'Increase Inventory',
      IsAllowMultipleBehavior: true,
      Icon: 'fas fa-plus-square'
    },
    DisplayMessage: {
      Value: 'DisplayMessage',
      Text: 'Display Message',
      IsAllowMultipleBehavior: true,
      Icon: 'fas fa-envelope'
    },
    SelectButtons: {
      Value: 'SelectButtons',
      Text: 'Select Button(s)',
      IsAllowMultipleBehavior: true,
      Icon: 'fas fa-check-square'
    },
    SingularFee: {
      Value: 'SingularFee',
      Text: 'Singular Fee',
      IsAllowMultipleBehavior: true,
      Icon: 'fas fa-money-bill-alt'
    },
    MultipleProduct: {
      Value: 'MultipleProduct',
      Text: 'Multiple Products',
      IsAllowMultipleBehavior: false,
      Icon: 'fas fa-duotone fa-layer-group'
    },
    TemporaryNav: {
      Value: 'TemporaryNav',
      Text: 'Temporary Navigation',
      IsAllowMultipleBehavior: false,
      Icon: 'fas fa-map-pin'
    },
    UncheckAllButtons: {
      Value: 'UncheckAllButtons',
      Text: 'Uncheck All Button(s)',
      IsAllowMultipleBehavior: false,
      Icon: 'fas fa-border-all'
    },
    UncheckButtons: {
      Value: 'UncheckButtons',
      Text: 'Uncheck Button(s)',
      IsAllowMultipleBehavior: true,
      Icon: 'fas fa-dice-one'
    },
    SetSize: {
      Value: 'SetSize',
      Text: 'Set Size',
      IsAllowMultipleBehavior: true,
      Icon: 'fas fa-boxes'
    },
    AddCommentToAllProducts: {
      Value: 'AddCommentToAllProduct',
      Text: 'Add Comment To All Product',
      IsAllowMultipleBehavior: false,
      Icon: 'fas fa-comments'
    },
    AddComment: {
      Value: 'AddComment',
      Text: 'Add Comment To Order',
      IsAllowMultipleBehavior: false,
      Icon: 'fas fa-comment'
    },
    AutoMarkup: {
      Value: 'AutoMarkup',
      Text: 'Add Auto Markup',
      IsAllowMultipleBehavior: false,
      Icon: 'fas fa-sack-dollar'
    },
    AutoDiscount: {
      Value: 'AutoDiscount',
      Text: 'Add Auto Discount',
      IsAllowMultipleBehavior: false,
      Icon: 'fas fa-badge-percent'
    },
    AddMainProduct: {
      Value: 'AddMainProduct',
      Text: 'Add Main Product',
      IsAllowMultipleBehavior: false,
      Icon: 'fas fa-star'
    },
    AddDetailEntry: {
      Value: 'AddDetailEntry',
      Text: 'Add Detail Entry',
      IsAllowMultipleBehavior: false,
      Icon: 'fas fa-stars'
    },
    FilterProducts: {
      Value: 'FilterProducts',
      Text: 'Filter Products',
      IsAllowMultipleBehavior: false,
      Icon: 'fas fa-filter'
    },
    CallShakeGenerateAPI: {
      Value: 'CallShakeGenerateAPI',
      Text: 'Call Shake Generate API',
      IsAllowMultipleBehavior: false,
      Icon: 'fas fa-magic'
    }
  },

  ExportConnectionParameters: {
    FTP: [
      { Key: 'Server', Value: null, DisplayName: 'Server', Info: 'IP address or DNS of the server to connect to' },
      { Key: 'Port', Value: null, DisplayName: 'Port', Info: '21 for FTP, 22 for SFTP' },
      { Key: 'Username', Value: null, DisplayName: 'Username', Info: 'The username to log in with' },
      { Key: 'Password', Value: null, DisplayName: 'Password', Info: 'The password to log in with' },
      { Key: 'Location', Value: null, DisplayName: 'Location', Info: 'The path to upload to, for example /dir_name' },
      { Key: 'IsUseSSL', Value: null, DisplayName: 'Use SSL', Info: 'If encrypted SFTP, enter "Yes" or "1"' },
      { Key: 'UsePassive', Value: null, DisplayName: 'Use Passive', Info: 'If passive FTP is required, enter "Yes" or "1"' }
    ],
    S3: [
      { Key: 'AccessKeyId', Value: null, DisplayName: 'Access Key' },
      { Key: 'SecretAccessKey', Value: null, DisplayName: 'Secret Access Key' },
      { Key: 'BucketRegion', Value: null, DisplayName: 'Bucket Region' },
      { Key: 'BucketName', Value: null, DisplayName: 'Bucket Name' },
      { Key: 'StorageClass', Value: null, DisplayName: 'Storage Class' }
    ],
    Email: [
      { Key: 'Sender', Value: null, DisplayName: 'Sender' },
      { Key: 'Recipients', Value: null, DisplayName: 'Recipients' },
      { Key: 'Subject', Value: null, DisplayName: 'Subject' },
      { Key: 'DisplayName', Value: null, DisplayName: 'Display Name' },
      { Key: 'MessageText', Value: null, DisplayName: 'Message Text' }
    ],
    SyncAPI: [
      { Key: 'APIURL', Value: null, DisplayName: 'API URL' }
    ]
  },
  ReportColumnSettingName: {
    Alignment: 'Align',
    Total: 'Total',
    Format: 'Format',
    GroupField: 'GroupField',
  },
  ReportColumnFormat: {
    Currency: 'currency',
    Date: 'date',
    Numeric: 'numeric',
  },
  GridColumnFormat: {
    Format: 'Format'
  },
  LightHardwareProperty: {
    AuthToken: 'AuthToken',
    LightCount: 'LightCount',
    LightsInfo: 'LightsInfo',
    BaseUrl: 'BaseUrl'
  },
  TimeFormats: [
    { Name: '12 hour', Value: 'hh:mm a' },
    { Name: '24 hour', Value: 'HH:mm' },
  ],
  GENERIC_USER: 'Generic User',
  DigitalMenuWidgetTypes: {
    Text: { Name: 'Text', Value: 'Text', Icon: text },
    List: { Name: 'List', Value: 'List', Icon: listUl },
    Image: { Name: 'Image', Value: 'Image', Icon: imageLight },
    ImageList: { Name: 'ImageList', Value: 'Image List', Icon: images },
    Banner: { Name: 'Banner', Value: 'Banner', Icon: browser },
    TileList: { Name: 'TileList', Value: 'Tile List', Icon: boxesLight },
    ComboItemList: { Name: 'ComboItemList', Value: 'Combo Item List', Icon: cloneLight },
    Item: { Name: 'Item', Value: 'Item', Icon: burgerSoda },
    Group: { Name: 'Group', Value: 'Group', Icon: objectGroup },
    Video: { Name: 'Video', Value: 'Video', Icon: solidVideo },
  },
  OutOfStockBehaviorTypes: {
    Hide: 'Hide',
    Disabled: 'Disabled'
  },
  KioskOutOfStockBehaviorType: {
    ShowOutOfStock: 'Show as Out of Stock',
    Hide: 'Hide',
  },
  KioskOutOfStockBehaviorTypes: [
    { Name: 'Show as Out of Stock', Value: 1 },
    { Name: 'Hide', Value: 2 },
  ],
  BulletTypes: {
    Circle: 'circle',
    Disc: 'disc',
    DisclosureClosed: 'disclosure-closed',
    DisclosureOpen: 'disclosure-open',
    None: 'none',
    Numeric: 'numeric',
    Square: 'square',
  },
  Orientation: {
    Horizontal: 'Horizontal',
    Vertical: 'Vertical'
  },

  GroupListBehavior: [
    { Name: 'Block', Value: 'block' },
    { Name: 'Inline Block', Value: 'inline-block' },
    { Name: 'Flex', Value: 'flex' },
    { Name: 'Grid', Value: 'grid' },
    { Name: 'None', Value: 'none' },
  ],

  ImageTextPosition: [
    'Bottom',
    'Top',
  ],

  TextAlign: [
    { Name: 'Center', Value: 'center' },
    { Name: 'End', Value: 'end' },
    { Name: 'Justify', Value: 'justify' },
    { Name: 'Left', Value: 'left' },
    { Name: 'Revert', Value: 'revert' },
    { Name: 'Right', Value: 'right' },
  ],

  AlignItemsVertical: [
    { Name: 'Bottom', Value: 'bottom' },
    { Name: 'Center', Value: 'center' },
    { Name: 'Top', Value: 'top' },
  ],

  AlignItemsHorizontal: [
    { Name: 'Center', Value: 'center' },
    { Name: 'Left', Value: 'start' },
    { Name: 'Right', Value: 'end' },
  ],

  HorizontalAlignmentsForFlex: [
    { Name: 'Center', Value: 'center' },
    { Name: 'Left', Value: 'start' },
    { Name: 'Right', Value: 'end' },
    { Name: 'Space Around', Value: 'space-around' },
    { Name: 'Space Between', Value: 'space-between' },
    { Name: 'Space Evenly', Value: 'space-evenly' },
  ],

  VerticalAlignments: [
    { Name: 'Bottom', Value: 'end' },
    { Name: 'Center', Value: 'center' },
    { Name: 'Top', Value: 'start' },
  ],

  VerticalAlign: [
    { Name: 'Bottom', Value: 'bottom' },
    { Name: 'Middle', Value: 'middle' },
    { Name: 'Top', Value: 'top' },
  ],

  JustifyContent: [
    { Name: 'Center', Value: 'center' },
    { Name: 'End', Value: 'end' },
    { Name: 'Space Around', Value: 'space-around' },
    { Name: 'Space Between', Value: 'space-between' },
    { Name: 'Space Evenly', Value: 'space-evenly' },
    { Name: 'Start', Value: 'start' },
  ],

  HardwareTypeImages: [
    { Type: 'AudioNotifier', ImagePath: 'images/terminal/device-audio-notifier.png', Group: 'Peripherals' },
    { Type: 'Camera', ImagePath: 'images/terminal/device-camera.png', Group: 'Peripherals' },
    { Type: 'CashDrawer', ImagePath: 'images/terminal/device-cash-drawer.png', Group: 'Payment' },
    { Type: 'CreditCardTerminal', ImagePath: 'images/terminal/device-credit-card-terminal.png', Group: 'Payment' },
    { Type: 'KitchenPrinter', ImagePath: 'images/terminal/device-kitchen-printer.png', Group: 'Printer' },
    { Type: 'LabelPrinter', ImagePath: 'images/terminal/device-label-printer.png', Group: 'Printer' },
    { Type: 'LightingController', ImagePath: 'images/terminal/device-light-controller.png', Group: 'Peripherals' },
    { Type: 'ReceiptPrinter', ImagePath: 'images/terminal/device-receipt-printer.png', Group: 'Printer' },
    { Type: 'AudioRecorder', ImagePath: 'images/terminal/audio-recorder.png', Group: 'Peripherals' }

  ],

  HardwareGroup: {
    Printer: 'Printer',
    Payment: 'Payment',
    Peripherals: 'Peripherals',
  },
  TemperatureExceptions: [
    { Key: 'Low', Value: 'Low' },
    { Key: 'High', Value: 'High' },
    { Key: 'Not Reported', Value: 'NotReported' }
  ],
  Period: {
    Day: 'Day',
    Week: 'Week',
    Month: 'Month'
  },
  HoursOverPeriodMonth: [
    { Key: 'Current', Value: 'Current' },
    { Key: 'Previous', Value: 'Previous' }
  ],
  Weeks: [
    { Key: 'Monday', Value: 'Monday', Id: 1, Abbreviation: 'M' },
    { Key: 'Tuesday', Value: 'Tuesday', Id: 2, Abbreviation: 'T' },
    { Key: 'Wednesday', Value: 'Wednesday', Id: 3, Abbreviation: 'W' },
    { Key: 'Thursday', Value: 'Thursday', Id: 4, Abbreviation: 'Th' },
    { Key: 'Friday', Value: 'Friday', Id: 5, Abbreviation: 'F' },
    { Key: 'Saturday', Value: 'Saturday', Id: 6, Abbreviation: 'Sa' },
    { Key: 'Sunday', Value: 'Sunday', Id: 0, Abbreviation: 'S' }
  ],
  EventSubscriptionAttributeKeys: {
    Equipments: 'Equipments',
    TemperatureExceptionState: 'TemperatureExceptionState',
    TemperatureExceptionMinutes: 'TemperatureExceptionMinutes',
    HardwareDevices: 'HardwareDevices',
    HardwareStateMinutes: 'HardwareStateMinutes',
    Hours: 'Hours',
    Period: 'Period',
    Day: 'Day',
    Week: 'Week',
    Month: 'Month',
    QtyStatus: 'QtyStatus',
    WithinPercentOfMinimum: 'WithinPercentOfMinimum'
  },
  InventoryPrepNeededQtyStatus: {
    BelowPar: 'BelowPar',
    WithinPercentOfMinimum: 'WithinPercentOfMinimum'
  },
  EquipmentProperties: {
    MinTemperature: 'Min Temperature',
    MaxTemperature: 'Max Temperature',
    CurrentTemperature: 'Current Temperature'
  },
  EventType: {
    Event: 'Event',
    Recovery: 'Recovery'
  },
  ReportsWithHorizontalScroll: ['Product Reorder Planning'],
  FontStyle: {
    Bold: 'bold',
    Italic: 'italic',
    Underline: 'underline'
  },
  TransactionTypes: {
    Payment: 'Payment',
    Void: 'Void',
    GiftCardRecharge: 'GiftCardRecharge',
    GiftCardRechargeGratis: 'GiftCardRecharge(Gratis)'
  },
  TransactionStatus: {
    Pending: 'Pending',
    Canceled: 'Canceled',
    Failed: 'Failed',
    Success: 'Success'
  },
  MenuInteractivityActionType: {
    Highlight: 'Highlight',
    Flash: 'Flash',
    HideExclusions: 'Hide Exclusions'
  },
  DefaultPhoneMask: '(000) 000-0000',
  PhoneText: 'Phone: ',
  PushNotificationModes: {
    Audio: 'Audio',
    Text: 'Text',
    SpeakText: 'Speak Text'
  },
  SingularFeeTypes: {
    PerOrder: 'PerOrder',
    PerProduct: 'PerProduct'
  },
  RewardAfterEligibility: {
    Immediately: 'Immediately',
    EndOfDay: 'EndOfDay'
  },
  PromotionTypes: {
    GiftCards: { Name: 'GiftCards', DisplayName: 'Gift Card', Route: 'gift-cards', Icon: promotionGiftCard, Permission: Permissions.PromotionsGiftCard },
    Promotions: { Name: 'Promotions', DisplayName: 'Promotions', Route: 'promotions', Icon: piggyBank, Permission: Permissions.PromotionsOther }
  },
  WaitTimeRuleTypes: {
    Global: { Name: 'Global', Value: 'Global', DisplayName: 'Global', RoutLink: 'global', Icon: globe, Permission: Permissions.Global, PermissionLevel: Levels.Access },
    Order: { Name: 'Per Order', Value: 'PerOrder', DisplayName: 'Per Order', RoutLink: 'per-order', Icon: bagsShopping, Permission: Permissions.Order, PermissionLevel: Levels.Access },
  },
  PromotionConfigPropertyTypes: {
    SalesProduct: 'SalesProduct',
    SalesCategory: 'SalesCategory',
    SalesGroup: 'SalesGroup',
    Text: 'Text',
    Numeric: 'Numeric',
    PromotionPeriods: 'PromotionPeriods',
    MembershipTier: 'MembershipTier',
    Time: 'Time',
    WeekDays: 'WeekDays'
  },
  ExportTypes: {
    Auto: 'Auto',
    Manual: 'Manual'
  },
  ExportStatus: {
    Pending: 'Pending',
    Released: 'Released',
    Ignored: 'Ignored'
  },
  TaskActionTypes: {
    New: 'New',
    Existing: 'Existing'
  },

  WidgetFieldInfo: {
    HeaderImage: { info: '', image: 'header-image.png' },
    HeaderText: { info: '', image: 'header-text.png' },
    HeaderPrice: { info: '', image: 'header-price.png' },
    HeaderBadge: { info: '', image: 'header-badge.png' },
    Title: { info: '', image: 'title.png' },
    Footer: { info: '', image: 'footer.png' },
    OutOfStockBehavior: { info: '', image: 'out-of-stock-behavior.png' },
    GroupedItemStyle: { info: '', image: 'grouped-item-style.png' },
    ListItemStyle: { info: '', image: 'list-item-style.png' },
    BorderStyle: { info: '', image: 'border-style.png' },
    ItemFontStyle: { info: '', image: 'item-font-style.png' },
    HideTags: { info: '', image: 'hide-tags.png' },
    ShowProductPrice: { info: '', image: 'show-product-price.png' },
    ShowItemDescription: { info: '', image: 'show-item-description.png' },
    DisplayListStyle: { info: 'Used to set bullet style of list items. e.g. here disc style is set to \'Square\'', image: 'display-list-style.png' },
    NoOfColumns: { info: 'Specify no. of column in list. e.g. here it is set to 2', image: 'no-of-columns.png' },
    JustifyContent: { info: '', image: 'justify-content.png' },
    AlignItems: { info: '', image: 'align-items.png' },
    imageShadow: { info: 'Image shadow can be configured from here.', image: 'image-shadow.png' },
  },

  BorderStyles: [
    { Key: 'Dashed', Value: 'dashed' },
    { Key: 'Dotted', Value: 'dotted' },
    { Key: 'Double', Value: 'double' },
    { Key: 'Groove', Value: 'groove' },
    { Key: 'Hidden', Value: 'hidden' },
    { Key: 'None', Value: 'none' },
    { Key: 'Outset', Value: 'outset' },
    { Key: 'Ridge', Value: 'ridge' },
    { Key: 'Solid', Value: 'solid' },
  ],

  SeparatorDirection: [
    { Key: 'Horizontal', Value: 'row' },
    { Key: 'Vertical', Value: 'column' },
  ],

  TextDecoration: [
    { Key: 'Line Through', Value: 'line-through' },
    { Key: 'Overline', Value: 'overline' },
    { Key: 'Underline', Value: 'underline' },
    { Key: 'Underline Overline', Value: 'underline overline' }
  ],

  SessionStorageKeys: {
    InventoryScannerBatch: 'inventoryScannerBatch'
  },

  DietaryWarningTypes: {
    Allergen: "1",
    EnvironmentalWarning: "2",
  },
  OnSiteInventoryText: 'Default',
  TerminalStatus: [
    { Key: 'Online', Value: 'Online', Icon: 'circleGreen' },
    { Key: 'Offline', Value: 'Offline', Icon: 'circleRed' },
    { Key: 'Not Tracked', Value: 'NotTracked', Icon: '' },
  ],
  OrderKioskWidgetTypes: {
    Button: { Name: 'Button', Icon: diceOneDuotone },
    List: { Name: 'List', Icon: listUl },
    Image: { Name: 'Image', Icon: imageLight },
    Text: { Name: 'Text', Icon: text },
  },
  KioskScreenTemplateTypes: {
    AllergensVerifyScreenLayout: 'Allergens Confirmation Layout',
    AllergensInquiryLayout: 'Allergens Inquiry Layout',
    AllergensSelectionScreenLayout: 'Allergens Selection Layout',
    PromptTemplate: 'Prompt Template',
    FixedGridLayout: 'Fixed Grid Layout',
    FluidGridLayout: 'Fluid Grid Layout',
    SplitScreenLayout: 'Split Screen Layout',
    WelcomeScreenLayout: 'Welcome Screen Layout',
    AIShakeGroupSelectionTemplate: 'AI Shake Group Selection Layout',
    AIShakeCreatedTemplate: 'AI Shake Created Layout',
  },
  KioskScreenConfigProperties: {
    ScreenConfig: 'ScreenConfig',
    Grid: 'Grid',
    ButtonConfig: 'ButtonConfig',
    SecondaryButtonConfig: 'SecondaryButtonConfig',
    LeftPanelConfig: 'LeftPanelConfig',
    RightPanelConfig: 'RightPanelConfig',
    Buttons: 'Buttons',
    LeftPanelButtons: 'LeftPanelButtons',
    RightPanelButtons: 'RightPanelButtons',
    CreatedShakeScreenConfig: 'CreatedShakeScreenConfig',
    SecondaryButtons: 'SecondaryButtons'
  },
  KioskScreenConfigTypes: {
    JSON: 'JSON',
    Buttons: 'Buttons',
    SecondaryButtons: 'SecondaryButtons'
  },
  OrderMethods: {   
    OrderKiosk: 'Order Kiosk'
  },
  UserPreferences: {
    InvoiceLocation: 'InvoiceLocation',
    OrderMaintenanceUser: 'OrderMaintenanceUser',
  },
  OrderProductStatus: {
    None: 0,
    Sent: 1,
    Voided: 2,
    Deleted: 3,
    Disount: 4,
    Paid: 5,
    Delayed: 6,
    Refunded: 7
  },
  OnlineOrderingSettingNames: {
    RefundPolicy: 'refundPolicy',
    PrivacyPolicy: 'privacyPolicy',
    TermsAndConditions: 'termsAndConditions'
  },
  MakeTableSortProperties: [
    { Key: 'Order Id', Value: 'Id' },
    { Key: 'Make Table DateTime', Value: 'MakeTableDateTime' },
  ]
};
