import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import { MakeTableWorkflowParameter, OrderVariable } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class MakeTableWorkflowService {

  makeTableWorkflowApiBasePath = `${RuntimeConstants.API_PATH}make-table-workflow-configuration`;
  constructor(private httpService: HttpClient) { }

  newMakeTableWorkflowConfigurationParameter(): MakeTableWorkflowParameter {
    return {
      IsUseMakeTable: false,
      ShowSubaccountInMakeTable: false,
      UseParallelPickScreenAndTimer: false,
      AutoCollapseOnlineOrders: false,
      AutoExpandMinutesOnlineOrder: null,
      OrderReadyShowNotifyButtonOnMaketable: false,
      OrderReadyNotifyViaSMSText: '',
      OrderReadyNotifyViaDJTerminal: null,
      OrderReadyNotifyViaDJVolume: null,
      OrderReadyNotifyViaAudioNotifier: null,
      OrderReadyNotifyViaAudioNotifierText: '',
      OrderReadyNotifyViaAudioNotifierVoice: '',
      OrderReadyNotifyViaAudioNotifierVolume: null,
      MakeTableStateChangeDelayMsec: null,
      OrderReadyNotifyViaDJText: '',
      OrderReadyNotifyViaDJVoice: '',
      IsShowMakeTablePendingState: false,
      MakeTableWorkflowStates: null,
      ShowWhenMoreThanOneSubaccountInMakeTable: false,
      IsShowTipOnMakeTable: false,
      IsShowCookingPanel: false,
      PromptToChooseUsernameOnMakeTable: false,
      ShortMakeTableOrdersBy: 'Id'
    };
  }

  getMakeTableWorkflowParameters(): Observable<MakeTableWorkflowParameter> {
    return this.httpService.get<MakeTableWorkflowParameter>(`${this.makeTableWorkflowApiBasePath}`);
  }

  updateMakeTableWorkflowParameters(makeTableWorkflowParameter: MakeTableWorkflowParameter) {
    return this.httpService.post<MakeTableWorkflowParameter>(`${this.makeTableWorkflowApiBasePath}`, makeTableWorkflowParameter);
  }

  getOrderVariables(): Observable<Array<OrderVariable>> {
    return this.httpService.get<Array<any>>(`${this.makeTableWorkflowApiBasePath}/order-variables`);
}
}
