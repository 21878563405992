import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { User } from '../../../../information-management/users';
import { AudioNotificationFile } from '../../../../information-management/audio-notification-files/interfaces/audio-notification-file';
import { Terminal } from '../../../../configurator/terminals/';
import { ExternalApi } from '../../../../configurator/external-api';
import { AlertsService, DomainConstants, HardwareModel, ICloseable, Messages, ModalService, SpinnerService } from 'src/app/shared';
import { find, forEach, orderBy } from 'lodash';
import { EventSubscriptionAction } from '../../interfaces/event-subscription-action';
import { Task } from '../../../../information-management/tasks/interface/task';
import { EmailConfigurationComponent } from '../email-configuration';
import { TerminalMessageConfigurationComponent } from '../terminal-message-configuration/terminal-message-configuration.component';
import { DelayConfigurationComponent } from '../delay-configuration/delay-configuration.component';
import { TaskConfigurationComponent } from '../task-configuration';
import { SelectExternalApiComponent } from '../select-external-api';
import { SelectAudioNotificationComponent } from '../select-audio-notification';
import { SmsConfigurationComponent } from '../sms-configuration/sms-configuration.component';
import { DjConfigurationComponent } from '../dj-configuration/dj-configuration.component';
import { LightConfigurationComponent } from '../light-configuration/light-configuration.component';
import { EventSubscription, EventSubscriptionActionAttribute } from '../../interfaces';
import { EventSubscriptionService } from '../../services/event-subscription.service';
import { PushNotificationConfigurationComponent } from '../push-notification-configuration/push-notification-configuration.component';
import { KioskEnableDisableConfigurationComponent } from '../kiosk-enable-disable-configuration';

@Component({
  selector: 'pos-event-configuration-edit',
  templateUrl: './event-configuration-edit.component.html',
  styleUrls: ['./event-configuration-edit.component.scss']
})
export class EventConfigurationEditComponent implements OnInit {

  @Input() id = 0;
  @Input() eventIdentifier = '';
  @Input() eventAction: EventSubscriptionAction;
  @Input() availableDeliveryChannels: Array<any> = [];
  @Input() eventAttributes: Array<string> = [];
  @Input() eventSubscription: EventSubscription;
  @Input() subscriptionEventType: string;
  eventDeliveryChannels = DomainConstants.EventDeliveryChannels;
  @ViewChild('eventActivityEditForm', { static: true }) eventActivityEditForm: NgForm;

  @Input() users: Array<User> = [];
  @Input() terminals: Array<Terminal> = [];
  @Input() djTerminals: Array<Terminal> = [];
  @Input() externalApis: Array<ExternalApi> = [];
  @Input() audioDevices: Array<HardwareModel> = [];
  @Input() audioFiles: Array<AudioNotificationFile> = [];
  @Input() audioVoices: Array<string> = [];
  @Input() lightingControllers: Array<HardwareModel> = [];
  @Input() tasks: Array<Task> = [];
  @Input() isRecoverableEvent;
  isRecoverable = false;
  deliveryTypes = [];
  eventTypes = DomainConstants.EventTypes;
  @Input() selectedDeliveryType;
  deliveryAttributes = DomainConstants.EventDeliveryAttributes;
  @Output() close: EventEmitter<any> = new EventEmitter();
  constructor(private alertService: AlertsService,
    private spinnerService: SpinnerService,
    private eventSubscriptionService: EventSubscriptionService,
    private modalService: ModalService) { }


  ngOnInit(): void {
    this.generateDeliveryType();
    this.loadConfigurationModal();
  }

  loadConfigurationModal = () => {
    switch (this.selectedDeliveryType.type) {
      case this.eventDeliveryChannels.Email.type:
        this.openEmailConfigurationModal();
        break;
      case this.eventDeliveryChannels.TerminalMessage.type:
        this.openTerminalMessageConfigurationModal();
        break;
      case this.eventDeliveryChannels.ExternalAPIDelivery.type:
        this.openExternalApiConfigurationModal();
        break;
      case this.eventDeliveryChannels.AudioNotification.type:
        this.openAudioNotificationConfigurationModal();
        break;
      case this.eventDeliveryChannels.Delay.type:
        this.openDelayConfigurationModal();
        break;
      case this.eventDeliveryChannels.Task.type:
        this.openTaskConfigurationModal();
        break;
      case this.eventDeliveryChannels.OrderEntryTerminalWarning.type:
        this.openOrderEntryWarningConfigurationModal();
        break;
      case this.eventDeliveryChannels.SMS.type:
        this.openSMSConfigurationModal();
        break;
      case this.eventDeliveryChannels.DJ.type:
        this.openDJConfigurationModal();
        break;
      case this.eventDeliveryChannels.PushNotification.type:
        this.openPushNotificationConfigurationModal();
        break;
      case this.eventDeliveryChannels.Light.type:
        this.openLightConfigurationModal();
        break;
      case this.eventDeliveryChannels.EnableOrDisableKiosk.type:
        this.openDisableKioskConfigurationModal();
        break;
      default:
        break;
    }
  }

  generateDeliveryType = () => {
    forEach(this.eventDeliveryChannels, (channels) => {
      const isAvailableDeliveryChannel = find(this.availableDeliveryChannels, x => x.Type === channels.type);
      if (isAvailableDeliveryChannel || channels.type === this.eventDeliveryChannels.Delay.type) {
        this.deliveryTypes.push(channels);
      }
    });
    this.deliveryTypes = orderBy(this.deliveryTypes, 'type');
  }


  onClose = (isReload: boolean) => {
    this.close.emit({ shouldReload: isReload, audioFiles: this.audioFiles });
  }

  openEmailConfigurationModal = () => {
    const modalRef = this.modalService.getModalWrapper(EmailConfigurationComponent);
    const modal = modalRef.show({
      animated: false,
      keyboard: false,
      class: 'vertical-center',
      initialState: {
        actionId: this.eventAction ? this.eventAction.Id : 0,
        eventSubscriptionActionAttributes: this.eventAction ? this.eventAction.EventSubscriptionActionAttributes : [],
        eventAttributes: this.eventAttributes,
        eventIdentifier: this.eventIdentifier,
        selectedDeliveryType: this.selectedDeliveryType,
        deliveryTypes: this.deliveryTypes,
        isRecoverableEvent: this.isRecoverableEvent && (this.eventAction?.EventType ? this.eventAction.EventType == DomainConstants.EventType.Event : true)
      }
    });
    this.subscribeModalCloseEvent(modal);
  }

  openSMSConfigurationModal = () => {
    const modalRef = this.modalService.getModalWrapper(SmsConfigurationComponent);
    const modal = modalRef.show({
      animated: false,
      keyboard: false,
      class: 'vertical-center',
      initialState: {
        actionId: this.eventAction ? this.eventAction.Id : 0,
        eventSubscriptionActionAttributes: this.eventAction ? this.eventAction.EventSubscriptionActionAttributes : [],
        eventAttributes: this.eventAttributes,
        eventIdentifier: this.eventIdentifier,
        selectedDeliveryType: this.selectedDeliveryType,
        deliveryTypes: this.deliveryTypes,
        isRecoverableEvent: this.isRecoverableEvent && (this.eventAction?.EventType ? this.eventAction.EventType == DomainConstants.EventType.Event : true)
      }
    });
    this.subscribeModalCloseEvent(modal);
  }

  openTerminalMessageConfigurationModal = () => {
    const modalRef = this.modalService.getModalWrapper(TerminalMessageConfigurationComponent);
    const modal = modalRef.show({
      animated: false,
      keyboard: false,
      class: 'vertical-center',
      initialState: {
        actionId: this.eventAction ? this.eventAction.Id : 0,
        eventSubscriptionActionAttributes: this.eventAction ? this.eventAction.EventSubscriptionActionAttributes : [],
        eventAttributes: this.eventAttributes,
        eventIdentifier: this.eventIdentifier,
        selectedDeliveryType: this.selectedDeliveryType,
        users: this.users,
        terminals: this.terminals,
        deliveryTypes: this.deliveryTypes,
        isRecoverableEvent: this.isRecoverableEvent && (this.eventAction?.EventType ? this.eventAction.EventType == DomainConstants.EventType.Event : true)
      }
    });
    this.subscribeModalCloseEvent(modal);
  }

  openDisableKioskConfigurationModal = () => {
    const modalRef = this.modalService.getModalWrapper(KioskEnableDisableConfigurationComponent);
    const modal = modalRef.show({
      animated: false,
      keyboard: false,
      class: 'vertical-center',
      initialState: {
        actionId: this.eventAction ? this.eventAction.Id : 0,
        eventSubscriptionActionAttributes: this.eventAction ? this.eventAction.EventSubscriptionActionAttributes : [],
        eventAttributes: this.eventAttributes,
        eventIdentifier: this.eventIdentifier,
        selectedDeliveryType: this.selectedDeliveryType,
        terminals: this.terminals,
        deliveryTypes: this.deliveryTypes,
        isRecoverableEvent: this.isRecoverableEvent && (this.eventAction?.EventType ? this.eventAction.EventType == DomainConstants.EventType.Event : true)
      }
    });
    this.subscribeModalCloseEvent(modal);
  }

  openDelayConfigurationModal = () => {
    const modalRef = this.modalService.getModalWrapper(DelayConfigurationComponent);
    const modal = modalRef.show({
      animated: false,
      keyboard: false,
      class: 'vertical-center',
      initialState: {
        actionId: this.eventAction ? this.eventAction.Id : 0,
        eventSubscriptionActionAttributes: this.eventAction ? this.eventAction.EventSubscriptionActionAttributes : [],
        eventAttributes: this.eventAttributes,
        eventIdentifier: this.eventIdentifier,
        selectedDeliveryType: this.selectedDeliveryType,
        deliveryTypes: this.deliveryTypes,
        isRecoverableEvent: this.isRecoverableEvent && (this.eventAction?.EventType ? this.eventAction.EventType == DomainConstants.EventType.Event : true)
      }
    });
    this.subscribeModalCloseEvent(modal);
  }

  openTaskConfigurationModal = () => {
    const modalRef = this.modalService.getModalWrapper(TaskConfigurationComponent);
    const modal = modalRef.show({
      animated: false,
      keyboard: false,
      class: 'vertical-center',
      initialState: {
        actionId: this.eventAction ? this.eventAction.Id : 0,
        eventSubscriptionActionAttributes: this.eventAction ? this.eventAction.EventSubscriptionActionAttributes : [],
        eventAttributes: this.eventAttributes,
        eventIdentifier: this.eventIdentifier,
        selectedDeliveryType: this.selectedDeliveryType,
        tasks: this.tasks,
        deliveryTypes: this.deliveryTypes,
        isRecoverableEvent: this.isRecoverableEvent && (this.eventAction?.EventType ? this.eventAction.EventType == DomainConstants.EventType.Event : true)
      }
    });
    this.subscribeModalCloseEvent(modal);
  }

  openExternalApiConfigurationModal = () => {
    const modalRef = this.modalService.getModalWrapper(SelectExternalApiComponent);
    const modal = modalRef.show({
      animated: false,
      keyboard: false,
      class: 'vertical-center',
      initialState: {
        actionId: this.eventAction ? this.eventAction.Id : 0,
        eventSubscriptionActionAttributes: this.eventAction ? this.eventAction.EventSubscriptionActionAttributes : [],
        eventAttributes: this.eventAttributes,
        eventIdentifier: this.eventIdentifier,
        selectedDeliveryType: this.selectedDeliveryType,
        externalApis: this.externalApis,
        deliveryTypes: this.deliveryTypes,
        isRecoverableEvent: this.isRecoverableEvent && (this.eventAction?.EventType ? this.eventAction.EventType == DomainConstants.EventType.Event : true)
      }
    });
    this.subscribeModalCloseEvent(modal);
  }

  openAudioNotificationConfigurationModal = () => {
    const modalRef = this.modalService.getModalWrapper(SelectAudioNotificationComponent);
    const modal = modalRef.show({
      animated: false,
      keyboard: false,
      class: 'vertical-center',
      initialState: {
        actionId: this.eventAction ? this.eventAction.Id : 0,
        eventSubscriptionActionAttributes: this.eventAction ? this.eventAction.EventSubscriptionActionAttributes : [],
        eventAttributes: this.eventAttributes,
        eventIdentifier: this.eventIdentifier,
        selectedDeliveryType: this.selectedDeliveryType,
        audioDevices: this.audioDevices,
        audioFiles: this.audioFiles,
        audioVoices: this.audioVoices,
        deliveryTypes: this.deliveryTypes,
        isRecoverableEvent: this.isRecoverableEvent && (this.eventAction?.EventType ? this.eventAction.EventType == DomainConstants.EventType.Event : true)
      }
    });
    this.subscribeModalCloseEvent(modal);
  }

  openOrderEntryWarningConfigurationModal = () => {
    this.saveEventSubscription();
  }

  openDJConfigurationModal = () => {
    const modalRef = this.modalService.getModalWrapper(DjConfigurationComponent);
    const modal = modalRef.show({
      animated: false,
      keyboard: false,
      class: 'vertical-center',
      initialState: {
        actionId: this.eventAction ? this.eventAction.Id : 0,
        eventSubscriptionActionAttributes: this.eventAction ? this.eventAction.EventSubscriptionActionAttributes : [],
        eventAttributes: this.eventAttributes,
        eventIdentifier: this.eventIdentifier,
        selectedDeliveryType: this.selectedDeliveryType,
        audioVoices: this.audioVoices,
        terminals: this.terminals,
        deliveryTypes: this.deliveryTypes,
        isRecoverableEvent: this.isRecoverableEvent && (this.eventAction?.EventType ? this.eventAction.EventType == DomainConstants.EventType.Event : true)
      }
    });
    this.subscribeModalCloseEvent(modal);
  }

  openLightConfigurationModal = () => {
    const modalRef = this.modalService.getModalWrapper(LightConfigurationComponent);
    const modal = modalRef.show({
      animated: false,
      keyboard: false,
      class: 'vertical-center',
      initialState: {
        actionId: this.eventAction ? this.eventAction.Id : 0,
        eventSubscriptionActionAttributes: this.eventAction ? this.eventAction.EventSubscriptionActionAttributes : [],
        eventAttributes: this.eventAttributes,
        eventIdentifier: this.eventIdentifier,
        selectedDeliveryType: this.selectedDeliveryType,
        deliveryTypes: this.deliveryTypes,
        lightingControllers: this.lightingControllers,
        isRecoverableEvent: this.isRecoverableEvent && (this.eventAction?.EventType ? this.eventAction.EventType == DomainConstants.EventType.Event : true)
      }
    });
    this.subscribeModalCloseEvent(modal);
  }

  openPushNotificationConfigurationModal = () => {
    const modalRef = this.modalService.getModalWrapper(PushNotificationConfigurationComponent);
    const modal = modalRef.show({
      animated: false,
      keyboard: false,
      class: 'vertical-center',
      initialState: {
        actionId: this.eventAction ? this.eventAction.Id : 0,
        eventSubscriptionActionAttributes: this.eventAction ? this.eventAction.EventSubscriptionActionAttributes : [],
        eventAttributes: this.eventAttributes,
        selectedDeliveryType: this.selectedDeliveryType,
        audioFiles: this.audioFiles,
        audioVoices: this.audioVoices,
        isRecoverableEvent: this.isRecoverableEvent && (this.eventAction?.EventType ? this.eventAction.EventType == DomainConstants.EventType.Event : true)
      }
    });
    this.subscribeModalCloseEvent(modal);
  }

  subscribeModalCloseEvent = (modal: ICloseable) => {
    modal.close.subscribe(res => {
      if (res) {
        if (res.audioFiles?.length) {
          this.audioFiles = res.audioFiles;
        }
        if (res.shouldReload && res.actionAttributes) {
          this.saveEventSubscription(res.actionAttributes);
        } else {
          this.onClose(false);
        }
      }
    });
  }

  saveEventSubscription(eventSubscriptionActionAttributes: Array<EventSubscriptionActionAttribute> = []) {
    eventSubscriptionActionAttributes.forEach(x => x.DateAdded = new Date());
    if (this.eventAction && this.eventAction.Id > 0) {
      let eventSubscriptionAction = null;
      if (this.eventAction.EventType === DomainConstants.EventType.Event) {
        eventSubscriptionAction = find(this.eventSubscription.EventSubscriptionActions, x => x.Id === this.eventAction.Id);
      } else {
        eventSubscriptionAction = find(this.eventSubscription.EventSubscriptionRecoveryActions, x => x.Id === this.eventAction.Id);
      }
      eventSubscriptionAction.EventSubscriptionActionAttributes = eventSubscriptionActionAttributes;
    } else {
      const newEventSubscriptionAction: EventSubscriptionAction = {
        Id: 0,
        DeliveryChannel: this.selectedDeliveryType.type,
        DeliverySchedule: 'Immediately',
        EventSubscriptionId: this.id,
        EventSubscriptionActionAttributes: eventSubscriptionActionAttributes,
        IsActive: true,
        EventType: this.subscriptionEventType
      };
      this.eventSubscription.EventSubscriptionActions.push(newEventSubscriptionAction);
    }
    this.prepareAttribute();
    this.spinnerService.show();
    this.eventSubscription.EventSubscriptionActions.push(...this.eventSubscription.EventSubscriptionRecoveryActions);
    this.eventSubscriptionService.saveEventSubscription(this.eventIdentifier, this.eventSubscription)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res) => {
          this.alertService.renderSuccessMessage(Messages.EventActionSaveSuccess);
          this.onClose(true);
        }
      });
  }

  prepareAttribute = () => {
    if (this.eventSubscription.EventSubscriptionAttributes.length) {
      forEach(this.eventSubscription.EventSubscriptionAttributes, attribute => {
        if (this.eventIdentifier === this.eventTypes.ProductOrderedEvent || this.eventIdentifier === this.eventTypes.ButtonPressedEvent) {
          attribute.AttributeValue = Array(attribute.AttributeValue).join(',');
        } else if (attribute.AttributeKey) {
          attribute.AttributeValue = attribute.AttributeValue || attribute.AttributeValue === 0
            ? attribute.AttributeValue.toString() : null;
        }
      });
    }
  }

}
