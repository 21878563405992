<div *ngFor="let data of dataNodes; let index = index; let last = last;">
    <div *ngIf="data.Permission.ShouldInclude" class="col-12 pt-pix-10 p-0 relative" [ngClass]="hasParent ? 'parent-div ps-3': 'p-0'"
        [ngStyle]="{ '--border-color': data.Permission.LevelColor }">
        <div class="row ms-0 p-pix-5 pe-pix-7 radius-5"
            [ngClass]="hasParent ? ((last || data.IsLastFilteredElement) ? 'last-permission' : 'child-permission') : ''"
            [class.bg-light-grey-f6]="index % 2 != 0" [ngStyle]="{ '--border-color': data.Permission.LevelColor }">
            <div class="col-md-7 col-8 ps-pix-5 pe-0"> 
                <div class="display-flex">
                    <label class="edit-form__section__label" [ngClass]="{'pointer': data.Children?.length }" (click)="closeExpanded(data)">
                        <pos-icon class="btn-expand" *ngIf="data.Permission.IsExpanded && data.Children?.length"
                            [icon]="icons.angleDown"></pos-icon>
                        <pos-icon class="btn-expand" *ngIf="!data.Permission.IsExpanded && data.Children?.length"
                            [icon]="icons.angleRight"></pos-icon>
                        <span class="ps-pix-5" [class.bold]="!data.Permission.IsPermissible"> {{data.Permission.Name}}
                        </span>

                        <span class="permission-id px-pix-2" *ngIf="data.Permission.IsPermissible"> {{data.Permission.Id}}
                        </span>

                        <pos-icon [icon]="icons.tagSm" *ngIf="data.Permission.Tags?.length" class="pointer ps-pix-5"
                            [pTooltip]="data.Permission.TagsList" tooltipStyleClass="font-12"
                            [escape]="false"></pos-icon>
                    </label>
                    <span *ngIf="data.Permission.IsPermissible" class="dots-border ms-pix-10"></span>
                </div>
                <p class="description grey" [ngClass]="data.Children?.length ? 'ps-pix-40' : 'ps-pix-5'">
                    {{data.Permission.Description}}</p>
            </div>
            <div class="col-md-5 col-4 p-0 display-flex" *ngIf="data.Permission.IsPermissible">
                <span class="dots-border me-pix-10"></span>
                <ng-select posInput *ngIf="data.Permission.AccessType === permissionTypes.Access" [items]="accessLevels"
                    bindLabel="Name" bindValue="Id" [(ngModel)]="data.PermissionLevel" appendTo="body"
                    [clearable]="false" class="col-150 pull-right" [name]="data.Permission.Id"
                    (change)="data.IsUpdated = true; updatePermissions(data);">
                </ng-select>

                <ng-select posInput *ngIf="data.Permission.AccessType === permissionTypes.Level" [items]="permissionLevels"
                    bindLabel="Name" bindValue="Id" [(ngModel)]="data.PermissionLevel" appendTo="body"
                    [clearable]="false" class="col-150 pull-right" [name]="data.Permission.Id"
                    (change)="data.IsUpdated = true; updatePermissions(data);">
                </ng-select>
            </div>
        </div>
        <div class="col-12 ps-pix-15" *ngIf="data.Children?.length">
            <pos-permission-node [ngClass]="{'display-none': !data.Permission.IsExpanded}" [dataNodes]="data.Children"
                [accessLevels]="accessLevels" [hasParent]="true"
                class="position-relative "
                [permissionLevels]="permissionLevels"></pos-permission-node>
        </div>
    </div>
</div>
