import { deleteWhite } from 'src/app/shared/components/icon/icons';
import { Input, OnChanges, SimpleChanges, Component, OnInit } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { InventoryProductDietaryWarningService } from 'src/app/shared/services/inventory-product-dietary-warnings.service';
import { DietaryWarning, DietaryWarningType, InventoryProductDietaryWarning } from '../../interface/inventory-product-dietary-warnings';
import * as _ from 'lodash';
import { cloneDeep, find, forEach } from 'lodash';
import { ControlContainer, NgForm } from '@angular/forms';
import { AlertsService } from 'src/app/shared/services';
import { SpinnerService } from 'src/app/shared/components';
import { DomainConstants, Messages } from 'src/app/shared/constants';
@Component({
    selector: 'pos-inventory-product-dietary-warnings',
    templateUrl: './inventory-product-dietary-warnings.component.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class InventoryProductDietaryWarningComponent implements OnInit, OnChanges {
    @Input() dietaryWarningInfo: Array<InventoryProductDietaryWarning> = [];
    @Input() inventoryProductId: number;
    public dietaryWarnings: Array<SelectItem> = [];
    public tempDietaryWarnings: Array<SelectItem> = [];
    public dietaryWarningTypes: Array<SelectItem> = [];
    public icons = { deleteWhite };
    constructor(
        private inventoryProductDietaryWarningService: InventoryProductDietaryWarningService,
        private alertService: AlertsService,
        private spinnerService: SpinnerService
    ) {
        this.dietaryWarnings = [];
        this.tempDietaryWarnings = [];
        this.dietaryWarningTypes = [];
    }

    ngOnInit() {
        this.getDietaryWarnings();
        this.getDietaryWarningTypes();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.disableSelectedDietaryWarning();
        if(changes['dietaryWarningInfo']) {
            this.setDefaultDietaryInfoType();
        }
    }
    getDietaryWarnings() {
        this.spinnerService.show();
        this.inventoryProductDietaryWarningService.getDietaryWarnings()
            .subscribe( {
                next: (response: DietaryWarning[]) => {
                    const listDietaryWarnings = response.map(function (data) {
                        return { label: data.Name, value: data.Id };
                    });
                    this.dietaryWarnings = listDietaryWarnings;
                    this.tempDietaryWarnings = _.cloneDeep(listDietaryWarnings);
                    this.disableSelectedDietaryWarning();
                },
                error: this.alertService.showApiError,
                complete: () => this.spinnerService.hide(),
            });
    }
    getDietaryWarningTypes() {
        this.spinnerService.show();
        this.inventoryProductDietaryWarningService.getDietaryWarningTypes()
            .subscribe({
                next: (response: DietaryWarningType[]) => {
                    const listDietaryWarningTypes = response.map(function (data) {
                        return { label: data.Name, value: data.Id };
                    });
                    this.dietaryWarningTypes = listDietaryWarningTypes;
                    this.setDefaultDietaryInfoType();
                },
                error: this.alertService.showApiError,
                complete: () => this.spinnerService.hide(),
            });
    }
    disableSelectedDietaryWarning() {
        forEach(this.dietaryWarnings, (item) => {
            const isAvailable = find(this.dietaryWarningInfo, (info) => info.DietaryWarningId == item.value);
            item.disabled = isAvailable ? true : false;
        });
    }

    createDietaryWarningRow(lastRow: boolean) {
        if (!lastRow) {
            return;
        }
        const paramsObj = {
            Id: 0,
            InventoryProductId: this.inventoryProductId,
            DietaryWarningId: null,
            DietaryWarningTypeId: null,
            DietaryWarning: {
                Id: 0, Name: null, DateAdded: null,
                DateEdited: null
            },
            DietaryWarningType: {
                Id: 0, Name: null, DateAdded: null,
                DateEdited: null
            },
            DateAdded: null,
            DateEdited: null
        };
        this.dietaryWarningInfo.push(paramsObj);
        this.setDefaultDietaryInfoType();
    }

    setDefaultDietaryInfoType() {
        const dietaryInfoLen = this.dietaryWarningInfo.length;
        if (dietaryInfoLen && !this.dietaryWarningInfo[dietaryInfoLen - 1].DietaryWarningTypeId && !this.dietaryWarningInfo[dietaryInfoLen - 1].DietaryWarningId ) {  // checking if dietary warning is set for double check.
        this.dietaryWarningInfo[dietaryInfoLen - 1].DietaryWarningTypeId 
            = this.dietaryWarningTypes.find( (x) => x.value == DomainConstants.DietaryWarningTypes.Allergen)?.value;
        }
    }

    clearDietaryWarningRow(data, index: number): void {
        if (data.Id > 0) {
            this.inventoryProductDietaryWarningService.deleteDietaryWarning(data.Id).subscribe(
                {
                    next: () => {
                        this.alertService.renderSuccessMessage(Messages.InventoryProductDietaryWarningDeleted);
                    }, error: this.alertService.showApiError
                });
        }
        const tempDietaryInfo = cloneDeep(this.dietaryWarningInfo);
        this.dietaryWarningInfo = [];
        tempDietaryInfo.splice(index, 1);
        setTimeout(() => {
            this.dietaryWarningInfo = tempDietaryInfo;
        });
        this.disableSelectedDietaryWarning();
    }
}
