<pos-edit-wrapper heading="{{selectedDeliveryType.description}}" (close)="onClose(false)">
    <form #djActivityEditForm="ngForm" autocomplete="off" (submit)='saveActivity(djActivityEditForm.valid)'>
        <div class="col-12 pos-modal-body screen-scroll">
            <pos-row>
                <pos-column>
                    <pos-form-field label="Terminals" [validationMessages]="{required: 'Please select terminal.'}"
                        inputStyle="col-md-10 col-12">
                        <ng-select posInput [items]="terminals" appendTo="body" bindLabel="TerminalName"
                            bindValue="TerminalId" [multiple]="true" placeholder="Select terminal(s)"
                            [(ngModel)]="selectedTerminals" [hideSelected]="true" name="selectedTerminals"
                            [required]="true" (change)="changeSelectedTerminals()">
                        </ng-select>
                    </pos-form-field>
                </pos-column>
                <pos-column>
                    <pos-form-field label="Speak Text" [validationMessages]="{required: 'Please add audio text.'}"
                        inputStyle="col-md-10 col-12">
                        <textarea posInput class="form-control" name="audioText" rows="5" #audioText
                            (click)="getCaretPos(audioText)" (keyup)="getCaretPos(audioText)"
                            [(ngModel)]="audioTextString" maxlength="2000" [required]="true"></textarea>
                        <span class="me-pix-20 mt-pix-n30 relative" style="float: right">
                            <span class="font-20 pointer grey">
                                <pos-icon [icon]="icons.messageCode" class="pointer"
                                    (click)="isOpenVariable = !isOpenVariable">
                                </pos-icon>
                            </span>
                            <div style="position: fixed; z-index: 10;" class="col-200" *ngIf="isOpenVariable">
                                <ng-select posInput [items]="eventAttributes" appendTo="body" placeholder="Select event variable"
                                    name="eventVariable" [(ngModel)]="selectedEventAttribute" class="dj-notification"
                                    (change)="changeEventAttribute()" [isOpen]="true">
                                </ng-select>
                            </div>
                        </span>
                    </pos-form-field>
                </pos-column>
                <pos-column>
                    <pos-form-field label="Audio Voice" [validationMessages]="{required: 'Please select audio voice.'}">
                        <ng-select posInput [items]="audioVoices" appendTo="body" placeholder="Select audio voice"
                            name="audioVoice" [(ngModel)]="selectedAudioVoice" [required]="true">
                        </ng-select>
                    </pos-form-field>
                </pos-column>
                <pos-column>
                    <pos-form-field label="Audio Volume">
                        <pos-row class="mx-0">
                            <pos-column xs="11" class="ps-0">
                                <ngx-slider [(value)]="volume" [options]="options"></ngx-slider>
                            </pos-column>
                            <pos-column xs="1" class="p-0">
                                <span class="badge mt-pix-2 volume-circle">{{volume}}</span>
                            </pos-column>
                        </pos-row>
                    </pos-form-field>
                </pos-column>
                <pos-column>
                    <pos-repeat-action-configuration
                        [(eventSubscriptionActionAttributes)]="eventSubscriptionActionAttributes"
                        [isFormSubmitted]="djActivityEditForm.submitted" #RepeatActionConfiguration
                        [isRecoverableEvent]="isRecoverableEvent">

                    </pos-repeat-action-configuration>
                </pos-column>
            </pos-row>
        </div>
        <div class="col-12 action-button-container popup-footer">
            <button type="submit" class="btn-primary btn-lg btn btn-save pos-button">Save</button>
            <button type="button" (click)="onClose(false)"
                class="btn-secondary btn-lg btn btn-cancel-modal pos-button">Cancel</button>
        </div>
    </form>
</pos-edit-wrapper>
