import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import { GeneralWorkflowParameter } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class GeneralWorkflowService {

  generalWorkflowApiBasePath = `${RuntimeConstants.API_PATH}general-workflow-configuration`;
  constructor(private httpService: HttpClient) { }

  newGeneralWorkflowConfigurationParameter(): GeneralWorkflowParameter {
    return {
      IsGlobalShutoff: false,
      WeekStart: "",
      PhoneNumberMask: '(000) 000-0000',
      ResetMenuDisplayFilterSeconds: 5,
      ManualReleaseMaxFileCount: 20,
      AutoOpenMessageTimeout: 0,
    };
  }

  getGeneralWorkflowParameters(): Observable<GeneralWorkflowParameter> {
    return this.httpService.get<GeneralWorkflowParameter>(`${this.generalWorkflowApiBasePath}`);
  }

  updateGeneralWorkflowParameters(generalWorkflowParameter: GeneralWorkflowParameter) {
    return this.httpService.post<GeneralWorkflowParameter>(`${this.generalWorkflowApiBasePath}`, generalWorkflowParameter);
  }
}
