<pos-kiosk-header *ngIf="screenConfig.Header.IsShow" [header]="screenConfig.Header" />
<div class="row mx-0 designer-area" [style.background-color]="screenConfig.BackgroundColor"
    [style.background-image]="screenConfig.BackgroundImage ? 'url(\''+ imageRootPath + screenConfig.BackgroundImage + '\')' : null">
    <div class="overflow-auto d-flex p-0" [ngClass]="screenConfig.ShowInvoice ? 'col-xl-9 col-8' : 'col-12'"
        [style.height]="containerHeight + 'px'">
        <div [ngStyle]="{'width': rightPanelConfig.Width ? 'calc(100% - '+ rightPanelConfig.Width +')' : '75%'}">
            <div class="d-flex h-100 relative" *ngIf="leftPanelConfig"
                [style.align-items]="leftPanelConfig.Alignment?.Vertical"
                [style.justify-content]="leftPanelConfig.Alignment?.Horizontal">
                <div class="d-flex"
                    [ngStyle]="{'top': leftPanelConfig.VerticalPosition + 'px', 'position': 'absolute' }">
                    <div class="grid-column pointer" (click)="buttonClick(screenButton, true)"
                        *ngFor="let screenButton of leftPanelButtons" [ngStyle]="{'height': leftPanelConfig.Height ? leftPanelConfig.Height + 'px' : '50px',
                'width': leftPanelConfig.Width ? leftPanelConfig.Width + 'px' : '', 'min-width': leftPanelConfig.Width ? leftPanelConfig.Width + 'px' : '100px',
                'border-color': screenButton?.Button?.BorderColor, 'background-color': screenButton?.Button?.BackgroundColor,
                'border-radius': leftPanelConfig.Radius + 'px', '--rotation-degree': leftPanelConfig.RotationDegree + 'deg',
                '--bg-opacity': leftPanelConfig.Opacity ?? 1 }"
                        [class.kiosk-bg-light-grey]="isDesignMode || (screenButton.Button && !screenButton.Button.BackgroundColor)"
                        [ngClass]="{'active': screenButton?.Button?.IsSelected, 'searched': screenButton?.Button?.IsSearched, 'filtered-product': screenButton?.Button?.IsFilteredByAllergens, 'out-of-stock': screenButton?.Button?.SalesProduct && !screenButton.Button.SalesProduct.IsInStock && !screenButton?.Button?.IsFilteredByAllergens, 'inactive-product': screenButton?.Button?.SalesProduct && !screenButton.Button.SalesProduct.IsActive }">
                        <pos-icon class="plus-icon" *ngIf="!screenButton?.Button && isDesignMode"
                            [icon]="icons.plusMessage"></pos-icon>
                        <div class="config-button">
                            <pos-kiosk-screen-setup [screenButton]="screenButton" [screenButtons]="leftPanelButtons"
                                (screenButtonsChange)="emitScreenButtonsChange($event)" [isDesignMode]="isDesignMode"
                                [isAllowDelete]="true"></pos-kiosk-screen-setup>
                        </div>
                        <div class="button-text text-center" *ngIf="screenButton?.Button" [innerHTML]="screenButton.Button.ButtonText | uppercase"
                            [ngStyle]="{'color': screenButton.Button.TextColor, 'font-size': screenButton.Button.FontSize }"></div>

                    </div>
                    <pos-action-icon [icon]="icons.addKioskButton" iconText="Add" *ngIf="isDesignMode"
                        class="text-nowrap mb-pix-10" (click)="addKioskScreenChoice()"></pos-action-icon>
                </div>
            </div>
        </div>

        <div class="right-panel-config p-3 m-2 border-1"
            [ngStyle]="{'width': rightPanelConfig.Width ? rightPanelConfig.Width : '25%'}">
            <div class="right-panel-header">
                <div class="right-panel-title" [style.height]="rightPanelConfig.Header.Height + 'px'">
                    <div class="right-panel-title-text" [ngStyle]="{'background-color': rightPanelConfig.Header.BackgroundColor, 'color': rightPanelConfig.Header.FontColor, 'font-size': rightPanelConfig.Header.FontSize 
                         }" [innerHTML]="rightPanelConfig.Header?.Title"></div>
                </div>
                <div class="right-panel-image"
                    [ngStyle]="{'--header-bg-color': rightPanelConfig.Header.BackgroundColor, 'height': rightPanelConfig.Header.ImageHeight ? rightPanelConfig.Header.ImageHeight +'px' : '', '--image-radius': rightPanelConfig.Header.ImageRadius +'px' }">
                    <img *ngIf="rightPanelConfig.Header.Image" [src]="imageRootPath + rightPanelConfig.Header.Image" />
                </div>
            </div>
            <pos-action-icon [icon]="icons.addKioskButton" iconText="Add" *ngIf="isDesignMode"
                class="text-nowrap text-right my-pix-10" (click)="addKioskScreenChoice(false)"></pos-action-icon>
            <div *ngIf="rightPanelConfig" class="overflow-auto flex-fill right-panel-buttons"
                [ngStyle]="{'justify-content': rightPanelConfig.Alignment?.Vertical}">
                <div class="grid-column pointer mxi-0" (click)="buttonClick(screenButton)"
                    *ngFor="let screenButton of rightPanelButtons; let i = index;" [ngStyle]="{'height': rightPanelConfig.Height ? rightPanelConfig.Height + 'px' : '50px',
                    'min-height': rightPanelConfig.Height ? rightPanelConfig.Height + 'px' : '50px',
                'border-color': screenButton?.Button?.BorderColor, 'background-color': screenButton?.Button?.BackgroundColor,
                '--border-radius': rightPanelConfig.Radius + 'px',
                '--bg-opacity': rightPanelConfig.Opacity ?? 1 }"
                    [class.kiosk-bg-light-grey]="isDesignMode || (screenButton.Button && !screenButton.Button.BackgroundColor)"
                    [ngClass]="{'active': screenButton?.Button?.IsSelected, 'searched': screenButton?.Button?.IsSearched, 'filtered-product': screenButton?.Button?.IsFilteredByAllergens, 'out-of-stock': screenButton?.Button?.SalesProduct && !screenButton.Button.SalesProduct.IsInStock && !screenButton?.Button?.IsFilteredByAllergens, 'inactive-product': screenButton?.Button?.SalesProduct && !screenButton.Button.SalesProduct.IsActive }">
                    <pos-icon class="plus-icon" *ngIf="!screenButton?.Button && isDesignMode"
                        [icon]="icons.plusMessage"></pos-icon>
                    <div class="config-button">
                        <pos-kiosk-screen-setup [screenButton]="screenButton" [screenButtons]="rightPanelButtons"
                            [buttonsDesignConfig]="rightPanelButtonConfig" [setupItems]="setupItems"
                            (screenButtonsChange)="emitScreenButtonsChange($event, false)" [buttonIndex]="i"
                            [isDesignMode]="isDesignMode" [isAllowDelete]="true"></pos-kiosk-screen-setup>
                    </div>
                    <div class="button-text" *ngIf="screenButton?.Button" [innerHTML]="screenButton.Button.ButtonText | uppercase"
                        [ngStyle]="{'color': screenButton.Button.TextColor, 'font-size': screenButton.Button.FontSize }"></div>
                    <div class="button-image" *ngIf="screenButton?.Button && buttonsDesign?.[i]?.Icon"
                        [ngStyle]="{'background-color': buttonsDesign?.[i]?.IconBackground, '--image-border-radius': rightPanelConfig.Radius - 2 + 'px'}">
                        <img alt="" src="{{imageRootPath}}buttons/{{buttonsDesign[i].Icon}}" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-xl-3 col-4 p-3" *ngIf="screenConfig.ShowInvoice" [ngStyle]="{'height': invoiceHeight - 3 + 'px'}">
        <pos-kiosk-invoice></pos-kiosk-invoice>
    </div>
</div>
<pos-kiosk-footer *ngIf="screenConfig.Footer.IsShow" [buttons]="screenButtons"
    [footer]="screenConfig.Footer"></pos-kiosk-footer>
