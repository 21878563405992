
import { InventoryScheduleEditorService } from './services/inventory-schedule-editor.service';
import { AlertsService, ApplicationStateService } from 'src/app/shared/services';
import { GridColumn, TemplateColumn, DateColumn, TextAlign, TableComponent } from '@tarktech/tark-ng-utils';
import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import * as _ from 'lodash';
import { InventorySchedule } from './interface/inventory-schedule';
import { Messages } from 'src/app/shared/constants/ui-messages';
import { ActivatedRoute, Router } from '@angular/router';
import { Format } from '@tarktech/tark-ng-utils/table/format-type';
import { DomainConstants } from 'src/app/shared/constants/domain-constants';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { BaseGridComponent } from 'src/app/shared/components/base-grid.component';
import { inventoryScheduleEditor } from 'src/app/shared/components/icon/icons';
declare let $: any;

@Component({
  selector: 'pos-inventory-schedule-editor',
  templateUrl: './inventory-schedule-editor.component.html',
  styleUrls: ['./inventory-schedule-editor.component.scss']
})
export class InventoryScheduleEditorComponent extends BaseGridComponent implements OnInit {

  selectedFunction: any = 'Date';
  startDate: Date;
  minDate: Date;
  inventoryLogOrBaselineDate: Date;
  subcategories: Array<any> = [];
  vendors: Array<any> = [];
  zones: Array<any> = [];
  subcategoryId: number = null;
  vendorId: number = null;
  zoneId: number = null;
  inventoryScheduleOption = DomainConstants.InventoryScheduleOptions;
  inventoryProducts: Array<InventorySchedule> = [];
  filteredInventoryProducts: Array<InventorySchedule> = [];
  inventoryScheduleColumns: Array<GridColumn> = [];
  @ViewChild('chkTemplate', {static: true}) private chkTemplate: any;
  @ViewChild('inventoryScheduleForm')
  inventoryScheduleForm: NgForm;
  @ViewChild('inventoryScheduleGrid', {static: true}) inventoryScheduleGrid: TableComponent;
  @ViewChild('headerTemplate', {static: true}) headerTemplate: TemplateRef<any>;
  isCheckedAll = false;
  dateFormat = 'mm-dd-yyyy';
  icons = { inventoryScheduleEditor }
  props = {
    labelClass:'',
    controlBoxClass:"col-12"
  }
  inventoryScheduleDateOptions = DomainConstants.InventoryScheduleDateOptions;
  selectedDateOption = DomainConstants.InventoryScheduleDateOptions.NextInventoryDate;
  constructor(private alertService: AlertsService,
    private spinnerService: SpinnerService,
    protected applicationStateService: ApplicationStateService,
    protected route: ActivatedRoute,
    private inventoryScheduleEditorService: InventoryScheduleEditorService,
    private router: Router
  ) {
    super(applicationStateService, route);
  }

  ngOnInit() {
    this.startDate = new Date();
    this.minDate = new Date();
    this.getFilterData();
    this.changeFilter();
    this.columnConfiguration();
    this.dateFormat = this.applicationStateService.settingParam.PCalendarDateFormat;
  }

  getFilterData() {
    this.inventoryScheduleEditorService.getInventorySubcategory()
      .subscribe(
        response => {
          if (response) {
            this.subcategories = response;
          } else {
            this.subcategories = [];
          }
        },
        this.alertService.showApiError,
        () => { }
      );
    this.inventoryScheduleEditorService.getInventoryVendors()
      .subscribe(
        response => {
          if (response) {
            this.vendors = response;
          } else {
            this.vendors = [];
          }
        },
        this.alertService.showApiError,
        () => { }
      );
    this.inventoryScheduleEditorService.getInventoryZones()
      .subscribe(
        response => {
          if (response) {
            this.zones = response;            
          } else {
            this.zones = [];
          }
        },
        this.alertService.showApiError,
        () => { }
      );
  }


  getInventoryProduct() {
    const obj = this.prepareRequestData();
    this.isCheckedAll = false;
    if (this.selectedFunction == this.inventoryScheduleOption.ByDate ||
      (obj.SubcategoryId != null || obj.VendorId != null || obj.ZoneId != null)) {
      this.spinnerService.show();
      this.inventoryScheduleEditorService.getInventoryProduct(obj)
        .subscribe(
          response => {
            if (response) {
              this.inventoryProducts = response;
            } else {
              this.inventoryProducts = [];
            }
          },
          this.alertService.showApiError,
          () => {
            this.spinnerService.hide();
          }
        );
    }
  }

  getHeight = function () {
    return $(window).height() - 250 + 'px';
  };

  changeFilter() {
    this.startDate = new Date;
    this.subcategoryId = null;
    this.vendorId = null;
    this.zoneId = null;
    this.inventoryProducts = [];
    this.inventoryScheduleGrid.reset();
    this.getInventoryProduct();
  }

  prepareRequestData() {
    const obj = {
      NextScheduleLogDate: null,
      SubcategoryId: null,
      VendorId: null,
      ZoneId: null
    };
    if (this.selectedFunction === this.inventoryScheduleOption.ByDate) {
      obj.NextScheduleLogDate = this.startDate ? this.startDate : new Date;
    } else if (this.selectedFunction === this.inventoryScheduleOption.BySubcategory) {
      obj.SubcategoryId = this.subcategoryId;
    } else if (this.selectedFunction === this.inventoryScheduleOption.ByVendor) {
      obj.VendorId = this.vendorId;
    } else if (this.selectedFunction === this.inventoryScheduleOption.ByZone) {
      obj.ZoneId = this.zoneId;
    }
    return obj;
  }

  scheduleGridFilter(event) {
    this.isCheckedAll = false;
    if (!event.filters.global) {
      this.filteredInventoryProducts = [];
    } else {
      this.filteredInventoryProducts = [...event.filteredValue];
    }
  }

  selectAll(isCheckedAll) {
    const selectedInventoryProducts: Array<InventorySchedule> = (!this.filteredInventoryProducts.length ?
      this.inventoryProducts : this.filteredInventoryProducts);
    _.forEach(selectedInventoryProducts, (inventoryProduct) => {
      inventoryProduct.Update = isCheckedAll;
    });
  }

  private columnConfiguration() {

    const chkColumn = new TemplateColumn({
      itemTemplate: this.chkTemplate,
      Width: '5%',
      TextAlign: TextAlign.Center,
      headerTemplate: this.headerTemplate
    });

    this.inventoryScheduleColumns = [
      new GridColumn({ HeaderText: 'Name', Field: 'Name', IsSortable: true, Width: '10%' }),
      new GridColumn({ HeaderText: 'Category', Field: 'CategoryName', IsSortable: true, Width: '15%' }),
      new GridColumn({ HeaderText: 'Vendor', Field: 'VendorName', IsSortable: true, Width: '8%' }),
      new GridColumn({ HeaderText: 'Primary Zone', Field: 'PrimaryZone', IsSortable: true, Width: '10%' }),
      new GridColumn({ HeaderText: 'Secondary Zone', Field: 'SecondaryZone', IsSortable: true, Width: '10%' }),
      new DateColumn({ HeaderText: 'Next Schedule Date', Format: Format.Date, Field: 'LogDueDateOverride',
        IsSortable: true, Width: '7%' }),
      new DateColumn({ HeaderText: 'Baseline Date', Format: Format.Date, Field: 'InventoryBaselineDate',
        IsSortable: true, Width: '7%' }),
      chkColumn
    ] as Array<GridColumn>;
  }

  applyDates(isValid: boolean) {
    if (!isValid) {
      return;
    }
    const products: any = _.filter(this.inventoryProducts, (product) => {
      if (product.Update) {
        if(this.selectedDateOption === this.inventoryScheduleDateOptions.NextInventoryDate){
          product.LogDueDateOverride = this.inventoryLogOrBaselineDate;
          product.InventoryFieldToUpdate = this.inventoryScheduleDateOptions.NextInventoryDate;
        }
        else if(this.selectedDateOption === this.inventoryScheduleDateOptions.BaseLineDate){
          product.InventoryBaselineDate = this.inventoryLogOrBaselineDate;
          product.InventoryFieldToUpdate = this.inventoryScheduleDateOptions.BaseLineDate;
        }
      }
      return product.Update;
    });
    if (products && products.length > 0) {
      this.spinnerService.show();
      this.inventoryScheduleEditorService.updateProduct(products)
        .subscribe(
          response => {
            this.alertService.renderSuccessMessage(Messages.UpdateInventoryNextScheduleDate);
            this.getInventoryProduct();
          },
          this.alertService.showApiError,
          () => {
            this.spinnerService.hide();
          }
        );
    }
  }

  // onSelectDate() {
  //   this.getInventoryProduct();
  // }

  onClear() {
    this.inventoryScheduleForm.reset();
    this.inventoryScheduleForm.resetForm();
    this.selectedFunction = 'Date';
    this.selectedDateOption = DomainConstants.InventoryScheduleDateOptions.NextInventoryDate;
    this.startDate = new Date;
    this.subcategoryId = null;
    this.vendorId = null;
    this.zoneId = null;
    this.inventoryLogOrBaselineDate = null;
    this.inventoryScheduleGrid.reset();
    this.getInventoryProduct();
  }

  cancelEditing() {
    this.router.navigate(['../'], {relativeTo: this.route});
  }
}
