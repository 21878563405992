import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderSummaryTotalComponent } from './order-summary-total/order-summary-total.component';
import { OrderSummaryProductsComponent } from './order-summary-products/order-summary-products.component';
import { ComponentsModule, TableConfiguration } from '../shared';
import { TableModule } from '@tarktech/tark-ng-utils';
import { TooltipModule } from 'primeng/tooltip';

@NgModule({
  imports: [
    CommonModule,
    ComponentsModule,
    TooltipModule,
    TableModule.forRoot(TableConfiguration)
  ],
  declarations: [OrderSummaryTotalComponent, OrderSummaryProductsComponent],
  exports: [OrderSummaryTotalComponent, OrderSummaryProductsComponent]
})
export class OrderSummaryModule { }
