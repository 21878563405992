<pos-edit-wrapper heading="Confirm" (close)="cancel()">
    <div class="modal-body px-pix-20">
        <pos-inventory-log-stock-sales-product-warning [salesInventoryProducts]="salesInventoryProducts">
        </pos-inventory-log-stock-sales-product-warning>
    </div>
    <div class="col-12 action-button-container popup-footer pb-pix-10">
        <button type="submit" class="btn btn-primary btn-lg btn-save pos-button"
            (click)="updateInventoryQty()">Continue</button>
        <button type="button" class="btn btn-secondary btn-lg btn-cancel-modal pos-button"
            (click)="cancel()">Cancel</button>
    </div>
</pos-edit-wrapper>
