<pos-edit-wrapper heading="{{selectedDeliveryType.description}}" (close)="onClose(false)">
    <form #disableKioskEditForm="ngForm" autocomplete="off"
        (submit)='saveActivity(disableKioskEditForm.valid)'>
        <context-provider provide="props">
            <div class="col-12 pos-modal-body screen-scroll">
                <pos-row>
                    <pos-column>
                        <pos-form-field label="Terminals" [validationMessages]="{required: 'Please select terminal.'}">
                            <ng-select posInput [items]="terminals" appendTo="body" bindLabel="TerminalName"
                                bindValue="TerminalId" [multiple]="true" placeholder="Select terminal"
                                [(ngModel)]="selectedTerminals" (change)="changeSelectedTerminals()"
                                name="selectedTerminals" [hideSelected]="true" [required]="true">
                            </ng-select>
                        </pos-form-field>
                    </pos-column>

                    <pos-column>
                        <pos-form-field label="State">
                            <div class="btn-group">
                                <label class="btn btn-radio"
                                    [ngClass]="enableStatus == 'Enable' ? 'active btn-primary font-nobold' : 'btn-secondary'">
                                    <input [(ngModel)]="enableStatus" type="radio" value="Enable" name="Enable"> Enable
                                </label>
                                <label class="btn btn-radio"
                                    [ngClass]="enableStatus == 'Disable' ? 'active btn-primary font-nobold' : 'btn-secondary'">
                                    <input [(ngModel)]="enableStatus" type="radio" value="Disable" name="Disable"> Disable
                                </label>
                            </div>
                        </pos-form-field>
                    </pos-column>
                    <pos-column>
                         <pos-repeat-action-configuration [(eventSubscriptionActionAttributes)]="eventSubscriptionActionAttributes"                
                             [isFormSubmitted]="disableKioskEditForm.submitted" #RepeatActionConfiguration [isRecoverableEvent]="isRecoverableEvent">
                           </pos-repeat-action-configuration>
                     </pos-column>
                </pos-row>
            </div>
            <div class="col-12 action-button-container popup-footer">
                <button type="submit" class="btn-primary btn-lg btn btn-save pos-button">Save</button>
                <button type="button" (click)="onClose(false)" class="btn-secondary btn-lg btn btn-cancel-modal pos-button"
                    value="Cancel">Cancel</button>
            </div>
        </context-provider>
    </form>
</pos-edit-wrapper>
