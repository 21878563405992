import { Component, OnInit, ViewChild } from '@angular/core';
import { TerminalSummary } from '../../interface/terminal-summary';
import {
  SpinnerService, AlertsService, DomainConstants, AuthenticationService, Levels, ApplicationStateService,
  Messages, RuntimeConstants, RabbitMQService, HttpStatusCodes, EventBroadcastingService, ModalBackdropService, InfoModalComponent, ModalService
} from 'src/app/shared';
import { TerminalsService } from 'src/app/configurator';
import { finalize } from 'rxjs/operators';
import * as _ from 'lodash';
import { KeyValue } from '@angular/common';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { userImpersonate } from 'src/app/shared/components/icon';
import { ApplicationLoginService } from '../../../shared/services/application-login.service';
import { CameraAnnotationService, PermissionService } from '../../../shared/services';
import { MainService } from '../../../header-entry/services/main.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Router } from '@angular/router';
import { ReloadSettingsService } from 'src/app/shared/services/reload-settings.service';
import { ApplicationFeaturesService } from 'src/app/shared/services/application-features.service';
declare let $: any;
@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'pos-terminal-selection',
  templateUrl: './terminal-selection.component.html',
  styleUrls: ['./terminal-selection.component.scss', '../../../../styles/pages/login.scss']
})
export class TerminalSelectionComponent implements OnInit {
  mappedTerminalsWithIp = [];
  localIpAddress: Array<string> = [];
  terminalUseResponseSubscription: Subscription;
  imagePath = RuntimeConstants.IMAGE_BASE_PATH;
  terminalList: Array<TerminalSummary> = [];
  terminalSelectionList: any = []; // TerminalSummary
  mostRecentTerminalId: any;
  mostRecentTerminalDetails: TerminalSummary;
  isImpersonateUser = false;
  userHasPermission = true;
  terminalAccessDenied = '';
  styleClasses = '';
  terminalTypes: any;
  selectedTerminalId: number;
  responseFromTerminal = false;
  requestedTerminal: TerminalSummary;
  dashboardTerminalId: number;
  waitListTerminalId: number;
  textContent = 0;
  progress;
  currentTerminalTypeUsedCount: number = 0;
  modalRefList = [];
  isRecentTerminalInLinkedTerminals: boolean;
  terminalUsedRef: BsModalRef;
  terminalProcessingMessage: string;
  icon = {
    userImpersonate,
  };
  @ViewChild('loaderModal') public loaderModal: any;
  @ViewChild('terminalUsedModal') public terminalUsedModal: any;
  constructor(private alertService: AlertsService,
    private spinnerService: SpinnerService,
    private terminalService: TerminalsService,
    private authenticationService: AuthenticationService,
    private applicationStateService: ApplicationStateService,
    private applicationLoginService: ApplicationLoginService,
    private rabbitMQService: RabbitMQService,
    private router: Router,
    private bsModalService: BsModalService,
    private modalService: ModalService,
    private modalBackdropService: ModalBackdropService,
    private eventBroadcastingService: EventBroadcastingService,
    private permissionService: PermissionService,
    private mainService: MainService,
    private reloadSettingsService: ReloadSettingsService,
    private applicationFeatureService: ApplicationFeaturesService,
    private cameraAnnotationService: CameraAnnotationService
  ) {
    this.terminalTypes = DomainConstants.TerminalTypes;
  }

  ngOnInit() {
    this.getTerminal();
    this.isImpersonateUser = this.applicationStateService.isImpersonateUser;
    this.terminalAccessDenied = Messages.ErrorWhileUserNotHaveAnyTerminalAccessPermission;
    this.terminalProcessingMessage = Messages.ConnectingToSystem;
    this.subscribeSignInAsTerminalEvent();
  }
  checkImpersonateUser(terminals) {
    if (this.isImpersonateUser) {
      this.spinnerService.show();
      this.applicationLoginService.getLoginById(this.applicationStateService.userId)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        })).subscribe({
          next: (response: Array<TerminalSummary>) => {
            if (response) {
              this.loginCompleted(response, terminals);
            }
          }, error: this.alertService.showApiError
        });
    } else {
      this.userHaveTerminalAccessPermission();
      this.terminalList = terminals;
      this.setLicenseToTerminalList();
      this.prepareTerminalSelection();
      this.loadTerminalCSS();
    }
  }

  private loginCompleted(res: any, terminals: any): void {
    this.spinnerService.show();
    const loginObservables = [];
    const userDetails = res.UserTerminalDetails.UserDetails;
    if (userDetails) {
      this.applicationStateService.userId = userDetails.id;
      this.applicationStateService.cookieUserId = userDetails.id;
      this.applicationStateService.userDetails = userDetails;
      this.applicationStateService.sessionApplicationMode = 'application';
    }
    loginObservables.push(this.permissionService.getUserPermission(userDetails.id));
    loginObservables.push(this.mainService.getApplicationVersion());
    loginObservables.push(this.mainService.initializeApplication());
    loginObservables.push(this.authenticationService.getLocalIpAddress());
    forkJoin(loginObservables).pipe(finalize(() => {
      this.spinnerService.hide();
    }))
      .subscribe({
        next: (response: any) => {
          if (res && response) {
            const permissionData = response[0] ? response[0] : null;
            const appVersion = response[1] && response[1].CurrentVersion ? response[1].CurrentVersion : null;
            const licenseInfo = response[2] && response[2].LicenseInfo ? response[2].LicenseInfo : null;
            const config = this.applicationStateService.applicationInitializationConfigurations;
            config.CurrentVersion = appVersion;
            this.applicationStateService.applicationInitializationConfigurations = config;
            this.applicationStateService.userPermissions = permissionData;
            this.applicationStateService.licenseInfo = licenseInfo;
            this.userHaveTerminalAccessPermission();
            this.terminalList = terminals;
            this.setLicenseToTerminalList();
            this.localIpAddress = response[3] ? response[3] : null;
            this.prepareTerminalSelection();
            this.loadTerminalCSS();
          }
        }, error: this.handelLoginError
      });
  }

  getTerminal() {
    this.spinnerService.show();
    const loginObservables = [];
    loginObservables.push(this.terminalService.getTerminalSummary());
    loginObservables.push(this.authenticationService.getLocalIpAddress());
    forkJoin(loginObservables).pipe(finalize(() => {
      this.spinnerService.hide();
    })).subscribe({
      next: (responses: any) => {
        if (responses?.length) {
          this.localIpAddress = responses[1] ? responses[1] : null;
          this.checkImpersonateUser(responses[0]);
        }
      }, error: this.alertService.showApiError
    });
  }

  prepareTerminalSelection() {
    if (!this.applicationStateService.cookieUserId) {
      this.mostRecentTerminalId = null;
    } else {
      this.mostRecentTerminalId = this.applicationStateService.cookieId;
    }
    this.terminalList = this.terminalList.filter(x => x.TerminalType != DomainConstants.TerminalTypes.NOTIFICATION.Name)
    _.forEach(this.terminalList, (terminal) => {
      _.forEach(DomainConstants.TerminalTypes, (type) => {
        if (type.Name === terminal.TerminalType) {
          terminal.Code = type.Code;
          terminal.Image = type.Image;
          terminal.Icon = type.Icon;
        }
      });
    });

    // show only order entry terminal if redirect link is set.
    if (sessionStorage.getItem('redirectRoute')) {
      this.terminalList = this.terminalList.filter(x => x.TerminalType == DomainConstants.TerminalTypes.ORDER_ENTRY.Name);
    }

    this.mostRecentTerminalDetails = _.find(this.terminalList, (terminal) => {
      return terminal.TerminalId === this.mostRecentTerminalId && terminal.IsEnabled;
    });

    if (this.mostRecentTerminalDetails && !this.userHavePermissionForTerminalType(this.mostRecentTerminalDetails.TerminalType)) {
      this.mostRecentTerminalDetails = null;
    }

    _.remove(this.terminalList, (terminal) => {
      if (!this.userHavePermissionForTerminalType(terminal.TerminalType) || !terminal?.IsEnabled) {
        return terminal;
      }
    });

    this.applicationStateService.recentTerminalDetails = this.mostRecentTerminalDetails;
    this.terminalSelectionList = _.groupBy(this.terminalList, (terminal) => {
      if (this.userHavePermissionForTerminalType(terminal.TerminalType)) {
        return terminal.TerminalType;
      }
    });
    this.loginToMappedTerminal();
  }

  loginToMappedTerminal = () => {
    const mappedTerminal = _.filter(this.terminalList, (terminal: TerminalSummary) => {
      return this.localIpAddress.includes(terminal.MappedIpAddress);
    });
    if (mappedTerminal?.length) {
      if (mappedTerminal[0].MappedIpAddressLevel) {
        this.saveTerminalEvents(mappedTerminal[0]);
      } else {
        this.mappedTerminalsWithIp = mappedTerminal;
      }
    }

    this.isRecentTerminalInLinkedTerminals = _.find(this.mappedTerminalsWithIp, (t) => {
      return t.TerminalId === this.mostRecentTerminalDetails?.TerminalId;
    });
  }

  originalOrder = (firstKey: KeyValue<number, string>, secondKey: KeyValue<number, string>): number => {
    return 0;
  }

  subscribeSignInAsTerminalEvent() {
    this.eventBroadcastingService.signInAsTerminal.subscribe((res) => {
      this.saveTerminalEvents(this.applicationStateService.recentTerminalDetails, false);
    });
  }

  closeLoaderModel = (name) => {
    const loaderModalRef = _.find(this.modalRefList, (ref) => {
      return ref.Name == name;
    });
    if (loaderModalRef) {
      loaderModalRef.ModalRef.hide();
      this.modalBackdropService.removeBackdrop();
      this.clearProgressInterval();
    }
  }

  saveTerminalEvents(terminal: TerminalSummary, isShowModal: boolean = true) {
    this.currentTerminalTypeUsedCount = 0;
    this.applicationStateService.cookieId = terminal.TerminalId;
    this.applicationStateService.terminalId = terminal.TerminalId;
    this.applicationStateService.terminalType = terminal.TerminalType;
    this.applicationStateService.cookieTerminalType = terminal.TerminalType;
    this.selectedTerminalId = terminal.TerminalId;
    this.applicationStateService.cookieTerminalName = terminal.TerminalName;
    this.responseFromTerminal = false;
    this.requestedTerminal = terminal;
    const terminalDetails = {
      TerminalId: terminal.TerminalId,
      TerminalName: terminal.TerminalName,
      TerminalType: terminal.TerminalType
    };
    if (isShowModal) {
      console.log('Loader model open from saveTerminalEvents()');
      const modalRef = this.bsModalService.show(this.loaderModal, {
        backdrop: true,
        ignoreBackdropClick: true,
        class: 'vertical-center sm-modal-width',
        'keyboard': false
      });
      this.modalBackdropService.addBackDrop();
      _.remove(this.modalRefList, (model) => model.Name == 'saveTerminalEvents');
      this.modalRefList.push({ ModalRef: modalRef, Name: 'saveTerminalEvents' });
      this.progressBar();
    } else {
      this.spinnerService.show();
    }
    if (terminal.TerminalType === this.terminalTypes.MANAGEMENT_DASHBOARD.Name) {
      this.dashboardTerminalId = terminal.TerminalId;
    }
    if (terminal.TerminalType === this.terminalTypes.WAITLIST.Name) {
      this.waitListTerminalId = terminal.TerminalId;
    }
    this.applicationLoginService.checkTerminalName(terminalDetails).pipe(finalize(() => {
      if (!isShowModal) {
        this.spinnerService.hide();
      }
    })).subscribe({
      next: (response: Array<TerminalSummary>) => {
        if (response) {
          if (!isShowModal) {
            this.spinnerService.show();
          }
          setTimeout(() => {
            const selectedTerminalTypeFeature = _.find(this.applicationStateService.applicationFeatures, (feature) => {
              return feature.Name === this.requestedTerminal.TerminalType;
            });

            if (selectedTerminalTypeFeature?.Configuration?.Count <= this.currentTerminalTypeUsedCount) {

              this.modalService.show(InfoModalComponent, {
                animated: false,
                class: 'vertical-center',
                backdrop: true,
                initialState: {
                  message: `Can not log in. Your subscription supports no more than ${selectedTerminalTypeFeature?.Configuration.Count} ${this.requestedTerminal.TerminalType} terminals at a time.`
                }
              });
              this.modalBackdropService.addBackDrop();
              this.closeLoaderModel('saveTerminalEvents');
              this.terminalModalClose();
            } else {
              this.usedInTerminalResponse(this.responseFromTerminal, isShowModal);
            }
            if (!isShowModal) {
              this.spinnerService.hide();
            }
          }, 4000);
        }
      }, error: () => this.alertService.renderErrorMessage(Messages.UnexpectedErrorOccurred)
    });
    if (this.terminalUseResponseSubscription) {
      this.terminalUseResponseSubscription.unsubscribe();
    }
    this.subscribeToTerminalInUseQueue(this.selectedTerminalId);
  }


  private subscribeToTerminalInUseQueue(terminalId: number): void {
    this.terminalUseResponseSubscription = this.rabbitMQService.subscribeToTerminalUseResponseMessage$(terminalId)
      .subscribe({
        next: (message: any) => {
          if (message.Payload.TerminalId === terminalId) {
            this.responseFromTerminal = true;
          } else {
            if (this.requestedTerminal.TerminalType === message.Payload.TerminalType) {
              this.currentTerminalTypeUsedCount++;
            }
          }
        }, error: () => {
          console.log('error while connecting to RabbitMQ.');
        }
      });
  }

  usedInTerminalResponse(responseFromTerminal, isShowModal) {
    if (!responseFromTerminal) {
      this.saveTerminalEventLog('saveTerminalEvents', isShowModal);
    } else {
      console.log('Loader model hide from logoutTheUsedTerminal()');
      this.terminalUsedRef = this.bsModalService.show(this.terminalUsedModal, {
        animated: false,
        backdrop: true,
        class: 'vertical-center',
        keyboard: false
      });
      this.modalBackdropService.addBackDrop();
      this.closeLoaderModel('saveTerminalEvents');
    }
  }

  logoutTheUsedTerminal() {
    if (this.terminalUsedRef) {
      this.terminalUsedRef.hide();
      this.modalBackdropService.removeBackdrop();
    }
    this.terminalProcessingMessage = Messages.LogoutRequestSent;
    this.applicationLoginService.logoutTheUsedTerminal(this.selectedTerminalId).pipe(finalize(() => {
      this.spinnerService.hide();
    })).subscribe({
      next: (response) => {
        if (response) {
          this.terminalProcessingMessage = Messages.ConnectingToSystem;
          this.progressBar();
          this.saveTerminalEventLog('logoutTheUsedTerminal');
        }
      }, error: this.alertService.showApiError
    });
  }

  private saveTerminalEventLog(eventName, isShowModal = true) {
    this.applicationStateService.gotoLoginScreen = false;
    const terminalEvent = {
      userId: this.applicationStateService.userId,
      terminalid: this.selectedTerminalId,
      eventMessage: 'Terminal Sign In',
      eventDetails: 'Sign In',
      terminalname: this.requestedTerminal.TerminalName,
      ipAddress: ''
    };
    const terminalSelectionObservables: Array<Observable<any>> = [];
    if (!isShowModal) {
      this.spinnerService.show();
    }
    terminalSelectionObservables.push(this.terminalService.saveTerminalEvents(terminalEvent));
    terminalSelectionObservables.push(this.terminalService.logTerminalUserLogin(terminalEvent));
    terminalSelectionObservables.push(this.reloadSettingsService.reloadSettings(this.requestedTerminal.TerminalId));
    terminalSelectionObservables.push(this.applicationFeatureService.getApplicationFeatures());
    forkJoin(terminalSelectionObservables).pipe(finalize(() => {
      this.spinnerService.hide();
    }))
      .subscribe({
        next: (response) => {
          if (response) {
            if (response[2]) {
              this.afterSettingReloaded(response[2], eventName);
            }
            this.applicationStateService.recentTerminalDetails = this.requestedTerminal;
            this.applicationStateService.setLocalizationSettings(this.applicationStateService.settingParam);
            this.getUserPreference(eventName);
          }
        },
        error: this.handelLoginError
      });
  }

  getUserPreference(eventName) {
    this.closeLoaderModel(eventName);
    this.spinnerService.show();
    this.applicationLoginService.getUserPreference(this.applicationStateService.userId).pipe(finalize(() => {
      this.spinnerService.hide();
    })).subscribe({
      next: (response) => {
        if (response) {
          this.applicationLoginService.setUserPreferences(response.UserPreferenceModel);
          const appConfig = this.applicationStateService.configurations;
          appConfig.TerminalType = this.requestedTerminal.TerminalType;
          appConfig.TerminalTypeName = this.requestedTerminal.TerminalType;
          _.forEach(this.terminalTypes, (type) => {
            // if (type.Name === 'Product Build Queue') { // 
            if (type.Name === this.requestedTerminal.TerminalType) {
              // redirect to link instead of terminal home page.
              if (sessionStorage.getItem('redirectRoute')) {
                this.router.navigate([sessionStorage.getItem('redirectRoute')]);
                sessionStorage.removeItem('redirectRoute');
              }
              else if (type.Name === this.terminalTypes.MAKE_TABLE.Name) {
                this.router.navigate(['make-table']);
              } else if (type.Name === this.terminalTypes.ORDER_CONFIRMATION_SCREEN.Name) {
                this.router.navigate(['order-confirmation']);
              } else if (type.Name === this.terminalTypes.PICK_SCREEN.Name) {
                this.router.navigate(['pick-screen']);
              } else if (type.Name === this.terminalTypes.ORDER_STATUS.Name) {
                this.router.navigate(['order-status']);
              } else if (type.Name === this.terminalTypes.TIMER.Name) {
                this.router.navigate(['timer']);
              } else if (type.Name === this.terminalTypes.BACK_OFFICE.Name) {
                this.router.navigate(['back-office/console']);
              } else if (type.Name === this.terminalTypes.TIME_ENTRY.Name) {
                this.router.navigate(['time-entry']);
              } else if (type.Name === this.terminalTypes.DIGITAL_SIGNAGE.Name) {
                this.router.navigate(['digital-signage']);
              } else if (type.Name === this.terminalTypes.INSTRUCTION_SCREEN.Name) {
                this.router.navigate(['instruction-terminal']);
              } else if (type.Name === this.terminalTypes.DJ.Name) {
                this.router.navigate(['dj']);
              } else if (type.Name === this.terminalTypes.MANAGEMENT_DASHBOARD.Name && this.dashboardTerminalId) {
                this.router.navigate(['management-dashboard', this.dashboardTerminalId]);
              } else if (type.Name === this.terminalTypes.SERVE_SCREEN.Name) {
                this.router.navigate(['serve-screen']);
              } else if (type.Name === this.terminalTypes.PRODUCT_BUILD_QUEUE.Name) {
                this.router.navigate(['product-build-queue']);
              } else if (type.Name === this.terminalTypes.MENU_EXPLORER.Name) {
                this.router.navigate(['menu-explorer']);
              } else if (type.Name === this.terminalTypes.MENU_DISPLAY.Name) {
                this.getTerminalProperties(type);
              } else if (type.Name === this.terminalTypes.ORDER_KIOSK.Name) {
                this.router.navigate(['order-kiosk/terminal']);
              } else if (type.Name === this.terminalTypes.WAITLIST.Name && this.waitListTerminalId) {
                this.router.navigate(['wait-list/terminal', this.waitListTerminalId]);
              } else {
                this.router.navigate(['order/order-entry']);
              }
              this.applicationStateService.isFirstTimeAfterLogin = true;
            }
          });
        }
      }, error: this.alertService.showApiError
    });
  }

  getTerminalProperties(type) {
    this.spinnerService.show();
    this.terminalService.getTerminalProperties(this.selectedTerminalId)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (response) => {
          const terminalProperties = response ? response : [];
          let displayMenuId = null;
          _.forEach(terminalProperties, (property) => {
            if (property.PropertyKey === DomainConstants.TerminalProperties.MENU_DISPLAY_ID.Key) {
              displayMenuId = parseInt(property.PropertyValue, 10);
            }
          });
          if (type.Name === this.terminalTypes.MENU_DISPLAY.Name) {
            this.router.navigate(['menu-display', displayMenuId]);
          }
        }, error: this.alertService.showApiError
      });
  }

  private afterSettingReloaded(data, eventName): void {
    const userDetails = this.applicationStateService.userDetails;
    if (userDetails != null) {
      data.CurrentUser = userDetails.firstname + ' ' + userDetails.lastname;
      this.applicationStateService.cookieTerminalName = this.requestedTerminal.TerminalName;
      this.applicationStateService.terminalName = this.requestedTerminal.TerminalName;
      this.applicationStateService.terminalType = this.requestedTerminal.TerminalType;
      this.applicationStateService.currentUser = userDetails.firstname + ' ' + userDetails.lastname;
      this.applicationStateService.cookieCurrentUser = userDetails.firstname + ' ' + userDetails.lastname;
      this.applicationStateService.settingParam = data;
      this.cameraAnnotationService.addAnnotationToCamera(this.applicationStateService.cameraAnnotations.SignIn, {});
    } else {
      this.closeLoaderModel(eventName);
      this.applicationStateService.gotoLoginScreen = false;
    }
  }

  private handelLoginError = (response, self?: any) => {
    let message = '';
    this.spinnerService.hide();
    if (!(response.data && response.data.StatusMessage === 'External-Auth-Required')) {
      if (response.status === HttpStatusCodes.BadRequest) {
        if (response && response.data && !response.data.ReturnStatus && response.data.ReturnMessage.length > 0) {
          message = response.data.ReturnMessage[0];
        } else {
          message = response.data;
        }
      } else {
        if (response.data && response.data.StatusMessage && response.data.StatusMessage !== '') {
          message = response.data.StatusMessage;
        } else if (response.status === HttpStatusCodes.Unauthorized) {
          message = 'Access denied';
        } else {
          message = (message && message !== '') ? message : Messages.UnexpectedErrorOccurred;
        }
      }
      $('#MessageModal').modal('show');
    }
  }

  terminalModalClose() {
    this.clearProgressInterval();
    if (this.terminalUsedRef) {
      this.terminalUsedRef.hide();
    }
  }

  clearProgressInterval() {
    clearInterval(this.progress);
  }

  userHavePermissionForTerminalType(terminalType) {
    const currentTerminal = _.find(DomainConstants.TerminalTypes, function (type) {
      return type.Name === terminalType;
    });
    return currentTerminal ?
      this.authenticationService.userHasPermission([{ 'Name': currentTerminal.Permission, 'Level': Levels.Access }], 'any') : false;
  }

  private userHaveTerminalAccessPermission(): void {
    const terminalList = DomainConstants.TerminalTypes;
    const terminalTypes = Object.keys(terminalList).map(it => terminalList[it]);
    this.userHasPermission = false;
    _.forEach(terminalTypes, (type) => {
      if (!this.userHasPermission) {
        this.userHasPermission = this.authenticationService.userHasPermission(
          [{ 'Name': type.Permission, 'Level': Levels.Access }],
          'any');
      }
    });
  }

  /** increase or decrease the size as per the number of terminal buttons. */
  private loadTerminalCSS = function () {
    const numberOfButtons = this.terminalSelectionList.length;
    if (numberOfButtons === 1) {
      this.styleClasses = '.colm w100 h1';
    } else if (numberOfButtons >= 2 && numberOfButtons <= 4) {
      this.styleClasses = '.colm w50 h4';
    } else if (numberOfButtons >= 5 && numberOfButtons <= 7) {
      this.styleClasses = '.colm w33 h5';
    } else if (numberOfButtons >= 8 && numberOfButtons <= 16) {
      this.styleClasses = '.colm w25 h6';
    } else if (numberOfButtons >= 17 && numberOfButtons <= 30) {
      this.styleClasses = '.colm w20 h7';
    } else if (numberOfButtons >= 31 && numberOfButtons <= 36) {
      this.styleClasses = '.colm w16 h8';
    } else if (numberOfButtons >= 37 && numberOfButtons <= 49) {
      this.styleClasses = '.colm w14 h9';
    } else if (numberOfButtons >= 48 && numberOfButtons <= 64) {
      this.styleClasses = '.colm w12 h10';
    } else {
      this.styleClasses = '.colm w25 h6';
    }
  };

  cancelImpersonateUser() {
    this.applicationStateService.isImpersonateUser = false;
    this.applicationStateService.impersonateUserDetails = null;
    this.applicationStateService.gotoLoginScreen = true;
    this.router.navigate(['users']);
    setTimeout(() => {
      window.location.reload();
    });
  }

  setLicenseToTerminalList() {
    this.terminalList.forEach((data) => {
      const feature = this.applicationStateService.applicationFeatures.find(x => x.Name == data.TerminalType);
      data.IsEnabled = feature?.IsEnabled ?? false;
    });
  }

  progressBar() {
    let textContent = 0;
    this.progress = setInterval(function () {
      const msgDigit = textContent / 3;
      textContent = textContent + 60;
      if ((textContent / 3) >= 100) {
        clearInterval(this.progress);
      } else {
        if (msgDigit === 20) {
          $('#message').html('Signing in user...');
        }
        if (msgDigit === 40) {
          $('#message').html('Connecting to peripherals...');
        }
        if (msgDigit === 60) {
          $('#message').html('Loading menu screens...');
        }
      }
    }, 2800);
  }
}

