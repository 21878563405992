import { Component, OnInit, ViewChild, Input, EventEmitter, Output, TemplateRef } from '@angular/core';
import { salesCategoriesTh, salesCategoriesClock, editWhiteWithText, plusWhite, salesCategoriesCommentDollar, salesSizesFunnelDollar, editWhite, deleteWhite } from 'src/app/shared/components/icon';
import { NgForm } from '@angular/forms';
import { ProductWithSizes, SalesCategory, SalesKitchenPrinter } from 'src/app/information-management';
import { ApplicationStateService } from 'src/app/shared/services/application-state.service';
import { SalesCategoryService } from 'src/app/shared/services/sales-category.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { Messages, DomainConstants, Permissions, Levels } from 'src/app/shared/constants';
import { finalize } from 'rxjs/operators';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SalesGroup } from 'src/app/information-management/sales-groups/interface';
import { SettingParam } from 'src/app/shared/interface/setting-param';
import { forkJoin } from 'rxjs';
import { ParameterService } from 'src/app/shared/services/parameter.service';
import {
    SalesGroupEditModelComponent
} from 'src/app/information-management/sales-groups/component/sales-group-edit-model/sales-group-edit-model.component';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { SalesSizeService } from 'src/app/shared/services/sales-size.service';
import * as _ from 'lodash';
import { HardwareService } from 'src/app/shared/services/hardware.service';
import { SalesGroupService } from 'src/app/shared/services/sales-group.service';
import { GridColumn, TemplateColumn, TextAlign } from '@tarktech/tark-ng-utils';
import { ModalBackdropService } from 'src/app/shared/components/modal/modal-component';
import { SalesCategorySizeEditComponent } from '../sales-category-size-edit/sales-category-size-edit.component';
declare let $: any;

@Component({
    selector: 'pos-sales-category-edit',
    templateUrl: './sales-category-edit.component.html'
})
export class SalesCategoryEditComponent implements OnInit {

    @Input('salesCategoryId') salesCategoryId: number;
    @Input('isPopup') isPopup: boolean = false;
    @ViewChild('salesCategoryForm', { static: true }) salesCategoryForm: NgForm;
    @Output() close = new EventEmitter<any>();
    @Output() formChange = new EventEmitter<NgForm>();
    salesCategory: SalesCategory;
    salesGroupList: SalesGroup[] = [];
    icons = {
        salesCategoriesTh,
        salesCategoriesClock,
        editWhiteWithText,
        plusWhite,
        salesCategoriesCommentDollar,
        salesSizesFunnelDollar,
        editWhite,
        deleteWhite
    };
    permission = {
        salesGroup: Permissions.SalesGroups,
        editLevel: Levels.Edit,
      };
    selectedSalesCategoryKitchenPrinters = [];
    selectedSalesCategorySizes = [];
    infoMakeTableCookingState = Messages.InfoMakeTableCookingState;
    infoMakeTableReadyState = Messages.InfoMakeTableReadyState;
    settingParam = this.applicationStateService.settingParam;
    isMakeTableCookingState = false;
    isMakeTableReadyState = false;
    infoSalesCategory = this.salesCategoryService.state;
    prepSecondsConvertToTime: Date;
    makeTableColor = { backgroundColor: '' };
    prepButtonColor = { backgroundColor: '' };
    kitchenPrinters = [];
    labelPrinters = [];
    sizes = [];
    confirmConflictDeleteSize = '';
    activeProductsConflictedSizes: Array<ProductWithSizes> = [];
    inactiveProductsConflictedSizes: Array<ProductWithSizes> = [];
    conflictedSizeColumns: Array<GridColumn> = [];
    editSalesGroupId = 0;
    @ViewChild('deleteSizeConfirmation') public deleteSizeConfirmation: any;
    modalRef: BsModalRef;
    tabList = {
        SalesCategorySettings: 'SalesCategorySettings',
        TimeSetting: 'TimeSetting',
        SalesCategorySizes: 'SalesCategorySizes',
    };
    selectedTab = this.tabList.SalesCategorySettings;
    kitchenPrinterInfo = '';
    props = {
        controlBoxClass: 'col-lg-5 col-md-6 col-sm-10 col-xs-12'
    }
    salesCategorySizesColumns: GridColumn[] = [];
    @ViewChild('operationTemplate', { static: true }) operationTemplate: TemplateRef<any>;
    @ViewChild('operationHeaderTemplate', { static: true }) operationHeaderTemplate: TemplateRef<any>;
    filesMap = new Map<number, File>();
    
    constructor(private applicationStateService: ApplicationStateService,
        private salesCategoryService: SalesCategoryService,
        private alertService: AlertsService,
        private spinnerService: SpinnerService,
        private modalService: BsModalService,
        private modalBackdropService: ModalBackdropService,
        private parameterService: ParameterService,
        private salesGroupService: SalesGroupService,
        private hardwareService: HardwareService,
        private salesSizesService: SalesSizeService,
        private salesGroupModelService: ModalService) {
    }

    ngOnInit() {
        this.salesCategory = this.salesCategoryService.newSalesCategory();
        this.configureSalesCategorySizesColumns();
        this.getSalesCategoryDetails();
        this.scrollToTop();
    }

    configureSalesCategorySizesColumns(){
        const operationColumn = new TemplateColumn({
            itemTemplate: this.operationTemplate,
            Width: '120px',
            headerTemplate: this.operationHeaderTemplate,
            TextAlign: TextAlign.Center
        });

        this.salesCategorySizesColumns = [
            new GridColumn({ HeaderText: 'Name', Field: 'SaleSizes.Name' }),
            operationColumn,
        ]
    }

    private getSalesCategoryDetails() {
        if (this.salesCategoryId >= 0) {
            this.selectedSalesCategoryKitchenPrinters = [];
            if (!this.settingParam.MakeTableWorkflowStates) {
                this.loadSettingParams();
            } else {
                this.setPrepSettingVisibility();
            }
            this.spinnerService.show();
            const salesCategoryObservable = [];
            salesCategoryObservable.push(this.salesGroupService.getSalesGroups());
            salesCategoryObservable.push(this.hardwareService.getHardwaresOfType(DomainConstants.HardwareTypes.LabelPrinter));
            salesCategoryObservable.push(this.getMappedKitchenPrinters());
            salesCategoryObservable.push(this.salesSizesService.getSalesProductSizes(0));
            if (this.salesCategoryId > 0) {
                salesCategoryObservable.push(this.salesCategoryService.getSalesCategory(this.salesCategoryId));
                salesCategoryObservable.push(this.salesCategoryService.getKitchenPrinter(this.salesCategoryId));
            } else {
                this.salesCategoryForm.reset();
                this.makeTableColor.backgroundColor = '';
                this.prepButtonColor.backgroundColor = '';
                this.salesCategory = this.salesCategoryService.newSalesCategory();
            }
            forkJoin(salesCategoryObservable)
                .pipe(finalize(() => {
                    this.spinnerService.hide();
                }))
                .subscribe({ next: (responses: any) => {
                    this.salesGroupList = responses[0];
                    this.labelPrinters = responses[1];
                    if (responses[2]) {
                        this.kitchenPrinters = responses[2].filter(k => k.IsActive);
                    }
                    this.sizes = responses[3];
                    if (this.salesCategoryId > 0) {
                        this.salesCategory = responses[4];
                        this.manageSalesCategory();
                        if (responses[5]) {
                            this.manageKitchenPrinter(responses[5]);
                        }
                    }
                    this.formChange.emit(this.salesCategoryForm);
                    this.getSalesCategoryKitchenPrintersForInfo(this.salesCategory.sales_group_id, false);
                }, error: this.alertService.showApiError });
        }
    }
    private loadSettingParams() {
        this.spinnerService.show();
        this.parameterService.getParameterForTerminal(this.applicationStateService.terminalId)
            .pipe(finalize(() => {
                this.spinnerService.hide();
            }))
            .subscribe({ next: (response: SettingParam) => {
                this.applicationStateService.settingParam = response;
                this.settingParam = this.applicationStateService.settingParam;
                this.setPrepSettingVisibility();
            }, error: this.alertService.showApiError });
    }
    private manageSalesCategory() {
        this.changeMakeTableCategoryColor();
        this.changeSalesCategoryPrepButtonColor();
        this.secondsConvertToTime(this.salesCategory.PrepTime);
        this.setSizes();
    }
    private manageKitchenPrinter(kitchenPrinters: any) {
        const selectedKitchenPrinters = [];
        kitchenPrinters.forEach((kitchenPrinter: any) => {
            selectedKitchenPrinters.push(this.getSalesCategoryKitchenPrinter(kitchenPrinter.Id, kitchenPrinter.Name));
        });
        this.selectedSalesCategoryKitchenPrinters = selectedKitchenPrinters;
    }
    private getSalesCategoryKitchenPrinter(id: number, name: string) {
        return {
            id: id,
            Name: name
        };
    }

    getSalesCategoryKitchenPrintersForInfo = (groupId, isSilentCall = true) => {
        if (groupId) {
            if (!isSilentCall) {
                this.spinnerService.show();
            }
            this.salesCategoryService.getKitchenPrintersInfo(groupId)
                .pipe(finalize(() => {
                    if (!isSilentCall) {
                        this.spinnerService.hide();
                    }
                }))
                .subscribe({ next: (res: SalesKitchenPrinter) => {
                    if (res) {
                        const printerNames = this.getKitchenPrinterNames(res.KitchenPrinters);
                        this.kitchenPrinterInfo = `If not specified, will print to: ${printerNames} <i> (Inherited from
                ${res.InheritedFrom ? (res.InheritedFrom + ':') : 'default configuration'} ${res.SourceName ? (res.SourceName + ')') : ')'} </i>`;
                    }
                }, error: this.alertService.showApiError });
        }
    }

    getKitchenPrinterNames = (printerIds: Array<number>) => {
        let printerNames = '';
        _.forEach(this.kitchenPrinters, (printer) => {
            const printerDetail = _.find(printerIds, id => {
                return printer.Id === id;
            });
            if (printerDetail) {
                printerNames += !printerNames ? printer.Name : (', ' + printer.Name);
            }
        });
        return printerNames;
    }

    getMappedKitchenPrinters() {
        this.kitchenPrinters = [];
        return this.hardwareService.getHardwaresOfType(DomainConstants.HardwareTypes.KitchenPrinter);
    }
    setPrepSettingVisibility() {
        this.settingParam.MakeTableWorkflowStates.forEach((makeTableWorkflowState: any) => {
            if (makeTableWorkflowState.Code === DomainConstants.MakeTableStates.COOKING
                && makeTableWorkflowState.IsSelected) {
                this.isMakeTableCookingState = true;
            }
            if (makeTableWorkflowState.Code === DomainConstants.MakeTableStates.READY
                && makeTableWorkflowState.IsSelected) {
                this.isMakeTableReadyState = true;
            }
        });
    }
    secondsConvertToTime(seconds: number) {
        if (seconds > 0) {
            const lowestDate = new Date(1970, 0, 1);
            lowestDate.setHours(0, 0, seconds, 0);
            this.prepSecondsConvertToTime = lowestDate;
        } else {
            this.prepSecondsConvertToTime = null;
        }
    }
    setSizes() {
        this.selectedSalesCategorySizes = this.salesCategory.Sizes;
    }
    saveSalesCategory(isValid: boolean) {
        if (!isValid) {
            return;
        }
        this.salesCategory.Sizes = this.selectedSalesCategorySizes;
        this.salesCategory.KitchenPrinterList = this.selectedSalesCategoryKitchenPrinters;
        if (this.salesCategory.IsTimedPrep) {
        } else {
            this.salesCategory.IsUseWorkflowTimer = false;
            this.salesCategory.PrepTime = null;
            this.salesCategory.PrepButtonColor = null;
        }
        this.salesCategory.LabelPrinter = this.salesCategory.IsPrintToLabels ? this.salesCategory.LabelPrinter : null;
        if (this.salesCategoryId === 0) {
            this.addSalesCategory();
        } else {
            this.onUpdateSalesCategory();
        }
    }
    private addSalesCategory() {
        this.spinnerService.show();
        this.salesCategoryService.addSalesCategory(this.salesCategory, [...this.filesMap].map(([sizeId, file]) => ({ sizeId, file })))
            .pipe(finalize(() => {
                this.spinnerService.hide();
            }))
            .subscribe({ next: (res) => {
                this.alertService.renderSuccessMessage(Messages.SalesCategorySaveSuccess);
                this.close.emit({ shouldReload: true, salesCategory: res });
            }, error: this.alertService.showApiError });
    }
    private onUpdateSalesCategory() {
        this.spinnerService.show();
        this.salesCategoryService.getSalesProductWithSizes(this.salesCategory)
            .pipe(finalize(() => {
                this.spinnerService.hide();
            }))
            .subscribe({ next: (response) => {
                if (response && response.length > 0) {
                    this.confirmConflictDeleteSize = Messages.ConfirmConflictDeleteSizes;
                    this.activeProductsConflictedSizes = response.filter(x => x.IsActive);
                    this.inactiveProductsConflictedSizes = response.filter(x => !x.IsActive);
                    this.modalRef = this.modalService.show(this.deleteSizeConfirmation, {
                        'backdrop': 'static',
                        'class': 'vertical-center',
                        keyboard: false
                    });
                    this.modalBackdropService.addBackDrop();
                    this.conflictedSizeColumns = [
                        new GridColumn({ HeaderText: 'Product', Field: 'ProductName', Width: '60%' }),
                        new GridColumn({ HeaderText: 'Size', Field: 'Size', Width: '40%' })
                    ];
                } else {
                    this.updateSalesCategory();
                }
            }, error: this.alertService.showApiError });
    }
    private updateSalesCategory() {
        this.spinnerService.show();
        this.salesCategoryService.updateSalesCategory(this.salesCategory, [...this.filesMap].map(([sizeId, file]) => ({ sizeId, file })))
            .pipe(finalize(() => {
                this.spinnerService.hide();
            }))
            .subscribe({ next: () => {
                this.alertService.renderSuccessMessage(Messages.SalesCategorySaveSuccess);
                this.close.emit({ shouldReload: true });
            }, error: this.alertService.showApiError });
    }

    getHeight() {
        return $(window).height() - 305 + 'px';

    }
    cancelConflictedSizes() {
        this.modalRef.hide();
        this.modalBackdropService.removeBackdrop();
    }
    confirmDeleteConflictedSizes() {
        this.modalRef.hide();
        this.modalBackdropService.removeBackdrop();
        this.updateSalesCategory();
    }
    cancelEditing() {
        this.close.emit({ shouldReload: false });
    }
    onSaveSalesGroup(salesGroup: SalesGroup) {
        this.spinnerService.show();
        this.salesGroupService.getSalesGroups()
            .pipe(finalize(() => {
                this.spinnerService.hide();
            }))
            .subscribe({ next: (salesGroupList: SalesGroup[]) => {
                this.salesGroupList = salesGroupList;
                this.salesCategory.sales_group_id = salesGroup.Id;
            }, error: this.alertService.showApiError });
    }
    addEditGroup(id: number) {
        if (!id || id === 0) {
            this.editSalesGroupId = 0;
        } else {
            this.editSalesGroupId = id;
        }
        const salesGroupModalRef = this.salesGroupModelService.show(SalesGroupEditModelComponent, {
            animated: false,
            class: 'vertical-center modal-lg',
            initialState: {
                salesGroupId: this.editSalesGroupId,
                fromListPage: false
            }
        });
        // if ($('.modal:visible').length) {
        //     $('.modal-backdrop').first().css('z-index', parseInt($('.modal:visible').last().css('z-index')) + 0);
        // }
        salesGroupModalRef.close.subscribe(res => {
            if (res && res.shouldReload) {
                this.onSaveSalesGroup(res.salesGroup);
                this.getSalesCategoryKitchenPrintersForInfo(res.salesGroup && res.salesGroup.Id ? res.salesGroup.Id :
                    this.salesCategory.sales_group_id);
            }
        });
    }
    onSaveCancel() {
        this.modalRef.hide();
    }

    changeMakeTableCategoryColor() {
        this.makeTableColor.backgroundColor = this.salesCategory.make_table_color;
    }

    changeSalesCategoryPrepButtonColor() {
        this.prepButtonColor.backgroundColor = this.salesCategory.PrepButtonColor;
    }
    onChangeSizes() {
        const selectedDefaultSize = _.find(this.selectedSalesCategorySizes, (size) => {
            return size.SizeId == this.salesCategory.DefaultSizeId;
        });
        if (!selectedDefaultSize) {
            this.salesCategory.DefaultSizeId = null;
        }
    }
    private scrollToTop(): void {
        window.scrollTo(0, 0);
    }

    editSalesCategorySize(sizeId: number, salesCategorySize) {
        const modal = this.salesGroupModelService.show(SalesCategorySizeEditComponent, {
            animated: false,
            class: 'vertical-center',
            initialState: {
                salesCategorySizeId: sizeId,
                salesCategorySize: {...salesCategorySize},
                salesCategoryId: this.salesCategoryId,
                sizes: this.sizes,
                excludeSizes: this.selectedSalesCategorySizes
                                .map(x => x.SizeId)
                                .filter(sizeId => sizeId !== salesCategorySize?.SizeId),
                image: salesCategorySize ? this.filesMap.get(salesCategorySize.SizeId) : null,
            }
        })

        modal.close.subscribe((res) => {
            if (res?.shouldReload && res.salesCategorySize) {
                const salesCategorySize = res.salesCategorySize;
                const index = this.selectedSalesCategorySizes.findIndex(x => x.SizeId === salesCategorySize.SizeId);
            
                if (index > -1) {
                    this.selectedSalesCategorySizes[index] = salesCategorySize;
                } else {
                    this.selectedSalesCategorySizes.push(salesCategorySize);
                }
                
                if (res.file) {
                    this.filesMap.set(salesCategorySize.SizeId, res.file);
                }

                if (!salesCategorySize.Image) {
                    this.filesMap.delete(salesCategorySize.SizeId)
                }

                this.selectedSalesCategorySizes = [...this.selectedSalesCategorySizes];
            }

        })
    }

    deleteSalesCategorySize(salesCategorySize) {
        const index = this.selectedSalesCategorySizes.findIndex(x => x.SizeId === salesCategorySize.SizeId);
        if (index > -1) {
            this.selectedSalesCategorySizes.splice(index, 1);
        }
        this.selectedSalesCategorySizes = [...this.selectedSalesCategorySizes];
        this.onChangeSizes();
    }
}
