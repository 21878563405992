import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { DjScheme } from '../../interface/dj-scheme';
import { GridColumn, TemplateColumn } from '@tarktech/tark-ng-utils';
import { plusWhite, editWhite, deleteWhite, sort, djSchemesRoad, clone, barsWhite } from 'src/app/shared/components/icon';
import {
  BaseListComponent, AlertsService, SpinnerService, ModalService, Permissions, Levels,
  PrintTableService, ApplicationStateService, Messages, DomainConstants, ConfirmDeleteModalComponent,
  FieldInfoMessages, AuthenticationService
} from 'src/app/shared';
import { DjSchemeService } from '../../services/dj-scheme.service';
import { finalize } from 'rxjs/operators';
import * as _ from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'pos-dj-schemes-list',
  templateUrl: './dj-schemes-list.component.html',
})
export class DjSchemesListComponent extends BaseListComponent<DjScheme> implements OnInit {

  djSchemes: Array<DjScheme> = [];
  djSchemeColumns: Array<GridColumn> = [];
  permission = {
    name: Permissions.SystemConfigurationDJSchemes,
    readOnlyLevel: Levels.ReadOnly,
    editLevel: Levels.Edit
  };
  fieldInfoMessages = FieldInfoMessages;
  isAscending = false;
  @ViewChild('operationTemplate', { static: true }) operationTemplate: TemplateRef<any>;
  @ViewChild('nameHeaderTemplate', { static: true }) nameHeaderTemplate: TemplateRef<any>;
  @ViewChild('nameTemplate', { static: true }) nameTemplate: TemplateRef<any>;
  @ViewChild('operationHeaderTemplate', { static: true }) operationHeaderTemplate: TemplateRef<any>;
  icons = {
    plusWhite, editWhite, deleteWhite, sort, djSchemesRoad, clone, barsWhite
  };
  selectedScheme: DjScheme;
  popup: any;

  constructor(protected alertService: AlertsService,
    protected spinnerService: SpinnerService,
    protected modalService: ModalService,
    protected djSchemeService: DjSchemeService,
    protected printService: PrintTableService,
    protected applicationStateService: ApplicationStateService,
    protected router: Router,
    protected route: ActivatedRoute,
    private authenticationService: AuthenticationService) {
    super(djSchemeService, alertService, spinnerService, modalService, applicationStateService, printService, route);
  }

  ngOnInit() {
    this.deleteSuccessMessage = Messages.DJSchemeDeleted;
    this.djSchemeColumns = this.configureGridColumns();
  }

  reload() {
    this.spinnerService.show();
    this.djSchemeService.getAll()
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: response => {
          this.djSchemes = response == null ? [] : response;
        }, error: this.alertService.showApiError
      });
  }

  configureGridColumns(): Array<GridColumn> {

    const operationColumn = new TemplateColumn({
      itemTemplate: this.operationTemplate,
      Width: '110px',
      headerTemplate: this.operationHeaderTemplate,
      CellClass: 'cell-padding'
    });

    const headerColumn = new TemplateColumn({
      itemTemplate: this.nameTemplate,
      Width: '95%',
      headerTemplate: this.nameHeaderTemplate
    });

    this.djSchemeColumns = [
      headerColumn
    ];

    if (this.authenticationService.userHasPermission([{ Name: this.permission.name, Level: this.permission.editLevel }], 'any')) {
      this.djSchemeColumns.push(operationColumn);
    }

    _.remove(this.djSchemeColumns, (column) => {
      return (column.Type === DomainConstants.GridColumnsTypes.EditColumn || column.Type === DomainConstants.GridColumnsTypes.DeleteColumn);
    });

    return this.djSchemeColumns;
  }

  getGridColumns(): Array<GridColumn> {
    return this.djSchemeColumns;
  }

  editItem(id: number, data?: DjScheme): void {
    this.router.navigate([id], { relativeTo: this.route });
  }

  getConfirmDeleteMessage(data: DjScheme): string {
    return Messages.ConfirmDeleteDJScheme;
  }

  deleteScheme(data: DjScheme): void {
    const modalRef = this.modalService.getModalWrapper(ConfirmDeleteModalComponent);
    const modal = modalRef.show({
      animated: false,
      class: 'vertical-center',
      'backdrop': 'static',
      initialState: {
        message: Messages.ConfirmDeleteDJScheme
      }
    });
    modal.close.subscribe(res => {
      if (res && res.shouldDelete) {
        this.delete(data.Id);
      }
    });
  }

  sortData() {
    if (this.isAscending) {
      this.djSchemes.sort((a, b) => a.Name.localeCompare(b.Name));
    } else {
      this.djSchemes.sort((a, b) => b.Name.localeCompare(a.Name));
    }
    this.isAscending = !this.isAscending;
  }

  close() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

  onLinkPopOver(data: DjScheme, popup: any) {
    this.selectedScheme = data;
    this.popup = popup;
  }

  copyDJScheme(id: number) {
    this.popup.hide();
    this.router.navigate(['0'], { relativeTo: this.route, state: { copySchemeId: id } });
  }
}
