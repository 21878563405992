import { Injectable } from '@angular/core';
import { HttpStatusCodes } from '../constants/http-constants';
import { Messages } from '../constants/ui-messages';

@Injectable()
export class HttpResponseUtilsService {
  constructor() {}

  parseError(errorResponse) {
    switch (errorResponse?.status) {
      case HttpStatusCodes.NotFound:
        return this.apiError(
          HttpStatusCodes.NotFound,
          errorResponse.error
            ? errorResponse.error
            : Messages.CanNotFindResource
        );
      case HttpStatusCodes.BadRequest:
        return this.apiError(
          HttpStatusCodes.BadRequest,
          errorResponse.error ? errorResponse.error : Messages.InvalidRequest
        );
      case HttpStatusCodes.InternalServerError:
        return this.apiError(
          HttpStatusCodes.BadRequest,
          Messages.UnexpectedErrorOccurred
        );
      default:
        return this.apiError(
          errorResponse?.status,
          Messages.RequestProcessingError
        );
    }
  }

  apiError(statusCode, message) {
    const error: ApiError = {
      statusCode: statusCode,
      message: message
    };
    return error;
  }
}

export interface ApiError {
  statusCode: any;
  message: any;
}
