import { ConfirmDeleteComponent } from 'src/app/shared/components/confirm-delete/confirm-delete.component';
import { AuthenticationService } from '../../../../../shared/auth/services/authentication.service';
import { SpinnerService } from '../../../../../shared/components/spinner/spinner.service';
import { AlertsService } from '../../../../../shared/services/alerts.service';
import { EditColumn, LinkColumn, DeleteColumn, GridColumn } from '@tarktech/tark-ng-utils';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalService, Messages, PrintTableService, BaseGridComponent, ApplicationStateService, AccountAttributeTypes } from 'src/app/shared';
import { Permissions, Levels } from '../../../../../shared/constants';
import * as _ from 'lodash';
import {
    AccountAttributeTypeService
} from 'src/app/information-management/accounts/account-attribute-types/services/account-attribute-type.service';
import { AccountAttributeTypeEditComponent } from '../account-attribute-type-edit/account-attribute-type-edit.component';
import { ActivatedRoute, Router } from '@angular/router';
import { StringUtils } from 'src/app/shared/string-utils/string-utils';
import { accountAttributeType } from 'src/app/shared/components/icon';

@Component({
    selector: 'pos-account-attribute-types',
    templateUrl: './account-attribute-types.component.html',
})
export class AccountAttributeTypesComponent extends BaseGridComponent implements OnInit {

    constructor(private accountAttributeTypeService: AccountAttributeTypeService,
        private alertService: AlertsService,
        private spinnerService: SpinnerService,
        private modalService: ModalService,
        private authenticationService: AuthenticationService,
        protected applicationStateService: ApplicationStateService,
        protected route: ActivatedRoute,
        protected router: Router,
        private printService: PrintTableService) {
        super(applicationStateService, route);
    }

    accountAttributeTypeId = 0;
    accountAttributeTypes: AccountAttributeTypes[] = [];
    @ViewChild('confirmAccountAttributeTypeDelete') private confirmAccountAttributeTypeDelete: ConfirmDeleteComponent;
    accountAttributeTypeColumns: Array<GridColumn> = [];
    printAccountAttributeTypeColumns: Array<GridColumn> = [];
    icons = { accountAttributeType };
    permission = {
        name: Permissions.CustomersAccountAttributeTypes,
        readOnlyLevel: Levels.ReadOnly,
        editLevel: Levels.Edit
    };

    ngOnInit() {
        this.configureGridColumns();
        this.reloadAccountAttributeTypes();
    }

    reloadAccountAttributeTypes() {
        this.spinnerService.show();
        this.accountAttributeTypeService.getAccountAttributeTypes()
            .subscribe(
                response => {
                    if (response) {
                        this.accountAttributeTypes = response;
                    } else {
                        this.accountAttributeTypes = [];
                    }
                },
                this.alertService.showApiError,
                () => {
                    this.spinnerService.hide();
                }
            );
    }

    private configureGridColumns() {

        const editColumn = new EditColumn({
            Clicked: (accountAttributeType) => {
                this.editAccountAttributeType(accountAttributeType);
            },
            Width: '57px',
            AddIcon: new LinkColumn({
                Clicked: () => {
                    this.editAccountAttributeType(0);
                },
                ToolTip: 'New'
            })
        });

        const deleteColumn = new DeleteColumn({
            Clicked: (data) => {
                this.deleteAccountAttributeType(data);
            },
            Width: '83px',
            PrintIcon: new LinkColumn({
                Clicked: () => {
                    this.printData();
                }
            })
        });

        this.accountAttributeTypeColumns = [
            new GridColumn({ HeaderText: 'Name', Field: 'Name', IsSortable: true, Width: '30%' }),
            new GridColumn({ HeaderText: 'Value Type', Field: 'ValueType', IsSortable: true, Width: '30%' }),
            new GridColumn({ HeaderText: 'Values', Field: 'QueryValue', IsSortable: true, Width: '33%' }),
        ] as Array<GridColumn>;

        this.printAccountAttributeTypeColumns = [...this.accountAttributeTypeColumns];

        if (this.authenticationService.userHasPermission([{ Name: this.permission.name, Level: this.permission.editLevel }], 'any')) {
            this.accountAttributeTypeColumns.push(editColumn);
            this.accountAttributeTypeColumns.push(deleteColumn);
        }
    }

    gridRowReorder(event) {
        const dragElementIndex = event.dragIndex;
        const dropElementIndex = event.dropIndex;
        if (dragElementIndex < dropElementIndex) {
            this.accountAttributeTypes[dropElementIndex - 1].Ordinal = dropElementIndex;
        }
        else {
            this.accountAttributeTypes[dropElementIndex].Ordinal = dropElementIndex + 1;
        }
        this.accountAttributeTypeService.updateAttributeTypeOrdinal(dragElementIndex < dropElementIndex ?
            this.accountAttributeTypes[dropElementIndex - 1] : this.accountAttributeTypes[dropElementIndex]).subscribe(
                () => { },
                this.alertService.showApiError,
                () => { }
            );
    }

    deleteAccountAttributeType(data) {
        this.accountAttributeTypeId = data.Id;
        this.confirmAccountAttributeTypeDelete.Show(
            StringUtils.format(Messages.ConfirmDeleteAccountAttributeType, { 'accountAttributeType': data.Name }));
    }

    onConfirmAcknowledge() {
        this.spinnerService.show();
        this.accountAttributeTypeService.deleteAccountAttributeType(this.accountAttributeTypeId)
            .subscribe(
                () => {
                    this.alertService.renderSuccessMessage(
                        Messages.AccountAttributeTypeDeleteSuccess
                    );
                    this.reloadAccountAttributeTypes();
                },
                this.alertService.showApiError,
                () => {
                    this.spinnerService.hide();
                }
            );
    }

    editAccountAttributeType(data) {
        const accountAttributeTypeModalRef = this.modalService.show(AccountAttributeTypeEditComponent, {
            animated: false,
            class: 'vertical-center',
            initialState: {
                accountAttributeType: { ...data }
            }
        });

        accountAttributeTypeModalRef.close.subscribe(res => {
            if (res && res.shouldReload) {
                this.reloadAccountAttributeTypes();
            }
        });
    }

    printData() {
        this.printService.printEmitter.next({ gridColumns: this.printAccountAttributeTypeColumns, gridData: this.accountAttributeTypes });
    }

    close() {
        this.router.navigate(['../'], { relativeTo: this.route });
    }
}
