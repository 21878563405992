export class DirectedGraph {
  adjacencyList;
  constructor() {
    this.adjacencyList = {};
  }

  addVertex(vertex) {
    if (!this.adjacencyList[vertex]) {
      this.adjacencyList[vertex] = [];
    }
  }

  addEdge(source, destination) {
    if (!this.adjacencyList[source]) {
      this.addVertex(source);
    }
    if (!this.adjacencyList[destination]) {
      this.addVertex(destination);
    }
    this.adjacencyList[source].push(destination);
  }

  removeEdge(source, destination) {
    if (this.adjacencyList[source])
      this.adjacencyList[source] = this.adjacencyList[source].filter(vertex => vertex !== destination);
  }

  removeVertex(vertex) {
    while (this.adjacencyList[vertex]) {
      const adjacentVertex = this.adjacencyList[vertex].pop();
      this.removeEdge(vertex, adjacentVertex);
    }
    delete this.adjacencyList[vertex];
  }

  isPathFrom(start, destination): boolean {

    // if vertex doesn't exist in graph there is no path between start and destination
    if(!this.adjacencyList[start] || !this.adjacencyList[destination]) {
      return false;
    }

    const stack = [start];
    const visited = {};
    visited[start] = true;
    while (stack.length) {
      const currentVertex = stack.pop();

      if (currentVertex === destination) {
        return true;
      }

      this.adjacencyList[currentVertex].forEach(child => {
        if (!visited[child]) {
          visited[child] = true;
          stack.push(child);
        }
      });
    }
    return false;
  }

  printGraph() {
    console.log("printing graph...");
    for (const vertex in this.adjacencyList) {
      const edges = this.adjacencyList[vertex];
      console.log(`${vertex} -> ${edges.join(', ')}`);
    }
  }
}
