import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import {
  SpinnerService, AlertsService, ModalService, AuthenticationService, ApplicationStateService,
  DashboardConfigurationService, SalesCategoryService, PrintTableService, ExportService, TagService,
  StringUtils,
  Messages,
  LoggerService
} from 'src/app/shared';
import { SalesProductsService } from '../../services';
import { forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { SalesProduct } from '../../interface';
import { GridColumn, TemplateColumn, TextAlign, TableComponent } from '@tarktech/tark-ng-utils';
import { Permissions, Levels } from '../../../../shared/constants/';
import {
  check, plusWhite, printWhite, editWhite, deleteWhite, barsWhite, dashboardList, home, shoppingCart,
  list, fileAlt, clone, codeBranch, listAlt, salesProductRecipe, history, dollarSign, salesProductsFileInvoiceDollar, salesRefreshAvailability
} from 'src/app/shared/components/icon';
import { SalesProductDefaultButtonComponent } from '../sales-product-default-button';
import { SalesProductRecipeListComponent } from '../sales-product-recipe-list';
import { CopySalesProductComponent } from '../copy-sales-product';
import { MergeSalesProductComponent } from '../merge-sales-product';
import { SalesProductQuickReportComponent } from '../sales-product-quick-report';
import { SalesProductListAllButtonsComponent } from '../sales-product-list-all-buttons/sales-product-list-all-buttons.component';
import { SalesProductInstructionConfigurationComponent } from '../sales-product-instruction-configuration';
import { BaseGridComponent } from 'src/app/shared/components/base-grid.component';
import { SalesProductDashboardComponent } from '../sales-product-dashboard/sales-product-dashboard.component';
import { ActivatedRoute, Router } from '@angular/router';
import { find, forEach, remove, uniq } from 'lodash';
import { Tag } from 'src/app/information-management/tags';
import { RefreshSalesProductInputModel } from '../../interface/refresh-sales-product-model';
declare let $: any;
@Component({
  selector: 'pos-sales-product-list',
  templateUrl: './sales-product-list.component.html',
  styleUrls: ['./sales-product-list.component.scss']
})
export class SalesProductListComponent extends BaseGridComponent implements OnInit {

  isActive = true;
  popup: any;
  salesProductCategoryId: number = null;
  salesProducts: Array<SalesProduct> = [];
  salesCategories: Array<any> = [];
  salesProductColumns: Array<GridColumn> = [];
  salesProduct: SalesProduct;
  salesProductShowRecipeId: number = null;
  printSalesProductListColumns: Array<GridColumn> = [];
  salesProductDetails: SalesProduct;
  permission = {
    name: Permissions.SalesProducts,
    readOnlyLevel: Levels.ReadOnly,
    editLevel: Levels.Edit,
    parent: Permissions.Sales,
    parentAccessLevel: Levels.Access
  };
  dashboardId: number;
  salesProductsForBulkUpdateIds: Array<number> = [];
  isBulkUpdateAllProducts = false;
  filteredSalesProducts: Array<SalesProduct> = [];
  filterColumns: Array<string>;
  tags: Array<Tag> = [];
  editProductPermission = false;
  readOnlyProductPermission = false;
  @ViewChild('popOverForSalesProductName', { static: true }) popOverForSalesProductName: TemplateRef<any>;
  @ViewChild('taxableTemplate', { static: true }) taxableTemplate: TemplateRef<any>;
  @ViewChild('activeTemplate', { static: true }) activeTemplate: TemplateRef<any>;
  @ViewChild('operationTemplate', { static: true }) operationTemplate: TemplateRef<any>;
  @ViewChild('operationHeaderTemplate', { static: true }) operationHeaderTemplate: TemplateRef<any>;
  @ViewChild('headerTemplate', { static: true }) headerTemplate: TemplateRef<any>;
  @ViewChild('salesProductList', { static: true }) salesProductList: TableComponent;
  @ViewChild('bulkUpdateHeaderTemplate', { static: true }) bulkUpdateHeaderTemplate: TemplateRef<any>;
  @ViewChild('bulkUpdateCheckboxTemplate', { static: true }) bulkUpdateCheckboxTemplate: TemplateRef<any>;

  public icons = {
    check,
    plusWhite,
    printWhite,
    editWhite,
    deleteWhite,
    barsWhite,
    dashboardList,
    home,
    shoppingCart,
    list,
    fileAlt,
    clone,
    codeBranch,
    listAlt,
    salesProductRecipe,
    history,
    dollarSign,
    salesProductsFileInvoiceDollar,
    salesRefreshAvailability
  };
  constructor(
    protected applicationStateService: ApplicationStateService,
    private spinnerService: SpinnerService,
    private alertService: AlertsService,
    private salesProductService: SalesProductsService,
    private salesCategoryService: SalesCategoryService,
    private modalService: ModalService,
    private authenticationService: AuthenticationService,
    protected route: ActivatedRoute,
    private router: Router,
    private printService: PrintTableService,
    private exportService: ExportService,
    private tagService: TagService,
    private dashboardConfigurationService: DashboardConfigurationService,
    private loggerService: LoggerService
  ) {
    super(applicationStateService, route);
    const navigation = router.getCurrentNavigation();
    this.gridContext = navigation?.extras?.state?.GridContext ?? this.gridContext;
    this.salesProductCategoryId = navigation?.extras?.state?.Category ?? this.salesProductCategoryId;
    this.isActive = navigation?.extras?.state?.Active ?? this.isActive;
  }

  getOtherProperties() {
    this.othersProperties = { isActive: this.isActive, salesProductCategoryId: this.salesProductCategoryId };
  }

  ngOnInit() {
    if (this.othersProperties.isActive === false) {
      this.isActive = false;
    }
    this.salesProductCategoryId = this.othersProperties.salesProductCategoryId ?? this.salesProductCategoryId;
    this.configureGridColumns();
    this.getProductDetails();
    this.salesProductShowRecipeId = null;
    this.salesProductDetails = null;
    this.salesProductList.context = this.gridContext;
    this.getProductDashboardId();
  }

  getProductDashboardId = () => {
    this.spinnerService.show();
    this.dashboardConfigurationService.getDashboardByName('Sales Product Dashboard')
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (response) => {
          this.dashboardId = response ? response.Id : null;
        }, error: this.alertService.showApiError
      });
  }

  configureGridColumns() {
    const checkboxTemplate = new TemplateColumn({
      HeaderText: '', IsSortable: false,
      itemTemplate: this.bulkUpdateCheckboxTemplate, Width: '37px',
      headerTemplate: this.bulkUpdateHeaderTemplate
    });

    const nameTemplate = new TemplateColumn({
      HeaderText: 'Product', Field: 'Name', IsSortable: true,
      itemTemplate: this.popOverForSalesProductName
    });

    const taxableColumn = new TemplateColumn({
      HeaderText: 'Taxable',
      itemTemplate: this.taxableTemplate,
      TextAlign: TextAlign.Center,
      Width: '110px',
      Field: 'Taxable',
      IsSortable: true,
    });

    const activeColumn = new TemplateColumn({
      HeaderText: 'Active',
      itemTemplate: this.activeTemplate,
      TextAlign: TextAlign.Center,
      Width: '110px',
      Field: 'Active',
      IsSortable: true,
    });
    this.filterColumns = ['MenuDisplayText', 'SelectedTagNames'];
    this.salesProductColumns = [
      nameTemplate,
      new GridColumn({ HeaderText: 'Category', Field: 'CategoryName', IsSortable: true }),
      taxableColumn,
      activeColumn,
    ];
    this.printSalesProductListColumns = [...this.salesProductColumns];
    this.salesProductColumns.unshift(checkboxTemplate);
    this.checkPermission();
  }

  private checkPermission(): void {
    this.editProductPermission = this.authenticationService.userHasPermission([{ Name: this.permission.name, Level: this.permission.editLevel }], 'any');
    this.readOnlyProductPermission =this.authenticationService.userHasPermission([{ Name: this.permission.name, Level: this.permission.readOnlyLevel }], 'any')
    const columnWidth = this.editProductPermission ? '150px' : (this.readOnlyProductPermission? '100px' : '');

    if (this.editProductPermission || this.readOnlyProductPermission) {
      const operationColumn = new TemplateColumn({
        itemTemplate: this.operationTemplate,
        Width:  columnWidth ,
        headerTemplate: this.operationHeaderTemplate,
        CellClass: 'cell-padding',
        TextAlign: TextAlign.Center
      });
      this.salesProductColumns.push(operationColumn);
    }
  }

  addSaleProduct() {
    this.router.navigate([0], {
      state: {
        salesProductId: 0,
        isScroll: false,
        salesProductName: ''
      },
      relativeTo: this.route
    });
  }

  editSalesProduct(salesProduct: SalesProduct) {
    this.router.navigate([salesProduct.id], {
      state: {
        salesProductId: salesProduct.id,
        isScroll: false,
        salesProductName: salesProduct.Name
      },
      relativeTo: this.route
    });
  }

  openDashboard = (salesProduct: SalesProduct) => {
    const modalRef = this.modalService.getModalWrapper(SalesProductDashboardComponent);
    const modal = modalRef.show({
      animated: false,
      class: 'vertical-center modal-lg modal-max-width-95',
      initialState: {
        salesProductName: salesProduct.Name,
        salesProductId: salesProduct.id,
        dashboardId: this.dashboardId
      }
    });

    modal.close.subscribe(() => {

    });
  }

  printDiv() {
    this.printService.printEmitter.next({ gridColumns: this.printSalesProductListColumns, gridData: this.salesProducts });
  }

  exportCSV() {
    this.exportService.exportCSV('sales-products', this.salesProducts, null, this.printSalesProductListColumns);
  }

  getProductDetailsWithFilter() {
    this.isBulkUpdateAllProducts = false;
    this.gridContext.PageNumber = 1;
    this.getProductDetails();
  }
  getProductDetails() {
    this.spinnerService.show();
    this.salesProducts = [];
    const salesProductObservables = [];
    salesProductObservables.push(this.salesProductService.getProducts(this.isActive,
      this.salesProductCategoryId ? this.salesProductCategoryId : 0));
    salesProductObservables.push(this.salesCategoryService.getSalesCategories(true));
    salesProductObservables.push(this.tagService.getAll());
    forkJoin(salesProductObservables)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (responses: any) => {
          if (responses) {
            this.salesProducts = responses[0] ? responses[0] : [];
            this.salesCategories = responses[1] ? responses[1] : [];
            this.tags = responses[2] ? responses[2] : [];
            this.salesProducts.forEach(x => {
              x.IsAddToBulkUpdate = this.salesProductsForBulkUpdateIds.includes(x.id);
              if (x.SalesProductTags?.length > 0) {
                this.mapTags(x);
              }
            });
          }
        }, error: this.alertService.showApiError
      });
  }

  mapTags(salesProduct: SalesProduct) {
    salesProduct.SelectedTagNames = [];
    forEach(salesProduct.SalesProductTags, (salesTag) => {
      const mappedTag = find(this.tags, (tag) => tag.Id === salesTag.TagId);
      if (mappedTag) {
        salesProduct.SelectedTagNames.push(mappedTag.Name);
      }
    })
  }

  onTableFiltered(event) {
    this.isBulkUpdateAllProducts = false;
    this.filteredSalesProducts = [];
    if (event.filteredValue.length !== this.salesProducts.length) {
      this.filteredSalesProducts = event.filteredValue;
    }
  }

  openSalesProductShowRecipe(data: SalesProduct) {
    this.salesProductShowRecipeId = data.id;
  }
  openSalesProductPopover(data: SalesProduct, pop: any) {
    this.salesProductDetails = data;
    this.popup = pop;
    $('popover-container').popover({ container: 'body' });
  }

  onSalesProductHistory() {
    this.router.navigate([this.salesProductDetails.id, 'sales-product-history'], { relativeTo: this.route, state: { GridContext: this.gridContext, Category: this.salesProductCategoryId, Active: this.isActive }});
    this.popup.hide();
  }

  onSalesProductSizeHistory() {
    this.router.navigate([this.salesProductDetails.id, 'sales-product-size-history'], { relativeTo: this.route, state: { GridContext: this.gridContext, Category: this.salesProductCategoryId, Active: this.isActive } });
  }

  refreshSalesProductAvailability(){
    const refreshSalesProductInputModel: RefreshSalesProductInputModel = {
      InventoryProductId: null,
      SalesProductId: this.salesProductDetails.id
    };
    this.spinnerService.show();
    this.salesProductService.refreshSalesProductAvailability(refreshSalesProductInputModel)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res) => {
            this.alertService.renderSuccessMessage(StringUtils.format(Messages.SyncSalesProductAvailability, { 'salesProductName': this.salesProductDetails.Name }));
        }, error: this.alertService.showApiError
    });
  }

  openDefaultButtonModal() {
    const salesProductDefaultModalRef = this.modalService.show(SalesProductDefaultButtonComponent, {
      animated: false,
      class: 'vertical-center',
      initialState: {
        productId: this.salesProductDetails.id,
        isPopup: true,
        productName: this.salesProductDetails.Name,
        headerText: 'Default Product Selection',
      }
    });
    salesProductDefaultModalRef.close.subscribe(() => {
      this.salesProductDetails = null;
    });
    this.popup.hide();
  }

  openProductIngredientModal() {
    const salesProductDefaultModalRef = this.modalService.show(SalesProductRecipeListComponent, {
      animated: false,
      class: 'vertical-center modal-max-width-80',
      initialState: {
        productId: this.salesProductDetails.id,
        isPopup: true,
        productName: this.salesProductDetails.Name
      }
    });
    salesProductDefaultModalRef.close.subscribe(() => {
      this.salesProductDetails = null;
    });
    this.popup.hide();
  }

  openProductCopyModal() {
    const salesProductDefaultModalRef = this.modalService.show(CopySalesProductComponent, {
      animated: false,
      class: 'vertical-center',
      initialState: {
        salesProductId: this.salesProductDetails.id,
        salesProductName: this.salesProductDetails.Name
      }
    });
    salesProductDefaultModalRef.close.subscribe(res => {
      this.salesProductDetails = null;
      if (res && res.shouldReload) {
        this.getProductDetails();
      }
    });
    this.popup.hide();
  }

  openMergeProductModal() {
    const salesProductDefaultModalRef = this.modalService.show(MergeSalesProductComponent, {
      animated: false,
      class: 'vertical-center',
      initialState: {
        dateAdded: this.salesProductDetails.DateAdded,
        salesProductId: this.salesProductDetails.id,
        productName: this.salesProductDetails.Name
      }
    });
    salesProductDefaultModalRef.close.subscribe(res => {
      this.salesProductDetails = null;
      if (res && res.shouldReload) {
        this.getProductDetails();
      }
    });
    this.popup.hide();
  }

  openProductQuickReportModal() {
    const salesProductDefaultModalRef = this.modalService.show(SalesProductQuickReportComponent, {
      animated: false,
      class: 'vertical-center',
      initialState: {
        productId: this.salesProductDetails.id,
        productName: this.salesProductDetails.Name,
        isPopup: true
      }
    });
    salesProductDefaultModalRef.close.subscribe(() => {
      this.salesProductDetails = null;
    });
    this.popup.hide();
  }

  openListAllButtonModal() {
    const salesProductDefaultModalRef = this.modalService.show(SalesProductListAllButtonsComponent, {
      animated: false,
      class: 'vertical-center',
      initialState: {
        productId: this.salesProductDetails.id,
        productName: this.salesProductDetails.Name
      }
    });
    salesProductDefaultModalRef.close.subscribe(() => {
      this.salesProductDetails = null;
    });
    this.popup.hide();
  }

  openProductInstructionsModal() {
    const salesProductInstructionsModalRef = this.modalService.show(SalesProductInstructionConfigurationComponent, {
      animated: false,
      class: 'vertical-center modal-max-width-95',
      initialState: {
        salesProductId: this.salesProductDetails.id,
        salesProductName: this.salesProductDetails.Name
      }
    });
    salesProductInstructionsModalRef.close.subscribe(() => {
      this.salesProductDetails = null;
    });
    this.popup.hide();
  }

  addProductForBulkUpdate(salesProduct: SalesProduct) {
    this.isBulkUpdateAllProducts = false;
    if (salesProduct.IsAddToBulkUpdate) {
      this.salesProductsForBulkUpdateIds.push(salesProduct.id);
    } else {
      remove(this.salesProductsForBulkUpdateIds, x => x === salesProduct.id);
    }
  }

  addAllProductsForBulkUpdate() {
    if (this.isBulkUpdateAllProducts) {
      if (this.filteredSalesProducts.length) {
        this.salesProducts.forEach(x => x.IsAddToBulkUpdate
          = this.filteredSalesProducts.map(p => p.id).includes(x.id) ? true : x.IsAddToBulkUpdate);
        this.salesProductsForBulkUpdateIds.push(...this.filteredSalesProducts.map(x => x.id));
      } else {
        this.salesProducts.forEach(x => x.IsAddToBulkUpdate = true);
        this.salesProductsForBulkUpdateIds.push(...this.salesProducts.map(x => x.id));
      }
    } else {
      if (this.filteredSalesProducts.length) {
        this.salesProducts.forEach(x => x.IsAddToBulkUpdate =
          this.filteredSalesProducts.map(p => p.id).includes(x.id) ? false : x.IsAddToBulkUpdate);
        remove(this.salesProductsForBulkUpdateIds, x => this.filteredSalesProducts.map(p => p.id).includes(x));
      } else {
        this.salesProducts.forEach(x => x.IsAddToBulkUpdate = false);
        remove(this.salesProductsForBulkUpdateIds, x => this.salesProducts.map(p => p.id).includes(x));
      }
    }
    this.loggerService.logError("Change bulk update flag, salesproducts: " + this.salesProductsForBulkUpdateIds.join(","));

    this.salesProductsForBulkUpdateIds = uniq(this.salesProductsForBulkUpdateIds);
  }

  updateBulkProducts() {
    this.router.navigate(['bulk-update'], {
      relativeTo: this.route,
      state: { salesProductIds: this.salesProductsForBulkUpdateIds }
    });
  }

  close() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }
}
