import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { BaseFormComponent, SpinnerService, checkDouble, times } from 'src/app/shared/components';
import { DomainConstants, Messages, RuntimeConstants } from 'src/app/shared/constants';
import { SettingParam } from 'src/app/shared/interface';
import { AlertsService, ApplicationStateService, ExtensionValidationService, FormUtilityService } from 'src/app/shared/services';
import { finalize } from 'rxjs/operators';
import { ConfirmOrderService } from 'src/app/order-kiosk/services';
import { ConfirmOrderStyle } from 'src/app/order-kiosk/interfaces';
declare let $: any;

@Component({
  selector: 'pos-confirm-order-config',
  templateUrl: './confirm-order-config.component.html',
  styleUrls: ['./confirm-order-config.component.scss']
})
export class ConfirmOrderConfigComponent extends BaseFormComponent implements OnInit {


  get getForm(): NgForm {
    return this.form
  }
  @ViewChild('configForm') form: NgForm;

  parameters: SettingParam;
  imageBasePath = RuntimeConstants.IMAGE_BASE_PATH;
  updatedLogoFileName = '';
  updatedFileName = '';
  orderConfirmImage = null;
  selectedFiles: File;
  orderConfirmImagePath = '';
  showIcon = false;
  receiptLogoPath = '';
  icons = {
    checkDouble,
    times
  };
  style: ConfirmOrderStyle;
  alignItemsVertical = DomainConstants.VerticalAlignments;
  horizontalAlignments = DomainConstants.AlignItemsHorizontal;

  constructor(
    private spinnerService: SpinnerService,
    private alertsService: AlertsService,
    private applicationStateService: ApplicationStateService,
    private router: Router,
    formUtilityService: FormUtilityService,
    private extensionValidation: ExtensionValidationService,
    private confirmOrderService: ConfirmOrderService) {
    super(formUtilityService);
    this.parameters = applicationStateService.newSettingParams();
    this.style = confirmOrderService.newConfirmOrderStyle();
  }

  ngOnInit(): void {
    this.load();
  }

  load() {
    this.spinnerService.show();
    this.confirmOrderService.getConfirmOrderConfig()
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: res => {
          this.parameters = res;
          if (res.OrderKioskOrderConfirmStyle) {
            this.style = JSON.parse(res.OrderKioskOrderConfirmStyle) as ConfirmOrderStyle;
          }
          this.orderConfirmImage = `${this.imageBasePath}/order-kiosk/default-screens/${this.parameters.OrderKioskOrderConfirmImage}`;
          this.showIcon = !(this.parameters.OrderKioskOrderConfirmImage === '' || this.parameters.OrderKioskOrderConfirmImage == null);
        }, error: this.alertsService.showApiError
      });
  }

  saveConfig(isValid: boolean) {
    if (!isValid) {
      return;
    }
    this.parameters.OrderKioskOrderConfirmStyle = JSON.stringify(this.style);
    this.spinnerService.show();
    this.confirmOrderService.save(this.parameters, this.orderConfirmImage)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: res => {
          this.alertsService.renderSuccessMessage(Messages.KioskOrderConfirmConfigSave);
        }, error: this.alertsService.showApiError
      });
  }

  public handleFileInput(files: FileList) {
    this.orderConfirmImage = files.item(0);
    const fileToUpload = this.orderConfirmImage ? this.orderConfirmImage.name : '';
    if (this.extensionValidation.isInvalidExtension(fileToUpload, ['.jpg', '.bmp', '.png', '.gif'])) {
      if (fileToUpload) {
        this.alertsService.renderErrorMessage(Messages.InvalidReceiptLogoFileType);
      }
      this.removeIcon();
      return;
    }
    if (files && files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        $('#orderConfirmImage').attr('src', reader.result);
      };
      reader.readAsDataURL(files[0]);
      this.parameters.OrderKioskOrderConfirmImage = this.orderConfirmImage ? this.orderConfirmImage.name : '';
      this.showIcon = true;
    }
  }

  public removeIcon(): void {
    this.parameters.OrderKioskOrderConfirmImage = '';
    $('#orderConfirmImage').val('');
    $('#image').val(null);
    this.orderConfirmImage = null;
    this.showIcon = false;
  }

  cancelEditing() {
    this.router.navigate(['manage/order-kiosk']);
  }
}
