import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RuntimeConstants } from 'src/app/shared/constants';
import { DietaryWarningModel } from '../interface/dietary-warning-model';

@Injectable({
  providedIn: 'root'
})
export class DietaryWarningsService {

  constructor(private httpService: HttpClient) { }
  apiBasePath = `${RuntimeConstants.API_PATH}dietarywarning`

  newDietaryWarning(id: number = 0): DietaryWarningModel {
    return {
        Id: id,
        Name: '',
        Image: '',
        IsOverridable: false
    }
  }

  getDietaryWarnings(): Observable<DietaryWarningModel[]> {
      return this.httpService.get<DietaryWarningModel[]>(this.apiBasePath);
  }
  getDietaryWarningsById(id: number): Observable<DietaryWarningModel> {
      return this.httpService.get<DietaryWarningModel>(`${this.apiBasePath}/${id}`);
  }

  saveDietaryWarning(dietaryWarning: DietaryWarningModel, file: File) {
    const formData = new FormData();
    if (file) {
        formData.append('file', file);
    }
    formData.append('model', JSON.stringify(dietaryWarning));
    return this.httpService.post(`${this.apiBasePath}`, formData);
  }
}
