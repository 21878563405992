<pos-edit-wrapper heading="Log Inventory for {{eventMessage.InventoryProductName}}" (close)="onCancel()">
  <div class="modal-body pb-0">
    <div>{{ exhaustionMessage }}</div>
    <form #inventoryProductLog="ngForm">
      <pos-inventory-product-log [isPopup]="true" [isFromInventoryExhaustion]="true"
        [(inventoryProduct)]="inventoryProduct"></pos-inventory-product-log>
    </form>
  </div>
  <div class="col-md-12 action-button-container popup-footer pb-pix-10">
    <button class="btn btn-primary btn-lg btn-save pos-button" (click)="onLog()" type="button">Log</button>
    <button class="btn btn-secondary btn-lg btn-cancel-modal pos-button" (click)="onCancel()" type="button">Cancel</button>
  </div>
</pos-edit-wrapper>
