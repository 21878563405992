import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs';
import { ButtonConfig, KioskScreenChoice, KioskScreenConfig, ScreenConfig } from 'src/app/order-kiosk/interfaces';
import { KioskNavigationService, OrderKioskConfigService, OrderKioskScreenChoiceService } from 'src/app/order-kiosk/services';
import { AlertsService, DomainConstants, ModalService, RuntimeConstants, SpinnerService, plusMessage, addKioskButton } from 'src/app/shared';
import { SetKioskButtonComponent } from '../../button-actions/set-kiosk-button';

declare let $: any;

@Component({
  selector: 'pos-prompt-template',
  templateUrl: './prompt-template.component.html',
  styleUrls: ['./prompt-template.component.scss']
})
export class PromptTemplateComponent {
  @Input() screenConfigs: Array<KioskScreenConfig> = [];
  @Input() screenId: number;
  @Input() screenButtons: Array<KioskScreenChoice> = [];
  screenConfig: ScreenConfig;
  buttonConfig: ButtonConfig;
  imageRootPath = RuntimeConstants.IMAGE_BASE_PATH + "/order-kiosk/";
  invoiceHeight: number;
  templateType = DomainConstants.KioskScreenTemplateTypes.PromptTemplate;
  isDesignMode = false;
  icons = {
    addKioskButton,
    plusMessage
  }

  constructor(private orderKioskConfigService: OrderKioskConfigService,
    private orderKioskScreenChoiceService: OrderKioskScreenChoiceService,
    private spinnerService: SpinnerService,
    private alertsService: AlertsService,
    private route: ActivatedRoute,
    private modalService: ModalService,
    private kioskNavigationService: KioskNavigationService
  ) {
    this.isDesignMode = route.snapshot.params.id;
    this.screenConfig = orderKioskConfigService.newScreenConfig();
    this.buttonConfig = orderKioskConfigService.newButtonConfig();
  }

  ngOnInit(): void {
    this.resizeWindow();
    this.prepareConfigs();
    this.calculateHeight();
    this.orderKioskConfigService.setScreenButtonsBackgroundColor(this.screenButtons, this.buttonConfig.Opacity);
  }

  prepareConfigs() {
    const screenConfig = this.orderKioskConfigService.parseConfigProperties(DomainConstants.KioskScreenConfigProperties.ScreenConfig, this.screenConfigs);
    if (screenConfig) this.screenConfig = screenConfig;

    const secondaryButtonConfig = this.orderKioskConfigService.parseConfigProperties(DomainConstants.KioskScreenConfigProperties.ButtonConfig, this.screenConfigs);
    if (secondaryButtonConfig) this.buttonConfig = secondaryButtonConfig;
  }

  ngAfterViewInit(): void {
    this.calculateHeight();
  }

  calculateHeight() {
    this.invoiceHeight = this.orderKioskConfigService.calculateInvoiceHeight(this.screenConfig);
  }

  resizeWindow() {
    $(window).resize(() => {
      this.calculateHeight();
    });
  }

  buttonClick = (kioskScreenChoice: KioskScreenChoice) => {
    if (kioskScreenChoice.Button && !this.isDesignMode) {
      kioskScreenChoice.Button.NextScreenId = kioskScreenChoice.NextScreenId;
      this.kioskNavigationService.buttonClick(kioskScreenChoice.Button, this.screenId, kioskScreenChoice.ConfigId);
    } else if (!kioskScreenChoice.Button && this.isDesignMode) {
      this.configureButton(kioskScreenChoice);
    }
  }

  addKioskScreenChoice() {
    this.spinnerService.show();
    const kioskChoice = this.orderKioskScreenChoiceService.newScreenChoice();
    kioskChoice.ScreenId = this.screenId;
    kioskChoice.ConfigId = this.screenButtons?.[0]?.ConfigId;
    kioskChoice.Ordinal = Math.max(...this.screenButtons.map(x => x.Ordinal)) + 1;
    this.orderKioskScreenChoiceService.insertAndGet(kioskChoice)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res: KioskScreenChoice) => {
          this.screenButtons = [...this.screenButtons, res];
          this.buttonClick(res);
          this.orderKioskConfigService.setScreenButtonsBackgroundColor(this.screenButtons, this.buttonConfig.Opacity);
        }, error: this.alertsService.showApiError
      });
  }

  configureButton(button: KioskScreenChoice) {
    const modalRef = this.modalService.show(SetKioskButtonComponent, {
      backdrop: 'static',
      class: 'vertical-center',
      animated: false,
      keyboard: false,
      initialState: {
        kioskScreenButton: button
      }
    });
    modalRef.close.subscribe((res) => {
      if (button.Button) {
        button.Button.BackgroundColor = this.orderKioskConfigService.getBackgroundColor(button.Button, this.buttonConfig.Opacity);
      }
    });
  }

  emitScreenButtonsChange(screenButtons) {
    this.screenButtons = screenButtons;
  }
}
