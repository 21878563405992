import { Component, EventEmitter, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { DateColumn, GridColumn, TemplateColumn, TextAlign } from '@tarktech/tark-ng-utils';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/operators';
import { User } from 'src/app/information-management/users';
import { OrderInvoice, Transactions } from 'src/app/orders/interface';
import {
  angleLeft, angleRight, calendar, checkCircleSolid, cogs, barsWhite, clock, fileAudio,
  printOrderManagement, orderManagementLinkMember, minusCircle, undo, replyAll, codeBranch, conciergeBell, times
} from 'src/app/shared/components/icon';
import { OrderSummary } from '../../interface/order-summary';
import { PrintOrder } from '../../interface/print-order';
import { filter, forEach, remove, cloneDeep } from 'lodash';
import { MergeOrder } from '../../interface/merge-order';
import { RefundPaymentComponent } from '../refund-payment/refund-payment.component';
import { OrderMaintenanceService } from '../../services/order-maintenance.service';
import { SetAccountComponent } from 'src/app/set-account/set-account.component';
import { ModalBackdropService, ModalComponent } from 'src/app/shared/components/modal/modal-component';
import { Account, CreditCardPaymentStatus, SettingParam } from 'src/app/shared/interface';
import { DomainConstants, Levels, Messages, Permissions } from 'src/app/shared/constants';
import { ConfirmDeleteModalComponent, InfoModalComponent, ModalService, NumpadWrapperComponent, SpinnerService, SwipeCardComponent } from 'src/app/shared/components';
import { AlertsService, ApplicationStateService, OrderEventBroadcastingService, OrderService, RabbitMQService, UserService } from 'src/app/shared/services';
import { ManagesService } from 'src/app/shared/services/manages.service';
import { OrderTransactionsComponent } from 'src/app/manage-console/order-transactions/order-transactions.component';

declare let $: any;
import { StringUtils } from 'src/app/shared/string-utils/string-utils';
import { Format } from '@tarktech/tark-ng-utils/table/format-type';
import { CreditCardTransaction } from '../../interface/credit-card-transaction';
import { VoidOrder } from '../../interface/void-order';
import { UntilDestroy } from '@ngneat/until-destroy';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { TimeEntryComponent } from 'src/app/orders/order-entry/special-functions/time-entry/time-entry.component';
import { SetOrderMethodComponent } from '../set-order-method/set-order-method.component';
@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'pos-order-maintenance',
  templateUrl: './order-maintenance.component.html',
  styleUrls: ['./order-maintenance.component.scss']
})
export class OrderMaintenanceComponent extends ModalComponent implements OnInit, OnDestroy {

  icons = {
    angleLeft, angleRight, calendar, checkCircleSolid, cogs, barsWhite, clock, fileAudio,
    printOrderManagement, orderManagementLinkMember, undo, minusCircle, replyAll, codeBranch, conciergeBell, times
  };
  today = new Date();
  dateToFilter = new Date();
  userList: Array<User> = [];
  currentUserId: number = null;
  orderList: Array<OrderSummary> = [];
  orderColumns: Array<GridColumn> = [];
  orderId = 0;
  height: string;
  popupHeight: number;
  settingParam: SettingParam;
  @Output() close: EventEmitter<any> = new EventEmitter<any>();
  @Output() drawerStatusCompleted: EventEmitter<any> = new EventEmitter<any>();
  loyalAccountTerm = '';

  disabledRefund = true;
  orderRefunded = false;
  isUnserved = false;
  isDisabledAddTip = false;
  selectedSurrogateId = 0;
  customFilterColumns: Array<string> = ['ProductName'];
  mergeOrderId = 0;
  sourceSurrogateOrderId = 0;
  destinationSurrogateOrderId = 0;
  checkCCTipStatusInterval;
  creditCardTransactions: Array<Transactions> = [];
  orderTransactions: Array<Transactions> = [];
  orderData: OrderInvoice;
  selectedTransactionId: string;
  selectedTransactionDetails: Array<CreditCardTransaction> = [];
  rabbitMQSubscriptions: Array<any> = [];
  permissions: any = Permissions;
  accessLevels: any = Levels;
  permissionVoidOrder = {
    name: Permissions.OrderManagementVoidOrder,
    access: Levels.Access
  };
  unlinkSuccessMessage = '';
  selectedOrderTotal: number;
  permissionRefunds = {
    name: Permissions.OrderManagementRefunds,
    access: Levels.Access
  };
  selectedOrderTypeId = 0;
  rabbitMqOrderEntryTerminalQueueSubscription: Subscription;
  rabbitMqHICResponseSubscription: Subscription;
  isVoidOrder = false;
  isScheduledOrder = false;
  @ViewChild('totalTemplate', { static: true }) private totalTemplate: TemplateRef<any>;
  @ViewChild('paidAmountTemplate', { static: true }) private paidAmountTemplate: TemplateRef<any>;
  @ViewChild('nameTemplate', { static: true }) private nameTemplate: TemplateRef<any>;
  @ViewChild('gratuityTemplate', { static: true }) private gratuityTemplate: TemplateRef<any>;
  @ViewChild('cashTenderedTemplate', { static: true }) private cashTenderedTemplate: TemplateRef<any>;
  @ViewChild('settingTemplate', { static: true }) private settingTemplate: TemplateRef<any>;
  @ViewChild('paymentType', { static: true }) paymentType: TemplateRef<any>;
  @ViewChild('orderMethod', { static: true }) orderMethod: TemplateRef<any>;
  @ViewChild('setAccount') setAccount: SetAccountComponent;
  @ViewChild('tipInfoModal') tipInfoModal: TemplateRef<any>;
  bsPopover: TemplateRef<any>;
  tipInfoModalRef: BsModalRef;

  tipInfoModalHeader = 'Info';
  tipInfoModalMessage = '';
  tipInfoModalButtonText = 'Cancel';
  memberOperation = DomainConstants.LoyalAccountTermPrefix.Link;
  accountTerm = '';
  dateFormat = 'mm-dd-yyyy';
  refundedOrderAmount: number = 0;
  isUnloadOrderSummary = false;
  scheduledDateForSelectedOrder: Date;
  rabbitMQRequestIdForTip: string = '';
  isShowAudioFileIcon = false;

  constructor(private spinnerService: SpinnerService,
    private alertsService: AlertsService,
    private userService: UserService,
    private orderService: OrderService,
    private applicationStateService: ApplicationStateService,
    private modalService: ModalService,
    private bsModalService: BsModalService,
    private managesServices: ManagesService,
    private eventBroadcastingService: OrderEventBroadcastingService,
    private rabbitMQService: RabbitMQService,
    modalRef: BsModalRef,
    private modalBackdropService: ModalBackdropService,
    private orderMaintenanceService: OrderMaintenanceService) {
    super(modalRef);
  }

  ngOnInit(): void {
    this.currentUserId = this.applicationStateService.userDetails.id;
    this.settingParam = this.applicationStateService.settingParam;
    this.accountTerm = !this.settingParam.AccountTerm ? 'Account' : this.settingParam.AccountTerm;
    this.loyalAccountTerm = this.settingParam.LoyalAccountTerm ? this.settingParam.LoyalAccountTerm : 'Member';
    this.dateFormat = this.applicationStateService.settingParam.PCalendarDateFormat;
    this.isShowAudioFileIcon = this.applicationStateService.settingParam.EnableAudioRecording;
    this.loadData();
    this.setOrderColumns();
    this.subscribeSetAccount();
    this.subscribeToHICResponseToTerminal();
    this.subscribePaymentSuccessMessages();
    this.resetHeight();
    this.resizeWindow();
  }

  resizeWindow() {
    $(window).resize(() => {
      this.resetHeight();
    });
  }

  resetHeight = () => {
    this.height = $(window).height() - 255 + 'px';
    this.popupHeight = $(window).height() - 150;
    $('.order-summary').height(this.popupHeight - 30);
  }

  ngOnDestroy(): void {
    this.clearCCTipStatusInterval();
  }

  loadData() {
    this.spinnerService.show();
    this.userService.getAllActiveUser()
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (users: Array<User>) => {
          const userList: Array<User> = [];
          const newUser = this.userService.newUser();
          newUser.name = 'All';
          newUser.id = -1;
          userList.push(newUser, ...users);
          this.userList = userList;
          const preferredUser = this.applicationStateService.configurations.UserPreferenceOrderMaintenanceUser
          if (this.userList.some(user => user.id == preferredUser))
            this.currentUserId = preferredUser ?? this.currentUserId;
          
          this.getAllOrdersBetweenDate();
        }, error: this.alertsService.showApiError
      });
  }

  setOrderId(data: OrderSummary) {
    this.orderId = data.Order;
  }

  markUnservedOrder() {

    const modalRef = this.modalService.getModalWrapper(ConfirmDeleteModalComponent);
    const modal = modalRef.show({
      animated: false,
      class: 'vertical-center',
      'backdrop': 'static',
      initialState: {
        message: StringUtils.format(Messages.ConfirmMarkUnservedOrder,
          { 'orderId': this.selectedSurrogateId })
      }
    });
    modal.close.subscribe(res => {
      if (res && res.shouldDelete) {
        this.spinnerService.show();
        this.orderMaintenanceService.markUnservedOrder(this.orderId)
          .pipe(finalize(() => {
            this.spinnerService.hide();
          }))
          .subscribe({
            next: () => {
              this.getAllOrdersBetweenDate();
            }
          });
      }
    });
  }

  getAllOrdersBetweenDate(isSilentReload = false) {
    this.memberOperation = DomainConstants.LoyalAccountTermPrefix.Link;
    if (!isSilentReload) {
      this.spinnerService.show();
    }
    const orderDate = new Date(this.dateToFilter);
    const orderSummary: { Date: Date, EnteredByUserID?: number } = { Date: orderDate, EnteredByUserID: this.currentUserId };

    const observables: Array<Observable<any>> = [];
    observables.push(this.orderService.getAllOrdersBetweenDate(orderSummary));
    
    if (this.applicationStateService.userId != this.currentUserId) {
      this.applicationStateService.configurations.UserPreferenceOrderMaintenanceUser = this.currentUserId;
      let userPreference = { 
        user_id: this.applicationStateService.userId, 
        key: DomainConstants.UserPreferences.OrderMaintenanceUser, 
        value: this.applicationStateService.configurations.UserPreferenceOrderMaintenanceUser 
      };
      observables.push(this.orderService.saveUserPreference(userPreference));
    }

    forkJoin(observables)
      .pipe(finalize(() => {
        if (!isSilentReload) {
          this.spinnerService.hide();
        }
      }))
      .subscribe({
        next: ([response, ...otherResponses]: [any, ...any]) => {
          this.orderList = response;
          forEach(this.orderList, (order) => {
            order.CustomerAccountName = order.CustomerAccountName + (order.CustomerAccountName && order.Table ? ' | ' : '') + order.Table;
            order.IsScheduledOrder = order.ScheduleDate && new Date(order.ScheduleDate) > new Date() && !!order.IsUnserved;
          });
          this.orderId = 0;
          this.isUnserved = false;
          this.isScheduledOrder = false;
          this.mergeOrderId = 0;
          this.disabledRefund = true;
          this.orderRefunded = false;
        }, error: this.alertsService.showApiError
      });
  }

  setOrderColumns() {

    const totalColumn = new TemplateColumn({
      HeaderText: 'Total',
      itemTemplate: this.totalTemplate,
      Width: '10%',
      Field: 'Total',
      TextAlign: TextAlign.Right,
      IsSortable: true
    });


    const paidAmountColumn = new TemplateColumn({
      HeaderText: 'Paid Amount',
      itemTemplate: this.paidAmountTemplate,
      Width: '15%',
      Field: 'PaidAmount',
      TextAlign: TextAlign.Right,
      IsSortable: true
    });

    const gratuityColumn = new TemplateColumn({
      HeaderText: 'Gratuity',
      itemTemplate: this.gratuityTemplate,
      Width: '10%',
      Field: 'Gratuity',
      TextAlign: TextAlign.Right,
      IsSortable: true
    });

    const cashTenderedColumn = new TemplateColumn({
      HeaderText: 'Cash Tendered',
      itemTemplate: this.cashTenderedTemplate,
      Width: '15%',
      Field: 'CashTendered',
      TextAlign: TextAlign.Right,
      IsSortable: true,
    });

    const settingColumn = new TemplateColumn({
      itemTemplate: this.settingTemplate,
      Width: '88px',
    });

    const paymentType = new TemplateColumn({
      HeaderText: 'Payment',
      itemTemplate: this.paymentType,
      Width: '20%',
      Field: 'Type',
      TextAlign: TextAlign.Left,
      IsSortable: true,
    });

    const orderMethod = new TemplateColumn({
      HeaderText: 'Order Method',
      itemTemplate: this.orderMethod,
      Width: '12%',
      Field: 'OrderMethod',
      TextAlign: TextAlign.Left,
      IsSortable: true,
    });

    const nameColumn = new TemplateColumn({
      HeaderText: 'Name',
      itemTemplate: this.nameTemplate,
      Width: '15%',
      Field: 'Name',
      TextAlign: TextAlign.Left,
      IsSortable: true
    });


    this.orderColumns = [
      new GridColumn({ HeaderText: 'Order Id', Field: 'Order', IsSortable: true, Width: '75px' }),
      new GridColumn({ HeaderText: 'Order Number', Field: 'SurrogateOrderId', IsSortable: true, Width: '15%' }),
      new GridColumn({ HeaderText: this.accountTerm, Field: 'CustomerAccountName', IsSortable: true, Width: '15%' }),
      nameColumn,
      new DateColumn({ HeaderText: 'Time', Field: 'Date', IsSortable: true, Width: '10%', Format: Format.Time }),
      totalColumn,
      gratuityColumn,
      paidAmountColumn,
      paymentType,
      cashTenderedColumn,
      orderMethod,
      new GridColumn({ HeaderText: 'Revenue Center', Field: 'RevenueCenter', IsSortable: true, Width: '15%' }),
      settingColumn,
    ] as Array<GridColumn>;
  }

  backOneDay() {
    this.dateToFilter = new Date(this.dateToFilter.setDate(this.dateToFilter.getDate() - 1));
    this.getAllOrdersBetweenDate();
  }

  forwardOneDay() {
    this.dateToFilter = new Date(this.dateToFilter.setDate(this.dateToFilter.getDate() + 1));
    this.getAllOrdersBetweenDate();
  }

  loadOrderSummary(event) {
    this.isUnloadOrderSummary = false;
    if (event.data?.CustomerAccountId) {
      this.memberOperation = DomainConstants.LoyalAccountTermPrefix.Unlink;
    } else {
      this.memberOperation = DomainConstants.LoyalAccountTermPrefix.Link;
    }
    const orderDetails: OrderSummary = event.data;
    this.orderId = orderDetails.Order;
    this.selectedSurrogateId = orderDetails.SurrogateOrderId;
    this.isDisabledAddTip = false;
    this.checkIsAllowToAddTip(this.orderData ? this.orderData.OrderDateTime : this.dateToFilter);
    this.disabledRefund = orderDetails.Total <= 0 || (orderDetails.Total - orderDetails.PaidAmount) > 0;
    this.orderRefunded = orderDetails.RefundedQty > 0 || this.disabledRefund;
    this.getOrderRefundedAmount();
    if (!this.mergeOrderId) {
      this.isUnserved = orderDetails.IsUnserved ? true : false;
      this.isScheduledOrder = orderDetails.ScheduleDate && new Date(orderDetails.ScheduleDate) > new Date() && this.isUnserved;
      this.scheduledDateForSelectedOrder = orderDetails.ScheduleDate;
      forEach(this.orderList, (item) => {
        item.Color = '';
        item.IsScheduledOrder = item.ScheduleDate && new Date(item.ScheduleDate) > new Date();
      });
      orderDetails.Color = 'bg-theme-primary';
      this.sourceSurrogateOrderId = orderDetails.SurrogateOrderId;
    } else {
      this.destinationSurrogateOrderId = orderDetails.SurrogateOrderId;
      const modalRef = this.modalService.getModalWrapper(ConfirmDeleteModalComponent);
      const modal = modalRef.show({
        animated: false,
        class: 'vertical-center',
        'backdrop': 'static',
        initialState: {
          message: StringUtils.format(Messages.ConfirmMergeOrder,
            { 'fromOrder': this.sourceSurrogateOrderId, 'toOrder': this.destinationSurrogateOrderId })
        }
      });
      modal.close.subscribe(res => {
        if (res && res.shouldDelete) {
          this.mergeOrder();
        } else {
          this.getAllOrdersBetweenDate();
        }
      });
    }
  }

  printCheckOfOrder() {
    const printOrderModel: PrintOrder = this.orderService.newPrintOrder();
    if (this.orderId) {
      if (this.settingParam.ReceiptPrinter.Id) {
        printOrderModel.OrderId = this.orderId;
        printOrderModel.Parameter = this.settingParam;
        printOrderModel.SurrogateOrderId = this.selectedSurrogateId;
        printOrderModel.TerminalId = this.settingParam.TerminalId;
        printOrderModel.ReceiptPrinterId = this.settingParam.ReceiptPrinter.Id;
        printOrderModel.PrintCount = 1;
        this.orderService.printCheck(printOrderModel)
          .subscribe({
            next: () => {
              this.settingParam.PrintEachOrder = false;
            }, error: this.alertsService.showApiError
          });
      } else {
        this.alertsService.renderErrorMessage(Messages.ReceiptPrinterNotMapped);
      }
    }

  }

  onMemberOperation() {
    if (this.memberOperation === 'Link') {
      this.linkMemberToOrder();
    } else {
      this.confirmUnlink();
    }
  }

  linkMemberToOrder() {
    if (this.settingParam.LoyaltyProgramRequiresMemberInformation || !this.settingParam.IsEnableLoyaltyCardProgram) {
      const modalRef = this.modalService.getModalWrapper(SetAccountComponent);
      const modal = modalRef.show({
        animated: false,
        class: 'vertical-center modal-lg',
        initialState: {
          orderId: this.orderId,
          isSelectMember: true,
          fromOrderManagement: true,
          isTypeAccount: true
        }
      });
      modal.close.subscribe(() => {
        this.getAllOrdersBetweenDate();
      });
    } else {
      const loyaltyCardModalRef = this.modalService.getModalWrapper(SwipeCardComponent);
      const swipeModal = loyaltyCardModalRef.show({
        animated: false,
        class: 'vertical-center',
        backdrop: 'static',
        keyboard: false,
        initialState: {
          modalHeaderText: 'Loyalty Card',
          message: Messages.SwipeCardRequest
        }
      });
      swipeModal.close.subscribe((response) => {
        if (response && response.cardNumber) {
          this.setAccount.onSwipeDetected(response.cardNumber);
        }
      });
    }
  }

  hoverTableRow($event) {

  }

  popoverCreditDetail(data: OrderSummary, $event) {
    $event.preventDefault();
    if (data.CreditCardTransactionResponses) {
      this.selectedTransactionDetails = [...data.CreditCardTransactionResponses];
      this.selectedOrderTotal = data.PaidAmount + (data.Gratuity??0);
      this.selectedOrderTypeId = data.OrderTypeId;
    }
  }

  subscribeSetAccount() {
    this.eventBroadcastingService.setAccountCompleted.subscribe((res: Account) => {
      this.getAllOrdersBetweenDate();
    }, () => { }, (subscription) => this.rabbitMQSubscriptions.push(subscription));
  }

  voidOrder() {
    const modalRef = this.modalService.getModalWrapper(ConfirmDeleteModalComponent);
    const modal = modalRef.show({
      animated: false,
      class: 'vertical-center',
      'backdrop': 'static',
      initialState: {
        message: Messages.ConfirmDeleteVoidingOrder
      }
    });
    modal.close.subscribe(res => {
      if (res && res.shouldDelete) {
        this.voidTheSelectedOrder();
      }
    });
  }

  voidTheSelectedOrder() {
    this.spinnerService.show();
    const voidOrderModel: VoidOrder = this.orderService.newVoidOrder();
    voidOrderModel.OrderId = this.orderId;
    voidOrderModel.UserId = this.applicationStateService.userId;
    voidOrderModel.DrawerId = this.settingParam.CashDrawerPrinter.Id;
    voidOrderModel.CreditCardTerminal = this.settingParam.CreditCardTerminal;
    voidOrderModel.TerminalName = this.applicationStateService.terminalName;
    voidOrderModel.TerminalId = this.applicationStateService.terminalId;
    voidOrderModel.IsUnserved = this.isUnserved;
    const transactions = this.orderList.find(x => x.Order == this.orderId)?.CreditCardTransactionResponses;
    const isHaveCCTransactions = transactions?.filter(x => x.CCTxnRefNumber)?.length > 0;
    this.isVoidOrder = isHaveCCTransactions;
    this.managesServices.voidTheSelectedOrder(voidOrderModel)
      .pipe(finalize(() => {
        if (!isHaveCCTransactions) {
          this.spinnerService.hide();
        }
      }))
      .subscribe({
        next: () => {
          const voidOrderTimeout = setTimeout(() => {
            if (voidOrderTimeout) {
              clearTimeout(voidOrderTimeout);
            }
            this.getAllOrdersBetweenDate();
            this.getDrawerStatus();
            this.orderId = 0;
          }, 1000);
        }, error: this.alertsService.showApiError
      });
  }

  getDrawerStatus(isSilentReload = false) {
    if (!isSilentReload) {
      this.spinnerService.show();
    }
    this.managesServices.getDrawerStatus(this.settingParam.CashDrawerPrinter.Id)
      .pipe(finalize(() => {
        if (!isSilentReload) {
          this.spinnerService.hide();
        }
      }))
      .subscribe({
        next: (response) => {
          this.drawerStatusCompleted.emit({ response: response });
        }, error: this.alertsService.showApiError
      });
  }

  getOrderRefundedAmount() {
    this.spinnerService.show();
    this.orderService.getOrderRefundedAmount(this.orderId)
      .pipe(finalize(() => this.spinnerService.hide()))
      .subscribe({
        next: (response) => this.refundedOrderAmount = response,
        error: this.alertsService.showApiError,
      })
  }

  refundAmount() {
    if (this.orderData.GrandTotal <= Math.abs(this.refundedOrderAmount)) {
      this.alertsService.renderErrorMessage(Messages.OrderRefundNotAllowed);
    } else {
      const modalRef = this.modalService.getModalWrapper(RefundPaymentComponent);
      const modal = modalRef.show({
        animated: false,
        class: 'vertical-center modal-max-width-65',
        'backdrop': 'static',
        keyboard: false,
        initialState: {
          orderId: this.orderId,
        }
      });
      modal.close.subscribe(res => {
        if (res?.shouldReload) {
          this.getAllOrdersBetweenDate();
        }
      });
    }
  }

  mergeOrders() {
    if (this.orderId > 0) {
      this.mergeOrderId = this.orderId;
      remove(this.orderList, x => x.Order === this.orderId || !x.IsUnserved);
    }
  }

  mergeOrder() {
    this.memberOperation = DomainConstants.LoyalAccountTermPrefix.Link;
    this.spinnerService.show();
    const mergeOrderModel: MergeOrder = this.orderMaintenanceService.newMergeOrder();
    mergeOrderModel.TerminalId = this.applicationStateService.terminalId;
    mergeOrderModel.SourceOrderId = this.mergeOrderId;
    mergeOrderModel.DestinationOrderId = this.orderId;
    this.orderMaintenanceService.mergeOrder(mergeOrderModel)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: () => {
          this.alertsService.renderSuccessMessage(Messages.OrderMergeSuccess);
          this.orderId = null;
          this.getAllOrdersBetweenDate();
        }, error: this.alertsService.showApiError
      });
  }

  addTip() {
    if (this.creditCardTransactions) {
      if (this.creditCardTransactions.length > 1) {
        const modalRef = this.modalService.getModalWrapper(OrderTransactionsComponent);
        const modal = modalRef.show({
          animated: false,
          class: 'vertical-center',
          'backdrop': 'static',
          initialState: {
            transactions: this.creditCardTransactions
          }
        });
        modal.close.subscribe(res => {
          this.selectTransaction(res);
        });
      } else {
        this.selectTransaction(this.creditCardTransactions[0]);
      }
    }
  }

  onLinkPopOver(data, pop) {
  }

  unlinkMember() {
    if (this.orderId > 0) {
      this.spinnerService.show();
      this.orderService.unlinkOrderMember(this.orderId)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: (response) => {
            this.getAllOrdersBetweenDate();
            this.alertsService.renderSuccessMessage(
              StringUtils.format(Messages.UnlinkedLoyalAccountTermSuccess, { 'loyalAccountTerm': this.loyalAccountTerm }));
          }, error: this.alertsService.showApiError
        });
    }
  }

  private confirmUnlink(): void {
    const confirmDelete = this.modalService.show(ConfirmDeleteModalComponent, {
      animated: false,
      class: 'vertical-center',
      'backdrop': 'static',
      initialState: {
        message: StringUtils.format(Messages.ConfirmUnlinkedLoyalAccount, { 'loyalAccountTerm': this.loyalAccountTerm })
      }
    });
    confirmDelete.close.subscribe(res => {
      if (res && res.shouldDelete) {
        this.unlinkMember();
      }
    });
  }

  selectTransaction(transaction: Transactions) {
    if (transaction) {
      this.selectedTransactionId = transaction.Id.Value.toString();
      const modalRef = this.modalService.getModalWrapper(NumpadWrapperComponent);
      const modal = modalRef.show({
        animated: false,
        class: 'vertical-center',
        'backdrop': 'static',
        initialState: {
          numpadTitle: 'Tip Amount'
        }
      });
      modal.close.subscribe(res => {
        if (res?.value) {
          this.submitTipAmount(res.value);
        }
      });
    }
  }

  submitTipAmount(value) {
    if (this.selectedTransactionId) {
      this.tipInfoModalMessage = Messages.Connecting;
      this.tipInfoModalButtonText = 'Cancel';
      this.tipInfoModalHeader = 'Info';
      this.tipInfoModalRef = this.bsModalService.show(this.tipInfoModal, {
        animated: false,
        class: 'vertical-center',
        'backdrop': 'static',
        keyboard: false
      });
      this.modalBackdropService.addBackDrop(3);
      this.rabbitMQRequestIdForTip = this.rabbitMQService.guidWithTimeStamp();
      const tipTransaction = {
        Amount: value, TerminalId: this.applicationStateService.terminalId,
        SurrogateOrderId: this.selectedSurrogateId,
        RabbitMQRequestId: this.rabbitMQRequestIdForTip
      };
      this.orderService.tipAdjust(this.selectedTransactionId, tipTransaction)
        .subscribe({
          next: () => {
            this.checkCCTipStatusInterval = setInterval(() => {
              this.checkCCTipStatus();
            }, 5000);
          }, error: this.alertsService.showApiError
        });
    }
  }
  onCCTipTransactionGetSuccessStatus = () => {
    this.clearCCTipStatusInterval();
    this.tipInfoModalMessage = Messages.TipAddedSuccess;
    this.rabbitMQRequestIdForTip = '';
    this.tipInfoModalButtonText = 'Ok';
    this.tipInfoModalHeader = Messages.Success;
    this.getAllOrdersBetweenDate();
  }

  onCCTipTransactionGetCanceledStatus = () => {
    this.clearCCTipStatusInterval();
    this.tipInfoModalMessage = DomainConstants.TransactionStatus.Canceled;
    this.rabbitMQRequestIdForTip = '';
    this.tipInfoModalHeader = Messages.Error;
    this.tipInfoModalButtonText = 'Ok';
  }

  onCCTipTransactionGetFailedStatus = () => {
    this.clearCCTipStatusInterval();
    this.tipInfoModalMessage = Messages.TipAddFailed;
    this.rabbitMQRequestIdForTip = '';
    this.tipInfoModalHeader = Messages.Error;
    this.tipInfoModalButtonText = 'Ok';
  }
  clearCCTipStatusInterval() {
    if (this.checkCCTipStatusInterval) {
      clearInterval(this.checkCCTipStatusInterval);
    }
  }
  checkCCTipStatus = () => {
    if (this.rabbitMQRequestIdForTip && this.orderId) {
      this.orderService.getTransactionStatus(this.rabbitMQRequestIdForTip)
        .subscribe({
          next: (response: CreditCardPaymentStatus) => {

            if (response.Status == DomainConstants.TransactionStatus.Success) {
              this.onCCTipTransactionGetSuccessStatus();
            } else if (response.Status == DomainConstants.TransactionStatus.Failed) {
              this.onCCTipTransactionGetFailedStatus();
            } else if (response.Status == DomainConstants.TransactionStatus.Canceled) {
              this.onCCTipTransactionGetCanceledStatus();
            }
            else if (this.tipInfoModalMessage == Messages.Connecting && response.Status == DomainConstants.TransactionStatus.Pending) {
              this.tipInfoModalMessage = Messages.StillConnectingForTip;
            }
          }, error: this.alertsService.showApiError
        });
    } else {
      this.clearCCTipStatusInterval();
    }
  }

  cancelTipInfoModal() {
    this.tipInfoModalRef.hide();
    this.modalBackdropService.removeBackdrop();
    this.clearCCTipStatusInterval();
  }

  subscribeToHICResponseToTerminal() {
    this.rabbitMqHICResponseSubscription = this.rabbitMQService.subscribeToHICResponseToTerminal$(this.applicationStateService.terminalId)
      .subscribe((message) => {
        const responseObject = message.Payload.HICReponse;
        this.tipInfoModalButtonText = 'Cancel';
        this.tipInfoModalHeader = 'Info';
        if (responseObject.HardwareType === 'CreditCardTerminal') {
          if (responseObject.Status === 'Connected') {
            if (responseObject.RequestType === 'TipAdjust') {
              this.tipInfoModalMessage = Messages.TipAddedSuccess;
              this.clearCCTipStatusInterval();
            } else {
              this.tipInfoModalMessage = Messages.SwipeCardRequest;
            }
          } else if (responseObject.Status === 'Busy') {
            this.clearCCTipStatusInterval();
            if (responseObject.ConnectedToTerminalId === this.applicationStateService.terminalId) {
              this.tipInfoModalMessage = Messages.CreditCardTerminalBusyWithPreviousRequest;
            } else {
              this.tipInfoModalMessage = StringUtils.format(Messages.CreditCardTerminalBusy,
                { 'terminalName': responseObject.ConnectedToTerminalName, 'creditCardTerminalName': responseObject.DeviceName });
            }
            this.tipInfoModalButtonText = 'OK';
          } else if (responseObject.Status === 'Error') {
            this.tipInfoModalMessage = responseObject.Message;
            this.tipInfoModalButtonText = 'OK';
            this.tipInfoModalHeader = 'Error';
            this.clearCCTipStatusInterval();
          }
        }
      });
  }

  subscribePaymentSuccessMessages() {
    this.rabbitMqOrderEntryTerminalQueueSubscription = this.rabbitMQService.subscribeToOrderPaymentResponseTerminalQueue$(this.applicationStateService.terminalId)
      .subscribe((message: any) => {
        this.paymentResponseCompleted(message.Payload.PaymentResponse);
      });
  }

  paymentResponseCompleted(response) {
    if (response.PaymentCompleted) {
      if (response.RequestType === 'TipAdjust') {
        if (response.PaymentSuccessfullyComplete) {
          this.tipInfoModalMessage = 'Tip Added';
          this.tipInfoModalHeader = Messages.Success;
          this.getAllOrdersBetweenDate();
        } else {
          this.tipInfoModalHeader = Messages.Error;
          this.tipInfoModalMessage = response.PaymentResponseFromWeb.Message;
        }
        this.tipInfoModalButtonText = 'OK';
      }
      if (response.RequestType === 'VoidTransaction') {
        if (!response.PaymentSuccessfullyComplete) {
          this.spinnerService.hide();
          this.alertsService.renderErrorMessage(StringUtils.format(Messages.ErrorWhileVoidTransaction, { 'errorMessage': response.PaymentResponseFromWeb.Message }));
        } else {
          if (!this.isVoidOrder) {
            this.alertsService.renderSuccessMessage(Messages.TransactionVoidSuccess);
          }
        }
        this.spinnerService.hide();
        this.getAllOrdersBetweenDate(true);
        this.getDrawerStatus(true);
      }
    }
  }

  addedProductToOrderCompleted(orderData: OrderInvoice) {
    this.orderData = orderData;
    this.isDisabledAddTip = false;
    if (this.orderId) {
      this.getOrderTransactions();
    }
  }

  getOrderTransactions() {
    this.spinnerService.show();
    this.orderService.getTransactions(this.orderId)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (response: Array<Transactions>) => {
          this.orderTransactions = response;
          if (this.orderTransactions && this.orderTransactions.length) {
            this.creditCardTransactions = filter(this.orderTransactions, (transaction) => {
              return transaction.PaymentTypeId === DomainConstants.PaymentTypes.CREDITCARD &&
                transaction.TransactionType != DomainConstants.TransactionTypes.Void;
            });
            this.checkIsAllowToAddTip(this.orderData ? this.orderData.OrderDateTime : this.dateToFilter);
          }
        }, error: this.alertsService.showApiError
      });
  }

  checkIsAllowToAddTip(orderDate) {
    if (this.creditCardTransactions && this.creditCardTransactions.length) {
      const endOfDayTime = this.settingParam.EndOfDay ? this.settingParam.EndOfDay : '00:00:00';
      const endOfDaySplit = endOfDayTime.split(':');
      const currentDate = new Date();
      const endDate = new Date();
      endDate.setHours(endOfDaySplit[0], endOfDaySplit[1], endOfDaySplit[2]);
      if (currentDate > endDate) {
        endDate.setDate(endDate.getDate() + 1);
      }
      const startDate = cloneDeep(endDate);
      startDate.setDate(startDate.getDate() - this.settingParam.AllowAddingTipTillDays);
      this.isDisabledAddTip = new Date(orderDate) > startDate && new Date(orderDate) < endDate;
    } else {
      this.isDisabledAddTip = false;
    }
  }

  closeDialog() {
    this.close.emit();
  }

  rescheduleOrder() {
    const modalRef = this.modalService.getModalWrapper(TimeEntryComponent);
    const modal = modalRef.show({
      animated: false,
      class: 'vertical-center modal-lg',
      initialState: {
        title: 'Reschedule Order',
        isRescheduleOrder: true,
        instruction: Messages.TimedOrderInstructions,
        scheduleDate: this.scheduledDateForSelectedOrder ?? null
      }
    });
    modal.close.subscribe((response) => {
      if (response && response.enteredTime) {
        this.updateScheduleDate(response.enteredTime);
      }
    });
  }

  updateScheduleDate(scheduleDate: Date) {
    if (this.orderId) {
      this.spinnerService.show();
      this.orderService.scheduleOrder(this.orderId, scheduleDate)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: () => {
            this.orderData = null;
            this.isUnloadOrderSummary = true;
            this.getAllOrdersBetweenDate();
          }, error: this.alertsService.showApiError
        });
    }
  }

  updatePopoverRef(pop: TemplateRef<any>) {
    this.bsPopover = pop;
  }

  changeOrderMethod(data: OrderSummary) {
    const modalRef = this.modalService.getModalWrapper(SetOrderMethodComponent);
    const modal = modalRef.show({
      animated: false,
      class: 'vertical-center',
      initialState: {
        order: data
      }
    });
    modal.close.subscribe((res) => {
      if (res?.shouldReload) {
        const reloadOrders = setTimeout(() => {
          this.getAllOrdersBetweenDate();
          clearTimeout(reloadOrders);
        }, 300);
      }
    });
  }
}
