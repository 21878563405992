import { Injectable } from '@angular/core';
import { BaseCrudService } from './base-crud.service';
import { KioskExcludedDietaryWarning } from 'src/app/order-kiosk';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class OrderKioskExcludedDietaryService extends BaseCrudService<KioskExcludedDietaryWarning> {

  constructor(private httpClient: HttpClient) {
    super('kiosk-excluded-dietary', httpClient);
  }

  newExcludedDietaryWarning() {
    return {
      Id: 0,
      DietaryWarningId: 0
    } as KioskExcludedDietaryWarning;
  }


}
