<div class="page-content">
  <div class="tab-content">
    <div class="tab-pane fade in active show">
      <div class="col-12 p-0">
        <a class="close pe-pix-20" routerLink="../../">×</a>
        <div class="col-10 p-0 display-flex ps-pix-20">
          <pos-icon [icon]="icons.terminalTv" class="pe-pix-10 vertical-center-align"></pos-icon>
          <h3 class="page-content__header">
            Device Mapping
          </h3>
        </div>
        <form (ngSubmit)="saveDeviceMapping(deviceMappingForm.valid)" #deviceMappingForm="ngForm">
          <div class="col-12 px-4 border-top content-scroll">
            <div class="col-12">
              <div>
                <h3 class="setting-title mt-pix-5">Terminal: {{ terminalName }}</h3>
              </div>
              <div *ngFor="let hardwareGroup of hardwareTypes; let indexGroup = index; ">
                <div class="hardware-group" [ngClass]="{'border-top': indexGroup != 0}">
                  <div class="row">
                    <div class="col-12 ms-pix-10">
                      <b>{{hardwareGroup[0].HardwareGroup}}</b>
                    </div>
                  </div>
                  <ul>
                    <li *ngFor="let hardware of hardwareGroup; let index = index let last = last" class="ms-pix-10 me-pix-10"
                      (click)="selectedField = hardware.Type" [ngClass]="{'bgColor': index % 2 != 0}">

                      <div class="pt-pix-10 mb-1 row" id="{{hardware.Type}}" [ngClass]="{'mb-pix-10': last}">
                        <div class="col-lg-2 col-md-4 col-sm-12 text-center">
                          <label class="label-section text-center font-weight-600">
                            <div [ngStyle]="{'height': '40px'}">
                              <img [src]="hardware?.ImagePath" alt="device-audio-notifer" height="40" width="40">
                            </div>{{ hardware.Description }}
                          </label>
                        </div>
                        <div class="col-md-6 col-xs-12 pt-pix-7 position-relative">
                          <ng-select posInput *ngIf="hardware.HardwareType!=cameraHardWareType"
                            [items]="hardware.hardwareDevices" appendTo="body" bindLabel="Name" bindValue="Id"
                            placeholder="None" [(ngModel)]="hardware.DeviceId"
                            name="DeviceName_{{hardware.Description}}" #DeviceName_{{hardware.Description}}="ngModel">
                          </ng-select>
                          <ng-select posInput *ngIf="hardware.HardwareType==cameraHardWareType" [multiple]="true"
                            [items]="hardware.hardwareDevices" appendTo="body" bindLabel="Name" bindValue="Id"
                            placeholder="None" [(ngModel)]="cameraDeviceIds" name="DeviceName_{{hardware.Description}}"
                            #DeviceName_{{hardware.Description}}="ngModel">
                          </ng-select>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div *ngIf="!hardwareTypes.length">No hardware(s) mapped.</div>
            </div>
          </div>
          <div class="col-12 action-button-container page-footer">
            <button type="submit" class="btn-primary btn-lg btn btn-save pos-button" scrollup>Save</button>
            <button type="button" class="btn-secondary btn-lg btn btn-cancel pos-button" scrollup
              routerLink="../../">Cancel</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
