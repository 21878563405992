import { Account } from "src/app/shared";

export class Diagram {
    Id: number;
    Name: string;
    IsActive: boolean;
    LayoutDefinition: string;
    // AccountVisualizations: AccountVisualization[];
    // DeletedVisualizations?: AccountVisualization[];
  }

  export class AccountVisualization {
    IsAccount: boolean;
    Id: number;
    AccountId: any;
    Label?: string;
    Shape: Shape;
    Account?: Account;
    OrderId?: string;
    Amount?: string;
    TooltipHTML?: string;
    IsActive?: boolean;
  }

  export class Shape {
    Type: string;
    Text?: string;
    SeatingCapacity?: number;
    ReadableLocation?: string;
    BackgroundColor?: string;
    BorderColor?: string;
    FontColor?: string;
    Size: Size;
    Rotation?: number;
    Location: Location;
    AdditionalInfo: object = {};
    Rounded?: number;
    WhiteSpace?: string;
    Aspect?: string;
    // used for line shapes
    SourcePoint?: Location;
    TargetPoint?: Location;
    Dashed?: number;
    Shape?: string;
    JettySize?: string;
    OrthogonalLoop?: string;
    EndArrow?: string;
    StartArrow?: string;
    StartFill?: number;
    EndFill?: number;
    TargetPerimeterSpacing?: number;
    SourcePerimeterSpacing?: number;
    EndSize?: number;
    StartSize?: number;
    StrokeWidth?: number;
    Curved?: number;
    Width?: number;
  }

  export class Location {
    constructor (x: number, y: number) {
      this.X = x;
      this.Y = y;
    }

    X: number;
    Y: number;
  }

  export class Size {
    constructor (w: number, h: number) {
      this.Width = w;
      this.Height = h;
    }
    Width: number;
    Height: number;
  }
