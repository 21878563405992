import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { GridColumn, TableComponent, TarkDatePipe, TemplateColumn, TextAlign } from '@tarktech/tark-ng-utils';
import { cloneDeep, filter, find, forEach, remove } from 'lodash';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { finalize, take } from 'rxjs/operators';
import {
  AlertsService, ApplicationStateService, DomainConstants, HardwareService, InfoModalComponent, InventoryLogService,
  InventoryOrderReceivingNewBarcodeList, InventoryOrderReceivingProductList, InventoryProductBarCodeService, Permissions, Levels, Messages,
  ModalBackdropService, ModalService, NumpadWrapperComponent, SettingParam, SpinnerService
} from 'src/app/shared';
import { HardwareModel } from 'src/app/shared/interface/hardware-model';
import { InventoryProductLog, InvVendor, VendorAndCategory } from '../../../inventory-log/interfaces';

import { plusWhite, editWhite, deleteWhite, editWhiteWithText } from 'src/app/shared/components/icon';
import { ConfirmInventoryReceivingComponent } from '../confirm-inventory-receiving/confirm-inventory-receiving.component';
import { InventoryReceived } from '../../../../../shared/interface/inventory-received';
import { InventoryReceivingService } from '../../services/inventory-receiving.service';
import { InventoryProduct } from '../../interfaces/inventory-product';
import Pluralize from 'pluralize';
import { Router } from '@angular/router';
import { InventoryDuplicateEntryComponent } from '../inventory-duplicate-entry/inventory-duplicate-entry.component';
import { InventoryProductEditModelComponent } from 'src/app/information-management/inventory-products';
import { InventoryOffSiteLocation, InventoryOffSiteLocationService } from 'src/app/information-management/inventory-off-site-locations';
declare let $: any;
@Component({
  selector: 'pos-inventory-receiving',
  templateUrl: './inventory-receiving.component.html',
  styleUrls: ['./inventory-receiving.component.scss']
})
export class InventoryReceivingComponent implements OnInit {

  settingParams: SettingParam;
  inventoryProductList: Array<InventoryProduct> = [];
  inventoryProductLogList: Array<InventoryProduct> = [];
  inventoryId: number;
  selectedVendorId: number;
  vendors: Array<InvVendor> = [];
  productList: Array<InventoryOrderReceivingProductList> = [];
  barcodeList: Array<InventoryOrderReceivingNewBarcodeList> = [];
  selectedProduct: InventoryOrderReceivingProductList;
  labelPrinters: Array<HardwareModel> = [];
  receivingType: string = 'Now';
  currentDate: Date;
  currentTime: string;
  barcodeText: string = '';
  unitOverride?: any;
  barcodeProductList: Array<InventoryOrderReceivingNewBarcodeList> = [];
  @ViewChild('formInventoryReceiving') formInventoryReceiving: NgForm;
  @ViewChild('productSelectionModal') public productSelectionModal: any;
  @ViewChild('selectFromMultipleInventoryProductModal') public selectFromMultipleInventoryProductModal: any;
  isLabelPrinting: boolean = false;
  inventoryProductName: string = '';
  editedProductId: number;
  productAdditionalParams = {
    VendorId: 0
  };
  screenHeight = $(window).height() - 470 + 'px';
  productSelectionModalRef: BsModalRef;
  multipleInventoryProductModalRef: BsModalRef;
  @ViewChild('qtyReceivedTemplate', { static: true }) private qtyReceivedTemplate: any;
  @ViewChild('unitQtyOverrideTemplate', { static: true }) private unitQtyOverrideTemplate: any;
  @ViewChild('deleteTemplate', { static: true }) private deleteTemplate: any;
  @ViewChild('addProductTemplate', { static: true }) private addProductTemplate: any;
  @ViewChild('inventoryProductReceiving', { static: true }) inventoryProductReceiving: TableComponent;
  inventoryProductReceivingColumns: Array<GridColumn> = [];
  isShowUnitQtyOverride: boolean = false;
  public icons = {
    plusWhite,
    deleteWhite,
    editWhite,
    editWhiteWithText
  };
  isMobileMode = false;
  dateFormat = 'mm-dd-yyyy';
  permission = {
    inventoryProduct: Permissions.InventoryProducts,
    editLevel: Levels.Edit,
  };
  inventoryLocations: Array<InventoryOffSiteLocation> = [];
  selectedLocation = 0;
  selectedLocationName = DomainConstants.OnSiteInventoryText;
  showCancelButton = false;

  constructor(private alertService: AlertsService,
    private spinnerService: SpinnerService,
    private applicationStateService: ApplicationStateService,
    private inventoryLogService: InventoryLogService,
    private hardwareService: HardwareService,
    private inventoryProductBarcodeService: InventoryProductBarCodeService,
    private modalService: ModalService,
    private bsModalService: BsModalService,
    private modalBackdropService: ModalBackdropService,
    private inventoryReceivingService: InventoryReceivingService,
    private inventoryOffSiteLocationService: InventoryOffSiteLocationService,
    private tarkDatePipe: TarkDatePipe,
    private router: Router) {
    const navigation = router.getCurrentNavigation();
    this.selectedLocation = navigation?.extras?.state?.SelectedLocationId ?? 0;
  }

  ngOnInit(): void {
    this.setDefaults();
    this.getVendorAndCategory();
    this.getInventoryProducts();
    this.getHardwareByType();
    this.getInventoryLocations();
    this.configureGridColumns();
    this.screenHeight = $(window).height() - 470 + 'px';
    this.checkIsMobileMode();
    $(window).resize(() => {
      this.checkIsMobileMode();
    });
    this.dateFormat = this.applicationStateService.settingParam.PCalendarDateFormat;
  }

  configureGridColumns() {
    const qtyReceivedTemp = new TemplateColumn({
      HeaderText: 'Qty Received',
      Field: 'QtyReceived',
      IsSortable: false,
      Width: '120px',
      itemTemplate: this.qtyReceivedTemplate,
      TextAlign: TextAlign.Right,
      CellClass: 'override-cell-class',
    });

    const unitQtyOverrideTemp = new TemplateColumn({
      HeaderText: 'Override Unit Qty',
      itemTemplate: this.unitQtyOverrideTemplate,
      TextAlign: TextAlign.Right,
      Width: '130px',
      Field: 'UnitQtyOverride',
      IsSortable: false,
      CellClass: 'override-cell-class',
    });

    const operationColumn = new TemplateColumn({
      itemTemplate: this.deleteTemplate,
      Width: '60px',
      headerTemplate: this.addProductTemplate,
      CellClass: 'cell-padding',
      TextAlign: TextAlign.Center
    });

    this.inventoryProductReceivingColumns = [
      new GridColumn({ HeaderText: 'Product', Field: 'ProductName', IsSortable: false, Width: '25%' }),
      qtyReceivedTemp,
      unitQtyOverrideTemp,
      new GridColumn({ HeaderText: 'Unit(s)', Field: 'Unit', IsSortable: false, Width: '200px' }),
      new GridColumn({ HeaderText: 'Unit Details', Field: 'UnitDetails', IsSortable: false, Width: '20%' }),
      operationColumn
    ];
  }

  setDefaults = () => {
    this.settingParams = this.applicationStateService.settingParam;
    const cachedProducts = this.applicationStateService.inventoryOrderReceivingProductList;
    this.productList = cachedProducts?.length ? cachedProducts : [];
    const cachedBarcodes = this.applicationStateService.inventoryOrderReceivingNewBarcodeList;
    this.barcodeList = cachedBarcodes?.length ? cachedBarcodes : [];
    this.currentDate = new Date();
    this.setShowCancelButton();
    $('#barcodeName').focus();
  }

  getInventoryProducts = (vendorId?: number) => {
    this.spinnerService.show();
    this.inventoryReceivingService.getInventoryReceivingProducts(vendorId ? vendorId : 0)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res: Array<InventoryProduct>) => {
          this.getInventoryLoggingCompleted(res);
        }, error: this.alertService.showApiError
      });
  }

  getInventoryLoggingCompleted = (res: Array<InventoryProduct>) => {
    this.inventoryProductList = res ? res : [];
    forEach(this.productList, (product) => {
      const inventoryProduct = find(this.inventoryProductList, x => x.Id === product.Id);
      if (inventoryProduct) {
        product.ProductName = inventoryProduct.Product;
        product.inventoryUnit = inventoryProduct.Unit;
        product.UnitQty = inventoryProduct.Qty;
        product.Unit = (product.QtyReceived ? Pluralize(product.inventoryUnit,
          product.QtyReceived) : product.inventoryUnit) + ', Unit Qty: ' + product.UnitQty;
        product.UnitDetails = inventoryProduct.UnitDetails;
      }
    });
    this.inventoryProductLogList = res ? res : [];
    this.getInventoryProductByVendor(this.selectedVendorId ? this.selectedVendorId : 0);
  }

  getInventoryProductByVendor = (vendorId?: number) => {
    this.inventoryProductLogList = [];
    if (vendorId) {
      this.inventoryProductLogList = filter(this.inventoryProductList, (item) => {
        return item.VendorId === vendorId;
      });
    } else {
      this.inventoryProductLogList = cloneDeep(this.inventoryProductList);
    }
  }

  getInventoryProductById = (inventoryProductId: number, inventoryProductList: Array<InventoryProduct>): InventoryProduct => {
    if (inventoryProductList && inventoryProductList.length) {
      const inventoryProduct = find(inventoryProductList, (item) => {
        return item.Id === inventoryProductId;
      });
      return inventoryProduct;
    }
  }

  addNewBarcode = (inventoryProductId: number, barcodeId, barcode: string, unitQtyOverride?: number) => {
    const barcodeDetails = {
      Id: barcodeId,
      InventoryProductId: inventoryProductId,
      Barcode: barcode,
      UnitQtyOverride: unitQtyOverride
    } as InventoryOrderReceivingNewBarcodeList;
    this.barcodeList.push(barcodeDetails);
  }

  saveInventoryProductBarcodes = () => {
    this.spinnerService.show();
    this.inventoryProductBarcodeService.saveInventoryProductBarcodes(this.barcodeList)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (response: Array<InventoryOrderReceivingNewBarcodeList>) => {
          this.getInventoryProducts(0);
        }, error: this.alertService.showApiError
      });
  }

  editInventoryReceived = () => {
    this.router.navigate(['manage/inventory-receiving-edit'], { state: { SelectedLocationId: this.selectedLocation } });
  }

  openOverridePriceModal(product, editMode: boolean, index: number) {
    const productQtyReceived = 1;
    this.selectedProduct = cloneDeep(product);
    this.inventoryId = this.selectedProduct.Id;
    const modalRef = this.modalService.getModalWrapper(NumpadWrapperComponent);
    const modal = modalRef.show({
      animated: false,
      class: 'vertical-center',
      keyboard: false,
      initialState: {
        numpadId: "qtyReceivedModal",
        value: editMode ? product.QtyReceived : productQtyReceived,
        numpadTitle: "Qty Received",
        numpadOption: {
          allowDecimal: true,
          forceDecimalNumber: false,
          startWithDecimal: false,
          cancelButtonText: 'Cancel',
          doubleZero: false,
          additionalInformationText: `
            <div class="pt-2 fs-5">
              <span class="fw-bold">Unit Qty:</span> ${this.selectedProduct.UnitQty} ${this.selectedProduct.inventoryUnit}
            </div>`,
          additionalInfoFooterText: `
            <div class="text-align-end fs-5">
              <span>${this.selectedProduct.PurchaseUnit ?? ''}</span>
            </div>
            `,
          validationCallBack: (parameters: { field: string, value: number }) => {
            const qty = parameters.value * this.selectedProduct.UnitQty;
            if (qty && this.inventoryLogService.hasUnitQtyIncrementError(qty, this.selectedProduct.UnitQtyIncrement)) {
              return [
                { field: 'value', message: `<span class="fs-5">Value should be in multiple of ${this.selectedProduct.UnitQtyIncrement}</span>` }
              ];
            }
            return [];
          }
        }
      }

    });
    modal.close.subscribe((response) => {
      const existingProduct = find(this.productList, (item) =>
        item.ProductId === product.ProductId &&
        item.Barcode === product.Barcode &&
        item.UnitQtyOverride === product.UnitQtyOverride)
      if (!existingProduct && Object.keys(response).length) {
        this.addProductToReceiving(product);
        index = this.productList.indexOf(product);
      } else if (existingProduct && response.value) {
        index = (index == -1) ? this.productList.indexOf(existingProduct) : index;
        product.QtyReceived = this.productList[index].QtyReceived + 1;
      }
      if (!editMode) {
        response.value = Number(response.value) + Number(product.QtyReceived - 1);
      }
      if (response && (response.value || response.value == 0)) {
        this.updateQtyReceived(response, index);
      }
    });
  }

  openUpdateUnitQtyOverrideModal(product, index) {
    this.selectedProduct = cloneDeep(product);
    this.inventoryId = this.selectedProduct.Id;
    const modalRef = this.modalService.getModalWrapper(NumpadWrapperComponent);
    const modal = modalRef.show({
      animated: false,
      class: 'vertical-center',
      keyboard: false,
      initialState: {
        numpadId: "unitQtyOverrideModal",
        value: product.UnitQtyOverride,
        numpadTitle: "Override Unit Qty",
        numpadOption: {
          allowDecimal: false,
          cancelButtonText: 'Cancel',
          additionalInformationText: `<div class="pt-2 fs-5"><span class="fw-bold">Unit Qty:</span> ${this.selectedProduct.UnitQty} ${this.selectedProduct.inventoryUnit}</div>`
        }
      }

    });
    modal.close.subscribe((response) => {
      if (response && (response.value || response.value == 0)) {
        this.updateUnitQtyOverride(response, index);
      }
      $('#barcodeName').focus();
    });
  }

  updateQtyReceived = (res, index) => {
    const updatedQty = res && Number(res.value) ? Number(res.value) : 0;
    const product = this.productList[index]
    product.QtyReceived = updatedQty;
    product.Unit = (product.QtyReceived ? Pluralize(product.inventoryUnit,
      product.QtyReceived) : product.inventoryUnit) + ', Unit Qty: ' + product.UnitQty;
  }

  updateUnitQtyOverride = (res, index) => {
    const updatedQty = res && res.value ? Number(res.value) : 0;
    const product = this.productList[index];
    product.UnitQtyOverride = updatedQty;
    const availableProduct = find(this.barcodeList, (barcode) => {
      return barcode.InventoryProductId === this.selectedProduct.Id && barcode.Barcode === this.selectedProduct.Barcode;
    });
    if (availableProduct) {
      availableProduct.UnitQtyOverride = updatedQty;
    } else {
      if (this.selectedProduct && this.selectedProduct.Barcode && this.selectedProduct.Barcode.trim()) {
        this.addNewBarcode(this.selectedProduct.Id, this.selectedProduct.BarcodeId,
          this.selectedProduct.Barcode, updatedQty);
      }
    }
  }

  getVendorAndCategory() {
    this.spinnerService.show();
    this.inventoryLogService.getVendorAndCategory()
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (response: VendorAndCategory) => {
          this.vendors = response && response.VendorModel ? response.VendorModel : [];
          this.selectedVendorId = null;
        }, error: this.alertService.showApiError
      });
  }

  getHardwareByType = () => {
    this.spinnerService.show();
    this.hardwareService.getHardwaresOfType(DomainConstants.HardwareTypes.LabelPrinter)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (response: Array<HardwareModel>) => {
          this.labelPrinters = response ? response : [];
        }, error: this.alertService.showApiError
      });
  }

  getInventoryLocations() {
    this.spinnerService.show();
    this.inventoryOffSiteLocationService.getActiveLocations()
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (response: Array<InventoryOffSiteLocation>) => {
          this.inventoryLocations = response;
          this.setLocationName();
        }, error: this.alertService.showApiError
      });
  }

  setDefaultDate = () => { this.currentDate = new Date(); };

  searchBarcode = () => {
    const barcodeProducts = this.getMatchingProductsByBarcode(this.barcodeText);
    if (barcodeProducts && barcodeProducts.length > 1) {
      this.barcodeProductList = cloneDeep(barcodeProducts);
      this.openMultipleInventoryProductSelectionModal();
    } else if (barcodeProducts && barcodeProducts.length == 1) {
      this.checkDuplicateReceiving(barcodeProducts[0]).then(() => {
        this.addProductToReceiving(barcodeProducts[0]);
      });
      this.resetModels();
    } else {
      this.isShowUnitQtyOverride = true;
      this.openInventoryProductSelectionModal();
    }
  }

  resetModels = () => {
    this.barcodeText = '';
    this.unitOverride = null;
    $('#barcodeName').focus();
    this.selectedProduct = null;
    this.applicationStateService.inventoryOrderReceivingNewBarcodeList = this.barcodeList;
    this.applicationStateService.inventoryOrderReceivingProductList = this.productList;
    this.inventoryId = null;
    this.selectedVendorId = null;
    this.isShowUnitQtyOverride = false;
  }

  addInventoryProductRow = () => {
    if (this.inventoryId) {
      const inventoryProduct = this.getInventoryProductById(this.inventoryId, this.inventoryProductLogList);
      let barCodeFlag = false;
      const barcodeProduct = this.prepareBarcodeProduct(this.barcodeText, 0, inventoryProduct,
        this.unitOverride ? this.unitOverride : null);
      if (this.barcodeText && this.barcodeText.trim()) {
        this.checkDuplicateReceiving(barcodeProduct).then(() => {
          this.addProductToReceiving(barcodeProduct);
        })

        barCodeFlag = true;
        const unitQtyOverride = (!this.unitOverride || this.unitOverride === '' ? null :
          parseFloat(this.unitOverride));
        this.addNewBarcode(this.inventoryId, 0, this.barcodeText.trim(), unitQtyOverride);
      }
      this.closeInventoryProductSelectionModal();
      this.resetModels();
      if (!barCodeFlag) {
        this.bsModalService.onHidden.pipe(take(1)).subscribe({
          next: () => {
            this.checkDuplicateReceiving(barcodeProduct).then(() => {
              this.openOverridePriceModal(barcodeProduct, false, -1);
            })
          }
        });
      }
    }
  }

  checkDuplicateReceiving(product): Promise<boolean> {
    return new Promise<boolean>((_resolve, _reject) => {
      this.spinnerService.show();
      if (this.receivingType != 'Select') {
        this.currentDate = new Date();
      }
      this.inventoryReceivingService.getInventoryReceivingPresentDay(product?.Id, this.currentDate.toDateString()).pipe(
        finalize(() => {
          this.spinnerService.hide();
        })).subscribe({
          next: (response) => {
            if (response.length) {

              forEach(response, (item) => {
                item.InventoryOffsiteLocation = find(this.inventoryLocations, il => (il.Id == (item.InventoryOffsiteLocationId ?? 0)))?.Name;
              });

              const dateTime = this.currentDate.toDateString() == new Date().toDateString() ? "Today" : this.tarkDatePipe.transform(this.currentDate.valueOf());
              const confirmReceive = this.modalService.show(InventoryDuplicateEntryComponent, {
                animated: false,
                class: 'vertical-center',
                'backdrop': 'static',
                initialState: {
                  productReceived: product,
                  productList: response,
                  date: this.receivingType == 'Select' ? dateTime : "Today"
                }
              });
              confirmReceive.close.subscribe(() => {
                return _resolve(true);
              });
            } else {
              return _resolve(true);
            }
          }, error: (error) => {
            this.alertService.showApiError(error);
            _reject(true)
          }
        });
    });
  }

  addInventoryProductRowFromMultiple = (inventoryProduct) => {
    this.addProductToReceiving(inventoryProduct);
    this.closeMultipleInventoryProductSelectionModal();
    this.resetModels();
  }

  addProductToReceiving = (product: InventoryOrderReceivingProductList) => {
    let productWithBarcodeIndex, productIndex;
    const matchedProduct = find(this.productList, (item) =>
      item.ProductId === product.ProductId &&
      item.Barcode === product.Barcode &&
      item.UnitQtyOverride === product.UnitQtyOverride
    );
    if (!matchedProduct) {
      this.productList.unshift(product);
    } else {
      matchedProduct.QtyReceived++;
      matchedProduct.Unit = (matchedProduct.QtyReceived ? Pluralize(matchedProduct.inventoryUnit,
        matchedProduct.QtyReceived) : matchedProduct.inventoryUnit) + ', Unit Qty: ' + matchedProduct.UnitQty;

      productWithBarcodeIndex = this.productList.findIndex(x => x.Barcode && x.Barcode === product.Barcode &&
        x.ProductId === product.ProductId);
      productIndex = this.productList.findIndex(x => !x.Barcode && x.Id === product.Id);

      if (productWithBarcodeIndex >= 0) {
        this.productList.splice(productWithBarcodeIndex, 1);
        this.productList.unshift(matchedProduct);
      } else if (productIndex >= 0) {
        this.productList.splice(productIndex, 1);
        this.productList.unshift(matchedProduct);
      }
    }
    this.setShowCancelButton();
  }



  deleteProductFromReceiving = (product: InventoryOrderReceivingProductList) => {
    remove(this.productList, (item) => {
      return item.ProductId === product.ProductId && item.UnitQtyOverride === product.UnitQtyOverride
        && item.BarcodeId === product.BarcodeId;
    });
    remove(this.barcodeList, (barcode) => {
      return barcode.InventoryProductId === product.ProductId &&
        barcode.Barcode.toLowerCase() === product.Barcode.toLowerCase() &&
        barcode.UnitQtyOverride === product.UnitQtyOverride;
    });
    this.applicationStateService.inventoryOrderReceivingProductList = this.productList;
    this.applicationStateService.inventoryOrderReceivingNewBarcodeList = this.barcodeList;
    this.setShowCancelButton();
    $('#barcodeName').focus();
  }

  saveInventoryProductReceivingCompleted = (response) => {
    if (response) {
      this.cancel(true);
      this.alertService.renderSuccessMessage(Messages.ReceivingProductSaveSuccess);
      if (!this.selectedLocation) {
        if (response.length
          && this.isLabelPrinting && this.labelPrinters && this.labelPrinters.length > 0) {
          this.openInfoModal(Messages.ReceivingInstructionsAndLabelPrinted);
        } else if (this.isLabelPrinting && this.labelPrinters && this.labelPrinters.length > 0) {
          this.openInfoModal(Messages.ReceivingLabelPrinted);
        } else if (response.length) {
          this.openInfoModal(Messages.ReceivingInstructionsPrinted);
        }
      }
    }
  }

  openInfoModal = (message) => {
    const modalRef = this.modalService.show(InfoModalComponent, {
      animated: false,
      class: 'vertical-center',
      keyboard: false,
      initialState: {
        message: message,
      }
    });
    modalRef.close.subscribe(() => {
      $('#barcodeName').focus();
    });
  }

  addEditInventoryProduct = (productId: number) => {
    this.inventoryProductName = '';
    this.editedProductId = productId;
    if (productId > 0) {
      const product = find(this.inventoryProductLogList, (item) => {
        return item.Id === productId;
      });
      if (product) {
        this.inventoryProductName = ': ' + product.Product;
      }
    }
    this.productAdditionalParams.VendorId = this.selectedVendorId;
    this.openInventoryProductEditModal();
  }

  openInventoryProductSelectionModal = () => {
    this.inventoryId = null;
    this.selectedProduct = null;
    this.getInventoryProductByVendor(0);
    this.productSelectionModalRef = this.bsModalService.show(this.productSelectionModal, {
      'backdrop': 'static',
      'class': 'vertical-center',
      'keyboard': false
    });
    this.modalBackdropService.addBackDrop();
  }

  closeInventoryProductSelectionModal = () => {
    if (this.productSelectionModalRef) {
      this.productSelectionModalRef.hide();
      this.bsModalService.onHidden.emit(true);
      this.modalBackdropService.removeBackdrop();
    }
    this.resetModels();
    $('#barcodeName').focus();
  }

  openMultipleInventoryProductSelectionModal = () => {
    this.multipleInventoryProductModalRef = this.bsModalService.show(this.selectFromMultipleInventoryProductModal, {
      'backdrop': 'static',
      'class': 'vertical-center',
      'keyboard': false
    });
    this.modalBackdropService.addBackDrop();
  }

  closeMultipleInventoryProductSelectionModal = () => {
    if (this.multipleInventoryProductModalRef) {
      this.multipleInventoryProductModalRef.hide();
      this.modalBackdropService.removeBackdrop();
    }
    $('#barcodeName').focus();
  }

  openInventoryProductEditModal = () => {
    const modalRef = this.modalService.getModalWrapper(InventoryProductEditModelComponent);
    const modal = modalRef.show({
      'backdrop': 'static',
      'class': 'vertical-center modal-max-width-95',
      'keyboard': false,
      initialState: {
        parameters: this.productAdditionalParams,
        productid: this.editedProductId
      }
    });
    modal.close.subscribe((res) => {
      if (res?.shouldReload) {
        this.saveInventoryProductDialog(res.inventoryProduct);
      }
    });
  }


  onSaveInventoryProduct = (inventoryProduct: InventoryProductLog) => {
    this.selectedVendorId = inventoryProduct.VendorId;
    this.getInventoryProducts();
    this.getInventoryProductByVendor(inventoryProduct.VendorId);
    this.inventoryId = inventoryProduct.Id;
  }

  cancel = (isFromSave = false) => {
    this.productList = [];
    this.barcodeList = [];
    this.applicationStateService.inventoryOrderReceivingNewBarcodeList = [];
    this.applicationStateService.inventoryOrderReceivingProductList = [];
    this.selectedLocation = isFromSave ? this.selectedLocation : 0;
    this.selectedVendorId = null;
    this.receivingType = 'Now';
    this.currentDate = new Date();
    this.setShowCancelButton();
    $('#barcodeName').focus();
  }

  redirectToManageConsole = () => {
    this.router.navigate(['manage/console']);
  }

  getMatchingProductsByBarcode = (barcode) => {
    const productsWithSameBarcode = [];
    // Find matched product from Newly added barcode list
    forEach(this.barcodeList, (item) => {
      if (item.Barcode.toLowerCase() === barcode.toLowerCase()) {
        const inventoryProduct = this.getInventoryProductById(item.InventoryProductId, this.inventoryProductLogList);
        const barcodeProduct = this.prepareBarcodeProduct(barcode, item.Id, inventoryProduct, item.UnitQtyOverride);
        productsWithSameBarcode.push(barcodeProduct);
      }
    });

    // Find matched product from saved inventory products list
    forEach(this.inventoryProductList, (product) => {
      forEach(product.Barcodes, (inventoryBarcode) => {
        if (inventoryBarcode.Barcode.toLowerCase() === barcode.toLowerCase()) {
          const barcodeProduct = this.prepareBarcodeProduct(barcode, inventoryBarcode.Id, product, inventoryBarcode.UnitQtyOverride);
          // if (this.barcodeList && this.barcodeList.length) {
          const item = find(this.barcodeList, (bar) => {
            return (bar.InventoryProductId === barcodeProduct.ProductId && bar.Barcode === barcodeProduct.Barcode);
          });
          if (!item) {
            productsWithSameBarcode.push(barcodeProduct);
          }
          // }
        }
      });
    });

    return productsWithSameBarcode;
  }


  prepareBarcodeProduct = (barcodeText: string, barcodeId: number,
    inventoryProduct: InventoryProduct, UnitQtyOverride?: number): InventoryOrderReceivingProductList => {
    const barcodeProduct: InventoryOrderReceivingProductList = {
      Barcode: barcodeText.toLowerCase(),
      BarcodeId: barcodeId,
      QtyReceived: 1,
      UnitQtyOverride: UnitQtyOverride ? UnitQtyOverride : null,
      MaxPortionsText: '',
      MeasureByInstructions: '',
      ProductId: null,
      ProductName: '',
      Unit: '',
      UnitQty: null,
      inventoryUnit: '',
      UnitDetails: '',
      UnitQtyIncrement: null,
      PurchaseUnit: '',
    };
    if (inventoryProduct != null) {
      barcodeProduct.MaxPortionsText = inventoryProduct.MaxPortionsText;
      barcodeProduct.MeasureByInstructions = inventoryProduct.MeasureByInstructions;
      barcodeProduct.ProductId = inventoryProduct.Id;
      barcodeProduct.ProductName = inventoryProduct.Product;
      barcodeProduct.Unit = inventoryProduct.Unit + ', Unit Qty: ' + inventoryProduct.Qty;
      barcodeProduct.UnitQty = inventoryProduct.Qty;
      barcodeProduct.inventoryUnit = inventoryProduct.Unit;
      barcodeProduct.Id = inventoryProduct.Id;
      barcodeProduct.Product = inventoryProduct.Product;
      barcodeProduct.UnitDetails = inventoryProduct.UnitDetails;
      barcodeProduct.UnitQtyIncrement = inventoryProduct.UnitQtyIncrement;
      barcodeProduct.PurchaseUnit = inventoryProduct.PurchaseUnit;
    }
    return barcodeProduct;
  }

  openConfirmInventoryReceivingModal = () => {
    if (this.productList && this.productList.length) {
      const modalRef = this.modalService.getModalWrapper(ConfirmInventoryReceivingComponent);
      const modal = modalRef.show({
        animated: false,
        class: 'vertical-center',
        keyboard: false,
        initialState: {
          productList: cloneDeep(this.productList),
          location: this.selectedLocationName
        }

      });
      modal.close.subscribe((response) => {
        if (response && (response.shouldConfirm)) {
          this.saveInventoryProductReceiving();
        }
        $('#barcodeName').focus();
      });
    }
  }

  saveInventoryProductReceiving = () => {
    const receivingItemList: Array<InventoryReceived> = [];
    if (this.receivingType == 'Select' && this.currentTime) {
      const splitTime = this.currentTime.split(':');
      if (splitTime && splitTime.length > 0) {
        this.currentDate.setHours(Number(splitTime[0]));
        this.currentDate.setMinutes(Number(splitTime[1]));
        this.currentDate.setSeconds(0);
      }
    }
    const currentDateTime = this.receivingType == 'Select' ? this.currentDate : new Date();
    this.isLabelPrinting = false;
    this.prepareReceivingItemsList(currentDateTime, receivingItemList);

    this.spinnerService.show();
    this.inventoryReceivingService.recordInventoryReceiving(this.applicationStateService.terminalId, receivingItemList)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (response) => {
          if (this.barcodeList && this.barcodeList.length) {
            this.saveInventoryProductBarcodes();
          }
          this.saveInventoryProductReceivingCompleted(response);
        }, error: this.alertService.showApiError
      });

  }

  saveInventoryProductDialog = (inventoryProduct) => {
    this.selectedVendorId = inventoryProduct.vendor_id;
    this.getInventoryProducts();
    this.selectedProduct = inventoryProduct;
    this.selectedProduct.Product = inventoryProduct.name;
    this.selectedProduct.Id = inventoryProduct.id;
    this.inventoryId = inventoryProduct.id;
  }

  private prepareReceivingItemsList(currentDateTime: Date, receivingItemList: InventoryReceived[]) {
    forEach(this.productList, (product) => {
      const receivedProduct = {
        Id: 0,
        InventoryProductId: product.ProductId,
        Qty: (product.UnitQtyOverride ? product.UnitQtyOverride : product.UnitQty) * product.QtyReceived,
        ReceivedDate: currentDateTime,
        UserId: this.applicationStateService.userId,
        Barcode: product.Barcode ? product.Barcode : null,
        TerminalName: this.applicationStateService.terminalName,
        UnitQty: product.UnitQtyOverride ? product.UnitQtyOverride : product.UnitQty,
        InventoryOffsiteLocationId: this.selectedLocation ? this.selectedLocation : null,
      } as InventoryReceived;
      if (product.MaxPortionsText || product.MeasureByInstructions) {
        this.isLabelPrinting = true;
      }
      receivingItemList.push(receivedProduct);
    });
  }

  checkIsMobileMode() {
    this.isMobileMode = $(window).width() < 1151 ? true : false;
  }

  setLocationName() {
    this.setShowCancelButton();
    this.selectedLocationName = this.inventoryLocations.find(x => x.Id == this.selectedLocation)?.Name;
  }

  setShowCancelButton() {
    if (this.selectedLocation || this.productList?.length || this.receivingType != 'Now') {
      this.showCancelButton = true;
    } else {
      this.showCancelButton = false;
    }
  }

}
