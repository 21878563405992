import { Component, EventEmitter, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ModalComponent } from 'src/app/shared/components/modal/modal-component';
import { InventoryLogStockSalesProduct } from 'src/app/shared/interface/inventory-log-stock-sales-product';

@Component({
  selector: 'pos-inventory-sales-stock-warning',
  templateUrl: './inventory-sales-stock-warning.component.html'
})
export class InventorySalesStockWarningComponent extends ModalComponent {

  salesInventoryProducts: Array<InventoryLogStockSalesProduct> = [];
  @Output() isUpdateInventoryQty: EventEmitter<any> = new EventEmitter();
  
  constructor(modalRef: BsModalRef) {
    super(modalRef);
  }

  updateInventoryQty(){
    this.cancel(true);
  }

  cancel(isUpdateInventoryQty=false) {
    this.close.emit({isUpdateInventoryQty: isUpdateInventoryQty});
  }
}
