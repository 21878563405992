import { Component, ViewChild } from '@angular/core';
import { LoyaltyFormAttributeService } from './services/loyalty-form-attribute.service';
import { forkJoin, finalize, Observable } from 'rxjs';
import { LoyaltyFormAttribute } from './interfaces/loyalty-form-attribute';
import { NgForm } from '@angular/forms';
import { LoyaltyFormSettingsService } from '../configurator/loyalty-form-settings/service/loyalty-form-settings.service';
import { LoyaltyFormSettings } from '../configurator/loyalty-form-settings/interface/loyalty-form-settings';
import { RuntimeConstants } from '../shared/constants/runtime-constants';
import { Account } from '../shared/interface/account';
import { AlertsService } from '../shared/services/alerts.service';
import { AccountService } from '../shared/services/account.service';
import { RecaptchaConfigService } from '../configurator/recapcha-settings/service/recaptcha-config.service';
import { SiteKey } from '../configurator/recapcha-settings/interface/site-key';
import { checkCircleGreen } from '../shared/components/icon/icons';
import { DomainConstants, InfoModalComponent, ModalService } from '../shared';
import { RecaptchaComponent } from 'ng-recaptcha';
declare let $: any;
@Component({
  selector: 'pos-account-registration',
  templateUrl: './account-registration.component.html',
  styleUrls: ['./account-registration.component.scss'],
})
export class AccountRegistrationComponent {

  imagePath = RuntimeConstants.IMAGE_BASE_PATH;
  account: Account;
  token = null;
  siteKey: string = null;
  loyaltyFormAttributes: LoyaltyFormAttribute[] = [];
  systemDefinedNameAttribute: LoyaltyFormAttribute;
  loyaltyFormSettings: LoyaltyFormSettings;
  accountAttributeValueType = DomainConstants.AccountAttributeValueTypes;
  icons = {
    checkCircleGreen,
  }
  @ViewChild('registrationForm') registrationForm: NgForm;
  @ViewChild('recaptcha') recaptchaRef: RecaptchaComponent;
  confettiArray: { x: number; color: string; delay: number; duration: number }[] = [];
  isFormSubmited = false;

  constructor(protected alertService: AlertsService,
    protected accountService: AccountService,
    private loyaltyFormAttributeService: LoyaltyFormAttributeService,
    private loyaltyFormSettingsService: LoyaltyFormSettingsService,
    private modalService: ModalService,
    private recaptchaConfigService: RecaptchaConfigService) {
    this.account = this.accountService.newAccount();
    this.loyaltyFormSettings = this.loyaltyFormSettingsService.newLoyaltyFormSettings();
  }

  ngOnInit() {
    this.loadDependencies();
    this.createConfettiArray();
  }

  loadDependencies() {
    const accountRegistrationObservable: Array<Observable<any>> = [];
    accountRegistrationObservable.push(this.loyaltyFormAttributeService.getAll());
    accountRegistrationObservable.push(this.recaptchaConfigService.getSiteKey());
    accountRegistrationObservable.push(this.loyaltyFormSettingsService.getLoyaltyFormSettings());
    forkJoin(accountRegistrationObservable)
      .pipe(finalize(() => {
        this.hideSpinner();
      }))
      .subscribe({
        next: ([attributes, sitekey, settings]: [Array<LoyaltyFormAttribute>, SiteKey, LoyaltyFormSettings]) => {
          this.systemDefinedNameAttribute = attributes.find(x => this.isSystemDefinedAttribute(x) && x.Name == 'Name');
          this.loyaltyFormAttributes = attributes.filter(x => !this.isSystemDefinedAttribute(x));
          this.siteKey = sitekey.Value;
          this.loyaltyFormSettings = settings;
        }, error: this.alertService.showApiError
      })
  }

  createConfettiArray(){
    for (let i = 0; i < 50; i++) {
      this.confettiArray.push({
        x: Math.random() * 100,
        color: this.getRandomColor(),
        delay: Math.random() * 0.1,
        duration: 2 + Math.random() * 3,
      });
    }
  }

  getRandomColor(): string {
    const colors = ['#FF5733', '#33FF57', '#3357FF', '#FFD700', '#FF33A1'];
    return colors[Math.floor(Math.random() * colors.length)];
  }

  isSystemDefinedAttribute(loyaltyAttribute: any): boolean {
    return loyaltyAttribute.IsSystemDefined && loyaltyAttribute.AccountAttributeTypeId === null;
  }

  submit(isValid: boolean) {
    if (!isValid || (this.loyaltyFormSettings.UseRecapcha && !this.token)) {
      return;
    }
    
    if(this.loyaltyFormAttributes.some(x => x.AccountAttributeType.IsRequired && !x.AccountAttributeType.Value)){
      return;
    }

    this.prepareAccountAttributeTypes();
    this.account.RecaptchaToken = this.token;
    this.showSpinner();
    this.accountService.saveAccountDetails(this.account)
      .pipe(finalize(() => {
        this.hideSpinner();
      }))
      .subscribe({
        next: x => {
          this.resetForm();
          this.loadFormSubmitd()
        }, error: (error) => { this.handleException(error); }
      });
  }

  handleException(error){
    if (this.recaptchaRef) {
      this.recaptchaRef.reset();
      this.token = null;
    }
    const modalRef = this.modalService.getModalWrapper(InfoModalComponent).show({
        animated: false,
        class: 'vertical-center',
        initialState: {
          message: 'Error occured while Validating Account Details, ' + '<b>' + error.error + '</b>'
        }
    });
  }

  loadFormSubmitd(){
    this.isFormSubmited = true;
  }

  resetForm(){
    this.registrationForm.resetForm();
    this.resetAttributeFields();
  }

  resetAttributeFields(){
    this.loyaltyFormAttributes.forEach(x => {
      x.AccountAttributeType.Value = null;
    });
  }

  prepareAccountAttributeTypes() {
    this.loyaltyFormAttributes.forEach((loyaltyAttribute: LoyaltyFormAttribute) => {
      if (loyaltyAttribute.AccountAttributeType?.Value?.toString().trim()) {
        const accountAttribute = {
          id: 0,
          AccountId: this.account.Id,
          AttributeValue: loyaltyAttribute.AccountAttributeType.Value?.toString().trim(),
          AccountAttributeTypeId: loyaltyAttribute.AccountAttributeTypeId
        };
        this.account.AccountAttributes.push(accountAttribute);
      }
    });
  }

  shouldShowError(attribute: any, isSubmitted: boolean): boolean {
    return (
      !attribute.AccountAttributeType.Value &&
      attribute.AccountAttributeType.IsRequired &&
      isSubmitted
    );
  }

  getAction(valueType: string): string {
    const actionTypes = [
      this.accountAttributeValueType.Dropdown,
      this.accountAttributeValueType.Date,
      this.accountAttributeValueType.Radio,
    ];
    return actionTypes.includes(valueType) ? 'choose' : 'enter';
  }

  showSpinner(){
    if ($('#public-loader-container').length) {
      $('#public-loader-container').show();
    }
  }

  hideSpinner(){
    if ($('#public-loader-container').length) {
      $('#public-loader-container').hide();
    }
  }
}
