<div class="kiosk-background" [style.background-image]="image ? 'url(\'' + baseImagePath + image + '\')' : null"
    (click)="redirectToHome({ startOrderingDirectly: true })">
    <div class="outer-div" [style.align-items]="style.VerticalAlignment">
        <div class="order-details d-flex" [ngStyle]="{'top': style.VerticalPosition + 'px',
            'color': style.FontColor ? style.FontColor : '',
            'background-color': style.BackgroundColor ? style.BackgroundColor : ''
        }">
            <div class="col-12">
                <div class="col-12 primary-info">
                    Order #{{orderNumber}} Confirmed
                </div>
                <div class="col-12 secondary-info" [innerHTML]="orderAppreciationText"></div>
                <hr *ngIf="(isAskForNotifyViaSMS && notifyMessage) || enteredPhoneNumber" class="width-20 white m-auto mt-pix-10" />
                <div class="col-12 secondary-info pt-pix-10" (click)="openNumpad($event)" *ngIf="isAskForNotifyViaSMS && notifyMessage && !enteredPhoneNumber" [innerHTML]="notifyMessage"></div>
                <div class="col-12 secondary-info pt-pix-10" *ngIf="enteredPhoneNumber">{{enteredPhoneNumber}}</div>
            </div>
        </div>
    </div>
</div>
