import { Component, OnInit, ViewChild } from '@angular/core';
import { WorkSchedule, WorkScheduleTag } from '../../interface';
import { BaseCrudComponent } from 'src/app/shared/components/base-crud.component';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { Messages } from 'src/app/shared/constants';
import { finalize } from 'rxjs/operators';
import { NgForm } from '@angular/forms';
import { WorkScheduleTagService } from 'src/app/shared/services/work-schedule-tag.service';
import { User } from 'src/app/information-management/users';
import { forkJoin } from 'rxjs';
import { DatePipe } from '@angular/common';
import * as _ from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/shared/services/user.service';
import { WorkScheduleService } from 'src/app/shared/services/work-schedule.service';
import { ApplicationStateService, FormUtilityService } from 'src/app/shared/services';
import { workScheduleCalendarAlt } from 'src/app/shared/components/icon';
import { cloneDeep } from 'lodash';

@Component({
    selector: 'pos-work-schedule-edit',
    templateUrl: './work-schedule-edit.component.html',
    styleUrls: ['./work-schedule-edit.component.scss']
})
export class WorkScheduleEditComponent extends BaseCrudComponent<WorkSchedule> implements OnInit {
    get getForm(): NgForm {
        return this.workScheduleForm
    }
    @ViewChild('workScheduleForm') workScheduleForm: NgForm;
    tags: WorkScheduleTag[] = [];
    users: User[] = [];
    workSchedule: WorkSchedule;
    workScheduleObservable = [];
    selectedTag: WorkScheduleTag;
    dateFormat = 'mm-dd-yyyy';
    icons = { workScheduleCalendarAlt }
    props = {
        labelClass: '',
        controlBoxClass: 'col-lg-3 col-md-6 col-sm-6 col-xs-12'
    }
    constructor(
        protected workScheduleService: WorkScheduleService,
        protected alertService: AlertsService,
        protected spinnerService: SpinnerService,
        private router: Router,
        private workScheduleTagService: WorkScheduleTagService,
        private applicationStateService: ApplicationStateService,
        private userService: UserService,
        private datePipe: DatePipe,
        private route: ActivatedRoute,
        formUtilityService: FormUtilityService
    ) {
        super(workScheduleService, alertService, spinnerService, formUtilityService);
        this.id = route.snapshot.params.id ? parseInt(route.snapshot.params.id, 10) : 0;
    }

    ngOnInit() {
        this.workSchedule = this.workScheduleService.newWorkSchedule();
        this.workSchedule.start_time = this.convertDateToStringFormat(this.workSchedule.start_time, 'HH:mm');
        this.workSchedule.end_time = this.convertDateToStringFormat(this.workSchedule.end_time, 'HH:mm');
        this.saveSuccessMessage = Messages.WorkScheduleSaveSuccess;
        this.dataSubscription();
        this.scrollToTop();
        this.dateFormat = this.applicationStateService.settingParam.PCalendarDateFormat;
    }

    private convertDateToStringFormat(date: any, format: string) {
        return this.datePipe.transform(date, format);
    }

    onSelectStartDate() {
        this.workSchedule.end_date = cloneDeep(this.workSchedule.start_date);
    }
    submit(isValid: boolean) {
        if (!isValid) {
            return;
        }

        const startTime = this.workSchedule.start_time.split(':');
        this.workSchedule.start_date.setHours(startTime[0], startTime[1], 0, 0);
        const endTime = this.workSchedule.end_time.split(':');
        this.workSchedule.end_date.setHours(endTime[0], endTime[1], 0, 0);

        if (this.workSchedule.end_date.getTime() <= this.workSchedule.start_date.getTime()) {
            this.alertService.renderErrorMessage(Messages.ErrorWhileStartTimeIsGreaterEndTime);
            return;
        }

        this.workSchedule.TagId = this.selectedTag ? this.selectedTag.Id : null;

        this.save(this.workSchedule);
    }
    private dataSubscription(): void {
        if (this.id > 0) {
            this.workScheduleObservable.push(this.loadData());
        }
    }

    public loadDependencies(): void {
        this.spinnerService.show();
        this.workScheduleObservable.push(this.workScheduleTagService.getWorkScheduleTags());
        this.workScheduleObservable.push(this.userService.getAllActiveUser(false));

        forkJoin(this.workScheduleObservable)
            .pipe(finalize(() => {
                this.spinnerService.hide();
            }))
            .subscribe({
                next: (responses: any) => {
                    if (responses) {
                        if (this.id > 0) {
                            this.workSchedule = responses[0] ? responses[0] : this.workScheduleService.newWorkSchedule();
                            this.workSchedule.start_date = new Date(this.workSchedule.start_date);
                            this.workSchedule.end_date = new Date(this.workSchedule.end_date);
                            this.workSchedule.start_time = this.convertDateToStringFormat(this.workSchedule.start_date, 'HH:mm');
                            this.workSchedule.end_time = this.convertDateToStringFormat(this.workSchedule.end_date, 'HH:mm');

                            this.tags = responses[1] ? responses[1] : [];
                            if (this.id > 0) {
                                this.selectedTag = _.find(this.tags, (tag) => {
                                    return tag.Id === this.workSchedule.TagId;
                                });
                            }
                            this.users = responses[2] ? responses[2] : [];
                        } else {
                            this.tags = responses && responses[0] ? responses[0] : [];
                            this.users = responses && responses[1] ? responses[1] : [];
                        }

                    }
                }, error: this.alertService.showApiError
            });
    }

    public onSaveSuccess(model: WorkSchedule): void {
        this.cancelEditing();
    }

    public cancelEditing() {
        this.router.navigate(['work-schedule'], { relativeTo: this.route.parent });
    }
    private scrollToTop(): void {
        window.scrollTo(0, 0);
    }
}
