<pos-edit-wrapper heading="{{ id > 0 ? 'Edit' : 'Add' }} Terminal" (close)="cancel()" [headingIcon]="icons.terminalTv"
  [isPopup]="false">
  <form (ngSubmit)="saveTerminal(terminalForm.valid)" #terminalForm="ngForm" class="edit-form">
    <context-provider provide="props">
      <div class="col-12 pos-page-body border-top content-scroll">
        <div class="col-12">
          <div>
            <h3 class="setting-title">Terminal</h3>
          </div>
          <pos-row class="px-2">
            <pos-column>
              <pos-form-field label="Terminal Name" [fieldInfo]="fieldInfoMessages.TerminalsName"
                [validationMessages]="{ required: 'Please enter terminal name.' }">
                <input posInput class="form-control" id="terminalName" name="terminalName"
                  [(ngModel)]="terminal.TerminalName" [required]="true" type="text" #terminalName="ngModel" />
              </pos-form-field>
            </pos-column>
            <pos-column>
              <pos-form-field label="Terminal Type" [fieldInfo]="fieldInfoMessages.TerminalsTypes"
                [validationMessages]="{ required: 'Please select terminal type.' }">
                <ng-select posInput [items]="terminalTypes" [hideSelected]="true" appendTo="body" bindLabel="Name"
                  bindValue="Id" placeholder="Select terminal type" [(ngModel)]="terminal.TerminalTypeId"
                  name="terminalType" (change)="changeTerminalTypeName()" #terminalType="ngModel" [required]="true"
                  [disabled]="id > 0" [ngClass]="{'select-disabled':id != 0}">
                </ng-select>
              </pos-form-field>
            </pos-column>
            <pos-column>
              <pos-form-field>
                <p-checkbox [(ngModel)]="monitorForConnection" name="monitorForConnection"
                  label="Monitor For Connection" binary="true"></p-checkbox>
              </pos-form-field>
            </pos-column>
            <!-- DIGITAL SIGNAGE terminal type -->
            <pos-column *ngIf="terminal.TerminalType === terminalTypeNames.DIGITAL_SIGNAGE.Name">
              <pos-form-field label="Slideshow" [validationMessages]="{required: 'Please select slideshow.'}">
                <ng-select posInput [items]="slideshows" [hideSelected]="true" appendTo="body" bindLabel="Name"
                  bindValue="Id" placeholder="Select slideshow" [(ngModel)]="terminalSlideshowMap.SlideshowId"
                  name="slideshow" #slideshow="ngModel" [required]="true">
                </ng-select>
              </pos-form-field>
            </pos-column>

            <!-- PICK SCREEN and INSTRUCTION SCREEN terminal type -->
            <pos-column
              *ngIf="(terminal.TerminalType === terminalTypeNames.PICK_SCREEN.Name || terminal.TerminalType === terminalTypeNames.INSTRUCTION_SCREEN.Name || terminal.TerminalType === terminalTypeNames.ORDER_ENTRY.Name || terminal.TerminalType == terminalTypeNames.ORDER_KIOSK.Name)">
              <pos-form-field label="Make Table Terminal" inputStyle="col-md-6 col-sm-9"
                [validationMessages]="{required: 'Please select make table.'}">
                <ng-select posInput [items]="selectedMakeTableTerminals" appendTo="body" bindLabel="TerminalName"
                  [multiple]="true" [hideSelected]="true" placeholder="Select make table terminal"
                  [(ngModel)]="makeTableTerminalIds" name="makeTableTerminals" [required]="isUseMakeTable">
                </ng-select>
              </pos-form-field>
            </pos-column>
            <pos-column
              *ngIf="(terminal.TerminalType === terminalTypeNames.PICK_SCREEN.Name || terminal.TerminalType === terminalTypeNames.INSTRUCTION_SCREEN.Name || terminal.TerminalType === terminalTypeNames.ORDER_ENTRY.Name || terminal.TerminalType == terminalTypeNames.ORDER_KIOSK.Name)">
              <button type="button" class="btn btn-primary white btn-sm normal mt-pix-10 pos-button"
                (click)="linkToAllMakeTable()">Link
                to All Make Tables</button>
            </pos-column>

            <!-- INSTRUCTION SCREEN terminal type -->
            <pos-column *ngIf="terminal.TerminalType === terminalTypeNames.INSTRUCTION_SCREEN.Name">
              <pos-form-field label="Max Products"
                [validationMessages]="{ required: 'Please enter max product.', range: 'Please enter value between 1 to 8.'}">
                <input posInput class="form-control col-120" name="MaxProducts" min="1" max="8" step="1"
                  [(ngModel)]="maxProducts" [required]="true" #MaxProducts="ngModel" type="number" [range]="[1, 8]" />
              </pos-form-field>
            </pos-column>

            <!-- DJ terminal type -->
            <pos-column *ngIf="terminal.TerminalType === terminalTypeNames.DJ.Name">
              <pos-form-field label="Schemes" [validationMessages]="{required: 'Please select DJ scheme.'}"
                inputStyle="col-md-5">
                <ng-select posInput [items]="djSchemes" bindLabel="Name" bindValue="Id" name="djSchemes"
                  [multiple]="true" placeholder="Select scheme(s)" [(ngModel)]="DJSchemeIds" appendTo="body"
                  [required]="true" [hideSelected]="true">
                </ng-select>
              </pos-form-field>
            </pos-column>
            <pos-column *ngIf="terminal.TerminalType === terminalTypeNames.DJ.Name">
              <pos-form-field label="Volume">
                <pos-row>
                  <pos-column xs="11">
                    <ngx-slider [(value)]="volume" [options]="options"></ngx-slider>
                  </pos-column>
                  <pos-column xs="1">
                    <span class="badge mt-pix-n2 volume-circle">{{volume ? volume : 0}}</span>
                  </pos-column>
                </pos-row>
              </pos-form-field>
            </pos-column>

            <!-- PRODUCT BUILD QUEUE terminal type -->
            <pos-column *ngIf="terminal.TerminalType === terminalTypeNames.PRODUCT_BUILD_QUEUE.Name">
              <pos-form-field label="Columns"
                [validationMessages]="{required: 'Please enter columns.', range: 'Columns must be in range of 1-10.'}">
                <input posInput class="form-control col-120" max="10" min="1" name="productBuildQueueGridColumns"
                  [(ngModel)]="gridColumns" [required]="true" type="number" #columns="ngModel" [range]="[1, 10]">
              </pos-form-field>
            </pos-column>
            <pos-column *ngIf="terminal.TerminalType === terminalTypeNames.PRODUCT_BUILD_QUEUE.Name">
              <pos-form-field label="Rows"
                [validationMessages]="{required: 'Please enter rows.', range: 'Rows must be in range of 1-4.'}">
                <input posInput class="form-control col-120" max="4" min="1" name="productBuildQueueGridRows"
                  [(ngModel)]="gridRows" [required]="true" type="number" #rows="ngModel" [range]="[1, 4]">
              </pos-form-field>
            </pos-column>

            <!-- Display Menu terminal type -->
            <pos-column *ngIf="terminal.TerminalType === terminalTypeNames.MENU_DISPLAY.Name">
              <pos-form-field label="Menu" [validationMessages]="{required: 'Please select menu.'}">
                <ng-select posInput [items]="displayMenuData" [hideSelected]="true" appendTo="body" bindLabel="Name"
                  bindValue="Id" placeholder="Select menu" name="menu" #menu="ngModel" [(ngModel)]="displayMenuId"
                  [required]="true">
                </ng-select>
              </pos-form-field>
            </pos-column>

            <!-- ORDER ENTRY terminal type -->
            <pos-column *ngIf="terminal.TerminalType === terminalTypeNames.ORDER_ENTRY.Name">
              <pos-form-field label="Home Screen" [validationMessages]="{required: 'Please select home screen.'}">
                <ng-select posInput [items]="screenList" bindLabel="Name" bindValue="Id"
                  placeholder="Select home screen" [required]="true" appendTo="body" [(ngModel)]="selectedHomeScreen"
                  name="screenList">
                </ng-select>
              </pos-form-field>
            </pos-column>
            <pos-column *ngIf="terminal.TerminalType === terminalTypeNames.ORDER_ENTRY.Name">
              <pos-form-field label="Table Layout">
                <ng-select posInput [items]="tableLayouts" [multiple]="true" [hideSelected]="true" bindLabel="Name"
                  bindValue="Id" placeholder="Select table layout" name="tableLayouts" appendTo="body"
                  [(ngModel)]="tableLayoutIds">
                </ng-select>
              </pos-form-field>
            </pos-column>
            <pos-column *ngIf="terminal.TerminalType === terminalTypeNames.ORDER_ENTRY.Name">
              <pos-form-field [validationMessages]="{ required: 'Please select at least one option' }">
                <p-checkbox posInput name="chkPromptOrder" #chkPromptOrder="ngModel" (onChange)="promptOrders()"
                  [(ngModel)]="isOrderPrompt" binary="true" label="Override Serve Methods" [required]="isOrderPrompt">
                </p-checkbox>
              </pos-form-field>
              <div class="ps-pix-10" *ngFor="let orderPrompt of promptServeMethods">
                <pos-form-field>
                  <p-checkbox posInput name="promptOrder_type_{{orderPrompt.Name}}"
                    #promptOrder_type_{{orderPrompt.Name}}="ngModel" (onChange)="checkOrderPromptsValidation()"
                    [(ngModel)]="orderPrompt.isSelected" binary="true" [disabled]="!isOrderPrompt"
                    [label]="orderPrompt.Name">
                  </p-checkbox>

                </pos-form-field>
              </div>
              <span class="red-extreme ms-pix-10"
                *ngIf="isOrderPrompt && !isValidOrderPrompts && terminalForm.submitted">Please
                select at least one option</span>
            </pos-column>
            <pos-column
              *ngIf="settingParam.EnableAudioRecording && terminal.TerminalType === terminalTypeNames.ORDER_ENTRY.Name">
              <pos-form-field [label]="terminalPropertyNames.TAG_AUDIO_TYPE.Text"
                [validationMessages]="{required: 'Please select audio request type.'}">
                <ng-select posInput [items]="tagAudioRequestTypes" [clearable]="false" bindLabel="Key" bindValue="Value"
                  placeholder="Select audio request type" name="tagAudio" appendTo="body"
                  [(ngModel)]="tagAudioRequestType" [required]="true" #tagAudio="ngModel">
                </ng-select>
              </pos-form-field>
            </pos-column>

            <pos-column
              *ngIf="settingParam.EnableAudioRecording && terminal.TerminalType === terminalTypeNames.ORDER_ENTRY.Name">
              <pos-form-field [label]="terminalPropertyNames.AUDIO_INTERACTION_MODE.Text"
                inputStyle="col-md-5 col-sm-8 col-12">
                <ng-select posInput [items]="audioInteractionModeList" [multiple]="true" bindLabel="Key"
                  bindValue="Value" placeholder="Select audio interaction mode" name="audioInteractionModes"
                  appendTo="body" [(ngModel)]="audioInteractionModes" #audioInteractionMode="ngModel">
                </ng-select>
              </pos-form-field>
            </pos-column>

            <!-- ORDER CONFIRMATION terminal type -->
            <pos-column *ngIf="terminal.TerminalType === terminalTypeNames.ORDER_CONFIRMATION_SCREEN.Name">
              <pos-form-field label="Order Entry Terminal"
                [validationMessages]="{required: 'Please select order entry.'}">
                <ng-select posInput [items]="orderEntryTerminals" bindLabel="TerminalName" bindValue="TerminalId"
                  placeholder="Select order entry terminal" name="activeOrderTerminals" appendTo="body"
                  [(ngModel)]="terminal.OrderEntryTerminalId" [required]="true" #activeOrderTerminals="ngModel">
                </ng-select>
              </pos-form-field>
            </pos-column>
            <pos-column *ngIf="terminal.TerminalType === terminalTypeNames.ORDER_CONFIRMATION_SCREEN.Name">
              <pos-form-field label="Slideshow">
                <ng-select posInput [items]="slideshows" [hideSelected]="true" appendTo="body" bindLabel="Name"
                  bindValue="Id" placeholder="Select slideshow" [(ngModel)]="terminalSlideshowMap.SlideshowId"
                  name="slideshow" #slideshow="ngModel">
                </ng-select>
              </pos-form-field>
            </pos-column>
            <pos-column *ngIf="terminal.TerminalType === terminalTypeNames.ORDER_CONFIRMATION_SCREEN.Name">
              <pos-form-field [label]="terminalPropertyNames.ORDER_CONFIRMATION_SCROLL_SPEED.Text"
                [validationMessages]="{required: 'Please enter scroll speed.', range: 'Scroll speed must be in range of 1-99 seconds.'}">
                <input posInput class="form-control col-120" max="99" min="1" name="orderConfirmationScrollSpeed"
                  [(ngModel)]="scrollSpeed" [required]="true" type="number" #scrollSpeedRef="ngModel" [range]="[1, 99]">
              </pos-form-field>
            </pos-column>
            <pos-column *ngIf="terminal.TerminalType === terminalTypeNames.ORDER_CONFIRMATION_SCREEN.Name">
              <pos-form-field [label]="terminalPropertyNames.ORDER_CONFIRMATION_IDLE_TIMEOUT.Text"
                [validationMessages]="{required: 'Please enter scroll idle timeout.', range: 'Idle timeout must be in range of 1-60 minutes.'}">
                <input posInput class="form-control col-120" max="60" min="1" name="orderConfirmationIdleTimeout"
                  [(ngModel)]="idleTimeout" [required]="true" type="number" #timeout="ngModel" [range]="[1, 60]">
              </pos-form-field>
            </pos-column>

            <!-- ORDER STATUS terminal type -->
            <pos-column *ngIf="terminal.TerminalType === terminalTypeNames.ORDER_STATUS.Name">
              <pos-form-field label="Slideshow">
                <ng-select posInput [items]="slideshows" [hideSelected]="true" appendTo="body" bindLabel="Name"
                  bindValue="Id" placeholder="Select slideshow" [(ngModel)]="terminalSlideshowMap.SlideshowId"
                  name="slideshow" #slideshow="ngModel">
                </ng-select>
              </pos-form-field>
            </pos-column>
            <pos-column *ngIf="terminal.TerminalType === terminalTypeNames.ORDER_STATUS.Name">
              <pos-form-field label="Font Size" inputStyle="col-3 col-150">
                <p class="input-group flex-nowrap">
                  <input posInput class="form-control col-120 price-input" name="fontSize" [(ngModel)]="fontSize"
                    type="number" step="1" />
                  <span class="input-group-addon">px</span>
                </p>
              </pos-form-field>
            </pos-column>

            <!-- MAKE TABLE terminal type -->
            <pos-column *ngIf="terminal.TerminalType === terminalTypeNames.MAKE_TABLE.Name">
              <pos-form-field [label]="terminalPropertyNames.LAYOUT.Text">
                <ng-select posInput [items]="layouts" appendTo="body" bindLabel="Name" bindValue="Value"
                  [(ngModel)]="selectedLayout" name="layout" #layout="ngModel" [clearable]="false"
                  [clearOnBackspace]="false">
                </ng-select>
              </pos-form-field>
            </pos-column>
            <pos-column *ngIf="terminal.TerminalType === terminalTypeNames.MAKE_TABLE.Name">
              <pos-form-field label="Order Terminal" inputStyle="col-md-6">
                <ng-select posInput [items]="orderEntryTerminals" bindLabel="TerminalName" bindValue="TerminalId"
                  [multiple]="true" [hideSelected]="true" placeholder="Select order terminal"
                  name="activeOrderTerminals" appendTo="body" [(ngModel)]="orderEntryTerminalIds"
                  #activeOrderTerminals="ngModel">
                </ng-select>
              </pos-form-field>
            </pos-column>
            <pos-column *ngIf="terminal.TerminalType === terminalTypeNames.MAKE_TABLE.Name">
              <button type="button" class="btn btn-primary btn-sm white normal mt-pix-10 pos-button"
                (click)="linkMakeTableToAllOrderEntry()">
                Link to all Order Terminals</button>
            </pos-column>
            <pos-column *ngIf="terminal.TerminalType === terminalTypeNames.MAKE_TABLE.Name">
              <pos-form-field>
                <p-checkbox name="chkReadOnly" #chkReadOnly="ngModel" [(ngModel)]="isReadOnly" binary="true"
                  [label]="terminalPropertyNames.READ_ONLY.Text">
                </p-checkbox>
              </pos-form-field>
            </pos-column>
            <pos-column *ngIf="terminal.TerminalType === terminalTypeNames.MAKE_TABLE.Name">
              <pos-form-field>
                <p-checkbox name="chkFilterProduct" [(ngModel)]="isFilteredProducts" binary="true"
                  [label]="terminalPropertyNames.FILTERED_PRODUCTS.Text">
                </p-checkbox>
              </pos-form-field>
            </pos-column>
            <pos-column *ngIf="terminal.TerminalType === terminalTypeNames.MAKE_TABLE.Name">
              <div class="pt-pix-10 ps-pix-30" *ngIf="isFilteredProducts">
                <span class="bold"> Choose Sales Groups </span>
                <pos-filtered-products [terminalId]="id" [filteredProducts]="filteredProducts"
                  (productsFiltered)="productsFiltered($event)" [height]="'300px'" [isLoadOnPopup]="false"
                  [isShowFilterSwitch]="false" [isShowHeaderFooter]="false" [isDataLoaded]="true">
                </pos-filtered-products>
              </div>
            </pos-column>

            <!-- TIMER terminal type -->
            <pos-column *ngIf="terminal.TerminalType === terminalTypeNames.TIMER.Name">
              <pos-form-field [label]="terminalPropertyNames.TIMER_OPTIONS.Text"
                [validationMessages]="{required: 'Please select timer options.'}">
                <ng-select posInput [items]="timerOptions" appendTo="body" [(ngModel)]="timerOption" [required]="true"
                  name="timerOption" #inpTimerOption="ngModel" placeholder="Select timer options" bindLabel="key"
                  bindValue="value" (change)="createSlotTimesArray(true)">
                </ng-select>
              </pos-form-field>
            </pos-column>

            <pos-column *ngIf="terminal.TerminalType === terminalTypeNames.TIMER.Name">
              <pos-form-field [label]="terminalPropertyNames.TIMER_NUMBER_OF_SLOT.Text"
                [validationMessages]="{required: 'Please enter number of slot.'}">
                <input posInput class="form-control col-120" name="noOfSlot" [(ngModel)]="noOfSlot" [required]="true"
                  type="number" #inpNoOfSlot="ngModel" (change)="createSlotTimesArray()" posOnlyNumbers>
              </pos-form-field>
            </pos-column>
            <pos-column
              *ngIf="terminal.TerminalType === terminalTypeNames.TIMER.Name && timerOption === timerTerminalOptions.TIMER_ONLY">
              <pos-form-field>
                <p-checkbox posInput name="manualAdjustment" #ManualAdjustment="ngModel" [(ngModel)]="manualAdjustment"
                  binary="true" label="Manual Adjustment">
                </p-checkbox>
              </pos-form-field>
            </pos-column>
            <pos-column lg="10" xs="12" class=" mt-pix-10"
              *ngIf="terminal.TerminalType === terminalTypeNames.TIMER.Name && slotTimes?.length">
              <div class="setting-title">Slot Configuration</div>
              <pos-row class="pb-pix-10">
                <pos-column xs="2" class="col-75">
                </pos-column>
                <pos-column sm="4" class="text-align-center">
                  <label class="label-section bold">Audio</label>
                </pos-column>
                <pos-column xs="1" class="col-60 ps-0">
                </pos-column>
                <pos-column xs="3" class="p-0 col-120 text-align-center"
                  *ngIf="timerOption === timerTerminalOptions.TIMER_ONLY">
                  <label class="label-section bold">Seconds</label>
                </pos-column>
              </pos-row>

              <pos-row *ngFor="let slotTime of slotTimes; let i = index;" class="pt-pix-10">
                <pos-column xs="2" class="col-75">
                  <label class="label-section pt-pix-7">Slot {{i + 1}}</label>
                </pos-column>
                <pos-column xs="4">
                  <ng-select posInput [items]="timerSoundsObject" [(ngModel)]="slotTime.sound" appendTo="body"
                    name="slotSound_{{i}}" placeholder="Select timer audio" bindLabel="Key" bindValue="Value"
                    (change)="onSlotSoundChange(slotTime)">
                  </ng-select>
                </pos-column>
                <pos-column xs="1" class="col-60 pt-pix-2 ps-0">
                  <pos-action-icon *ngIf="slotTime.soundPlaying" [icon]="icons.stopRed"
                    (click)="playTimerAudio(slotTime, i)"></pos-action-icon>
                  <pos-action-icon *ngIf="!slotTime.soundPlaying" [icon]="icons.playGreen"
                    (click)="playTimerAudio(slotTime, i)"></pos-action-icon>
                </pos-column>
                <pos-column xs="3" class="col-120 p-0" *ngIf="timerOption === timerTerminalOptions.TIMER_ONLY">
                  <input posInput type="number" class="form-control col-120" name="slot_{{i}}" posOnlyNumbers min="0"
                    [(ngModel)]="slotTime.seconds" (change)="slotTime.minutes = calculateMinutes(slotTime.seconds)"
                    (keyup)="slotTime.minutes = calculateMinutes(slotTime.seconds)">
                </pos-column>
                <pos-column xs="2" *ngIf="timerOption === timerTerminalOptions.TIMER_ONLY">
                  <label class="label-section pt-pix-7">{{slotTime.minutes | date: 'HH:mm:ss' }}</label>
                </pos-column>
              </pos-row>
            </pos-column>
            <pos-column xs="12" *ngIf="terminal.TerminalType === terminalTypeNames.TIMER.Name">
              <pos-form-field [label]="terminalPropertyNames.TIMER_PREPGROUP.Text">
                <ng-select posInput appendTo="body" [items]="prepGroupsList" [multiple]="true" [hideSelected]="true"
                  (change)="changePrepGroups()" name="prepGroup" bindLabel="PrepGroupName" bindValue="PrepGroupName"
                  [(ngModel)]="prepGroups" #prepGroup="ngModel" placeholder="Select prep group">
                </ng-select>
              </pos-form-field>
            </pos-column>

            <!-- MANAGEMENT DASHBOARD terminal type -->
            <pos-column *ngIf="terminal.TerminalType === terminalTypeNames.MANAGEMENT_DASHBOARD.Name">
              <pos-form-field label="Dashboard" inputStyle="col-12">
                <pos-row>
                  <pos-column md="3" xs="12">
                    <ng-select posInput [items]="dashboardList" bindLabel="Name" placeholder="Select dashboard"
                      bindValue="Id" [(ngModel)]="managementDashboard" name="managementDashboard" appendTo="body"
                      #dashboard="ngModel">
                    </ng-select>
                  </pos-column>
                  <pos-column lg="2" sm="3" xs="6" class="col-120">
                    <input posInput class="form-control" type="number" placeholder="Seconds" min="1"
                      [(ngModel)]="dashboardSec" name="dashboardSec" />
                  </pos-column>
                  <pos-column sm="3" xs="6">
                    <button type="button" class="btn btn-primary normal me-pix-5 pos-button white"
                      (click)="addTerminalDashboardProperty()">{{
                      editedDashboard ? 'Update' : 'Add'}}</button>
                    <button type="button" class="btn btn-secondary normal pos-button"
                      (click)="cancelTerminalDashboardProperty()">Cancel</button>
                  </pos-column>
                </pos-row>
              </pos-form-field>
            </pos-column>
            <pos-column md="6" xs="12" class="mt-pix-10">
              <pos-dashboard-terminal-properties
                *ngIf="terminal.TerminalType === terminalTypeNames.MANAGEMENT_DASHBOARD.Name && terminalDashboardProperties && terminalDashboardProperties.length"
                [(dashboardList)]="terminalDashboardProperties" (onEdit)="onEditTerminalDashboardProperty($event)"
                (onDelete)="onDeleteTerminalDashboardProperties()"></pos-dashboard-terminal-properties>
            </pos-column>

            <!-- Menu explorer terminal type -->
            <pos-column *ngIf="terminal.TerminalType === terminalTypeNames.MENU_EXPLORER.Name">
              <pos-form-field label="Home Screen" [validationMessages]="{required: 'Please select home screen.'}">
                <ng-select posInput [items]="screenList" bindLabel="Name" bindValue="Id"
                  placeholder="Select home screen" [required]="true" appendTo="body" [(ngModel)]="selectedHomeScreen"
                  name="screenList">
                </ng-select>
              </pos-form-field>
            </pos-column>
            <pos-column *ngIf="terminal.TerminalType === terminalTypeNames.MENU_EXPLORER.Name">
              <pos-form-field label="Idle Timeout" inputStyle="row"
                [validationMessages]="{min: 'Please enter idle timeout seconds greater than 0.'}">
                <pos-column md="6" xs="6" class="col-120">
                  <input posInput class="form-control" min="1" name="menuExplorerIdleTimeout"
                    [(ngModel)]="menuExplorerIdleTimeout" type="number" #timeout="ngModel">
                </pos-column>
                <pos-column md="3" xs="4"><label class="label-section">seconds</label></pos-column>
              </pos-form-field>
            </pos-column>

             <!-- order kiosk terminal type -->
                          
            <pos-column *ngIf="terminal.TerminalType === terminalTypeNames.ORDER_KIOSK.Name">
              <pos-form-field [appendPaddingTop]="false">
                <p-checkbox [(ngModel)]="isEnabled" name="isEnabled"
                    label="Out of service" binary="true"></p-checkbox>
              </pos-form-field>
            </pos-column>

            <pos-column *ngIf="terminal.TerminalType === terminalTypeNames.ORDER_KIOSK.Name">
              <pos-form-field label="Home Screen" [validationMessages]="{required: 'Please select home screen.'}">
                <ng-select posInput [items]="kioskScreens" bindLabel="Name" bindValue="Id"
                  placeholder="Select home screen" [required]="true" appendTo="body" [(ngModel)]="selectedHomeScreen"
                  name="kioskScreens">
                </ng-select>
              </pos-form-field>
            </pos-column>

            <pos-column *ngIf="terminal.TerminalType === terminalTypeNames.ORDER_KIOSK.Name">
              <pos-form-field label="Continue Order Screen" [validationMessages]="{required: 'Please select continue order screen.'}">
                <ng-select posInput [items]="kioskScreens" bindLabel="Name" bindValue="Id"
                  placeholder="Select continue order screen" [required]="true" appendTo="body" [(ngModel)]="selectedContinueOrderScreen"
                  name="kioskContinueOrderingScreens">
                </ng-select>
              </pos-form-field>
            </pos-column>

            <pos-column *ngIf="terminal.TerminalType === terminalTypeNames.ORDER_KIOSK.Name">
              <pos-form-field label="Resolution">
                <div class="row mx-0">
                  <div class="col-5 col-100 p-0">
                    <input posInput type="number" name="width" #width="ngModel" class="form-control"
                      [(ngModel)]="kioskWidth" />
                  </div>
                  <div class="col-1 text-center col-25 p-0 h-36">
                    <label class="pt-pix-4 font-20">×</label>
                  </div>
                  <div class="col-5 col-100 p-0">
                    <input posInput type="number" name="height" #height="ngModel" class="form-control"
                      [(ngModel)]="kioskHeight" />
                  </div>
                  <div class="label-section col-1 pe-0">
                    px
                  </div>
                </div>
              </pos-form-field>
            </pos-column>
            <pos-column *ngIf="terminal.TerminalType === terminalTypeNames.ORDER_KIOSK.Name">
              <pos-form-field label="Font Family">
                <input posInput type="text" name="kioskFontFamily" #kioskFontFamily="ngModel" class="form-control"
                  [(ngModel)]="fontFamily" />
              </pos-form-field>
            </pos-column>

            <pos-column *ngIf="terminal.TerminalType === terminalTypeNames.ORDER_KIOSK.Name">
              <pos-form-field label="Slideshow" [validationMessages]="{required: 'Please select slideshow.'}">
                <ng-select posInput [items]="slideshows" [hideSelected]="true" appendTo="body" bindLabel="Name"
                  bindValue="Id" placeholder="Select slideshow" [(ngModel)]="terminalSlideshowMap.SlideshowId"
                  name="slideshow" #slideshow="ngModel" [required]="true">
                </ng-select>
              </pos-form-field>
            </pos-column>

            <pos-column *ngIf="terminal.TerminalType === terminalTypeNames.ORDER_KIOSK.Name">
              <pos-form-field label="User Idle Timeout">
                <input posInput posOnlyNumbers name="userIdleTimeout" class="form-control col-120" 
                  [(ngModel)]="userIdleTimeout"/>
              </pos-form-field>
            </pos-column>
          </pos-row>

          <!-- waitlist terminal type -->           
          <pos-column *ngIf="terminal.TerminalType === terminalTypeNames.WAITLIST.Name">
            <pos-form-field label="Notification Text">
              <textarea posInput class="form-control" rows="5" id="notificationText"
                name="notificationText" class="form-control"
                (click)="setCaretPosition('notificationText')" (keyup)="setCaretPosition('notificationText')"
                [(ngModel)]="notificationText"></textarea>
              <span class="me-pix-20 mt-pix-n30 position-relative float-end">
                <span class="font-20 pointer grey">
                  <pos-icon [icon]="icons.code" (click)="isOpenVariableForNotificationText = !isOpenVariableForNotificationText">
                  </pos-icon>
                </span>
                <div *ngIf="isOpenVariableForNotificationText" class="col-200 order-variable-select">
                  <ng-select posInput [items]="notificationTextVariables" placeholder="Select notification text variable"
                    id="variableForNotificationText" name="variableForNotificationText"
                    (change)="changeOrderVariable(selectedNotificationTextVariable, notificationText, 'notificationText')"
                    [isOpen]="true" [(ngModel)]="selectedNotificationTextVariable">
                  </ng-select>
                </div>
              </span>
            </pos-form-field>
           </pos-column>

           <pos-column *ngIf="terminal.TerminalType === terminalTypeNames.WAITLIST.Name">
            <pos-form-field label="Max Entries">
              <input posInput posOnlyNumbers name="maxEntries" class="form-control col-120" 
                [(ngModel)]="maxEntries"/>
            </pos-form-field>
           </pos-column>

           <pos-column *ngIf="terminal.TerminalType === terminalTypeNames.WAITLIST.Name">
            <pos-form-field label="Max Wait Time">
              <pos-row class="align-items-center">
                <pos-column class="col-120">
                  <input posInput posOnlyNumbers name="maxWaitTime" class="form-control" 
                    [(ngModel)]="maxWaitTime"/>
                </pos-column>
                <pos-column class="col-120 ps-0">
                  Minute(s)
                </pos-column>
              </pos-row>
            </pos-form-field>
           </pos-column>

          <!-- Display Menu terminal type -->
          <div class="pt-pix-10 col-12 section-border px-pix-5"
            *ngIf="(terminal.TerminalType === terminalTypeNames.MENU_DISPLAY.Name)">
            <legend class="reset-style bold">
              <span class="bold"> Overrides </span>
            </legend>
            <pos-menu-display-override-config [displayMenuData]="displayMenuData"
              [(menuDisplaySettings)]="menuDisplaySettings" *ngIf="isLoadMenuDisplayOverride || !terminal.Id"
              [isFormSubmitted]="terminalForm.submitted">
            </pos-menu-display-override-config>
          </div>

          <div class="col-12 p-0 section-border pt-pix-10">
            <legend class="reset-style bold">
              <span class="bold"> Link with IP </span>
            </legend>
            <pos-row class="px-2">
              <pos-column>
                <pos-form-field>
                  <div class="btn-group">
                    <label class="btn btn-radio col-85"
                      [ngClass]=" !ipAddressLevel ?'active btn-primary normal': 'btn-secondary'">
                      <input [(ngModel)]="ipAddressLevel" [value]="false" type="radio" name="ipLevel">
                      Default
                    </label>
                    <label class="btn btn-radio col-85"
                      [ngClass]=" ipAddressLevel ?'active btn-danger normal': 'btn-secondary'">
                      <input [(ngModel)]="ipAddressLevel" [value]="true" type="radio" name="ipLevel">
                      Required
                    </label>
                  </div>
                </pos-form-field>
              </pos-column>
              <pos-column>
                <pos-form-field label="IP Address">
                  <input posInput class="form-control" id="ip" name="ip" [(ngModel)]="ipAddress" type="text"
                    #ip="ngModel" />
                </pos-form-field>
              </pos-column>
            </pos-row>
          </div>
        </div>
      </div>
      <div class="col-12 action-button-container page-footer">
        <button class="btn btn-primary btn-lg btn-save pos-button white" scrollup type="submit"
          value="Save">Save</button>
        <button class="btn btn-secondary btn-lg btn-cancel pos-button" (click)="cancelTerminalEdit()" scrollup
          type="button" value="Cancel">Cancel</button>
      </div>
    </context-provider>
  </form>
</pos-edit-wrapper>
