<pos-edit-wrapper heading="Select inventory product" (close)="cancel()">
    <form #deductInventoryProductForm="ngForm" (ngSubmit)="addDeductInventoryProduct(deductInventoryProductForm.valid)"
        autocomplete="off">
        <div class="col-12 pos-modal-body screen-scroll">
            <pos-row colXs="12" class="p-0">
                <pos-column>
                    <pos-form-field label="Inventory Product"
                        [validationMessages]="{required: 'Please select inventory product'}"
                        inputStyle="col-sm-8 col-12">
                        <ng-select posInput #inventoryProduct="ngModel" name="inventoryProduct" appendTo="body"
                            [items]="inventoryProducts" bindLabel="Name" bindValue="Id" #inventoryProductSelection
                            [(ngModel)]="inventoryProductToDeduct.InventoryProductId"
                            placeholder="Select inventory product" [required]="true"
                            (ngModelChange)="changeInventoryProduct()">
                            <ng-template ng-option-tmp let-item="item" let-index="index">
                                <span [innerHTML]="item.Name"></span>
                            </ng-template>
                        </ng-select>
                    </pos-form-field>
                </pos-column>
                <pos-column>
                    <pos-form-field label="Qty" inputStyle="col-md-9 col-12"
                        [validationMessages]="{ required: 'Please enter unit quantity.', min: 'Qty should be greater than 0.', max: 'Qty should be less than or equal to available qty ' + availableQty + (selectedUnit ? ' (' + Pluralize(selectedUnit, availableQty) +')' : '') +'.' }">
                        <pos-row class="p-0">
                            <pos-column xs="9" class="pe-0 row mx-0">
                                <input posInput name="productUnitQty" #productUnitQty="ngModel" type="number"
                                    class="form-control col-120 col-3 me-pix-10"
                                    [(ngModel)]="inventoryProductToDeduct.Qty" [required]="true" id="productUnitQty"
                                    [max]="availableQty" step="{{defaultConsumptionQty??1}}" [min]="0.0001"
                                    [disabled]="doNotAllowOverridingQty"/>
                                <label class="label-section pt-pix-7 display-flex col-50 p-0"
                                    *ngIf="inventoryProductToDeduct.InventoryProductId">{{
                                    inventoryProductToDeduct.Qty && selectedUnit ? Pluralize(selectedUnit,
                                    inventoryProductToDeduct.Qty)
                                    : selectedUnit }}

                                </label>
                            </pos-column>
                            <pos-column xs="3" class="p-0">
                                <button type="submit" class="btn-primary btn btn-save pos-button">Add</button>
                            </pos-column>
                        </pos-row>
                    </pos-form-field>
                </pos-column>
            </pos-row>
            <pos-row colXs="12" class="pt-pix-10 mx-0">
                <pos-column class="p-0">
                    <ng-table #gridComponent [columns]="inventoryColumns" [sort]="false" [data]="selectedInventories"
                        [paginator]="false" [search]="false"></ng-table>
                </pos-column>
            </pos-row>
        </div>
        <div class="col-12 action-button-container popup-footer">

            <button type="button" class="btn-secondary btn-lg btn btn-cancel-modal pos-button"
                (click)="cancel()">Close</button>
        </div>
        <ng-template let-data="rowData" #unitQtyTemplate>
            <span class="vertical-mid">
                {{data.Qty}} {{data.Unit}}
            </span>
        </ng-template>
    </form>
</pos-edit-wrapper>
