import { AlertsService } from './../../../../shared/services/alerts.service';
import { ApplicationStateService } from './../../../../shared/services/application-state.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Component, ViewChild } from '@angular/core';
import { MathsUtilityService, SpinnerService, Messages, ModalBackdropService, ModalComponent } from 'src/app/shared';
import * as _ from 'lodash';
import { InventoryLogService } from 'src/app/shared/services/inventory-log.service';
import { StringUtils } from 'src/app/shared/string-utils/string-utils';
import { Subscription } from 'rxjs/internal/Subscription';

@Component({
  selector: 'pos-log-inventory-on-exhaustion',
  templateUrl: './log-inventory-on-exhaustion.component.html'
})
export class LogInventoryOnExhaustionComponent extends ModalComponent {

  inventoryExhaustionEventSubscription: Subscription;
  modalRef: BsModalRef;
  confirmInventoryModalRef: BsModalRef;
  settingParam: any;
  inventoryProduct: any = {};
  eventMessage: any;
  salesInventoryProducts: any = [];
  inventoryProductList = [];
  exhaustionMessage = 'Inventory exhausted has been calculated for this product. Please check inventory and log current on-hand quantity.';

  @ViewChild('logInventoryProductModal') public logInventoryProductModal: any;
  constructor(private bsModalRef: BsModalRef,
    private modalBackdropService: ModalBackdropService,
    private applicationStateService: ApplicationStateService,
    private alertService: AlertsService,
    private mathsUtilityService: MathsUtilityService,
    private inventoryLogService: InventoryLogService,
    private spinnerService: SpinnerService) {
      super(bsModalRef)
  }

  onCancel() {
    this.close.emit();
  }

  onLog() {
    this.inventoryProductList = [];
    if (
      (this.inventoryProduct.IsUpdated && this.inventoryProduct.UnitQtyIncrement != null &&
        this.mathsUtilityService.floatSafeModulus(
          parseFloat(this.inventoryProduct.Qty),
          parseFloat(this.inventoryProduct.UnitQtyIncrement)
        ) != 0)) {
      return;
    } else {
      this.updateLogInventory();
    }
  }

  updateLogInventory() {
    this.inventoryProduct.IsUpdated = true;
    this.inventoryProductList.push(this.inventoryProduct);
    const inventoryLogData = {
      CategoryName: this.inventoryProduct.Category + ' - ' + this.inventoryProduct.SubCategory,
      CategoryList: this.inventoryProductList,
      IsChecked: true
    };
    const userDetails = this.applicationStateService.userDetails;
    let inventoryLogList = [];
    inventoryLogList.push(inventoryLogData);
    const objInventory = {
      InventoryList: inventoryLogList,
      UserName: userDetails.lastname + ' ' + userDetails.firstname,
      UserId: userDetails.id,
      FilterValue: '',
      FilterName: '',
    };
    this.spinnerService.show();
    this.inventoryLogService.updateInventoryLog(objInventory)
      .subscribe({
        next: (res) => {
          this.alertService.renderSuccessMessage(
            StringUtils.format(Messages.InventoryLogSuccess,
              { 'productName': this.inventoryProduct.Product })
          );
          this.modalRef.hide();
          this.modalBackdropService.removeBackdrop();
        }, error: this.alertService.showApiError,
        complete: () => {
          this.spinnerService.hide();
        }
      });
  }
}
