import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs';
import { AlertsService, DomainConstants, ModalService, RuntimeConstants, SpinnerService, plusMessage, addKioskButton } from 'src/app/shared';
import { ButtonDesign, KioskConfigSetupItem, KioskScreenChoice, KioskScreenConfig, LeftPanelConfig, RightPanelConfig, ScreenConfig } from 'src/app/order-kiosk/interfaces';
import { KioskNavigationService, OrderKioskConfigService, OrderKioskScreenChoiceService } from 'src/app/order-kiosk/services';
import { SetKioskButtonComponent } from 'src/app/order-kiosk';
import { find } from 'lodash';

declare let $: any;

@Component({
  selector: 'pos-welcome-screen-two',
  templateUrl: './welcome-screen-two.component.html',
  styleUrls: ['./welcome-screen-two.component.scss']
})
export class WelcomeScreenTwoComponent {
  @Input() screenConfigs: Array<KioskScreenConfig> = [];
  @Input() screenId: number;
  @Input() screenButtons: Array<KioskScreenChoice> = [];
  leftPanelButtons: Array<KioskScreenChoice> = [];
  rightPanelButtons: Array<KioskScreenChoice> = [];
  screenConfig: ScreenConfig;
  leftPanelConfig: LeftPanelConfig;
  rightPanelConfig: RightPanelConfig;
  rightPanelButtonConfig: KioskScreenConfig;
  buttonsDesign: Array<ButtonDesign> = [];
  imageRootPath = RuntimeConstants.IMAGE_BASE_PATH + "/order-kiosk/";
  containerHeight: number;
  templateType = DomainConstants.KioskScreenTemplateTypes.WelcomeScreenLayout;
  isDesignMode = false;
  icons = {
    addKioskButton,
    plusMessage
  }
  setupItems: KioskConfigSetupItem;

  constructor(private orderKioskConfigService: OrderKioskConfigService,
    private orderKioskScreenChoiceService: OrderKioskScreenChoiceService,
    private spinnerService: SpinnerService,
    private alertsService: AlertsService,
    private route: ActivatedRoute,
    private modalService: ModalService,
    private kioskNavigationService: KioskNavigationService
  ) {
    this.screenConfig = orderKioskConfigService.newScreenConfig();
    this.leftPanelConfig = orderKioskConfigService.newLeftPanelConfig();
    this.rightPanelConfig = orderKioskConfigService.newRightPanelConfig();
    this.isDesignMode = route.snapshot.params.id;
  }

  ngOnInit(): void {
    this.resizeWindow();
    this.prepareConfigs();
    this.calculateInvoiceHeight();
    this.setupItems = { DesignButton: true };
  }

  ngAfterViewInit(): void {
    this.calculateInvoiceHeight();
  }

  calculateInvoiceHeight() {
    this.containerHeight = this.orderKioskConfigService.calculateInvoiceHeight(this.screenConfig);
  }

  prepareConfigs() {
    const screenConfig = this.orderKioskConfigService.parseConfigProperties(DomainConstants.KioskScreenConfigProperties.ScreenConfig, this.screenConfigs);
    if (screenConfig) this.screenConfig = screenConfig;

    const leftPanelConfig = this.orderKioskConfigService.parseConfigProperties(DomainConstants.KioskScreenConfigProperties.LeftPanelConfig, this.screenConfigs);
    if (leftPanelConfig) this.leftPanelConfig = leftPanelConfig;

    const rightPanelConfig = this.orderKioskConfigService.parseConfigProperties(DomainConstants.KioskScreenConfigProperties.RightPanelConfig, this.screenConfigs);
    if (rightPanelConfig) this.rightPanelConfig = rightPanelConfig;

    const rightPanelButtonConfig = find(this.screenConfigs, x => x.Name == DomainConstants.KioskScreenConfigProperties.RightPanelButtons);
    if (rightPanelButtonConfig) this.rightPanelButtonConfig = rightPanelButtonConfig;

    this.buttonsDesign = this.orderKioskConfigService.parseConfigProperties(DomainConstants.KioskScreenConfigProperties.RightPanelButtons, this.screenConfigs);

    this.leftPanelButtons = this.screenButtons.filter(x => x.ScreenConfig.Name == 'LeftPanelButtons');
    this.rightPanelButtons = this.screenButtons.filter(x => x.ScreenConfig.Name == 'RightPanelButtons');

    this.orderKioskConfigService.setScreenButtonsBackgroundColor(this.leftPanelButtons, this.leftPanelConfig.Opacity);
    this.orderKioskConfigService.setScreenButtonsBackgroundColor(this.rightPanelButtons, this.rightPanelConfig.Opacity);
  }

  resizeWindow() {
    $(window).resize(() => {
      this.calculateInvoiceHeight();
    });
  }

  buttonClick = (kioskScreenChoice: KioskScreenChoice, isLeftPanel = false) => {
    if (kioskScreenChoice.Button && !this.isDesignMode) {
      kioskScreenChoice.Button.NextScreenId = kioskScreenChoice.NextScreenId;
      this.kioskNavigationService.buttonClick(kioskScreenChoice.Button, this.screenId, kioskScreenChoice.ConfigId);
    } else if (!kioskScreenChoice.Button && this.isDesignMode) {
      this.configureButton(kioskScreenChoice, isLeftPanel);
    }
  }

  addKioskScreenChoice(isLeftPanel = true) {
    this.spinnerService.show();
    const kioskChoice = this.orderKioskScreenChoiceService.newScreenChoice();
    kioskChoice.ScreenId = this.screenId;
    kioskChoice.ConfigId = isLeftPanel ? this.leftPanelButtons?.[0]?.ConfigId : this.rightPanelButtons?.[0]?.ConfigId;
    kioskChoice.Ordinal = isLeftPanel ? Math.max(...this.leftPanelButtons.map(x => x.Ordinal)) + 1: Math.max(...this.rightPanelButtons.map(x => x.Ordinal)) + 1;
    this.orderKioskScreenChoiceService.insertAndGet(kioskChoice)
      .pipe(finalize(() => this.spinnerService.hide()))
      .subscribe({
        next: (res: KioskScreenChoice) => {
          if (isLeftPanel)
            this.leftPanelButtons = [...this.leftPanelButtons, res];
          else
            this.rightPanelButtons = [...this.rightPanelButtons, res];
          this.buttonClick(res);
        }, error: this.alertsService.showApiError
      });
  }

  configureButton(button: KioskScreenChoice, isLeftPanel: boolean) {
    const modalRef = this.modalService.show(SetKioskButtonComponent, {
      backdrop: 'static',
      class: 'vertical-center',
      animated: false,
      keyboard: false,
      initialState: {
        kioskScreenButton: button
      }
    });
    modalRef.close.subscribe((res) => {
      if (button.Button) {
        button.Button.BackgroundColor = this.orderKioskConfigService.getBackgroundColor(button.Button, isLeftPanel ? this.leftPanelConfig.Opacity : this.rightPanelConfig.Opacity);
      }
    });
  }

  emitScreenButtonsChange(screenButtons, isLeftPanel = true) {
    if (isLeftPanel) {
      this.leftPanelButtons = screenButtons;
    } else this.rightPanelButtons = screenButtons;
  }

}
