import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RuntimeConstants } from '../constants';
import { AiShake } from '../interface/ai-shake';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AiShakeService {

  baseApiUrl = `${RuntimeConstants.API_PATH}AIShake`;

  constructor(private httpService: HttpClient) { }

  searchAiShakes(term: string): Observable<AiShake[]> {
    return this.httpService.get<AiShake[]>(`${this.baseApiUrl}/all?search=${term}`);
  }
}
