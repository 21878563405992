import { Injectable, EventEmitter } from '@angular/core';
import { OrderInvoice } from 'src/app/orders';
import { Account } from '../interface';
import { BehaviorSubject } from 'rxjs';
import { ApplicationStateService } from './application-state.service';

@Injectable({providedIn: 'root'})
export class OrderEventBroadcastingService {

  menuChangeClicked = new EventEmitter<any>();
  inboxMessage = new BehaviorSubject<boolean>(false);
  newOrder = new EventEmitter<boolean>();
  selectSeat = new EventEmitter<number>();
  terminalWarning = new EventEmitter<any>();
  activeOrderClicked = new EventEmitter<number>();
  onAddItemStart = new EventEmitter<number>();
  onAddItemComplete = new EventEmitter<number>();
  hideInvoiceLoader = new EventEmitter<any>();
  reloadHomeState = new EventEmitter<any>();
  reloadOrder = new EventEmitter<OrderInvoice>();
  startNewOrderFromOutSide = new EventEmitter<any>();
  paymentCompleted = new EventEmitter<number>();
  setAccountCompleted = new EventEmitter<Account>();
  deleteOrder = new EventEmitter<number>();
  addToGoSeat = new EventEmitter();
  autoSignOutUser = new EventEmitter();
  openMessageDialog = new BehaviorSubject<boolean>(false);
  forceOpenMessageDialogTimeout: NodeJS.Timeout;
  isMessageDialogOpen = false;
  
  constructor(private applicationStateService: ApplicationStateService) { 
    this.initializeForceMessageDialogOpen();
  }

  changeHomeState() {
    this.reloadHomeState.emit();
  }

  onMenuChangeClicked() {
    this.menuChangeClicked.emit();
  }

  messageButtonEvent(messageFound: boolean) {
    this.inboxMessage.next(messageFound);
  }

  newOrderEvent(isRequiredNavigateToHome) {
    this.newOrder.emit(isRequiredNavigateToHome);
  }

  onTerminalWarning(warnings: any) {
    this.terminalWarning.emit(warnings);
  }

  onActiveOrderClicked(orderId) {
    this.activeOrderClicked.emit();
  }

  onAddItemStarted() {
    this.onAddItemStart.emit();
  }

  onAddItemCompleted() {
    this.onAddItemComplete.emit();
  }

  onOrderReload(order: OrderInvoice) {
    this.reloadOrder.emit(order);
  }

  onStartNewOrderFromOutSide() {
    this.startNewOrderFromOutSide.emit();
  }
  onPaymentCompleted(orderId: number) {
    this.paymentCompleted.emit(orderId);
  }

  onSetAccountCompleted(account: Account) {
    this.setAccountCompleted.emit(account);
  }

  onSeatSelect(seatId) {
    this.selectSeat.emit(seatId);
  }

  onHideInvoiceLoader() {
    this.hideInvoiceLoader.emit();
  }

  onDeleteOrder(orderId: number) {
    this.deleteOrder.emit(orderId);
  }

  onAddToGoSeat() {
    this.addToGoSeat.emit();
  }

  onAutoSignOutUser() {
    this.autoSignOutUser.emit();
  }

  initializeForceMessageDialogOpen() {
    this.inboxMessage.subscribe({
      next: (res: boolean) => { 
        const autoPopupTimeout = this.applicationStateService.settingParam.AutoOpenMessageTimeout;

        if(!res) {
          this.openMessageDialog.next(false);
          clearTimeout(this.forceOpenMessageDialogTimeout);
          this.forceOpenMessageDialogTimeout = null;
          return;
        }

        if (!autoPopupTimeout 
          || (res && this.isMessageDialogOpen)
          || (res && this.forceOpenMessageDialogTimeout)
        ) {
          return;
        }
        
        this.forceOpenMessageDialogTimeout = setTimeout(() => {
          clearTimeout(this.forceOpenMessageDialogTimeout);
          this.forceOpenMessageDialogTimeout = null;
          if (!this.isMessageDialogOpen) {
            this.openMessageDialog.next(true);
          }
        }, autoPopupTimeout * 1000);
      }
    });
  }
}

