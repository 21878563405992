import { IconSpecification } from './icon-specification';

export const plus: IconSpecification = {
    iconClasses: ['fas', 'plus'],
    additionalClasses: ['faplus']
};

export const normalPlus: IconSpecification = {
    iconClasses: ['fas', 'plus'],
    additionalClasses: ['normal-plus']
}
export const drawerCheckPointPlus: IconSpecification = {
    iconClasses: ['fas', 'plus']
};
export const plusMessage: IconSpecification = {
    iconClasses: ['fas', 'plus']
};
export const printOrderManagement: IconSpecification = {
    iconClasses: ['fas', 'print'],
    additionalClasses: ['print-ordermanagement', 'white']
};

export const rectangleLandscape: IconSpecification = {
    iconClasses: ['fad', 'rectangle-landscape']
};

export const rectanglePortrait: IconSpecification = {
    iconClasses: ['fad', 'rectangle-portrait']
};

export const printOrderInvoice: IconSpecification = {
    iconClasses: ['fas', 'print']
};

export const printReportManagement: IconSpecification = {
    iconClasses: ['fas', 'print'],
    additionalClasses: ['print-ordermanagement']
};

export const pencil: IconSpecification = {
    iconClasses: ['fas', 'pencil-alt']
};

export const editToggleInvoiceArea: IconSpecification = {
    iconClasses: ['fas', 'pencil-alt'],
    additionalClasses: ['faedittoggleinvoicearea']
};
export const editScreenSetup: IconSpecification = {
    iconClasses: ['far', 'edit']
};
export const editSolid: IconSpecification = {
    iconClasses: ['fas', 'edit']
};
export const trashRegularOrderInvoice: IconSpecification = {
    iconClasses: ['far', 'trash-alt'],
    additionalClasses: ['fatrashregularorderinvoice']
};
export const trashRegularOrderInvoiceDisable: IconSpecification = {
    iconClasses: ['far', 'trash-alt'],
    additionalClasses: ['fatrashregularorderinvoicedisable']
};
export const trashScreenSetup: IconSpecification = {
    iconClasses: ['fas', 'trash']
};
export const dashboardList: IconSpecification = {
    iconClasses: ['fas', 'tachometer-alt'],
    additionalClasses: ['font-17', 'white'],
    backgroundClasses: ['dashboard-color']
};
export const dashboardManagementTerminal: IconSpecification = {
    iconClasses: ['fas', 'tachometer-alt'],
    additionalClasses: ['fadashboardmanagement']
};
export const stackTachometer: IconSpecification = {
    iconClasses: ['fas', 'tachometer-alt'],
    additionalClasses: ['fa-stack-1x', 'white', 'tachometer']
};
export const stackCircleMediumViolet: IconSpecification = {
    iconClasses: ['fas', 'circle'],
    additionalClasses: ['fa-stack-2x', 'medium-violet']
};

export const solidVideo: IconSpecification = {
    iconClasses: ['fas', 'video'],
};

export const solidCircle: IconSpecification = {
    iconClasses: ['fas', 'circle'],
};
export const bars: IconSpecification = {
    iconClasses: ['fas', 'bars'],
    additionalClasses: ['fabars']
};
export const lightBulb: IconSpecification = {
    iconClasses: ['far', 'lightbulb']
};
export const lightBulbPickScreen: IconSpecification = {
    iconClasses: ['far', 'lightbulb'],
    additionalClasses: ['falightbulbpickscreen']
};
export const ban: IconSpecification = {
    iconClasses: ['fas', 'ban'],
    additionalClasses: ['white', 'font-15'],
    backgroundClasses: ['ban-color']
};
export const checkCircleWhite: IconSpecification = {
    iconClasses: ['far', 'check-circle'],
    additionalClasses: ['white', 'font-16'],
    backgroundClasses: ['check-circle-color']
};
export const checkCircleGreen: IconSpecification = {
    iconClasses: ['fas', 'check-circle'],
    additionalClasses: ['loyalty-form-submited-circle-color', 'font-80']
};
export const checkCircle: IconSpecification = {
    iconClasses: ['far', 'check-circle'],
    additionalClasses: ['facheckcircle']
};
export const checkCircleMakeTable: IconSpecification = {
    iconClasses: ['far', 'check-circle'],
    additionalClasses: ['facheckcirclemaketable']
};
export const checkCircleSolid: IconSpecification = {
    iconClasses: ['fas', 'check-circle']
};
export const image: IconSpecification = {
    iconClasses: ['far', 'image'],
    additionalClasses: ['faimage']
};
export const menuSetupUnusedImage: IconSpecification = {
    iconClasses: ['far', 'image']
};
export const bug: IconSpecification = {
    iconClasses: ['fas', 'bug'],
    // additionalClasses: ['fabug']
};
export const backward: IconSpecification = {
    iconClasses: ['fas', 'backward']
};
export const forward: IconSpecification = {
    iconClasses: ['fas', 'forward']
};
export const times: IconSpecification = {
    iconClasses: ['fas', 'times']
};
export const search: IconSpecification = {
    iconClasses: ['fas', 'search'],
    additionalClasses: ['fasearch']
};
export const backgroundJobsSearch: IconSpecification = {
    iconClasses: ['fas', 'search']
};
export const calendar: IconSpecification = {
    iconClasses: ['fas', 'calendar-alt']
};
export const megaphone: IconSpecification = {
    iconClasses: ['fas', 'megaphone']
};
export const check: IconSpecification = {
    iconClasses: ['fas', 'check']
};
export const upload: IconSpecification = {
    iconClasses: ['fas', 'upload']
};
export const clone: IconSpecification = {
    iconClasses: ['far', 'clone']
};
export const infoCircle: IconSpecification = {
    iconClasses: ['fas', 'info-circle']
};
export const home: IconSpecification = {
    iconClasses: ['fas', 'home']
};
export const shoppingCart: IconSpecification = {
    iconClasses: ['fas', 'shopping-cart']
};
export const list: IconSpecification = {
    iconClasses: ['fas', 'list']
};
export const language: IconSpecification = {
    iconClasses: ['fas', 'language']
};
export const listAlt: IconSpecification = {
    iconClasses: ['far', 'list-alt']
};
export const fileAlt: IconSpecification = {
    iconClasses: ['far', 'file-alt']
};
export const file: IconSpecification = {
    iconClasses: ['far', 'file']
};
export const barcode: IconSpecification = {
    iconClasses: ['fas', 'barcode']
};
export const barcodeScan: IconSpecification = {
    iconClasses: ['fas', 'barcode-scan']
};
export const truck: IconSpecification = {
    iconClasses: ['fas', 'fa-truck']
};
export const utensils: IconSpecification = {
    iconClasses: ['fas', 'fa-utensils']
};
export const cartArrowDown: IconSpecification = {
    iconClasses: ['fas', 'cart-arrow-down']
};
export const copy: IconSpecification = {
    iconClasses: ['far', 'copy']
};
export const play: IconSpecification = {
    iconClasses: ['fas', 'play']
};
export const pause: IconSpecification = {
    iconClasses: ['fas', 'pause']
}
export const stop: IconSpecification = {
    iconClasses: ['fas', 'stop']
};
export const stepBackward: IconSpecification = {
    iconClasses: ['fas', 'step-backward']
};
export const stepForward: IconSpecification = {
    iconClasses: ['fas', 'step-forward']
};
export const clock: IconSpecification = {
    iconClasses: ['far', 'clock']
};
export const exclamationTriangle: IconSpecification = {
    iconClasses: ['fas', 'exclamation-triangle']
};
export const cogs: IconSpecification = {
    iconClasses: ['fas', 'cogs']
};
export const cog: IconSpecification = {
    iconClasses: ['fas', 'cog']
};
export const magic: IconSpecification = {
    iconClasses: ['fas', 'magic']
};
export const timesCircle: IconSpecification = {
    iconClasses: ['fas', 'times-circle']
};
export const sync: IconSpecification = {
    iconClasses: ['fas', 'sync']
};
export const userTimes: IconSpecification = {
    iconClasses: ['fas', 'user-times']
};
export const rssSquare: IconSpecification = {
    iconClasses: ['fas', 'rss-square']
};
export const circle: IconSpecification = {
    iconClasses: ['fas', 'circle']
};
export const circleRegular: IconSpecification = {
    iconClasses: ['far', 'circle']
};
export const database: IconSpecification = {
    iconClasses: ['fas', 'database']
};
export const menuBackupDatabase: IconSpecification = {
    iconClasses: ['fas', 'database'],
    additionalClasses: ['fa-stack-1x', 'white', 'align-vertical-center']
}
export const reply: IconSpecification = {
    iconClasses: ['fas', 'reply']
};
export const keyboard: IconSpecification = {
    iconClasses: ['far', 'keyboard']
};
export const angleLeft: IconSpecification = {
    iconClasses: ['fas', 'angle-left']
};
export const angleRight: IconSpecification = {
    iconClasses: ['fas', 'angle-right']
};
export const angleDown: IconSpecification = {
    iconClasses: ['fas', 'angle-down']
};
export const dollarSign: IconSpecification = {
    iconClasses: ['fas', 'dollar-sign']
};
export const minusCircle: IconSpecification = {
    iconClasses: ['fas', 'minus-circle']
};
export const replyAll: IconSpecification = {
    iconClasses: ['fas', 'reply-all']
};
export const code: IconSpecification = {
    iconClasses: ['fas', 'code']
};
export const codeBranch: IconSpecification = {
    iconClasses: ['fas', 'code-branch']
};
export const undo: IconSpecification = {
    iconClasses: ['fas', 'undo']
};
export const share: IconSpecification = {
    iconClasses: ['fas', 'share']
};
export const shareAll: IconSpecification = {
    iconClasses: ['fas', 'share-all']
};
export const arrowAltCircleLeft: IconSpecification = {
    iconClasses: ['fas', 'arrow-alt-circle-left']
};
export const arrowLeft: IconSpecification = {
    iconClasses: ['fas', 'arrow-left']
};
export const levelDown: IconSpecification = {
    iconClasses: ['fas', 'level-down-alt']
};
export const envelopeSolid: IconSpecification = {
    iconClasses: ['fas', 'envelope']
};
export const envelopeRegular: IconSpecification = {
    iconClasses: ['far', 'envelope']
};
export const longArrowLeft: IconSpecification = {
    iconClasses: ['fas', 'long-arrow-alt-left']
};
export const checkSquare: IconSpecification = {
    iconClasses: ['far', 'check-square']
};
export const book: IconSpecification = {
    iconClasses: ['fas', 'book']
};
export const desktop: IconSpecification = {
    iconClasses: ['fas', 'desktop']
};
export const save: IconSpecification = {
    iconClasses: ['far', 'save']
};
export const arrowsAlt: IconSpecification = {
    iconClasses: ['fas', 'arrows-alt']
};
export const arrowsAlth: IconSpecification = {
    iconClasses: ['fas', 'arrows-alt-h']
};
export const objectUngroup: IconSpecification = {
    iconClasses: ['far', 'object-ungroup']
};
export const plusSquare: IconSpecification = {
    iconClasses: ['fas', 'plus-square']
};
export const paintBrush: IconSpecification = {
    iconClasses: ['fas', 'paint-brush']
};
export const tv: IconSpecification = {
    iconClasses: ['fas', 'tv']
};
export const terminal: IconSpecification = {
    iconClasses: ['fas', 'terminal']
};
export const thLarge: IconSpecification = {
    iconClasses: ['fas', 'th-large']
};
export const minus: IconSpecification = {
    iconClasses: ['fas', 'minus']
};
export const comments: IconSpecification = {
    iconClasses: ['far', 'comments']
};
export const caretUp: IconSpecification = {
    iconClasses: ['fas', 'caret-up']
};
export const caretDown: IconSpecification = {
    iconClasses: ['fas', 'caret-down']
};
export const caretRight: IconSpecification = {
    iconClasses: ['fas', 'caret-right']
};
export const caretLeft: IconSpecification = {
    iconClasses: ['fas', 'caret-left']
};
export const spinner: IconSpecification = {
    iconClasses: ['fas', 'spinner'],
    pulse: true,
    fixedWidth: true
};
export const angleDoubleRight: IconSpecification = {
    iconClasses: ['fas', 'angle-double-right']
};
export const angleDoubleLeft: IconSpecification = {
    iconClasses: ['fas', 'angle-double-left']
};
export const rocket: IconSpecification = {
    iconClasses: ['fas', 'rocket']
};
export const minusSquare: IconSpecification = {
    iconClasses: ['fas', 'minus-square']
};

export const mapPin: IconSpecification = {
    iconClasses: ['fas', 'map-pin']
};
export const square: IconSpecification = {
    iconClasses: ['fas', 'square'],
    additionalClasses: ['fasquare']
};
export const squareO: IconSpecification = {
    iconClasses: ['far', 'square'],
    additionalClasses: ['fasquare-o']
};
export const th: IconSpecification = {
    iconClasses: ['fas', 'th']
};
export const stackTh: IconSpecification = {
    iconClasses: ['fas', 'th'],
    additionalClasses: ['white', 'fa-stack-1x', 'stack-th']
};
export const box: IconSpecification = {
    iconClasses: ['fas', 'box']
};
export const rerun: IconSpecification = {
    iconClasses: ['fas', 'redo']
};

export const redoWhite: IconSpecification = {
    iconClasses: ['fas', 'redo'],
    additionalClasses: ['fa-stack-1x', 'white', 'font-15'],
    backgroundClasses: ['dashboard-color']
};

export const diceOneDuotone: IconSpecification = {
    iconClasses: ['fad', 'dice-one']
};
export const taskAll: IconSpecification = {
    iconClasses: ['fas', 'tasks']
};
export const taskToDo: IconSpecification = {
    iconClasses: ['fas', 'clipboard-list']
};
export const taskSkipped: IconSpecification = {
    iconClasses: ['fas', 'ban']
};
export const taskCompleted: IconSpecification = {
    iconClasses: ['far', 'check-circle']
};
export const reportSalesSearchDollar: IconSpecification = {
    iconClasses: ['fas', 'fa-search-dollar', 'black', 'font-x-normal']
};

export const reportEmployeeUsers: IconSpecification = {
    iconClasses: ['fas', 'fa-users', 'black', 'font-x-normal']
};

export const reportInventoryBoxes: IconSpecification = {
    iconClasses: ['fas', 'fa-boxes', 'black', 'font-x-normal']
};

export const reportOperationsBuilding: IconSpecification = {
    iconClasses: ['fas', 'fa-building', 'black', 'font-x-normal']
};

export const reportCustomerUserTag: IconSpecification = {
    iconClasses: ['fas', 'fa-user-tag', 'black', 'font-x-normal']
};

export const reportTroubleshootingSearch: IconSpecification = {
    iconClasses: ['fas', 'fa-search', 'black', 'font-x-normal']
};

export const exportScript: IconSpecification = {
    iconClasses: ['fas', 'file-export']
};

export const bookmark: IconSpecification = {
    iconClasses: ['fas', 'bookmark']
};

export const layerGroup: IconSpecification = {
    iconClasses: ['fas', 'layer-group']
};

export const boxes: IconSpecification = {
    iconClasses: ['fas', 'boxes']
};

export const onlineOrderingCommentAlt: IconSpecification = {
    iconClasses: ['fas', 'comment-alt']
};

export const onlineOrderingImages: IconSpecification = {
    iconClasses: ['fas', 'images']
};

export const onlineOrderingSchedule: IconSpecification = {
    iconClasses: ['fas', 'clock']
};

export const onlineOrderingColor: IconSpecification = {
    iconClasses: ['fas', 'palette']
};

export const externalApiTest: IconSpecification = {
    iconClasses: ['fa-play']
};

export const faPlay: IconSpecification = {
    iconClasses: ['fa-play']
};

export const column: IconSpecification = {
    iconClasses: ['fas', 'columns']
};


export const externalApiCopy: IconSpecification = {
    iconClasses: ['fa-copy']
};

export const ordinalBars: IconSpecification = {
    iconClasses: ['fas', 'fa-bars']
};

export const rawIngredientSalesProduct: IconSpecification = {
    iconClasses: ['fas', 'file-invoice-dollar']
};

export const rawIngredientInventoryProductStore: IconSpecification = {
    iconClasses: ['fas', 'store']
};

export const trainingCog: IconSpecification = {
    iconClasses: ['fa-cog']
};

export const trainingMove: IconSpecification = {
    iconClasses: ['fa-arrows-alt']
};

export const trainingApproveCheckCircle: IconSpecification = {
    iconClasses: ['far', 'check-circle']
};

export const twitterSquare: IconSpecification = {
    iconClasses: ['fab fa-twitter-square']
};

export const thList: IconSpecification = {
    iconClasses: ['fas', 'th-list']
};

export const hashtag: IconSpecification = {
    iconClasses: ['fas', 'hashtag']
}

export const lock: IconSpecification = {
    iconClasses: ['fas', 'lock']
}

export const lockOpen: IconSpecification = {
    iconClasses: ['fas', 'lock-open']
}

export const newProduct: IconSpecification = {
    iconClasses: ['fas', 'plus'],
};

export const onlineOrderingMenuSync: IconSpecification = {
    iconClasses: ['fas', 'sync']
};

export const newAudioFile: IconSpecification = {
    iconClasses: ['fas', 'plus'],
    additionalClasses: ['faplus']
};

export const messageCode: IconSpecification = {
    iconClasses: ['fas', 'code']
};

export const trainingApproveNote: IconSpecification = {
    iconClasses: ['fas', 'sticky-note']
};

export const dicountMoneyBill: IconSpecification = {
    iconClasses: ['fas', 'money-bill']
};

export const switchCard: IconSpecification = {
    iconClasses: ['fas', 'exchange-alt']
};

export const addBalanceToCard: IconSpecification = {
    iconClasses: ['fas', 'dollar-sign']
};

export const giftCard: IconSpecification = {
    iconClasses: ['fas', 'id-card']
};
export const licenseIdCard: IconSpecification = {
    iconClasses: ['fas', 'id-card']
};
export const fieldInfoCircle: IconSpecification = {
    iconClasses: ['fas', 'info-circle'],
    additionalClasses: ['tooltip-info']
};

export const unlock: IconSpecification = {
    iconClasses: ['fas', 'unlock'],
    additionalClasses: ['unlock']
};

export const inventoryLogInfoCircle: IconSpecification = {
    iconClasses: ['far', 'sticky-note'],
    additionalClasses: ['text-primary', 'font-23']
};

export const salesRefreshAvailability: IconSpecification = {
    additionalClasses: ['font-12'],
    iconClasses: ['fas', 'sync']
};

export const inventoryLogClock: IconSpecification = {
    iconClasses: ['far', 'clock'],
    additionalClasses: ['red', 'font-23']
};

export const inventoryLogWarning: IconSpecification = {
    iconClasses: ['fal', 'exclamation-triangle'],
    additionalClasses: ['red']
};

export const salesGroupThLarge: IconSpecification = {
    iconClasses: ['fas', 'fa-th-large']
};
export const salesGroupClock: IconSpecification = {
    iconClasses: ['far', 'fa-clock']
};
export const salesCategoriesTh: IconSpecification = {
    iconClasses: ['fas', 'th']
};
export const salesCategoriesClock: IconSpecification = {
    iconClasses: ['far', 'clock']
};
export const audioNotification: IconSpecification = {
    iconClasses: ['fas', 'bullhorn']
};
export const taxi: IconSpecification = {
    iconClasses: ['fas', 'fa-taxi']
};
export const ship: IconSpecification = {
    iconClasses: ['fas', 'fa-ship']
};
export const car: IconSpecification = {
    iconClasses: ['fas', 'fa-car']
};
export const accountTelephone: IconSpecification = {
    iconClasses: ['fas', 'phone-alt']
}
export const accountAddressCard: IconSpecification = {
    iconClasses: ['far', 'address-card'],
}
export const accountPrint: IconSpecification = {
    iconClasses: ['fas', 'print'],
}
export const memberMoreDetails: IconSpecification = {
    iconClasses: ['fas', 'ellipsis-h'],
}
export const loyaltyCreditCard: IconSpecification = {
    iconClasses: ['fas', 'credit-card'],
}
export const userImpersonate: IconSpecification = {
    iconClasses: ['fas', 'theater-masks']
};
export const informationManagementUser: IconSpecification = {
    iconClasses: ['fas', 'user']
};
export const userMoneyCheckAlt: IconSpecification = {
    iconClasses: ['fas', 'money-check-alt']
};
export const orderButtonBan: IconSpecification = {
    iconClasses: ['fas', 'ban']
};
export const orderInfoInventoryCheckDouble: IconSpecification = {
    iconClasses: ['fas', 'check-double']
};

export const managementDashboardTerminal: IconSpecification = {
    iconClasses: ['fas', 'tachometer-alt'],
    additionalClasses: ['white']
};

export const filterListStack: IconSpecification = {
    iconClasses: ['fas', 'filter'],
    additionalClasses: ['fa-stack-1x', 'black', 'pt-pix-1']
};

export const stackCircleDeepSkyBlue: IconSpecification = {
    iconClasses: ['fas', 'circle'],
    additionalClasses: ['fa-stack-2x', 'deep-sky-blue']
};
export const stackCirclePortage: IconSpecification = {
    iconClasses: ['fas', 'circle'],
    additionalClasses: ['fa-stack-2x', 'portage']
};

export const stackCircleLightSalmon: IconSpecification = {
    iconClasses: ['fas', 'circle'],
    additionalClasses: ['fa-stack-2x', 'lightsalmon']
};

export const serveScreenTerminal: IconSpecification = {
    iconClasses: ['fas', 'rocket'],
    additionalClasses: ['white']
};

export const stackFasListAlt: IconSpecification = {
    iconClasses: ['fas', 'list-alt'],
    additionalClasses: ['white', 'fa-stack-1x', 'stack-th']
};


export const stackRocket: IconSpecification = {
    iconClasses: ['fas', 'rocket'],
    additionalClasses: ['white', 'fa-stack-1x', 'rocket']
};
export const instructionTerminal: IconSpecification = {
    iconClasses: ['fas', 'magic'],
    additionalClasses: ['instruction-terminal__icon-bg__icon']
};
export const stackMagic: IconSpecification = {
    iconClasses: ['fas', 'magic'],
    additionalClasses: ['fa-stack-1x', 'fa-inverse', 'magic']
};
export const stackCircleGreen: IconSpecification = {
    iconClasses: ['fas', 'circle'],
    additionalClasses: ['fa-stack-2x', 'green']
};
export const infractionThumbsDown: IconSpecification = {
    iconClasses: ['fas', 'fa-thumbs-down']
};

export const taskClipboardList: IconSpecification = {
    iconClasses: ['fas', 'clipboard-list']
};
export const adminWrench: IconSpecification = {
    iconClasses: ['fas', 'wrench']
};
export const timeUserClock: IconSpecification = {
    iconClasses: ['fas', 'user-clock']
};
export const signInUserTag: IconSpecification = {
    iconClasses: ['fas', 'user-tag']
};
export const reloadRedo: IconSpecification = {
    iconClasses: ['fas', 'redo']
};
export const userNameCircle: IconSpecification = {
    iconClasses: ['fas', 'user-circle']
};
export const subscriptionBookmark: IconSpecification = {
    iconClasses: ['fas', 'bookmark']
};
export const userSignOut: IconSpecification = {
    iconClasses: ['fas', 'sign-out-alt']
};
export const turnDebugMode: IconSpecification = {
    iconClasses: ['fas', 'bug']
};
export const forceLogOut: IconSpecification = {
    iconClasses: ['fas', 'user-times', 'red', 'font-3em']
};
export const navigateBackward: IconSpecification = {
    iconClasses: ['fas', 'long-arrow-alt-left'],
    additionalClasses: ['fa-2x']
};
export const productPrintToggleOn: IconSpecification = {
    iconClasses: ['fas', 'toggle-on'],
    additionalClasses: ['green']
};
export const requireNameToggleOn: IconSpecification = {
    iconClasses: ['fas', 'toggle-on'],
    additionalClasses: ['green']
};
export const productPrintToggleOff: IconSpecification = {
    iconClasses: ['fas', 'toggle-off'],
};
export const requireNameToggleOff: IconSpecification = {
    iconClasses: ['fas', 'toggle-off'],
};
export const shoppingBag: IconSpecification = {
    iconClasses: ['fas', 'shopping-bag'],
};
export const cashPayoutMoneyBillWave: IconSpecification = {
    iconClasses: ['fas', 'money-bill-wave']
};
export const tablesSolarPanel: IconSpecification = {
    iconClasses: ['fas', 'solar-panel']
};
export const accountOrdersShoppingCart: IconSpecification = {
    iconClasses: ['fas', 'shopping-cart']
};

export const timeClockEntriesClock: IconSpecification = {
    iconClasses: ['far', 'clock']
};

export const salesProductInfo: IconSpecification = {
    iconClasses: ['fas', 'info-circle']
};

export const salesProductPricing: IconSpecification = {
    iconClasses: ['fas', 'dollar-sign']
};

export const salesProductWorkflow: IconSpecification = {
    iconClasses: ['fas', 'arrow-circle-right']
};

export const salesProductButtonsDiceOne: IconSpecification = {
    iconClasses: ['fas', 'dice-one']
};

export const salesProductRecipe: IconSpecification = {
    iconClasses: ['fas', 'clipboard-list']
};

export const salesProductQuickReportFileAlt: IconSpecification = {
    iconClasses: ['fas', 'file-alt']
};

export const salesProductClock: IconSpecification = {
    iconClasses: ['fas', 'clock']
};
export const productSimplyBuilt: IconSpecification = {
    iconClasses: ['fab', 'simplybuilt']
};

export const prepLabelPriting: IconSpecification = {
    iconClasses: ['fas', 'print']
};

export const productInformationInfoCircle: IconSpecification = {
    iconClasses: ['fas', 'info-circle']
};

export const inventorySetupBoxes: IconSpecification = {
    iconClasses: ['fas', 'boxes']
};

export const vendorDetailsTruck: IconSpecification = {
    iconClasses: ['fas', 'truck']
};

export const workflowArrowCircleRight: IconSpecification = {
    iconClasses: ['fas', 'arrow-circle-right']
};

export const inventoryLocationsArrow: IconSpecification = {
    iconClasses: ['fas', 'location-arrow']
};

export const screensDesktop: IconSpecification = {
    iconClasses: ['fas', 'desktop']
};

export const buttonsDiceOne: IconSpecification = {
    iconClasses: ['fas', 'dice-one']
};

export const infoManageAngleDoubleRight: IconSpecification = {
    iconClasses: ['fas', 'angle-double-right']
};

export const infoManageAngleDoubleLeft: IconSpecification = {
    iconClasses: ['fas', 'angle-double-left']
};

export const salesGroupCommentsDollar: IconSpecification = {
    iconClasses: ['fas', 'comments-dollar']
};

export const salesCategoriesCommentDollar: IconSpecification = {
    iconClasses: ['fas', 'comment-dollar']
};

export const salesProductsFileInvoiceDollar: IconSpecification = {
    iconClasses: ['fas', 'file-invoice-dollar']
};

export const salesUnitsThLarge: IconSpecification = {
    iconClasses: ['fas', 'th-large']
};

export const salesSizesFunnelDollar: IconSpecification = {
    iconClasses: ['fas', 'funnel-dollar']
};

export const infoManageBullHorn: IconSpecification = {
    iconClasses: ['fas', 'bullhorn']
};

export const vendorStruck: IconSpecification = {
    iconClasses: ['fas', 'truck']
};

export const unitsThLarge: IconSpecification = {
    iconClasses: ['fas', 'th-large']
};

export const inventoryCategoriesUnitList: IconSpecification = {
    iconClasses: ['fas', 'th-list']
};

export const inventorySubCategoriesTh: IconSpecification = {
    iconClasses: ['fas', 'th']
};

export const inventoryProductsStore: IconSpecification = {
    iconClasses: ['fas', 'store']
};

export const inventoryRawIngredient: IconSpecification = {
    iconClasses: ['fas', 'inventory']
};

export const inventoryZonesWarehouse: IconSpecification = {
    iconClasses: ['fas', 'warehouse']
};

export const inventoryScheduleEditor: IconSpecification = {
    iconClasses: ['fas', 'calendar']
};

export const infoManageTimeClockEntriesClock: IconSpecification = {
    iconClasses: ['fas', 'clock']
};

export const loginMessagesCommentDots: IconSpecification = {
    iconClasses: ['fas', 'comment-dots']
};

export const workScheduleCalendarAlt: IconSpecification = {
    iconClasses: ['fas', 'calendar-alt']
};

export const infoManageInfractionThumbsDown: IconSpecification = {
    iconClasses: ['fas', 'thumbs-down']
};

export const infolinksExternalLinkAlt: IconSpecification = {
    iconClasses: ['fas', 'external-link-alt']
};

export const infoLinkSectionsExternalLinkSquareAlt: IconSpecification = {
    iconClasses: ['fas', 'external-link-square-alt']
};

export const users: IconSpecification = {
    iconClasses: ['fas', 'users']
};

export const securityRolesUserLock: IconSpecification = {
    iconClasses: ['fas', 'user-lock']
};

export const jobCodeBusinessTime: IconSpecification = {
    iconClasses: ['fas', 'business-time']
};

export const checkDouble: IconSpecification = {
    iconClasses: ['fas', 'check-double']
};

export const cashDrawerEntriesMoneyBillWave: IconSpecification = {
    iconClasses: ['fas', 'money-bill-wave']
};

export const cashPayoutsSearchDollar: IconSpecification = {
    iconClasses: ['fas', 'search-dollar']
};

export const commentWarningsComments: IconSpecification = {
    iconClasses: ['fas', 'comments']
};

export const inventoryLossReasonEdit: IconSpecification = {
    iconClasses: ['fas', 'edit']
};

export const accountCategoriesClipboardList: IconSpecification = {
    iconClasses: ['fas', 'clipboard-list']
};

export const infoManageTablesSolarPanel: IconSpecification = {
    iconClasses: ['fas', 'solar-panel']
};

export const accountAttributeType: IconSpecification = {
    iconClasses: ['fas', 'align-center']
};

export const equipmentTypesIndustry: IconSpecification = {
    iconClasses: ['fas', 'industry']
};

export const equipmentPropertiesDolly: IconSpecification = {
    iconClasses: ['fas', 'dolly']
};


export const equipmentProperties: IconSpecification = {
    iconClasses: ['fas', 'ballot']
};

export const equipmentListGripVertical: IconSpecification = {
    iconClasses: ['fas', 'grip-vertical']
};

export const iconsVectorSquare: IconSpecification = {
    iconClasses: ['fas', 'vector-square']
};

export const trainingBadge: IconSpecification = {
    iconClasses: ['far', 'id-badge']
};

export const infoManageCogs: IconSpecification = {
    iconClasses: ['fas', 'cogs']
};

export const infoManageShoppingCart: IconSpecification = {
    iconClasses: ['fas', 'shopping-cart']
};

export const infoManageCheckSquare: IconSpecification = {
    iconClasses: ['far', 'check-square']
};

export const tools: IconSpecification = {
    iconClasses: ['fas', 'tools']
};

export const orderinfoInfoCircle: IconSpecification = {
    iconClasses: ['fas', 'info-circle']
};

export const addProductPlusSquare: IconSpecification = {
    iconClasses: ['fas', 'plus-square']
};

export const sendOrderPaperPlane: IconSpecification = {
    iconClasses: ['fas', 'paper-plane']
};

export const settleMoneyBill: IconSpecification = {
    iconClasses: ['fas', 'money-bill']
};

export const cancelOrderUndo: IconSpecification = {
    iconClasses: ['fas', 'undo']
};

export const reviewOrderSearchDollar: IconSpecification = {
    iconClasses: ['fas', 'search-dollar']
};

export const ringupMoneyBill: IconSpecification = {
    iconClasses: ['fas', 'money-bill']
};

export const driverReport: IconSpecification = {
    iconClasses: ['fas', 'file-invoice-dollar']
};

export const salesStatisticsReport: IconSpecification = {
    iconClasses: ['fas', 'tasks']
};

export const cashierReport: IconSpecification = {
    iconClasses: ['fas', 'money-check-alt']
};

export const salesReport: IconSpecification = {
    iconClasses: ['fas', 'money-check-alt']
};

export const allSalesReport: IconSpecification = {
    iconClasses: ['fas', 'credit-card']
};

export const settleSplitPrint: IconSpecification = {
    iconClasses: ['fas', 'print']
};

export const serveOrderCheckSquare: IconSpecification = {
    iconClasses: ['far', 'check-square']
};

export const printCheckPrinting: IconSpecification = {
    iconClasses: ['fas', 'print']
};

export const dispatchTruck: IconSpecification = {
    iconClasses: ['fas', 'truck']
};

export const scheduledOrderClock: IconSpecification = {
    iconClasses: ['fas', 'clock']
};

export const accountsTablesIdBadge: IconSpecification = {
    iconClasses: ['fas', 'id-badge']
};

export const toGoShoppingBag: IconSpecification = {
    iconClasses: ['fas', 'shopping-bag']
};

export const loyalAccountTermCrown: IconSpecification = {
    iconClasses: ['fas', 'crown']
};

export const functionButtonCaretDown: IconSpecification = {
    iconClasses: ['fas', 'caret-down']
};
export const inventoryReceivedTruck: IconSpecification = {
    iconClasses: ['fas', 'truck']
};
export const inventoryPrepUtensils: IconSpecification = {
    iconClasses: ['fas', 'utensils']
};

export const userUnblock: IconSpecification = {
    iconClasses: ['fas', 'unlock-alt']
};
export const nutritionPrint: IconSpecification = {
    iconClasses: ['fas', 'print'],
};
export const ingredientsInfoPrint: IconSpecification = {
    iconClasses: ['fas', 'print'],
};
export const paste: IconSpecification = {
    iconClasses: ['fas', 'paste'],
};
export const applicationLoginUndo: IconSpecification = {
    iconClasses: ['fas', 'undo-alt'],
};
export const applicationLoginArrowCircleRight: IconSpecification = {
    iconClasses: ['fas', 'arrow-circle-right']
};
export const databasePlus: IconSpecification = {
    iconClasses: ['fas', 'plus']
};
export const hardwareLabelPrint: IconSpecification = {
    iconClasses: ['fas', 'print']
};
export const sort: IconSpecification = {
    iconClasses: ['fas', 'sort']
};
export const star: IconSpecification = {
    iconClasses: ['fas', 'star']
};
export const font: IconSpecification = {
    iconClasses: ['fas', 'font']
};
export const terminalTv: IconSpecification = {
    iconClasses: ['fas', 'tv']
};
export const rabbitMqConfigurationCogs: IconSpecification = {
    iconClasses: ['fas', 'cogs']
};
export const centralApiConfigurationCogs: IconSpecification = {
    iconClasses: ['fas', 'tools']
};
export const hardwareToolbox: IconSpecification = {
    iconClasses: ['fas', 'toolbox']
};
export const hardwareInterfacePlug: IconSpecification = {
    iconClasses: ['fas', 'plug']
};
export const externalApiPlug: IconSpecification = {
    iconClasses: ['fas', 'plug']
};
export const cashDrawerMoneyBillWave: IconSpecification = {
    iconClasses: ['fas', 'money-bill-wave']
};
export const printing: IconSpecification = {
    iconClasses: ['fas', 'print']
};
export const branding: IconSpecification = {
    iconClasses: ['fas', 'ad']
};
export const digitalSignageBullHorn: IconSpecification = {
    iconClasses: ['fas', 'bullhorn']
};
export const colorsPaletter: IconSpecification = {
    iconClasses: ['fas', 'palette']
};
export const layoutBoxes: IconSpecification = {
    iconClasses: ['fas', 'boxes']
};
export const music: IconSpecification = {
    iconClasses: ['fas', 'music']
};
export const playListThList: IconSpecification = {
    iconClasses: ['fas', 'th-list']
};
export const djSchemesRoad: IconSpecification = {
    iconClasses: ['fas', 'road']
};
export const taxonomyFileWord: IconSpecification = {
    iconClasses: ['fas', 'file-word']
};
export const deliveryTruckMoving: IconSpecification = {
    iconClasses: ['fas', 'truck-moving']
};
export const taxesMoneyBillAlt: IconSpecification = {
    iconClasses: ['fas', 'money-bill-alt']
};
export const usersCogs: IconSpecification = {
    iconClasses: ['fas', 'users-cog']
};
export const endOfDayMoon: IconSpecification = {
    iconClasses: ['fas', 'moon']
};
export const baselineExpenseMoneyBill: IconSpecification = {
    iconClasses: ['fas', 'money-bill']
};
export const accountsAndTabsUsersTag: IconSpecification = {
    iconClasses: ['fas', 'user-tag']
};
export const loyaltyFormConfigurationCogs: IconSpecification = {
    iconClasses: ['fas', 'cogs']
};
export const loyaltyProgramAttribute: IconSpecification = {
    iconClasses: ['fas', 'align-center']
};
export const recaptchaConfig: IconSpecification = {
    iconClasses: ['fas', 'wrench']
};
export const paymentsMoneyBillAlt: IconSpecification = {
    iconClasses: ['fas', 'money-bill-alt']
};
export const whiteListedAddressBook: IconSpecification = {
    iconClasses: ['far', 'address-book']
};
export const blackListedAddressBook: IconSpecification = {
    iconClasses: ['fas', 'address-book']
};
export const cardBasedLoginsIdCard: IconSpecification = {
    iconClasses: ['fas', 'id-card']
};
export const externalLoginsSigninAlt: IconSpecification = {
    iconClasses: ['fas', 'sign-in-alt']
};
export const externalApiLink: IconSpecification = {
    iconClasses: ['fas', 'external-link-alt']
};
export const flushCache: IconSpecification = {
    iconClasses: ['fas', 'broom']
};
export const emailTemplatesEnvelopeOpen: IconSpecification = {
    iconClasses: ['fas', 'envelope-open']
};
export const makeTableCollapseCaretRight: IconSpecification = {
    iconClasses: ['fas', 'caret-right']
};
export const makeTableCollapseCaretDown: IconSpecification = {
    iconClasses: ['fas', 'caret-down']
};
export const djBars: IconSpecification = {
    iconClasses: ['fas', 'bars']
};
export const dashboardPlus: IconSpecification = {
    iconClasses: ['fas', 'plus'],
    additionalClasses: ['white', 'font-16'],
    backgroundClasses: ['green']
};

export const dashboardSave: IconSpecification = {
    iconClasses: ['fas', 'save']
};
export const textWidget: IconSpecification = {
    iconClasses: ['fas', 'font'],
    additionalClasses: ['widget-add-modal__block__widget__icon-block__icon']
};
export const chartWidget: IconSpecification = {
    iconClasses: ['fas', 'chart-line'],
    additionalClasses: ['widget-add-modal__block__widget__icon-block__icon']
};
export const gaugeWidget: IconSpecification = {
    iconClasses: ['fas', 'tachometer-alt'],
    additionalClasses: ['widget-add-modal__block__widget__icon-block__icon']
};
export const iframWidget: IconSpecification = {
    iconClasses: ['fas', 'table'],
    additionalClasses: ['widget-add-modal__block__widget__icon-block__icon']
};
export const tableWidget: IconSpecification = {
    iconClasses: ['fas', 'table'],
    additionalClasses: ['widget-add-modal__block__widget__icon-block__icon']
};

export const quickSearchDashboardList: IconSpecification = {
    iconClasses: ['fas', 'tachometer-alt'],
};
export const inventoryprepfixed: IconSpecification = {
    iconClasses: ['fas', 'file'],
};
export const inventoryprepvariable: IconSpecification = {
    iconClasses: ['fas', 'flask'],
};
export const inventorylogupdate: IconSpecification = {
    iconClasses: ['far', 'save'],
};
export const inventorylogprint: IconSpecification = {
    iconClasses: ['fas', 'print'],
    additionalClasses: ['white']
};
export const orderManagementLinkMember: IconSpecification = {
    iconClasses: ['fas', 'credit-card']
};
export const conciergeBell: IconSpecification = {
    iconClasses: ['fas', 'concierge-bell']
};
export const refundPaymentBan: IconSpecification = {
    iconClasses: ['fas', 'ban'],
};
export const backgroundJobs: IconSpecification = {
    iconClasses: ['fas', 'tasks']
};

export const trainingModeOn: IconSpecification = {
    iconClasses: ['fas', 'toggle-on']
};
export const trainingModeOff: IconSpecification = {
    iconClasses: ['fas', 'toggle-off']
};
export const trainingApproveThumbsUp: IconSpecification = {
    iconClasses: ['far', 'thumbs-up']
};
export const tag: IconSpecification = {
    iconClasses: ["fas", "tag"],
};
export const tagSm: IconSpecification = {
    iconClasses: ["fas", "tag"],
    additionalClasses: ["font-11"]
};
export const unlinkedScreensDesktop: IconSpecification = {
    iconClasses: ['fas', 'desktop']
};
export const menuSetupOff: IconSpecification = { // duplicate as trainingModeOff
    iconClasses: ['fas', 'toggle-off']
};
export const inactiveButtonsDiceOne: IconSpecification = {
    iconClasses: ['fas', 'dice-one']
};
export const designModeOn: IconSpecification = {
    iconClasses: ['fas', 'toggle-on']
};
export const designModeOff: IconSpecification = {
    iconClasses: ['fas', 'toggle-off']
};
export const eventbars: IconSpecification = {
    iconClasses: ['fas', 'bars'],
};
export const eventToggleOn: IconSpecification = {
    iconClasses: ['fas', 'toggle-on'],
    additionalClasses: ['theme-primary font-20']
};
export const eventToggleOff: IconSpecification = {
    iconClasses: ['fas', 'toggle-off'],
    additionalClasses: ['font-20']
};
export const eventTasksCheckDouble: IconSpecification = {
    iconClasses: ['fas', 'check-double']
};
export const eventOrderEntryTerminalWarning: IconSpecification = {
    iconClasses: ['fas', 'exclamation-triangle'],
};
export const sortasc: IconSpecification = {
    iconClasses: ['fas', 'sort-up']
};
export const sortdesc: IconSpecification = {
    iconClasses: ['fas', 'sort-down']
};
export const receiptDesignerLock: IconSpecification = {
    iconClasses: ['fas', 'lock'],
};
export const receiptDesignerBan: IconSpecification = {
    iconClasses: ['fas', 'ban'],
};
export const receiptDesignerCheckCircle: IconSpecification = {
    iconClasses: ['far', 'check-circle']
};
export const receiptDesignerPlusSquare: IconSpecification = {
    iconClasses: ['far', 'plus-square']
};
export const receiptDesignerArrowCircleRight: IconSpecification = {
    iconClasses: ['fas', 'share']
};
export const receiptDesignerArrowCircleLeft: IconSpecification = {
    iconClasses: ['fas', 'reply']
};
export const receiptDesignerTimesCircle: IconSpecification = {
    iconClasses: ['far', 'times-circle']
};
export const reportBan: IconSpecification = {
    iconClasses: ['fas', 'ban']
};
export const runReport: IconSpecification = {
    iconClasses: ['fas', 'file-alt']
};
export const eventActionPlus: IconSpecification = {
    iconClasses: ['fas', 'plus']
};
export const screenSetupPlus: IconSpecification = {
    iconClasses: ['fas', 'plus']
};
export const menuSetupSearch: IconSpecification = {
    iconClasses: ['fas', 'search']
};
export const dayparts: IconSpecification = {
    iconClasses: ['fas', 'clock']
};
export const orderSeatPlus: IconSpecification = {
    iconClasses: ['fas', 'plus']
};
export const stackCircleGreenLight: IconSpecification = {
    iconClasses: ['fas', 'circle'],
    additionalClasses: ['fa-stack-2x', 'green-light']
};
export const stackTv: IconSpecification = {
    iconClasses: ['fas', 'tv'],
    additionalClasses: ['fa-stack-1x', 'fa-inverse', 'pt-pix-1']
};
export const stackHeartRate: IconSpecification = {
    iconClasses: ['fas', 'heart-rate'],
    additionalClasses: ['fa-stack-1x', 'order-status-heart-rate']
};
export const makeTableNotified: IconSpecification = {
    iconClasses: ['fad', 'badge-check']
};
export const screenBackupPlus: IconSpecification = {
    iconClasses: ['fas', 'plus'],
    additionalClasses: ['add-color']
};
export const ingredientImport: IconSpecification = {
    iconClasses: ['fas', 'upload']
};
export const plusWhite: IconSpecification = {
    iconClasses: ['fas', 'plus'],
    additionalClasses: ['white'],
    backgroundClasses: ['bg-add']
};
export const editWhite: IconSpecification = {
    iconClasses: ['fas', 'pencil-alt'],
    additionalClasses: ['white', 'font-16']
};

export const tickOne: IconSpecification = {
    iconClasses: ['fas', 'check'],
    additionalClasses: ['fa-stack-1x', 'ml-4', 'green']
};

export const tickTwo: IconSpecification = {
    iconClasses: ['fas', 'check'],
    additionalClasses: ['fa-stack-1x', 'ml-n2', 'green']
};

export const tickInverse: IconSpecification = {
    iconClasses: ['fas', 'check'],
    additionalClasses: ['fa-stack-1x', 'ml-n2', 'fa-inverse', 'green']
};

export const deleteWhite: IconSpecification = {
    iconClasses: ['fas', 'trash-alt'],
    additionalClasses: ['white', 'font-16'],
    backgroundClasses: ['delete-color']
};
export const squareSolid: IconSpecification = {
    iconClasses: ['fas', 'square'],
    additionalClasses: ['fa-stack-2x']
};

export const checkCircleTask: IconSpecification = {
    iconClasses: ['far', 'check-circle'],
    additionalClasses: ['font-20', 'pointer']
};

export const expandArrows: IconSpecification = {
    iconClasses: ['fas', 'expand-arrows']
};

export const checkCircleSimple: IconSpecification = {
    iconClasses: ['far', 'check-circle']
};

export const taskHelp: IconSpecification = {
    iconClasses: ['far', 'question-circle']
};
export const taskSkip: IconSpecification = {
    iconClasses: ['fas', 'ban']
};
export const taskCollapseCaretDown: IconSpecification = {
    iconClasses: ['fas', 'caret-down']
};
export const taskCollapseCaretRight: IconSpecification = {
    iconClasses: ['fas', 'caret-right']
};
export const taskTodo: IconSpecification = {
    iconClasses: ['far', 'circle'],
    additionalClasses: ['font-20', 'pointer']
};
export const squareSolidCog: IconSpecification = {
    iconClasses: ['fas', 'square'],
    additionalClasses: ['fa-stack-2x', 'font-25', 'edit-color', 'mt-n1']
};
export const printWhite: IconSpecification = {
    iconClasses: ['fas', 'print'],
    additionalClasses: ['white'],
    backgroundClasses: ['bg-print']
};
export const inventoryProductIngredientStore: IconSpecification = {
    iconClasses: ['fas', 'store']
};
export const editWhiteWithText: IconSpecification = {
    iconClasses: ['fas', 'pencil-alt'],
    additionalClasses: ['white'],
    backgroundClasses: ['bg-edit']
};
export const barsWhite: IconSpecification = {
    iconClasses: ['fas', 'bars'],
    additionalClasses: ['font-16', 'white d-flex align-items-center justify-content-center'],
    backgroundClasses: ['burger-color']
};
export const cogWhite: IconSpecification = {
    iconClasses: ['fas', 'cog'],
    additionalClasses: ['white', 'font-15', 'd-flex align-items-center justify-content-center'],
    backgroundClasses: ['grey']
};
export const functionButtonFilter: IconSpecification = {
    iconClasses: ['fas', 'filter'],
};
export const squareSolidPaginationLightBlue: IconSpecification = {
    iconClasses: ['fas', 'square'],
    additionalClasses: ['fa-stack-2x', 'p-pagination-light-blue']
};
export const filterToggleOn: IconSpecification = {
    iconClasses: ['fas', 'toggle-on'],
    additionalClasses: ['green', 'font-25']
};
export const filterToggleOff: IconSpecification = {
    iconClasses: ['fas', 'toggle-off'],
    additionalClasses: ['theme-separator', 'font-25']
};

export const orderInvoiceBars: IconSpecification = {
    iconClasses: ['fas', 'bars']
};
export const nutitionFactPrint: IconSpecification = {
    iconClasses: ['far', 'file-powerpoint']
};
export const sms: IconSpecification = {
    iconClasses: ['fas', 'sms']
};
export const buttonBehaviorPlus: IconSpecification = {
    iconClasses: ['fas', 'plus']
};
export const databaseWhite: IconSpecification = {
    iconClasses: ['fas', 'database'],
    additionalClasses: ['white', 'font-16'],
    backgroundClasses: ['orange-red']
};
export const listWhite: IconSpecification = {
    iconClasses: ['fas', 'list'],
    additionalClasses: ['white', 'font-16'],
    backgroundClasses: ['green']
};
export const exclamationCircle: IconSpecification = {
    iconClasses: ['fas', 'exclamation-circle']
};
export const fileChartLine: IconSpecification = {
    iconClasses: ['fas', 'file-chart-line']
};
export const queryValuesDatabase: IconSpecification = {
    iconClasses: ['fas', 'database'],
    additionalClasses: ['white', 'font-16'],
    backgroundClasses: ['sky-blue']
};
export const clipboardList: IconSpecification = {
    iconClasses: ['fas', 'clipboard-list']
};

export const exchangeAlt: IconSpecification = {
    iconClasses: ['fas', 'exchange-alt']
};

export const exchangeAltAction: IconSpecification = {
    iconClasses: ['fas', 'exchange-alt'],
    additionalClasses: ['font-17', 'vertical-center-align'],
    backgroundClasses: ['green']
};

export const sortNumericUp: IconSpecification = {
    iconClasses: ['fas', 'sort-numeric-up']
};

export const clipboardCheck: IconSpecification = {
    iconClasses: ['fas', 'clipboard-check'],
    additionalClasses: ['white', 'font-16'],
    backgroundClasses: ['green']
};
export const searchPlus: IconSpecification = {
    iconClasses: ['fas', 'search-plus']
};

export const mousePointer: IconSpecification = {
    iconClasses: ['fas', 'mouse-pointer']
};

export const searchMinus: IconSpecification = {
    iconClasses: ['fas', 'search-minus']
};

export const handRock: IconSpecification = {
    iconClasses: ['fas', 'hand-rock']
};

export const fileExportWhite: IconSpecification = {
    iconClasses: ['fas', 'file-export'],
    additionalClasses: ['white', 'font-15'],
    backgroundClasses: ['green']
};


export const calenderEdit: IconSpecification = {
    iconClasses: ['fas', 'fa-calender-edit']
};

export const history: IconSpecification = {
    iconClasses: ['fas', 'history']
};
export const dashboardClone: IconSpecification = {
    iconClasses: ['far', 'clone'],
    additionalClasses: ['font-17', 'white'],
    backgroundClasses: ['dashboard-color']
};

export const squareGreenStack: IconSpecification = {
    iconClasses: ['fas', 'square'],
    additionalClasses: ['fa-stack-2x', 'green']
};

export const forwardStack: IconSpecification = {
    iconClasses: ['fas', 'forward'],
    additionalClasses: ['fa-stack-1x', 'white', 'align-vertical-center']
};
export const arrowRightStack: IconSpecification = {
    iconClasses: ['fas', 'arrow-alt-right'],
    additionalClasses: ['fa-stack-1x', 'white', 'font-15', 'align-vertical-center']
};

export const fileSearch: IconSpecification = {
    iconClasses: ['far', 'file-search'],
    additionalClasses: ['fa-stack-1x', 'white', 'align-vertical-center']
};

export const fileSearchPreview: IconSpecification = {
    iconClasses: ['fas', 'file-search'],
    additionalClasses: ['white']
};

export const squareLightOrangeStack: IconSpecification = {
    iconClasses: ['fas', 'square'],
    additionalClasses: ['fa-stack-2x', 'warning-light-orange']
};

export const truckLoading: IconSpecification = {
    iconClasses: ['fal', 'truck-loading'],
};

export const shippingFast: IconSpecification = {
    iconClasses: ['fas', 'shipping-fast'],
};

export const chevronCircleRight: IconSpecification = {
    iconClasses: ['fas', 'chevron-circle-right'],
};

export const checkCirclePlain: IconSpecification = {
    iconClasses: ['fas', 'check-circle'],
};

export const circlePlain: IconSpecification = {
    iconClasses: ['fas', 'circle'],
};

export const fadeCircle: IconSpecification = {
    iconClasses: ['fad', 'circle'],
};

export const historyStack: IconSpecification = {
    iconClasses: ['fas', 'history'],
    additionalClasses: ['fa-stack-1x', 'white', 'align-vertical-center']
};
export const filterSolid: IconSpecification = {
    iconClasses: ['fas', 'filter']
};
export const displayMenu: IconSpecification = {
    iconClasses: ['fas', 'tv-alt']
};

export const inbox: IconSpecification = {
    iconClasses: ['fas', 'inbox']
};

export const paperPlane: IconSpecification = {
    iconClasses: ['fas', 'paper-plane']
};

export const undoStack: IconSpecification = {
    iconClasses: ['fas', 'undo'],
    additionalClasses: ['fa-stack-1x', 'white', 'align-vertical-center']
};

export const inboxIn: IconSpecification = {
    iconClasses: ['fas', 'inbox-in']
};

export const inboxOut: IconSpecification = {
    iconClasses: ['fas', 'inbox-out']
};

export const squareOrangeRedStack: IconSpecification = {
    iconClasses: ['fas', 'square'],
    additionalClasses: ['fa-stack-2x', 'dashboard-color']
};

export const squareLightYellowOrangeStack: IconSpecification = {
    iconClasses: ['fas', 'square'],
    additionalClasses: ['fa-stack-2x', 'light-orange-yellow']
};

export const squareWhiteGreyStack: IconSpecification = {
    iconClasses: ['fas', 'square'],
    additionalClasses: ['fa-stack-2x', 'white-grey']
};

export const envelopeStack: IconSpecification = {
    iconClasses: ['fas', 'envelope'],
    additionalClasses: ['fa-stack-1x', 'white', 'align-vertical-center', 'pt-pix-1']
};

export const envelopeOpenStack: IconSpecification = {
    iconClasses: ['fas', 'envelope-open'],
    additionalClasses: ['fa-stack-1x', 'white', 'align-vertical-center']
};


export const composeEnvelopeStack: IconSpecification = {
    iconClasses: ['fas', 'envelope'],
    additionalClasses: ['fa-stack-2x', 'white', 'font-17']
};

export const composeEnvelopeOpenStack: IconSpecification = {
    iconClasses: ['fas', 'envelope-open'],
    additionalClasses: ['fa-stack-2x', 'white', 'font-17']
};

export const checkCircleStack: IconSpecification = {
    iconClasses: ['fas', 'check-circle'],
    additionalClasses: ['fa-stack-1x', 'theme-separator', 'font-05', 'theme-stroke']
};

export const composeCircleStack: IconSpecification = {
    iconClasses: ['fas', 'circle'],
    additionalClasses: ['fa-stack-1x', 'theme-separator', 'font-05', 'compose-circle']
};

export const markAllCircleStack: IconSpecification = {
    iconClasses: ['fas', 'circle'],
    additionalClasses: ['fa-stack-1x', 'mark-all-circle']
};

export const composePlusStack: IconSpecification = {
    iconClasses: ['fas', 'plus'],
    additionalClasses: ['fa-stack-3x', 'compose-plus']
};

export const markAllCheckStack: IconSpecification = {
    iconClasses: ['fas', 'check'],
    additionalClasses: ['fa-stack-3x', 'mark-all-check']
};
export const bell: IconSpecification = {
    iconClasses: ['fas', 'bell']
};
export const bellOn: IconSpecification = {
    iconClasses: ['fas', 'bell-on']
}
export const exclamationTriangleRed: IconSpecification = {
    iconClasses: ['fas', 'exclamation-triangle'],
    additionalClasses: ['red']
};
export const windowCancel: IconSpecification = {
    iconClasses: ['fas', 'window-close']
};
export const stackFasBurgerSoda: IconSpecification = {
    iconClasses: ['fas', 'burger-soda'],
    additionalClasses: ['white', 'fa-stack-1x']
};
export const stackCircleDarkPurple: IconSpecification = {
    iconClasses: ['fas', 'circle'],
    additionalClasses: ['fa-stack-2x', 'dark-purple']
};
export const nutritionFacts: IconSpecification = {
    iconClasses: ['fab', 'nutritionix']
};
export const expand: IconSpecification = {
    iconClasses: ['fas', 'expand']
};
export const orderProductCheckMark: IconSpecification = {
    iconClasses: ['fas', 'check-circle'],
    additionalClasses: ['fa-stack-1x']
};
export const orderProductCircle: IconSpecification = {
    iconClasses: ['fas', 'circle'],
    additionalClasses: ['fa-stack-2x', 'white', 'font-85', 'width-inherit', 'align-vertical-center']
};
export const undoWhite: IconSpecification = {
    iconClasses: ['fas', 'undo'],
    additionalClasses: ['fa-stack-1x', 'white', 'font-15'],
    backgroundClasses: ['dashboard-color']
};
export const orderBuilding: IconSpecification = {
    iconClasses: ['fas', 'shopping-bag'],
    additionalClasses: ['font-16']
};
export const makeTable: IconSpecification = {
    iconClasses: ['fas', 'utensils'],
    additionalClasses: ['font-15']
};
export const pickUpScreen: IconSpecification = {
    iconClasses: ['fas', 'inventory'],
    additionalClasses: ['font-15']
};
export const confirmationScreenSolid: IconSpecification = {
    iconClasses: ['fas', 'box-check'],
    additionalClasses: ['font-16']
};
export const camera: IconSpecification = {
    iconClasses: ['fas', 'camera']
}

export const stackCircleNotification: IconSpecification = {
    iconClasses: ['fas', 'circle'],
    additionalClasses: ['fa-stack-2x', 'notification-color']
};


export const notificationStack: IconSpecification = {
    iconClasses: ['fas', 'bell'],
    additionalClasses: ['white', 'fa-stack-1x', 'align-vertical-center']
};
export const piggyBank: IconSpecification = {
    iconClasses: ['far', 'piggy-bank']
};
export const piggyBankLight: IconSpecification = {
    iconClasses: ['fal', 'piggy-bank']
};
export const promotionGiftCard: IconSpecification = {
    iconClasses: ['fas', 'gift-card']
};
export const puzzlePiece: IconSpecification = {
    iconClasses: ['fas', 'puzzle-piece']
};
export const coins: IconSpecification = {
    iconClasses: ['fas', 'coins']
};

export const manualSubscriptionReject: IconSpecification = {
    iconClasses: ['fas', 'vote-nay']
};


export const scroll: IconSpecification = {
    iconClasses: ['fas', 'scroll']
}
export const trashAltWhite: IconSpecification = {
    iconClasses: ['fas', 'trash-alt'],
    additionalClasses: ['delete-color']
};

export const embedScreen: IconSpecification = {
    iconClasses: ['fas', 'link']
}

export const unlinkEmbeddedScreen: IconSpecification = {
    iconClasses: ['fas', 'unlink']
}

export const ellipsisV: IconSpecification = {
    iconClasses: ['fal', 'ellipsis-v'],
    additionalClasses: ['black', 'font-15'],
}

export const browser: IconSpecification = {
    iconClasses: ['fad', 'browser']
}

export const text: IconSpecification = {
    iconClasses: ['fad', 'text']
}

export const listUl: IconSpecification = {
    iconClasses: ['fad', 'list-ul']
}

export const images: IconSpecification = {
    iconClasses: ['fad', 'images']
}

export const imageLight: IconSpecification = {
    iconClasses: ['fad', 'image']
}

export const boxesLight: IconSpecification = {
    iconClasses: ['fad', 'boxes']
}

export const cloneLight: IconSpecification = {
    iconClasses: ['fad', 'clone']
}

export const burgerSoda: IconSpecification = {
    iconClasses: ['fad', 'burger-soda']
}

export const checkCircleGrey: IconSpecification = {
    iconClasses: ['fas', 'check-circle'],
    additionalClasses: ['grey']
};
export const externalLink: IconSpecification = {
    iconClasses: ['far', 'external-link'],
    additionalClasses: ['white'],
    backgroundClasses: ['green']
};
export const objectGroup: IconSpecification = {
    iconClasses: ['fas', 'object-group']
};

export const heading: IconSpecification = {
    iconClasses: ['fas', 'heading']
}

export const sortAlphaDown: IconSpecification = {
    iconClasses: ['fas', 'sort-alpha-down']
}

export const fileAudio: IconSpecification = {
    iconClasses: ['fas', 'file-audio']
};

export const downloadWhite: IconSpecification = {
    iconClasses: ['fas', 'download'],
    additionalClasses: ['white', 'font-16']
};

export const folderMagnifyingGlass: IconSpecification = {
    iconClasses: ['far', 'file-search'],
    additionalClasses: ['fa-stack-1x', 'white', 'align-vertical-center']
}

export const stopRed: IconSpecification = {
    iconClasses: ['fas', 'stop'],
    additionalClasses: ['white', 'font-16', 'align-vertical-center', 'pt-pix-1'],
    backgroundClasses: ['delete-color']
};

export const forwardFad: IconSpecification = {
    iconClasses: ['fad', 'forward']
}

export const fileImport: IconSpecification = {
    iconClasses: ['fas', 'file-import']
}

export const playGreen: IconSpecification = {
    iconClasses: ['fas', 'play'],
    additionalClasses: ['white', 'font-16', 'align-vertical-center', 'pt-pix-1'],
    backgroundClasses: ['green']
};

export const playGreenNormalFont: IconSpecification = {
    iconClasses: ['fas', 'play'],
    additionalClasses: ['white', 'align-vertical-center', 'ps-pix-1', 'pt-pix-1'],
    backgroundClasses: ['green']
};

export const pauseGreen: IconSpecification = {
    iconClasses: ['fas', 'pause'],
    additionalClasses: ['white', 'align-vertical-center', 'pt-pix-1'],
    backgroundClasses: ['green']
};

export const warehouseAlt: IconSpecification = {
    iconClasses: ['fas', 'warehouse-alt']
};

export const dollyFlatbedAlt: IconSpecification = {
    iconClasses: ['fas', 'dolly-flatbed-alt']
};

export const flashlight: IconSpecification = {
    iconClasses: ['fas', 'flashlight'],
    rotate: 270,
}

export const globe: IconSpecification = {
    iconClasses: ['fas', 'globe']
}

export const bagsShopping: IconSpecification = {
    iconClasses: ['fas', 'bags-shopping']
}

export const help: IconSpecification = {
    iconClasses: ['fas', 'question-square'],
    additionalClasses: ['font-16'],
}

export const hourglassTask: IconSpecification = {
    iconClasses: ['far', 'hourglass-half'],
    additionalClasses: ['font-20', 'pointer']
};

export const circleGreen: IconSpecification = {
    iconClasses: ['fas', 'circle'],
    additionalClasses: ['green']
};
export const circleRed: IconSpecification = {
    iconClasses: ['fas', 'circle'],
    additionalClasses: ['red']
};

export const fileSearchWhite: IconSpecification = {
    iconClasses: ['far', 'file-search'],
    additionalClasses: ['white'],
    backgroundClasses: ['bg-warning-light-orange']
};

export const contactSupport: IconSpecification = {
    iconClasses: ['fas', 'user-headset'],
};

export const applePay: IconSpecification = {
    iconClasses: ['fab', 'apple-pay']
}

export const googlePay: IconSpecification = {
    iconClasses: ['fab', 'google-pay']
}

export const mapMarkerAlt: IconSpecification = {
    iconClasses: ['fas', 'map-marker-alt']
};

export const tabletAlt: IconSpecification = {
    iconClasses: ['fas', 'tablet-alt']
};

export const chevronRight: IconSpecification = {
    iconClasses: ['fas', 'chevron-right']
}

export const chevronDown: IconSpecification = {
    iconClasses: ['fas', 'chevron-down']
}

export const plusCircleLight: IconSpecification = {
    iconClasses: ['fal', 'plus-circle'],
    additionalClasses: ['font-22 green']
}

export const minusCircleLight: IconSpecification = {
    iconClasses: ['fal', 'minus-circle'],
    additionalClasses: ['font-22 green']
}

export const trashAlt: IconSpecification = {
    iconClasses: ['far', 'trash-alt']
};

export const stackCircleOrderKiosk: IconSpecification = {
    iconClasses: ['fas', 'circle'],
    additionalClasses: ['fa-stack-2x', 'order-kiosk-color']
};

export const orderKioskStack: IconSpecification = {
    iconClasses: ['fas', 'tablet-alt'],
    additionalClasses: ['white', 'fa-stack-1x', 'align-vertical-center']
};

export const searchWhite: IconSpecification = {
    iconClasses: ['fas', 'search'],
    additionalClasses: ['white'],
};

export const allergiesLight: IconSpecification = {
    iconClasses: ['fal', 'allergies']
}
export const userRobot: IconSpecification = {
    iconClasses: ['fas', 'user-robot'],
};

export const tasks: IconSpecification = {
    iconClasses: ['fas', 'tasks']
}

export const stackCircleWaitTimeTerminal: IconSpecification = {
    iconClasses: ['fas', 'circle'],
    additionalClasses: ['fa-stack-2x', 'wait-list-terminal-color']
}

export const waitTimeTerminalStack: IconSpecification = {
    iconClasses: ['fas', 'watch'],
    additionalClasses: ['white', 'fa-stack-1x', 'align-vertical-center']
};

export const watch: IconSpecification = {
    iconClasses: ['fas', 'watch'],
}

export const stickyNoteRegular: IconSpecification = {
    iconClasses: ['far', 'sticky-note']
}

export const squareLight: IconSpecification = {
    iconClasses: ['fal', 'square']
};

export const fileInvoice: IconSpecification = {
    iconClasses: ['fas', 'file-invoice']
};

export const fileInvoiceDollar: IconSpecification = {
    iconClasses: ['fas', 'file-invoice-dollar']
};

export const fileArchive: IconSpecification = {
    iconClasses: ['fas', 'file-archive']
};

export const banSolid: IconSpecification = {
    iconClasses: ['fas', 'ban']
};

export const addKioskButton: IconSpecification = {
    iconClasses: ['fas', 'plus'],
    additionalClasses: ['font-15'],
    backgroundClasses: ['bg-print']
};
