import { newProduct, check, infoManageCheckSquare, times } from './../../../../../shared/components/icon/icons';
import { Messages } from 'src/app/shared/constants/ui-messages';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { AlertsService, FormUtilityService } from 'src/app/shared/services';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { bookmark, layerGroup, boxes } from '../../../../../shared/components/icon';
import { EditColumn, DeleteColumn, TemplateColumn, GridColumn, TextAlign, LinkColumn } from '@tarktech/tark-ng-utils';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { OnlineOrderingCategoriesService } from '../../../service/online-ordering-categories.service';
import { OnlineOrderingCategoryProductService } from '../../../service/online-ordering-category-product.service';
import * as _ from 'lodash';
import { BaseFormComponent, ConfirmDeleteComponent } from 'src/app/shared';
import { OnlineOrderingCategoryProductComponent } from '../../online-ordering-categories';
import { ActivatedRoute, Router } from '@angular/router';
import { StringUtils } from 'src/app/shared/string-utils/string-utils';
import { ExtensionValidationService } from 'src/app/shared/services/extension-validation.service';
import { NgForm } from '@angular/forms';
import { OnlineOrderingOptionProductConfigurationComponent } from '../online-ordering-option-product-configuration';
import { Observable } from 'rxjs';
import { OnlineOrderingChildOptionService } from '../../../service/online-ordering-child-option.service';
import { ButtonOptionGraph } from '../../../service/button-option-graph';
declare let $: any;
@Component({
  selector: 'pos-online-ordering-option-edit',
  templateUrl: './online-ordering-option-edit.component.html',
  styleUrls: ['./online-ordering-option-edit.component.scss'],
})
export class OnlineOrderingOptionEditComponent extends BaseFormComponent implements OnInit {
  get getForm(): NgForm {
    return this.form
  }
  optionsTransParam: any;
  @ViewChild('confirmDeleteOptionButton') private confirmDeleteOptionButton: ConfirmDeleteComponent;
  @ViewChild('optionImage') private optionImage: ElementRef;
  optionId: number;
  selectedProduct: any;
  showCategoryIcon = false;
  buttonId: number;
  categoryIconName = '';
  optionName = '';
  image: File;
  public icons = {
    bookmark,
    layerGroup,
    boxes,
    newProduct,
    check,
    infoManageCheckSquare,
    times
  };
  tabList = {
    OptionSettings: 'OptionSettings',
    ProductSetting: 'ProductSetting'
  };
  selectedTab = this.tabList.OptionSettings;
  uploadedImages: any = [];
  @ViewChild('productIconTemplate', { static: true }) private productIconTemplate: any;
  @ViewChild('productActiveTemplate', { static: true }) private productActiveTemplate: any;
  @ViewChild('orderingOption', { static: true }) form: NgForm;
  resolutionWarningMessage = '';
  orderingOptionProductColumns: Array<GridColumn> = [];
  optionProducts: Array<any> = [];
  salesProducts: Array<any> = [];
  optionDetails: any = { Id: 0 };

  private buttonOptionGraph: ButtonOptionGraph = new ButtonOptionGraph();

  constructor(private modalService: ModalService,
    private router: Router,
    private route: ActivatedRoute,
    private onlineOrderingCategoriesService: OnlineOrderingCategoriesService,
    private alertService: AlertsService,
    private spinnerService: SpinnerService,
    private onlineOrderingCategoryProductService: OnlineOrderingCategoryProductService,
    private extensionValidation: ExtensionValidationService,
    private onlineOrderingChildOptionService: OnlineOrderingChildOptionService,
    formUtilityService: FormUtilityService) {
    super(formUtilityService);
    this.optionId = route.snapshot.params.id ? parseInt(route.snapshot.params.id, 10) : 0;
  }

  ngOnInit() {
    this.columnConfiguration();
    this.getSalesProducts();
    this.getOptionDetails();
    this.setInitialValue();
    this.buildOptionGraph();
  }

  getSalesProducts() {
    this.onlineOrderingCategoryProductService.getOnlineOrderingCategoryProducts()
      .subscribe({
        next: (res) => {
          this.salesProducts = res;
        }, error: this.alertService.showApiError
      });
  }
  getOptionDetails() {
    if (this.optionId) {
      this.spinnerService.show();
      this.onlineOrderingCategoriesService.getOnlineOrderingOption(this.optionId)
        .subscribe({
          next: (res) => {
            this.optionDetails = res.OnlineOrderingCategory;
            this.optionName = this.optionDetails.Name;
            this.optionProducts = _.orderBy(res.OnlineOrderingProducts, 'Ordinal');
            this.removeTimeStampFromIcon();
          }, error: this.alertService.showApiError,
          complete: () => {
            this.spinnerService.hide();
          }
        });
    }
  }

  buildOptionGraph() {
    this.spinnerService.show();
    this.onlineOrderingCategoryProductService.getOnlineOrderOptionDataForGraph()
    .subscribe({
      next: (res) => {
        this.buttonOptionGraph = new ButtonOptionGraph();
        this.buttonOptionGraph.buildGraph(res.MenuButtonOptions, res.MenuButtonChildOptions);
      },
      error: this.alertService.showApiError,
      complete: () => this.spinnerService.hide(),
    });
  }

  removeTimeStamp(icon: string): string {
    if (icon) {
      const index = icon.lastIndexOf('.');
      const suffix = icon.substr(index);
      const timeIndex = icon.lastIndexOf('-');
      let iconName = icon.substr(0, index);
      if (icon.substr(timeIndex).length >= (suffix.length + 19)) {
        iconName = icon.substr(0, timeIndex);
      }
      return iconName + suffix;
    }
  }

  removeTimeStampFromIcon() {
    this.categoryIconName = this.removeTimeStamp(this.optionDetails.Icon);
  }

  handleFileInput(files: FileList) {
    this.image = files.item(0);
    const fileToUpload = this.image ? this.image.name : '';
    if (this.extensionValidation.isInvalidExtension(fileToUpload)) {
      if (fileToUpload) {
        this.alertService.renderErrorMessage(Messages.InvalidReceiptLogoFileType);
      }
      this.removeIcon();
      return;
    }
    if (files && files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        $('#optionImage').attr('src', reader.result);
        setTimeout(() => { this.checkOptionImageResolution(); });
      };
      reader.readAsDataURL(files[0]);
      this.showCategoryIcon = true;
      this.optionDetails.Icon = this.image ? this.image.name : '';
    }
  }

  checkOptionImageResolution() {
    if (this.optionImage && (this.optionImage.nativeElement.naturalWidth < 229 || this.optionImage.nativeElement.naturalHeight < 139)) {
      this.resolutionWarningMessage = StringUtils.format(Messages.OnlineOrderingImageResolutionWarning, { 'resolution': '229X139' });
      this.optionDetails.hasImageResolutionError = true;
    } else {
      this.resolutionWarningMessage = '';
      this.optionDetails.hasImageResolutionError = false;
    }
  }

  getHeight = function () {
    return $(window).height() - 310 + 'px';
  };

  removeIcon() {
    this.optionDetails.Icon = '';
    $('#optionIcon').val('');
    this.showCategoryIcon = false;
    this.categoryIconName = '';
  }

  private columnConfiguration() {

    const productIconColumn = new TemplateColumn({
      HeaderText: 'Has Icon',
      itemTemplate: this.productIconTemplate,
      TextAlign: TextAlign.Center,
      Width: '100px'
    });

    const productVisibleColumn = new TemplateColumn({
      HeaderText: 'Visible',
      itemTemplate: this.productActiveTemplate,
      TextAlign: TextAlign.Center,
      Width: '100px'
    });

    const deleteColumn = new DeleteColumn({
      Clicked: (data) => {
        this.deleteButton(data);
      },
      Width: '35px'
    });

    const editColumn = new EditColumn({
      Clicked: (data) => {
        this.editProduct(data.Id);
      },
      Width: '35px'
    });

    const optionConfigureColumn = new LinkColumn({
      Clicked: (data) => {
        this.showProductConfiguration(data);
      },
      Width: '35px',
      Icon: 'far fa-cog',
      ToolTip: 'Configurations',
      BackgroundColor: 'grey'
    });

    this.orderingOptionProductColumns = [
      new GridColumn({ HeaderText: 'Button Text', Field: 'ButtonText', IsSortable: true, Width: '20%' }),
      new GridColumn({ HeaderText: 'Sales Product', Field: 'SalesProductName', IsSortable: true, Width: '20%' }),
      new GridColumn({ HeaderText: 'Background Color', Field: 'BackgroundColor', IsSortable: true, Width: '20%' }),
      new GridColumn({ HeaderText: 'Text Color', Field: 'TextColor', IsSortable: true, Width: '20%' }),
    ] as Array<GridColumn>;
    this.orderingOptionProductColumns.push(productIconColumn);
    this.orderingOptionProductColumns.push(productVisibleColumn);

    this.orderingOptionProductColumns.push(editColumn);
    this.orderingOptionProductColumns.push(deleteColumn);
    this.orderingOptionProductColumns.push(optionConfigureColumn);
  }

  gridRowReorder(event) {
    this.onlineOrderingCategoryProductService.updateOnlineOrderingProductOrdinal(this.optionProducts).subscribe(
      (res) => { this.optionProducts = res ? res : this.optionProducts; },
      this.alertService.showApiError,
      () => { }
    );
  }

  deleteButton(data) {
    this.buttonId = data.OptionButtonId;
    const message = StringUtils.format(Messages.ConfirmDeleteOptionButton, { 'buttonName': data.ButtonText });
    this.confirmDeleteOptionButton.Show(message);

  }

  onConfirmAcknowledge() {
    this.spinnerService.show();
    this.onlineOrderingCategoriesService.deleteButtonToOption(this.buttonId)
      .subscribe({
        next: (res) => {
          this.alertService.renderSuccessMessage(Messages.DeleteOptionButton);
          this.buttonOptionGraph.removeEdgeOptionToButton(this.optionId, this.buttonId);
          this.getOptionDetails();
        },
        error: this.alertService.showApiError,
        complete: () => {
          this.spinnerService.hide();
        }
      });
  }

  showProductConfiguration(data: any) {
    this.modalService.show(OnlineOrderingOptionProductConfigurationComponent, {
      animated: false,
      class: 'vertical-center',
      initialState: {
        productName: data.ButtonText,
        productId: data.Id,
        getOnlineOrderingProductConfiguration: this.getOnlineOrderingChildOptions.bind(this),
        deleteMenuButtonConfiguration: this.deleteOnlineOrderingChildOption.bind(this),
        addMenuButtonConfiguration: this.addOnlineOrderingChildOption.bind(this),
        updateOnlineOrderingMenuButtonConfigurationOrdinal: this.updateOnlineOrderingChildOptionOrdinal.bind(this),
        buttonOptionGraph: this.buttonOptionGraph,
        optionLabel: 'Child Option',
        optionId: this.optionId
      }
    });
  }

  getOnlineOrderingChildOptions(buttonId: number) : Observable<any>{
    return this.onlineOrderingChildOptionService.getChildOptionsByButtonId(buttonId);
  }

  deleteOnlineOrderingChildOption(childOptionId: number) : Observable<any>{
    return this.onlineOrderingChildOptionService.deleteChildOption(childOptionId);
  }

  addOnlineOrderingChildOption(childOption: any) : Observable<any>{
    return this.onlineOrderingChildOptionService.addChildOption(childOption)
  }

  updateOnlineOrderingChildOptionOrdinal(childOptions: any) : Observable<any>{
    return this.onlineOrderingChildOptionService.updateChildOptionOrdinal(childOptions);
  }

  editProduct(id) {
    const productModalRef = this.modalService.show(OnlineOrderingCategoryProductComponent, {
      animated: false,
      class: 'vertical-center',
      initialState: {
        buttonId: id,
        isToggle: ((this.optionProducts && this.optionProducts.length > 0) ? this.optionProducts[0].IsToggle : null)
      }
    });

    productModalRef.close.subscribe(res => {
      if (res && res.shouldReload) {
        if (res.buttonId) {
          this.selectedProduct = {
            Id: res.buttonId, IsToggle: this.optionProducts.length > 0 ?
              this.optionProducts[0].IsToggle : res.IsToggle
          };
          this.addButtonToOption();
        } else {
          this.getOptionDetails();
        }
      }
    });
  }

  saveOrderingOption(isValid: boolean) {
    const fileToUpload = this.image ? this.image.name : '';
    if (!isValid || (fileToUpload !== '' && this.extensionValidation.isInvalidExtension(fileToUpload))) {
      return;
    }
    this.optionDetails.IsOption = true;
    if (this.optionDetails.MinSelections > this.optionDetails.MaxSelections) {
      this.alertService.renderErrorMessage(Messages.MinSelectionsMustLessThanMaxSelections);
      return;
    }
    this.spinnerService.show();
    this.onlineOrderingCategoriesService.saveOnlineOrderingCategory(this.optionDetails, this.image)
      .subscribe({
        next: (res) => {
          this.onCancel(true);
        },
        error: this.alertService.showApiError,
        complete: () => {
          this.spinnerService.hide();
        }
      });
  }

  onCancel(reload: boolean) {
    this.router.navigate(['online-ordering-options'], { relativeTo: this.route.parent });
  }

  addButtonToOption() {
    if (this.optionProducts && this.optionProducts.length > 0) {
      const index = _.findIndex(this.optionProducts, (optionProduct) => {
        return optionProduct.Id === this.selectedProduct.Id;
      });

      if (index >= 0) {
        this.alertService.renderErrorMessage(Messages.ErrorWhileOnlineOrderingAddingDuplicateButton);
        return;
      }

      if (this.optionProducts[0].IsToggle !== this.selectedProduct.IsToggle) {
        this.alertService.renderErrorMessage(Messages.ErrorWhileOnlineOrderingAddingButton);
        return;
      }


    }
    const button = {
      CategoryId: this.optionId,
      ButtonId: this.selectedProduct.Id
    };

    if (this.buttonOptionGraph.isPathFromButtonToOption(this.selectedProduct.Id, this.optionId)) {
      this.alertService.renderErrorMessage(Messages.AddingButtonToOptionCreatesCycle);
      return;
    }

    this.spinnerService.show();
    this.onlineOrderingCategoriesService.addButtonToOption(button)
      .subscribe({
        next: (res) => {
          this.alertService.renderSuccessMessage(Messages.OnlineOrderingButtonAddedSuccess);
          this.getOptionDetails();
          this.buttonOptionGraph.addButtonOptions([{ ButtonId: this.selectedProduct.Id, OptionId: this.optionId }]);
          this.selectedProduct = null;
        },
        error: this.alertService.showApiError,
        complete: () => { this.spinnerService.hide(); }
      });
  }
}
