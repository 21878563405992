<pos-edit-wrapper heading="{{selectedDeliveryType.description}}" (close)="onClose(false)">
    <form #taskActivityEditForm="ngForm" autocomplete="off" (submit)='saveActivity(taskActivityEditForm.valid)'>
        <context-provider provide="props">
            <div class="col-12 pos-modal-body screen-scroll">
                <pos-row>
                    <pos-column>
                        <pos-form-field label="Type">
                            <div class="btn-group">
                                <label class="btn btn-radio"
                                    [ngClass]="taskType == taskTypes.Existing ? 'active btn-primary normal' : 'btn-secondary'">
                                    <input type="radio" name="taskType" class="form-check-input" [(ngModel)]="taskType"
                                        [value]="taskTypes.Existing" (change)="changeTaskType()">Existing</label>
                                <label class="btn btn-radio"
                                    [ngClass]="taskType == taskTypes.New ? 'active btn-primary normal' : 'btn-secondary'">
                                    <input type="radio" name="taskType" class="form-check-input" [(ngModel)]="taskType"
                                        [value]="taskTypes.New" (change)="changeTaskType()">New</label>
                            </div>
                        </pos-form-field>
                    </pos-column>
                    <pos-column *ngIf="taskType == taskTypes.Existing">
                        <pos-form-field label="Task" [validationMessages]="{required: 'Please select task.'}">
                            <ng-select posInput [items]="tasks" appendTo="body" bindLabel="taskname" bindValue="id"
                                placeholder="Select task" [(ngModel)]="selectedTask" name="task" [required]="true">
                            </ng-select>
                        </pos-form-field>
                    </pos-column>
                    <pos-column *ngIf="taskType == taskTypes.New">
                        <pos-form-field label="Task" [validationMessages]="{required: 'Please enter task.'}">
                            <textarea posInput class="form-control" name="taskNameTemplate" rows="5" #taskNameTemplate
                                (click)="getCaretPos(taskNameTemplate)" (keyup)="getCaretPos(taskNameTemplate)"
                                [(ngModel)]="taskName" maxlength="2000" [required]="true"></textarea>
                            <span class="me-pix-20 mt-pix-n30 relative" style="float: right">
                                <span class="font-20 pointer grey">
                                    <pos-icon [icon]="icons.messageCode" class="pointer"
                                        (click)="isOpenVariable = !isOpenVariable">
                                    </pos-icon>
                                </span>
                                <div style="position: fixed; z-index: 10;" *ngIf="isOpenVariable" class="col-200">
                                    <ng-select posInput [items]="eventAttributes" appendTo="body" class="task-notification"
                                        placeholder="Select event variable" name="eventVariable"
                                        [(ngModel)]="selectedEventAttribute" (change)="changeEventAttribute()"
                                        [isOpen]="true">
                                    </ng-select>
                                </div>
                            </span>
                        </pos-form-field>
                    </pos-column>
                    <pos-column>
                        <pos-repeat-action-configuration [(eventSubscriptionActionAttributes)]="eventSubscriptionActionAttributes"
                            [isFormSubmitted]="taskActivityEditForm.submitted" #RepeatActionConfiguration
                            [isRecoverableEvent]="isRecoverableEvent">
                        </pos-repeat-action-configuration>
                    </pos-column>
                </pos-row>
            </div>
            <div class="col-12 action-button-container popup-footer">
                <button type="submit" class="btn-primary btn-lg btn btn-save pos-button">Save</button>
                <button type="button" (click)="onClose(false)"
                    class="btn-secondary btn-lg btn btn-cancel-modal pos-button">Cancel</button>
            </div>
        </context-provider>
    </form>
</pos-edit-wrapper>
