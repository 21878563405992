import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Permissions, Levels, ApplicationStateService, DomainConstants } from 'src/app/shared';
import {
  screensDesktop,
  buttonsDiceOne,
  infoManageAngleDoubleRight,
  infoManageAngleDoubleLeft,
  salesGroupCommentsDollar,
  salesCategoriesCommentDollar,
  salesUnitsThLarge,
  salesProductsFileInvoiceDollar,
  salesSizesFunnelDollar,
  infoManageBullHorn,
  vendorStruck,
  unitsThLarge,
  inventoryCategoriesUnitList,
  inventorySubCategoriesTh,
  inventoryProductsStore,
  inventoryZonesWarehouse,
  inventoryScheduleEditor,
  securityRolesUserLock,
  users, infoLinkSectionsExternalLinkSquareAlt,
  infolinksExternalLinkAlt,
  infoManageInfractionThumbsDown,
  workScheduleCalendarAlt,
  loginMessagesCommentDots,
  infoManageTimeClockEntriesClock,
  jobCodeBusinessTime,
  checkDouble,
  cashDrawerEntriesMoneyBillWave,
  cashPayoutsSearchDollar,
  commentWarningsComments,
  accountCategoriesClipboardList,
  infoManageTablesSolarPanel,
  accountAttributeType,
  equipmentPropertiesDolly,
  equipmentProperties,
  equipmentListGripVertical,
  iconsVectorSquare,
  trainingBadge,
  infoManageCogs,
  infoManageShoppingCart,
  infoManageCheckSquare,
  tools,
  tag,
  search,
  times,
  inventoryRawIngredient,
  lockOpen,
  warehouseAlt,
  layerGroup,
  fileAlt,
  exclamationTriangle
} from 'src/app/shared/components/icon';
import { BaseMenuSearchService } from 'src/app/shared/services/base-menu-search.service';
declare let $: any;

@Component({
  selector: 'pos-information-management-base',
  templateUrl: './information-management-base.component.html',
  styleUrls: ['./information-management-base.component.scss']
})
export class InformationManagementBaseComponent implements OnInit {

  isFullScreen = false;
  permissions: any = Permissions;
  accessLevels: any = Levels;
  informationManagementFilter = '';
  icons = {
    infoManageAngleDoubleRight,
    infoManageAngleDoubleLeft,
    search,
    times
  };
  menuList = [
    {
      GroupName: 'User Interface',
      Permission: this.permissions.UserInterface,
      PermissionLevel: this.accessLevels.Access,
      List: [
        {
          Name: 'Buttons', Icon: buttonsDiceOne, Permission: this.permissions.UIButtons,
          PermissionLevel: this.accessLevels.ReadOnly, RoutLink: 'buttons'
        },
        {
          Name: 'Screens', Icon: screensDesktop, Permission: this.permissions.UIScreens,
          PermissionLevel: this.accessLevels.ReadOnly, RoutLink: 'screens'
        },
        {
          Name: 'Multiple Product Groups', Icon: layerGroup, Permission: this.permissions.MultipleProductGroups,
          PermissionLevel: this.accessLevels.ReadOnly, RoutLink: 'multiple-product-groups'
        }
      ]
    },
    {
      GroupName: 'Sales Configuration',
      Permission: this.permissions.Sales,
      PermissionLevel: this.accessLevels.Access,
      List: [
        {
          Name: 'Sales Groups', Icon: salesGroupCommentsDollar, Permission: this.permissions.SalesGroups,
          PermissionLevel: this.accessLevels.ReadOnly, RoutLink: 'sales-group'
        },
        {
          Name: 'Sales Categories', Icon: salesCategoriesCommentDollar, Permission: this.permissions.SalesCategories,
          PermissionLevel: this.accessLevels.ReadOnly, RoutLink: 'sales-category'
        },
        {
          Name: 'Sales Units', Icon: salesUnitsThLarge, Permission: this.permissions.SalesUnits,
          PermissionLevel: this.accessLevels.ReadOnly, RoutLink: 'sales-units'
        },
        {
          Name: 'Sales Products', Icon: salesProductsFileInvoiceDollar, Permission: this.permissions.SalesProducts,
          PermissionLevel: this.accessLevels.ReadOnly, RoutLink: 'sales-products'
        },
        {
          Name: 'Sales Sizes', Icon: salesSizesFunnelDollar, Permission: this.permissions.SalesSizes,
          PermissionLevel: this.accessLevels.ReadOnly, RoutLink: 'sales-size'
        }
      ]
    },
    {
      GroupName: 'Inventory Configuration',
      Permission: this.permissions.ManageInventory,
      PermissionLevel: this.accessLevels.Access,
      List: [
        {
          Name: 'Vendors', Icon: vendorStruck, Permission: this.permissions.InventoryVendors,
          PermissionLevel: this.accessLevels.ReadOnly, RoutLink: 'vendors'
        },
        {
          Name: 'Units', Icon: unitsThLarge, Permission: this.permissions.InventoryStorageUnits,
          PermissionLevel: this.accessLevels.Access, RoutLink: 'units'
        },
        {
          Name: 'Inventory Categories', Icon: inventoryCategoriesUnitList, Permission: this.permissions.InventoryCategories,
          PermissionLevel: this.accessLevels.Access, RoutLink: 'inventory-categories'
        },
        {
          Name: 'Inventory Subcategories', Icon: inventorySubCategoriesTh, Permission: this.permissions.InventorySubcategories,
          PermissionLevel: this.accessLevels.ReadOnly, RoutLink: 'inventory-subcategories'
        },
        {
          Name: 'Inventory Products', Icon: inventoryProductsStore, Permission: this.permissions.InventoryProducts,
          PermissionLevel: this.accessLevels.ReadOnly, RoutLink: 'inventory-products'
        },
        {
          Name: 'Inventory Zones', Icon: inventoryZonesWarehouse, Permission: this.permissions.InventoryZones,
          PermissionLevel: this.accessLevels.ReadOnly, RoutLink: 'inventory-zones'
        },
        {
          Name: 'Raw Ingredients', Icon: inventoryRawIngredient, Permission: this.permissions.InventoryRawIngredients,
          PermissionLevel: this.accessLevels.ReadOnly, RoutLink: 'raw-ingredients'
        },
        {
          Name: 'Dietary Warnings', Icon: exclamationTriangle, Permission: this.permissions.DietaryWarnings,
          PermissionLevel: this.accessLevels.ReadOnly, RoutLink: 'dietary-warnings'
        },
        {
          Name: 'Inventory Schedule Editor', Icon: inventoryScheduleEditor, Permission: this.permissions.InventoryScheduleEditor,
          PermissionLevel: this.accessLevels.ReadOnly, RoutLink: 'inventory-schedule-editor'
        },
        {
          Name: 'Off-Site Locations', Icon: warehouseAlt, Permission: this.permissions.OffSiteInventory,
          PermissionLevel: this.accessLevels.ReadOnly, RoutLink: 'off-site-locations'
        },
      ]
    },
    {
      GroupName: 'Team Configuration',
      Permission: this.permissions.StaffManagement,
      PermissionLevel: this.accessLevels.Access,
      List: [
        {
          Name: 'Permissions', Icon: lockOpen, Permission: this.permissions.Permissions,
          PermissionLevel: this.accessLevels.ReadOnly, RoutLink: 'permissions'
        },
        {
          Name: 'Security Roles', Icon: securityRolesUserLock, Permission: this.permissions.ManageSecurityRoles,
          PermissionLevel: this.accessLevels.ReadOnly, RoutLink: 'security-roles'
        },
        {
          Name: 'Users', Icon: users, Permission: this.permissions.ManageEmployees,
          PermissionLevel: this.accessLevels.ReadOnly, RoutLink: 'users'
        },
        {
          Name: 'Info Link Sections', Icon: infoLinkSectionsExternalLinkSquareAlt, Permission: this.permissions.ManageInfoLinkSections,
          PermissionLevel: this.accessLevels.ReadOnly, RoutLink: 'info-link-sections'
        },
        {
          Name: 'Info Links', Icon: infolinksExternalLinkAlt, Permission: this.permissions.ManageInfoLinks,
          PermissionLevel: this.accessLevels.ReadOnly, RoutLink: 'info-links'
        },
        {
          Name: 'Infractions', Icon: infoManageInfractionThumbsDown, Permission: this.permissions.ManageInfractions,
          PermissionLevel: this.accessLevels.ReadOnly, RoutLink: 'infractions'
        },
        {
          Name: 'Work Schedule', Icon: workScheduleCalendarAlt, Permission: this.permissions.ManageWorkSchedule,
          PermissionLevel: this.accessLevels.ReadOnly, RoutLink: 'work-schedule'
        },
        {
          Name: 'Login Messages', Icon: loginMessagesCommentDots, Permission: this.permissions.ManageLoginMessages,
          PermissionLevel: this.accessLevels.ReadOnly, RoutLink: 'login-messages'
        },
        {
          Name: 'Time Clock Entries', Icon: infoManageTimeClockEntriesClock, Permission: this.permissions.ManageTimeClockEntries,
          PermissionLevel: this.accessLevels.ReadOnly, RoutLink: 'time-clock-entries'
        }
      ]
    },
    {
      GroupName: 'Operations',
      Permission: this.permissions.AppConfigurationOperations,
      PermissionLevel: this.accessLevels.Access,
      List: [
        {
          Name: 'Job Codes', Icon: jobCodeBusinessTime, Permission: this.permissions.OperationsJobCodes,
          PermissionLevel: this.accessLevels.ReadOnly, RoutLink: 'job-codes'
        },
        {
          Name: 'Tasks', Icon: checkDouble, Permission: this.permissions.OperationsTasks,
          PermissionLevel: this.accessLevels.ReadOnly, RoutLink: 'tasks'
        },
        {
          Name: 'Cash Drawer Entries', Icon: cashDrawerEntriesMoneyBillWave, Permission: this.permissions.OperationsCashDrawerEntries,
          PermissionLevel: this.accessLevels.ReadOnly, RoutLink: 'cash-drawer-entries'
        },
        {
          Name: 'Cash Payouts', Icon: cashPayoutsSearchDollar, Permission: this.permissions.OperationsCashPayouts,
          PermissionLevel: this.accessLevels.ReadOnly, RoutLink: 'cash-payouts'
        },
        {
          Name: 'Comment Warnings', Icon: buttonsDiceOne, Permission: this.permissions.CommentWarnings,
          PermissionLevel: this.accessLevels.ReadOnly, RoutLink: 'comment-warning'
        },
        {
          Name: 'Product Loss Reasons', Icon: commentWarningsComments, Permission: this.permissions.InventoryLossReason,
          PermissionLevel: this.accessLevels.ReadOnly, RoutLink: 'product-loss-reason'
        },
        {
          Name: 'Tags', Icon: tag, Permission: this.permissions.SalesTags,
          PermissionLevel: this.accessLevels.ReadOnly, RoutLink: 'tags'
        }
      ]
    },
    {
      GroupName: 'Customers',
      Permission: this.permissions.AppConfigurationCustomers,
      PermissionLevel: this.accessLevels.Access,
      List: [
        {
          Name: 'Account Categories', Icon: accountCategoriesClipboardList, Permission: this.permissions.CustomersAccountCategories,
          PermissionLevel: this.accessLevels.ReadOnly, RoutLink: 'account-categories'
        },
        {
          Name: 'Accounts', Icon: infoManageTablesSolarPanel, Permission: this.permissions.CustomersAccounts,
          PermissionLevel: this.accessLevels.ReadOnly, RoutLink: 'accounts'
        },
        {
          Name: 'Accounts Attribute Types', Icon: accountAttributeType, Permission: this.permissions.CustomersAccountAttributeTypes,
          PermissionLevel: this.accessLevels.ReadOnly, RoutLink: 'account-attribute-types'
        }
      ]
    },
    {
      GroupName: 'Equipment',
      Permission: this.permissions.Equipments,
      PermissionLevel: this.accessLevels.Access,
      List: [
        {
          Name: 'Equipment Properties', Icon: equipmentProperties, Permission: this.permissions.EquipmentsProperties,
          PermissionLevel: this.accessLevels.ReadOnly, RoutLink: 'equipment-properties'
        },
        {
          Name: 'Equipment Types', Icon: equipmentPropertiesDolly, Permission: this.permissions.EquipmentsTypes,
          PermissionLevel: this.accessLevels.ReadOnly, RoutLink: 'equipment-types'
        },
        {
          Name: 'Equipment List', Icon: equipmentListGripVertical, Permission: this.permissions.EquipmentsEquipment,
          PermissionLevel: this.accessLevels.ReadOnly, RoutLink: 'equipments'
        }
      ]
    },
    {
      GroupName: 'Training',
      Permission: this.permissions.Training,
      PermissionLevel: this.accessLevels.Access,
      List: [
        {
          Name: 'Training Areas', Icon: iconsVectorSquare, Permission: this.permissions.TrainingAreas,
          PermissionLevel: this.accessLevels.ReadOnly, RoutLink: 'training-areas'
        },
        {
          Name: 'Training Badges', Icon: trainingBadge, Permission: this.permissions.TrainingBadges,
          PermissionLevel: this.accessLevels.ReadOnly, RoutLink: 'training-badges'
        }
      ]
    },
    {
      GroupName: 'Online Ordering',
      Permission: this.permissions.OnlineOrdering,
      PermissionLevel: this.accessLevels.Access,
      List: [
        {
          Name: 'Settings', Icon: infoManageCogs, Permission: this.permissions.OnlineOrderingSettings,
          PermissionLevel: this.accessLevels.ReadOnly, RoutLink: 'online-ordering-settings'
        },
        {
          Name: 'Categories', Icon: infoManageShoppingCart, Permission: this.permissions.OnlineOrderingCategories,
          PermissionLevel: this.accessLevels.ReadOnly, RoutLink: 'online-ordering-categories'
        },
        {
          Name: 'Options', Icon: infoManageCheckSquare, Permission: this.permissions.OnlineOrderingOptionLists,
          PermissionLevel: this.accessLevels.ReadOnly, RoutLink: 'online-ordering-options'
        },
        {
          Name: 'Configuration', Icon: tools, Permission: this.permissions.OnlineOrderingOptionConfiguration,
          PermissionLevel: this.accessLevels.ReadOnly, RoutLink: 'online-ordering-configuration'
        },
        {
          Name: 'Terms & Conditions', Icon: fileAlt, Permission: this.permissions.OnlineOrderingTermsAndConditions,
          PermissionLevel: this.accessLevels.ReadOnly, RoutLink: 'online-ordering-terms-conditions'
        }
      ]
    },
    {
      GroupName: 'Wait Time',
      Permission: this.permissions.WaitTime,
      PermissionLevel: this.accessLevels.Access,
      List: [
        DomainConstants.WaitTimeRuleTypes.Global,
        DomainConstants.WaitTimeRuleTypes.Order
      ]
    },
    {
      GroupName: 'Audio Notifier',
      Permission: this.permissions.AudioNotifier,
      PermissionLevel: this.accessLevels.Access,
      List: [
        {
          Name: 'Audio Clips', Icon: infoManageBullHorn, Permission: this.permissions.AudioClips,
          PermissionLevel: this.accessLevels.ReadOnly, RoutLink: 'audio-notification-files'
        }
      ]
    },
  ];
  filterMenuList = [...this.menuList];
  constructor(private applicationStateService: ApplicationStateService,
    private baseMenuSearchService: BaseMenuSearchService,
    private router: Router) { }

  ngOnInit() {
    if (this.router.url === 'manage/app-configuration') {
      this.applicationStateService.gridRouteContext = null;
    }
  }

  toggleFullScreen() {
    this.isFullScreen = !this.isFullScreen;
    if (this.isFullScreen) {
      $('.tab-content').css('width', '100%');
    } else {
      $('.tab-content').css('width', '80%');
    }
  }

  search() {
    this.filterMenuList = this.baseMenuSearchService.search(this.menuList, this.informationManagementFilter);
  }
}
