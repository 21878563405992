<pos-edit-wrapper heading="Rearrange Buttons" (close)="onClose()">
    <div class="col-12 container-fluid">
        <ul class="nav nav-tabs pos-nav-tabs inner-tab">
            <li *ngFor="let group of groupedButtons | keyvalue: originalOrder"
                [ngClass]="{'active': selectedTab == group.value[0].ScreenConfig.Name }">
                <a (click)="selectedTab = group.value[0].ScreenConfig.Name" class="blue">
                    {{ group.value[0].ScreenConfig.Name | splitByCapitalLetter }}</a>
            </li>
        </ul>
        <div class="modal-body px-0 overflow-auto"
            [ngStyle]="{ 'height': screenHeight + 'px', 'max-height': 'calc(100vh - 200px)'}">
            <div class="buttons-body">
                <div *ngFor="let group of groupedButtons | keyvalue"
                    [hidden]="selectedTab != group.value[0].ScreenConfig.Name">

                    <!-- Buttons -->
                    <div cdkDropListGroup class="display-flex" [ngClass]="{'flex-wrap': gridConfig.NoOfCols}"
                        *ngIf="group.value[0].ScreenConfig.Name == kioskScreenConfigProperties.Buttons"
                        [ngStyle]="{'width': kioskScreen.TemplateType == templateTypes.FluidGridLayout ? (((buttonConfig.Width ?? buttonWidth) + 20) * gridConfig.NoOfCols) + 'px' : 'inherit' }">
                        <div cdkDropList *ngFor="let screenChoice of group.value; let buttonIndex = index"
                            [cdkDropListData]="buttonIndex" id="divRepeatGroup">
                            <div cdkDrag [cdkDragData]="buttonIndex" (cdkDragEntered)="dragEntered($event, group.value)"
                                [ngStyle]="{ 'width': buttonWidth + 'px', 'height': (buttonConfig.Height ?? buttonWidth) + 'px', 'border-color': screenChoice?.Button?.BorderColor, 'border-radius': buttonConfig.Radius + 'px', 'background-color': screenChoice?.Button?.BackgroundColor}"
                                class="kiosk-button kiosk-bg-light-grey">
                                <div class="button-image" *ngIf="screenChoice?.Button?.Image"
                                    [ngStyle]="{'--max-button-width': buttonWidth + 'px', 'border-radius': buttonConfig.ImageRadius}">
                                    <img alt="" src="{{imageRootPath}}{{screenChoice.Button.Image}}"
                                        [class.stretch-image]="gridConfig.IsStretchImage" />
                                </div>
                                <div class="button-text"
                                    [ngClass]="screenChoice?.Button?.Image ? '' : 'height-fill-available'"
                                    [ngStyle]="{'color': screenChoice.Button?.TextColor, 'font-size': screenChoice.Button?.FontSize, 'background-color': screenChoice.Button?.Image ? buttonConfig.TextBackground : '', '--button-border-radius': buttonConfig.Radius + 'px' }"
                                    [innerHTML]="screenChoice.Button?.ButtonText | uppercase">
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Secondary Buttons -->
                    <div cdkDropListGroup class="display-flex"
                        *ngIf="group.value[0].ScreenConfig.Name == kioskScreenConfigProperties.SecondaryButtons">
                        <div cdkDropList *ngFor="let screenChoice of group.value; let buttonIndex = index"
                            [cdkDropListData]="buttonIndex">
                            <div cdkDrag [cdkDragData]="buttonIndex" (cdkDragEntered)="dragEntered($event, group.value)"
                                [ngStyle]="{'width': (secondaryButtonConfig.Width ?? 100) + 'px', 'height': (secondaryButtonConfig.Height ?? 50) + 'px', 'border-color': screenChoice?.Button?.BorderColor, 'border-radius': secondaryButtonConfig.Radius + 'px',
                            'background-color': screenChoice?.Button?.BackgroundColor}"
                                class="kiosk-button kiosk-bg-light-grey">
                                <div class="button-image" *ngIf="screenChoice?.Button?.Image"
                                    [ngStyle]="{'--max-button-width': (secondaryButtonConfig.Width ?? 100) + 'px'}">
                                    <img alt="" src="{{imageRootPath}}{{screenChoice.Button.Image}}" />
                                </div>
                                <div class="button-text"
                                    [ngClass]="screenChoice?.Button?.Image ? '' : 'height-fill-available'"
                                    [ngStyle]="{'color': screenChoice.Button?.TextColor, 'font-size': screenChoice.Button?.FontSize }"
                                    [innerHTML]="screenChoice.Button?.ButtonText | uppercase">
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Left Panel Buttons -->
                    <div cdkDropListGroup class="display-flex"
                        *ngIf="group.value[0].ScreenConfig.Name == kioskScreenConfigProperties.LeftPanelButtons">
                        <div cdkDropList *ngFor="let screenChoice of group.value; let buttonIndex = index"
                            [cdkDropListData]="buttonIndex">
                            <div cdkDrag [cdkDragData]="buttonIndex" (cdkDragEntered)="dragEntered($event, group.value)"
                                [ngStyle]="{'width': leftPanelConfig.Width + 'px', 'height': leftPanelConfig.Height + 'px', 'border-color': screenChoice?.Button?.BorderColor,
                        'background-color': screenChoice?.Button?.BackgroundColor, 'border-radius': leftPanelConfig.Radius + 'px',}"
                                class="kiosk-button kiosk-bg-light-grey">
                                <div class="button-image" *ngIf="screenChoice?.Button?.Image"
                                    [ngStyle]="{'--max-button-width': leftPanelConfig.Width + 'px'}">
                                    <img alt="" src="{{imageRootPath}}{{screenChoice.Button.Image}}" />
                                </div>
                                <div class="button-text"
                                    [ngClass]="screenChoice?.Button?.Image ? '' : 'height-fill-available'"
                                    [ngStyle]="{'color': screenChoice.Button?.TextColor, 'font-size': screenChoice.Button?.FontSize }"
                                    [innerHTML]="screenChoice.Button?.ButtonText | uppercase">
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Right Panel Buttons -->
                    <div cdkDropListGroup class="display-flex flex-column"
                        *ngIf="group.value[0].ScreenConfig.Name == kioskScreenConfigProperties.RightPanelButtons">
                        <div cdkDropList *ngFor="let screenChoice of group.value; let buttonIndex = index"
                            [cdkDropListData]="buttonIndex">
                            <div cdkDrag [cdkDragData]="buttonIndex" (cdkDragEntered)="dragEntered($event, group.value)"
                                [ngStyle]="{'width': rightPanelConfig.Width ?? '200px', 'height': rightPanelConfig.Height + 'px', 'border-color': screenChoice?.Button?.BorderColor,
                        'background-color': screenChoice?.Button?.BackgroundColor, 'border-radius': rightPanelConfig.Radius + 'px',}"
                                class="kiosk-button kiosk-bg-light-grey">
                                <div class="button-image" *ngIf="screenChoice?.Button?.Image"
                                    [ngStyle]="{'--max-button-width': rightPanelConfig.Width}">
                                    <img alt="" src="{{imageRootPath}}{{screenChoice.Button.Image}}" />
                                </div>
                                <div class="button-text"
                                    [ngClass]="screenChoice?.Button?.Image ? '' : 'height-fill-available'"
                                    [ngStyle]="{'color': screenChoice.Button?.TextColor, 'font-size': screenChoice.Button?.FontSize }"
                                    [innerHTML]="screenChoice.Button?.ButtonText | uppercase">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 action-button-container popup-footer">
        <button class="btn btn-primary btn-lg btn-save pos-button white" (click)="saveOrdinal()"
            type="button">Save</button>
        <button class="btn btn-secondary btn-lg btn-cancel-modal pos-button" (click)="onClose()"
            type="button">Cancel</button>
    </div>
</pos-edit-wrapper>
