import { AfterViewInit, Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { GridColumn, TemplateColumn } from '@tarktech/tark-ng-utils';
import { IngredientsDietaryWarning } from 'src/app/orders/order-entry';
declare let $: any;

@Component({
  selector: 'pos-dietary-warnings-info',
  templateUrl: './dietary-warnings-info.component.html',
  styleUrls: ['./dietary-warnings-info.component.scss']
})
export class DietaryWarningsInfoComponent implements OnInit, AfterViewInit {

  @Input() dietaryWarnings: Array<IngredientsDietaryWarning> = [];

  dietaryWarningInfoColumns: Array<GridColumn> = [];
  tableHeight = '300px';

  @ViewChild('inventoryDietaryTemplate', { static: true }) inventoryDietaryTemplate: TemplateRef<any>;

  constructor() { }

  ngOnInit(): void {
    this.configureGridColumns();
    this.resizeWindow();
  }

  ngAfterViewInit(): void {
    this.setHeight();
  }

  resizeWindow() {
    window.onresize = () => {
      this.setHeight();
    };
  }

  setHeight() {
    const height = $('.menu-explorer-tab-height').height() - 25;
    this.tableHeight = height + 'px';
  }

  configureGridColumns(): any {
    this.dietaryWarningInfoColumns = [
      new TemplateColumn({ HeaderText: 'Product', itemTemplate: this.inventoryDietaryTemplate, Width: '40%' , CellClass: 'pli-15' }),
      new GridColumn({ HeaderText: 'Warning', Field: 'DietaryWarning', Width: '30%' }),
      new GridColumn({ HeaderText: 'Warning Type', Field: 'DietaryWarningTypeName', Width: '30%' }),
    ] as Array<GridColumn>;
  }
}
