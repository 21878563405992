import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { recaptchaConfig } from 'src/app/shared/components/icon/icons';
import { RecaptchaConfig } from '../../interface/recaptcha-config';
import { RecaptchaConfigService } from '../../service/recaptcha-config.service';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PostSavedConfigurationService } from 'src/app/configurator/post-saved-configuration.service';
import { FormUtilityService } from 'src/app/shared/services/form-utility.service';
import { BaseFormComponent } from 'src/app/shared/components/base-form.component';
import { finalize } from 'rxjs';
import { Messages } from 'src/app/shared/constants/ui-messages';
import { ExtensionValidationService } from 'src/app/shared/services/extension-validation.service';
declare let $: any;
@Component({
  selector: 'pos-recapcha-settings',
  templateUrl: './recapcha-settings.component.html',
  styleUrls: ['./recapcha-settings.component.scss']
})
export class RecapchaSettingsComponent extends BaseFormComponent implements OnInit {
  get getForm(): NgForm {
    return this.form
  }
  @ViewChild('recapchaConfigForm') form: NgForm;
  recapchaSettings: RecaptchaConfig;
  icons = {
    recaptchaConfig
  };
  props = {
    controlBoxClass: 'col-lg-3 col-md-5 col-sm-8 col-xs-12'
  }
  isChooseFile: boolean = false;
  configFile: File;
  configFileName: string = null;
  configFileExtensions: string[] =  [".json"];
  acceptableConfigExtensions: string;

  constructor(private recapchaSettingsService: RecaptchaConfigService,
    private alertService: AlertsService,
    private spinnerService: SpinnerService,
    private router: Router,
    private route: ActivatedRoute,
    private postSavedConfigurationService: PostSavedConfigurationService,
    private extensionValidation: ExtensionValidationService,
    formUtilityService: FormUtilityService) {
    super(formUtilityService);
  }

  ngOnInit() {
    this.recapchaSettings = this.recapchaSettingsService.newRecaptchaConfig();
    this.acceptableConfigExtensions = this.configFileExtensions.join(',');
    this.getRecapchaSettings();
  }

  handleFileInput(files: FileList) {
    this.isChooseFile = true;
    this.configFile = files.item(0);
    const fileToUpload = this.configFile ? this.configFile.name : '';
    if(this.extensionValidation.isInvalidExtension(fileToUpload, this.configFileExtensions)){
      if(fileToUpload){
        this.alertService.renderErrorMessage(Messages.InvalidRecaptchaFileType);
      }
      $("#configFile").val('');
      this.configFileName = '';
      return;
    }

    if (files && files[0]) {
      this.configFileName = this.configFile.name;
    }
  }

  getRecapchaSettings() {
    this.spinnerService.show();
    this.recapchaSettingsService.getRecaptchaConfig().pipe(finalize(() => {
      this.spinnerService.hide();
    })).subscribe({
      next: (res) => {
        this.recapchaSettings = res;
        this.configFileName = this.recapchaSettings.ServiceAccountConfigFileName;
        this.onFormChange(this.form);
      }, error: this.alertService.showApiError
    });
  }

  saveRecapchaSettings(isValid: boolean) {
    if (!isValid || !this.configFileName) {
      return;
    }

    this.spinnerService.show();
    this.recapchaSettingsService.saveRecaptchaConfig(this.recapchaSettings, this.configFile).pipe(finalize(() => {
      this.spinnerService.hide()
    })).subscribe({
      next: (response) => {
        this.alertService.renderSuccessMessage(Messages.RecapchaConfigSaveSuccess);
        this.postSavedConfigurationService.showMessage();
      }, error: this.alertService.showApiError
    });
  }

  onFormChange($event: NgForm) {
    this.form = $event;
    this.setInitialValue();
  }

  onCancel() {
    this.router.navigate(['..'], { relativeTo: this.route });
  }
}
