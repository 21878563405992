import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';

@Injectable()
export class GiftCardService {

  constructor(private httpService: HttpClient) { }

  getAllGiftCards(): Observable<any[]> {
    return this.httpService.get<any[]>(
      `${RuntimeConstants.API_PATH}giftcard/`
    )
  }

  getGiftCardDetails(giftCardNumber: any): Observable<any> {
    return this.httpService.get(
      `${RuntimeConstants.API_PATH}giftcard/` + giftCardNumber
    );
  }

  getGiftCardHistory(giftCardNumber: any): Observable<any> {
    return this.httpService.get(
      `${RuntimeConstants.API_PATH}giftcard/history/` + giftCardNumber
    );
  }

  getGiftCardPurchaseAmount(drawerId: number): Observable<number> {
    return this.httpService.get<number>(
      `${RuntimeConstants.API_PATH}giftcard/purchase-amount/` + drawerId
    );
  }

  addGiftCardBalance(giftCardRequest: any): Observable<any> {
    return this.httpService.put(
      `${RuntimeConstants.API_PATH}giftcard/` + giftCardRequest.CardNumber,
      giftCardRequest
    );
  }

  cancelCreditCardTransaction(cancelRequestModel, orderId): Observable<any> {
    return this.httpService.post(
      `${RuntimeConstants.API_PATH}Orders/cancel-creditcard-transaction/` + orderId,
      cancelRequestModel
    );
  }

  printGiftCardBalance(giftCardRequest: any): Observable<any> {
    return this.httpService.put(
      `${RuntimeConstants.API_PATH}giftcard/print-balance`,
      giftCardRequest
    );
  }

  deleteGiftCardRecharge(transactionId: any): Observable<any> {
    return this.httpService.delete(
      `${RuntimeConstants.API_PATH}giftcard/delete-recharge/` + transactionId
    );
  }

}
