import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RuntimeConstants } from 'src/app/shared';

@Injectable({
  providedIn: 'root'
})
export class OnlineOrderingChildOptionService {

  readonly basePath = `${RuntimeConstants.API_PATH}onlineorderingchildoption/`;
  constructor(private httpService: HttpClient) { }

  getAllChildOptions() {
    return this.httpService.get(`${this.basePath}`);
  }

  getChildOptionsByButtonId(buttonId: number): Observable<any> {
    return this.httpService.get(`${this.basePath}${buttonId}`);
  }

  addChildOption(childOption): Observable<any> {
    return this.httpService.put(`${this.basePath}`, childOption);
  }

  deleteChildOption(childOptionId: number): Observable<any> {
    return this.httpService.delete(`${this.basePath}${childOptionId}`);
  }

  updateChildOptionOrdinal(childOptions): Observable<any> {
    return this.httpService.put(
      `${this.basePath}ordinal/`, childOptions
    );
  }
}
