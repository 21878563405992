import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { cloneDeep, filter, find, isNull } from 'lodash';
import { DomainConstants, FormUtilityService, ICloseable, ModalFormComponent, ModalService } from 'src/app/shared';
import { Terminal } from '../../../../configurator/terminals';
import { EventSubscriptionActionAttribute } from '../../interfaces/event-subscription-action-attribute';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { RepeatActionConfigurationComponent } from '../repeat-action-configuration';

@Component({
  selector: 'pos-kiosk-enable-disable-configuration',
  templateUrl: './kiosk-enable-disable-configuration.component.html',
  styleUrls: ['./kiosk-enable-disable-configuration.component.scss']
})
export class KioskEnableDisableConfigurationComponent extends ModalFormComponent implements OnInit, ICloseable {
  get getForm(): NgForm {
    return this.disableKioskEditForm;
  }
  @ViewChild('RepeatActionConfiguration') repeatActionConfiguration: RepeatActionConfigurationComponent;
  @ViewChild('disableKioskEditForm', { static: true }) disableKioskEditForm: NgForm;
  @Input() actionId = 0;
  @Input() eventSubscriptionActionAttributes: Array<EventSubscriptionActionAttribute> = [];
  @Input() eventAttributes;
  @Input() deliveryTypes = [];
  selectedDeliveryType;
  @Input() eventIdentifier: string;
  @Input() terminals: Array<Terminal> = [];
  @Input() isRecoverableEvent;
  deliveryAttributes = DomainConstants.EventDeliveryAttributes;
  selectedTerminals: Array<number> = [];
  enableStatus: string = 'Enable';
  props = {
    controlBoxClass: 'col-lg-9 col-md-9 col-sm-12 col-xs-12'
  }
  constructor(modalRef: BsModalRef, modalService: ModalService, protected formUtilityService: FormUtilityService) {
    super(modalRef, modalService, formUtilityService);
  }

  ngOnInit(): void {
    this.terminals = cloneDeep(filter(this.terminals, (terminal) => {
      return terminal.TerminalType == DomainConstants.TerminalTypes.ORDER_KIOSK.Name || !terminal.TerminalId
    }));
    const allTerminalRecord = this.terminals.find(x => !x.TerminalId);
    if (allTerminalRecord)
      allTerminalRecord.TerminalName = 'All Kiosk Terminals';
    const selectedTerminals = find(this.eventSubscriptionActionAttributes,
      x => x.AttributeKey === this.deliveryAttributes.EnableOrDisableKiosk.Terminals);
    this.selectedTerminals = selectedTerminals?.AttributeValue ?
      selectedTerminals.AttributeValue.split(',').map(x => parseInt(x, 10)) : null;
    const enableStatus = find(this.eventSubscriptionActionAttributes,
      x => x.AttributeKey === this.deliveryAttributes.EnableOrDisableKiosk.Status)?.AttributeValue;
    this.enableStatus = enableStatus ? enableStatus : this.enableStatus;
  }

  saveActivity = (isValid: boolean) => {
    if (isValid) {
      const actionAttributes = this.prepareEventActionAttributes();
      this.hide({ shouldReload: true, actionAttributes: actionAttributes });
    }
  }

  onClose = (shouldReload: boolean) => {
    this.hide({ shouldReload: shouldReload });
  }

  prepareEventActionAttributes() {
    const actionDeliveryAttributes: Array<EventSubscriptionActionAttribute> = [];
    this.addAttribute(this.deliveryAttributes.EnableOrDisableKiosk.Terminals, this.selectedTerminals ?
      this.selectedTerminals.join() : '', actionDeliveryAttributes);
    this.addAttribute(this.deliveryAttributes.EnableOrDisableKiosk.Status, this.enableStatus ?
      this.enableStatus : '', actionDeliveryAttributes);
    if (this?.repeatActionConfiguration?.repeatConfig) {
      this.addAttribute(this.deliveryAttributes.EnableOrDisableKiosk.Repeat, this?.repeatActionConfiguration?.isRepeat ? JSON.stringify(this?.repeatActionConfiguration?.repeatConfig) : "", actionDeliveryAttributes)
    }
    return actionDeliveryAttributes;
  }

  addAttribute = (key, value, array) => {
    const actionDeliveryAttribute: EventSubscriptionActionAttribute = {
      Id: this.getActionAttributeId(key),
      AttributeKey: key,
      AttributeValue: value,
      EventSubscriptionActionId: this.actionId
    };
    if ((actionDeliveryAttribute.Id > 0 && !isNull(actionDeliveryAttribute.AttributeValue)) ||
      (actionDeliveryAttribute.Id === 0 && actionDeliveryAttribute.AttributeValue)) {
      array.push(actionDeliveryAttribute);
    }
  }

  getActionAttributeId(key) {
    const attribute = find(this.eventSubscriptionActionAttributes, x => x.AttributeKey === key);
    return attribute ? attribute.Id : 0;
  }

  changeSelectedTerminals() {
    if (this.terminals.length > 0) {
      const lastSelectedTerminal = this.selectedTerminals[this.selectedTerminals.length - 1];
      if (lastSelectedTerminal === 0) {
        this.selectedTerminals = this.selectedTerminals.filter(x => x === 0);
      } else {
        this.selectedTerminals = this.selectedTerminals.filter(x => x !== 0);
      }
    }
  }
}
