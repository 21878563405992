<pos-edit-wrapper heading="AI Shake" [headingIcon]="icons.userRobot" (close)="cancelEditing()" [isPopup]="false">
    <form #configForm="ngForm" class="edit-form" (ngSubmit)="saveConfig(configForm.valid)">
        <div class="col-12 pos-page-body border-top">
            <pos-row>
                <pos-column>
                    <pos-form-field label="AI Processing Image" inputStyle="col-12">
                        <pos-row>
                            <pos-column md="5" sm="8" xs="12">
                                <input type="file" class="form-control" accept=".png,.jpg,.jpeg,.gif,.bmp,.mp4" #image
                                    name="image" id="image" (change)="handleFileInput($event.target.files)">
                            </pos-column>
                            <pos-column md="3" sm="4" xs="6">
                                <pos-row class="p-0">
                                    <pos-column md="3" sm="5" xs="8" class="text-right pe-0" [hidden]="!showIcon">
                                        <img src="{{imageBasePath}}/order-kiosk/default-screens/{{parameters.OrderKioskAIShakeProcessingImage}}"
                                            height="50px" #aiProcessingImage id="aiProcessingImage" alt="Video" />
                                    </pos-column>
                                    <pos-column sm="4" xs="2" class="mt-pix-n5" (click)="removeIcon()"
                                        [hidden]="!showIcon">
                                        <pos-icon [icon]="icons.times" class="pointer remove-icon-badge"></pos-icon>
                                    </pos-column>
                                </pos-row>
                            </pos-column>
                        </pos-row>
                    </pos-form-field>
                </pos-column>

                <pos-column>
                    <pos-form-field label="Image/Video Playback Seconds">
                        <input posInput posOnlyNumbers name="imageSeconds" class="form-control col-120" type="number"
                            [(ngModel)]="parameters.OrderKioskAIShakeProcessingImageSeconds" />
                    </pos-form-field>
                </pos-column>
            </pos-row>
            <div class="col-12 section-border">
                <h3 class="setting-title">Throttling Configuration</h3>
                <pos-row colXs="12" class="pos-title-content">
                    <pos-column>
                        <pos-form-field label="Allowed Subsequent Attempts">
                            <input posInput posOnlyNumbers name="limit" class="form-control col-120" type="number"
                                [(ngModel)]="parameters.OrderKioskAIShakeGenerationLimit" />
                        </pos-form-field>
                    </pos-column>

                    <pos-column>
                        <pos-form-field label="Throttle Until" inputStyle="col-6 row row-cols-2 mx-0"
                            [validationMessages]="{required: 'Please enter throttle until minutes.'}">
                            <input posInput posOnlyNumbers name="downTimeMinutes" class="form-control col-120"
                                type="number" [(ngModel)]="parameters.OrderKioskAIShakeGenerationDownTimeMinutes"
                                [required]="parameters.OrderKioskAIShakeGenerationLimit" />
                            <label class="col-30 edit-form__section__label"> minutes </label>
                        </pos-form-field>
                    </pos-column>

                    <pos-column>
                        <pos-form-field label="Message" [validationMessages]="{required: 'Please enter message.'}">
                            <textarea posInput class="form-control" name="messageText"
                                [(ngModel)]="parameters.OrderKioskAIShakeGenerationDownTimeMessageText"
                                [required]="parameters.OrderKioskAIShakeGenerationLimit"
                                #messageText="ngModel"></textarea>
                        </pos-form-field>
                    </pos-column>
                </pos-row>
            </div>
        </div>
        <div class="col-xs-12 action-button-container page-footer">
            <div class="form-horizontal">
                <button class="btn btn-primary btn-lg btn-save pos-button" scrollup type="submit"
                    value="Save">Save</button>
                <button class="btn btn-secondary btn-lg btn-cancel pos-button" routerLink="../" scrollup type="button"
                    value="Cancel">Cancel</button>
            </div>
        </div>
    </form>
</pos-edit-wrapper>
