import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KioskChoice } from '../interfaces/kiosk-choice';
import { BaseCrudService } from 'src/app/shared/services/base-crud.service';
import { DomainConstants } from 'src/app/shared';

@Injectable({
  providedIn: 'root'
})
export class OrderKioskChoiceService extends BaseCrudService<KioskChoice> {

  constructor(httpClient: HttpClient) {
    super('kiosk-choice', httpClient);
  }

  newChoice() {
    return {
      Id: 0,
      BackgroundColor: '',
      BorderColor: '',
      ButtonText: '',
      KitchenText: '',
      ButtonType: DomainConstants.KioskChoiceType.Standard,      
      ChoiceBehaviors: [],
      Image: '',
      TextColor: '',
      SalesProduct: null,
      SalesProductId: null
    } as KioskChoice;
  }
}
