import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AlertsService, ModalComponent, SpinnerService, Messages, ModalService, ConfirmDeleteModalComponent, StringUtils } from 'src/app/shared';
import { DeleteColumn, GridColumn } from '@tarktech/tark-ng-utils';
import { OnlineOrderingCategoriesService, OnlineOrderingCategoryProductService } from '../../../service';
import { ConfirmDeleteComponent } from 'src/app/shared/components/confirm-delete/confirm-delete.component';
import { Observable, zip } from 'rxjs';
import * as _ from 'lodash';
import { ButtonOptionGraph } from '../../../service/button-option-graph';

@Component({
  selector: 'pos-online-ordering-option-product-configuration',
  templateUrl: './online-ordering-option-product-configuration.component.html',
  styleUrls: ['./online-ordering-option-product-configuration.component.scss'],
})
export class OnlineOrderingOptionProductConfigurationComponent extends ModalComponent implements OnInit {

  optionList: Array<any> = [];
  mappedOptionList: Array<any> = [];
  optionProductList: Array<any> = [];
  mappedOptionListColumns: Array<GridColumn> = [];
  selectedOption: any;
  screenHeight = 150 + 'px';
  productId: number;
  productName: string;
  showSelectionOption: boolean = false;
  deleteMappedOptionId: number = 0;
  getOnlineOrderingProductConfiguration: (productId: number) => Observable<any>;
  deleteMenuButtonConfiguration: (optionId: number) => Observable<any>;
  addMenuButtonConfiguration: (menuButtonConfiguration: any) => Observable<any>;
  updateOnlineOrderingMenuButtonConfigurationOrdinal: (mappedOptionList: any) => Observable<any>;
  @Input() buttonOptionGraph: ButtonOptionGraph = new ButtonOptionGraph();

  optionLabel: string;
  optionId: number;
  constructor(commentModalRef: BsModalRef,
    private readonly alertService: AlertsService,
    private readonly spinnerService: SpinnerService,
    private readonly onlineOrderingCategoriesService: OnlineOrderingCategoriesService,
    private readonly onlineOrderingCategoryProductService: OnlineOrderingCategoryProductService,
    private readonly modalService: ModalService) {
    super(commentModalRef);
  }

  ngOnInit() {
    this.columnConfiguration();
    this.getConfigurationDetails()
  }

  getConfigurationDetails() {
    if (this.productId > 0) {
      this.spinnerService.show();
      zip(
        this.onlineOrderingCategoriesService.getOnlineOrderingCategories(),
        this.getOnlineOrderingProductConfiguration(this.productId),
        (
          categoryData: any,
          configurationData: any
        ) => {
          this.optionList = _.filter(categoryData, (orderingCategories) => {
            return orderingCategories.IsOption == true && orderingCategories.Id !== this.optionId;
          });
          this.optionList = _.orderBy(this.optionList, ['Name']);
          this.mappedOptionList = configurationData;
        }
      ).subscribe({
        next: (res) => {
        }, error: this.alertService.showApiError,
        complete: () => {
          this.spinnerService.hide();
        }
      });

    }
  }

  private columnConfiguration() {
    const deleteColumn = new DeleteColumn({
      Clicked: (data) => {
        this.deleteMappedConfiguration(data);
      },
      Width: '40px'
    });


    this.mappedOptionListColumns = [
      new GridColumn({ HeaderText: this.optionLabel+'s', Field: 'MenuCategory.Name', IsSortable: true, Width: '88%' })
    ] as Array<GridColumn>;
    this.mappedOptionListColumns.push(deleteColumn);
  }

  onShowSelectionOption(data: any) {
    if (this.selectedOption == data.Option) {
      this.showSelectionOption = !this.showSelectionOption;
    }
    else {
      this.showSelectionOption = true;
    }
    this.selectedOption = data.Option;
  }

  onCancel(reload: boolean) {
    this.hide({ shouldReload: reload });
  }

  addOption() {
    if (this.mappedOptionList && this.mappedOptionList.length > 0) {
      var index = _.findIndex(this.mappedOptionList, (optionProduct) => {
        return optionProduct.OptionId == this.selectedOption;
      });

      if (index >= 0) {
        this.alertService.renderErrorMessage(StringUtils.format(Messages.ErrorWhileOnlineOrderingAddingDuplicateMenuButtonOption, { 'option': this.optionLabel.toLowerCase() }));
        return;
      }
    }

    if (this.selectedOption) {
      let menuButtonConfiguration = {
        ButtonId: this.productId,
        OptionId: this.selectedOption,
        Ordinal: 0
      }
      
      if (this.buttonOptionGraph.isPathFromOptionToButton(this.selectedOption, this.productId)) {
        this.alertService.renderErrorMessage(Messages.AddingOptionToButtonCreatesCycle)
        return;
      }

      this.spinnerService.show();
      this.addMenuButtonConfiguration(menuButtonConfiguration).subscribe({
        next: () => {
          this.alertService.renderSuccessMessage(StringUtils.format(Messages.MenuButtonConfigurationAdded, { 'option': this.optionLabel }));
          this.getConfigurationDetails();
          this.selectedOption = null;
          this.buttonOptionGraph.addButtonChildOptions([{ ButtonId: this.productId, OptionId: this.selectedOption }]);
        }, 
        error: this.alertService.showApiError,
        complete: () => this.spinnerService.hide(),
      })
    }
  }

  deleteMappedConfiguration(data) {
    this.deleteMappedOptionId = data.Id;
    const modelRef = this.modalService.show(ConfirmDeleteModalComponent ,{
      animated: false,
      initialState: {
        message: StringUtils.format(Messages.ConfirmDeleteMenuButtonConfiguration, { 'option': this.optionLabel.toLowerCase() })
      }
    });
    modelRef.close.subscribe((res)=>{
      if(res.shouldDelete){
        this.onConfirmAcknowledge();
      }
    })
  }

  onConfirmAcknowledge() {
    this.spinnerService.show();
    this.deleteMenuButtonConfiguration(this.deleteMappedOptionId).subscribe(
      () => {
        this.alertService.renderSuccessMessage(
          StringUtils.format(Messages.MenuButtonConfigurationDeleted, { 'option': this.optionLabel.toLowerCase() })
        );
        this.deleteMappedOptionId = 0;
        this.buttonOptionGraph.removeEdgeButtonToOption(this.productId, this.deleteMappedOptionId);
        this.getConfigurationDetails();
      },
      this.alertService.showApiError,
      () => {
        this.spinnerService.hide();
      }
    );
  }

  gridRowReorder(event) {
    this.updateOnlineOrderingMenuButtonConfigurationOrdinal(this.mappedOptionList).subscribe({
      next: (res) => { this.mappedOptionList = res ? res : this.mappedOptionList; },
      error: this.alertService.showApiError
    });
  }
}
