import { Injectable } from '@angular/core';
import { SlideShowData } from 'src/app/shared';
import { CreatedShake, KioskScreen, ShakeFlavorGroup } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class OrderKioskStorageService {

  private _slideShowData: Array<SlideShowData> = [];
  public get slideShowData(): Array<SlideShowData> {
    return this._slideShowData;
  }
  public set slideShowData(value: Array<SlideShowData>) {
    this._slideShowData = value;
  }
  private _kioskScreen: KioskScreen;
  public get kioskScreen(): KioskScreen {
    return this._kioskScreen;
  }
  public set kioskScreen(value: KioskScreen) {
    this._kioskScreen = value;
  }
  
  private _homeScreenId: number;
  public get homeScreenId(): number {
    return this._homeScreenId;
  }
  public set homeScreenId(value: number) {
    this._homeScreenId = value;
  }
  private _continueOrderingScreenId: number; 
  public get continueOrderScreenId(): number {
    return this._continueOrderingScreenId;
  }
  public set continueOrderScreenId(value: number) {
    this._continueOrderingScreenId = value
  }
  
  private _kioskScreens: Array<KioskScreen> = [];
  public get kioskScreens(): Array<KioskScreen> {
    return this._kioskScreens;
  }
  public set kioskScreens(value: Array<KioskScreen>) {
    this._kioskScreens = value;
  }
  
  private _shakeFlavorGroups: ShakeFlavorGroup[];
  public get shakeFlavorGroups(): ShakeFlavorGroup[] {
    return this._shakeFlavorGroups;
  }
  public set shakeFlavorGroups(value: ShakeFlavorGroup[]) {
    this._shakeFlavorGroups = value;
  }
  
  private _createdShake: CreatedShake;
  public get createdShake(): CreatedShake {
    return this._createdShake;
  }
  public set createdShake(value: CreatedShake) {
    this._createdShake = value;
  }
  
  private _aiShakeGenerationCount: number = 0;
  public get aiShakeGenerationCount(): number {
    return this._aiShakeGenerationCount;
  }
  public set aiShakeGenerationCount(value: number) {
    this._aiShakeGenerationCount = value;
  }
  
  private _shakeDownTimeTimeout: NodeJS.Timeout;
  public get shakeDownTimeTimeout(): NodeJS.Timeout {
    return this._shakeDownTimeTimeout;
  }
  public set shakeDownTimeTimeout(value: NodeJS.Timeout) {
    this._shakeDownTimeTimeout = value;
  }

  private _isServeAllTogether: boolean;
  public get isServeAllTogether(): boolean {
    return this._isServeAllTogether;
  }
  public set isServeAllTogether(value: boolean) {
    this._isServeAllTogether = value;
  }

}
