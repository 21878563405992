import { Component, OnInit, EventEmitter, Input, Output, OnDestroy } from '@angular/core';
import * as _ from 'lodash';

import { Observable, Subject, Subscription, from, of, forkJoin, fromEvent } from 'rxjs';
import {
  RuntimeConstants, DomainConstants, AlertsService, ApplicationStateService, SettingParam, Messages, Configurations, RabbitMQService,
  OrderService, CommentWarningService, InventoryProductDietaryWarningService, ExternalApiService, LoggerService,
  ButtonBehavior, InventoryProductService, UserDetails, NumpadWrapperComponent, ServeMethod, HttpResponseUtilsService, orderProductCheckMark, orderProductCircle, circleRegular, CameraAnnotationService, CameraAnnotationObject, StringUtilityService,
  ParameterService,
  Account
} from 'src/app/shared';
import { INavigationAction } from 'src/app/shared/interface/navigation-action';
import { flatMap, filter, finalize, map } from 'rxjs/operators';
import { SetOrderTypeComponent } from '../set-order-type';
import { TerminalsService } from 'src/app/configurator';
import { Layout } from '../../interface/layout';
import { SetAccountComponent } from 'src/app/set-account/set-account.component';
import { cog, minusSquare, orderButtonBan, checkSquare, navigateBackward, exclamationTriangleRed, clock } from 'src/app/shared/components/icon';
import { InfoModalComponent } from 'src/app/shared/components/info-modal/info-modal.component';
import { MenuResolveFactoryService } from '../../services/menu-resolve-factory.service';
import { MenuOptions } from '../../interface/button-menu';
import { AssignPagerComponent } from '../special-functions/assign-pager/assign-pager.component';
import { ManualDiscountComponent } from '../special-functions/manual-discount/manual-discount.component';
import { TerminalDetails } from '../../interface/terminal-details';
import { SalesProductInfo } from '../../interface/sales-product-info';
import { ScreenBehavior } from '../../interface/screen-behavior';
import { OrderEntryInfoModeComponent } from '../order-entry-info-mode/order-entry-info-mode';
import { ScreenButtons } from '../../interface/screen-buttons';
import { VersionedObject } from '../versioned-object';
import { SalesProductDefault } from '../../interface/sales-product-default';
import { ButtonFunctionType } from 'src/app/shared/interface/button-function-type';

import { TimeEntryComponent } from '../special-functions/time-entry/time-entry.component';
import { CommentWarning, DietaryWarning, SalesProductBarcodeService, SalesProductBarcode } from 'src/app/information-management';
import { OrderNavigationButton } from '../../interface/order-navigation-button';
import { KeyboardComponent } from 'src/app/keyboard';
import { ModalService } from 'src/app/shared/components/modal';
import { SpinnerService } from 'src/app/shared/components/spinner';
import { OrderEventBroadcastingService } from 'src/app/shared/services/order-event-broadcasting.service';
import { OrderInvoice, OrderDietaryRestriction, OrderProduct } from '../../interface';
import { LayoutDesignersService } from 'src/app/shared/services/layout-designers.service';
import { TableSelectionComponent } from '../../table-selection/table-selection.component';
import { OrderDietaryRestrictionComponent } from '../order-dietary-restriction/order-dietary-restriction.component';
import { OrderVersionVal } from '../../interface/order-version-val';

import { cloneDeep, forEach, orderBy, some, flatMap as lodashFlatMap } from 'lodash';
import { QuantityChangePopupComponent } from 'src/app/shared/components/quantity-change-popup/quantity-change-popup.component';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ManagesService } from 'src/app/shared/services/manages.service';
declare let $: any;
import { StringUtils } from 'src/app/shared/string-utils/string-utils';
import { ButtonWarningsAndTagsComponent } from '../button-warnings-and-tags/button-warnings-and-tags.component';
import { SignInComponent } from '../sign-in/sign-in.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ButtonNavigationService } from 'src/app/shared/services/button-navigation.service';
import { MenuActions } from '../../constants';
import { AudioOperationsService } from '../../../shared/services/audio-operations.service';
import { DeductInventoryProductComponent } from '../deduct-inventory-product/deduct-inventory-product/deduct-inventory-product.component';
import { DecimalPipe } from '@angular/common';
import { ScannedProduct, ScannedProductOrder } from '../../interface/scanned-sales-product';
import { BarcodeProductSelectionComponent } from 'src/app/information-management/sales-products/component/barcode-product-selection/barcode-product-selection.component';
import { PhoneNumberNumpadWrapperComponent } from 'src/app/shared/components/numpad/phone-number-numpad-wrapper/phone-number-numpad-wrapper.component';
import { SelectAiShakeComponent } from '../select-ai-shake/select-ai-shake.component';
@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'pos-order-navigation',
  templateUrl: './order-navigation.component.html',
  styleUrls: ['./order-navigation.component.scss']
})
export class OrderNavigationComponent implements OnInit, OnDestroy {

  navigationStartOn: number;
  navigationEndOn: number;
  @Input() infoMode: boolean = false;
  @Input() orderId: number = 0;
  @Output() infoModeChange = new EventEmitter<boolean>();
  public icons = {
    cog,
    orderButtonBan,
    minusSquare,
    checkSquare,
    navigateBackward,
    exclamationTriangleRed,
    orderProductCheckMark,
    orderProductCircle,
    circleRegular,
    clock
  };
  buttonBehaviorTypes = DomainConstants.ButtonBehaviors;
  searchButtons: Array<OrderNavigationButton> = [];
  filteredButtons: Array<OrderNavigationButton> = [];
  allButtons: Array<OrderNavigationButton> = [];
  commentWarnings: Array<CommentWarning> = [];
  defaultProducts: Array<SalesProductDefault> = [];
  salesProductInfo: Array<SalesProductInfo> = [];
  screenBehaviors: Array<ScreenBehavior> = [];
  eventsSubscription: Array<Subscription> = [];
  orderEntryRabbitMQSubscriptions: Array<Subscription> = [];
  isContinueMultipleProductNavigation: boolean = false;
  multipleProductButtonId: number;
  multipleProductGroupId: number;
  screenNavigationTracker: Array<ScreenButtons> = [];
  orderNavigation: ScreenButtons;
  imageSize: string;
  isOrderInitiate: boolean = false;
  searchValue: string;
  promptPromise: Promise<string>;
  orderPromise: Promise<number>;
  dietaryPromise: Promise<Array<OrderDietaryRestriction>>;
  layoutPromise: Promise<number>;
  functionParameters: any = [];
  deliveryTypeId: number;
  homeScreenId: number;
  priceAdjust: number = 0;
  dineInTypeId: number;
  onlineOrderId: number;
  isTrainingOrder: boolean = false;
  orderNavigationHeight: string;
  serveMethods: Array<ServeMethod> = [];
  orderCommentValue: string = '';
  cartOrderDataAccountDetails: any = {};
  subaccountOrdinal: number = 1;
  cartOrderDataDetails: any = [];
  designMode: boolean = false;
  mappedLayout: Array<any> = [];
  initiateOrderProduct: OrderProduct = null;
  initiateScannedOrderProduct: ScannedProduct = null;
  initiateOrderCommand = null;
  buttonFunctions = DomainConstants.ButtonFunctionTypes;
  userDetails: UserDetails;
  accountVisualizations: Array<any> = [];
  customerAccountId: number = null;
  accountId: number = null;
  aiShakeId: string;
  aiShakeButtonId: number;


  resolvePromptPromise: (value?: string | PromiseLike<string>) => void;
  rejectPromptPromise: (reason?: any) => void;

  resolveDietaryRestrictionPromise: (value?: Array<OrderDietaryRestriction> | PromiseLike<Array<OrderDietaryRestriction>>) => void;
  rejectDietaryRestrictionPromise: (reason?: any) => void;

  resolveLayoutTableSelectionPromise: (value?: number | PromiseLike<number>) => void;
  rejectLayoutTableSelectionPromise: (reason?: any) => void;

  resolveOrderPromise: (value?: string | PromiseLike<number>) => void;
  rejectOrderPromise: (reason?: any) => void;

  resolveSignInPromise: (value?: any) => void;
  rejectSignInPromise: (reason?: any) => void;

  orderDetail: OrderInvoice;
  screenButtons: Array<ScreenButtons> = [];
  roundTripParentScreen: ScreenButtons;
  roundTripButton: OrderNavigationButton;
  startScreenId: number;
  orderKeyboardOptions = { isOpen: false, capsLock: false, title: 'Enter order name', maxLength: 90 };
  isOpen = false;
  isOpenCommentEntry: boolean = false;
  commentEntryKeyboardOptions = { isOpen: false, capsLock: false, title: 'Enter comment', maxLength: 90 };
  keyboardValue: string = '';
  settingParam: SettingParam;
  versionedVal = new VersionedObject<OrderVersionVal>({ Order: this.orderService.newOrder(), ScreenButtons: this.orderService.newScreenButtons() });
  mappedTerminals: Array<TerminalDetails> = [];
  dietaryWarnings: Array<DietaryWarning> = [];
  appStateConfigurations: Configurations;

  /* Tab or Table Id store in accountId. */


  /* Account(Member) id store in customerAccountId. */

  tableLayouts: Array<Layout> = [];
  public imageRootPath = RuntimeConstants.IMAGE_BASE_PATH + "/buttons";

  /* button designing variables */
  buttonSize: string;
  buttonHeight: string;
  buttonWidth: string;

  /* menu opening variables */
  oldMenuIndex: number;

  /* button menu option variable */
  buttonMenuOptions: Array<MenuOptions>;
  buttonMenuOptionsConstants = DomainConstants.ButtonMenu;
  menuActions = MenuActions;

  private navigationSubject: Subject<INavigationAction> = new Subject<INavigationAction>();
  private get navigation$(): Observable<INavigationAction> {
    return this.navigationSubject.asObservable();
  }

  screenTrackLimit: number = 1;
  isShowCliTerminal: boolean = false;
  screenWidth = $(window).width();
  filteredData = null;
  isMobile = false;
  isShowCheckmarkOnToggleButton: boolean;
  // rabbitMq subscriptions
  rabbitMqTerminalWarningSubscription: Subscription;
  rabbitMqOrderEntryTerminalQueueSubscription: Subscription;
  rabbitMqSalesProductOutOfStockEventSubscription: Subscription;
  rabbitMqSalesProductReplenishedEventSubscription: Subscription;
  rabbitMqCashDrawerClosedEventSubscription: Subscription;
  rabbitMqCashDrawerOpenedEventSubscription: Subscription;
  rabbitMqRequireNameOnOrderTemporaryDisableSubscription: Subscription;
  phoneNumberModalCloseRabbitMQSubscription: Subscription;
  cameraAnnotations: CameraAnnotationObject;
  screens: Array<any> = [];
  salesProductBarcodes: Array<SalesProductBarcode> = [];
  configurations: Configurations;
  constructor(private orderService: OrderService,
    private alertService: AlertsService,
    private modalService: ModalService,
    private spinnerService: SpinnerService,
    private terminalService: TerminalsService,
    private externalApiService: ExternalApiService,
    private applicationStateService: ApplicationStateService,
    private commentWarningService: CommentWarningService,
    private rabbitMQService: RabbitMQService,
    private eventBroadcastingService: OrderEventBroadcastingService,
    private menuComponentResolveFactoryService: MenuResolveFactoryService,
    private manageService: ManagesService,
    private inventoryProductDietaryWarningService: InventoryProductDietaryWarningService,
    private layoutDesignerService: LayoutDesignersService,
    private inventoryProductService: InventoryProductService,
    private loggerService: LoggerService,
    private buttonNavigationService: ButtonNavigationService,
    private deviceDetectorService: DeviceDetectorService,
    private httpResponseUtilService: HttpResponseUtilsService,
    private cameraAnnotationService: CameraAnnotationService,
    private stringUtilityService: StringUtilityService,
    public audioOperationsService: AudioOperationsService,
    public barcodeService: SalesProductBarcodeService,
    public parameterService: ParameterService,
    private decimalPipe: DecimalPipe
  ) {
    this.getHeight();
    this.versionedVal.Obj.Order = this.orderService.newOrder();
    this.orderNavigation = this.orderService.newScreenButtons();
    this.buttonMenuOptions = menuComponentResolveFactoryService.getButtonMenu();
    this.isMobile = this.deviceDetectorService.isMobile()
  }


  ngOnInit() {
    this.orderDetail = this.orderService.getNewOrderInvoiceDetails();
    this.getRequiredData();
    this.setDefaults();
    if (this.applicationStateService.statusCloseDrawer === null) {
      this.getDrawerStatus();
    }
    this.setEvents();
    this.isTrainingOrder = this.applicationStateService.sessionTrainingMode != null ? JSON.parse(this.applicationStateService.sessionTrainingMode) : false;
    this.resizeWindow();
    this.cameraAnnotations = this.applicationStateService.cameraAnnotations;
    this.configurations = this.applicationStateService.configurations;
  }


  resizeWindow() {
    window.onresize = () => {
      this.setButtonSize();
    };
  }

  ngOnDestroy() {
    _.forEach(this.eventsSubscription, (subscribe) => {
      if (subscribe) {
        subscribe.unsubscribe();
      }
    });
  }
  ngAfterViewInit(): void {
    if (this.designMode) {
      const content = document.querySelector('.tab-content');
      const scroll$ = fromEvent(content, 'scroll').pipe(map(() => content));
      scroll$.subscribe(() => {
        this.calculatePosition();
      });
    }
  }

  setEvents() {
    $(window).resize(() => {
      this.screenWidth = $(window).width();
      this.getHeight();
      this.changeBreadcrumbs();
    });
    $(document).click(() => {
      if (this.designMode) {
        $('.custom-order').css('display', 'none');
        this.oldMenuIndex = null;
      }
    });
  }

  setDefaults() {
    this.settingParam = this.applicationStateService.settingParam;
    this.appStateConfigurations = this.applicationStateService.configurations;
    this.designMode = JSON.parse(this.applicationStateService.sessionDesignMode);
    this.userDetails = this.applicationStateService.userDetails;
    this.isShowCheckmarkOnToggleButton = this.settingParam.IsShowCheckmarkOnToggleButton;
  }

  getRequiredData() {
    this.getServeMethodIds();
    this.subscribeToNavigation();
    this.getServeMethods();
    this.getMappedLayoutWithTerminal();
    this.getOrderNavigationDetails();
    this.getMappedTerminals();
    this.getFunctionParams();
    this.getSalesProductDefaults();
    this.getSalesProductsInfo();
    this.getCommentWarnings();
    this.getDietaryWarnings();
    this.getScreenData();
    this.getBarcodes();
    this.subscribeRequiredExchanges();
    this.getRequireNameOnOrderTemporaryDisableValue();
  }

  subscribeRequiredExchanges() {
    this.subscribeToSalesProductOutOfStockEventExchange();
    this.subscribeToSalesProductReplenishedEventExchange();
    this.subscribeToOrderEntryTerminalBroadcastQueue();
    this.subscribeMenuChangeClick();
    this.subscribeToRequireNameOnOrderTemporaryDisableParameterChangeMessage();
    this.subscribeStartNewOrder();
    this.subscribeActiveOrderClick();
    this.subscribeReloadHomeState();
    this.subscribeSeatSelect();
    this.subscribeOrderReload();
    this.subscribeCashDrawerEventExchange();
    this.subscribeSetAccount();
  }

  subscribeCashDrawerEventExchange() {
    this.rabbitMqCashDrawerClosedEventSubscription = this.rabbitMQService.subscribeToCashDrawerClosedEventExchange$()
      .subscribe((message: any) => {
        if (message.Payload.POSEvent) {
          if (this.settingParam.CashDrawerPrinter.Id > 0 && message.Payload.POSEvent.DrawerId == this.settingParam.CashDrawerPrinter.Id) {
            this.applicationStateService.statusCloseDrawer = true;

          }
        }
      });
    this.rabbitMqCashDrawerOpenedEventSubscription = this.rabbitMQService.subscribeToCashDrawerOpenedEventExchange$()
      .subscribe((message: any) => {
        if (message.Payload.POSEvent) {
          if (this.settingParam.CashDrawerPrinter.Id > 0 && message.Payload.POSEvent.DrawerId == this.settingParam.CashDrawerPrinter.Id) {
            this.applicationStateService.statusCloseDrawer = false;
          }
        }
      });
  }

  subscribeOrderReload = () => {
    this.eventsSubscription.push(this.eventBroadcastingService.reloadOrder.subscribe((orderDetail: OrderInvoice) => {
      this.orderDetail = orderDetail;
      this.orderId = orderDetail?.Id?.Value;
    }));
  }

  subscribeSetAccount() {
    this.eventsSubscription.push(this.eventBroadcastingService.setAccountCompleted.subscribe((res: Account) => {
      if (!this.orderId) {
        if (res.AccountType === DomainConstants.AccountTypes.Account) {
          this.versionedVal.Obj.Order.CustomerAccountId = res.Id;
          this.customerAccountId = res.Id;
        } else {
          this.versionedVal.Obj.Order.AccountId = res.Id;
          this.accountId = res.Id;
        }
      }
    }));
  }


  subscribeActiveOrderClick = () => {
    this.eventsSubscription.push(this.eventBroadcastingService.activeOrderClicked.subscribe((orderId) => {
      this.orderId = orderId;
    }));
  }

  subscribeSeatSelect = () => {
    this.eventsSubscription.push(this.eventBroadcastingService.selectSeat.subscribe((seatId) => {
      this.subaccountOrdinal = seatId;
    }));
  }

  subscribeStartNewOrder() {
    this.eventsSubscription.push(this.eventBroadcastingService.newOrder.subscribe(() => {
      this.orderId = 0;
      this.subaccountOrdinal = 1;
      this.completeNavigation();
    }));
  }

  subscribeReloadHomeState() {
    this.eventsSubscription.push(this.eventBroadcastingService.reloadHomeState.subscribe(() => {
      this.completeNavigation();
    }));
  }

  showInfoMessage(message) {
    const modalRef = this.modalService.show(InfoModalComponent, {
      animated: false,
      class: 'vertical-center',
      initialState: {
        message: message
      }
    });
  }

  getCommentWarnings() {
    this.spinnerService.show();
    this.commentWarningService.getCommentWarnings()
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next:
          response => {
            this.commentWarnings = response;
          }, error: this.alertService.showApiError
      });
  }

  getDrawerStatus() {
    if (this.settingParam.CashDrawerPrinter && this.settingParam.CashDrawerPrinter.Id) {
      this.spinnerService.show();
      this.manageService.getDrawerStatus(this.settingParam.CashDrawerPrinter.Id)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: (res) => {
            this.applicationStateService.statusCloseDrawer = (res.action !== "Open");
          }, error: this.alertService.showApiError
        });
    }
  }

  getDietaryWarnings() {
    this.spinnerService.show();
    this.inventoryProductDietaryWarningService.getDietaryWarnings()
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: response => {
          this.dietaryWarnings = response;
        }, error: this.alertService.showApiError
      });
  }

  getBarcodes() {
    this.spinnerService.show();
    this.barcodeService.getAll()
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: response => {
          this.salesProductBarcodes = response;
        }, error: this.alertService.showApiError
      });
  }

  getSalesProductsInfo() {
    this.spinnerService.show();
    this.orderService.getSalesProductsInfo()
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (response) => {
          if (response) {
            _.forEach(response, (productInfo) => {
              this.salesProductInfo[productInfo.SalesProductId] = productInfo;
            });
          }
        }, error: this.alertService.showApiError
      });
  }

  getMappedTerminals(): void {
    this.spinnerService.show();
    this.orderService.getMappedTerminalsByType(this.applicationStateService.terminalId, "Make Table")
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (response: any) => {
          this.mappedTerminals = response ? response : [];
        }, error: this.alertService.showApiError
      });
  }

  getServeMethodIds() {
    const dinein = _.find(DomainConstants.PromptServeMethods, (item) => {
      return item.Name == "Dine in";
    });
    const delivery = _.find(DomainConstants.PromptServeMethods, (item) => {
      return item.Name == "Delivery";
    });
    const online = _.find(DomainConstants.PromptServeMethods, (item) => {
      return item.Name == "Online";
    });
    if (!!delivery.id)
      this.deliveryTypeId = parseInt(delivery.id);
    if (!!dinein.id)
      this.dineInTypeId = parseInt(dinein.id);
    if (!!online.id)
      this.onlineOrderId = parseInt(online.id);
  }

  getServeMethods() {
    this.spinnerService.show();
    this.orderService.getServeMethods(this.applicationStateService.terminalId, 'OrderPrompts')
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (response: Array<ServeMethod>) => {
          _.forEach(response, (item, $index) => {
            if (this.onlineOrderId === Number($index)) {
              delete response[$index];
            }
          });
          this.serveMethods = response;
        }, error: this.alertService.showApiError
      });
  }

  setButtonSize = () => {
    const areaWidth = $('.order-section__products__block').width();
    this.screenButtons = this.buttonNavigationService.setButtonSize(this.screenButtons, areaWidth);
  }

  getOrderNavigationDetails(screenId: number = null, loadScreens: boolean = false) {
    this.spinnerService.show();
    this.getFunctionParams();
    this.orderService.getOrderNavigationDetails()
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (data: ScreenButtons[]) => {
          this.screenButtons = data;
          this.filterExcludedButtons();
          this.setButtonSize();
          this.setHomeScreenId(screenId);
          this.setAllButtons();
          if (screenId) {
            const selectedCurrentNavigation = cloneDeep(this.orderNavigation);

            this.orderNavigation = this.screenButtons[screenId] ? cloneDeep(this.screenButtons[screenId]) : this.orderNavigation;
            _.forEach(selectedCurrentNavigation.Buttons, (selectedButton) => {
              _.forEach(this.orderNavigation.Buttons, (currentButton) => {
                if (currentButton.ScreenChoiceId === selectedButton.ScreenChoiceId && selectedButton.Toggle) {
                  currentButton.IsSelected = selectedButton.IsSelected;
                  currentButton.IsDefault = selectedButton.IsDefault;
                  this.setButtonBgColor(currentButton);

                }
              });
            });
          } else if (this.homeScreenId) {
            if (this.screenButtons[this.homeScreenId]?.Screen) {
              const homeScreen = { id: this.screenButtons[this.homeScreenId]?.Screen.Id, Screen: this.screenButtons[this.homeScreenId]?.Screen.Name };
              this.screens = [homeScreen, ...this.screens];
            }
            this.orderNavigation = this.screenButtons[this.homeScreenId] ? cloneDeep(this.screenButtons[this.homeScreenId]) : this.orderNavigation;
          }
          this.scrollTop();
          this.setIsSearch();
          this.validateButtonVisibility();
          this.setNavigationFromBehaviors();
          if (screenId == this.homeScreenId) {
            this.navigateToHomeScreen();
          }
          if (loadScreens) {
            const homeScreen = { id: this.screenButtons[this.homeScreenId]?.Screen.Id, Screen: this.screenButtons[this.homeScreenId]?.Screen.Name };
            this.screens = [homeScreen, ...this.screens];
          }
          this.spinnerService.hide();
        }, error: (err) => {
          this.alertService.showApiError(err);
          this.spinnerService.hide();
        }
      });
  }

  private setHomeScreenId(screenId: number) {
    if (!this.homeScreenId && !screenId) {
      let homeScreenButtons = _.find(this.screenButtons, (screenButton) => screenButton.Screen.StartForm);
      if (homeScreenButtons) {
        this.homeScreenId = homeScreenButtons.Screen.Id;
      }
    }
  }

  private filterExcludedButtons() {
    let excludedButtons = this.applicationStateService.userPermissions.ButtonExclusions.map(x => x.ChoiceId);
    if (excludedButtons.length) {
      forEach(this.screenButtons, x => x.Buttons = x.Buttons.filter(y => !excludedButtons.includes(y.Id)));
    }
  }

  setNavigationFromBehaviors() {
    _.forEach(this.orderNavigation.Buttons, (currentButton) => {
      if (currentButton.Behaviors?.length) {
        const tempNavigationBehavior = _.find(currentButton.Behaviors, (behavior: ButtonBehavior) => {
          return behavior.BehaviorName === DomainConstants.ButtonBehaviors.TemporaryNav.Value;
        });

        if (tempNavigationBehavior) {
          const behaviorValues = JSON.parse(tempNavigationBehavior.TextValue);
          currentButton.StackScreenId = behaviorValues.screenId;
        }
      }
    });
  }
  setAllButtons() {
    this.allButtons = [];
    _.forEach(this.screenButtons, (screenButton) => {
      _.forEach(screenButton.Buttons, (button) => {
        if (button.SalesProductTags && button.SalesProductTags.length > 0) {
          forEach(button.SalesProductTags, (salesProductTag) => {
            if (!button.SearchableTags) {
              button.SearchableTags = "";
            }
            if (salesProductTag?.Tag?.IsSearchable) {
              button.SearchableTags += salesProductTag?.Tag.Name;
            }
          });
        }
        button.IsInStock = this.buttonNavigationService.setButtonInStock(button);
        this.allButtons.push(button);
      });
      this.buttonNavigationService.setEmbeddedScreenOrdinal(screenButton.Buttons);
    });
  }

  getSalesProductDefaults() {
    this.spinnerService.show();
    this.orderService.getSalesProductDefaults()
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (data: Array<SalesProductDefault>) => {
          this.defaultProducts = data;
        }, error: this.alertService.showApiError
      });
  }

  getScreenData(isNewScreenAdded = false, newScreenId = null) {
    this.spinnerService.show();
    this.orderService.getScreenData(-1)
      .pipe(finalize(() => this.spinnerService.hide()))
      .subscribe({
        next: res => {
          this.screens = res?.LoadScreenModel ? res.LoadScreenModel : [];
          if (newScreenId) {
            this.getOrderNavigationDetails(newScreenId, isNewScreenAdded);
          }
        }, error: this.alertService.showApiError
      });
  }
  selectDefaultProducts() {
    //Select default selection which configured in sales product
    let selectedProducts = this.versionedVal.getLastCommittedVersion();
    if (selectedProducts.Order?.Product?.Components.length) {
      const productsWithDefaultSelections = this.defaultProducts.filter(x => (selectedProducts.Order.Product.Components.map(x => x.SalesProductId)).includes(x.SalesProductId));
      for (let button of this.orderNavigation.Buttons) {
        if (button.SalesProductId) {
          const defaultButton = productsWithDefaultSelections.find(x => x.SelectedSalesProductId == button.SalesProductId);
          if (defaultButton) {
            button.IsDefault = true;
            button.IsSelected = true;
            this.setButtonBgColor(button);
          }
        }
      }
    }
  }

  selectDefaultButtons() {
    //Select default selection which configured in button edit(pos_screen_choices)
    _.forEach(this.orderNavigation.Buttons, (button) => {
      if (button.IsDefaultChecked && button.IsVisible) {
        this.buttonClick(button);
      }
    });
  }

  subscribeToSalesProductOutOfStockEventExchange() {
    this.rabbitMqSalesProductOutOfStockEventSubscription = this.rabbitMQService.subscribeToSalesProductOutOfStockEventExchange$()
      .subscribe((message: any) => {
        if (message.Payload.POSEvent) {
          this.setSalesProductStockStatus(message.Payload.POSEvent.SalesProductId, message.Payload.POSEvent.SizeIds, false);
        }
      });
  }

  subscribeToRequireNameOnOrderTemporaryDisableParameterChangeMessage() {
    this.rabbitMqRequireNameOnOrderTemporaryDisableSubscription = this.rabbitMQService.subscribeToRequireNameOnOrderTemporaryDisableParameterChangeMessage$()
      .subscribe((message: any) => {
        if (message.Payload) {
          this.settingParam.RequireNameOnOrderTemporaryDisable = message.Payload.Message == "true" ? true : false;
        }
      });
  }

  subscribeToSalesProductReplenishedEventExchange() {
    this.rabbitMqSalesProductReplenishedEventSubscription = this.rabbitMQService.subscribeToSalesProductReplenishedEventExchange$()
      .subscribe((message: any) => {
        if (message.Payload.POSEvent) {
          this.setSalesProductStockStatus(message.Payload.POSEvent.SalesProductId, message.Payload.POSEvent.SizeIds, true);
        }
      });
  }

  subscribeToOrderEntryTerminalBroadcastQueue() {
    this.rabbitMqTerminalWarningSubscription = this.rabbitMQService.subscribeToTerminalWarningMessage$()
      .subscribe({
        next: (message: any) => {
          this.eventBroadcastingService.terminalWarning.emit(message.Payload);
        }, error: () => {
          console.log("error while connecting to RabbitMQ.");
        }
      });
  }

  subscribeMenuChangeClick = () => {
    this.eventsSubscription.push(this.eventBroadcastingService.menuChangeClicked.subscribe((res) => {
      this.getOrderNavigationDetails(null);
      this.completeNavigation();
    }));
  }

  setSalesProductStockStatus = (productId: number, sizeIds: Array<number>, status: boolean) => {
    // Update IsInStock flag in main (all buttons list) object
    _.forEach(this.screenButtons, (screenButton) => {
      _.forEach(screenButton.Buttons, (button) => {
        this.setInStockForDefaultSize(button, productId, sizeIds, status);
      });
    });

    // Update IsInStock flag in visible screen's button list object
    _.forEach(this.orderNavigation.Buttons, (button) => {
      this.setInStockForDefaultSize(button, productId, sizeIds, status);
    });

    this.setBarcodeProductStatus(productId, sizeIds, status);
  }

  private setBarcodeProductStatus(productId: number, sizeIds: number[], status: boolean) {
    const productBarcode = this.salesProductBarcodes.find(x => x.SalesProductId == productId && sizeIds.includes(x.SalesSizeId));
    if (productBarcode) {
      forEach(sizeIds, size => {
        const productSize = productBarcode.SalesProduct?.SalesProductSizes?.find(x => x.SizeId == size);
        if (productSize) {
          productSize.IsInStock = status;
        }
      });
    }
  }

  setInStockForDefaultSize(button: OrderNavigationButton, productId: number, sizeIds: Array<number>, status: boolean) {
    if (button.SalesProductId === productId && sizeIds?.some((size) => size === (this.versionedVal?.Obj?.Order?.Product?.SizeId ?
      this.versionedVal.Obj.Order.Product.SizeId : button.DefaultSizeId))) {
      button.IsInStock = status;
      this.buttonNavigationService.setSalesProductSizeInStock(button, status, sizeIds);
    }
    else if (button.SalesProductId === productId) {
      this.buttonNavigationService.setSalesProductSizeInStock(button, status, sizeIds);
    }
  }
  getMappedLayoutWithTerminal() {
    const layoutObservable = [];
    let terminalConfig;
    layoutObservable.push(this.terminalService.getTerminalProperties(this.applicationStateService.terminalId));
    layoutObservable.push(this.layoutDesignerService.getAll());
    layoutObservable.push(this.layoutDesignerService.getAccountVisualizations());
    this.spinnerService.show();
    forkJoin(layoutObservable)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (responses: any) => {
          if (responses) {
            terminalConfig = responses[0] ? responses[0] : [];
            const layouts = [];
            if (responses[1]) {
              _.forEach(responses[1], (layout) => {
                if (layout.IsActive) {
                  const selectedLayout = {
                    id: layout.Id,
                    label: layout.Name
                  };
                  layouts.push(selectedLayout);
                }
              });
            }
            if (responses[2]) {
              this.accountVisualizations = responses[2];
            }
            let availableLayouts;
            _.forEach(terminalConfig, (terminalProperty) => {
              if (DomainConstants.TerminalProperties.HOME_SCREEN_ID.Key == terminalProperty.PropertyKey) {
                this.homeScreenId = terminalProperty.PropertyValue;
              }
              if (DomainConstants.TerminalProperties.TABLE_LAYOUT.Key === terminalProperty.PropertyKey) {
                availableLayouts = terminalProperty.PropertyValue.split(',').map((item) => {
                  return parseInt(item, 10);
                });
              }
            });
            _.forEach(availableLayouts, (item) => {
              const currentLayout = _.find(layouts, (layout) => {
                return layout.id == item;
              });
              if (currentLayout) {
                this.mappedLayout.push(item);
              }
            });
          }
        }, error: this.alertService.showApiError
      });
  }

  private getFunctionParams() {
    this.orderService.getButtonFunctionParameters(null)
      .subscribe({
        next: (parameters) => {
          this.functionParameters = parameters;
        }
      });
  }

  private getRequireNameOnOrderTemporaryDisableValue() {
    this.spinnerService.show();
    this.parameterService.getParameterForName('RequireNameOnOrderTemporaryDisable')
      .pipe(finalize(() => this.spinnerService.hide()))
      .subscribe({
        next: (response) => {
          this.applicationStateService.settingParam.RequireNameOnOrderTemporaryDisable = response?.value == 'true' ? true : false;
        },
        error: this.alertService.showApiError,
      })
  }

  private isValidNavigation = (action: INavigationAction) => Boolean(action) && !action.isCancelled;
  private currentNavigation: Subscription;

  private navigationSideEffect = (action: (navigationAction: INavigationAction, index?) => Observable<INavigationAction>) => (source: Observable<INavigationAction>) =>
    source.pipe(
      flatMap(action),
      filter(this.isValidNavigation)
    );

  private orderCommandSideEffect = (action: (navigationAction: INavigationAction, index?) => Observable<INavigationAction>) => (source: Observable<INavigationAction>) =>
    source.pipe(
      this.navigationSideEffect(x => {
        let navigationAction = action(x);
        return navigationAction;
      }));

  private specialFunctionSideEffect = (specialFunctionType: ButtonFunctionType, action: (navigationAction: INavigationAction) => Observable<INavigationAction>) => (source: Observable<INavigationAction>) =>
    source.pipe(
      this.navigationSideEffect(x => {
        if (x.button.SpecialFunction === specialFunctionType.Name) {
          return action(x);
        }
        return of(x);
      }));

  private completeNavigation(nextScreenId: number = null) {
    this.navigationSubject.complete();
    this.currentNavigation.unsubscribe();
    this.navigationSubject = new Subject<INavigationAction>();
    this.subscribeToNavigation();
    if (nextScreenId > 0) {
      this.orderNavigation = this.screenButtons[nextScreenId] ? cloneDeep(this.screenButtons[nextScreenId]) : this.orderService.newScreenButtons();
      this.scrollTop();
      this.validateButtonVisibility();
      this.setNavigationFromBehaviors();
      this.screenNavigationTracker = [];
      this.screenNavigationTracker.push(this.screenButtons[this.homeScreenId]);
      this.setIsSearch();
      this.isContinueMultipleProductNavigation = true;
    } else {
      this.navigateToHomeScreen();
      this.multipleProductButtonId = null;
      this.aiShakeId = null;
      this.aiShakeButtonId = null;
    }
    this.versionedVal = new VersionedObject<OrderVersionVal>({ Order: this.orderService.newOrder(), ScreenButtons: this.orderService.newScreenButtons() });
    this.versionedVal.Obj.Order = this.orderService.newOrder();
  }

  navigateToHomeScreen() {
    if (this.homeScreenId) {
      this.orderNavigation = this.screenButtons[this.homeScreenId] ? cloneDeep(this.screenButtons[this.homeScreenId]) : this.orderService.newScreenButtons();
    } else {
      this.orderNavigation = this.orderService.newScreenButtons();
    }
    this.scrollTop();
    this.validateButtonVisibility();
    this.setNavigationFromBehaviors();
    this.screenNavigationTracker = [];
    this.isContinueMultipleProductNavigation = false;
    this.setIsSearch();
  }

  toPromise<T>(event: EventEmitter<T>): Promise<T> {
    return new Promise<T>((res, rej) => {
      event.subscribe(data => res(data), data => rej(data));
    });
  }

  private subscribeToNavigation() {
    this.currentNavigation = this.navigation$
      .pipe(
        this.specialFunctionSideEffect(DomainConstants.ButtonFunctionTypes.NON_FUNCTIONAL, (x => this.nonFunctional(x))),
        this.navigationSideEffect(x => this.processInfoMode(x)),
        this.navigationSideEffect(x => this.checkIsInStock(x)),
        this.navigationSideEffect(x => this.validateScreenMinMax(x)),
        this.specialFunctionSideEffect(DomainConstants.ButtonFunctionTypes.ASSIGN_PAGER, (x => this.assignPager(x))),
        this.specialFunctionSideEffect(DomainConstants.ButtonFunctionTypes.CALL_API, (x => this.callExternalApi(x))),
        this.specialFunctionSideEffect(DomainConstants.ButtonFunctionTypes.COMMENT_ENTRY, (x => this.commentEntry(x, DomainConstants.SpecialFunctionBusinessCommand.AddComment))),
        this.specialFunctionSideEffect(DomainConstants.ButtonFunctionTypes.CUSTOMER_NAME, (x => this.commentEntry(x, DomainConstants.SpecialFunctionBusinessCommand.SetName))),
        this.specialFunctionSideEffect(DomainConstants.ButtonFunctionTypes.NUMERIC_DISCOUNT_MANUAL, (x => this.numericManual(x))),
        this.specialFunctionSideEffect(DomainConstants.ButtonFunctionTypes.NUMERIC_MARKUP, (x => this.numericMarkup(x))),
        this.specialFunctionSideEffect(DomainConstants.ButtonFunctionTypes.PERCENT_DISCOUNT, (x => this.percentDiscount(x))),
        this.specialFunctionSideEffect(DomainConstants.ButtonFunctionTypes.PERCENT_MARKUP, (x => this.percentMarkup(x))),
        this.specialFunctionSideEffect(DomainConstants.ButtonFunctionTypes.SET_SIZE, (x => this.setSize(x))),
        this.specialFunctionSideEffect(DomainConstants.ButtonFunctionTypes.TIME_ENTRY, (x => this.timeEntry(x))),
        this.specialFunctionSideEffect(DomainConstants.ButtonFunctionTypes.SET_PHONE_NUMBER, (x => this.setPhoneNumber(x))),
        this.specialFunctionSideEffect(DomainConstants.ButtonFunctionTypes.SET_DIETARY_RESTRICTION, (x => this.dietaryRestriction(x))),
        this.specialFunctionSideEffect(DomainConstants.ButtonFunctionTypes.REMOVE_TAX, (x => this.removeTax(x))),
        this.specialFunctionSideEffect(DomainConstants.ButtonFunctionTypes.ADD_COMMENT_TO_ALL, (x => this.addCommentToAll(x))),
        this.specialFunctionSideEffect(DomainConstants.ButtonFunctionTypes.DEDUCT_INVENTORY_PRODUCT, (x => this.openSelectInventoryToDeductModal(x))),
        this.navigationSideEffect(x => this.setOrderProductQty(x)),
        this.navigationSideEffect(x => this.handleToggleButton(x)),
        this.navigationSideEffect(x => this.setOrderProductObject(x)),
        this.navigationSideEffect(x => this.buttonBehavior(x)),
        this.specialFunctionSideEffect(DomainConstants.ButtonFunctionTypes.ADD_COMMENT, (x => this.addComment(x))),
        this.specialFunctionSideEffect(DomainConstants.ButtonFunctionTypes.ADD_COMMENT_UNRESTRICTED, (x => this.addComment(x))),
      )
      .subscribe({
        next: (response: INavigationAction) => {
          if (!response.button.StackScreenId) {
            this.setScreenButtons(response);
          }
          if (this.checkIsNavigationEnd(response)) {
            if (this.roundTripParentScreen && (this.roundTripButton.NextScreenId || this.roundTripButton.Toggle)) {
              let roundTripIndex = _.findIndex(this.screenNavigationTracker, (screenNavigation) => {
                return screenNavigation.Screen.Id == this.roundTripParentScreen.Screen.Id;
              });
              if (this.roundTripButton.NextScreenId) {
                this.orderNavigation = cloneDeep(this.screenButtons[this.roundTripButton.NextScreenId]);
                this.selectDefaultProducts();
                this.validateButtonVisibility();
                this.selectDefaultButtons();
                this.setNavigationFromBehaviors();
                this.screenNavigationTracker.splice(roundTripIndex + 1, this.screenNavigationTracker.length);
              } else {
                this.orderNavigation = cloneDeep(this.roundTripParentScreen);
                this.screenNavigationTracker.splice(roundTripIndex, this.screenNavigationTracker.length);
              }
              this.scrollTop();
              this.setIsSearch();
              this.versionedVal.commit(`v${this.versionedVal.LatestVersion}_${this.roundTripParentScreen.Screen.Id}`);
              this.versionedVal.sqaushIntoLastCheckpoint();
              this.roundTripParentScreen = null;
              this.roundTripButton = null;
            } else {
              this.addTheseItem();
              this.roundTripParentScreen = null;
              this.roundTripButton = null;
            }
          } else if (response.orderCommand?.type) {
            if (!(response.orderCommand.type == DomainConstants.SpecialFunctionBusinessCommand.AssignPager && !this.orderId)) {
              this.eventBroadcastingService.onAddItemStart.emit();
            }
            response.orderCommand.TerminalId = this.applicationStateService.terminalId;
            response.orderCommand.SubaccountOrdinal = this.subaccountOrdinal;
            if (!this.isOrderInitiate) {
              if (!this.orderId) {
                this.isOrderInitiate = true;
              }
              this.addSpecialFunction(response.orderCommand);
            } else {
              this.initiateOrderCommand = response;
            }
            if (response.orderCommand.type == DomainConstants.SpecialFunctionBusinessCommand.SetDiscount ||
              response.orderCommand.type == DomainConstants.SpecialFunctionBusinessCommand.SetMarkup) {
              this.completeNavigation();
            }
          }
        }
      });
  }

  addSpecialFunction(command) {
    if (!command.orderType) {
      const availableServeMethods = Object.keys(this.serveMethods).map(key => ({ ServeMethodId: key, value: this.serveMethods[key] }));
      command.orderType = availableServeMethods.length == 1 ? availableServeMethods[0].ServeMethodId : DomainConstants.ServeMethods.Carryout;
    }
    this.orderService.specialFunction(this.orderId, command)
      .pipe(finalize(() => {
        this.isOrderInitiate = false;
        this.eventBroadcastingService.hideInvoiceLoader.emit();
      }))
      .subscribe({
        next: (res: any) => {
          if (!this.orderId) {
            this.audioOperationsService.tagAudioRecord(DomainConstants.AudioInteractions.NewOrder, res.Id.Value);
          }
          this.orderId = res.Id.Value;
          this.orderDetail = res;
          this.onAddItemCompleted(res.Id.Value, this.stringUtilityService.displayName(command.type));
          this.checkInitiateOrderProduct();
        }, error: this.specialFunctionError
      });
  }

  onAddItemCompleted(orderId, item) {
    this.eventBroadcastingService.onAddItemComplete.emit(orderId);
    this.cameraAnnotationService.addAnnotationToCamera(this.cameraAnnotations.ItemAddedToInvoice, {
      ProductName: item,
      OrderId: this.orderDetail.SurrogateOrderId
    });
  }

  specialFunctionError = errorResponse => {
    const error = this.httpResponseUtilService.parseError(errorResponse);
    this.showInfoMessage(error.message);
    this.spinnerService.hide();
  }

  private checkIsNavigationEnd(response) {
    return !response.button.Toggle && !response.button.NextScreenId
      && !response.orderCommand?.type && !response.button.StackScreenId
      && response.button.SpecialFunction != DomainConstants.ButtonFunctionTypes.DEDUCT_INVENTORY_PRODUCT.Name;
  }

  private checkScreenToggles() {
    if (this.roundTripParentScreen) {
      let canAddNextButton = false;

      //_.every

      _.forEach(this.orderNavigation.Buttons, (button) => {
        if (!button.Toggle && !((some(button.Behaviors, { BehaviorName: DomainConstants.ButtonBehaviors.UncheckAllButtons.Value })))
          && button.SpecialFunction != DomainConstants.ButtonFunctionTypes.COMMENT_ENTRY.Name && button.SpecialFunction != DomainConstants.ButtonFunctionTypes.PLACEHOLDER.Name
          && button.SpecialFunction != DomainConstants.ButtonFunctionTypes.NON_FUNCTIONAL.Name) {
          canAddNextButton = true;
        }
      });
      if (!canAddNextButton) {
        this.addNextButton();
      }
    }
  }

  private addExcludeProduct(navigationObj) {
    _.forEach(this.orderNavigation.Buttons, (button) => {
      if (navigationObj.button.ScreenChoiceId != button.ScreenChoiceId && button.IsDefault && button.NextScreenId) {
        this.versionedVal.Obj.Order.Product.Components.push({
          ScreenChoiceId: button.ScreenChoiceId,
          ScreenId: button.ScreenId, SalesProductId: button.SalesProductId, GroupName: button.GroupName, IsExclusion: true, Comment: ''
        });
      }
    });
  }

  private setScreenButtons(navigationObj) {
    let screenId = navigationObj.button.StackScreenId ? navigationObj.button.StackScreenId : navigationObj.button.NextScreenId;

    //Added (default + next screen set) as exclude in case clicked button's next screen set or navigation end
    if (navigationObj.button.NextScreenId || (!navigationObj.button.NextScreenId && !navigationObj.button.Toggle)) {
      this.addExcludeProduct(navigationObj);
    }
    if (screenId) {
      this.screenNavigationTracker.push(this.orderNavigation);
      this.changeBreadcrumbs();
      this.orderNavigation = cloneDeep(this.screenButtons[screenId]);
      this.scrollTop();
      if (navigationObj.button.StackScreenId) {
        this.versionedVal.setCheckpoint(`${this.versionedVal.LatestVersion}_${navigationObj.button.screenId}`);
      } else {
        this.versionedVal.commit(`v${this.versionedVal.LatestVersion}_${screenId}_${navigationObj.button.ScreenChoiceId}`);
      }
      this.setOrderNavigation();
    }
  }

  private setOrderNavigation() {
    this.selectDefaultProducts();
    this.validateButtonVisibility();
    this.selectDefaultButtons();
    this.setNavigationFromBehaviors();
    this.checkScreenToggles();
    this.setIsSearch();
  }

  addComponent(x: INavigationAction) {
    if (x.button.ButtonType == DomainConstants.ButtonTypes.DetailEntry || x.button.ButtonType == DomainConstants.ButtonTypes.MainProduct) {
      let component = _.find(this.versionedVal.Obj.Order.Product.Components, (component) => {
        return component.ScreenChoiceId == x.button.ScreenChoiceId
      });
      // Don't Add component if the button is default and has navigation
      //In that scenario, user is just going with default and navigating to next screen
      if (!component && (x.button.IsDefault && !x.button.NextScreenId && !x.button.StackScreenId) || !x.button.IsDefault) {
        this.versionedVal.Obj.Order.Product.Components.push({
          ScreenChoiceId: x.button.ScreenChoiceId, ScreenId: x.button.ScreenId,
          SalesProductId: x.button.SalesProductId, GroupName: x.button.GroupName,
          IsExclusion: x.button.IsDefault, Comment: this.versionedVal.Obj.Order.Product.Comment,
          TemporayNavigationScreenChoiceId: this.roundTripButton ? this.roundTripButton.ScreenChoiceId : null
        });
        this.versionedVal.Obj.Order.Product.Comment = '';
      }
    }
  }

  /** Set order product object Start */
  private setOrderProductObject(x: INavigationAction): Observable<INavigationAction> {
    if (x.button.ButtonType == DomainConstants.ButtonTypes.MainProduct && !this.versionedVal.Obj.Order.Product.ItemId) {
      if (x.button.SalesProductId) {
        this.versionedVal.Obj.Order.Product.ItemId = x.button.SalesProductId;
        this.versionedVal.Obj.Order.Product.ScreenChoiceId = x.button.ScreenChoiceId;
        this.versionedVal.Obj.Order.Product.DefaultQty = x.button.DefaultQty;
        this.versionedVal.Obj.Order.Product.UnitName = x.button.UnitName;
        this.versionedVal.Obj.Order.Product.ButtonText = x.button.ButtonText;
      }
    }

    //Toggle button will handle in handle toggle function
    if (!x.button.Toggle) {
      this.addComponent(x);
    }

    this.priceAdjust = 0;
    let currentScreenBehavior = _.find(this.orderNavigation.Screen.ScreenBehaviors, (behavior: any) => {
      return behavior.behavior_name === 'Time Price Override';
    });

    if (currentScreenBehavior && (currentScreenBehavior.starttime != null || currentScreenBehavior.endtime != null)) {
      if (this.buttonNavigationService.timeToDateTime(currentScreenBehavior.starttime).getTime() < (new Date().getTime())
        && this.buttonNavigationService.timeToDateTime(currentScreenBehavior.endtime).getTime() > (new Date()).getTime()) {
        this.priceAdjust = currentScreenBehavior.manualadjustment;
      }
    }
    this.versionedVal.Obj.ScreenButtons = this.orderNavigation;
    //}
    return of(x);
  }


  private setOrderProductQty(x): Observable<INavigationAction> {
    if (this.checkIsNavigationEnd(x)) {
      if (!(this.roundTripParentScreen && (this.roundTripButton.NextScreenId || this.roundTripButton.Toggle))) {
        if (this.canSetOrderProductQty(this.versionedVal.Obj?.Order?.Product?.DefaultQty ?
          this.versionedVal.Obj.Order.Product.DefaultQty : x.button.DefaultQty)) {
          return from(this.promptForQtyChange(this.versionedVal.Obj?.Order?.Product?.DefaultQty ?
            this.versionedVal.Obj.Order.Product : x.button)
            .then((qty: string) => {
              this.versionedVal.Obj.Order.Product.Qty = parseFloat(qty);
              return x;
            }, () => {
              x.isCancelled = true;
              return x;
            }));
        } else {

          this.versionedVal.Obj.Order.Product.Qty = ((this.versionedVal.Obj?.Order?.Product?.DefaultQty ?
            this.versionedVal.Obj.Order.Product.DefaultQty : x.button.DefaultQty) ?? 1);
          return of(x);
        }
      }
    }
    return of(x);
  }


  promptForQtyChange(product): Promise<string> {

    const numpadModalRef = this.modalService.show(QuantityChangePopupComponent, {
      animated: false,
      class: 'vertical-center',
      initialState: {
        value: product.DefaultQty,
        salesProductUnit: product.UnitName
      }
    });

    numpadModalRef.close.subscribe(res => {
      if (res.value) {
        this.resolvePromptPromise(res.value);
      } else {
        this.rejectPromptPromise();
      }
    });

    this.promptPromise = new Promise<string>((resolve, reject) => {
      this.resolvePromptPromise = resolve;
      this.rejectPromptPromise = reject;
    });
    return this.promptPromise;
  }


  /** Set order product object End */

  /* Check is mapped make table terminal start */

  private checkIsMappedMakeTableTerminal(x: INavigationAction): Observable<INavigationAction> {
    if (this.mappedTerminals.length === 0 && this.settingParam.IsUseMakeTable) {
      this.showInfoMessage(StringUtils.format(Messages.NoMakeTableMappedWithOrderEntry,
        { 'terminalName': this.settingParam.TerminalName }));
      x.isCancelled = true;
      return of(x);
    }
    return of(x);
  }

  /* Check is mapped make table terminal end */


  /** Check is drawer loaded start */
  private checkIsDrawerLoaded = (x: INavigationAction): Observable<INavigationAction> => {
    if (this.settingParam.CashDrawerPrinter.Id) {
      if (this.applicationStateService.statusCloseDrawer && !this.designMode) {
        this.showInfoMessage(Messages.DrawerIsClosed);
        x.isCancelled = true;
        return of(x);
      }
    }
    //Either drawer is loaded, or no cash drawer is mapped with this terminal
    return of(x);
  }

  /** Check is drawer loaded end */

  /* Set order name config start  */
  private processOrderName = (x: INavigationAction): Observable<INavigationAction> => {
    if (!this.orderId && !this.isOrderInitiate
      && this.settingParam.RequireNameOnOrder == DomainConstants.RequireNameOnOrder.BEFORE_ORDER
      && this.settingParam.RequireNameOnOrderTemporaryDisable && !this.versionedVal.Obj.Order.Name
      && (!x.button || x.button.SpecialFunction != DomainConstants.ButtonFunctionTypes.CUSTOMER_NAME.Name)) {
      console.log('Waiting for comment');
      return from(this.promptForOrderName()
        .then(keyboardValue => {
          if (keyboardValue) {
            this.versionedVal.Obj.Order.Name = keyboardValue;
            this.checkAndSetOrderType();
            let orderCommand = {
              type: DomainConstants.SpecialFunctionBusinessCommand.SetName,
              value: keyboardValue,
              screenChoiceId: 0,
              terminalId: this.applicationStateService.terminalId,
              orderType: this.versionedVal.Obj.Order.OrderTypeId,
              accountId: this.versionedVal.Obj.Order.AccountId,
              customerAccountId: this.versionedVal.Obj.Order.CustomerAccountId
            };

            this.eventBroadcastingService.onAddItemStart.emit();
            if (!this.orderId) {
              this.isOrderInitiate = true;
            }
            this.orderService.specialFunction(this.orderId, orderCommand)
              .pipe(finalize(() => {
                this.isOrderInitiate = false;
                this.eventBroadcastingService.hideInvoiceLoader.emit();
              }))
              .subscribe({
                next: (res: any) => {
                  this.orderDetail = res;
                  this.orderId = res.Id.Value;
                  this.onAddItemCompleted(res.Id.Value, 'Customer Name')
                  this.checkInitiateOrderProduct();
                  this.checkInitiateOrderCommand();
                }, error: this.alertService.showApiError
              });
            this.keyboardValue = '';
            this.versionedVal.commit();
            return x;
          }
          x.isCancelled = true;
          return x;
        }, () => {
          x.isCancelled = true;
          return x;
        }));
    } else {
      return of(x);
    }
  }

  promptForOrderName(): Promise<string> {
    this.orderKeyboardOptions.title = "Enter order name";
    this.isOpen = true;
    this.keyboardValue = '';
    const keyboardModalRef = this.modalService.show(KeyboardComponent, {
      animated: false,
      class: 'vertical-center modal-max-width-95',
      initialState: {
        keyboardId: 'memoKeyboard',
        isOpen: true,
        options: this.orderKeyboardOptions,
        value: this.keyboardValue
      }
    });
    keyboardModalRef.close.subscribe(res => {
      if (res) {
        this.keyboardValue = res.value;
        this.resolvePromptPromise(this.keyboardValue);
      } else {
        this.rejectPromptPromise();
      }
    });

    this.promptPromise = new Promise<string>((resolve, reject) => {
      this.resolvePromptPromise = resolve;
      this.rejectPromptPromise = reject;
    });
    return this.promptPromise;
  }

  keyboardSubmit($event) {
    this.resolvePromptPromise(this.keyboardValue);
  }

  keyboardCancel($event) {
    this.rejectPromptPromise();
  }

  /* Set order name config end  */

  /* Set order type config start  */

  private processOrderType = (x: INavigationAction): Observable<INavigationAction> => {
    const availableServeMethods = Object.keys(this.serveMethods).map(key => ({ ServeMethodId: key, value: this.serveMethods[key] }));
    if (!this.orderId && !this.isOrderInitiate && !this.versionedVal.Obj.Order.OrderTypeId) {
      if (availableServeMethods.length <= 1) {
        this.setOrderTypeId(availableServeMethods);
      } else {
        return from(this.promptForOrderType()
          .then((response: any) => {
            if (response?.serveMethodId) {
              this.versionedVal.Obj.Order.OrderTypeId = parseInt(response.serveMethodId);
              this.versionedVal.commit();
            } else {
              x.isCancelled = true;
            }
            return x;
          }));
      }
    } else if (!this.orderId && !this.versionedVal.Obj.Order.OrderTypeId) {
      this.setOrderTypeId(availableServeMethods);
      this.loggerService.logError(`processOrderType orderId: ${this.orderId}, isOrderInitiate: ${this.isOrderInitiate}, orderTypeId: ${this.versionedVal.Obj.Order.OrderTypeId}, availableOrderTypes: ${JSON.stringify(availableServeMethods)}`);
      this.loggerService.logError(`processOrderType versionedVal info: ${JSON.stringify(this.versionedVal.Obj.Order)}`);
    }
    return of(x);
  }


  setOrderTypeId(availableServeMethods) {
    const defaultServeMethod = availableServeMethods.length == 1 ? availableServeMethods[0].ServeMethodId : DomainConstants.ServeMethods.Carryout;
    this.versionedVal.Obj.Order.OrderTypeId = parseInt(defaultServeMethod.toString());
    this.versionedVal.commit();
  }
  promptForOrderType(): Promise<string> {
    const modalRef = this.modalService.show(SetOrderTypeComponent, {
      animated: false,
      class: 'vertical-center',
      initialState: {
        serveMethods: this.serveMethods
      }
    });
    return this.toPromise(modalRef.close);
  }


  /* Set order type config end  */


  /* Set account config start */
  processSetAccount = (x: INavigationAction): Observable<INavigationAction> => {
    if (!this.orderId && !this.isOrderInitiate) {
      let dinein = _.find(DomainConstants.PromptServeMethods, (item) => {
        return item.Name == "Dine in";
      });
      if (this.versionedVal.Obj.Order.OrderTypeId == parseInt(dinein.id) && !this.versionedVal.Obj.Order.AccountId) {
        if (this.mappedLayout.length > 0) {
          return from(this.promptForTableSelection(x));
        } else {
          this.alertService.renderInformationalMessage(Messages.NoLayoutMappedWithOrderEntry);
          x.isCancelled = true;
        }
        return of(x);
      }
      else if (this.settingParam.AutoDefer && !this.versionedVal.Obj.Order.AccountId
        && !this.versionedVal.Obj.Order.CustomerAccountId
        && this.versionedVal.Obj.Order.OrderTypeId != parseInt(dinein.id)
        && this.settingParam.ChooseAccountOptional == DomainConstants.AccountOptions.Required) {
        if (this.settingParam.AccountTypeDefault == DomainConstants.AccountTypes.Account) {
          return from(this.redirectToAccount(true)
            .then((response: any) => {
              if (response?.accountId) {
                this.versionedVal.Obj.Order.CustomerAccountId = parseInt(response.accountId);
                this.versionedVal.commit();
              } else {
                x.isCancelled = true;
              }
              return x;
            }));
        } else if (this.settingParam.AccountTypeDefault == DomainConstants.AccountTypes.Tab) {
          return from(this.redirectToAccount(false)
            .then((response: any) => {
              if (response?.accountId) {
                this.versionedVal.Obj.Order.AccountId = parseInt(response.accountId);
                this.versionedVal.commit();
              } else {
                x.isCancelled = true;
              }
              return x;
            }));
        } else if (this.settingParam.AccountTypeDefault == DomainConstants.AccountTypes.Table) {
          return from(this.promptForTableSelection(x));
        } else {
          return of(x);
        }
      } else {
        return of(x);
      }
    } else {
      return of(x);
    }
  }

  private promptForTableSelection(x: INavigationAction): Promise<INavigationAction> {
    return this.tableForOrder()
      .then((accountId: any) => {
        if (accountId) {
          this.versionedVal.Obj.Order.AccountId = parseInt(accountId);
          this.accountId = parseInt(accountId);
          this.versionedVal.commit();
        } else {
          x.isCancelled = true;
        }
        return x;
      });
  }

  redirectToAccount(isTypeAccount) {
    const modalRef = this.modalService.show(SetAccountComponent, {
      animated: false,
      class: 'vertical-center modal-lg',
      initialState: {
        orderId: this.orderId,
        isTypeAccount: isTypeAccount
      }
    });

    return this.toPromise(modalRef.close);
  }

  openLayoutSelectionForOrder = () => {
    this.spinnerService.show();
    this.orderService.getUnservedOrdersData(0, 0)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res) => {
          const modalRef = this.modalService.getModalWrapper(TableSelectionComponent);
          const modal = modalRef.show({
            keyboard: false,
            animated: false,
            class: 'vertical-center modal-max-width-95',
            initialState: {
              orders: res ?? [],
              mappedLayout: this.mappedLayout,
              accounts: this.accountVisualizations
            }
          });
          modal.close.subscribe(res => {
            if (res?.accountId) {
              this.resolveLayoutTableSelectionPromise(res.accountId);
            } else {
              this.rejectLayoutTableSelectionPromise();
            }
          });
        }, error: this.alertService.showApiError
      });
  }

  tableForOrder() {
    setTimeout(() => {
      this.openLayoutSelectionForOrder();
    })
    this.layoutPromise = new Promise<number>((resolve, reject) => {
      this.resolveLayoutTableSelectionPromise = resolve;
      this.rejectLayoutTableSelectionPromise = reject;
    });
    return this.layoutPromise;
  }

  /* Set account config end */

  /** Info Mode Start */

  processInfoMode(x: INavigationAction): Observable<INavigationAction> {
    if (this.infoMode && x.button.SalesProductId) {
      const modalRef = this.modalService.show(OrderEntryInfoModeComponent, {
        animated: false,
        class: 'vertical-center modal-lg',
        initialState: {
          productInfo: x.button,
          manualPriceAdjust: this.priceAdjust
        }
      });

      modalRef.close.subscribe(res => { });
      x.isCancelled = true;
      this.infoMode = false;
      this.infoModeChange.emit(this.infoMode);
    }

    return of(x);
  }

  changeInfoMode($event) {
    this.infoMode = $event;
  }

  /** Info Mode End */

  /* Check is in stock start */

  checkIsInStock(x: INavigationAction): Observable<INavigationAction> {
    // Do not allow ordering, if product is out of stock, and product is not allowed to order when out of stock, or product is inactive
    if (x.button.SalesProductId) {
      if (!x.button.IsInStock && !x.button.IsAllowOutOfStockOrder) {

        this.showInfoMessage(StringUtils.format(x.button.SalesProductSizes?.length > 1 ? Messages.OutOfStockProductMessageForMultipleSizes : Messages.OutOfStockProductMessage, { 'salesProduct': x.button.ProductName }));
        x.isCancelled = true;
      }
      else if (!x.button.IsActive) {
        this.showInfoMessage(StringUtils.format(Messages.InactiveProductMessage, { 'salesProduct': x.button.ProductName }));
        x.isCancelled = true;
      }
    }
    return of(x);
  }

  /* Check is in stock end  */

  findGroupSelectedButton(groupName) {
    return _.find(this.orderNavigation.Buttons, (button) => {
      return button.GroupName == groupName && button.IsSelected && button.IsVisible
    });
  }

  // Removed toggle button if max selection is 1
  removeMaxValidatedToggleButton(selectedGroupButton) {
    _.remove(this.versionedVal.Obj.Order.Product.Components, (component) => {
      return component.ScreenChoiceId == selectedGroupButton.ScreenChoiceId;
    });
    if (selectedGroupButton.Id == this.versionedVal.Obj.Order.Product.SizeButtonId && selectedGroupButton.IsSelected) {
      this.versionedVal.Obj.Order.Product.SizeId = null;
    }
  }

  /** Check group min max start */
  handleToggleButton(x: INavigationAction): Observable<INavigationAction> {
    if (x.button.Toggle) {
      if (this.versionedVal.Obj.Order.Product) {
        // When button is default then it was automatically select.
        this.applyToggleSelection(x);
      }
    } else {
      x.button.IsSelected = !x.button.IsSelected;
    }
    return of(x);
  }

  private applyToggleSelection(x: INavigationAction) {
    if (this.isDefaultSelectedButton(x)) {
      this.handleDefaultToggleButton(x);
    } else {
      if (x.button.GroupName) {
        if (this.validateButtonGroup(x)) {
          this.handleMinMaxGroupToggle(x);
        }
      } else {
        this.addComponent(x);
        x.button.IsSelected = x.button.IsDefault ? false : true;
        this.setButtonBgColor(x.button);
      }
    }
  }
  private isDefaultSelectedButton(x: INavigationAction) {
    return (x.button.IsSelected && !x.button.IsDefault) || (!x.button.IsSelected && x.button.IsDefault);
  }

  private handleMinMaxGroupToggle(x: INavigationAction) {
    //  If no min/max is specified they should act as though min and max are 1
    if (x.button.ButtonGroupMax === 1 || (!x.button.ButtonGroupMax && !x.button.ButtonGroupMin)) {
      const selectedGroupButton = this.findGroupSelectedButton(x.button.GroupName);
      if (selectedGroupButton) {
        this.removeMainProduct(selectedGroupButton.SalesProductId);
        this.removeMaxValidatedToggleButton(selectedGroupButton);
        _.forEach(this.orderNavigation.Buttons, (button) => {
          if (button.ScreenChoiceId == selectedGroupButton.ScreenChoiceId) {
            button.IsSelected = x.button.IsDefault ? true : false;
            this.setButtonBgColor(button);
          }
        });
        if (selectedGroupButton.IsDefault) {
          this.versionedVal.Obj.Order.Product.Components.push({
            ScreenChoiceId: selectedGroupButton.ScreenChoiceId,
            ScreenId: selectedGroupButton.ScreenId, SalesProductId: selectedGroupButton.SalesProductId,
            GroupName: selectedGroupButton.GroupName, IsExclusion: true, Comment: ''
          });
        }
      }
      this.addComponent(x);
      x.button.IsSelected = x.button.IsDefault ? false : true;
      this.setButtonBgColor(x.button);
    }
  }

  private handleDefaultToggleButton(x: INavigationAction) {
    if (x.button.IsDefault && x.button.GroupName && this.validateButtonGroup(x) && (x.button.ButtonGroupMax === 1 || (!x.button.ButtonGroupMax && !x.button.ButtonGroupMin))) {
      const selectedGroupButton = this.findGroupSelectedButton(x.button.GroupName);
      if (selectedGroupButton) {
        this.removeMaxValidatedToggleButton(selectedGroupButton);
        _.forEach(this.orderNavigation.Buttons, (button) => {
          if (button.ScreenChoiceId == selectedGroupButton.ScreenChoiceId) {
            button.IsSelected = selectedGroupButton.IsDefault ? true : false;
            this.setButtonBgColor(button);
          }
        });
      }
    }
    if (!x.isCancelled) {
      _.remove(this.versionedVal.Obj.Order.Product.Components, (component) => {
        return component.ScreenChoiceId == x.button.ScreenChoiceId || component.TemporayNavigationScreenChoiceId === x.button.ScreenChoiceId;
      });
      x.button.IsSelected = x.button.IsDefault ? true : false;
      // make multiple product id null in case of toggle button cancel
      if (this.multipleProductButtonId == x.button.Id) {
        this.multipleProductButtonId = null;
        this.versionedVal.Obj.Order.Product.MultipleProductButtonId = null;
      }
      if (this.aiShakeButtonId == x.button.Id) {
        this.aiShakeButtonId = null;
        this.aiShakeId = null;
        this.versionedVal.Obj.Order.Product.AiShakeId = null;
        this.versionedVal.Obj.Order.Product.AiShakeButtonId = null;
      }
      this.setButtonBgColor(x.button);
      x.isCancelled = true;
      this.removeMainProduct(x.button.SalesProductId);
    }
  }

  removeMainProduct(salesProductId: number) {
    if (this.versionedVal.Obj.Order.Product.ItemId == salesProductId) {
      this.versionedVal.Obj.Order.Product.ItemId = null;
    }
  }

  validateButtonGroup(x: INavigationAction) {
    //  If no min/max is specified they should act as though min and max are 1
    if (x.button.ButtonGroupMax === 1 || (!x.button.ButtonGroupMax && !x.button.ButtonGroupMin)) {
      return true;
    } else {
      const selectedButtons = _.filter(this.orderNavigation.Buttons, (button) => {
        return button.GroupName == x.button.GroupName && button.IsSelected && button.IsVisible;
      });

      if (x.button.IsDefault && x.button.IsSelected) {
        _.remove(selectedButtons, (button) => {
          return x.button.Id == button.Id;
        });
      }

      if (x.button.ButtonGroupMax && selectedButtons.length >= x.button.ButtonGroupMax) {
        this.showInfoMessage(StringUtils.format(Messages.MaximumSelectionGroupButton, { 'groupName': x.button.GroupName, buttonGroupMax: x.button.ButtonGroupMax }));
        x.isCancelled = true;
      } else {
        this.addComponent(x);
        x.button.IsSelected = x.button.IsDefault ? false : true;
        this.setButtonBgColor(x.button);
      }
    }
  }
  /** Check group min max end */


  /** Validate screen min max Start */

  validateScreenMinMax(x: INavigationAction): Observable<INavigationAction> {
    if (x.button.NextScreenId || !x.button.Toggle) {
      x.isCancelled = this.checkMinMaxValidation();
    }
    return of(x);
  }


  checkMinMaxValidation(): boolean {
    let isCancelled = false;
    let groups = _.uniqBy(this.orderNavigation.Buttons.filter(y => y.GroupName && y.IsVisible), "GroupName");
    let screenMessage = "";
    if (groups.length) {
      for (let button of groups) {
        if (button.ButtonGroupMin) {
          let selectedGroupButtons = this.orderNavigation.Buttons.filter(y => y.GroupName === button.GroupName && y.IsSelected && y.IsVisible);
          if (button.ButtonGroupMin > selectedGroupButtons.length) {
            if (button.ButtonGroupMin == 1) {
              screenMessage = StringUtils.format(Messages.MinimumSelectionGroupButton,
                { 'groupName': button.GroupName });
            } else {
              screenMessage = StringUtils.format(Messages.MinimumSelectionGroupButtons,
                { 'groupName': button.GroupName, buttonGroupMin: button.ButtonGroupMin });
            }
            isCancelled = true;
            break;
          }
        }
      };
    }

    if (!isCancelled && this.orderNavigation.Screen.MinSelections && this.orderNavigation.Screen.MaxSelections) {
      let currentScreenSelections = this.orderNavigation.Buttons.filter(y => y.IsSelected);
      if (this.orderNavigation.Screen.MinSelections > currentScreenSelections.length) {
        screenMessage = StringUtils.format(Messages.MinimumSelectionGroupScreen,
          { 'minSelections': this.orderNavigation.Screen.MinSelections });
        isCancelled = true;
      }
      else if (this.orderNavigation.Screen.MaxSelections < currentScreenSelections.length) {
        screenMessage = StringUtils.format(Messages.MaximumSelectionGroupScreen,
          { 'maxSelections': this.orderNavigation.Screen.MaxSelections });
        isCancelled = true;
      }
    }
    if (isCancelled) {
      this.showInfoMessage(screenMessage);
    }
    return isCancelled;
  }

  /** Validate Screen min max end */


  /** Check is special function start */

  numericMarkup(x: INavigationAction): Observable<INavigationAction> {


    let selectedButtonFunctionParam = _.find(this.functionParameters, (param) => {
      return param.ButtonId == x.button.Id && param.Key == DomainConstants.ButtonFunctionTypes.NUMERIC_MARKUP.NumericMarkupType;
    });

    if (selectedButtonFunctionParam && selectedButtonFunctionParam.Value == DomainConstants.MarkupDiscountConfigurationType.Auto) {
      const selectedButtonFunctionValue = _.find(this.functionParameters, (param) => {
        return param.ButtonId == x.button.Id && param.Key == DomainConstants.ButtonFunctionTypes.NUMERIC_MARKUP.NumericMarkupAmount;
      });
      const selectedButtonFunctionTaxable = _.find(this.functionParameters, (param) => {
        return param.ButtonId == x.button.Id && param.Key == DomainConstants.ButtonFunctionTypes.NUMERIC_MARKUP.Taxable;
      });
      if (selectedButtonFunctionValue?.Value) {
        x.orderCommand = this.getMarkupCommand(selectedButtonFunctionValue.Value, selectedButtonFunctionTaxable?.Value == DomainConstants.MarkupDiscountConfigurationType.Taxable, x.button.ScreenChoiceId);
        return of(x);
      }
    }
    return this.numericManual(x);

  }

  numericManual(x: INavigationAction): Observable<INavigationAction> {
    return from(this.promptForManualAmount(x.button)
      .then((response: any) => {
        if (response?.amountValue) {
          if (x.button.SpecialFunction === DomainConstants.ButtonFunctionTypes.NUMERIC_DISCOUNT_MANUAL.Name) {
            if (this.calculateAmountForNumericDiscount(response.amountValue)) { return x; }
            x.orderCommand = {
              type: DomainConstants.SpecialFunctionBusinessCommand.SetDiscount, value: response.amountValue * -1, screenChoiceId: x.button.ScreenChoiceId,
              terminalId: this.applicationStateService.terminalId, orderType: this.versionedVal.Obj.Order.OrderTypeId,
              isTrainingOrder: this.isTrainingOrder,
              accountId: this.versionedVal.Obj.Order.AccountId, customerAccountId: this.versionedVal.Obj.Order.CustomerAccountId,
              discountType: 'Numeric'
            };
          } else {
            x.orderCommand = this.getMarkupCommand(response.amountValue, response.isTaxable, x.button.ScreenChoiceId);
          }
        } else {
          x.isCancelled = true;
        }
        return x;
      }));
  }

  getMarkupCommand(amount, isTaxable, screenChoiceId) {
    return {
      type: DomainConstants.SpecialFunctionBusinessCommand.SetMarkup,
      orderType: this.versionedVal.Obj.Order.OrderTypeId,
      value: amount,
      isTaxable: isTaxable,
      isTrainingOrder: this.isTrainingOrder,
      screenChoiceId: screenChoiceId,
      terminalId: this.applicationStateService.terminalId,
      accountId: this.versionedVal.Obj.Order.AccountId, customerAccountId: this.versionedVal.Obj.Order.CustomerAccountId,
      markupType: 'Numeric',
      name: 'Markup'
    };
  }

  promptForManualAmount(button: OrderNavigationButton): Promise<string> {
    let selectedIsTaxable = true;
    let taxableItem = Messages.TaxableItem;
    if (button.SpecialFunction == DomainConstants.ButtonFunctionTypes.NUMERIC_MARKUP.Name) {
      const selectedButtonFunction = _.find(this.functionParameters, (param) => {
        return param.ButtonId == button.Id && (param.Key == DomainConstants.ButtonFunctionTypes.NUMERIC_MARKUP.Taxable);
      });
      if (selectedButtonFunction && selectedButtonFunction.Value === "NonTaxable") {
        taxableItem = Messages.NonTaxable;
        selectedIsTaxable = false;
      }
    }

    const modalRef = this.modalService.show(ManualDiscountComponent, {
      animated: false,
      class: 'vertical-center',
      initialState: {
        button: button,
        selectedIsTaxable: selectedIsTaxable,
        taxableItem: taxableItem
      }
    });
    return this.toPromise(modalRef.close);
  }


  percentDiscount(x: INavigationAction): Observable<INavigationAction> {
    if (this.orderDetail.MarkupPct) {
      this.showInfoMessage(StringUtils.format(Messages.MarkupDiscountRestrict, { 'priceModifier': 'markup' }));
      x.isCancelled = true;
      return of(x);
    }
    let selectedButtonFunctionParam = _.find(this.functionParameters, function (param) {
      return param.ButtonId == x.button.Id && param.Key == "PercentDiscountType";
    });

    if (selectedButtonFunctionParam && selectedButtonFunctionParam.Value == "Auto") {
      let selectedButtonFunctionPercent = _.find(this.functionParameters, function (param) {
        return param.ButtonId == x.button.Id && param.Key == DomainConstants.ButtonFunctionTypes.PERCENT_DISCOUNT.Code;
      });
      if (selectedButtonFunctionPercent && selectedButtonFunctionPercent.Value > 0) {
        if (this.calculateAmountForPercentageDiscount(selectedButtonFunctionPercent.Value)) { return of(x); }
        x.orderCommand = {
          type: DomainConstants.SpecialFunctionBusinessCommand.SetDiscount, value: selectedButtonFunctionPercent.Value, screenChoiceId: x.button.ScreenChoiceId,
          terminalId: this.applicationStateService.terminalId, isTrainingOrder: this.isTrainingOrder, accountId: this.versionedVal.Obj.Order.AccountId, customerAccountId: this.versionedVal.Obj.Order.CustomerAccountId, discountType: 'Percent',
          orderType: this.versionedVal.Obj.Order.OrderTypeId
        };
      }
      return of(x);
    } else {
      return from(this.promptForManualPercent(x.button, 'Manual Discount')
        .then((response: any) => {
          if (response?.amountValue && !this.calculateAmountForPercentageDiscount(response.amountValue)) {
            x.orderCommand = {
              type: DomainConstants.SpecialFunctionBusinessCommand.SetDiscount, value: response.amountValue, screenChoiceId: x.button.ScreenChoiceId,
              terminalId: this.applicationStateService.terminalId, accountId: this.versionedVal.Obj.Order.AccountId, isTrainingOrder: this.isTrainingOrder,
              customerAccountId: this.versionedVal.Obj.Order.CustomerAccountId,
              orderType: this.versionedVal.Obj.Order.OrderTypeId
            };
          } else {
            x.isCancelled = true;
          }
          return x;
        }));
    }

  }

  buttonBehavior(x: INavigationAction): Observable<INavigationAction> {
    return from(this.executeButtonBehaviors(x, this.orderNavigation)
      .then((response: any) => {
        return x;
      }, () => {
        if (x.button.Toggle) {
          x.button.IsSelected = false;
          x.button.Color = x.button.OriginalColor;
          if (this.versionedVal.Obj.Order.Product?.Components?.length) {
            _.remove(this.versionedVal.Obj.Order.Product.Components, (c) => {
              return c.ScreenChoiceId == x.button.ScreenChoiceId;
            });
          }
        }
        x.isCancelled = true;
        return x;
      }
      ));
  }

  promptForButtonBehaviorMessage(message) {
    const modalRef = this.modalService.show(InfoModalComponent, {
      animated: false,
      class: 'vertical-center',
      initialState: {
        message: message
      }
    });
    return this.toPromise(modalRef.close);
  }

  percentMarkup(x: INavigationAction): Observable<INavigationAction> {
    if (this.orderDetail.DiscountPct) {
      this.showInfoMessage(StringUtils.format(Messages.MarkupDiscountRestrict, { 'priceModifier': 'discount' }));
      x.isCancelled = true;
      return of(x);
    }

    let selectedButtonFunctionParam = _.find(this.functionParameters, function (param) {
      return param.ButtonId == x.button.Id && param.Key === 'PercentMarkupType';
    });

    if (selectedButtonFunctionParam && selectedButtonFunctionParam.Value === 'Auto') {
      let selectedButtonFunctionPercent = _.find(this.functionParameters, function (param) {
        return param.ButtonId == x.button.Id && param.Key == DomainConstants.ButtonFunctionTypes.PERCENT_MARKUP.Code;
      });
      if (selectedButtonFunctionPercent && selectedButtonFunctionPercent.Value > 0) {
        x.orderCommand = {
          type: DomainConstants.SpecialFunctionBusinessCommand.SetMarkup, value: selectedButtonFunctionPercent.Value,
          screenChoiceId: x.button.ScreenChoiceId, terminalId: this.applicationStateService.terminalId, userId: this.userDetails.id,
          isTrainingOrder: this.isTrainingOrder,
          orderType: this.versionedVal.Obj.Order.OrderTypeId,
          accountId: this.versionedVal.Obj.Order.AccountId, customerAccountId: this.versionedVal.Obj.Order.CustomerAccountId
        };
      }
      return of(x);
    } else {
      return from(this.promptForManualPercent(x.button, 'Manual Markup')
        .then((response: any) => {
          if (response?.amountValue) {
            x.orderCommand = {
              type: DomainConstants.SpecialFunctionBusinessCommand.SetMarkup, value: response.amountValue, screenChoiceId: x.button.ScreenChoiceId,
              terminalId: this.applicationStateService.terminalId,
              isTrainingOrder: this.isTrainingOrder,
              accountId: this.versionedVal.Obj.Order.AccountId, customerAccountId: this.versionedVal.Obj.Order.CustomerAccountId,
              markupType: 'Percent',
              orderType: this.versionedVal.Obj.Order.OrderTypeId,
            };
          } else {
            x.isCancelled = true;
          }
          return x;
        }));
    }

  }

  promptForManualPercent(button: OrderNavigationButton, title: string): Promise<string> {
    const modalRef = this.modalService.show(ManualDiscountComponent, {
      animated: false,
      class: 'vertical-center',
      initialState: {
        button: button,
        isDisplayTaxable: false,
        numpadTitle: title
      }
    });
    return this.toPromise(modalRef.close);
  }

  timeEntry(x: INavigationAction): Observable<INavigationAction> {
    return from(this.promptForTimeEntry(x.button)
      .then((response: any) => {
        if (response?.enteredTime) {
          const scheduleTime: Date = response.enteredTime;
          x.orderCommand = {
            type: DomainConstants.SpecialFunctionBusinessCommand.SetTime,
            value: `${scheduleTime.getHours()}:${scheduleTime.getMinutes()}`,
            screenChoiceId: x.button.ScreenChoiceId,
            terminalId: this.applicationStateService.terminalId,
            isTrainingOrder: this.isTrainingOrder,
            accountId: this.versionedVal.Obj.Order.AccountId, customerAccountId: this.versionedVal.Obj.Order.CustomerAccountId,
            orderType: this.versionedVal.Obj.Order.OrderTypeId,
          };
        }
        return x;
      }));
  }
  promptForTimeEntry(button: OrderNavigationButton): Promise<string> {
    const timeSlots: Array<{ text: string; value: number }> = [
      { text: '10 min', value: 10 },
      { text: '15 min', value: 15 },
      { text: '20 min', value: 20 },
      { text: '30 min', value: 30 },
      { text: '45 min', value: 45 },
      { text: '60 min', value: 60 }
    ];
    const modalRef = this.modalService.getModalWrapper(TimeEntryComponent);
    const modal = modalRef.show({
      animated: false,
      class: 'vertical-center',
      initialState: {
        title: button.ButtonText,
        timeSlots: timeSlots,
        isShowCalender: false
      }
    });
    return this.toPromise(modal.close);
  }

  assignPager(x: INavigationAction): Observable<INavigationAction> {


    if (this.settingParam.IsUsePager) {
      return from(this.openPagerModal(x)
        .then((res: any) => {
          if (res?.Pager) {
            if (this.orderId || (!res.Pager.PagerNumber && res.Pager.OrderId)) {

              x.orderCommand = { type: DomainConstants.SpecialFunctionBusinessCommand.AssignPager, value: res.Pager.PagerNumber, pagerUpdateOrderId: res.Pager.OrderId, buttonId: x.button.Id };
            }
            else {
              x.isCancelled = true;
              this.alertService.renderErrorMessage(Messages.ErrorWhileOrderNotLoaded);
            }
          }
          return x;
        }));
    }
    return of(x);
  }

  commentEntry(x: INavigationAction, fnType: string): Observable<INavigationAction> {
    const commentEntryParam = _.find(this.functionParameters, (param) => {
      return param.ButtonId == x.button.Id && param.Key == DomainConstants.ButtonFunctionTypes.COMMENT_ENTRY.Code;
    })?.Value;

    if (commentEntryParam?.trim()) {
      this.prepareCommentEntryCommand(x, fnType, commentEntryParam);
      return of(x);
    }

    console.log('Waiting for comment');
    this.commentEntryKeyboardOptions.title = x.button.SpecialFunction == DomainConstants.ButtonFunctionTypes.CUSTOMER_NAME.Name ? 'Enter Customer Name' : 'Enter Comment';
    return from(this.promptForCommentEntry(x)
      .then(keyboardValue => {
        if (keyboardValue) {
          this.prepareCommentEntryCommand(x, fnType, keyboardValue);
        }
        this.orderCommentValue = '';
        return x;
      }, () => {
        return x;
      }));
  }

  private prepareCommentEntryCommand(x: INavigationAction, fnType: string, comment: string) {
    x.orderCommand = {
      type: fnType, value: comment,
      screenChoiceId: x.button.ScreenChoiceId, terminalId: this.applicationStateService.terminalId,
      isTrainingOrder: this.isTrainingOrder,
      accountId: this.versionedVal.Obj.Order.AccountId, customerAccountId: this.versionedVal.Obj.Order.CustomerAccountId,
      orderType: this.versionedVal.Obj.Order.OrderTypeId,
    };
  }

  setPhoneNumber(x: INavigationAction): Observable<INavigationAction> {
    return from(this.promptForSetPhoneNumber(x)
      .then((response: any) => {
        this.phoneNumberModalCloseRabbitMQSubscription?.unsubscribe();
        this.rabbitMQService.SendOrderPhoneNumberModalCloseMessage(this.applicationStateService.terminalId, response);
        if (response && response.value > 0) {
          x.orderCommand = {
            type: DomainConstants.SpecialFunctionBusinessCommand.SetPhoneNumber,
            value: response.value,
            screenChoiceId: x.button.ScreenChoiceId, terminalId: this.applicationStateService.terminalId,
            isTrainingOrder: this.isTrainingOrder,
            accountId: this.versionedVal.Obj.Order.AccountId, customerAccountId: this.versionedVal.Obj.Order.CustomerAccountId,
            orderType: this.versionedVal.Obj.Order.OrderTypeId,
          };
        } else {
          x.isCancelled = true;
        }
        return x;
      }));
  }

  addComment(x: INavigationAction): Observable<INavigationAction> {
    if (x.button.IsSelected) {
      console.log('Waiting for comment');
      this.commentEntryKeyboardOptions.title = 'Enter Comment';
      return from(this.promptForCommentEntry(x)
        .then(keyboardValue => {
          if (keyboardValue && this.versionedVal.Obj.Order.Product?.Components?.length) {
            this.versionedVal.Obj.Order.Product.Components[this.versionedVal.Obj.Order.Product.Components.length - 1].Comment = `C: ${keyboardValue}`;
            this.orderCommentValue = '';
          }
          return x;
        }, () => {
          return x;
        }));
    } else {
      return of(x);
    }
  }


  removeTax(x: INavigationAction): Observable<INavigationAction> {
    if (!this.orderDetail.PaidAmount && this.orderDetail.OrderAttributes.IsTaxable) {
      x.orderCommand = {
        type: DomainConstants.SpecialFunctionBusinessCommand.RemoveTax,
        screenChoiceId: x.button.ScreenChoiceId, terminalId: this.applicationStateService.terminalId, userId: this.userDetails.id,
        isTrainingOrder: this.isTrainingOrder,
        orderType: this.versionedVal.Obj.Order.OrderTypeId,
        accountId: this.versionedVal.Obj.Order.AccountId, customerAccountId: this.versionedVal.Obj.Order.CustomerAccountId
      };
    }
    return of(x);
  }

  addCommentToAll(x: INavigationAction): Observable<INavigationAction> {
    if (this.orderDetail?.Id?.Value) {
      x.orderCommand = {
        type: DomainConstants.SpecialFunctionBusinessCommand.AddCommentToAll,
        screenChoiceId: x.button.ScreenChoiceId, terminalId: this.applicationStateService.terminalId, userId: this.userDetails.id
      };
    }
    return of(x);
  }

  dietaryRestriction(x: INavigationAction): Observable<INavigationAction> {
    const selectedSetDietaryRestrictionParam = _.find(this.functionParameters, (param) => {
      return param.ButtonId == x.button.Id && param.Key == DomainConstants.ButtonFunctionTypes.SET_DIETARY_RESTRICTION.Code;
    });

    if (selectedSetDietaryRestrictionParam?.Value) {
      if (this.orderId > 0) {
        return from(this.promptForDietaryRestriction(selectedSetDietaryRestrictionParam, x.button.Id)
          .then(response => {
            if (response?.length) {
              x.orderCommand = this.prepareDietaryWarningCommand(selectedSetDietaryRestrictionParam.Value, x.button.ScreenChoiceId, x.button.Id);
              x.orderCommand.orderDietaryRestrictions = response;
            }
            return x;
          }, () => {
            x.isCancelled = true;
            return x;
          }));
      } else {
        x.orderCommand = this.prepareDietaryWarningCommand(selectedSetDietaryRestrictionParam.Value, x.button.ScreenChoiceId, x.button.Id);
        return of(x);
      }
    }
    x.isCancelled = true;
    return of(x);
  }

  prepareDietaryWarningCommand(restrictionId, screenChoiceId, buttonId) {
    let dietaryWarning = _.find(this.dietaryWarnings, (warning) => {
      return warning.Id == restrictionId;
    });
    return {
      type: DomainConstants.SpecialFunctionBusinessCommand.DietaryRestriction, SubaccountOrdinal: this.subaccountOrdinal, ButtonId: buttonId,
      dietaryRestrictionId: restrictionId, screenChoiceId: screenChoiceId, orderType: this.versionedVal.Obj.Order.OrderTypeId,
      dietaryWarningName: dietaryWarning ? dietaryWarning.Name : '', isTrainingOrder: this.isTrainingOrder,
      accountId: this.versionedVal.Obj.Order.AccountId, customerAccountId: this.versionedVal.Obj.Order.CustomerAccountId, terminalId: this.applicationStateService.terminalId
    };
  }

  promptForDietaryRestriction(dietaryRestrictionId, buttonId): Promise<Array<OrderDietaryRestriction>> {
    this.openDietaryWarningsOfOrder(dietaryRestrictionId, buttonId);
    this.dietaryPromise = new Promise<Array<OrderDietaryRestriction>>((resolve, reject) => {
      this.resolveDietaryRestrictionPromise = resolve;
      this.rejectDietaryRestrictionPromise = reject;
    });
    return this.dietaryPromise;
  }

  openDietaryWarningsOfOrder(dietaryRestrictionId, buttonId) {

    const dietaryWarning = _.find(this.dietaryWarnings, (warning) => {
      return warning.Id == parseInt(dietaryRestrictionId.Value);
    });

    const orderDietaryRestriction = {
      dietaryRestrictionId: dietaryRestrictionId.Value,
      OrderId: this.orderId, SubaccountOrdinal: this.subaccountOrdinal, ButtonId: buttonId, DietaryWarning: { Name: dietaryWarning.Name }
    }
    this.eventBroadcastingService.onAddItemStart.emit();
    this.orderService.getDietaryWarningsOfOrder(this.orderId, this.subaccountOrdinal, dietaryRestrictionId.Value, orderDietaryRestriction)
      .pipe(finalize(() => {
        this.eventBroadcastingService.hideInvoiceLoader.emit();
      }))
      .subscribe({
        next: (res: Array<OrderDietaryRestriction>) => {
          this.onAddItemCompleted(this.orderId, 'Dietary Warning');
          if (res?.length) {
            const modalRef = this.modalService.getModalWrapper(OrderDietaryRestrictionComponent);
            const dietaryWarningModalRef = modalRef.show({
              animated: false,
              keyboard: false,
              class: 'vertical-center modal-lg',
              initialState: {
                orderDietaryRestrictions: res
              }
            });

            dietaryWarningModalRef.close.subscribe(res => {
              if (res?.orderDietaryRestrictions?.length) {
                const dietaryRestrictions: OrderDietaryRestriction[] = lodashFlatMap(res.orderDietaryRestrictions.map(x => x.Components));
                this.resolveDietaryRestrictionPromise(dietaryRestrictions);
              }
              else {
                this.rejectDietaryRestrictionPromise();
              }
            });
          } else {
            this.resolveDietaryRestrictionPromise();
          }
        }, error: this.alertService.showApiError
      });

  }


  openCommentWarningModal(commentWarningMatch, x: INavigationAction) {
    if (!commentWarningMatch.IsBlocked) {
      const modalRef = this.modalService.show(InfoModalComponent, {
        animated: false,
        class: 'vertical-center',
        initialState: {
          message: commentWarningMatch.WarningMessage,
          confirmButtonText: 'Ok',
          rejectButtonText: 'Continue Anyway',
          modalHeaderText: 'Warning'
        }
      });

      modalRef.close.subscribe((res) => {
        if (res && (res.shouldConfirm || res.event == 'close')) {
          this.openCommentEntryKeyboard(x);
        } else {
          this.resolvePromptPromise(commentWarningMatch.CommentTextMatch);
        }
      });
    } else {
      const modalRef = this.modalService.show(InfoModalComponent, {
        animated: false,
        class: 'vertical-center',
        initialState: {
          message: commentWarningMatch.WarningMessage,
          confirmButtonText: 'Ok',
          modalHeaderText: 'Warning'
        }
      });
      modalRef.close.subscribe((res) => {
        this.commentEntryKeyboardOptions.isOpen = true;
      });
    }
  }

  openCommentEntryKeyboard(x) {
    this.isOpenCommentEntry = true;
    this.commentEntryKeyboardOptions.isOpen = true;
    const keyboardModalRef = this.modalService.show(KeyboardComponent, {
      animated: false,
      class: 'vertical-center modal-max-width-95',
      initialState: {
        keyboardId: 'memoKeyboard',
        isOpen: true,
        options: this.commentEntryKeyboardOptions,
        value: this.orderCommentValue
      }
    });

    keyboardModalRef.close.subscribe(res => {
      if (res?.value) {
        this.keyboardValue = res.value;
        this.commentEntryKeyboardSubmit(this.keyboardValue, x);
      }
    });
  }

  checkRestrictedComment(commentText: string) {
    if (this.commentWarnings && this.commentWarnings.length > 0) {
      let commentWarningMatch = _.find(this.commentWarnings, (warning) => {
        return warning.CommentTextMatch.trim().toLowerCase() == commentText.trim().toLowerCase();
      });
      return commentWarningMatch;
    }
    return false;
  }


  uncheckAllToggles() {
    _.forEach(this.orderNavigation.Buttons, (button) => {
      if (button.Toggle && button.IsSelected) {
        button.IsSelected = false;
        this.setButtonBgColor(button);
      }
      if (!button.IsDefault) {
        _.remove(this.versionedVal.Obj.Order.Product.Components, (component) => {
          return component.ScreenChoiceId === button.ScreenChoiceId;
        });
      }
      else {
        if (button.ButtonType == DomainConstants.ButtonTypes.DetailEntry || button.ButtonType == DomainConstants.ButtonTypes.MainProduct) {
          let component = _.find(this.versionedVal.Obj.Order.Product.Components, (component) => {
            return component.ScreenChoiceId == button.ScreenChoiceId
          });
          if (!component) {
            this.versionedVal.Obj.Order.Product.Components.push({ ScreenChoiceId: button.ScreenChoiceId, ScreenId: button.ScreenId, SalesProductId: button.SalesProductId, GroupName: button.GroupName, IsExclusion: button.IsDefault, Comment: this.versionedVal.Obj.Order.Product.Comment });
            this.versionedVal.Obj.Order.Product.Comment = '';
          }
        }
      }

    });
  }

  nonFunctional(x: INavigationAction): Observable<INavigationAction> {
    x.isCancelled = true;
    return of(x);
  }

  setSize(x: INavigationAction): Observable<INavigationAction> {
    console.log('Set Size');
    let selectedButtonFunctionSetSizeParam = _.find(this.functionParameters, function (param) {
      return param.ButtonId == x.button.Id && param.Key == DomainConstants.ButtonFunctionTypes.SET_SIZE.Code
    });
    if (this.versionedVal.Obj.Order.Product.SizeId == selectedButtonFunctionSetSizeParam.Value) {
      this.versionedVal.Obj.Order.Product.SizeId = null;
    } else {
      this.versionedVal.Obj.Order.Product.SizeId = selectedButtonFunctionSetSizeParam.Value ? parseInt(selectedButtonFunctionSetSizeParam.Value) : null;
      this.versionedVal.Obj.Order.Product.SizeButtonId = x.button.Id;
      this.versionedVal.Obj.Order.Product.SizeScreenChoiceId = x.button.ScreenChoiceId;
    }
    this.setIsInStockInButtons();
    return of(x);
  }

  setIsInStockInButtons() {
    _.forEach(this.screenButtons, (screenButton) => {
      _.forEach(screenButton.Buttons, (button) => {
        button.IsInStock = this.buttonNavigationService.setButtonInStock(button, this.versionedVal.Obj.Order.Product.SizeId);
      });
    });

    _.forEach(this.orderNavigation.Buttons, (button) => {
      button.IsInStock = this.buttonNavigationService.setButtonInStock(button, this.versionedVal.Obj.Order.Product.SizeId);
    });
  }

  promptForCommentEntry(x: INavigationAction): Promise<string> {
    this.openCommentEntryKeyboard(x);
    this.promptPromise = new Promise<string>((resolve, reject) => {
      this.resolvePromptPromise = resolve;
      this.rejectPromptPromise = reject;
    });
    return this.promptPromise;
  }

  promptForSetPhoneNumber(x: INavigationAction) {
    if (this.settingParam.PromptForPhoneNumberOnConfirmationScreen) {
      this.rabbitMQService.SendOrderPhoneNumberModalOpenMessage(this.applicationStateService.terminalId);
    }
    const modal = this.modalService.getModalWrapper(PhoneNumberNumpadWrapperComponent);
    const modalRef = modal.show({
      animated: false,
      class: 'vertical-center',
      initialState: {
        numpadTitle: 'Set Phone Number',
        numpadOption: {
          allowDecimal: false, allowLeadingZero: true, allowAlphabets: true, doubleZero: false, isMaskInput: true,
          mask: this.settingParam?.PhoneNumberMask?.trim() ? this.settingParam.PhoneNumberMask : DomainConstants.DefaultPhoneMask,
          maxLength: 10,
          minLength: 10,
          minValidationMessage: 'Please enter a valid phone number',
        },
        value: ' '
      }
    });
    this.phoneNumberModalCloseRabbitMQSubscription = this.rabbitMQService.subscribeToOrderPhoneNumberModalCloseMessage$(this.applicationStateService.terminalId)
      .subscribe({
        next: (res) => modalRef.close.emit(res.Payload),
      })
    return this.toPromise(modalRef.close);

  }

  commentEntryKeyboardSubmit($event, x: INavigationAction) {
    if (x.button.SpecialFunction !== DomainConstants.ButtonFunctionTypes.ADD_COMMENT_UNRESTRICTED.Name) {
      let commentWarningMatch = this.checkRestrictedComment($event);
      if (!commentWarningMatch) {
        this.resolvePromptPromise($event);
      } else {
        this.openCommentWarningModal(commentWarningMatch, x);
      }
    } else {
      this.resolvePromptPromise($event);
    }

  }

  commentEntryKeyboardCancel($event) {
    this.rejectPromptPromise();
  }

  openPagerModal(x: INavigationAction): Promise<any> {
    const modalRef = this.modalService.show(AssignPagerComponent, {
      animated: false,
      initialState: {
        orderId: this.orderId
      },
      class: 'vertical-center modal-lg',
    });
    return this.toPromise(modalRef.close);
  }

  callExternalApi(x: INavigationAction): Observable<INavigationAction> {
    let selectedExternalApi = _.find(this.functionParameters, (param) => {
      return param.ButtonId == x.button.Id && param.Key == DomainConstants.ButtonFunctionTypes.CALL_API.Code;
    });

    let selectedExternalApiParam = _.find(this.functionParameters, (param) => {
      return param.ButtonId == x.button.Id && param.Key == "ExternalApiParams";
    });
    let selectedExternalApiParameters = [];
    if (selectedExternalApiParam?.Value) {
      selectedExternalApiParameters = JSON.parse(selectedExternalApiParam.Value);
    }
    let preparedParams = {};
    if (selectedExternalApiParameters) {
      _.forEach(selectedExternalApiParameters, (param) => {
        preparedParams[param.ParameterId] = param.Value;
      });
    }

    if (selectedExternalApi?.Value) {
      this.spinnerService.show();
      this.externalApiService.executeExternalApi(selectedExternalApi.Value, preparedParams)
        .pipe(finalize(() => {
          this.spinnerService.hide();
        }))
        .subscribe({
          next: (response) => {
            this.alertService.renderInformationalMessage(Messages.ExternalApiCallSuccess);
          }, error: this.alertService.showApiError
        });
    }
    return of(x);
  }

  calculateAmountForPercentageDiscount(discount: number) {
    let discountTotal = 0;
    if (this.orderDetail?.OrderItems) {
      _.forEach(this.orderDetail.OrderItems, (item) => {
        if (item.Price < 0) {
          discountTotal = discountTotal + Math.abs(item.Price);
        }
      });
      const totalOrderAmount = this.orderDetail.SubTotal + discountTotal;
      let totalAmount = ((totalOrderAmount - discountTotal) * (discount / 100)) + discountTotal;
      if (this.validateTotalDiscount((Math.abs(totalAmount) * 100 / totalOrderAmount), this.settingParam.MaximumAllowedDiscount)) { return true; }
    }
  }

  calculateAmountForNumericDiscount(discount) {
    let discountTotal: number = 0;
    if (this.orderDetail?.OrderItems?.length) {
      _.forEach(this.orderDetail.OrderItems, (item) => {
        if (item.Price < 0) {
          discountTotal = discountTotal + Math.abs(item.Price);
        }
      });
      let totalOrderAmount: number = this.orderDetail.SubTotal - discountTotal;

      let totalPercentage = (discountTotal + parseFloat(discount)) * 100 / totalOrderAmount;
      if (this.validateTotalDiscount(Math.abs(totalPercentage), this.settingParam.MaximumAllowedDiscount)) { return true; }
    }
  }

  validateTotalDiscount(totalDiscount, maximumAllowedDiscount) {
    if (totalDiscount.toFixed(2) > maximumAllowedDiscount) {
      this.showInfoMessage(StringUtils.format(Messages.OrderMaxAllowedDiscount,
        { "totalDiscount": totalDiscount.toFixed(2), "maxDiscount": maximumAllowedDiscount.toFixed(2) }));
      return true;
    }
  }

  /** Check is special function end */

  buttonPressed(button: OrderNavigationButton) {
    this.buttonClick(button);
    this.cameraAnnotationService.addAnnotationToCamera(this.cameraAnnotations.OrderNavigationButtonPressed, {
      ButtonText: button.ButtonText,
      ScreenName: this.orderNavigation?.Screen?.Name,
    });
  }

  buttonClick(button: OrderNavigationButton): void {
    // No need to call any functions in case button function type placeholder or nonfunction
    if (!button.IsDisabled && button.SpecialFunction !== DomainConstants.ButtonFunctionTypes.PLACEHOLDER.Name && button.SpecialFunction !== DomainConstants.ButtonFunctionTypes.NON_FUNCTIONAL.Name) {
      console.log('Clicked button: ', button);
      this.ensureOrderPropertiesAreSet(cloneDeep({ button: button, isCancelled: false, orderCommand: {} }))
        .subscribe({
          next: () => {
            this.navigationSubject.next({ button: button, isCancelled: false, orderCommand: {} });
          }
        });
    } else if (button.IsDisabled) {
      const modalRef = this.modalService.getModalWrapper(ButtonWarningsAndTagsComponent);
      const modal = modalRef.show({
        animated: false,
        class: 'vertical-center',
        initialState: {
          button: button,
          filteredWarningsAndTags: this.filteredData
        }
      });
    }
  }

  ensureOrderPropertiesAreSet(orderNavigation): Observable<INavigationAction> {

    return this.checkIsMappedMakeTableTerminal(orderNavigation)
      .pipe(
        this.orderCommandSideEffect(x => this.checkIsDrawerLoaded(x)),
        this.orderCommandSideEffect(x => this.processOrderType(x)),
        this.orderCommandSideEffect(x => this.processSetAccount(x)),
        this.orderCommandSideEffect(x => this.processOrderName(x))
      );
  }

  validateButtonVisibility() {
    this.buttonNavigationService.validateButtonVisibility(this.orderNavigation.Buttons, this.designMode);
  }

  /** Open button menu function start */
  topMenuPosition: string = '';
  openMenuButtonIndex: number = 0;
  public buttonMenu($event: Event, buttonIndex: number): void {
    $event.preventDefault();
    $event.stopPropagation();
    $('.custom-order').css('display', 'none');

    /* Note: We user oldMenuIndex variable for handel exact menu open or close request */
    if (this.oldMenuIndex !== buttonIndex || this.oldMenuIndex == null) {
      this.oldMenuIndex = buttonIndex;
      $('#menu_' + buttonIndex).css('display', 'block');
    } else {
      $('#menu_' + buttonIndex).css('display', 'none');
      this.oldMenuIndex = null;
    }
    if (buttonIndex >= 0 && $('#button_' + buttonIndex).offset()) {
      this.topMenuPosition = $('#button_' + buttonIndex).offset().top + 20 + 'px';
    }
    this.openMenuButtonIndex = buttonIndex;
  }
  // Calculate position from top to control(Set Button setting menu position fixed)
  calculatePosition() {
    if (this.openMenuButtonIndex >= 0 && $('#button_' + this.openMenuButtonIndex).offset()) {
      this.topMenuPosition = $('#button_' + this.openMenuButtonIndex).offset().top + 20 + 'px';
    }
  }

  /** Open button menu function end */


  /** Handel open menu configuration function start */

  public openMenuConfig(menuOption: MenuOptions, button: OrderNavigationButton): void {
    this.menuComponentResolveFactoryService.menuActionExecutor(menuOption, button, this.orderNavigation.Buttons, this.screenButtons);
    const menuSubscription = this.menuComponentResolveFactoryService.modalObserver$.subscribe({
      next: (res) => {
        menuSubscription.unsubscribe();
        if (res?.reload) {

          if (menuOption.Menu === DomainConstants.ButtonMenu.DuplicateButtonOnScreen) {
            this.alertService.renderInformationalMessage(Messages.ReplicateButtonCreated);
          }
          this.getOrderNavigationDetails(this.orderNavigation.Screen.Id);

        }
        if (res?.isNewScreenAdded) {
          this.getScreenData(true);
        }
      }
    });
  }

  /** Handel open menu configuration function end */
  getHeight() {
    if ($(window).width() < 768) {
      this.orderNavigationHeight = ($(window).height() - 370) + 'px';
    } else {
      this.orderNavigationHeight = ($(window).height() - 285) + 'px';
    }
  }


  navigateLinkScreen(index) {
    let i = 0;
    for (i = this.screenNavigationTracker.length; i > index; i--) {
      this.navigateBackward();
    }
  }

  navigateBackward() {
    const currentSizeId = this.versionedVal.Obj.Order.Product.SizeId;
    const previousScreenId = this.orderNavigation.Screen.Id;
    if (this.orderNavigation.Screen.Id != this.homeScreenId) {
      const lastScreenData = this.screenNavigationTracker.pop();
      let lastCommit = this.versionedVal.getLastCommittedVersion();
      if (lastScreenData.Screen.Id == lastCommit.ScreenButtons.Screen.Id) {
        this.orderNavigation = lastCommit.ScreenButtons;
        this.versionedVal.revert(1);
      } else {
        while (lastScreenData.Screen.Id != lastCommit.ScreenButtons.Screen.Id && lastCommit.ScreenButtons.Screen.Id) {
          this.versionedVal.revert(1);
          lastCommit = this.versionedVal.getLastCommittedVersion();
        }
        if (lastCommit.ScreenButtons.Screen.Id) {
          this.orderNavigation = lastCommit.ScreenButtons;
        } else {
          this.navigateToHomeScreen();
        }
        this.scrollTop();
      }

      if (!this.versionedVal.Obj.Order) {
        this.versionedVal.Obj.Order = this.orderService.newOrder();
      }

      this.multipleProductButtonId = this.versionedVal.Obj.Order.Product.MultipleProductButtonId ?? null;
      this.aiShakeId = this.versionedVal.Obj.Order.Product.AiShakeId ?? null;
      this.aiShakeButtonId = this.versionedVal.Obj.Order.Product.AiShakeButtonId ?? null;

      this.validateButtonVisibility();
      this.setNavigationFromBehaviors();
      this.changeBreadcrumbs();
      this.setIsSearch();

      if (this.roundTripParentScreen && this.roundTripParentScreen.Screen.Id == lastScreenData.Screen.Id) {
        this.roundTripParentScreen = null;
        this.roundTripButton = null
      }
      if (!this.versionedVal.Obj.Order) {
        this.versionedVal.Obj.Order = this.orderService.newOrder();
      } else {
        this.setScreenButtonsToVersion(lastCommit.Order);
      }
    }
    if (currentSizeId != this.versionedVal.Obj.Order.Product.SizeId) {
      this.setIsInStockInButtons();
    }
  }
  setScreenButtonsToVersion(order) {
    _.forEach(this.orderNavigation.Buttons, (button) => {
      if (button.IsSelected) {
        _.remove(order.Product.Components, (component: any) => {
          return component.ScreenChoiceId == button.ScreenChoiceId && !button.Toggle;
        });
        if (order.Product.ScreenChoiceId == button.ScreenChoiceId) {
          this.setMainProduct(button, order);
        }

      }
    });
    order.Product.MultipleProductButtonId = this.multipleProductButtonId;
    order.Product.AiShakeId = this.aiShakeId;
    order.Product.AiShakeButtonId = this.aiShakeButtonId;
    this.versionedVal.Obj.Order = order;
  }

  addItemToOrder = () => {
    if (!this.checkMinMaxValidation()) {
      this.getOrderProductQty(this.versionedVal.Obj.Order.Product)
        .subscribe({
          next: (qty: number) => {
            if (qty) {
              this.versionedVal.Obj.Order.Product.Qty = qty;
              this.addTheseItem();
            }
          }
        });
    }
  }

  private setMainProduct(button: OrderNavigationButton, order) {
    if (button.Toggle) {
      const selectedMainProduct = _.find(this.orderNavigation.Buttons, (navigateButton) => {
        return navigateButton.IsSelected && navigateButton.ButtonType == DomainConstants.ButtonTypes.MainProduct;
      });
      if (selectedMainProduct) {
        order.Product.ScreenChoiceId = selectedMainProduct.ScreenChoiceId;
        order.Product.ItemId = selectedMainProduct.SalesProductId;
        this.versionedVal.Obj.Order.Product.DefaultQty = selectedMainProduct.DefaultQty;
        this.versionedVal.Obj.Order.Product.UnitName = selectedMainProduct.UnitName;
        this.versionedVal.Obj.Order.Product.ButtonText = selectedMainProduct.ButtonText;
      }
    } else {
      order.Product.ScreenChoiceId = null;
      order.Product.ItemId = null;
      button.IsSelected = false;
    }
  }

  private canSetOrderProductQty(defaultQty: number): boolean {
    return this.settingParam.AllowOrderingFractionalQty
      && this.settingParam.QuantityChange === DomainConstants.QuantityChange.HASH_BUTTON
      && defaultQty && defaultQty !== 1;
  }

  private getOrderProductQty(product): Observable<number> {
    if (this.canSetOrderProductQty(product.DefaultQty)) {
      return from(this.promptForQtyChange(product)
        .then((response: string) => {
          return response ? parseFloat(response) : null;
        }));
    }
    return of(product.DefaultQty ? product.DefaultQty : 1);
  }


  addProductToOrder(orderProduct) {

    if (this.isContinueMultipleProductNavigation) {
      orderProduct.MultipleProductButtonId = this.multipleProductButtonId;
      orderProduct.isContinueMultipleProductNavigation = true;
    }

    this.orderService.addItemToOrder(this.orderId, orderProduct)
      .pipe(finalize(() => {
        this.eventBroadcastingService.hideInvoiceLoader.emit();
      }))
      .subscribe({
        next: (res: Array<OrderDietaryRestriction>) => {
          if (res?.length) {
            this.dietaryRestrictionWarning(res, orderProduct);
          } else {
            this.onAddItemCompleted(this.orderId, orderProduct.ButtonText);
          }
        }, error: this.alertService.showApiError
      });
  }

  private dietaryRestrictionWarning(res: OrderDietaryRestriction[], orderProduct: any) {
    const dietaryWarningModalRef = this.showDietaryWarningModal(res);

    dietaryWarningModalRef.close.subscribe(response => {
      if (response.shouldReload) {
        const orderDietaryRestrictions = response.orderDietaryRestrictions;
        if (orderDietaryRestrictions?.length) {
          const mainProduct = _.find(orderDietaryRestrictions, (warning) => {
            return warning.SalesProductId == orderProduct.ItemId;
          });
          if (!mainProduct) {
            _.forEach(orderDietaryRestrictions, (warning) => {
              _.remove(orderProduct.Components, (component: any) => {
                return component.SalesProductId == warning.SalesProductId;
              });
            });
          } else { return; }
        }
        orderProduct.IsContinueOrdering = true;
        this.eventBroadcastingService.onAddItemStart.emit();
        this.addProductToOrder(orderProduct);
      }
    });
  }

  private showDietaryWarningModal(res: OrderDietaryRestriction[]) {
    const modalRef = this.modalService.getModalWrapper(OrderDietaryRestrictionComponent);
    const dietaryWarningModalRef = modalRef.show({
      animated: false,
      keyboard: false,
      class: 'vertical-center modal-lg',
      initialState: {
        orderDietaryRestrictions: res
      }
    });
    return dietaryWarningModalRef;
  }

  addTheseItem() {
    if (this.versionedVal?.Obj?.Order?.Product?.Components?.length > 0 || this.versionedVal?.Obj?.Order?.Product?.AdditionalInventories?.length > 0 || this.versionedVal?.Obj?.Order?.Product?.AiShakeId) {
      if (this.versionedVal.Obj.Order.Product && !this.versionedVal.Obj.Order.Product.ItemId) {
        _.forEach(this.versionedVal.Obj.Order.Product.Components, (component) => {
          if (component.SalesProductId > 0 && !this.versionedVal.Obj.Order.Product.ItemId) {
            this.versionedVal.Obj.Order.Product.ItemId = component.SalesProductId;
          }
        })
        if (!this.versionedVal.Obj.Order.Product.ItemId) {
          this.versionedVal.Obj.Order.Product.ItemId = (this.versionedVal?.Obj?.Order?.Product?.AdditionalInventories?.length > 0 ? -4 : -3);
        }
        this.versionedVal.Obj.Order.Product.ScreenChoiceId = this.versionedVal.Obj.Order.Product.Components[0]?.ScreenChoiceId ?? this.versionedVal?.Obj?.Order?.Product?.AdditionalInventories[0]?.ScreenChoiceId;
      }
      this.versionedVal.Obj.Order.TerminalId = this.applicationStateService.terminalId;
      this.versionedVal.Obj.Order.Product.SubAccountOrdinal = this.subaccountOrdinal;
      this.eventBroadcastingService.onAddItemStart.emit();
      this.navigationStartOn = performance.now();

      let orderProduct = cloneDeep(this.versionedVal.Obj.Order.Product);
      if (this.orderId > 0) {
        orderProduct.IsContinueOrdering = false;
        this.addProductToOrder(orderProduct);

      } else if (this.isOrderInitiate) {
        orderProduct.IsContinueOrdering = false;
        this.initiateOrderProduct = orderProduct;
      } else {
        this.versionedVal.Obj.Order.Products.push(this.versionedVal.Obj.Order.Product);

        this.isOrderInitiate = true;
        this.versionedVal.Obj.Order.IsTrainingOrder = this.isTrainingOrder;
        this.versionedVal.Obj.Order.OrderSubaccounts.push({
          Id: 0,
          OrderId: 0,
          SubaccountName: null,
          SubaccountOrdinal: 1
        });
        this.checkAndSetOrderType();
        this.orderService.createOrder(this.versionedVal.Obj.Order)
          .pipe(finalize(() => {
            this.isOrderInitiate = false;
          }))
          .subscribe({
            next: (res: any) => {
              this.navigationEndOn = performance.now();
              this.orderDetail = res;
              this.onAddItemCompleted(res.Id.Value, orderProduct.ButtonText);
              this.orderId = res.Id.Value;
              this.checkInitiateOrderProduct();
              this.audioOperationsService.tagAudioRecord(DomainConstants.AudioInteractions.NewOrder, this.orderId.toString());
            }, error: (err) => {
              this.alertService.showApiError(err);
              this.eventBroadcastingService.onAddItemComplete.emit();
            }
          });
      }
      const nextScreenId = this.orderNavigation.Screen.MultiProductNextScreens.find(x => x.MultiProductGroupId == this.multipleProductGroupId)?.MultiProductNextScreenId;
      this.completeNavigation(this.multipleProductButtonId > 0 ? nextScreenId : 0);
    }
  }

  checkAndSetOrderType() {
    if (!this.versionedVal.Obj.Order.OrderTypeId) {
      const availableServeMethods = Object.keys(this.serveMethods).map(key => ({ ServeMethodId: key, value: this.serveMethods[key] }));
      this.setOrderTypeId(availableServeMethods);
    }
  }

  checkInitiateOrderProduct() {
    if (this.initiateOrderProduct) {
      this.addProductToOrder(this.initiateOrderProduct);
      this.initiateOrderProduct = null;
    } else if (this.initiateScannedOrderProduct) {
      this.addScannedProductToOrder(this.initiateScannedOrderProduct);
      this.initiateScannedOrderProduct = null;
    }
  }
  checkInitiateOrderCommand() {
    if (this.initiateOrderCommand) {
      if (this.initiateOrderCommand.orderCommand.type === DomainConstants.SpecialFunctionBusinessCommand.DietaryRestriction) {
        this.dietaryRestriction(this.initiateOrderCommand);
        this.initiateOrderCommand = null;
      } else {
        this.addSpecialFunction(this.initiateOrderCommand.orderCommand);
        this.initiateOrderCommand = null;
      }
    }
  }

  setButtonBgColor(od) {
    if (od.IsSelected && this.settingParam.ChangeBackgroundColorOnToggleButton) {
      od.Color = od.ToggleColor ? od.ToggleColor :
        (od.IsDefault || od.GroupName ? this.settingParam.DefaultButtonActiveStateBackgroundColor : this.settingParam.ToggleButtonActiveStateBackgroundColor);
    } else {
      od.Color = od.OriginalColor;
    }
  }


  setIsSearch() {
    this.buttonNavigationService.setIsSearch(this.orderNavigation.Buttons, this.filteredButtons);
  }

  addNextButton() {
    let nextButton: OrderNavigationButton = this.orderService.newButton();
    nextButton.ButtonText = 'Next';
    nextButton.Toggle = false;
    nextButton.Image = this.settingParam.AutoNextButtonIcon;
    nextButton.ButtonType = DomainConstants.ButtonTypes.NavigationOnly;
    this.orderNavigation.Buttons.push(nextButton);
  }

  search($event) {
    const searchButtonInterval = setTimeout(() => {
      this.searchButtons = [];
      this.filteredButtons = [];
      if (this.searchValue.length > 2) {
        const searchData = this.buttonNavigationService.searchFromArray(this.allButtons, this.searchValue);
        this.searchButtons = orderBy(searchData.buttons, 'ButtonText');
        this.filteredButtons = searchData.buttonsHavingSearchValue;
      }
      this.setIsSearch();
      if (searchButtonInterval) {
        clearInterval(searchButtonInterval);
      }
    });
  }

  selectSearch(event) {
    this.searchButtons = [];
    this.filteredButtons = [];
    const searchData = this.buttonNavigationService.searchFromArray(this.allButtons, event?.value?.ButtonText ?? event?.ButtonText);
    this.searchButtons = searchData.buttons;
    this.filteredButtons = searchData.buttonsHavingSearchValue;
    this.setIsSearch();
  }
  clearButtonSearch() {
    this.searchButtons = [];
    this.filteredButtons = [];
    this.searchValue = '';
    this.setIsSearch();
  }

  changeBreadcrumbs() {
    const screenNavigationTrack = cloneDeep(this.screenNavigationTracker);
    this.screenTrackLimit = this.screenNavigationTracker.length;
    this.setScreenTrackLimit(screenNavigationTrack);
    this.scrollTop();
  }

  setScreenTrackLimit(newArray: Array<ScreenButtons>) {
    let linkWidth = 0;
    const totalScreenWidth = $(window).width();
    const navigationBarTotalWidth = $('.navigationBar').width();
    _.forEach(newArray, function (track, index) {
      $('#spanWidthGetter').text(track.Screen.Name);
      const currentTrackWidth = $('#spanWidthGetter').width() + 40;
      linkWidth += currentTrackWidth;
      $('#spanWidthGetter').text('');
    });
    if (this.screenNavigationTracker?.length && this.screenNavigationTracker[0].Screen.Name) {
      $('#spanWidthGetter').text(this.screenNavigationTracker[0].Screen.Name);
    }
    const titleWidth = $('#spanWidthGetter').width() + 200;
    if (navigationBarTotalWidth && totalScreenWidth > 767 && (linkWidth + titleWidth) > (navigationBarTotalWidth - 120)) { //400
      if (this.screenTrackLimit > 1) {
        newArray.splice(1, 1);
        this.screenTrackLimit -= 1;
        this.setScreenTrackLimit(newArray);
      }
    }

  }

  /** Button behaviors actions start */

  executeButtonBehaviors(navObj: INavigationAction, orderNavigation: ScreenButtons) {
    const buttonBehaviors = _.orderBy(navObj.button.Behaviors, 'Ordinal');

    return buttonBehaviors.reduce(
      (p, x) =>
        p.then(_ => this.executeButtonBehavior(x, navObj, orderNavigation)),
      Promise.resolve()
    )

  }

  executeButtonBehavior(behavior: ButtonBehavior, navObj: INavigationAction, orderNavigation: ScreenButtons) {
    try {
      const behaviorValues = JSON.parse(behavior.TextValue);
      if (behavior.BehaviorName === this.buttonBehaviorTypes.DisplayMessage.Value) {
        return this.displayMessage(behaviorValues.message);
      } else if (behavior.BehaviorName === this.buttonBehaviorTypes.DisplayToaster.Value) {
        return this.displayToaster(behaviorValues.message);
      } else if (behavior.BehaviorName === this.buttonBehaviorTypes.FlashButtons.Value) {
        return this.flashButtonsBehavior(behaviorValues.buttonIds, behaviorValues.seconds);
      } else if (behavior.BehaviorName === this.buttonBehaviorTypes.SelectButtons.Value) {
        return this.selectButtons(behaviorValues.buttonIds);
      } else if (behavior.BehaviorName === this.buttonBehaviorTypes.UncheckButtons.Value) {
        return this.uncheckButtons(behaviorValues.buttonIds);
      } else if (behavior.BehaviorName === this.buttonBehaviorTypes.UncheckAllButtons.Value) {
        return this.uncheckAllButtons();
      } else if (behavior.BehaviorName === this.buttonBehaviorTypes.SendPressEvent.Value) {
        return this.sendButtonPressEvent(navObj.button, orderNavigation);
      } else if (behavior.BehaviorName === this.buttonBehaviorTypes.TemporaryNav.Value) {
        return this.temporaryNavigation(behaviorValues.screenId, navObj);
      } else if (behavior.BehaviorName === this.buttonBehaviorTypes.IncrementInventory.Value) {
        return this.incrementInventory(behaviorValues.inventoryProductId, behaviorValues.qty, navObj.button.Id);
      } else if (behavior.BehaviorName === this.buttonBehaviorTypes.DecrementInventory.Value) {
        return this.decrementInventory(behaviorValues.inventoryProductId, behaviorValues.qty, navObj.button.Id);
      } else if (behavior.BehaviorName === this.buttonBehaviorTypes.SignIn.Value) {
        return this.signInUser();
      } else if (behavior.BehaviorName === this.buttonBehaviorTypes.MultipleProduct.Value && !this.multipleProductButtonId) {
        this.multipleProductButtonId = navObj.button.Id;
        this.multipleProductGroupId = behaviorValues.groupId;
        this.versionedVal.Obj.Order.Product.MultipleProductButtonId = navObj.button.Id;
        return Promise.resolve();
      } else if (behavior.BehaviorName === this.buttonBehaviorTypes.AddAiShake.Value) {
        return this.selectAiShake(navObj.button.Id, navObj.button.ScreenChoiceId);
      }
    } catch (ex) {
      console.error('Invalid ' + behavior.BehaviorName + ' behavior value: ' + behavior.TextValue);
    }
  }

  displayMessage = (message: string) => {
    const modalRef = this.modalService.show(InfoModalComponent, {
      animated: false,
      class: 'vertical-center',
      initialState: {
        message: message
      }
    });
    return this.toPromise(modalRef.close).then(() => { }, () => { });
  }

  signInUser = () => {
    const modalRef = this.modalService.show(SignInComponent, {
      animated: false,
      class: 'vertical-center modal-max-width-65'
    });
    const userCode = this.applicationStateService.userCode;
    return this.toPromise(modalRef.close).then((res) => {
      if (res?.shouldReload) {
        this.setDefaults();
        Promise.resolve();
      } else {
        this.applicationStateService.userCode = userCode;
        this.rejectSignInPromise(null);
      }
    }, () => { this.applicationStateService.userCode = userCode; this.rejectSignInPromise(null); })
  }

  selectAiShake = (buttonId: number, screenChoiceId: number) => {
    const modalRef = this.modalService.show(SelectAiShakeComponent, {
      animated: false,
      class: 'vertical-center'
    });

    return this.toPromise(modalRef.close)
      .then((res) => {
        if (res?.shakeId) {
          this.aiShakeId = res.shakeId;
          this.versionedVal.Obj.Order.Product.AiShakeId = this.aiShakeId;
          this.versionedVal.Obj.Order.Product.ScreenChoiceId = screenChoiceId;
          this.versionedVal.Obj.Order.Product.ItemId = -5;
          this.aiShakeButtonId = buttonId;
          return Promise.resolve();
        } else {
          return Promise.reject();
        }
      })
      .catch(() => Promise.reject());
  }


  getFilteredData(res) {
    this.filteredData = res;
    forEach(this.screenButtons, (screenButton) => {
      forEach(screenButton.Buttons, (button: OrderNavigationButton) => {
        let filteredObj = this.checkButtonIsInFilterForDisable(res, button);
        button.IsDisabled = filteredObj?.isDisabled;
        button.IsHide = filteredObj?.isHide;
        button.IsAppliedFilter = filteredObj?.isDisabled || filteredObj?.isHide;
      });
    });
    if (this.orderNavigation?.Buttons?.length) {
      forEach(this.orderNavigation.Buttons, (button: OrderNavigationButton) => {
        let filteredObj = this.checkButtonIsInFilterForDisable(res, button);
        button.IsDisabled = filteredObj?.isDisabled;
        button.IsHide = filteredObj?.isHide;
        button.IsAppliedFilter = filteredObj?.isDisabled || filteredObj?.isHide;
      });
    }
  }

  displayToaster(message: string) {
    this.alertService.renderSuccessMessage(message);
    return Promise.resolve();
  }

  flashButtonsBehavior = (buttonIds: Array<number>, seconds: number) => {
    this.setFlashButtons(buttonIds, true);
    return new Promise(res => {
      const flashButtonInterval = setTimeout((r) => {
        this.setFlashButtons(buttonIds, false);
        if (flashButtonInterval) {
          clearInterval(flashButtonInterval);
        }
        res(null);
      }, seconds * 1000);


    });
  }

  checkButtonIsInFilterForDisable(filteredData, button: OrderNavigationButton) {
    let filterAction = { isDisabled: false, isHide: false };
    let matchedDietary = [];
    if (filteredData?.DietaryWarnings?.length && button.DietaryWarnings?.length) {
      matchedDietary = _.intersectionBy(button.DietaryWarnings, filteredData.DietaryWarnings, 'Id');
      if (matchedDietary?.length) {
        filteredData.filterAction === DomainConstants.OrderNavigationFilterActions.DISABLE ? filterAction.isDisabled = true : filterAction.isHide = true;
      }
    }
    if (!matchedDietary?.length && !button.NoTags && filteredData?.Tags?.length) {
      let isMatchedTags = false;
      if (button.SalesProductTags?.length) {
        const matchedTags = _.intersectionBy(button.SalesProductTags, filteredData.Tags, 'TagId');
        if ((matchedTags && matchedTags.length === filteredData.Tags.length) || !button.SalesProductId) {
          isMatchedTags = true;
        } else {
          isMatchedTags = false;
        }
      } else {
        isMatchedTags = button.SalesProductId ? false : true;
      }
      filteredData.filterAction === DomainConstants.OrderNavigationFilterActions.DISABLE ? filterAction.isDisabled = !isMatchedTags : filterAction.isHide = !isMatchedTags;
    }
    return filterAction;
  }

  selectButtons(buttonIds: Array<number>) {
    this.changeButtonSelectionState(buttonIds, true);
    return Promise.resolve();
  }

  uncheckButtons(buttonIds: Array<number>) {
    this.changeButtonSelectionState(buttonIds, false);
    return Promise.resolve();
  }

  uncheckAllButtons() {
    this.uncheckAllToggles();
    return Promise.resolve();
  }

  temporaryNavigation(screenId: number, navObj: INavigationAction) {
    this.moveToTemporaryNavigation(screenId, navObj);
    return Promise.resolve();
  }

  incrementInventory(inventoryProductId: number, qty: number, buttonId: number) {
    this.inventoryProductService.updateInventoryQtyFromButtonBehavior(inventoryProductId, qty, buttonId)
      .subscribe({ next: () => { }, error: this.alertService.showApiError });
    return Promise.resolve();
  }

  decrementInventory(inventoryProductId: number, qty: number, buttonId: number) {
    this.inventoryProductService.updateInventoryQtyFromButtonBehavior(inventoryProductId, qty * -1, buttonId)
      .subscribe({ next: () => { }, error: this.alertService.showApiError });
    return Promise.resolve();
  }

  sendButtonPressEvent(button: OrderNavigationButton, orderNavigation: ScreenButtons) {
    let notificationModel = {
      ScreenChoiceID: button.ScreenChoiceId,
      Title: orderNavigation.Screen.Name,
      Selection: button.ButtonText,
      UserId: this.userDetails.id,
      Username: this.userDetails.firstname + ", " + this.userDetails.lastname,
      ButtonId: button.Id
    }
    this.orderService.notificationButton(notificationModel)
      .subscribe({
        next: () => {
        }, error: this.alertService.showApiError
      });
    return Promise.resolve();
  }


  changeButtonSelectionState(buttonIds: Array<number>, status: boolean) {
    this.orderNavigation.Buttons.forEach(btn => {
      if (buttonIds.includes(btn.Id) && ((status && !btn.IsSelected) || (!status && btn.IsSelected)) && btn.Toggle) {
        this.buttonClick(btn);
      }
    });
  }

  private setFlashButtons = (buttonIds: Array<number>, status: boolean) => {
    this.orderNavigation.Buttons.forEach(btn => {
      if (buttonIds.includes(btn.Id)) {
        btn.IsFlash = status;
      }
    });
  }

  moveToTemporaryNavigation(screenId: number, navigation: INavigationAction) {
    this.roundTripParentScreen = cloneDeep(this.orderNavigation);
    this.roundTripButton = cloneDeep(navigation.button);
    this.setScreenButtons(navigation);
  }
  /** Button behaviors actions end */

  scrollTop() {
    $('.thick-scrollbar').animate({ scrollTop: 0 }, 'medium');
  }

  openSelectInventoryToDeductModal(x: INavigationAction) {
    if ((x.button.Toggle && !x.button.IsSelected) || !x.button.Toggle) {
      const modal = this.modalService.getModalWrapper(DeductInventoryProductComponent);
      const modalRef = modal.show({
        keyboard: false,
        animated: false,
        class: 'vertical-center',
        initialState: {
          button: x.button,
          additionalInventories: this.versionedVal.Obj.Order.Product.AdditionalInventories
        }
      });
      return from(this.toPromise(modalRef.close)
        .then((res: any) => {
          if (res?.InventoryProductToDeduct) {
            const existingInventory = this.versionedVal.Obj.Order.Product.AdditionalInventories?.findIndex(y => y.ScreenChoiceId == res.InventoryProductToDeduct.ScreenChoiceId && y.InventoryProductId == res.InventoryProductToDeduct.InventoryProductId)
            if (existingInventory >= 0) {
              const existingQty = this.versionedVal.Obj.Order.Product.AdditionalInventories[existingInventory].Qty;
              this.versionedVal.Obj.Order.Product.AdditionalInventories[existingInventory].Qty = Number(this.decimalPipe.transform(existingQty + res.InventoryProductToDeduct.Qty, '0.2-4')?.replace(',', ''));

            }
            else {
              res.InventoryProductToDeduct.Qty = Number(this.decimalPipe.transform(res.InventoryProductToDeduct.Qty, '0.2-4')?.replace(',', ''));
              this.versionedVal.Obj.Order.Product.AdditionalInventories.push(res.InventoryProductToDeduct);
            }
          } else {
            x.isCancelled = true;
          }
          return x;
        })
      );
    } else {
      _.remove(this.versionedVal.Obj.Order.Product.AdditionalInventories, (inventory) => {
        return inventory.ScreenChoiceId == x.button.ScreenChoiceId;
      });
      return of(x);
    }

  }

  getProductByBarcode(barcode: string) {
    let foundProducts = this.salesProductBarcodes.filter(x => x.Barcode.toLowerCase() == barcode.toLowerCase());
    if (foundProducts.length) {
      this.ensureOrderPropertiesAreSet(cloneDeep({ isCancelled: false, orderCommand: {} }))
        .subscribe({
          next: () => {
            if (foundProducts.length > 1) {
              this.openProductSelectionModal(foundProducts);
            } else {
              this.setScannedProductQty(cloneDeep(foundProducts[0]));
            }
          }
        });
    } else {
      this.alertService.renderErrorMessage("No product is found with the entered barcode.");
    }
  }

  private setScannedProductQty(selectedProduct: SalesProductBarcode) {
    if (this.checkScannedProductIsInStock(selectedProduct)) {
      if (!selectedProduct.Qty) {
        let product = { DefaultQty: 1, UnitName: selectedProduct.SalesProduct?.SalesUnit?.Name };
        from(this.promptForQtyChange(product)
          .then((response: string) => {
            selectedProduct.Qty = response ? parseFloat(response) : null;
            this.createOrAddScannedProduct(selectedProduct);
          }));
      } else {
        this.createOrAddScannedProduct(selectedProduct);
      }
    }
  }

  private createOrAddScannedProduct(selectedProduct: SalesProductBarcode) {
    let scannedProduct = this.prepareScannedSalesProduct(selectedProduct);
    if (this.orderId) {
      this.addScannedProductToOrder(scannedProduct);
    } else if (this.isOrderInitiate) {
      scannedProduct.IsContinueOrdering = false;
      this.initiateScannedOrderProduct = scannedProduct;
    } else {
      this.createOrderAndAddScannedProduct(selectedProduct);
    }
  }

  openProductSelectionModal(foundProducts: Array<SalesProductBarcode>) {
    foundProducts = orderBy(foundProducts, ['SalesProduct.Name', 'SalesSize.Ordinal']);
    const modalRef = this.modalService.getModalWrapper(BarcodeProductSelectionComponent);
    const modal = modalRef.show({
      animated: false,
      keyboard: false,
      class: 'vertical-center',
      initialState: {
        barcodeProducts: foundProducts
      }
    });
    modal.close.subscribe((res) => {
      if (res?.product) {
        this.setScannedProductQty(cloneDeep(res.product));
      }
    });
  }

  prepareScannedSalesProduct(selectedProduct: SalesProductBarcode) {
    let product: ScannedProduct = {
      SubaccountOrdinal: this.subaccountOrdinal,
      Qty: selectedProduct.Qty ?? 1,
      SalesProductId: selectedProduct.SalesProductId,
      SalesSizeId: selectedProduct.SalesSizeId,
      Barcode: selectedProduct.Barcode,
      IsContinueOrdering: false,
      SalesProductName: selectedProduct.SalesProduct?.Name
    };
    return product;
  }

  createOrderAndAddScannedProduct(selectedProduct: SalesProductBarcode) {
    let product = this.prepareScannedSalesProduct(selectedProduct);
    if (!this.orderId) {
      let order: ScannedProductOrder = {
        IsTrainingOrder: this.isTrainingOrder,
        OrderTypeId: this.versionedVal.Obj.Order.OrderTypeId,
        OrderProduct: product,
        CustomerAccountId: this.customerAccountId,
        AccountId: this.accountId
      }
      this.isOrderInitiate = true;
      this.orderService.createScannedProductOrder(order)
        .pipe(finalize(() => {
          this.isOrderInitiate = false;
          this.accountId = null;
          this.customerAccountId = null;
        }))
        .subscribe({
          next: (res: any) => {
            this.navigationEndOn = performance.now();
            this.orderDetail = res;
            this.onAddItemCompleted(res.Id.Value, product.SalesProductName);
            this.orderId = res.Id.Value;
            this.audioOperationsService.tagAudioRecord(DomainConstants.AudioInteractions.NewOrder, this.orderId.toString());
          }, error: (err) => {
            this.alertService.showApiError(err);
            this.eventBroadcastingService.onAddItemComplete.emit();
          }
        });
    } else {
      this.addScannedProductToOrder(product);
    }
  }

  checkScannedProductIsInStock(scannedProduct: SalesProductBarcode): boolean {
    let salesProduct = scannedProduct.SalesProduct;
    const isInStock = salesProduct.SalesProductSizes.find(x => x.SizeId == scannedProduct.SalesSizeId)?.IsInStock;
    if (!isInStock && !salesProduct.IsAllowOutOfStockOrder) {
      this.showInfoMessage(StringUtils.format(salesProduct.SalesProductSizes?.length > 1 ? Messages.OutOfStockProductMessageForMultipleSizes : Messages.OutOfStockProductMessage, { 'salesProduct': salesProduct.Name }));
      return false;
    }
    else if (!salesProduct.IsActive) {
      this.showInfoMessage(StringUtils.format(Messages.InactiveProductMessage, { 'salesProduct': salesProduct.Name }));
      return false;
    }
    return true;
  }

  addScannedProductToOrder(scannedProduct: ScannedProduct) {
    this.eventBroadcastingService.onAddItemStart.emit();
    this.navigationStartOn = performance.now();

    this.orderService.addScannedItemToOrder(this.orderId, scannedProduct)
      .pipe(finalize(() => {
        this.eventBroadcastingService.hideInvoiceLoader.emit();
      }))
      .subscribe({
        next: (res: Array<OrderDietaryRestriction>) => {
          if (res?.length) {
            const dietaryWarningModalRef = this.showDietaryWarningModal(res);
            dietaryWarningModalRef.close.subscribe(response => {
              const orderDietaryRestrictions = response.orderDietaryRestrictions;
              if (orderDietaryRestrictions?.length) {
                const mainProduct = _.find(orderDietaryRestrictions, (warning) => {
                  return warning.SalesProductId == scannedProduct.SalesProductId;
                });
                if (mainProduct) {
                  return;
                }
              }
              scannedProduct.IsContinueOrdering = true;
              this.eventBroadcastingService.onAddItemStart.emit();
              this.addScannedProductToOrder(scannedProduct);
            });
          } else {
            this.onAddItemCompleted(this.orderId, scannedProduct.SalesProductName);
          }
        }, error: this.alertService.showApiError
      });
  }
}
