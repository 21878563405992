<pos-list-wrapper [headingIcon]="icons.loyaltyProgramAttribute" heading="Attributes" (close)="close()">
    <div class="row mt-4">
        <div class="col-12">
            <p-table [value]="loyaltyFormAttributes" #ptable (onRowReorder)="gridRowReorder($event)" 
            [columns]="loyaltyFormAttributeColumns">

                <ng-template pTemplate="caption">
                    <div class="float-right">
                        <div class="p-inputgroup align-items-center">
                            <input class="p-inputtext p-widget p-state-default p-corner-all p-component"
                                pInputText placeholder="Filter..." [(ngModel)]="gridContext.Filter"
                                (input)="ptable.filterGlobal($event.target.value, 'contains')"
                                #searchBox>
                        </div>
                    </div>
                </ng-template>

                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th style="width: 2em"></th>
                        <th *ngFor="let col of columns" class="font-weight-bold"
                                [pSortableColumn]="col.Field" [pSortableColumnDisabled]="!col.IsSortable"
                                [ngStyle]="{'width': col.Width, 'word-wrap':'break-word'}">
                                {{col.HeaderText}}
                                <p-sortIcon *ngIf="col.IsSortable" [field]="col.Field"></p-sortIcon>
                        </th>
                        <th style="width: 60px !important;" class="cell-edit" *ngIf="editPermission" colspan="2">
                            <div title="New" (click)="editLoyaltyFormAttribute(0)">
                                <pos-action-icon [icon]="icons.plusWhite" iconText="Add"
                                    class="no-wrap" backgroundClasses="bg-add d-inline-block">
                                </pos-action-icon>
                            </div>
                        </th>
                        <th style="width: 89px !important;" class="cell-delete pli-5"
                            *ngIf="editPermission || readOnlyPermission" colspan="2">
                            <pos-export-dropdown class="d-inline-block" (onPrint)="printDiv()"
                                (onCSV)="exportCSV()"></pos-export-dropdown>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-record let-i="rowIndex" let-columns="columns">
                    <tr [pReorderableRow]="i">
                        <td class="vertical-mid-imp" [class.movement-disabled]="isSystemDefinedAttribute(record)">
                            <i *ngIf="!isSystemDefinedAttribute(record)" [ngClass]="icons.ordinalBars.iconClasses" pReorderableRowHandle></i>
                        </td>
                        <ng-container *ngFor="let col of columns">
                            <td class="font-weight-bold" [ngStyle]="{'width': col.Width, 'word-wrap':'break-word'}" [class.movement-disabled]="isSystemDefinedAttribute(record)">
                                {{record[col.Field]}}
                            </td>
                        </ng-container>
                        <td colspan="4" *ngIf="editPermission" class="cell-edit text-center pointer cell-padding vertical-mid">
                            <a title="Edit" class="pointer" (click)="editLoyaltyFormAttribute(record)" scrollup>
                                <pos-action-icon [icon]="icons.editWhite"></pos-action-icon>
                            </a>
                            <a title="Delete" class="pointer" (click)="deleteAccountAttributeType(record)" scrollup *ngIf="!isSystemDefinedAttribute(record)">
                                <pos-action-icon [icon]="icons.deleteWhite"></pos-action-icon>
                            </a>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage" let-columns>
                    <tr>
                        <td [attr.colSpan]="7">
                            No records found
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</pos-list-wrapper>
<pos-confirm-delete (onConfirmAcknowledge)="onConfirmAcknowledge()" #confirmAccountAttributeTypeDelete>
</pos-confirm-delete>
<pos-print-table #printTableReference [hidden]="true"></pos-print-table>
