import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RuntimeConstants } from '../../../shared/constants';
import { AlertsService, ApplicationFeaturesService, ApplicationStateService, OrderService, UserIdleService } from 'src/app/shared/services';
import { bellOn, DomainConstants, Features, ICloseable, ModalService, PhoneMaskPipe, SettingParam, SpinnerService } from 'src/app/shared';
import { finalize } from 'rxjs/operators';
import { ConfirmOrderService, KioskNavigationService } from '../../services';
import { ConfirmOrderStyle } from '../../interfaces/config/confirm-order-style';
import { KioskNumpadComponent } from '../kiosk-numpad';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

@Component({
  selector: 'pos-kiosk-order-confirmed',
  templateUrl: './kiosk-order-confirmed.component.html',
  styleUrls: ['./kiosk-order-confirmed.component.scss']
})
export class KioskOrderConfirmedComponent {
  baseImagePath = `${RuntimeConstants.IMAGE_BASE_PATH}/order-kiosk/default-screens/`;
  image = 'order-kiosk/thank-you.png';
  displayText = "TRANSACTION COMPLETE";
  orderAppreciationText = "";
  orderNumber: number;
  timeoutSeconds = 30;
  timeout: NodeJS.Timeout;
  style: ConfirmOrderStyle;
  isAskForNotifyViaSMS = false;
  notifyMessage = null;
  enteredPhoneNumber: string = null;
  settingParam: SettingParam;
  icons = {
    bellOn
  }
  numpadRef: ICloseable;
  userIdleSubscription: Subscription;
  constructor(private router: Router, private route: ActivatedRoute,
    private applicationStateService: ApplicationStateService,
    private spinnerService: SpinnerService,
    private orderService: OrderService,
    private kioskNavigationService: KioskNavigationService,
    private alertService: AlertsService,
    private applicationFeatureService: ApplicationFeaturesService,
    private orderConfirmService: ConfirmOrderService,
    private userIdleService: UserIdleService,
    private sanitizer: DomSanitizer,
    private phoneMaskPipe: PhoneMaskPipe,
    private modalService: ModalService
  ) {
    this.style = orderConfirmService.newConfirmOrderStyle();
  }

  ngOnInit() {
    if (!this.kioskNavigationService.orderDetails) {
      this.router.navigate(['..'], { relativeTo: this.route });
    } else {
      const settingParam = this.applicationStateService?.settingParam;
      if (settingParam) {
        this.image = settingParam.OrderKioskOrderConfirmImage;
        this.orderAppreciationText = settingParam.OrderKioskOrderConfirmAppreciationText;
        this.timeoutSeconds = settingParam.OrderKioskOrderConfirmScreenIdleTimeout ?? 30;
        if (settingParam.OrderKioskOrderConfirmStyle) {
          this.style = JSON.parse(settingParam.OrderKioskOrderConfirmStyle) as ConfirmOrderStyle;
        }
        this.notifyMessage = settingParam.OrderKioskPhoneNumberInquiryText ? this.sanitizer.bypassSecurityTrustHtml(settingParam.OrderKioskPhoneNumberInquiryText) : null;
        this.isAskForNotifyViaSMS = settingParam.ItemReadyShowNotifyButtonOnServeScreen && settingParam.ItemReadyNotifyViaSMSText && this.applicationFeatureService.isEnableFeature(Features.SMSNotification);
      }
      this.orderNumber = this.kioskNavigationService.orderDetails.SurrogateOrderId;
      this.printOrder();
      this.startOrderConfirmTimer();
    }
  }

  private startOrderConfirmTimer() {
    this.timeout = setTimeout(() => {
      this.redirectToHome();
    }, this.timeoutSeconds * 1000);
  }

  redirectToHome(data = {}) {
    if (this.numpadRef) this.numpadRef.close.emit();
    clearTimeout(this.timeout);
    this.kioskNavigationService.resetProps();
    this.kioskNavigationService.completeNavigation();
    const navigationTimeout = setTimeout(() => {
      this.router.navigate(['home'], { relativeTo: this.route.parent, state: data });
      clearTimeout(navigationTimeout);
    }, 200);
  }

  printOrder = () => {
    const order = this.kioskNavigationService.orderDetails;
    this.settingParam = this.applicationStateService.settingParam;
    const printOrderModel = {
      OrderId: order.Id.Value,
      TerminalId: this.applicationStateService.terminalId,
      Parameter: this.settingParam,
      PaidAmount: order.PaidAmount,
      SurrogateOrderId: order.SurrogateOrderId,
      ReceiptPrinterId: this.settingParam?.ReceiptPrinter?.Id,
      PrintSeatDetails: []
    };
    this.spinnerService.show();
    this.orderService.printCheckWithQR(printOrderModel)
      .pipe(finalize(() => {
        this.spinnerService.hide()
      }))
      .subscribe({
        next: (res) => {
        }, error: this.alertService.showApiError
      });
  }

  openNumpad(event) {
    event.stopPropagation();
    clearTimeout(this.timeout);
    this.subscribeToUserIdleSubscription();
    this.numpadRef = this.modalService.show(KioskNumpadComponent, {
      'backdrop': 'static',
      keyboard: false,
      class: 'vertical-center kiosk-numpad modal-max-width-40',
      initialState: {
        qty: ' ',
        heading: 'Enter Phone Number',
        numpadOptions: {
          allowDecimal: false, allowLeadingZero: true, allowAlphabets: false, doubleZero: false, isMaskInput: true,
          mask: this.settingParam?.PhoneNumberMask?.trim() ? this.settingParam.PhoneNumberMask : DomainConstants.DefaultPhoneMask,
          maxLength: 10, minLength: 10, minValidationMessage: 'Please enter a valid phone number',
        },
      }
    });
    this.numpadRef.close.subscribe(res => {
      if (res?.qty) {
        this.updatePhoneNumber(res.qty);
      }
    });
  }

  updatePhoneNumber(phoneNumber) {
    this.spinnerService.show();
    this.orderService.updatePhoneNumber(this.kioskNavigationService.orderDetails.Id.Value, phoneNumber)
      .pipe(finalize(() => {
        this.spinnerService.hide()
      }))
      .subscribe({
        next: (res) => {
          this.enteredPhoneNumber = `Phone Number: ${this.phoneMaskPipe.transform(phoneNumber)}`;
          this.startOrderConfirmTimer();
        }, error: this.alertService.showApiError
      });
  }

  subscribeToUserIdleSubscription() {
    if (this.userIdleSubscription) {
      this.userIdleSubscription.unsubscribe();
    }
    this.userIdleSubscription = this.userIdleService.initialize(this.timeoutSeconds, () => this.userGoneIdle());
  }

  stopUserIdleService() {
    this.userIdleService.stop();
    this.userIdleSubscription?.unsubscribe();
  }

  userGoneIdle = () => {
    this.stopUserIdleService();
    this.redirectToHome();
  }

}
