<pos-edit-wrapper  (close)="onCancel()" [isPopup]="false" [headingIcon]="icons.exclamationTriangle" [heading]="'Dietary Warnings' + (originalName ? ': ' + originalName : '')">
    <form #dietaryWarningForm="ngForm" (ngSubmit)="saveDietaryWarning(dietaryWarningForm.valid)" autocomplete="off">

        <div class="col-12 pos-page-body border-top">
            <div class="col-12 pt-pix-5">
              <pos-row>
                <pos-column>
                  <pos-form-field label="Name" [validationMessages]="{required: 'Please enter raw ingredient name.'}"
                    inputStyle="col-lg-4 col-md-4 col-sm-4 col-xs-8">
                    <input posInput class="form-control" name="name" [(ngModel)]="dietaryWarningsData.Name"
                      [required]="true">
                  </pos-form-field>
                </pos-column>

                <pos-column>
                  <pos-form-field>
                    <p-checkbox name="IsOverridable" [(ngModel)]="dietaryWarningsData.IsOverridable" label="Overridable" binary="true"></p-checkbox>
                  </pos-form-field>
                </pos-column>

                <pos-column>
                  <pos-form-field>
                    <div class="row">
                      <div class="col-12 col-lg-8">
                        <input posInput type="file" class="form-control" accept=".png,.jpg,.jpeg,.gif,.bmp,.webp" #image name="image"
                          id="image" (change)="handleFileInput($event.target.files)">
                      </div>
                  
                      <div class="col-4" [hidden]="!showImageIcon">
                        <div>
                        <img src="{{imagePath}}/{{dietaryWarningsData.Image}}" alt="ingredient image" style="width: 50px"
                          #ingredientImage id="ingredientImage" />
                          <pos-icon [icon]="icons.times" class="pointer remove-icon-badge mt-pix-n20" (click)="removeIcon()"></pos-icon>
                        </div>
                      </div>
                    </div>
                  </pos-form-field>
                </pos-column>

                <pos-column>
                  <pos-form-field label="Make Table Color">
                    <pos-color-picker name="maketableColor" posInput [(ngModel)]="dietaryWarningsData.MakeTableColor"></pos-color-picker>
                  </pos-form-field>
                </pos-column>
              </pos-row>
            </div>
          </div>

        <div class="col-12 action-button-container page-footer">
            <button type="submit" class="btn-primary btn-lg btn btn-save pos-button">Save </button>
            <button type="button" class="btn-secondary  btn-lg btn btn-cancel pos-button" (click)="onCancel()">Cancel</button>
          </div>
    </form>
</pos-edit-wrapper>
