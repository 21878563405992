import { Injectable } from '@angular/core';
import { RabbitMQMessage } from '../models/rabbitmq-message';
import { MessagePayload } from '../models/message-payload';
import { TerminalEndpoint } from '../models/terminal-endpoint';
import { TerminalUseCheckPayload } from '../models/terminal-use-check-payload';
import { OrderProductDetailsPayload } from '../models/order-product-details-payload';
import { OrderDetailsPayload } from '../models/order-details-payload';
import { OrderInfoPayload } from '../models/order-info-payload';
import { OrderProductPayload } from '../models/order-product-payload';
import { UserClockoutMessagePayload } from '../models/user-clockout-message-payload';
import { StringMessagePayload } from '../models/string-message-payload';
import { OrderItemListPayload } from '../models/order-item-list-payload';
import { MessageTypes } from '../constant/RabbitMQMessageConstants';
import { FilterMenuItemsPayload } from '../models/filter-menu-items-payload';
import { HighlightMenuItemsPayload } from '../models/highlight-menu-items-payload';
import { RefreshMenuDisplayPayload } from '../models/refresh-menu-display-payload';
import { HicCameraRequestPayload } from '../models/hic-camera-request-payload';
import { AutoSignOutUserPayload } from '../models/auto-sign-out-user';
import { ReviewOrderPayload } from '../models/review-order-payload';
import { CloseReviewOrderModalPayload } from '../models/close-review-order-modal-payload';
import { OrderKioskStateUpdatedPayload } from '../models/order-kiosk-state-updated-payload';

@Injectable({
    providedIn: 'root'
})
export class RabbitMQMessageService {

    getTerminalInfoMessage(terminalId, terminalName, terminalType, destTerminalId?, destTerminalName?) {
        const sourceTerminalEndpoint = this.getTerminalEndpoint(terminalId, terminalName);
        const payload = this.getTerminalUseCheckPayload(terminalId, terminalName, terminalType);
        const destTerminalEndpoint = this.getTerminalEndpoint(destTerminalId, destTerminalName);

        return new RabbitMQMessage(MessageTypes.TERMINAL_USE_RESPONSE,
            sourceTerminalEndpoint,
            destTerminalEndpoint,
            payload);
    }

    getOrderKioskStateUpdatedMessage(terminalId: number, state: boolean) {
        const payload = new OrderKioskStateUpdatedPayload(terminalId, state);
        return new RabbitMQMessage(MessageTypes.ORDER_KIOSK_STATE_UPDATED,
            null,
            null,
            payload);
    }

    getOrderProductDetailsMessageForPickScreen(sourceMakeTableId, orderProductDetails) {
        const orderProductDetailsArray = [];
        orderProductDetailsArray.push(orderProductDetails);
        const sourceTerminalEndPoint = this.getTerminalEndpoint(sourceMakeTableId);
        const payload = this.getOrderProductDetailsPayload(orderProductDetailsArray);

        return new RabbitMQMessage(MessageTypes.PRODUCT_UPDATE_FOR_PICKSCREEN,
            sourceTerminalEndPoint,
            null,
            payload);
    }

    getOrderDetailsMessage(sourceTerminalId, orderDetails) {
        const sourceTerminalEndPoint = this.getTerminalEndpoint(sourceTerminalId);
        const payload = this.getOrderDetailsPayload(orderDetails);

        return new RabbitMQMessage(MessageTypes.ORDER_UPDATE, sourceTerminalEndPoint, null, payload);
    }

    getOrderReviewedMessage(sourceTerminalId, orderDetails) {
        const sourceTerminalEndPoint = this.getTerminalEndpoint(sourceTerminalId);
        const payload = this.getOrderDetailsPayload(orderDetails);

        return new RabbitMQMessage(MessageTypes.ORDER_REVIEWED, sourceTerminalEndPoint, null, payload);
    }

    getReviewOrderMessage(sourceTerminalId, orderData, seats) {
        const sourceTerminalEndPoint = this.getTerminalEndpoint(sourceTerminalId);
        const payload = this.getReviewOrderPayload(orderData, seats);
        return new RabbitMQMessage(MessageTypes.REVIEW_ORDER, sourceTerminalEndPoint, null, payload);
    }

    getCloseReviewOrderModalMessage(sourceTerminalId, isConfirmed) {
        const sourceTerminalEndPoint = this.getTerminalEndpoint(sourceTerminalId);
        const payload = new CloseReviewOrderModalPayload(isConfirmed);
        return new RabbitMQMessage(MessageTypes.CLOSE_REVIEW_ORDER_MODAL, sourceTerminalEndPoint, null, payload);
    }

    getRefreshActiveOrderMessage(sourceTerminalId, orderId) {
        const sourceTerminalEndPoint = this.getTerminalEndpoint(sourceTerminalId);
        const payload = this.getStringMessagePayload(orderId);
        return new RabbitMQMessage(MessageTypes.REFRESH_ACTIVE_ORDER, sourceTerminalEndPoint, null, payload);
    }

    getOrderUpdateMessage(sourceTerminalId, orderProductDetails) {
        const orderProductDetailsArray = [];
        orderProductDetailsArray.push(orderProductDetails);
        const sourceTerminalEndPoint = this.getTerminalEndpoint(sourceTerminalId);
        const payload = this.getOrderItemListPayload(orderProductDetailsArray);

        return new RabbitMQMessage(MessageTypes.ORDER_UPDATE, sourceTerminalEndPoint, null, payload);
    }

    getOrderProductStateChangeMessage(sourceTerminalId, orderItemList) {
        const sourceTerminalEndPoint = this.getTerminalEndpoint(sourceTerminalId);
        const payload = this.getOrderItemListPayload(orderItemList);

        return new RabbitMQMessage(MessageTypes.ORDER_PRODUCT_STATE_CHANGE,
            sourceTerminalEndPoint,
            null,
            payload);
    }

    getOrderServedMessage(sourceTerminalId, orderId) {
        const sourceTerminalEndPoint = this.getTerminalEndpoint(sourceTerminalId);
        const payload = this.getOrderInfoPayload(orderId);

        return new RabbitMQMessage(MessageTypes.ORDER_SERVED, sourceTerminalEndPoint, null, payload);
    }

    getOrderIsOpenCheckMessage(sourceTerminalId, sourceTerminalName, orderId) {
        const sourceTerminalEndPoint = this.getTerminalEndpoint(sourceTerminalId, sourceTerminalName);
        const payload = this.getOrderInfoPayload(orderId);

        return new RabbitMQMessage(MessageTypes.CHECK_ORDER_IS_OPEN, sourceTerminalEndPoint, null, payload);
    }

    getOrderAlreadyOpenCheckMessage(sourceTerminalId, sourceTerminalName, destinationTerminalId, orderId) {
        const sourceTerminalEndPoint = this.getTerminalEndpoint(sourceTerminalId, sourceTerminalName);
        const destinationEndPoint = this.getTerminalEndpoint(destinationTerminalId);
        const payload = this.getOrderInfoPayload(orderId);

        return new RabbitMQMessage(MessageTypes.ORDER_ALREADY_OPEN,
            sourceTerminalEndPoint,
            destinationEndPoint,
            payload);
    }

    getOrderCloseRequestMessage(sourceTerminalId, sourceTerminalName, destinationTerminalId, orderId) {
        const sourceTerminalEndPoint = this.getTerminalEndpoint(sourceTerminalId, sourceTerminalName);
        const destinationEndPoint = this.getTerminalEndpoint(destinationTerminalId);
        const payload = this.getOrderInfoPayload(orderId);

        return new RabbitMQMessage(MessageTypes.ORDER_CLOSE_REQUEST,
            sourceTerminalEndPoint,
            destinationEndPoint,
            payload);
    }

    getHICRequestMessage(hardwareDeviceId, sourceTerminalId, terminalName, userId, annotationText) {
        const sourceTerminalEndPoint = this.getTerminalEndpoint(sourceTerminalId);
        const payload = this.getHICCameraRequestPayload(hardwareDeviceId, sourceTerminalId, terminalName, userId, annotationText);
        return new RabbitMQMessage(MessageTypes.HIC_CAMERA_REQUEST, sourceTerminalEndPoint, null, payload);
    }

    getOrderFullyPaidMessage(sourceTerminalId, orderId) {
        const sourceTerminalEndPoint = this.getTerminalEndpoint(sourceTerminalId);
        const payload = this.getOrderInfoPayload(orderId);

        return new RabbitMQMessage(MessageTypes.ORDER_FULLY_PAID, sourceTerminalEndPoint, null, payload);
    }

    getOrderPaymentOutstandingMessage(sourceTerminalId, orderId) {
        const sourceTerminalEndPoint = this.getTerminalEndpoint(sourceTerminalId);
        const payload = this.getOrderInfoPayload(orderId);

        return new RabbitMQMessage(MessageTypes.ORDER_PAYMENT_OUTSTANDING,
            sourceTerminalEndPoint,
            null,
            payload);
    }

    getOrderQuantityChangedMessage(sourceTerminalId, orderItemId) {
        const sourceTerminalEndPoint = this.getTerminalEndpoint(sourceTerminalId);
        const payload = this.getOrderInfoPayload(orderItemId);

        return new RabbitMQMessage(MessageTypes.ORDER_QUANTITY_CHANGED,
            sourceTerminalEndPoint,
            null,
            payload);
    }

    getOrderItemDeleteMessage(sourceTerminalId, orderItemId) {
        const sourceTerminalEndPoint = this.getTerminalEndpoint(sourceTerminalId);
        const payload = this.getOrderInfoPayload(orderItemId);

        return new RabbitMQMessage(MessageTypes.ORDER_ITEM_DELETE, sourceTerminalEndPoint, null, payload);
    }

    getOrderProductIsCraftingMessage(sourceTerminalId, orderId, orderProductId, itemSelectedOn) {
        const sourceTerminalEndpoint = this.getTerminalEndpoint(sourceTerminalId);
        const payload = this.getOrderProductPayload(orderId, orderProductId);
        payload.SentOn = itemSelectedOn || new Date();

        return new RabbitMQMessage(MessageTypes.ORDER_PRODUCT_IS_CRAFTING,
            sourceTerminalEndpoint,
            null,
            payload);
    }

    getOrderProductIsAlreadySelectedForCraftingMessage(sourceTerminalId, destinationTerminalId, orderId, orderProductId) {
        const sourceTerminalEndpoint = this.getTerminalEndpoint(sourceTerminalId);
        const destinationTerminalEndpoint = this.getTerminalEndpoint(destinationTerminalId);

        const payload = this.getOrderProductPayload(orderId, orderProductId);

        return new RabbitMQMessage(MessageTypes.ORDER_PRODUCT_IS_ALREADY_SELECTED_FOR_CRAFTING,
            sourceTerminalEndpoint,
            destinationTerminalEndpoint,
            payload);
    }


    getFilterMenuItemsMessage(sourceTerminalId, dietaryWarnings?: Array<number>, tags?: Array<number>, widgets?: Array<number>, actionType?: string) {
        const sourceTerminalEndPoint = this.getTerminalEndpoint(sourceTerminalId);
        const payload = this.getFilterMenuItemsPayload(dietaryWarnings, tags, widgets, actionType);

        return new RabbitMQMessage(MessageTypes.FILTER_MENU_ITEMS, sourceTerminalEndPoint, null, payload);
    }

    getHighlightMenuItemsMessage(sourceTerminalId, productId?: number, actionType: string = null) {
        const sourceTerminalEndPoint = this.getTerminalEndpoint(sourceTerminalId);
        const payload = this.getHighlightMenuItemsPayload(productId, actionType);

        return new RabbitMQMessage(MessageTypes.HIGHLIGHT_MENU_ITEMS, sourceTerminalEndPoint, null, payload);
    }

    getRefreshMenuDisplayMessage(sourceTerminalId, menuDisplayId: number) {
        const sourceTerminalEndPoint = this.getTerminalEndpoint(sourceTerminalId);
        const payload = this.getRefreshMenuDisplayPayload(menuDisplayId);

        return new RabbitMQMessage(MessageTypes.REFRESH_MENU_DISPLAY, sourceTerminalEndPoint, null, payload);
    }

    getAutoSignOutUserMessage(sourceTerminalId: number, sourceTerminalName: string, userId: number, eventName: string) {
        const sourceTerminalEndPoint = this.getTerminalEndpoint(sourceTerminalId, sourceTerminalName);
        const payload = this.getLogoutUserPayload(userId, sourceTerminalId, eventName);

        return new RabbitMQMessage(MessageTypes.AUTO_SIGN_OUT_USER,
            sourceTerminalEndPoint,
            null,
            payload);
    }
    
    getOrderPhoneNumberModalOpenMessage(sourceTerminalId: number) {
        const sourceTerminalEndPoint = this.getTerminalEndpoint(sourceTerminalId);
        return new RabbitMQMessage(MessageTypes.ORDER_PHONE_NUMBER, sourceTerminalEndPoint, null, {});
    }
    
    getOrderPhoneNumberModalCloseMessage(sourceTerminalId: number, value: string) {
        const sourceTerminalEndPoint = this.getTerminalEndpoint(sourceTerminalId);
        const payload = value;
        return new RabbitMQMessage(MessageTypes.ORDER_PHONE_NUMBER, sourceTerminalEndPoint, null, payload);
    }
    /*********************************** Endpoints ***********************************/

    /************************* Factory Methods *********************/

    getTerminalEndpoint(terminalId, terminalName?) {
        return new TerminalEndpoint(terminalId, terminalName);
    }

    /************************* Factory Methods *********************/

    getTerminalUseCheckPayload(terminalId, terminalName, terminalType) {
        return new TerminalUseCheckPayload(terminalId, terminalName, terminalType);
    }

    getOrderKioskStateUpdatedPayload(terminalId: number, state: boolean) {
        return new OrderKioskStateUpdatedPayload(terminalId, state);
    }

    getOrderProductDetailsPayload(orderProductDetails) {
        return new OrderProductDetailsPayload(orderProductDetails);
    }
    getOrderDetailsPayload(orderDetails) {
        return new OrderDetailsPayload(orderDetails);
    }

    getReviewOrderPayload(orderData, seats) {
        return new ReviewOrderPayload(orderData, seats);
    }

    getOrderInfoPayload(orderId) {
        return new OrderInfoPayload(orderId);
    }
    getHICCameraRequestPayload(hardwareDeviceId, terminalId, terminalName, userId, annotationText) {
        return new HicCameraRequestPayload(hardwareDeviceId, terminalId, terminalName, userId, annotationText);
    }
    getOrderProductPayload(orderId, orderProductId) {
        return new OrderProductPayload(orderId, orderProductId);
    }
    getUserClockoutMessagePayload(userId) {
        return new UserClockoutMessagePayload(userId);
    }
    getEmptyMessagePayload() {
        return new MessagePayload();
    }
    getStringMessagePayload(message) {
        return new StringMessagePayload(message);
    }
    getOrderItemListPayload(orderItemList) {
        return new OrderItemListPayload(orderItemList);
    }
    getFilterMenuItemsPayload(dietaryWarnings?: Array<number>, tags?: Array<number>, widgets?: Array<number>, actionType?: string) {
        return new FilterMenuItemsPayload(dietaryWarnings, tags, widgets, actionType);
    }
    getHighlightMenuItemsPayload(productId: number, actionType: string = null) {
        return new HighlightMenuItemsPayload(productId, actionType);
    }
    getRefreshMenuDisplayPayload(menuDisplayId: number) {
        return new RefreshMenuDisplayPayload(menuDisplayId);
    }

    getRefreshDashboardMessage() {
        const payload = this.getEmptyMessagePayload();
        return new RabbitMQMessage(MessageTypes.REFRESH_DASHBOARD,
            null,
            null,
            payload);
    }

    getRefreshTerminalMessage() {
        const payload = this.getEmptyMessagePayload();
        return new RabbitMQMessage(MessageTypes.REFRESH_TERMINAL,
            null,
            null,
            payload);
    }

    getLogoutUserPayload(userId: number, terminalId: number, eventName: string) {
        return new AutoSignOutUserPayload(userId, terminalId, eventName);
    }
}
