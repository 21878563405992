import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import { Observable } from 'rxjs';
import { LogDueDateOverride } from '../../information-management/inventory-products/interface/log-due-date-override';
import { InventoryProductDetail } from '../../information-management/inventory-products/interface/inventory-product-detail';
import { ProductInventoryConsumption } from '../../information-management/inventory-products/interface/inventory-product-consumption';
import { InvProduct } from '../interface/inventory-product';
import { InventoryProductLabelPrintRequest } from '../interface/inventory-product-label-print-request';
import { InventoryProductBulkUpdate, InventoryProductSummary } from 'src/app/information-management';
import { InventoryReceived } from 'src/app/shared/interface/inventory-received';
import { MarkAsOrderedInventoryProduct, MarkAsOrderedModel } from 'src/app/manage-console/interfaces/mark-as-ordered';
import { InventoryProductDetails } from '../interface';

@Injectable({
    providedIn: 'root'
})
export class InventoryProductService {

    inventoryProductApiPath = `${RuntimeConstants.API_PATH}inventoryproducts`;

    constructor(private httpService: HttpClient) { }

    newInventoryProduct(): InvProduct {
        return {
            id: 0,
            name: '',
            subcategory_id: null,
            vendor_id: null,
            Aisle: null,
            vendor_sku: null,
            unit_id: null,
            UnitName: null,
            unit_qty: null,
            unit_details: '',
            price: null,
            min: null,
            max: null,
            active: true,
            DateAdded: new Date(),
            price_unit: null,
            current_qty: 0,
            max_days_between_inventory: null,
            vendor: null,
            PrimaryZoneId: null,
            PrimaryBinId: null,
            SecondaryZoneId: null,
            SecondaryBinId: null,
            Barcodes: [],
            common: false,
            IsWarnConsumption: true,
            ConsumptionVarianceLowThresholdPct: 10,
            MinTaskId: null,
            MinTaskTiming: null,
            MinTaskTimeString: '',
            ConsumptionThresholdLowAmount: 0,
            IsWarnUnexpectedInventoryQty: true,
            CalculatedQty: 0,
            AutoDecrementBy: 'None',
            AutoDecrementQty: null,
            ReceivingInstructions: null,
            Recipe: null,
            IngredientImage: null,
            IngredientInstruction: null,
            VendorName: null,
            PrimaryZoneName: null,
            PrimaryZoneCode: null,
            PrimaryZoneDescription: null,
            PrimaryZoneLabel: null,
            PrimaryZoneColor: null,
            SecondaryZoneName: null,
            SecondaryZoneCode: null,
            SecondaryZoneDescription: null,
            SecondaryZoneLabel: null,
            SecondaryZoneColor: null,
            PrimaryBinName: null,
            PrimaryBinDescription: null,
            PrimaryBinColor: null,
            SecondaryBinName: null,
            SecondaryBinDescription: null,
            SecondaryBinColor: null,
            LastLoggedQty: 0,
            SubCategory: null,
            LastLoggedDate: null,
            IsSkipInventory: false,
            IsLogAfterReceived: false,
            LogAfterReceivedDays: null,
            LogDueDateOverride: null,
            ConsumptionVarianceHighThresholdPct: 10,
            ConsumptionThresholdHighAmount: 0,
            MaxPortionsText: null,
            MeasureByInstructions: null,
            Parameters: null,
            Ingredients: [],
            NutritionInfo: [],
            DietaryWarningInfo: [],
            servingInformationData: {},
            IsFood: true,
            IsOnOrder: false,
            UserId: null,
            IsTriggerInventoryLogOnMinimumReached: false,
            ExhaustionPromptToTerminalId: null,
            IsPrintPrepLabel: false,
            IsExhaustionPromptToTerminalId: false,
            TaskWhen: '0',
            selectedTask: null,
            IsMinQuantityReachedTaskScheduled: false,
            TaskTime: new Date(),
            RemovedIngredients: [],
            VendorUrl: null,
            TypicalLeadTime: null,
            IsAllowOutOfStockSales: false,
            InventoryBaselineDate: null,
            CriticalLevel: null,
            DefaultConsumptionQty: null,
            DefaultRecipeQty: null,
            IsPermittedToSellIndividually: false,
            PurchaseUnitId: null,
            FriendlyName: null,
        };
    }

    newMarkAsOrderedInventoryProduct(): MarkAsOrderedInventoryProduct {
        return {
            InventoryProductId: null,
            InventoryProductName: '',
            IsOnOrder: false,
            UnitQuantity: null,
            CalculatedQuantity: null,
            Unit: null,
        };
    }

    newInventoryProductDetail(): InventoryProductDetails {
        return {
            Id: 0,
            Name: null,
            SubCategory: null,
            SubcategoryId: 0,
            Vendor: null,
            VendorSku: null,
            UnitName: null,
            UnitQty: 0,
            UnitDetails: null,
            IsSkipInventory: false,
            IsActive: true,
            CalculatedQty: 0,
            Min: 0,
            Max: 0,
            Price: 0,
            IsOnOrder: false,
            DateAdded: new Date(),
            IsFood: false,
            IsPrintPrepLabel: false,
            IsAddToBulkUpdate: false,
            InventorySubcategoryId: null,
            IsAllowOutOfStockSales: false
        };
    }

    activateInventoryProducts(active, inventoryProducts): Observable<any> {
        return this.httpService.post(
            `${RuntimeConstants.API_PATH}inventoryproducts/active/` + active,
            inventoryProducts
        );
    }

    getActiveInventoryProducts(active): Observable<any> {
        return this.httpService.get(
            `${RuntimeConstants.API_PATH}inventoryproducts/product/` + active
        );
    }

    updateLogDueDateOverride(inventoryLogDateOverride: LogDueDateOverride): Observable<any> {
        return this.httpService.post(`${RuntimeConstants.API_PATH}inventoryproducts/updateLogDueDateOverride`,
            inventoryLogDateOverride);
    }

    getInventoryProducts(inventoryCategoryId, inventoryVendorId, isActive, inventoryProductId): Observable<Array<InventoryProductDetail>> {
        return this.httpService.get<Array<InventoryProductDetail>>(`${RuntimeConstants.API_PATH}inventoryproducts/`
            + inventoryCategoryId + `/` + inventoryVendorId + `/` + isActive + `/` + inventoryProductId);
    }

    getInventoryProduct(inventoryProductId: number): Observable<any> {
        return this.httpService.get(`${RuntimeConstants.API_PATH}inventoryproducts/` + inventoryProductId);
    }

    getEligibleRecipeInventoryProduct(inventoryProductId: number): Observable<any> {
        return this.httpService.get(`${RuntimeConstants.API_PATH}inventoryproducts/` + inventoryProductId + `/eligible-recipe`);
    }

    getInventoryReceived(inventoryProductId: number): Observable<any> {
        return this.httpService.get(`${RuntimeConstants.API_PATH}inventoryproducts/` + inventoryProductId + `/inventoryReceived`);
    }

    getInventoryReceivedByDate(searchDetails): Observable<Array<InventoryReceived>> {
        return this.httpService.post<Array<InventoryReceived>>(`${RuntimeConstants.API_PATH}inventoryproducts/getInventoryReceived`, searchDetails);
    }

    getIncludedRecipe(inventoryProductId: number): Observable<any> {
        return this.httpService.get(`${RuntimeConstants.API_PATH}inventoryproducts/` + inventoryProductId + `/includedRecipe`);
    }

    getInventoryProductsForMerge(): Observable<any> {
        return this.httpService.get(`${RuntimeConstants.API_PATH}inventoryproducts`);
    }

    mergeProduct(masterProductId: number, oldProductId: number): Observable<any> {
        return this.httpService.post(`${RuntimeConstants.API_PATH}inventoryproducts/` +
            masterProductId + '/oldInventoryProductId/' + oldProductId + '/mergeProducts', null);
    }

    UpdateInventoryProductConsumptionDetails(
        inventoryProductId: number, updateConsumptionDetailsModel: Array<ProductInventoryConsumption>): Observable<any> {
        return this.httpService.post(`${RuntimeConstants.API_PATH}inventoryproducts/` +
            inventoryProductId + '/updateConsumptionDetails', updateConsumptionDetailsModel);
    }

    getInventoryProductList(
        inventoryCategoryId: number, inventoryVendorId: number, inventoryZoneId: number, isActive: boolean,
            inventoryProductId: number): Observable<any> {
        return this.httpService.get(`${RuntimeConstants.API_PATH}inventoryproducts/${inventoryCategoryId}/${inventoryVendorId}/${inventoryZoneId}/${isActive}/${inventoryProductId}`);
    }

    getInventoryProductConsumption(inventoryProductId: number) {
        return this.httpService.get(`${RuntimeConstants.API_PATH}inventoryproducts/${inventoryProductId}/consumption`);
    }

    updateInventoryProductAsOrdered(inventoryProductId, userId) {
        return this.httpService.post(`${RuntimeConstants.API_PATH}inventoryproducts/markAsOrder/${inventoryProductId}/${userId}`, null);
    }

    getAutoDecrementBy(): Observable<any> {
        return this.httpService.get(`${RuntimeConstants.API_PATH}inventoryproducts/auto-decrement`);
    }

    labelPrint(inventoryProductLabelPrint: InventoryProductLabelPrintRequest): Observable<any> {
        return this.httpService.post(`${RuntimeConstants.API_PATH}inventoryproducts/labelPrint`, inventoryProductLabelPrint);
    }

    saveInventoryProduct(inventory: InvProduct, icon: File): Observable<any> {
        const formData = this.prepareIconFormData(inventory, icon);
        return this.httpService.post(
            `${RuntimeConstants.API_PATH}inventoryproducts`,
            formData
        );
    }

    private prepareIconFormData(param: InvProduct, iconFile: File): FormData {
        const formData = new FormData();
        if (iconFile) {
            formData.append('file', iconFile);
        }
        // formData.append('isAvailableIcon', JSON.stringify({ isUseUploadedIcon: isAvailableIcon }));
        formData.append('model', JSON.stringify(param));
        return formData;
    }

    getInventoryLogStockSalesProducts(salesInventoryProducts) {
        return this.httpService.post(`${RuntimeConstants.API_PATH}salesproducts/instock/sales-inventory`, salesInventoryProducts);
    }

    triggerFlash(inventoryProductId: number, primaryBinId: number, secondaryBinId: number, terminalId: number, terminalName: string) {
        return this.httpService.post(`${this.inventoryProductApiPath}/${inventoryProductId}/flash`,
            { PrimaryBinId: primaryBinId, SecondaryBinId: secondaryBinId, TerminalId: terminalId, TerminalName: terminalName });
    }

    getInventoryPrepRecordingProducts(): Observable<Array<InventoryProductSummary>> {
        return this.httpService.get<Array<InventoryProductSummary>>(`${this.inventoryProductApiPath}/prepared`);
    }

    printInventoryPrep(inventoryProductId: number, inventoryProduct) {
        return this.httpService.post(`${this.inventoryProductApiPath}/${inventoryProductId}/prepare/print`, inventoryProduct);
    }

    recordInventoryPreparation(inventoryPrepProduct) {
        return this.httpService.post(`${this.inventoryProductApiPath}/${inventoryPrepProduct.InventoryProductId}/prepare`,
            inventoryPrepProduct);
    }

    updateInventoryQtyFromButtonBehavior(inventoryPrepProductId, qty, buttonId) {
        return this.httpService.post(`${this.inventoryProductApiPath}/${inventoryPrepProductId}/qty-update/button-behavior`,
            { Qty: qty, ButtonId: buttonId });
    }

    markInventoryProductsAsOrdered(productsMarkAsOrdered: MarkAsOrderedModel) {
        return this.httpService.post(`${this.inventoryProductApiPath}/mark-as-ordered`, productsMarkAsOrdered);
    }

    getProductsForBulkUpdate(inventoryProductIds: Array<number>) {
        return this.httpService.post(`${this.inventoryProductApiPath}/products`, inventoryProductIds);
    }

    saveProductsForBulkUpdate(inventoryProducts: Array<InventoryProductBulkUpdate>) {
        return this.httpService.post(`${this.inventoryProductApiPath}/update-products`, inventoryProducts);
    }

    getInventoryProductsForButtonFunction(): Observable<Array<InventoryProductDetails>> {
        return this.httpService.get<Array<InventoryProductDetails>>(`${this.inventoryProductApiPath}/products-for-button-function`);
    }
}
