import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomainConstants, RuntimeConstants } from 'src/app/shared/constants';
import { Observable } from 'rxjs';
import { CustomerCredential, TerminalSummary } from '../../application-login/interface';
import { ApplicationStateService } from './application-state.service';

@Injectable()
export class ApplicationLoginService {
  applicationLoginApiUrl = '';
  constructor(private httpService: HttpClient, private applicationStateService: ApplicationStateService) {
    this.applicationLoginApiUrl = RuntimeConstants.API_PATH + 'logins';
  }

  getCustomer(customerCredential: CustomerCredential): Observable<any> {
    return this.httpService.post<any>(`${this.applicationLoginApiUrl}/GetCustomer`, customerCredential);
  }

  checkTerminalName(terminalDetails): Observable<any> {
    return this.httpService.post<any>(`${this.applicationLoginApiUrl}/CheckTerminalName`, terminalDetails);
  }

  getUserPreference(userId: number): Observable<any> {
    return this.httpService.get(`${RuntimeConstants.API_PATH}Manages/GetUserPreference/` + userId);
  }

  logoutTheUsedTerminal(terminalId: number): Observable<any> {
    return this.httpService.post(`${this.applicationLoginApiUrl}/LogoutTheUsedTerminal/` + terminalId, null);
  }

  getUserTrainingBadges(userId: number): Observable<any> {
    return this.httpService.get(`${RuntimeConstants.API_PATH}usertrainingbadge/` + userId);
  }

  getUserTrainingDetails(userId: number): Observable<any> {
    return this.httpService.get(`${RuntimeConstants.API_PATH}usertraining/details/` + userId);
  }

  getUserByCode(code: string): Observable<any> {
    return this.httpService.post(`${this.applicationLoginApiUrl}/GetUserByCode/` + code, code);
  }

  clockLoginCheck(password: string): Observable<any> {
    return this.httpService.post(`${this.applicationLoginApiUrl}/ClockLoginCheck/` + password, password);
  }

  clockIntoPos(clockInOutDetails: any): Observable<any> {
    return this.httpService.post(`${this.applicationLoginApiUrl}/ClockIntoPos`, clockInOutDetails);
  }

  clockOutOfPos(clockInOutDetails: any): Observable<any> {
    return this.httpService.post(`${this.applicationLoginApiUrl}/ClockOutOfPos`, clockInOutDetails);
  }

  clockedOk(userId: number): Observable<any> {
    return this.httpService.post(`${this.applicationLoginApiUrl}/ClockedOK/` + userId, userId);
  }

  getLoginById(userId: number): Observable<any> {
    return this.httpService.post(`${this.applicationLoginApiUrl}/GetLoginById/` + userId, userId);
  }

  setUserPreferences(userPreferenceModel: {key: string, value: string}[]) {
    if (userPreferenceModel) {
      var invoiceLocation = userPreferenceModel.find(x => x.key == DomainConstants.UserPreferences.InvoiceLocation);
      if (invoiceLocation?.value) {
        this.applicationStateService.configurations.UserPreferenceInvoiceLocation = invoiceLocation.value;
      }
      var orderMaintenanceUser = userPreferenceModel.find(x => x.key == DomainConstants.UserPreferences.OrderMaintenanceUser);
      if (orderMaintenanceUser?.value) {
        this.applicationStateService.configurations.UserPreferenceOrderMaintenanceUser = parseInt(orderMaintenanceUser.value);
      }
    }
  }
}

