<pos-edit-wrapper heading="{{this.salesCategorySizeId > 0 ? 'Edit Sales Category Size' : 'Add Sales Category Size'}}"
    (close)="cancelEditing()">

    <form #salesCategoriesSizesForm="ngForm" (ngSubmit)="save(salesCategoriesSizesForm.valid)" autocomplete="off">
        <div class="col-12 pos-modal-body">
            <pos-row>
                <pos-column>
                    <pos-form-field label="Sizes"
                        [validationMessages]="{required: 'Please select size.'}">
                        <ng-select posInput [required]="!salesCategorySizeId" [items]="sizes"
                            [hideSelected]="true" name="sizes" bindLabel="Name" bindValue="Id" appendTo="body"
                            placeholder="Select size(s)"
                            [disabled]="!!salesCategorySizeId"
                            [(ngModel)]="salesCategorySize.SizeId">
                        </ng-select>
                    </pos-form-field>
                </pos-column>
                <pos-column>
                    <pos-form-field label="Icon" inputStyle="col-12">
                        <pos-row>
                            <pos-column md="8" sm="12">
                                <input posInput type="file" id="buttonIcon" class="form-control"
                                    accept=".jpg, .bmp, .png, .gif, .webp" name="imageInput"
                                    (change)="handleFileInput($event.target.files)" />
                            </pos-column>
                            <pos-column sm="4" [hidden]="!showImageIcon">
                                <div>
                                    <img src="{{imagePath}}/{{salesCategorySize.Image}}" alt="size" style="width: 50px"
                                      #image id="image" />
                                      <pos-icon [icon]="icons.times" class="pointer remove-icon-badge mt-pix-n20" (click)="removeIcon()"></pos-icon>
                                </div>
                            </pos-column>
                        </pos-row>
                    </pos-form-field>
                </pos-column>
            </pos-row>
        </div>

        <div class="col-12 action-button-container popup-footer">
            <button type="submit" class="btn-primary btn-lg white btn btn-save pos-button">Save</button>
            <button type="button" class="btn-secondary  btn-lg btn btn-cancel pos-button"
                (click)="cancelEditing()">Cancel
            </button>
        </div>
    </form>
</pos-edit-wrapper>
