<pos-edit-wrapper (close)="onCancel(false)"
  heading="{{ copy && copy.isCopyTo ? ('Copy instructions from ' + copy.from.productName + ' ' + copySize + ' to ' + selectedProductName + ' ' + selectedSize) 
            : ('Copy instructions to ' + copy.to.productName + ' ' + copySize + ' from ' + selectedProductName + ' ' +selectedSize) }}">
  <form #copyInstructionForm="ngForm" autocomplete="off">
    <div class="container-fluid">
      <div class="row mx-0 alert condensed inherit-position conflicted-recipe alert-danger" id="divSalesProductErrorMsg"
        style="display: none;"></div>

      <pos-row class="mb-pix-10 mx-0">
        <pos-column sm="6" xs="8" class="px-pix-5">
          <pos-form-field label="Copy {{copy.isCopyTo?'To':'From'}} Product">
            <ng-select posInput appendTo="body" [items]="salesProducts" bindLabel="Name" bindValue="ID"
              (change)="getIngredients()" placeholder="Select product" [(ngModel)]="selectedSalesProductId"
              name="product">
            </ng-select>
          </pos-form-field>
        </pos-column>
        <pos-column sm="6" xs="8" class="px-pix-5">
          <pos-form-field label="Product Size">
            <ng-select posInput appendTo="body" [disabled]="!selectedSalesProductId" [items]="availableSizes"
              bindLabel="Name" bindValue="Id" (change)="onChangeProduct(selectedSalesProductId,false)"
              placeholder="Select product size" [(ngModel)]="selectedSizeId" name="productSize">
            </ng-select>
          </pos-form-field>
        </pos-column>
      </pos-row>

      <div class="col-12 px-pix-10">
        <ul class="nav nav-tabs pos-nav-tabs">
          <li [ngClass]="{'active': selectedTab == tabList.Area }" (click)="changePanel(0)">
            <a (click)="selectedTab = tabList.Area" class="blue">
              <span class="pe-pix-5">
                <pos-icon class="theme-separator" [icon]="icons.clone"></pos-icon>
              </span> <span>Copy Area</span> </a>
          </li>
          <li [ngClass]="{'active': selectedTab == tabList.Instruction }" (click)="changePanel(1)">
            <a (click)="selectedTab = tabList.Instruction" class="blue">
              <span class="pe-pix-5">
                <pos-icon class="theme-separator" [icon]="icons.listAlt"></pos-icon>
              </span> <span>Copy Instruction</span> </a>
          </li>
        </ul>

        <div class="tab-content width-100">
          <div [hidden]="selectedTab != tabList.Area">
            <div class="row mx-0 overflow-auto-tabs m-0 width-100 screen-scroll"
              [ngStyle]="{'height': height + 'px'}">
              <pos-row class="pb-pix-10">
                <pos-column md="4" sm="6" xs="12" class="p-0">
                  <pos-form-field label="Area(s)">
                    <ng-select posInput appendTo="body" [disabled]="!selectedSalesProductId"
                      [items]="copy.from.areaList" bindLabel="Area" [multiple]="true" placeholder="Select area(s)"
                      [(ngModel)]="selectedAreas" name="selectInstructionArea">
                    </ng-select>
                  </pos-form-field>
                </pos-column>
              </pos-row>
            </div>
          </div>

          <div [hidden]="selectedTab != tabList.Instruction">
            <div class="row mx-0 overflow-auto-tabs m-0 width-100 screen-scroll" [ngStyle]="{'height': height + 'px'}">
              <pos-row class="pb-pix-10">
                <pos-column lg="4" md="5" sm="6" xs="12" class="p-0 flex-grow-1">
                  <pos-form-field label="Copy To" inputStyle="col-md-3 col-sm-6 col-12">
                    <ng-select posInput appendTo="body" [items]="copy.to.areaList" bindLabel="Area" bindValue="Id"
                      placeholder="Select area" [(ngModel)]="selectedTargetAreaId" name="targetArea">
                    </ng-select>
                  </pos-form-field>
                </pos-column>
                <pos-column lg="2" sm="3" class="p-0 pt-pix-5 pull-right">
                  <pos-form-field label=" " inputStyle="col-xs-12">
                    <ng-select posInput appendTo="body" [items]="copy.from.areaList" [multiple]="true" [hideSelected]="true"
                      bindLabel="Area" placeholder="Filter area(s)" [(ngModel)]="filteredSourceAreas"
                      name="selectFilterInstructionArea">
                    </ng-select>
                  </pos-form-field>
                </pos-column>
              </pos-row>
              <div *ngFor="let area of copy.from.areaList; index as i"
                class="col-12 p-0">
                <div *ngIf="!filteredSourceAreas || filteredSourceAreas.length==0 || checkIsAvailable(area)">
                  <div class="vertical-center-align bg-white pt-pix-5 pb-pix-5 bg-antiquewhite">
                    <div class="col-12 ps-pix-5">
                      <strong>{{area.Area}}</strong>
                    </div>
                  </div>
                  <div class="col-12 p-pix-5 pt-0">
                    <div
                      class="col-12 border-1 bg-white p-pix-5 ps-pix-1 mt-pix-5 vertical-center-align"
                      *ngFor="let instruction of copy.from.instructionAreas[area.Id]; index as j">
                      <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 text-left ps-0" style="width:2%">
                        <label>
                          <p-checkbox name="directLink_{{i}}_{{j}}" #directLink="ngModel"
                            [(ngModel)]="instruction.isSelected" binary="true">
                          </p-checkbox>
                        </label>
                      </div>
                      <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 p-0 h-60" style="text-align:center">
                        <img style="height:100%;max-width:100%;" [hidden]="!instruction.IngredientImage"
                          src="{{imagePath}}/instructions/{{instruction.IngredientImage}}" />
                      </div>
                      <div class="col-lg-10 col-md-10 col-sm-10 col-xs-10 ps-pix-2 pe-0">
                        {{instruction.IngredientInstruction}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="clearfix"></div>
    <div class="col-12 action-button-container popup-footer">
      <div class="form-horizontal">
        <button *ngIf="!isCopyArea" [disabled]="!selectedTargetAreaId || !selectedSizeId" (click)="onCopyInstructions()"
          class="btn btn-primary btn-save pos-button">
          Copy
        </button>
        <button *ngIf="isCopyArea"
          [disabled]="!selectedAreas || selectedAreas.length==0 || !selectedSalesProductId || (!selectedSizeId && copy.isCopyTo)"
          (click)="onCopyAreas(selectedAreas)" class="btn btn-primary btn-save pos-button">
          Copy
        </button>
        <button (click)="onCancel(false)" class="btn btn-secondary btn-cancel-modal pos-button" type="button">
          Cancel
        </button>
      </div>
    </div>
  </form>
</pos-edit-wrapper>
