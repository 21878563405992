import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { RuntimeConstants, Configurations, ApplicationStateService, SettingParam, OrderEventBroadcastingService, ModalService } from 'src/app/shared';
import {
  thLarge,
  terminal
} from 'src/app/shared/components/icon';
import { OrderNavigationComponent } from '../..';
import { MessageBoxComponent } from 'src/app/inbox-messages';
import { Subscription } from 'rxjs';
declare let $: any;
@Component({
  selector: 'pos-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent implements OnInit {

  icons = {
    thLarge,
    terminal
  };
  isShowCliTerminal: boolean = false;
  productShow: boolean = false;
  headerLinks = {
    tasks: true,
    home: true,
    warning: true,
    admin: true,
    time: true,
    activeOrders: true,
    signIn: true,
    mySubscriptions: true
  };
  configurations: Configurations;
  settingParam: SettingParam;
  windowHeight: any;
  tabList = {
    ButtonView: 'ButtonView',
    CliTerminal: 'CliTerminal'
  };
  selectedTab = this.tabList.ButtonView;
  orderId: number = 0;
  @ViewChild('orderNavigation') orderNavigation: OrderNavigationComponent;
  openMessageBoxEvent: Subscription;
  
  constructor(private applicationStateService: ApplicationStateService, private router: Router,
    private orderEventBroadcastingService: OrderEventBroadcastingService,
    private modalService: ModalService,
  ) {
    const navigation = router.getCurrentNavigation();
    this.orderId = (navigation?.extras?.state && navigation?.extras?.state.orderId) ?? 0;
   }
  imagePath: string = RuntimeConstants.IMAGE_BASE_PATH;
  ngOnInit() {
    this.setDefault();
    this.subscribeToOpenMessageBoxEvent();
    this.windowHeight = $(window).width();
    $(window).resize(() => {
      this.windowHeight = $(window).width();
    });
  }

  ngOnDestroy() {
    this.openMessageBoxEvent?.unsubscribe();
  }

  setDefault() {
    this.configurations = this.applicationStateService.configurations;
    this.settingParam = this.applicationStateService.settingParam;
  }

  scanBarcode(barcode: string) {
    this.orderNavigation.getProductByBarcode(barcode);
  }

  subscribeToOpenMessageBoxEvent() {
    this.openMessageBoxEvent?.unsubscribe();
    this.openMessageBoxEvent = this.orderEventBroadcastingService.openMessageDialog.subscribe({
      next: (res) => {
        if (!res) return;
        const modalRef = this.modalService.getModalWrapper(MessageBoxComponent);
        const _ = modalRef.show({
          animated: false,
          'backdrop': 'static',
          class: 'vertical-center modal-lg'
        });
      }
    })
  }

}
