import { Injectable } from "@angular/core";
import { IKioskNavigation } from "../interfaces/ikiosk-navigation";
import { ChoiceBehavior } from "./choices-behavior";
import { AlertsService, ApplicationStateService, DomainConstants, SettingParam } from "src/app/shared";
import { CreatedShake, KioskScreenButtons, ShakeCreatorRequest } from "../interfaces";
import { BehaviorPromiseProviderService, KioskNavigationService, OrderKioskStorageService, ShakeCreatorService } from "../services";
import { KioskOverlayComponent } from "../components";
import { finalize } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class CallShakeGenerateAPIBehavior extends ChoiceBehavior {

    constructor(
        private kioskNavigationService: KioskNavigationService,
        private alertService: AlertsService,
        private kioskStorageService: OrderKioskStorageService,
        private shakeCreatorService: ShakeCreatorService,
        private applicationStateService: ApplicationStateService,
        private behaviorPromiseProviderService: BehaviorPromiseProviderService) {
        super();
    }

    execute(navObj: IKioskNavigation, orderNavigation: KioskScreenButtons): Promise<void> {
        if (this.terminalType == DomainConstants.TerminalTypes.ORDER_KIOSK.Name) {
            const settingParam = this.applicationStateService.settingParam;
            if (settingParam.OrderKioskAIShakeGenerationLimit > 0 && settingParam.OrderKioskAIShakeGenerationLimit == this.kioskStorageService.aiShakeGenerationCount) {
                this.kioskNavigationService.showAIShakeGenerationLimitReachedMessage();
                this.behaviorPromiseProviderService.rejectPromise();
            } else {
                this.createAIShake(settingParam);
            }
        } else this.behaviorPromiseProviderService.resolvePromise();
        return this.behaviorPromiseProviderService.executionPromise;
    }

    private createAIShake(settingParam: SettingParam) {        
        const shakeCreatorRequest: ShakeCreatorRequest = {
            GroupId: null,
            DietaryRestrictionIds: this.kioskNavigationService.dietaryWarnings.map(x => x.Id)
        };
        const modalRef = this.kioskNavigationService.openOverlayModal(KioskOverlayComponent, () => { },
            { intervalSeconds: this.kioskNavigationService.userIdleTimeout, isShowButtons: false, backgroundImage: `order-kiosk/default-screens/${settingParam.OrderKioskAIShakeProcessingImage}` }
        );
        this.shakeCreatorService.createShake(shakeCreatorRequest)
            .pipe(finalize(() => {
                if (modalRef) {
                    if (settingParam.OrderKioskAIShakeProcessingImageSeconds > 0) {
                        const timeout = setTimeout(() => {
                            modalRef.close.emit();
                            clearTimeout(timeout);
                        }, settingParam.OrderKioskAIShakeProcessingImageSeconds * 1000);
                    } else modalRef.close.emit();
                }
            }))
            .subscribe({
                next: (response: CreatedShake) => {
                    this.kioskStorageService.createdShake = response;
                    this.kioskStorageService.aiShakeGenerationCount++;
                    this.behaviorPromiseProviderService.resolvePromise();
                }, error: (e) => {
                    this.behaviorPromiseProviderService.rejectPromise();
                    this.alertService.showApiError(e);
                }
            });
    }
}

