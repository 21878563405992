<pos-edit-wrapper (close)="onCancel()" [isPopup]="false" [headingIcon]="icons.recaptchaConfig"
  heading="Recaptcha Configuration">
  <form #recapchaConfigForm="ngForm" (ngSubmit)="saveRecapchaSettings(recapchaConfigForm.valid)" autocomplete="off">
    <context-provider provide="props">
      <div class="col-12 pos-modal-body border-top pt-pix-5">
        <div class="col-12">
          <pos-row class="px-2">
            <pos-column>
                <pos-form-field label="Project Id"
                    [validationMessages]="{required: 'Please enter project id.'}">
                    <input posInput class="form-control" name="recapchaProjectId" #recapchaProjectId="ngModel"
                    [(ngModel)]="recapchaSettings.ProjectId" type="text" [required]="true" />
                </pos-form-field>
            </pos-column>
            <pos-column>
                <pos-form-field label="Site Key"
                  [validationMessages]="{required: 'Please enter site key.'}">
                  <input posInput class="form-control" name="recapchaSiteKey" #recapchaSiteKey="ngModel"
                    [(ngModel)]="recapchaSettings.SiteKey" type="text" [required]="true" />
                </pos-form-field>
            </pos-column>
            <pos-column>
                <pos-form-field label="API Key"
                    [validationMessages]="{required: 'Please enter API key.'}">
                    <input posInput class="form-control" name="recapchaAPIKey" #recapchaAPIKey="ngModel"
                    [(ngModel)]="recapchaSettings.APIKey" type="text" [required]="true" />
                </pos-form-field>
            </pos-column>
            <pos-column>
                <pos-form-field label="Secret Key"
                    [validationMessages]="{required: 'Please enter secret key.'}">
                    <input posInput class="form-control" name="recapchaSecretKey" #recapchaSecretKey="ngModel"
                    [(ngModel)]="recapchaSettings.SecretKey" type="text" [required]="true" />
                </pos-form-field>
            </pos-column>
            <pos-column sm="8" md="5" lg="3">
              <pos-form-field label="Upload Config File" [inputStyle]="{'has-error': recapchaConfigForm.submitted && !configFileName}">
                <input posInput type="file" class="form-control" [accept]="acceptableConfigExtensions" #configFile name="configFile" 
                  id="configFile" (change)="handleFileInput($event.target.files)" [required]="true"/>
                  <span *ngIf="!(isChooseFile) && recapchaSettings.ServiceAccountConfigFileName">
                    <label class="control-label">{{recapchaSettings.ServiceAccountConfigFileName}}</label>
                  </span>
                  <span *ngIf="recapchaConfigForm.submitted && !configFileName">
                    <label class="control-label">Please choose file.</label>
                  </span>
              </pos-form-field>
            </pos-column>
          </pos-row>
        </div>
      </div>
      <div class="col-12 action-button-container page-footer">
        <button class="btn btn-primary btn-lg btn-save pos-button" scrollup type="submit"> Save </button>
        <button class="btn btn-secondary btn-lg btn-cancel pos-button" scrollup type="button" routerLink="../">
          Cancel </button>
      </div>
    </context-provider>
  </form>
</pos-edit-wrapper>
