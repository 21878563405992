import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RecaptchaConfig } from '../interface/recaptcha-config';
import { Observable } from 'rxjs';
import { RuntimeConstants } from 'src/app/shared/constants/runtime-constants';
import { SiteKey } from '../interface/site-key';

@Injectable({
  providedIn: 'root'
})
export class RecaptchaConfigService {

  constructor(private httpService: HttpClient) { }
    newRecaptchaConfig(): RecaptchaConfig {
        const recapchaConfigObj: RecaptchaConfig = {
            APIKey: '',
            ProjectId: '',
            SecretKey: '',
            SiteKey: '',
            ServiceAccountConfigFileName: ''
        };
        return recapchaConfigObj;
    }

    getRecaptchaConfig(): Observable<RecaptchaConfig> {
      return this.httpService.get<RecaptchaConfig>(
          `${RuntimeConstants.API_PATH}recaptchaSettings`
      );
    }

    saveRecaptchaConfig(recaptchaSettingsModel: RecaptchaConfig, file?: File): Observable<RecaptchaConfig> {
      const formData = this.prepareCpatchaSettingsFormData(recaptchaSettingsModel, file);
      return this.httpService.post<RecaptchaConfig>(
        `${RuntimeConstants.API_PATH}recaptchaSettings`, formData
      );
    }

    private prepareCpatchaSettingsFormData(param: any, file: File): FormData {
      const formData = new FormData();
      if (file) {
        formData.append('file', file);
      }
      formData.append('model', JSON.stringify(param));
      return formData;
    }

    getSiteKey(): Observable<SiteKey> {
      return this.httpService.get<SiteKey>(
        `${RuntimeConstants.API_PATH}recaptchaSettings/sitekey`
      );
    }
}
