import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountRegistrationComponent } from './account-registration.component';
import { ComponentsModule } from '../shared/components';
import { FormsModule } from '@angular/forms';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';

@NgModule({
  declarations: [AccountRegistrationComponent],
  imports: [
    CommonModule,
    ComponentsModule,
    FormsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
  ]
})
export class AccountRegistrationModule { }
