<div class="row mx-0 p-0 main-div">
    <div class="col-12 pt-pix-10">
        <ng-table #gridComponent [sort]="false" [scrollable]="true" [scrollHeight]="tableHeight" [paginator]="false"
            [search]="false" [columns]="dietaryWarningInfoColumns" [rowClass]="'color'" [data]="dietaryWarnings"
            [styleClass]="'no-border border-radius no-alternative-background theme-separator-color'">
        </ng-table>

        <ng-template let-data="rowData" #inventoryDietaryTemplate>
            <span>{{ data.InventoryProductFriendlyName ? data.InventoryProductFriendlyName: data.InventoryProductName }}</span>
        </ng-template>
    </div>
</div>
