import { Component, OnInit, OnDestroy, ViewChild, ElementRef, HostListener } from '@angular/core';
import { OrderConfirmationService } from './services/order-confirmation.service';
import {
  AlertsService, SpinnerService, ApplicationStateService, RuntimeConstants, DomainConstants, RabbitMQService,
  SettingParam, SalesSizeService, OrderItem,
  ModalService,
  ModalWrapperComponent,
  PhoneNumberNumpadWrapperComponent,
  ICloseable
} from 'src/app/shared';
import { finalize } from 'rxjs/operators';
import { SlideShowDetails, TerminalProperty, TerminalsService } from 'src/app/configurator';
import { forkJoin, interval as observableInterval, Observable, Subscription } from 'rxjs';
import * as _ from 'lodash';
import { UntilDestroy } from '@ngneat/until-destroy';
import { differenceBy, find, forEach } from 'lodash';
import { SalesSize } from 'src/app/information-management/sales-sizes';
import { checkCircleSolid, clock, exclamationCircle } from 'src/app/shared/components/icon/icons';
import { ReviewOrderPayload } from 'src/app/shared/services/rabbitmq/models/review-order-payload';
import { UserReviewOrderComponent } from '../user-review-order/user-review-order.component';
declare let $: any;
@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'pos-order-confirmation',
  templateUrl: './order-confirmation.component.html',
  styleUrls: ['./order-confirmation.component.scss']
})
export class OrderConfirmationComponent implements OnInit, OnDestroy {

  homeState = 'order-confirmation';
  isSlideShow = true;
  slideShowData: Array<any> = [];
  orderConfirmationScrollSpeed = 10;
  idleTimeoutMinutes = 3;
  shouldScroll = false;
  timerList: any = [];
  headerLinks = {
    tasks: false,
    time: false,
    admin: false,
    activeOrders: false,
    warning: false,
    home: false,
    userMenu: false,
    signIn: false
  };
  // icons = { exclamationCircle, checkCircleSolid };
  icons = { clock, exclamationCircle, checkCircleSolid };
  timer: Observable<number>;
  orderConfirmationRabbitMQSubscriptions: Array<any> = [];
  orderId = 0;
  settingParam: SettingParam;
  orderDetails: any;
  priceModifier: any = {};
  orderName = '';
  orderPhoneNumber = '';
  orderCustomerName = '';
  isReviewOrder = false;
  idleTimeInterval: any;
  orderItemTypes = DomainConstants.OrderItemTypes;
  height = '';
  divHeightTimeout: any;
  // rabbitMq subscriptions
  rabbitMqInvoiceSubscription: Subscription;
  rabbitMqOrderReviewedSubscription: Subscription;
  rabbitMQReviewOrderSubscription: Subscription;
  rabbitMqOrderDeleteSubscription: Subscription;
  phoneNumberModalCloseRabbitMQSubscription: Subscription;
  phoneNumberModalOpenRabbitMQSubscription: Subscription;
  orderPhoneNumberModal: ICloseable;
  loyaltyAccountTerm = '';
  accountTypes = DomainConstants.AccountTypes;
  tableTerm = '';
  accountTerm = '';
  itemAddedToOrder;
  isNewProductAdded = false;
  productImagePath = RuntimeConstants.IMAGE_BASE_PATH + '/sales-products';
  showProductText = false;
  productName: string = null;
  salesSizes: Array<SalesSize> = [];
  transitionSeconds: number = null;
  imageAnimationTimeout;
  displayImageTimeout;
  screenHeight = 0;
  @ViewChild('totalDiv') totalDivRef: ElementRef;
  isMobileMode = false;
  refreshTimeout;
  hour = 3600000;
  currentGroupId: number = null;
  firstGroupId: number = null;
  userReviewOrderModalRef: ModalWrapperComponent<typeof UserReviewOrderComponent>;

  constructor(private orderConfirmationService: OrderConfirmationService,
    private alertService: AlertsService,
    private spinnerService: SpinnerService,
    private applicationStateService: ApplicationStateService,
    private terminalService: TerminalsService,
    private rabbitMQService: RabbitMQService,
    private salesSizeService: SalesSizeService,
    private modalService: ModalService) { }

  ngOnInit() {
    this.setDefaults();
    this.loadDependencies();
    this.rabbitMQSubscriptions();
    this.setScreenHeight();
    this.resizeWindow();
    this.refreshOrderConfirmationScreen();

  }

  setDefaults = () => {
    this.settingParam = this.applicationStateService.settingParam;
    this.settingParam.ConfirmationScreenDisplayImageBackground = !this.settingParam.ConfirmationScreenDisplayImageBackground ? '#7AEAE6' :
      this.settingParam.ConfirmationScreenDisplayImageBackground;
    this.transitionSeconds = this.settingParam.ConfirmationScreenDisplayImageTransitionSeconds >=
      this.settingParam.ConfirmationScreenDisplayImageSeconds ? this.settingParam.ConfirmationScreenDisplayImageSeconds - 1
      : this.settingParam.ConfirmationScreenDisplayImageTransitionSeconds;
    this.loyaltyAccountTerm = this.settingParam.LoyalAccountTerm ? this.settingParam.LoyalAccountTerm : 'Account';
    this.tableTerm = this.settingParam.TableTerm ? this.settingParam.TableTerm : 'Table';
    this.accountTerm = this.settingParam.AccountTerm ? this.settingParam.AccountTerm : 'Tab';
    this.settingParam.ConfirmationScreenDisplayImageHeaderStyle = this.settingParam.ConfirmationScreenDisplayImageHeaderStyle ?
      JSON.parse(this.settingParam.ConfirmationScreenDisplayImageHeaderStyle.trim()) : null;
    this.settingParam.ConfirmationScreenDisplayImageProductStyle = this.settingParam.ConfirmationScreenDisplayImageProductStyle ?
      JSON.parse(this.settingParam.ConfirmationScreenDisplayImageProductStyle.trim()) : null;
  }

  ngOnDestroy() {
    _.forEach(this.timerList, (timer) => {
      if (timer) {
        timer.unsubscribe();
      }
    });
    if (this.refreshTimeout) {
      clearInterval(this.refreshTimeout);
    }
  }

  @HostListener('window:resize', ['$event'])
  resizeWindow() {
    this.setScreenHeight();
    this.height = this.totalDivRef ? this.totalDivRef.nativeElement?.offsetHeight : null;
    this.isMobileMode = window.innerWidth <= 767;
  }

  setScreenHeight = () => {
    this.screenHeight = window.innerHeight - 1;
  }

  loadDependencies() {
    const observables: Array<Observable<any>> = [];
    this.spinnerService.show();
    observables.push(this.terminalService.getTerminalProperties(this.applicationStateService.terminalId));
    observables.push(this.orderConfirmationService.getTerminalSlideshowDetails(this.applicationStateService.terminalId));
    observables.push(this.salesSizeService.getSalesSizes());
    forkJoin(observables)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      })).subscribe({
        next: ([terminalProperties, slideshowDetails, salesSizes]:
          [Array<TerminalProperty>, Array<SlideShowDetails>, Array<SalesSize>]) => {
          this.setTerminalProperties(terminalProperties);
          this.setSlidShowDetails(slideshowDetails);
          this.salesSizes = salesSizes;
        },
        error: this.alertService.showApiError
      })
  }

  setTerminalProperties(res) {
    if (res) {
      _.forEach(res, (terminalProperty) => {
        if (terminalProperty.PropertyKey === DomainConstants.TerminalProperties.ORDER_CONFIRMATION_SCROLL_SPEED.Key) {
          this.orderConfirmationScrollSpeed = parseInt(terminalProperty.PropertyValue, 0);
        } else if (terminalProperty.PropertyKey === DomainConstants.TerminalProperties.ORDER_CONFIRMATION_IDLE_TIMEOUT.Key) {
          this.idleTimeoutMinutes = parseInt(terminalProperty.PropertyValue, 0);
        }
      });
    }
  }

  setSlidShowDetails(res) {
    if (res) {
      _.forEach(res, (slideShow) => {
        slideShow.ImagePath = RuntimeConstants.IMAGE_BASE_PATH + '/' + DomainConstants.ImageDirectories.slideImages
          + '/' + slideShow.ImagePath;
        const slideShowObj = {
          source: slideShow.ImagePath,
          duration: slideShow.HoldSeconds * 1000,
          id: slideShow.Id
        };
        this.slideShowData.push(slideShowObj);
      });
      this.scrollTimer();
    }
  }

  rabbitMQSubscriptions = () => {

    this.rabbitMqOrderReviewedSubscription = this.rabbitMQService.subscribeToOrderReviewedMessage$(this.settingParam.OrderEntryTerminalId)
      .subscribe({
        next: (message: any) => {
          this.isReviewOrder = true;
          this.prepareOrderDetails(message);
        }, error: () => {
          console.log('error while connecting to RabbitMQ.');
        }
      });

    this.rabbitMQReviewOrderSubscription = this.rabbitMQService.subscribeToReviewOrderMessage$(this.settingParam.OrderEntryTerminalId)
      .subscribe({
        next: (message: any) => {
          this.openReviewOrderModal(message.Payload);
        },
        error: () => console.log('error while connecting to RabbitMQ.')
      });

    this.rabbitMqInvoiceSubscription = this.rabbitMQService.subscribeToInvoiceExchange$(this.settingParam.OrderEntryTerminalId)
      .subscribe({
        next: (message: any) => {
          this.isReviewOrder = false;
          this.prepareOrderDetails(message);
        }, error: () => {
          console.log('error while connecting to RabbitMQ.');
        }
      });

    this.rabbitMqOrderDeleteSubscription = this.rabbitMQService.subscribeToMakeTableExchange$(this.settingParam.OrderEntryTerminalId)
      .subscribe({
        next: (message: any) => {
          if (this.orderDetails && message?.Payload?.OrderDetails) {
            this.orderDetails.ScheduleDate = message?.Payload?.OrderDetails?.ScheduleDate;
          }
        }, error: () => {
          console.log('error while connecting to RabbitMQ.');
        }
      });

    this.phoneNumberModalCloseRabbitMQSubscription = this.rabbitMQService.subscribeToOrderPhoneNumberModalCloseMessage$(this.settingParam.OrderEntryTerminalId)
    .subscribe({
      next: () => this.orderPhoneNumberModal?.close.emit(),
    });

    this.phoneNumberModalOpenRabbitMQSubscription = this.rabbitMQService.subscribeToOrderPhoneNumberModalOpenMessage$(this.settingParam.OrderEntryTerminalId)
    .subscribe({
      next: () => {
        const modal = this.modalService.getModalWrapper(PhoneNumberNumpadWrapperComponent);
        this.orderPhoneNumberModal = modal.show({
          animated: false,
          class: 'vertical-center',
          initialState: {
            numpadTitle: 'Set Phone Number',
            numpadOption: {
              allowDecimal: false, allowLeadingZero: true, allowAlphabets: true, doubleZero: false, isMaskInput: true,
              mask: this.settingParam?.PhoneNumberMask?.trim() ? this.settingParam.PhoneNumberMask : DomainConstants.DefaultPhoneMask,
              maxLength: 10,
              minLength: 10,
              minValidationMessage: 'Please enter a valid phone number',
            },
            value: ' '
          }
        });
        this.orderPhoneNumberModal.close.subscribe({
          next: (res) => {
            this.orderPhoneNumberModal.close.unsubscribe();
            this.orderPhoneNumberModal = null;
            this.rabbitMQService.SendOrderPhoneNumberModalCloseMessage(this.settingParam.OrderEntryTerminalId, res);
          }
        })
      }
    })
  }

  openReviewOrderModal(payload: ReviewOrderPayload) {
    this.userReviewOrderModalRef = this.modalService.getModalWrapper(UserReviewOrderComponent);
    const modal = this.userReviewOrderModalRef.show({
      animated: false,
      class: 'vertical-center modal-max-width-95',
      initialState: {
        seats: payload.Seats,
        orderData: payload.OrderData
      }
    });
    modal.close.subscribe((response) => {
      this.userReviewOrderModalRef = null;
      if (response?.sendRabbitMqMessage) {
        this.rabbitMQService.sendCloseReviewOrderMessage(this.applicationStateService.settingParam.OrderEntryTerminalId, response.isConfirmed);
      }
    });
  }

  prepareOrderDetails = (rabbitMQMessage) => {
    if (rabbitMQMessage?.Payload?.OrderDetails) {
      this.orderName = '';
      this.orderCustomerName = '';
      if (this.settingParam.ConfirmationScreenDisplayImageOnAddProduct && this.settingParam.ConfirmationScreenDisplayImageSeconds) {
        this.resetImageDisplayProperties();
        this.findItemAddedToOrder(rabbitMQMessage.Payload.OrderDetails);
      }
      this.orderDetails = rabbitMQMessage.Payload.OrderDetails;
      if (this.orderDetails?.Id?.Value) {
        if (this.orderId != this.orderDetails.Id.Value) {
          this.isSlideShow = false;
          if (this.idleTimeInterval) {
            clearInterval(this.idleTimeInterval);
          }
          this.idleTimeInterval = setTimeout(() => {
            this.isSlideShow = true;
            this.orderId = 0;
            this.userReviewOrderModalRef?.hide();
          }, this.idleTimeoutMinutes * 60 * 1000);
        }
        this.setOrderName();
        this.setOrderPhoneNumber();
        this.orderId = this.orderDetails.Id.Value;
        this.checkIfNeedsScrolling();
        this.getTotalDivHeight();
        this.firstGroupId = this.orderDetails.OrderItems.find(x => x.GroupId !== null)?.GroupId;
        forEach(this.orderDetails.OrderItems, (orderItem: OrderItem) => {
          orderItem.IsScheduled = orderItem.ScheduleTime && new Date(orderItem.ScheduleTime) > new Date();
          orderItem.IsComboProduct = this.setIsComboProduct(orderItem.GroupId);
        });
      } else {
        // No need to open model b'cz if orderDetails is not available then we don't have surrogateOrderId
        // if (message.Payload.OrderDetails == "PaymentCompleted" && settingParam.ShowOrderNumberAfterPayment) {
        //   var orderConformationModal = modalHelper.openInformationModal('Your order number is ' + $scope.SurrogateOrderId);
        //   $timeout(function () { orderConformationModal.close() }, 10000);
        // }
        this.resetDefault();
      }
    } else {
      this.resetDefault();
    }
  }

  findItemAddedToOrder(orderDetails) {
    const lastItem = orderDetails.OrderItems[orderDetails.OrderItems.length - 1];
    let isNewProductDisplayed = false;
    if (lastItem) {
      const difference = new Date().getTime() - new Date(lastItem.DateAdded).getTime();
      if (difference < 5000) {
        lastItem.ProductImage = lastItem.SalesProductBusinessEntity?.ProductImage;
        if (lastItem.ProductImage) {
          this.itemAddedToOrder = lastItem;
          isNewProductDisplayed = true;
          this.displayNewProduct();
        }
      }
    }
    if (this.orderDetails && this.orderDetails?.OrderItems?.length < orderDetails.OrderItems.length &&
      orderDetails.Id.Value === this.orderId && !isNewProductDisplayed) {
      const newItem: Array<any> = differenceBy(orderDetails.OrderItems, this.orderDetails.OrderItems, 'Id.Value');
      if (newItem.length) {
        newItem.map(x => x.ProductImage = x.SalesProductBusinessEntity?.ProductImage);
        if (newItem[0].ProductImage) {
          this.itemAddedToOrder = newItem[0];
          this.displayNewProduct();
        }
      }
    }
  }

  displayNewProduct() {
    this.resetImageDisplayProperties();
    const displayTimeout = setTimeout(() => {
      this.isNewProductAdded = true;
      this.getSalesProductSize();
      this.imageAnimationTimeout = setTimeout(() => {
        this.showProductText = true;
        this.clearImageAnimationTimeout();
      }, this.transitionSeconds * 1000);
      this.displayImageTimeout = setTimeout(() => {
        this.isNewProductAdded = false;
        this.productName = null;
        this.clearDisplayImageTimeout();
      }, this.settingParam.ConfirmationScreenDisplayImageSeconds * 1000);
      if (displayTimeout) {
        clearTimeout(displayTimeout);
      }
    });
  }

  clearDisplayImageTimeout() {
    if (this.displayImageTimeout) {
      clearTimeout(this.displayImageTimeout);
    }
  }

  clearImageAnimationTimeout() {
    if (this.imageAnimationTimeout) {
      clearTimeout(this.imageAnimationTimeout);
    }
  }

  resetImageDisplayProperties() {
    this.isNewProductAdded = false;
    this.showProductText = false;
    this.productName = null;
    this.clearDisplayImageTimeout();
    this.clearImageAnimationTimeout();
  }

  getSalesProductSize() {
    const salesProductBusinessEntity = this.itemAddedToOrder.SalesProductBusinessEntity;
    if (salesProductBusinessEntity.SalesProductPrices?.length > 1) {
      let sizeId = null;
      const orderProductComponent = this.itemAddedToOrder.OrderProductComponents ? this.itemAddedToOrder.OrderProductComponents[0] : null;
      if (orderProductComponent && orderProductComponent.SizeId > 0) {
        sizeId = orderProductComponent.SizeId;
      } else {
        sizeId = salesProductBusinessEntity.SalesCategory.DefaultSizeId;
      }
      const productSize = find(this.salesSizes, x => x.Id === sizeId);
      this.productName = `${this.itemAddedToOrder.ReceiptText} (${productSize.Name})`;
    }
  }

  resetDefault = () => {
    this.orderDetails = null;
    this.orderId = 0;
    this.isSlideShow = true;
    this.shouldScroll = false;
    this.userReviewOrderModalRef?.hide();
  }

  setOrderPhoneNumber = () => {
    this.orderPhoneNumber = '';
    this.orderPhoneNumber = this.orderDetails.OrderAttributes.PhoneNumber;
  }

  setOrderName = () => {
    if (this.orderDetails.OrderAttributes.Name) {
      this.orderName = 'Name: ' + this.orderDetails.OrderAttributes.Name;
      this.orderCustomerName = this.orderName.substring(5);
    }
  }

  checkIfNeedsScrolling = () => {
    this.shouldScroll = $(document).height() >= $(window).height();
  }

  scrollTimer = () => {
    const secondsToScrollPage = this.orderConfirmationScrollSpeed || 4;
    const scrollSpeed = Math.ceil($(document).height() / $(window).height()) * 1000 * secondsToScrollPage;
    const self = this;
    this.timer = observableInterval(scrollSpeed + 3000);
    const timer = this.timer.subscribe((t) => {
      self.scrollContent(scrollSpeed);
    });
    this.timerList.push(timer);
  }

  scrollContent = (scrollSpeed) => {
    if (this.shouldScroll) {
      $('html, body').animate({ scrollTop: $(document).height() },
        { duration: scrollSpeed, queue: true });

      setTimeout(() => {
        $('html, body').animate({ scrollTop: 0 }, { duration: 1000, easing: 'linear', queue: true });
      }, scrollSpeed + 1000);
    }
  }

  getTotalDivHeight() {
    this.divHeightTimeout = setTimeout(() => {
      this.height = this.totalDivRef ? this.totalDivRef.nativeElement?.offsetHeight : null;

      if (this.divHeightTimeout) {
        clearTimeout(this.divHeightTimeout);
      }
    });
  }


  refreshOrderConfirmationScreen() {
    this.refreshTimeout = setInterval(() => {
      if (!this.orderDetails) {
        clearInterval(this.refreshTimeout);
        window.location?.reload();
      }
    }, this.hour);
  }

  setIsComboProduct(groupId: number) {
    let isSameGroup = (groupId != null && groupId == this.currentGroupId);
    if (groupId == this.firstGroupId) {
      this.firstGroupId = null;
      isSameGroup = false;
    }
    this.currentGroupId = groupId;
    return isSameGroup;
  }
}
