import { DatePipe } from '@angular/common';
import { ErrorHandler, Injectable, NgModule } from '@angular/core';
import { AppComponent } from './app.component';

// Import these globally to make the typescript compiler happy by bringing in their @types
// import 'angular';
// import 'angular-resource';
// import 'angular-route';

// Need to import NG 1.x module
// import app-configuration file here
import { AuthModule } from './shared/auth/auth.module';
import { SharedModule } from './shared/shared.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TableModule } from '@tarktech/tark-ng-utils';
import { ComponentsModule, DeactivateGuard, LocalProvider, LoggerService, TableConfiguration } from './shared';
import { HeaderEntryModule } from './header-entry/header-entry.module';
import { MainService } from './header-entry/services/main.service';
import { ApiInterceptor } from './shared/interceptors/api-interceptor';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { OrderCardSharedModule } from './order-shared/order-card-shared.module';
import { AuthGuard } from './app-guard';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LOCAL_PROVIDER } from '@tarktech/tark-ng-utils/table/service/tark-local-provider.service';
import { ApplicationStateService, ManagementDashboardModule, MenuDisplayModule, OrderMaintenanceModule, WaitListModule } from '.';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { RxStompService } from './shared/services/rabbitmq/services/rx-stomp.service';
import { NgxContextModule } from '@tarktech/ngx-context';
import { TrainingApprovalModalComponent } from './training/training-approve/components/training-approval-modal/training-approval-modal.component';
import { OrderKioskModule } from './order-kiosk/order-kiosk.module';
import { SpinnerService, SpinnerComponent } from './shared/components/spinner';
import { RabbitMQConfigurationService } from './shared/services/rabbitmq';
import { AccountRegistrationModule } from './account-registration/account-registration.module';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private loggerService: LoggerService) { }
  handleError(error: Error) {
    let errorMessage = error.toString();
    errorMessage = JSON.stringify(error.stack ? error.stack : error);
    this.loggerService.logError(`Unhandled exception occur from angular app, Error: ${errorMessage}`);
  }
}
@NgModule({
  imports: [
    SharedModule,
    BrowserModule,
    BrowserAnimationsModule,
    AuthModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    HeaderEntryModule,
    TableModule.forRoot(TableConfiguration),
    OrderCardSharedModule,
    OrderMaintenanceModule,
    ManagementDashboardModule,
    MatCardModule,
    MatSelectModule,
    MatSliderModule,
    AppRoutingModule,
    ComponentsModule,
    MenuDisplayModule,
    HttpClientModule,
    NgxContextModule,
    OrderKioskModule,
    WaitListModule,
    AccountRegistrationModule
  ],
  providers: [DatePipe,
    RxStompService,
    RabbitMQConfigurationService,
    MainService,
    {
      // processes all errors
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    },
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true, deps: [ApplicationStateService] },
    { provide: LOCAL_PROVIDER, useExisting: LocalProvider },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' }
    },
    AuthGuard,
    SpinnerService,
    DeactivateGuard
  ],
  declarations: [AppComponent, SpinnerComponent, TrainingApprovalModalComponent],
  exports: [SpinnerComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
