import { Injectable } from '@angular/core';
import { BaseCrudService } from '../../../shared/services/base-crud.service';
import { Font } from '../interface/font';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FontTypeService extends BaseCrudService<Font>{

  constructor(httpService: HttpClient) {
    super('fonts', httpService);
  }

  newFont() : Font {
    const font: Font = {
      Id: 0,
      Name: null,
      FileName: null,
    };
    return font;
  }
}
