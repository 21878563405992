import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  AlertsService, SpinnerService, ApplicationStateService, RabbitMQService,
  DomainConstants, RuntimeConstants, SettingParam, MessageTypes, ActiveOrder, OrderItem, OrderService
} from 'src/app/shared';
import { TerminalsService, SlideShowDetails } from 'src/app/configurator';
import { filter, finalize } from 'rxjs/operators';
import * as _ from 'lodash';
import { OrderStatusService } from '../services/order-status.service';
import { OrderStatusStates } from 'src/app/shared/interface/order-status-states';
import { OrderStatusInfo } from '../interfaces/order-status-info';
import { SlideShowData } from 'src/app/shared/interface/slide-show-data';
import { cloneDeep, find, remove } from 'lodash';
import { Subscription } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { OrderInvoice } from 'src/app/orders';
declare let $: any;
@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'pos-order-status',
  templateUrl: './order-status.component.html',
  styleUrls: ['./order-status.component.scss']
})
export class OrderStatusComponent implements OnInit, OnDestroy {

  headerLinks = {
    tasks: false,
    time: false,
    admin: false,
    activeOrders: false,
    warning: false,
    home: false,
    userMenu: false,
    signIn: false
  };
  homeState = 'order-status';
  fontSize: number;
  slideShowData: Array<SlideShowData> = [];
  orderStatusRabbitMQSubscriptions: Array<any> = [];
  orderStatusStates: Array<OrderStatusStates> = [];
  settingParams: SettingParam;
  isSlideShow: boolean;
  orderStatusList: Array<OrderStatusInfo> = [];

  // Subscriptions
  rabbitMqOrderServedSubscription: Subscription;
  rabbitMqOrderUpdatesFromAllMakeTableSubscription: Subscription;
  rabbitMqMakeTableExchangeSubscription: Subscription;
  rabbitMqOrderProductStateChangeSubscription: Subscription;
  rabbitMqOrderDeletedSubscription: Subscription;
  rabbitMqScheduleOrderUpdateSubscription: Subscription;
  rabbitMqOrderDeleteSubscription: Subscription;

  constructor(private alertService: AlertsService,
    private spinnerService: SpinnerService,
    private applicationStateService: ApplicationStateService,
    private terminalService: TerminalsService,
    private orderService: OrderService,
    private rabbitMQService: RabbitMQService,
    private orderStatusService: OrderStatusService) { }

  ngOnInit() {
    this.getTerminalProperties();
    this.getActiveOrdersForOrderStatus();
    this.getSlideShowDetails();
    this.setDefaults();
    this.rabbitMQSubscriptions();
  }

  setDefaults = () => {
    this.settingParams = this.applicationStateService.settingParam;
    this.orderStatusStates = this.settingParams.OrderStatusStates;
  }

  ngOnDestroy() {
  }

  getTerminalProperties = (): void => {
    this.spinnerService.show();
    this.terminalService.getTerminalProperties(this.applicationStateService.terminalId)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res) => {
          if (res) {
            _.forEach(res, (terminalProperty) => {
              if (terminalProperty.PropertyKey === DomainConstants.TerminalProperties.FONT_SIZE.Key) {
                this.fontSize = parseInt(terminalProperty.PropertyValue, 0);
              }
            });
          }
        }, error: this.alertService.showApiError
      });
  }

  getActiveOrdersForOrderStatus = (): void => {
    this.spinnerService.show();
    this.orderStatusService.getActiveOrdersForOrderStatus()
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res: Array<ActiveOrder>) => {
          if (res) {
            this.getActiveOrdersForOrderStatusCompleted(res);
          }
        }, error: this.alertService.showApiError
      });
  }

  getActiveOrdersForOrderStatusCompleted = (orderDetails: Array<ActiveOrder>): void => {
    this.orderStatusList = [];
    _.forEach(orderDetails, (order: ActiveOrder) => {
      let orderId = 0;
      const isAllItemIsDone = this.checkIsAllOrderItemDone(order);
      if (order.OrderItems.length > 0) {
        _.forEach(order.OrderItems, (orderItem: OrderItem) => {
          if (orderId !== order.Id && this.canSetOrderItemForOrderStatus(orderItem, isAllItemIsDone)) {
            let customerName = this.getCustomerDisplayName(order);
            this.orderStatusList.push(this.prepareOrderStatusInfo(order, orderItem, customerName, order.Id, order.SurrogateOrderId));
            orderId = order.Id;
          }
        });
      }
    });
    this.setSlideShow();
  }

  getSlideShowDetails = (): void => {
    this.spinnerService.show();
    this.orderStatusService.getTerminalSlideshowDetails(this.applicationStateService.terminalId)
      .pipe(finalize(() => {
        this.spinnerService.hide();
      }))
      .subscribe({
        next: (res: Array<SlideShowDetails>) => {
          if (res) {
            _.forEach(res, (slideShow: SlideShowDetails) => {
              slideShow.ImagePath = RuntimeConstants.IMAGE_BASE_PATH + '/' + DomainConstants.ImageDirectories.slideImages
                + '/' + slideShow.ImagePath;
              const slideShowObj: SlideShowData = {
                source: slideShow.ImagePath,
                duration: slideShow.HoldSeconds * 1000,
                id: slideShow.Id
              };
              this.slideShowData.push(slideShowObj);
            });
          }
        }, error: this.alertService.showApiError
      });
  }

  prepareOrderStatusInfo = (order: ActiveOrder, orderItem: OrderItem, name: string, orderId: number, surrogateOrderId: number): OrderStatusInfo => {
    const orderStatusInfo: OrderStatusInfo = {
      Status: this.getOrderState(orderItem.MakeTableState),
      OrderId: orderId,
      ScreenName: orderItem?.OrderProductComponents?.length ? orderItem.ReceiptText : '',
      Name: name,
      SurrogateOrderId: surrogateOrderId,
      ScheduleDate: orderItem.ScheduleTime,
      RemainingItems: order ? this.getUnservedOrderItemCount(order) : 0
    };
    if (orderItem.MakeTableState === DomainConstants.MakeTableStates.DONE) {
      this.getOrderDetails(orderStatusInfo, true);
    }
    return orderStatusInfo;
  }

  getUnservedOrderItemCount = (order: ActiveOrder) : number => {
    var unservedOrderItemsCount = order.OrderItems.filter(x => x.OrderProductComponents[0]?.IsShowOnMakeTable && x.MakeTableState != DomainConstants.MakeTableStates.DONE).map(x => x.Qty).reduce((sum, qty) => sum + qty, 0);
    return unservedOrderItemsCount;
  }

  rabbitMQSubscriptions = (): void => {
    this.rabbitMqScheduleOrderUpdateSubscription = this.rabbitMQService.subscribeToUpdateScheduleOrderMessage$()
      .subscribe({
        next: () => {
          this.getActiveOrdersForOrderStatus();
          this.setSlideShow();
        }
      });

    this.rabbitMqOrderUpdatesFromAllMakeTableSubscription = this.rabbitMQService.subscribeToOrderUpdatesFromAllMakeTables$()
      .subscribe({
        next: (message: any) => {
          this.productUpdateCallBack(message);
        }, error: () => {
          console.log('error while connecting to RabbitMQ.');
        }
      });

    this.rabbitMqOrderServedSubscription = this.rabbitMQService.subscribeToOrderServedMessage$()
      .subscribe({
        next: (message: any) => {
          const orderId = parseInt(message.Payload.OrderId, 0);
          const product: OrderStatusInfo = this.getOrderStatusById(orderId);
          if (product?.TimeoutId) {
            clearTimeout(product.TimeoutId);
          }
          _.remove(this.orderStatusList, (product) => {
            return product.OrderId === orderId;
          });
          this.setSlideShow();
        }, error: () => {
          console.log('error while connecting to RabbitMQ.');
        }
      });

    this.rabbitMqMakeTableExchangeSubscription = this.rabbitMQService.subscribeToOrderUpdatedMessageForAllOrderEntry$()
      .subscribe({
        next: (message) => {
          this.orderProductDetail(message);
        }, error: () => {
          console.log('error while connecting to RabbitMQ.');
        }
      });

    this.rabbitMqOrderProductStateChangeSubscription = this.rabbitMQService.subscribeToOrderItemStateChangedForAllOrderEntryMessage$()
      .subscribe({
        next: (message) => {
          this.orderProductDetail(message);
        }, error: () => {
          console.log('error while connecting to RabbitMQ.');
        }
      });
    
    this.rabbitMqOrderDeletedSubscription = this.rabbitMQService.subscribeToOrderDeletedMessageForAllOrderEntry$()
      .subscribe({
        next: (message) => {
          this.removeOrder(message.Payload.OrderId);
        }, error: () => {
          console.log('error while connecting to RabbitMQ.');
        }
      });
  }

  productUpdateCallBack = (message): void => {
    this.isSlideShow = false;
    $('html').css('overflow', 'auto');
    const orderItems = message?.Payload?.OrderProductDetails;
    if (orderItems) {
      _.forEach(orderItems, (orderItem) => {
        if (!this.isOnlineOrderContent(orderItem.ComponentName, orderItem.SalesProductID)) {
          _.forEach(this.orderStatusList, (item: OrderStatusInfo) => {
            if (orderItem.OrderId === item.OrderId) {
              if (orderItem.CompleteStatus) {
                item.Status = 'Completed';
              } else {
                item.Status = this.getOrderState(orderItem.make_table_state);
              }
              item.ScreenName = orderItems[0].ScreenName;
              this.changeUpdateProductBgColor(orderItem);
            }
          });
        }
      });
    }
  }

  changeUpdateProductBgColor = (orderItem): void => {
    $('#orderStatusTable' + orderItem.OrderId).fadeIn(300, function () {
      $('#orderStatusTable' + orderItem.OrderId).addClass('status-bg-yellow');
    });

    setTimeout(() => {
      $('#orderStatusTable' + orderItem.OrderId).removeClass('status-bg-yellow');
    }, 2000);
  }

  removeOrder(orderId) {
    const product: OrderStatusInfo = this.getOrderStatusById(orderId);
    if (product?.TimeoutId) {
      clearTimeout(product.TimeoutId);
    }
    _.remove(this.orderStatusList, (item: OrderStatusInfo) => {
      return item.OrderId === orderId;
    });
    this.setSlideShow();
  }

  orderProductDetail = (message): void => {
    const orderDetails: ActiveOrder = message.Payload.OrderDetails;
    orderDetails.OrderItems = this.filterItemsShownInMakeTable(orderDetails);

    let orderId = orderDetails?.Id ? orderDetails.Id : 0;
    let isNewOrder = this.isNewOrder(message);

    /* CASE: sometime order served in other screen but it's available in maketable and  when user change the state of any
     item then order never removed from order status screen : Season 2021 */
    if (isNewOrder && !orderDetails.ServedDateTime) {
      _.remove(orderDetails.OrderItems, (item: OrderItem) => {
        return item.OrderProductComponents[0].ReceiptText.toLowerCase().indexOf('name:') !== -1;
      });
      orderId = 0;
      _.forEach(orderDetails.OrderItems, (orderItem: OrderItem) => {
        if (orderId !== orderDetails.Id && this.canSetOrderItemForOrderStatus(orderItem)) {
          let customerName = this.getCustomerDisplayName(orderDetails); 
          this.orderStatusList.push(this.prepareOrderStatusInfo(orderDetails, orderItem, customerName, orderDetails.Id,
            orderDetails.SurrogateOrderId));
          orderId = orderDetails.Id;
        }
      });
    } else {
      const orderStatusInfo = this.getOrderStatusById(orderDetails.Id);
      if ((orderStatusInfo && orderDetails.ScheduleDate && new Date(orderDetails.ScheduleDate) > new Date()) || (orderStatusInfo && orderDetails.OrderItems.length === 0)) {
        remove(this.orderStatusList, x => x.OrderId === orderStatusInfo.OrderId);
      }
      _.forEach(orderDetails.OrderItems, (orderItem: OrderItem) => {
        const item = this.getOrderStatusById(orderDetails.Id);
        if (item && (!orderItem.ScheduleTime || (orderItem.ScheduleTime && new Date(orderItem.ScheduleTime) < new Date()))) {
          item.Name = this.getProductDisplayName(orderDetails, orderDetails.OrderName);
          const status = orderItem.OrderItemType == DomainConstants.OrderItemTypes.Product ?
            this.getOrderState(orderItem.MakeTableState) : item.Status;
          if (!item.Name) {
            item.Name = '---';
          }
          if (status === this.getOrderState(DomainConstants.MakeTableStates.DONE)) {
            if (item.TimeoutId) {
              clearTimeout(item.TimeoutId);
            }
            const updateOrderStatusTimeout = setTimeout(() => {
              this.handleOrderTimeout(item);
            }, 5000);
            item.TimeoutId = updateOrderStatusTimeout;
          }
          this.updateRemainingItemCount(message, orderItem, status);
          if (message.Type === MessageTypes.ORDER_PRODUCT_STATE_CHANGE && (item.Status !== status || (orderItem.OrderItemType === DomainConstants.OrderItemTypes.Product && item.ScreenName !== orderItem.ReceiptText))) {
            item.Status = status;
            this.changeUpdateProductBgColor(item);
            item.ScreenName = orderItem.ReceiptText;
            return false;
          }
        }
      });
    }

    this.setSlideShow();
  }

  getOrderStatusById(orderId: number): OrderStatusInfo {
    return _.find(this.orderStatusList, (item: OrderStatusInfo) => {
      return item.OrderId == orderId;
    });
  }

  filterItemsShownInMakeTable(order: ActiveOrder){
    return order.OrderItems.filter(x => x.OrderProductComponents[0].IsShowOnMakeTable);
  }

  isNewOrder(message): boolean{
    const orderDetails = message.Payload.OrderDetails;
    let orderId = orderDetails?.Id ? orderDetails.Id : 0;

    const orderStatusInfo = this.getOrderStatusById(orderId);
    return orderStatusInfo ||
      message.Type == MessageTypes.ORDER_PRODUCT_STATE_CHANGE ||
      (orderDetails.ScheduleDate &&
        new Date(orderDetails.ScheduleDate) > new Date())
      ? false
      : true;
  }

  getCustomerDisplayName(order: ActiveOrder): string {
    let customerName = this.getProductDisplayName(order, order.OrderName);
    if (!customerName) {
      customerName = '---';
    }
    return customerName;
  }

  handleOrderTimeout(item: OrderStatusInfo){
    this.getOrderDetails(item);
    if (item.TimeoutId) {
      clearTimeout(item.TimeoutId);
    }
  }

  updateRemainingItemCount(message, orderItem: OrderItem, itemStatus: string){
    const orderDetails = message.Payload.OrderDetails;
    const item = this.getOrderStatusById(orderDetails.Id);
    if(message.Type === MessageTypes.ORDER_PRODUCT_STATE_CHANGE && itemStatus === this.getOrderState(DomainConstants.MakeTableStates.PENDING)){
      if(item.TimeoutId){
        this.handleOrderTimeout(item);
      }
      else{
        item.RemainingItems += orderItem.Qty;
      }
    }
    if(message.Type == MessageTypes.ORDER_UPDATE){
      item.RemainingItems = this.getUnservedOrderItemCount(orderDetails);
    }
  }

  canSetOrderItemForOrderStatus(orderItem, isAllItemDone = false) {
    return (orderItem.OrderProductComponents.length ? orderItem.OrderProductComponents[0].IsShowOnMakeTable : false) &&
      (orderItem.Qty - Math.abs(orderItem.RefundQty ? orderItem.RefundQty : 0)) > 0 &&
      orderItem.OrderProductComponents[0].ReceiptText.toLowerCase().indexOf('name:') === -1 &&
      (orderItem.MakeTableState !== DomainConstants.MakeTableStates.DONE || isAllItemDone) &&
      !this.isOnlineOrderContent(orderItem.OrderProductComponents[0].ReceiptText, orderItem.SalesProductId) &&
      (orderItem.ScheduleTime ? new Date(orderItem.ScheduleTime) <= new Date() : true);
  }
  getOrderDetails(item: OrderStatusInfo, isCalledOnLoad = false) {
    let orderDetails = null;
    this.orderService.getOrderDetailsWithAllComponents(item.OrderId)
      .subscribe({
        next: (response: OrderInvoice) => {
          orderDetails = response;
          this.setOrderItemStatus(orderDetails, item, isCalledOnLoad);
          item.RemainingItems = this.getUnservedOrderItemCount(orderDetails);
        }, error: this.alertService.showApiError
      });
  }

  setOrderItemStatus(order, item: OrderStatusInfo, isCalledOnLoad = false) {
    let orderId = 0;
    if (order.OrderItems.length > 0) {
      _.forEach(order.OrderItems, (orderItem: OrderItem) => {
        if (orderId !== order.Id && this.canSetOrderItemForOrderStatus(orderItem)) {
          if (orderItem) {
            item.Status = this.getOrderState(orderItem.MakeTableState);
            if (orderItem.MakeTableState !== DomainConstants.MakeTableStates.DONE
              && (!orderItem.ScheduleTime || (orderItem.ScheduleTime && new Date(orderItem.ScheduleTime) <= new Date()))) {
              item.ScreenName = orderItem.OrderProductComponents[0]?.ReceiptText;
              if (!isCalledOnLoad) {
                this.changeUpdateProductBgColor(item);
              }
            }
            return false;
          }
          orderId = order.Id;
        }
      });
    }
  }
  checkIsAllOrderItemDone(order) {
    const orderItems = filter(order.OrderItems, (item) => {
      return item.MakeTableState != DomainConstants.MakeTableStates.DONE && item.OrderProductComponents[0]?.IsShowOnMakeTable;
    });
    return orderItems?.length ? true : false;
  }
  setSlideShow = (): void => {
    if (this.orderStatusList.length > 0) {
      this.isSlideShow = false;
      $('html').css('overflow', 'auto');
    } else {
      this.isSlideShow = true;
      $('html').css('overflow', 'hidden');
    }
  }

  getProductDisplayName = (orderDetails: ActiveOrder, defaultReturnString: string): string => {
    if (orderDetails.CustomerAccount?.Name) {
      return orderDetails.CustomerAccount.Name;
    } else if (orderDetails.Account?.Name) {
      return orderDetails.Account.Name;
    } else {
      return defaultReturnString;
    }
  }

  getOrderState = (makeTableState: string): string => {
    let currentOrderStatus = null;
    currentOrderStatus = _.find(this.orderStatusStates, (status) => {
      return status.maketableWorkflowStates.Code === makeTableState;
    });
    return currentOrderStatus ? currentOrderStatus.OrderState : makeTableState;
  }

  isOnlineOrderContent = (itemName: string, productId: number): boolean => {
    return itemName && (itemName.includes('Phone:') || itemName.includes('Online Order - Pickup time:')) && productId < 0;
  }
}
