<header class="main-header">
  <pos-keyboard-wedge [(isEnabled)]="isKeyboardWedgeEnabled" (onSwipeDetected)="onSwipeDetected($event)">
  </pos-keyboard-wedge>
  <div class="row mx-0 main-header__block bg-theme-primary border-header-top position-relative">
    <div class="col-md-2 col-12 logo main-header__block__logo pe-0">
      <a class="main-header__block__logo__link pull-left pe-pix-15 display-flex justify-content-center">
        <img class="img-responsive" (click)="gotoTerminal()" src="{{imagePath}}/logo/logo.png" />
      </a>
      <a href="javascript:;" class="main-header__block__logo__link" *ngIf="trainingMode">
        <img class="img-responsive training-mode bg-theme-separator" alt="Training Mode"
          src="images/admin/manage-training-mode.png" pTooltip="Training Mode" tooltipPosition="bottom" />
      </a>
    </div>
    <div class="col-sm-8 col-12 main-header__block__main-menu">
      <nav class="navbar navbar-expand-lg navigation main-header__block__main-menu__nav py-0" role="navigation">
        <div class="navbar-header" *ngIf="isShowBarIcon">
          <button class="navbar-toggler main-header__block__main-menu__nav__mobile pull-left header-nav-icon"
            data-bs-target="#bs-example-navbar-collapse-1" data-bs-toggle="collapse" type="button">
            <span class="sr-only">Toggle navigation</span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </button>
        </div>
        <div aria-haspopup="true"
          class="collapse navbar-collapse main-header__block__main-menu__nav__block pe-pix-15 ps-pix-15"
          id="bs-example-navbar-collapse-1">
          <ul class="nav navbar-nav">
            <li *ngIf="linksVisibility.home">
              <a class="pointer display-flex" (click)="homeClick()">
                <div class="menu-icon">
                  <pos-icon [icon]="icons.home"></pos-icon>
                </div>
                <div class="display-flex">Home</div>
              </a>
            </li>
            <li *ngIf="linksVisibility.warning && warnings.length">
              <a class="warning pointer" (click)="warningClick()">
                Warning
              </a>
            </li>
            <li *ngIf="linksVisibility.tasks && (applicationConfig ? applicationConfig.HasTaskSetup : true)">
              <a class="pointer display-flex" (click)="taskClick()" [ngClass]="{'shiftduties-alert': shiftDutiesDue}">
                <div class="menu-icon pe-pix-5">
                  <pos-icon [icon]="icons.taskClipboardList"></pos-icon>
                </div>
                <div class="display-flex">Tasks</div>
              </a>
            </li>
            <li *ngIf="linksVisibility.changeTerminal">
              <a class="pointer display-flex" (click)="gotoTerminal()">
                <div class="menu-icon pe-pix-5">
                  <pos-icon [icon]="icons.exchangeAlt"></pos-icon>
                </div>
                <div class="display-flex">Change Terminal</div>
              </a>
            </li>
            <li aria-haspopup="true" class="sub-navs sub-navs--mapped-terminal" *ngIf="linksVisibility.mappedTerminals"
              (mouseover)="toggleMappedTerminal(true)">
              <a (mouseout)="toggleMappedTerminal(false)" (click)="toggleMappedTerminal(true)"
                class="pointer display-flex">
                <div class="display-table">
                  <div class="menu-icon pe-pix-5">
                    <pos-icon [icon]="icons.cogs"></pos-icon>
                  </div>
                  <div class="display-flex">Mapped Terminals</div>
                </div>
                <ul class="main-header__block__main-menu__nav__block__sub-menu right-auto"
                  [hidden]="!mappedTerminalVisible">
                  <li *ngFor="let terminal of mappedOrderEntryTerminals">
                    <a class="pointer">
                      <div class="col-12 p-0">
                        {{terminal.terminal_name}}
                      </div>
                    </a>
                  </li>
                </ul>
              </a>

            </li>
            <li *ngIf="linksVisibility.admin">
              <a class="pointer display-flex" (click)="adminClick()"
                permissions='[{"Name": "{{permissions.manageConsole}}", "Level": "{{permissionLevel}}"}]'>
                <div class="menu-icon pe-pix-5">
                  <pos-icon [icon]="icons.adminWrench"></pos-icon>
                </div>
                <div class="display-flex">Admin</div>
              </a>
            </li>
            <li *ngIf="linksVisibility.filterProducts">
              <a class="pointer display-flex" (click)="filterProducts()">
                <div class="menu-icon pe-pix-5">
                  <pos-icon [icon]="icons.functionButtonFilter"></pos-icon>
                </div>
                <div class="display-flex">Filter Products</div>
              </a>
            </li>
            <li *ngIf="linksVisibility.time && settingParams.UseClockIns">
              <a class="pointer display-flex" (click)="timeEntryClick()">
                <div class="menu-icon pe-pix-5">
                  <pos-icon [icon]="icons.timeUserClock"></pos-icon>
                </div>
                <div class="display-flex">Time</div>
              </a>
            </li>
            <li *ngIf="linksVisibility.signIn">
              <a class="pointer display-flex" (click)="signInClick()">
                <div class="menu-icon pe-pix-5">
                  <pos-icon [icon]="icons.signInUserTag"></pos-icon>
                </div>
                <div class="display-flex">Sign In</div>
              </a>
            </li>
            <li *ngIf="linksVisibility.activeOrders" aria-haspopup="true" (mouseleave)="toggleActiveOrders(false)"
              (mouseenter)="toggleActiveOrders(true)" class="sub-navs sub-navs--active-order position-relative">
              <a [ngClass]="{'pointer': terminalType == terminalTypes.ORDER_ENTRY.Name}" class="display-flex"
                (click)="toggleActiveOrders(true)">
                <div class="menu-icon pe-pix-5">
                  <pos-icon [icon]="icons.listAlt"></pos-icon>
                </div>
                <div class="display-table">Active Orders
                  <span class="main-header__block__main-menu__nav__block__active-order">{{unservedOrdersData.length}}
                  </span>
                </div>
              </a>
              <ul class="main-header__block__main-menu__nav__block__sub-menu" *ngIf="unservedOrdersData.length"
                [ngClass]="{'display-none-imp': !activeOrdersVisible}" [hidden]="!activeOrdersVisible">
                <li *ngFor="let od of unservedOrdersData; trackBy:identify">
                  <div>
                    <a class="pointer" (click)="getActiveOrder(od)">

                      <div class="col-12">
                        <div class="col-12 text-ellipsis" *ngIf="od.AccountName">
                          <span *ngIf="od.OrderMethod?.Name == orderMethods.OrderKiosk">
                            <pos-icon [icon]="icons.tabletAlt"></pos-icon>
                          </span>
                          {{od.AccountType == accountTypes.Tab ? accountTerm : tableTerm}}: {{od.AccountName?
                          od.AccountName : ''}}
                        </div>
                      </div>
                      <div *ngIf="!od.AccountName" class="col-12 text-ellipsis">
                        <span *ngIf="od.OrderMethod?.Name == orderMethods.OrderKiosk">
                          <pos-icon [icon]="icons.tabletAlt"></pos-icon>
                        </span>
                        {{od.FirstMainProduct}}</div>
                      <div class="row mx-0">
                        <div class="col-lg-3 col-md-3 col-sm-4 col-4 p-0">
                          <span *ngIf="od.Min || od.Seconds">{{od.Min}}:{{od.Seconds |fixedLengthNumber:2}} </span>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-4 col-4 p-0">#{{od.SurrogateOrderId}}</div>
                        <div class="col-lg-3 col-md-3 col-sm-4 col-4 p-0">
                          <span [hidden]="!od.Seat">{{od.Seat}} {{subAccountTerm}}</span>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-4 col-4 p-0 float-right">{{od.SubTotal |
                          tark_currency}}</div>
                      </div>
                    </a>
                  </div>
                </li>
              </ul>

            </li>
            <li style="display: none">
              <span class="web-only"><a href="#/maketable/Maketable" target="_blank">Maketable</a></span>
              <span class="mobile-only"><a href="#/maketable/Maketable">Maketable</a></span>
            </li>
          </ul>
        </div>
      </nav>
    </div>
    <div class="col-sm-2 main-header__block__user-section " *ngIf="linksVisibility.userMenu">
      <div ngbDropdown class="dropdown main-header__block__user-section__drop-down">

        <div ngbDropdownToggle class="dropdown-toggle" aria-expanded="true" data-toggle="dropdown" id="headerMenu"
          role="button" aria-haspopup="true" aria-expanded="false">
          <span [ngClass]="{'impersonate-user': isImpersonateUser}">
            <span class="caret"></span>
            <em class="main-header__block__user-section__drop-down__user-name">{{userName}}</em>
            <pos-icon *ngIf="!badgeIcon" [icon]="icons.userNameCircle" class="pe-pix-3 ps-pix-3"></pos-icon>
            <span class="ms-pix-3 badge-icon" *ngIf="badgeIcon">

              <img src="{{imagePath}}/training-badge-icon/{{badgeIcon}}" height="24px" /></span>

          </span>

        </div>

        <div class="dropdown">
          <ul ngbDropdownMenu aria-labelledby="headerMenu" class="dropdown-menu w-max-content login-dropdown-menu">
            <a ngbDropdownItem class="pointer" (click)="reloadSettings()" role="menuitem" tabindex="-1">
              <div class="col-20 float-start pe-pix-5">
                <pos-icon [icon]="icons.reloadRedo"></pos-icon>

              </div>
              <div class="display-flex">Reload</div>

            </a>
            <a ngbDropdownItem class="pointer" (click)="subscriptionClick()" tabindex="-1"
              *ngIf="linksVisibility.mySubscriptions">
              <div class="col-20 float-start pe-pix-5">
                <pos-icon [icon]="icons.subscriptionBookmark"></pos-icon>
              </div>
              <div class="display-flex">My Subscriptions</div>
            </a>

            <a ngbDropdownItem class="pointer" (click)="workScheduleClick()" role="menuitem" tabindex="-1"
              permissions='[{"Name": "{{permissions.linkWorkSchedule}}", "Level": "{{permissionLevel}}"}]'>
              <div class="col-20 float-start pe-pix-5">
                <pos-icon [icon]="icons.calendar"></pos-icon>
              </div>
              <div class="display-flex">Work Schedule</div>
            </a>

            <a ngbDropdownItem class="pointer" (click)="linksClick()" role="menuitem" tabindex="-1"
              *ngIf="customLink && linksVisibility.customLinks">
              <div class="col-20 float-start pe-pix-5">
                <pos-icon [icon]="icons.infolinksExternalLinkAlt"></pos-icon>
              </div>
              <div class="display-flex">Links</div>
            </a>

            <a ngbDropdownItem class="pointer" (click)="turnDebugClick()" role="menuitem" tabindex="-1">
              <div class="col-20 float-start pe-pix-5">
                <pos-icon [icon]="icons.turnDebugMode"></pos-icon>
              </div>
              <div class="display-flex">Turn Debug{{!isDebug?' On' : ' Off'}}</div>
            </a>
            <span [feature]="features.SupportContact">
              <a ngbDropdownItem class="pointer" (click)="contactSupport()" role="menuitem" tabindex="-1"
                permissions='[{"Name": "{{permissions.linkContactSupport}}", "Level": "{{permissionLevel}}"}]'>
                <div class="col-20 float-start pe-pix-5">
                  <pos-icon [icon]="icons.contactSupport"></pos-icon>
                </div>
                <div class="display-flex">Contact Support</div>
              </a>
            </span>


            <a ngbDropdownItem class="pointer" (click)="turnTerminalDesignModeClick()" role="menuitem" tabindex="-1"
              *ngIf="customLink && linksVisibility.terminalDesignMode">
              <div class="col-20 float-start pe-pix-5">
                <pos-icon [icon]="icons.th"></pos-icon>
              </div>
              <div class="display-flex">Design Mode{{!terminalDesignMode?'' : ' Off'}}</div>
            </a>

            <a ngbDropdownItem class="pointer" (click)="endImpersonateClick()" role="menuitem" tabindex="-1"
              *ngIf="isImpersonateUser">
              <div class="col-20 float-start pe-pix-5">
                <pos-icon [icon]="icons.userImpersonate"></pos-icon>
              </div>
              <div class="display-flex">End Impersonation</div>
            </a>

            <a ngbDropdownItem class="pointer" (click)="logoutClick()" role="menuitem" tabindex="-1">
              <div class="col-20 float-start pe-pix-5">
                <pos-icon [icon]="icons.userSignOut"></pos-icon>
              </div>
              <div class="display-flex">Sign Out</div>
            </a>

            <a ngbDropdownItem class="user-info disabled" *ngIf="badgeIcon">
              <div class="user-profile-img">
                <img src="{{imagePath}}/training-badge-icon/{{badgeIcon}}" width="36px" alt="Badge Icon">
                <div class="ms-pix-10">
                  <h5 class="level-name">{{badgeName}}</h5>
                  <p class="level-info">Level
                    {{(userTrainingDetails.ApprovedLevels ?? 0) + '/' + userTrainingDetails.TotalLevels}}</p>
                </div>
              </div>

              <div class="pt-1 display-flex next-level-info" *ngIf="userTrainingDetails.NextBadge">
                <div> Next:</div>
                <div class="ps-pix-5 pe-pix-5">
                  <img src="{{imagePath}}/training-badge-icon/{{userTrainingDetails.NextBadgeIcon}}" height="20px"
                    alt="Next Badge Icon" />
                </div>
                <div class="ps-pix-3">{{ userTrainingDetails.NextBadge }}</div>
              </div>
            </a>
            <a ngbDropdownItem role="menuitem" [title]="versionInfo.version" class="disabled version-info">
              <div class="display-flex">v{{versionInfo.versionOnly}}</div>
            </a>
          </ul>
        </div>


      </div>
    </div>
  </div>
</header>

<ng-template #cardBasedSignIn>
  <div>
    <div class="modal-body">
      <div class="text-center">
        {{signInMessage}}
      </div>
    </div>
  </div>
</ng-template>

<ng-template #terminalWarningModal>
  <div>
    <div>
      <button class="close" (click)="closeWarningDialog()">&times;</button>
      <h4 class="modal-content__heading">Warnings</h4>
    </div>
    <div class="modal-body">
      <ul class="list-group">
        <!-- list-group -->
        <li class="list-group-item font-18 pe-0 py-0" [ngClass]="{'pointer': warning.IsActionable}"
          *ngFor="let warning of warnings">
          <div class="d-flex align-items-center">
            <span class="text-danger font-22 float-start inline pe-pix-5" *ngIf="!warning.IsTransient">
              <pos-icon [icon]="icons.exclamationCircle"></pos-icon>
            </span>
            <p (click)="warningClicked(warning)" class="flex-grow">
              {{warning.Message}}
            </p>
            <div class="float-end" (click)="warningClicked(warning)" *ngIf="warning.IsActionable">
              <button class="btn btn-success font-16 m-2 normal white pos-button">
                <pos-icon class="white" [icon]="icons.check" title="Click to act"></pos-icon> OK
              </button>
            </div>
          </div>
        </li>
        <li class="text-center" *ngIf="warnings.length == 0">No Warnings</li>
      </ul>
    </div>

  </div>
</ng-template>
<pos-inbox-message-popover *ngIf="isPopoutRequired"></pos-inbox-message-popover>
<pos-log-viewer *ngIf="isDebug" [isDebug]="isDebug"></pos-log-viewer>
<pos-clock-in #clockInComponent (clockInCompleted)="clockInCompletedSubmit($event)"
  (hideComponent)="hideClockInComponent()" [hidden]="true"></pos-clock-in>
