<pos-edit-wrapper heading="Edit Widget: {{widgetConfiguration?.Name}}" (close)="onCancel()">
    <form #formWidgetConfig="ngForm" (ngSubmit)="saveWidget(formWidgetConfig.valid)" autocomplete="off">
        <div class="col-12 container-fluid">
            <ul class="nav nav-tabs inner-tab pos-nav-tabs">
                <li [ngClass]="{'active': selectedTab == tabList.Details}">
                    <a (click)="selectedTab = tabList.Details" [ngClass]="{'required':widgetDetailsGroup.invalid}">
                        <span>
                            <pos-icon class="theme-separator pe-pix-5" [icon]="icons.infoCircle"></pos-icon>
                        </span>
                        <span>Details</span>
                    </a>
                </li>
                <li [ngClass]="{'active': selectedTab == tabList.Header}" *ngIf="widgetType.Name != menuWidgetTypes.Banner.Name && widgetType.Name != menuWidgetTypes.Text.Name && widgetType.Name != menuWidgetTypes.Image.Name
                    && widgetType.Name != menuWidgetTypes.Group.Name && widgetType.Name != menuWidgetTypes.Video.Name">
                    <a (click)="selectedTab = tabList.Header" [ngClass]="{'required':widgetHeaderGroup.invalid}">
                        <span>
                            <pos-icon class="theme-separator pe-pix-5" [icon]="icons.heading"></pos-icon>
                        </span>
                        <span>Header</span>
                    </a>
                </li>
                <li [ngClass]="{'active': selectedTab == tabList.Config}"
                    *ngIf="widgetType.Name != menuWidgetTypes.ComboItemList.Name && widgetType.Name != menuWidgetTypes.Group.Name">
                    <a (click)="selectedTab = tabList.Config" [ngClass]="{'required':widgetConfigGroup.invalid}">
                        <span>
                            <pos-icon class="theme-separator pe-pix-5" [icon]="icons.cog"></pos-icon>
                        </span>
                        <span>Config</span>
                    </a>
                </li>
                <li [ngClass]="{'active': selectedTab == tabList.Style}">
                    <a (click)="selectedTab = tabList.Style" [ngClass]="{'required':widgetStyleGroup.invalid}">
                        <span>
                            <pos-icon class="theme-separator pe-pix-5" [icon]="icons.colorsPaletter"></pos-icon>
                        </span>
                        <span>Style</span>
                    </a>
                </li>
                <li [ngClass]="{'active': selectedTab == tabList.Items}" *ngIf="widgetType.Name == menuWidgetTypes.List.Name
                    || widgetType.Name == menuWidgetTypes.ImageList.Name
                    || widgetType.Name == menuWidgetTypes.TileList.Name
                    || widgetType.Name == menuWidgetTypes.Item.Name
                    || widgetType.Name == menuWidgetTypes.ComboItemList.Name">
                    <a (click)="selectedTab = tabList.Items; loadSalesItems();"
                        [ngClass]="{'required':widgetItemsGroup.invalid}">
                        <span>
                            <pos-icon class="theme-separator pe-pix-5"
                                [icon]="icons.salesProductsFileInvoiceDollar"></pos-icon>
                        </span>
                        <span>Items</span>
                    </a>
                </li>
            </ul>
            <div class="overflow-auto-tabs pos-tab-body" [ngStyle]="{'height': containerHeight}">
                <div [hidden]="selectedTab != tabList.Details" ngModelGroup="widgetDetailsGroup"
                    #widgetDetailsGroup="ngModelGroup" class="col-12 pt-pix-5">
                    <pos-row colXs="12" class="p-0">
                        <pos-column>
                            <pos-form-field label="Name" [validationMessages]="{required: 'Please enter name.'}">
                                <input posInput name="widgetName" type="text" class="form-control"
                                    [(ngModel)]="widgetConfiguration.Name" [required]="true" />
                            </pos-form-field>
                        </pos-column>

                        <pos-column>
                            <pos-form-field label="Widget Type">
                                <input posInput disabled name="widgetType" type="text" class="form-control"
                                    [(ngModel)]="widgetType.Value" [required]="true" />
                            </pos-form-field>
                        </pos-column>
                    </pos-row>
                    <pos-row colSm="6" colXs="12" class="p-0">
                        <pos-column>
                            <pos-form-field label="X Coordinate" inputStyle="col-6 col-120"
                                [validationMessages]="{required: 'Please enter X Coordinate.'}">
                                <input posInput name="x" type="number" class="form-control"
                                    [(ngModel)]="widgetDetails.x" [required]="true" />
                            </pos-form-field>
                        </pos-column>

                        <pos-column>
                            <pos-form-field label="Y Coordinate" inputStyle="col-6 col-120"
                                [validationMessages]="{required: 'Please enter Y Coordinate.'}">
                                <input posInput name="y" type="number" class="form-control"
                                    [(ngModel)]="widgetDetails.y" [required]="true" />
                            </pos-form-field>
                        </pos-column>

                        <pos-column>
                            <pos-form-field label="Rows" inputStyle="col-6 col-120"
                                [validationMessages]="{required: 'Please enter Rows.'}">
                                <input posInput name="rows" type="number" class="form-control"
                                    [(ngModel)]="widgetDetails.rows" [required]="true" />
                            </pos-form-field>
                        </pos-column>

                        <pos-column>
                            <pos-form-field label="Cols" inputStyle="col-6 col-120"
                                [validationMessages]="{required: 'Please enter Cols.'}">
                                <input posInput name="cols" type="number" class="form-control"
                                    [(ngModel)]="widgetDetails.cols" [required]="true" />
                            </pos-form-field>
                        </pos-column>

                        <pos-column>
                            <pos-form-field label="Layer Index" inputStyle="col-6 col-120">
                                <input posInput name="layerIndex" type="number" class="form-control"
                                    [(ngModel)]="widgetDetails.layerIndex"
                                    [disabled]="widgetType.Name == menuWidgetTypes.Group.Name" />
                            </pos-form-field>
                        </pos-column>
                    </pos-row>
                </div>
                <div [hidden]="selectedTab != tabList.Config || widgetType.Name == menuWidgetTypes.Banner.Name"
                    ngModelGroup="widgetConfigGroup" #widgetConfigGroup="ngModelGroup" class="col-12 pt-pix-5">
                    <div *ngIf="widgetType.Name == menuWidgetTypes.ImageList.Name" class="col-12 p-0">
                        <pos-row>
                            <pos-column>
                                <pos-form-field>
                                    <p-checkbox posInput name="isShowImageText" #isShowImageText="ngModel"
                                        [(ngModel)]="widgetConfig.IsShowImageText" binary="true"
                                        label="Show image text">
                                    </p-checkbox>
                                </pos-form-field>
                            </pos-column>
                        </pos-row>
                    </div>

                    <div *ngIf="widgetType.Name == menuWidgetTypes.Item.Name || widgetType.Name == menuWidgetTypes.List.Name"
                        class="col-12 p-0">
                        <pos-row colXs="12" class="p-0">
                            <pos-column>
                                <pos-form-field>
                                    <div class="display-flex">
                                        <p-checkbox posInput name="isShowProductPrice"
                                            [(ngModel)]="widgetConfig.IsShowProductPrice" binary="true"
                                            label="Show Product Price"
                                            (onChange)="widgetStyle.PriceStyle = null"></p-checkbox>
                                        <field-info [info]="fieldInfo.ShowProductPrice.info"
                                            [image]="fieldInfo.ShowProductPrice.image">
                                        </field-info>
                                    </div>
                                </pos-form-field>
                            </pos-column>
                            <pos-column>
                                <pos-form-field>
                                    <p-checkbox posInput name="showPriceSize"
                                        [(ngModel)]="widgetConfig.IsShowPriceSize" binary="true"
                                        label="Show Price Size"></p-checkbox>
                                </pos-form-field>
                            </pos-column>
                            <pos-column>
                                <pos-form-field label="Only Show Price of Size">
                                    <input posInput type="text" class="form-control"
                                                name="showPriceOfSize"
                                                [(ngModel)]="widgetConfig.ShowPriceOfSize" />
                                </pos-form-field>
                            </pos-column>
                            <pos-column xs="12" class="mx-pix-5"
                                *ngIf="widgetConfig.IsShowProductPrice && widgetType.Name == menuWidgetTypes.List.Name">
                                <pos-form-field>
                                    <p-checkbox posInput name="showPriceAsTable"
                                        [(ngModel)]="widgetStyle.PriceTableConfig.ShowPriceAsTable" binary="true"
                                        label="Show Price As Table"></p-checkbox>
                                </pos-form-field>
                                <pos-row class="mx-0" *ngIf="widgetStyle.PriceTableConfig?.ShowPriceAsTable" colXs="6">
                                    <pos-column>
                                        <pos-form-field label="Table Width"
                                            [validationMessages]="{range: 'Width should be between 0 to 100.'}">
                                            <input posInput type="number" class="form-control col-120"
                                                name="priceTableWidth" [range]="[0,100]"
                                                [(ngModel)]="widgetStyle.PriceTableConfig.Width" />
                                        </pos-form-field>
                                    </pos-column>

                                    <pos-column>
                                        <pos-form-field label="Cell Padding">
                                            <input posInput type="text" class="form-control" name="priceCellPadding"
                                                [(ngModel)]="widgetStyle.PriceTableConfig.CellPadding" />
                                        </pos-form-field>
                                    </pos-column>

                                    <pos-column xs="12">
                                        <pos-form-field label="Sizes">
                                            <ng-select posInput placeholder="Add Product Size(s)" [items]="salesSizes"
                                                bindLabel="Name" bindValue="Id" [addTag]="true"
                                                name="PriceTableConfigSalesSizes"
                                                [(ngModel)]="widgetStyle.PriceTableConfig.SalesSizes" [multiple]="true"
                                                [hideSelected]="true">
                                            </ng-select>
                                        </pos-form-field>
                                    </pos-column>
                                </pos-row>
                            </pos-column>
                            <pos-column xs="12" class="mx-pix-5" *ngIf="widgetConfig.IsShowProductPrice">
                                <div>
                                    <h3 class="setting-title">Price Style</h3>
                                </div>
                                <pos-font-style-config [(fontStyleConfig)]="widgetStyle.PriceStyle"
                                    prefix="{{widgetType.Name}}_priceStyle"></pos-font-style-config>
                            </pos-column>

                            <pos-column>
                                <pos-form-field>
                                    <p-checkbox posInput name="isShowProductImage"
                                        [(ngModel)]="widgetConfig.IsShowProductImage" binary="true"
                                        label="Show Product Image"></p-checkbox>
                                </pos-form-field>
                            </pos-column>

                            <pos-column>
                                <pos-form-field>
                                    <div class="display-flex">
                                        <p-checkbox posInput name="isShowItemDescription"
                                            [(ngModel)]="widgetConfig.IsShowItemDescription" binary="true"
                                            label="Show Item Description"
                                            (onChange)="widgetStyle.DescriptionStyle = null"></p-checkbox>
                                        <field-info [info]="fieldInfo.ShowItemDescription.info"
                                            [image]="fieldInfo.ShowItemDescription.image">
                                        </field-info>
                                    </div>
                                </pos-form-field>
                            </pos-column>

                            <pos-column xs="12" class="mx-pix-5" *ngIf="widgetConfig.IsShowItemDescription">
                                <div>
                                    <h3 class="setting-title">Description Style</h3>
                                </div>
                                <pos-font-style-config [(fontStyleConfig)]="widgetStyle.DescriptionStyle"
                                    prefix="{{widgetType.Name}}_descriptionStyle"></pos-font-style-config>
                            </pos-column>

                            <pos-column>
                                <pos-form-field>
                                    <p-checkbox posInput name="isUseMakeTableColor"
                                        [(ngModel)]="widgetConfig.IsUseMakeTableColor" binary="true"
                                        label="Use Make Table Color"></p-checkbox>
                                </pos-form-field>
                            </pos-column>

                            <pos-column>
                                <pos-form-field>
                                    <div class="display-flex">
                                        <p-checkbox posInput name="hideTags" [(ngModel)]="widgetConfig.HideTags"
                                            binary="true" label="Hide Tags"></p-checkbox>
                                        <field-info [info]="fieldInfo.HideTags.info" [image]="fieldInfo.HideTags.image">
                                        </field-info>
                                    </div>
                                </pos-form-field>
                            </pos-column>
                            <pos-column xs="12" class="mx-pix-5" *ngIf="!widgetConfig.HideTags">
                                <pos-form-field label="Vertical Align"
                                    inputStyle="col-6 col-md-3">
                                    <ng-select posInput name="Vertical" bindLabel="Name" bindValue="Value"
                                        [items]="verticalAlign" [(ngModel)]="widgetStyle.TagConfig.verticalAlign"
                                        [clearOnBackspace]="false" appendTo="body"></ng-select>
                                </pos-form-field>
                            </pos-column>
                        </pos-row>
                    </div>

                    <div *ngIf="widgetType.Name == menuWidgetTypes.Item.Name">
                        <div class="col-12 p-0">
                            <pos-row>
                                <pos-column>
                                    <pos-form-field label="Item Image" inputStyle="col-12">
                                        <pos-row colXs="6">
                                            <pos-column>
                                                <input posInput type="file" id="itemWidgetConfigImage"
                                                    class="form-control" accept=".png,.jpg,.ico,.bmp"
                                                    (change)="widgetConfig.Image = handleFileInput($event.target.files, 'itemWidgetConfigImage', widgetConfig.Image)" />
                                            </pos-column>
                                            <pos-column [hidden]="!widgetConfig.Image">
                                                <pos-row>
                                                    <pos-column xs="2" class="col-50 pe-0">
                                                        <img alt="" id="image_itemWidgetConfigImage"
                                                            src="{{menuWidgetPath}}/{{widgetConfig.Image}}"
                                                            height="50px" />
                                                    </pos-column>
                                                    <pos-column sm="6" xs="12" class="mt-pix-n5">
                                                        <a (click)="removeIcon('itemWidgetConfigImage', widgetConfig.Image); widgetConfig.Image = ''"
                                                            class="black">
                                                            <pos-icon [icon]="icons.times"
                                                                class="pointer remove-icon-badge"></pos-icon>
                                                        </a>
                                                    </pos-column>
                                                </pos-row>
                                            </pos-column>
                                        </pos-row>
                                    </pos-form-field>
                                </pos-column>
                                <pos-column>
                                    <pos-form-field label="Display Text">
                                        <input posInput type="text" name="displayText" class="form-control"
                                            [(ngModel)]="widgetConfig.DisplayText" />
                                    </pos-form-field>
                                </pos-column>

                                <pos-column>
                                    <pos-form-field label="Price">
                                        <input posInput name="price" type="number" class="form-control col-120"
                                            [(ngModel)]="widgetConfig.Price" posDecimal />
                                    </pos-form-field>
                                </pos-column>
                            </pos-row>
                        </div>
                    </div>
                    <div *ngIf="widgetType.Name == menuWidgetTypes.List.Name">
                        <div class="col-12 p-0">
                            <pos-row colXs="12" class="p-0">
                                <pos-column>
                                    <pos-form-field label="No of Columns" [fieldInfoObject]="fieldInfo.NoOfColumns">
                                        <input posInput type="number" name="noOfColumns" class="form-control col-120"
                                            [(ngModel)]="widgetConfig.NoOfColumns" />
                                    </pos-form-field>
                                </pos-column>
                                <pos-column>
                                    <pos-form-field>
                                        <p-checkbox posInput name="showIndentedText"
                                            [(ngModel)]="widgetConfig.ShowIndentedText" binary="true"
                                            label="Show Indented Text"></p-checkbox>
                                    </pos-form-field>
                                </pos-column>
                                <pos-column>
                                    <pos-form-field label="Separator" inputStyle="col-12">
                                        <div class="row mx-0">
                                            <div class="col-4 p-0 col-150">
                                                <div class="btn-group">
                                                    <label class="btn btn-radio"
                                                        [ngClass]="listSeparatorType == 'Number' ? 'active btn-primary normal' : 'btn-secondary'">
                                                        <input [(ngModel)]="listSeparatorType" type="radio"
                                                            value="Number" name="Number" (change)="onSeparatorChange()">
                                                        After
                                                    </label>
                                                    <label class="btn btn-radio"
                                                        [ngClass]="listSeparatorType == 'Range' ? 'active btn-primary normal' : 'btn-secondary'">
                                                        <input posInput [(ngModel)]="listSeparatorType" type="radio"
                                                            value="Range" name="Range" (change)="onSeparatorChange()">
                                                        At
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="col-8 p-0">
                                                <pos-row *ngIf="listSeparatorType == 'Number'">
                                                    <pos-column xs="6" class="col-120">
                                                        <input posInput type="number" name="separator"
                                                            class="form-control" [(ngModel)]="listSeparators" />
                                                    </pos-column>
                                                    <pos-column xs="6">
                                                        <label class="pt-pix-7">Items</label>
                                                    </pos-column>
                                                </pos-row>

                                                <div *ngIf="listSeparatorType == 'Range'">
                                                    <ng-select posInput class="widget-tag" [items]="[]"
                                                        placeholder="{{listSeparators.length ? '' : 'Add Separator(s) '}}"
                                                        [addTag]="true" name="separator" [(ngModel)]='listSeparators'
                                                        [multiple]="true" [hideSelected]="true" [isOpen]="false">
                                                    </ng-select>
                                                </div>
                                            </div>
                                        </div>
                                    </pos-form-field>
                                </pos-column>
                                <pos-column>
                                    <pos-row colXs="6" class="p-0">
                                        <pos-column>
                                            <pos-form-field label="Separator Border Style" inputStyle="col-6 col-240">
                                                <ng-select posInput name="separator_borderStyle" bindLabel="Key"
                                                    bindValue="Value"
                                                    [(ngModel)]="widgetStyle.SeparatorStyle.BorderStyle"
                                                    [items]="BorderStyleList" [clearOnBackspace]="false"
                                                    appendTo="body"></ng-select>
                                            </pos-form-field>
                                        </pos-column>

                                        <pos-column>
                                            <pos-form-field label="Separator Background Color"
                                                inputStyle="col-6 col-240">
                                                <pos-color-picker posInput name="separator_background"
                                                    [(ngModel)]="widgetStyle.SeparatorStyle.BackgroundColor"></pos-color-picker>
                                            </pos-form-field>
                                        </pos-column>

                                        <pos-column>
                                            <pos-form-field label="Separator Margin" inputStyle="col-6 col-240">
                                                <input posInput type="text" name="separator_margin" class="form-control"
                                                    [(ngModel)]="widgetStyle.SeparatorStyle.Margin" />
                                            </pos-form-field>
                                        </pos-column>

                                        <pos-column>
                                            <pos-form-field label="Separator Padding" inputStyle="col-6 col-240">
                                                <input posInput type="text" name="separator_padding"
                                                    class="form-control"
                                                    [(ngModel)]="widgetStyle.SeparatorStyle.Padding" />
                                            </pos-form-field>
                                        </pos-column>

                                        <pos-column>
                                            <pos-form-field label="Separate Direction" inputStyle="col-6 col-240">
                                                <ng-select posInput name="separator_direction" bindLabel="Key"
                                                    bindValue="Value" [(ngModel)]="widgetStyle.SeparatorStyle.Direction"
                                                    [items]="SeparatorDirectionList" [clearOnBackspace]="false"
                                                    appendTo="body"></ng-select>
                                            </pos-form-field>
                                        </pos-column>
                                    </pos-row>
                                </pos-column>
                            </pos-row>
                        </div>
                    </div>
                    <div *ngIf="widgetType.Name == menuWidgetTypes.Text.Name">
                        <div class="col-12 p-0">
                            <pos-row>
                                <pos-column>
                                    <pos-form-field label="Text" inputStyle="col-12">
                                        <textarea posInput name="text" class="form-control"
                                            [(ngModel)]="widgetConfig.Text" rows="8"></textarea>
                                    </pos-form-field>
                                </pos-column>

                                <pos-column>
                                    <pos-form-field label="Orientation">
                                        <div class="btn-group">
                                            <label class="btn btn-radio"
                                                [ngClass]="widgetConfig.Orientation == 'Horizontal' ? 'active btn-primary normal' : 'btn-secondary'">
                                                <input [(ngModel)]="widgetConfig.Orientation" type="radio"
                                                    value="Horizontal" name="Horizontal"> Horizontal
                                            </label>
                                            <label class="btn btn-radio"
                                                [ngClass]="widgetConfig.Orientation == 'Vertical' ? 'active btn-primary normal' : 'btn-secondary'">
                                                <input posInput [(ngModel)]="widgetConfig.Orientation" type="radio"
                                                    value="Vertical" name="Vertical"> Vertical
                                            </label>
                                        </div>
                                    </pos-form-field>
                                </pos-column>
                            </pos-row>
                        </div>
                    </div>
                    <div *ngIf="widgetType.Name == menuWidgetTypes.Image.Name">
                        <div class="col-12 p-0">
                            <pos-row>
                                <pos-column>
                                    <pos-form-field label="Image" inputStyle="col-12">
                                        <pos-row colXs="6">
                                            <pos-column>
                                                <input posInput type="file" id="imageWidgetConfig" class="form-control"
                                                    accept=".png,.jpg,.ico,.bmp, .gif"
                                                    (change)="widgetConfig.Image = handleFileInput($event.target.files, 'imageWidgetConfig', widgetConfig.Image)" />
                                            </pos-column>
                                            <pos-column [hidden]="!widgetConfig.Image">
                                                <pos-row class="mx-0">
                                                    <pos-column xs="2" class="col-50 pe-0">
                                                        <img alt="" id="image_imageWidgetConfig"
                                                            src="{{menuWidgetPath}}/{{widgetConfig.Image}}"
                                                            height="50px" />
                                                    </pos-column>
                                                    <pos-column sm="6" xs="12" class="mt-pix-n5">
                                                        <a (click)="removeIcon('imageWidgetConfig', widgetConfig.Image); widgetConfig.Image = ''"
                                                            class="black">
                                                            <pos-icon [icon]="icons.times"
                                                                class="pointer remove-icon-badge"></pos-icon>
                                                        </a>
                                                    </pos-column>
                                                </pos-row>
                                            </pos-column>
                                        </pos-row>
                                    </pos-form-field>
                                </pos-column>
                            </pos-row>
                            <pos-row>
                                <pos-column class="display-flex pt-pix-10">
                                    <h3 class="setting-title pti-0">Image Shadow</h3>
                                    <field-info [info]="fieldInfo.imageShadow.info"
                                        [image]="fieldInfo.imageShadow.image">
                                    </field-info>
                                </pos-column>
                            </pos-row>
                            <pos-row colXs="6" class="pos-title-content">
                                <pos-column>
                                    <pos-form-field label="X Offset">
                                        <input type="text" name="Xoffset" [(ngModel)]="widgetStyle.OffsetX"
                                            class="form-control" />
                                    </pos-form-field>
                                </pos-column>

                                <pos-column>
                                    <pos-form-field label="Y Offset">
                                        <input type="text" name="Yoffset" [(ngModel)]="widgetStyle.OffsetY"
                                            class="form-control" />
                                    </pos-form-field>
                                </pos-column>

                                <pos-column>
                                    <pos-form-field label="Blur Radius">
                                        <input type="text" name="BlurRadius" [(ngModel)]="widgetStyle.BlurRadius"
                                            class="form-control" />
                                    </pos-form-field>
                                </pos-column>

                                <pos-column>
                                    <pos-form-field label="Shadow Color">
                                        <pos-color-picker posInput name="ShadowColor"
                                            [(ngModel)]="widgetStyle.ShadowColor"></pos-color-picker>
                                    </pos-form-field>
                                </pos-column>
                            </pos-row>
                        </div>
                    </div>
                    <div *ngIf="widgetType.Name == menuWidgetTypes.Video.Name">
                        <pos-row>
                            <pos-column>
                                <pos-form-field label="Video" inputStyle="col-12">
                                    <pos-row colXs="6">
                                        <pos-column>
                                            <input posInput type="file" id="videoWidgetConfig" class="form-control"
                                                accept=".mp4, .ogg, .webm, .mpg, .m4v, .avi"
                                                (change)="widgetConfig.Video = handleFileInput($event.target.files, 'videoWidgetConfig', widgetConfig.Video)" />
                                        </pos-column>
                                    </pos-row>
                                </pos-form-field>
                            </pos-column>
                        </pos-row>
                    </div>
                    <div *ngIf="(widgetType.Name == menuWidgetTypes.TileList.Name) ||
                                (widgetType.Name == this.menuWidgetTypes.List.Name)">

                        <div class="col-12 p-0">
                            <pos-row colXs="12" class="p-0 mx-0">
                                <pos-column class="section-border p-0">
                                    <div class="display-flex pt-pix-10">
                                        <h3 class="setting-title pti-0">Title</h3>
                                        <field-info [info]="fieldInfo.Title.info" [image]="fieldInfo.Title.image">
                                        </field-info>
                                    </div>
                                </pos-column>
                                <pos-column class="pos-title-content">
                                    <pos-form-field label="Title Text">
                                        <input posInput name="widgetConfigTitleText" type="text" class="form-control"
                                            [(ngModel)]="widgetConfig.TitleText" />
                                    </pos-form-field>
                                </pos-column>
                                <pos-column class="p-0">
                                    <pos-font-style-config [(fontStyleConfig)]="widgetStyle.TitleFontStyle"
                                        prefix="widgetStyle_titleFontStyle"></pos-font-style-config>
                                </pos-column>

                                <pos-column class="section-border p-0">
                                    <div class="display-flex pt-pix-10">
                                        <h3 class="setting-title pti-0">Footer</h3>
                                        <field-info [info]="fieldInfo.Footer.info" [image]="fieldInfo.Footer.image">
                                        </field-info>
                                    </div>
                                </pos-column>
                                <pos-column class="pos-title-content">
                                    <pos-form-field label="Footer Text">
                                        <input posInput name="widgetConfigFooterText" type="text" class="form-control"
                                            [(ngModel)]="widgetConfig.FooterText" />
                                    </pos-form-field>
                                </pos-column>
                                <pos-column class="p-0">
                                    <pos-font-style-config [(fontStyleConfig)]="widgetStyle.FooterFontStyle"
                                        prefix="widgetStyle_footerFontStyle"></pos-font-style-config>
                                </pos-column>
                            </pos-row>
                        </div>
                    </div>
                    <div class="col-12 p-0 section-border"
                        *ngIf="widgetType.Name == menuWidgetTypes.ImageList.Name || widgetType.Name == menuWidgetTypes.Item.Name || widgetType.Name == menuWidgetTypes.List.Name">
                        <div class="display-flex pt-pix-10">
                            <h3 class="setting-title pti-0">Out Of Stock Behavior</h3>
                            <field-info [info]="fieldInfo.OutOfStockBehavior.info"
                                [image]="fieldInfo.OutOfStockBehavior.image">
                            </field-info>
                        </div>
                        <pos-out-of-stock-behavior [(outOfStockBehavior)]="widgetConfig.OutOfStockBehavior"
                            [prefix]="widgetType.Name"></pos-out-of-stock-behavior>
                    </div>
                </div>

                <div [hidden]="selectedTab != tabList.Style" ngModelGroup="widgetStyleGroup"
                    #widgetStyleGroup="ngModelGroup" class="col-12 pt-pix-5">
                    <pos-row *ngIf="widgetType.Name != menuWidgetTypes.Group.Name">
                        <pos-column>
                            <pos-form-field label="Widget Background Color">
                                <pos-color-picker posInput name="widgetBackGroundColor"
                                    [(ngModel)]="widgetStyle.WidgetBackgroundColor"></pos-color-picker>
                            </pos-form-field>
                        </pos-column>

                        <pos-column>
                            <pos-form-field label="Horizontal" [fieldInfoObject]="fieldInfo.JustifyContent"
                                inputStyle="col-6 col-md-3">
                                <ng-select posInput name="Horizontal" bindLabel="Name" bindValue="Value"
                                    [items]="justifyContent" [(ngModel)]="widgetStyle.Horizontal"
                                    [clearOnBackspace]="false" appendTo="body"></ng-select>
                            </pos-form-field>
                        </pos-column>
                        <pos-column>
                            <pos-form-field label="Vertical" [fieldInfoObject]="fieldInfo.AlignItems"
                                inputStyle="col-6 col-md-3">
                                <ng-select posInput name="Vertical" bindLabel="Name" bindValue="Value"
                                    [items]="alignItemsVertical" [(ngModel)]="widgetStyle.Vertical"
                                    [clearOnBackspace]="false" appendTo="body"></ng-select>
                            </pos-form-field>
                        </pos-column>
                    </pos-row>
                    <div
                        *ngIf="widgetType.Name == menuWidgetTypes.Image.Name || widgetType.Name == menuWidgetTypes.ImageList.Name || widgetType.Name == menuWidgetTypes.Video.Name">
                        <pos-row colSm="6" colXs="12" class="p-0">

                            <pos-column>
                                <pos-form-field label="Image Height">
                                    <input posInput type="number" class="form-control col-120" name="image_height"
                                        [(ngModel)]="widgetStyle.Height" />
                                </pos-form-field>
                            </pos-column>

                            <pos-column>
                                <pos-form-field label="Image Width">
                                    <input posInput type="number" class="form-control col-120" name="image_width"
                                        [(ngModel)]="widgetStyle.Width" />
                                </pos-form-field>
                            </pos-column>

                            <pos-column xs="6" *ngIf="widgetType.Name == menuWidgetTypes.ImageList.Name">
                                <pos-form-field label="Image Text Position">
                                    <ng-select posInput name="imageTextPosition" [items]="imageTextPositions"
                                        [(ngModel)]="widgetStyle.ImageTextPosition" [clearOnBackspace]="false"
                                        appendTo="body"></ng-select>
                                </pos-form-field>
                            </pos-column>
                        </pos-row>
                    </div>
                    <div class="col-12 p-0" *ngIf="widgetType.Name == menuWidgetTypes.List.Name">
                        <pos-row>
                            <pos-column>
                                <pos-form-field label="Display List Style"
                                    [fieldInfoObject]="fieldInfo.DisplayListStyle" inputStyle="col-6 col-md-3">
                                    <ng-select posInput name="displayListStyle" bindLabel="name" bindValue="value"
                                        [items]="displayListStyles" [(ngModel)]="widgetStyle.DisplayListStyle"
                                        [clearOnBackspace]="false" appendTo="body"></ng-select>
                                </pos-form-field>
                            </pos-column>

                            <pos-column>
                                <pos-form-field label="Group List Behavior" inputStyle="col-6 col-md-3">
                                    <ng-select posInput name="groupListBehavior" bindLabel="Name" bindValue="Value"
                                        [items]="groupListBehaviors" [(ngModel)]="widgetStyle.GroupListBehavior"
                                        [clearOnBackspace]="false" appendTo="body"></ng-select>
                                </pos-form-field>
                            </pos-column>

                            <pos-column>
                                <pos-form-field label="Width" inputStyle="col-6 col-md-3">
                                    <input posInput name="list_width" type="text" class="form-control"
                                        [(ngModel)]="widgetStyle.Width" />
                                </pos-form-field>
                            </pos-column>
                        </pos-row>

                        <div class="col-12 p-0 section-border">
                            <div class="display-flex pt-pix-10">
                                <h3 class="setting-title pti-0">Grouped Item Style</h3>
                                <field-info [info]="fieldInfo.GroupedItemStyle.info"
                                    [image]="fieldInfo.GroupedItemStyle.image">
                                </field-info>
                            </div>
                            <pos-font-style-config [(fontStyleConfig)]="widgetStyle.GroupedItemStyle"
                                prefix="{{widgetType.Name}}_groupItemStyle"></pos-font-style-config>
                        </div>
                    </div>
                    <div class="col-12 p-0" *ngIf="widgetType.Name == menuWidgetTypes.TileList.Name">
                        <pos-row colXs="6" class="p-0">
                            <pos-column>
                                <pos-form-field label="Tile Height">
                                    <input posInput type="number" name="tileHeight" class="form-control col-120"
                                        [(ngModel)]="widgetStyle.TileHeight" />
                                </pos-form-field>
                            </pos-column>

                            <pos-column>
                                <pos-form-field label="Tile Per Row">
                                    <input posInput type="number" name="TilesPerRow" class="form-control col-120"
                                        [(ngModel)]="widgetStyle.TilesPerRow" />
                                </pos-form-field>
                            </pos-column>

                            <pos-column>
                                <pos-form-field label="Tile Background Color">
                                    <pos-color-picker posInput name="TileBackgroundColor"
                                        [(ngModel)]="widgetStyle.TileBackgroundColor"></pos-color-picker>
                                </pos-form-field>
                            </pos-column>
                        </pos-row>
                        <div class="col-12 p-0 section-border">
                            <div>
                                <h3 class="setting-title">Tile Border</h3>
                            </div>
                            <pos-border-style [(borderStyle)]="widgetStyle.TileBorder"
                                prefix="tileBorder"></pos-border-style>
                        </div>
                        <div class="col-12 p-0 section-border">
                            <div>
                                <h3 class="setting-title">Tile Title</h3>
                            </div>
                            <pos-font-style-config [(fontStyleConfig)]="widgetStyle.Title"
                                prefix="tileStyle_title"></pos-font-style-config>
                        </div>
                        <div class="col-12 p-0 section-border">
                            <div>
                                <h3 class="setting-title">Description</h3>
                            </div>
                            <pos-font-style-config [(fontStyleConfig)]="widgetStyle.Description"
                                prefix="tileStyle_description"></pos-font-style-config>
                        </div>

                        <div class="col-12 p-0 section-border">
                            <div>
                                <h3 class="setting-title">Price</h3>
                            </div>
                            <pos-font-style-config [(fontStyleConfig)]="widgetStyle.Price"
                                prefix="tileStyle_price"></pos-font-style-config>
                        </div>
                    </div>
                    <div class="col-12 p-0 section-border"
                        *ngIf="widgetType.Name == menuWidgetTypes.ImageList.Name || widgetType.Name == menuWidgetTypes.List.Name">
                        <div class="display-flex pt-pix-10">
                            <h3 class="setting-title pti-0">List Item</h3>
                            <field-info [info]="fieldInfo.ListItemStyle.info" [image]="fieldInfo.ListItemStyle.image"
                                [styleClass]="'pt-pix-10'">
                            </field-info>
                        </div>
                        <pos-general-style-config [(generalStyle)]="widgetStyle.ListItemStyle"
                            prefix="{{widgetType.Name}}_listItemStyle"></pos-general-style-config>
                    </div>
                    <div class="col-12 p-0" *ngIf="widgetType.Name == menuWidgetTypes.ComboItemList.Name">
                        <pos-row colXs="6" class="p-0">
                            <pos-column>
                                <pos-form-field label="Combo Item Width">
                                    <input posInput type="text" class="form-control" name="comboItemWidth"
                                        [(ngModel)]="widgetStyle.ItemWidth" />
                                </pos-form-field>
                            </pos-column>

                            <pos-column>
                                <pos-form-field label="Combo Item Height">
                                    <input posInput type="text" class="form-control" name="comboItemHeight"
                                        [(ngModel)]="widgetStyle.ItemHeight" />
                                </pos-form-field>
                            </pos-column>
                        </pos-row>
                    </div>
                    <div class="col-12 p-0 section-border"
                        *ngIf="widgetType.Name == menuWidgetTypes.ComboItemList.Name">
                        <div>
                            <h3 class="setting-title">Description Style</h3>
                        </div>
                        <pos-font-style-config [(fontStyleConfig)]="widgetStyle.DescriptionStyle"
                            prefix="{{widgetType.Name}}_descriptionStyle"></pos-font-style-config>

                        <pos-row>
                            <pos-column>
                                <pos-form-field label="Background Color">
                                    <pos-color-picker posInput name="comboListDescriptionBackgroundColor"
                                        [(ngModel)]="widgetStyle.DescriptionStyle.backgroundColor"></pos-color-picker>
                                </pos-form-field>
                            </pos-column>
                        </pos-row>
                    </div>

                    <div class="col-12 p-0"
                        [ngClass]="{'section-border': widgetType.Name != menuWidgetTypes.Group.Name}">
                        <div class="display-flex pt-pix-10">
                            <h3 class="setting-title pti-0">Border Style</h3>
                            <field-info [info]="fieldInfo.BorderStyle.info" [image]="fieldInfo.BorderStyle.image"
                                [styleClass]="'pt-pix-10'">
                            </field-info>
                        </div>
                        <pos-border-style [(borderStyle)]="widgetStyle.BorderStyle"
                            prefix="widgetStyle_borderStyle"></pos-border-style>
                    </div>

                    <div class="col-12 p-0 section-border" *ngIf="widgetType.Name == menuWidgetTypes.List.Name
                            || widgetType.Name == menuWidgetTypes.ImageList.Name
                            || widgetType.Name == menuWidgetTypes.ComboItemList.Name
                            || widgetType.Name == menuWidgetTypes.Item.Name
                            || widgetType.Name == menuWidgetTypes.Text.Name">
                        <div class="display-flex pt-pix-10">
                            <h3 class="setting-title pti-0">Item Font Style</h3>
                            <field-info [info]="fieldInfo.ItemFontStyle.info" [image]="fieldInfo.ItemFontStyle.image"
                                [styleClass]="'pt-pix-10'">
                            </field-info>
                        </div>
                        <pos-font-style-config [(fontStyleConfig)]="widgetStyle.ItemFontStyle"
                            prefix="widgetStyle_itemFontStyle"></pos-font-style-config>
                    </div>
                </div>

                <div [hidden]="(selectedTab != tabList.Header) && (selectedTab != tabList.Config || widgetType.Name != menuWidgetTypes.Banner.Name)"
                    ngModelGroup="widgetHeaderGroup" #widgetHeaderGroup="ngModelGroup" class="col-12 pt-pix-5">
                    <pos-widget-header-config *ngIf="widgetType.Name != menuWidgetTypes.Banner.Name"
                        [(widgetHeaderConfig)]="widgetConfig.WidgetHeaderConfig"
                        [(headerStyle)]="widgetStyle.WidgetHeaderStyleConfig" [prefix]="widgetType.Name"
                        [handleFileInput]="handleFileInput" [removeIcon]="removeIcon"></pos-widget-header-config>

                    <pos-widget-header-config *ngIf="widgetType.Name == menuWidgetTypes.Banner.Name"
                        [(widgetHeaderConfig)]="bannerWidgetConfig"
                        [(headerStyle)]="widgetStyle.WidgetHeaderStyleConfig" [prefix]="widgetType.Name"
                        [handleFileInput]="handleFileInput" [removeIcon]="removeIcon"></pos-widget-header-config>
                </div>

                <div [hidden]="selectedTab != tabList.Items" ngModelGroup="widgetItemsGroup"
                    #widgetItemsGroup="ngModelGroup" class="col-12 pt-pix-5">
                    <div *ngIf="widgetType.Name == menuWidgetTypes.List.Name
                        || widgetType.Name == menuWidgetTypes.ImageList.Name
                        || widgetType.Name == menuWidgetTypes.TileList.Name">
                        <pos-row class="mx-0" *ngIf="widgetType.Name == menuWidgetTypes.List.Name">
                            <pos-column class="p-0">
                                <div class="float-end mb-pix-10 pt-pix-5">
                                    <button type="button" class="btn btn-secondary normal pos-button"
                                        (click)="addNewGroup()">
                                        <pos-icon [icon]="icons.plus" class="pe-pix-5 font-12"></pos-icon> Group
                                    </button>
                                </div>
                            </pos-column>
                        </pos-row>
                        <div class="col-12 overflow-auto-tabs p-0" [ngStyle]="{'height': itemContainerHeight}"
                            cdkDropList (cdkDropListDropped)="widgetGroupDrop($event)">
                            <div class="col-12 item-box" cdkDrag [cdkDragDisabled]="!item.widgetGroup"
                                *ngFor="let item of itemsArray; let i = index; trackBy:trackByIdx">
                                <pos-row colXs="12" class="mx-0">
                                    <pos-column>
                                        <div class="row mx-0">
                                            <div class="col-9 widget-group-name p-0 pt-pix-10">
                                                <div class="title mx-pix-5">{{item.widgetGroup ? item.widgetGroup.Name :
                                                    'Default'}}</div>
                                            </div>
                                            <div class="col-3 p-0 float-end">
                                                <div class="col-1 p-0 col-35 float-end pt-pix-10"
                                                    *ngIf="item.widgetGroup">
                                                    <button cdkDragHandle type="button"
                                                        class="btn btn-secondary normal widget-group-move pos-button">
                                                        <pos-action-icon [icon]="icons.arrowsAlt"
                                                            [additionalClasses]="['font-16', 'white']"
                                                            [backgroundClasses]="['green']"></pos-action-icon>
                                                    </button>
                                                </div>
                                                <div class="col-1 p-0 col-35 float-end pt-pix-10"
                                                    [ngClass]="{'me-pix-10': item.widgetGroup}">
                                                    <button (click)="configOrdinal(item)" type="button"
                                                        class="btn btn-secondary normal widget-group-ordinal">
                                                        <pos-action-icon [icon]="icons.fileSearch"
                                                            [additionalClasses]="['font-16', 'white']"
                                                            [backgroundClasses]="['warning-light-orange']"></pos-action-icon>
                                                    </button>
                                                </div>
                                                <div class="col-1 p-0 col-35 float-end pt-pix-10 me-pix-10 pos-button"
                                                    *ngIf="item.widgetGroup">
                                                    <button (click)="onDeleteItem(i, item)" type="button"
                                                        class="btn btn-secondary normal widget-group-delete">
                                                        <pos-action-icon [icon]="icons.deleteWhite"></pos-action-icon>
                                                    </button>
                                                </div>
                                                <div class="col-1 col-35 float-end me-pix-10 pt-pix-10"
                                                    *ngIf="item.widgetGroup">
                                                    <button (click)="addNewGroup(item)" type="button"
                                                        class="btn btn-secondary normal widget-group-edit pos-button">
                                                        <pos-action-icon [icon]="icons.editWhite"></pos-action-icon>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </pos-column>
                                    <pos-column class="ms-pix-5">
                                        <pos-form-field posInput label="Sales Group" [appendPaddingTop]="false">
                                            <ng-select posInput name="salesGroup_{{i}}" [items]="salesGroupList"
                                                bindLabel="name" bindValue="Id" placeholder="Select sales group(s)"
                                                [multiple]="true" [hideSelected]="true" [(ngModel)]="item.salesGroupIds"
                                                appendTo="body" (change)="setProductOrdinal(item)"></ng-select>
                                        </pos-form-field>
                                    </pos-column>
                                    <pos-column class="ms-pix-5">
                                        <pos-form-field label="Sales Category">
                                            <ng-select posInput name="salesCategory_{{i}}" [items]="salesCategoryList"
                                                bindLabel="name" placeholder="Select sales category(s)" bindValue="id"
                                                [multiple]="true" [hideSelected]="true"
                                                [(ngModel)]="item.salesCategoryIds" appendTo="body"
                                                (change)="setProductOrdinal(item)"></ng-select>
                                        </pos-form-field>
                                    </pos-column>
                                    <pos-column class="ms-pix-5">
                                        <pos-form-field label="Tags">
                                            <ng-select posInput name="tags_{{i}}" [items]="tagList" bindLabel="Name"
                                                placeholder="Select sales tag(s)" (change)="setProductOrdinal(item)"
                                                bindValue="Id" [multiple]="true" [hideSelected]="true"
                                                [(ngModel)]="item.SalesTagsIds" appendTo="body"></ng-select>
                                        </pos-form-field>
                                    </pos-column>
                                </pos-row>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="widgetType.Name == menuWidgetTypes.Item.Name">
                        <pos-row colXs="12" class="p-0">
                            <pos-column>
                                <pos-form-field posInput label="Sales Product">
                                    <ng-select posInput name="itemSalesProduct" [items]="salesProductList"
                                        bindLabel="Name" bindValue="id" placeholder="Select sales product"
                                        [hideSelected]="true" [(ngModel)]="widgetConfiguration.SalesProductId"
                                        appendTo="body"></ng-select>
                                </pos-form-field>
                            </pos-column>
                        </pos-row>
                    </div>
                    <div *ngIf="widgetType.Name == menuWidgetTypes.ComboItemList.Name">
                        <pos-combo-items-config [(comboItems)]="widgetConfiguration.ComboItems"
                            [(descriptionStyle)]="widgetStyle.DescriptionStyle"
                            [salesProductList]="salesProductList"></pos-combo-items-config>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 action-button-container popup-footer">
            <button type="submit" value="Save" class="btn btn-primary btn-lg white btn-save pos-button"
                scrollup>Save</button>
            <button type="button" class="btn btn-secondary btn-lg btn-cancel-modal" value="Cancel pos-button"
                (click)="onCancel()" scrollup>Cancel</button>
        </div>
    </form>
</pos-edit-wrapper>
