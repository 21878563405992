import { DirectedGraph } from 'src/app/shared/classes';
import { ButtonOption } from '../interface/online-ordering-options-graph-model';

export class ButtonOptionGraph {

  private graph = new DirectedGraph();

  buildGraph(menuButtonOptions: ButtonOption[], menuButtonChildOptions: ButtonOption[]): void {
    this.graph = new DirectedGraph();
    this.addButtonOptions(menuButtonOptions);
    this.addButtonChildOptions(menuButtonChildOptions);
  }

  addButtonOptions(menuButtonOptions: ButtonOption[]): void {
    menuButtonOptions.forEach(({ ButtonId, OptionId }) => {
      const buttonNode = this.buttonSuffix(ButtonId);
      const optionNode = this.optionSuffix(OptionId);
      this.graph.addEdge(optionNode, buttonNode);
    });
  }

  addButtonChildOptions(menuButtonChildOptions: ButtonOption[]): void {
    menuButtonChildOptions.forEach(({ ButtonId, OptionId }) => {
      const buttonNode = this.buttonSuffix(ButtonId);
      const optionNode = this.optionSuffix(OptionId);
      this.graph.addEdge(buttonNode, optionNode);  // Option points to button
    });
  }

  removeEdgeButtonToOption(buttonId: number, optionId: number): void {
    const buttonNode = this.buttonSuffix(buttonId);
    const optionNode = this.optionSuffix(optionId);
    this.graph.removeEdge(buttonNode, optionNode);
  }

  removeEdgeOptionToButton(optionId: number, buttonId: number): void {
    const buttonNode = this.buttonSuffix(buttonId);
    const optionNode = this.optionSuffix(optionId);
    this.graph.removeEdge(optionNode, buttonNode);
  }

  isPathFromButtonToOption(buttonId: number, optionId: number): boolean {
    const buttonNode = this.buttonSuffix(buttonId);
    const optionNode = this.optionSuffix(optionId);
    return this.graph.isPathFrom(buttonNode, optionNode);
  }

  isPathFromOptionToButton(optionId: number, buttonId: number): boolean {
      const buttonNode = this.buttonSuffix(buttonId);
      const optionNode = this.optionSuffix(optionId);
      return this.graph.isPathFrom(optionNode, buttonNode);
  }

  buttonSuffix = (buttonId: number): string => `Button${buttonId}`;
  optionSuffix = (optionId: number): string => `Option${optionId}`;
}
