<pos-edit-wrapper (close)="onCancel()" [isPopup]="false" [headingIcon]="icons.loyaltyFormConfigurationCogs"
  heading="Self Registration Form">
  <form #loyaltyFormConfigForm="ngForm" (ngSubmit)="saveLoyaltyFormSettings(loyaltyFormConfigForm.valid)" autocomplete="off">
    <context-provider provide="props">
      <div class="col-12 pos-modal-body border-top pt-pix-5">
        <div class="col-12">
          <pos-row class="px-2">
            <pos-column>
              <pos-form-field label="Header Text"
                [validationMessages]="{required: 'Please enter header text.'}">
                <input posInput class="form-control" name="loyaltyFormHeaderText" #loyaltyFormHeaderText="ngModel"
                  [(ngModel)]="loyaltyFormSettings.HeaderText" type="text" [required]="true" />
              </pos-form-field>
            </pos-column>
            <pos-column>
              <pos-form-field inputStyle="d-flex">
                <p-checkbox posInput name="useRecapcha" #useRecapcha="ngModel"
                  [(ngModel)]="loyaltyFormSettings.UseRecapcha" binary="true" label="Use Recaptcha">
                </p-checkbox>
                <field-info info="If checked, displays a CAPTCHA in the Loyalty Program Form" 
                  styleClass="pt-0">
                </field-info>
              </pos-form-field>
            </pos-column>
            <pos-column>
              <div class="alert condensed inherit-position alert-warning mt-pix-10 mb-pix-5 font-16"
                *ngIf="loyaltyFormSettings.UseRecapcha" [innerHTML]="captchaWarning">
              </div>
            </pos-column>
            <pos-column>
              <pos-form-field label="Form Success Header Text"
                [validationMessages]="{required: 'Please enter form success header text.'}">
                <input posInput class="form-control" name="loyaltyFormSuccessHeaderText" #loyaltyFormSuccessHeaderText="ngModel"
                  [(ngModel)]="loyaltyFormSettings.FormSuccessHeader" type="text" [required]="true" />
              </pos-form-field>
            </pos-column>
            <pos-column>
              <pos-form-field label="Form Success Description"
                [validationMessages]="{required: 'Please enter form success description text.'}">
                <textarea posInput class="form-control" name="loyaltyFormSuccessDescriptionText" #loyaltyFormSuccessDescriptionText="ngModel"
                  [(ngModel)]="loyaltyFormSettings.FormSuccessDescription" rows="3" [required]="true">
                </textarea>
              </pos-form-field>
            </pos-column>
          </pos-row>
        </div>
      </div>
      <div class="col-12 action-button-container page-footer">
        <button class="btn btn-primary btn-lg btn-save pos-button" scrollup type="submit"> Save </button>
        <button class="btn btn-secondary btn-lg btn-cancel pos-button" scrollup type="button" routerLink="../">
          Cancel </button>
      </div>
    </context-provider>
  </form>
</pos-edit-wrapper>
