<pos-edit-wrapper heading="Screen Config: {{ kioskScreen.Name}}" (close)="onCancel()">
    <form #screenConfigForm="ngForm" (ngSubmit)="save(screenConfigForm.valid)" autocomplete="off">
        <div class="col-12 container-fluid">
            <ul class="nav nav-tabs inner-tab pos-nav-tabs">
                <li [ngClass]="{'active': selectedTab == tabList.Screen}" *ngIf="screenConfig">
                    <a (click)="selectedTab = tabList.Screen">
                        <span>
                            <pos-icon class="theme-separator pe-pix-5" [icon]="icons.desktop"></pos-icon>
                        </span>
                        <span>Screen</span>
                    </a>
                </li>
                <li [ngClass]="{'active': selectedTab == tabList.Grid}" *ngIf="grid">
                    <a (click)="selectedTab = tabList.Grid" [ngClass]="{'required': gridConfigGroup.invalid}">
                        <span>
                            <pos-icon class="theme-separator pe-pix-5" [icon]="icons.th"></pos-icon>
                        </span>
                        <span>Grid</span>
                    </a>
                </li>
                <li [ngClass]="{'active': selectedTab == tabList.Button}" *ngIf="buttonConfig || shakeButtonConfig">
                    <a (click)="selectedTab = tabList.Button">
                        <span>
                            <pos-icon class="theme-separator pe-pix-5" [icon]="icons.buttonsDiceOne"></pos-icon>
                        </span>
                        <span>Button</span>
                    </a>
                </li>
                <li [ngClass]="{'active': selectedTab == tabList.Panel}" *ngIf="leftPanelConfig || rightPanelConfig">
                    <a (click)="selectedTab = tabList.Panel">
                        <span>
                            <pos-icon class="theme-separator pe-pix-5" [icon]="icons.column"></pos-icon>
                        </span>
                        <span>Panel</span>
                    </a>
                </li>
                <li [ngClass]="{'active': selectedTab == tabList.Allergy}"
                    *ngIf="kioskScreen.TemplateType == templateTypes.AllergensSelectionScreenLayout || kioskScreen.TemplateType == templateTypes.AllergensVerifyScreenLayout">
                    <a (click)="selectedTab = tabList.Allergy">
                        <span>
                            <pos-icon class="theme-separator pe-pix-5" [icon]="icons.allergiesLight"></pos-icon>
                        </span>
                        <span>Allergy</span>
                    </a>
                </li>
                <li [ngClass]="{'active': selectedTab == tabList.AllergenButtons}"
                    *ngIf="kioskScreen.TemplateType == templateTypes.AllergensInquiryLayout">
                    <a (click)="selectedTab = tabList.AllergenButtons">
                        <span>
                            <pos-icon class="theme-separator pe-pix-5" [icon]="icons.buttonsDiceOne"></pos-icon>
                        </span>
                        <span>Button</span>
                    </a>
                </li>
                <li [ngClass]="{'active': selectedTab == tabList.Layout}"
                    *ngIf="kioskScreen.TemplateType == templateTypes.AIShakeCreatedTemplate">
                    <a (click)="selectedTab = tabList.Layout">
                        <span>
                            <pos-icon class="theme-separator pe-pix-5" [icon]="icons.th"></pos-icon>
                        </span>
                        <span>Layout</span>
                    </a>
                </li>
                <li [ngClass]="{'active': selectedTab == tabList.Note}"
                    *ngIf="kioskScreen.TemplateType == templateTypes.PromptTemplate">
                    <a (click)="selectedTab = tabList.Note">
                        <span>
                            <pos-icon class="theme-separator pe-pix-5" [icon]="icons.stickyNoteRegular"></pos-icon>
                        </span>
                        <span>Note</span>
                    </a>
                </li>
            </ul>
            <div class="pos-tab-body overflow-auto-tabs" [ngStyle]="{'height': containerHeight}">
                <div [hidden]="selectedTab != tabList.Screen" class="col-12 pt-pix-5">
                    <pos-row>
                        <pos-column sm="6" xs="12">
                            <pos-form-field label="Background Color">
                                <pos-color-picker posInput name="backgroundColor"
                                    [(ngModel)]="screenConfig.BackgroundColor"></pos-color-picker>
                            </pos-form-field>
                        </pos-column>
                        <pos-column sm="6" xs="12">
                            <pos-form-field label="Background Image" inputStyle="col-12">
                                <pos-row>
                                    <pos-column md="8" xs="9">
                                        <input posInput type="file" id="imageInput" name="imageInput"
                                            class="form-control" accept=".png,.jpg,.jpeg,.gif,.bmp" ngModel
                                            (change)="handleFileInput($event.target.files, 'backgroundImage', 'imageInput')" />
                                    </pos-column>
                                    <pos-column md="1" xs="3" class="p-0 col-70 mt-pix-n1"
                                        [hidden]="!screenConfig.BackgroundImage">
                                        <img #backgroundImage id="backgroundImage" alt="" class="col-40" height="40"
                                            src="{{imageRootPath}}{{screenConfig.BackgroundImage}}" />
                                        <div class="relative mt-pix-n7 float-right"
                                            (click)="removeIcon('imageInput'); screenConfig.BackgroundImage = null">
                                            <pos-icon [icon]="icons.times"
                                                class="pointer ms-pix-n7 remove-icon-badge"></pos-icon>
                                        </div>
                                    </pos-column>
                                </pos-row>
                            </pos-form-field>
                        </pos-column>
                        <pos-column *ngIf="kioskScreen.TemplateType == templateTypes.AIShakeGroupSelectionTemplate">
                            <pos-form-field label="Grid Start Position" inputStyle="col-6 row row-cols-2 mx-0">
                                <input posInput name="gridStartPosition" type="number" class="form-control col-120"
                                    [(ngModel)]="screenConfig.GridStartPosition" />
                                <label class="col-30 edit-form__section__label"> px </label>
                            </pos-form-field>
                        </pos-column>
                        <pos-column>
                            <pos-form-field>
                                <p-checkbox posInput name="isShowHeader" #isShowHeader="ngModel"
                                    (onChange)="headerConfigChanged()" [(ngModel)]="screenConfig.Header.IsShow"
                                    binary="true" label="Show Header">
                                </p-checkbox>
                            </pos-form-field>
                        </pos-column>
                        <pos-column *ngIf="screenConfig.Header.IsShow">
                            <pos-row class="mx-0">
                                <pos-column>
                                    <pos-form-field label="Title" inputStyle="col-6">
                                        <input posInput name="headerTitle" type="text" class="form-control"
                                            [(ngModel)]="screenConfig.Header.Title" />
                                    </pos-form-field>
                                </pos-column>
                                <pos-column sm="6" xs="12">
                                    <pos-form-field label="Background Color">
                                        <pos-color-picker posInput name="headerBackgroundColor"
                                            [(ngModel)]="screenConfig.Header.BackgroundColor"></pos-color-picker>
                                    </pos-form-field>
                                </pos-column>
                                <pos-column sm="6" xs="12">
                                    <pos-form-field label="Font Color">
                                        <pos-color-picker posInput name="headerFontColor"
                                            [(ngModel)]="screenConfig.Header.FontColor"></pos-color-picker>
                                    </pos-form-field>
                                </pos-column>
                                <pos-column sm="6" xs="12">
                                    <pos-form-field label="Border Color">
                                        <pos-color-picker posInput name="headerBorderColor"
                                            [(ngModel)]="screenConfig.Header.BorderColor"></pos-color-picker>
                                    </pos-form-field>
                                </pos-column>
                                <pos-column sm="6" xs="12">
                                    <pos-form-field label="Border Width" inputStyle="col-6 row row-cols-2 mx-0">
                                        <input posInput name="headerWidth" type="number" class="form-control col-120"
                                            [(ngModel)]="screenConfig.Header.BorderWidth" />
                                        <label class="col-30 edit-form__section__label"> px </label>
                                    </pos-form-field>
                                </pos-column>
                                <pos-column sm="6" xs="12">
                                    <pos-form-field label="Background Image" inputStyle="col-12">
                                        <pos-row>
                                            <pos-column md="8" xs="9">
                                                <input posInput type="file" id="screenHeaderImage"
                                                    name="screenHeaderImage" class="form-control"
                                                    accept=".png,.jpg,.jpeg,.gif,.bmp" ngModel
                                                    (change)="handleFileInput($event.target.files, 'screenHeaderImagePreview', 'screenHeaderImage')" />
                                            </pos-column>
                                            <pos-column md="1" xs="3" class="p-0 col-70 mt-pix-n1"
                                                [hidden]="!screenConfig.Header.BackgroundImage">
                                                <img #screenHeaderImagePreview id="screenHeaderImagePreview" alt=""
                                                    class="col-40" height="40"
                                                    src="{{imageRootPath}}{{screenConfig.Header.BackgroundImage}}" />
                                                <div class="relative mt-pix-n7 float-right"
                                                    (click)="removeIcon('screenHeaderImage'); screenConfig.Header.BackgroundImage = null">
                                                    <pos-icon [icon]="icons.times"
                                                        class="pointer ms-pix-n7 remove-icon-badge"></pos-icon>
                                                </div>
                                            </pos-column>
                                        </pos-row>
                                    </pos-form-field>
                                </pos-column>
                            </pos-row>
                        </pos-column>
                        <pos-column>
                            <pos-form-field>
                                <p-checkbox posInput name="isShowFooter" #isShowFooter="ngModel"
                                    [(ngModel)]="screenConfig.Footer.IsShow" binary="true" label="Show Footer">
                                </p-checkbox>
                            </pos-form-field>
                        </pos-column>
                        <pos-column *ngIf="screenConfig.Footer.IsShow">
                            <pos-row class="mx-0">
                                <pos-column>
                                    <pos-form-field>
                                        <p-checkbox posInput name="isShowBackButton" #isShowBackButton="ngModel"
                                            [(ngModel)]="screenConfig.Footer.IsShowBackButton" binary="true"
                                            label="Show Back Button">
                                        </p-checkbox>
                                    </pos-form-field>
                                </pos-column>
                            </pos-row>
                            <pos-row class="mx-0" colLg="6" colXs="12">
                                <pos-column>
                                    <pos-form-field label="Background Color">
                                        <pos-color-picker posInput name="footerBackgroundColor" cpPosition="top"
                                            [(ngModel)]="screenConfig.Footer.BackgroundColor"></pos-color-picker>
                                    </pos-form-field>
                                </pos-column>
                                <pos-column>
                                    <pos-form-field label="Background Image" inputStyle="col-12">
                                        <pos-row>
                                            <pos-column md="8" xs="9">
                                                <input posInput type="file" id="screenFooterImage"
                                                    name="screenFooterImage" class="form-control"
                                                    accept=".png,.jpg,.jpeg,.gif,.bmp" ngModel
                                                    (change)="handleFileInput($event.target.files, 'screenFooterImagePreview', 'screenFooterImage')" />
                                            </pos-column>
                                            <pos-column md="1" xs="3" class="p-0 col-70 mt-pix-n1"
                                                [hidden]="!screenConfig.Footer.BackgroundImage">
                                                <img #screenFooterImagePreview id="screenFooterImagePreview" alt=""
                                                    class="col-40" height="40"
                                                    src="{{imageRootPath}}{{screenConfig.Footer.BackgroundImage}}" />
                                                <div class="relative mt-pix-n7 float-right"
                                                    (click)="removeIcon('screenFooterImage'); screenConfig.Footer.BackgroundImage = null">
                                                    <pos-icon [icon]="icons.times"
                                                        class="pointer ms-pix-n7 remove-icon-badge"></pos-icon>
                                                </div>
                                            </pos-column>
                                        </pos-row>
                                    </pos-form-field>
                                </pos-column>
                                <pos-column>
                                    <pos-form-field label="Border Color">
                                        <pos-color-picker posInput name="footerBorderColor" cpPosition="top"
                                            [(ngModel)]="screenConfig.Footer.BorderColor"></pos-color-picker>
                                    </pos-form-field>
                                </pos-column>
                                <pos-column>
                                    <pos-form-field label="Border Width" inputStyle="col-6 row row-cols-2 mx-0">
                                        <input posInput name="borderWidth" type="number" class="form-control col-120"
                                            [(ngModel)]="screenConfig.Footer.BorderWidth" />
                                        <label class="col-30 edit-form__section__label"> px </label>
                                    </pos-form-field>
                                </pos-column>
                            </pos-row>
                        </pos-column>
                        <pos-column><pos-form-field>
                                <p-checkbox posInput name="isShowInvoice" #isShowInvoice="ngModel"
                                    [(ngModel)]="screenConfig.ShowInvoice" binary="true" label="Show Invoice">
                                </p-checkbox>
                            </pos-form-field>
                        </pos-column>
                        <pos-column class="px-pix-7" *ngIf="isAllowSettingOutOfStockBehavior">
                            <pos-form-field label="Out of Stock Behavior" inputStyle="col-3">
                                    <ng-select posInput name="outOfStockBehavior" bindLabel="Name" bindValue="Value"
                                        [items]="kioskOutOfStockBehaviorTypes"
                                        [(ngModel)]="screenConfig.OutOfStockBehavior" [clearOnBackspace]="false"
                                        appendTo="body" placeholder="Select Out of Stock Behavior">
                                    </ng-select>
                            </pos-form-field>
                        </pos-column>                   
                    </pos-row>
                </div>
                <div [hidden]="selectedTab != tabList.Note" class="col-12 pt-pix-5" ngModelGroup="noteConfigGroup"
                    #noteConfigGroup="ngModelGroup" *ngIf="screenConfig.Note">
                    <pos-row>
                        <pos-column xs="12">
                            <pos-form-field label="Note" inputStyle="col-12">
                                <ckeditor posInput [(ngModel)]="screenConfig.Note.Text" #ckeditor
                                    [config]="toolbarConfig" [editorUrl]="'assets/ckeditor/ckeditor.js'" name="note">
                                </ckeditor>
                            </pos-form-field>
                        </pos-column>
                    </pos-row>
                    <pos-row class="pos-title-content" colLg="6">
                        <pos-column>
                            <pos-form-field label="Container Width">
                                <input posInput name="noteContainerWidth" type="text" class="form-control col-120"
                                    [(ngModel)]="screenConfig.Note.ContainerWidth" />
                            </pos-form-field>
                        </pos-column>
                        <pos-column>
                            <pos-form-field label="Horizontal" inputStyle="col-6">
                                <ng-select posInput name="noteHorizontal" bindLabel="Name" bindValue="Value"
                                    [items]="horizontalAlignments" [(ngModel)]="screenConfig.Note.Alignment.Horizontal"
                                    [clearOnBackspace]="false" appendTo="body"
                                    placeholder="Select horizontal alignment"></ng-select>
                            </pos-form-field>
                        </pos-column>
                        <pos-column>
                            <pos-form-field label="Vertical" inputStyle="col-6">
                                <ng-select posInput name="noteVertical" bindLabel="Name" bindValue="Value"
                                    [items]="alignItemsVertical" [(ngModel)]="screenConfig.Note.Alignment.Vertical"
                                    [clearOnBackspace]="false" appendTo="body"
                                    placeholder="Select vertical alignment"></ng-select>
                            </pos-form-field>
                        </pos-column>
                        <pos-column>
                            <pos-form-field label="Vertical Position" inputStyle="col-6 row row-cols-2 mx-0">
                                <input posInput name="noteVerticalPosition" type="number" class="form-control col-120"
                                    [(ngModel)]="screenConfig.Note.VerticalPosition" />
                                <label class="col-30 edit-form__section__label"> px </label>
                            </pos-form-field>
                        </pos-column>

                        <pos-column sm="6" xs="12">
                            <pos-form-field label="Background Color">
                                <pos-color-picker posInput name="noteBackgroundColor" cpPosition="top"
                                    [(ngModel)]="screenConfig.Note.BackgroundColor"></pos-color-picker>
                            </pos-form-field>
                        </pos-column>

                        <pos-column sm="6" xs="12">
                            <pos-form-field label="Font Color">
                                <pos-color-picker posInput name="noteFontColor" cpPosition="top"
                                    [(ngModel)]="screenConfig.Note.Color"></pos-color-picker>
                            </pos-form-field>
                        </pos-column>

                        <pos-column>
                            <pos-form-field label="Border Color">
                                <pos-color-picker posInput name="noteBorderColor" cpPosition="top"
                                    [(ngModel)]="screenConfig.Note.BorderColor"></pos-color-picker>
                            </pos-form-field>
                        </pos-column>
                        <pos-column>
                            <pos-form-field label="Border Width" inputStyle="col-6 row row-cols-2 mx-0">
                                <input posInput name="noteBorderWidth" type="number" class="form-control col-120"
                                    [(ngModel)]="screenConfig.Note.BorderWidth" />
                                <label class="col-30 edit-form__section__label"> px </label>
                            </pos-form-field>
                        </pos-column>
                        <pos-column>
                            <pos-form-field label="Font size" inputStyle="col-6 row row-cols-2 mx-0">
                                <input posInput name="noteFontSize" #fontSize="ngModel" class="form-control col-120"
                                    [(ngModel)]="screenConfig.Note.FontSize" />
                                <label class="col-30 edit-form__section__label"> px </label>
                            </pos-form-field>
                        </pos-column>
                    </pos-row>
                </div>
                <div [hidden]="selectedTab != tabList.Grid" class="col-12 pt-pix-5" ngModelGroup="gridConfigGroup"
                    #gridConfigGroup="ngModelGroup">
                    <div class="col-12 p-0" *ngIf="grid">
                        <pos-row colLg="6">
                            <pos-column *ngIf="kioskScreen.TemplateType != templateTypes.PromptTemplate
                                && kioskScreen.TemplateType != templateTypes.FixedGridLayout">
                                <pos-form-field label="Rows" inputStyle="col-6 col-120"
                                    [validationMessages]="{required: 'Please enter rows.'}">
                                    <input posInput name="rows" type="number" class="form-control"
                                        [(ngModel)]="grid.NoOfRows" [required]="true" min="0" />
                                </pos-form-field>
                            </pos-column>

                            <pos-column *ngIf="kioskScreen.TemplateType != templateTypes.PromptTemplate">
                                <pos-form-field label="Cols" inputStyle="col-6 col-120"
                                    [validationMessages]="{required: 'Please enter cols.'}">
                                    <input posInput name="cols" type="number" class="form-control"
                                        [(ngModel)]="grid.NoOfCols" [required]="true" min="0" />
                                </pos-form-field>
                            </pos-column>
                        </pos-row>
                        <pos-row>
                            <pos-column>
                                <pos-form-field>
                                    <p-checkbox posInput name="isShowTagsAndPrice" #isShowTagsAndPrice="ngModel"
                                        [(ngModel)]="grid.IsShowTagsAndPrice" binary="true" label="Show Tags and Price">
                                    </p-checkbox>
                                </pos-form-field>
                            </pos-column>
                        </pos-row>
                        <pos-row>
                            <pos-column>
                                <pos-form-field>
                                    <p-checkbox posInput name="isStretchImage" #isStretchImage="ngModel"
                                        [(ngModel)]="grid.IsStretchImage" binary="true" label="Stretch Images">
                                    </p-checkbox>
                                </pos-form-field>
                            </pos-column>
                        </pos-row>
                        <pos-row>
                            <pos-column>
                                <pos-form-field>
                                    <p-checkbox posInput name="sortAlphabetically" #sortAlphabetically="ngModel"
                                        [(ngModel)]="grid.SortAlphabetically" binary="true" label="Sort Alphabetically">
                                    </p-checkbox>
                                </pos-form-field>
                            </pos-column>
                        </pos-row>
                    </div>
                </div>
                <div [hidden]="selectedTab != tabList.Button" class="col-12 pt-pix-5">
                    <div class="col-12 p-0" *ngIf="buttonConfig">
                        <pos-row colLg="6">
                            <pos-column>
                                <pos-form-field label="Height" inputStyle="col-6 row row-cols-2 mx-0">
                                    <input posInput name="buttonHeight" type="number" class="form-control col-120"
                                        [(ngModel)]="buttonConfig.Height" />
                                    <label class="col-30 edit-form__section__label"> px </label>
                                </pos-form-field>
                            </pos-column>

                            <pos-column
                                *ngIf="kioskScreen.TemplateType == templateTypes.FluidGridLayout || kioskScreen.TemplateType == templateTypes.PromptTemplate">
                                <pos-form-field label="Width" inputStyle="col-6 row row-cols-2 mx-0">
                                    <input posInput name="gridButtonWidth" type="number" class="form-control col-120"
                                        [(ngModel)]="buttonConfig.Width" />
                                    <label class="col-30 edit-form__section__label"> px </label>
                                </pos-form-field>
                            </pos-column>

                            <pos-column *ngIf="kioskScreen.TemplateType != templateTypes.FixedGridLayout">
                                <pos-form-field label="Horizontal" inputStyle="col-6">
                                    <ng-select posInput name="buttonHorizontal" bindLabel="Name" bindValue="Value"
                                        [items]="kioskScreen.TemplateType == templateTypes.PromptTemplate ? horizontalAlignmentsForFlex : horizontalAlignments"
                                        [(ngModel)]="buttonConfig.Alignment.Horizontal" [clearOnBackspace]="false"
                                        appendTo="body" placeholder="Select horizontal alignment"></ng-select>
                                </pos-form-field>
                            </pos-column>

                            <pos-column>
                                <pos-form-field label="Vertical" inputStyle="col-6">
                                    <ng-select posInput name="buttonVertical" bindLabel="Name" bindValue="Value"
                                        [items]="alignItemsVertical" [(ngModel)]="buttonConfig.Alignment.Vertical"
                                        [clearOnBackspace]="false" appendTo="body"
                                        placeholder="Select vertical alignment"></ng-select>
                                </pos-form-field>
                            </pos-column>

                            <pos-column>
                                <pos-form-field label="Radius" inputStyle="col-6 row row-cols-2 mx-0">
                                    <input posInput name="gridButtonRadius" type="number" class="form-control col-120"
                                        [(ngModel)]="buttonConfig.Radius" />
                                    <label class="col-30 edit-form__section__label"> px </label>
                                </pos-form-field>
                            </pos-column>
                            <pos-column>
                                <pos-form-field label="Opacity">
                                    <pos-row class="mx-0">
                                        <pos-column xs="11" class="ps-0">
                                            <ngx-slider [(value)]="buttonConfig.Opacity"
                                                [options]="options"></ngx-slider>
                                        </pos-column>
                                        <pos-column xs="1" class="p-0">
                                            <span class="badge mt-pix-2 volume-circle">
                                                {{ buttonConfig.Opacity ? (buttonConfig.Opacity * 100) : 0 }}</span>
                                        </pos-column>
                                    </pos-row>
                                </pos-form-field>
                            </pos-column>

                            <pos-column *ngIf="kioskScreen.TemplateType == templateTypes.PromptTemplate">
                                <pos-form-field label="Vertical Position" inputStyle="col-6 row row-cols-2 mx-0">
                                    <input posInput name="verticalPosition" type="number" class="form-control col-120"
                                        [(ngModel)]="buttonConfig.VerticalPosition" />
                                    <label class="col-30 edit-form__section__label"> px </label>
                                </pos-form-field>
                            </pos-column>

                            <pos-column>
                                <pos-form-field label="Rotation Degree" inputStyle="col-6 row row-cols-2 mx-0"
                                    [validationMessages]="{min: 'Degree must be between 0 and 360.', max: 'Degree must be between 0 and 360.'}">
                                    <input posInput name="gridButtonRotationDegree" type="number"
                                        class="form-control col-120" [(ngModel)]="buttonConfig.RotationDegree" min="0"
                                        max="360" />
                                    <label class="col-30 edit-form__section__label"> deg </label>
                                </pos-form-field>
                            </pos-column>

                            <pos-column sm="6" xs="12"
                                *ngIf="kioskScreen.TemplateType == templateTypes.FixedGridLayout || kioskScreen.TemplateType == templateTypes.FluidGridLayout">
                                <pos-form-field label="Button Text Background">
                                    <pos-color-picker posInput name="textBackgroundColor"
                                        [(ngModel)]="buttonConfig.TextBackground"></pos-color-picker>
                                </pos-form-field>
                            </pos-column>
                            <pos-column *ngIf="kioskScreen.TemplateType == templateTypes.PromptTemplate">
                                <pos-form-field label="Container Width">
                                    <input posInput name="buttonContainerWidth" type="text" class="form-control col-120"
                                        [(ngModel)]="buttonConfig.ContainerWidth" />
                                </pos-form-field>
                            </pos-column>
                            <pos-column>
                                <pos-form-field label="Button Image Radius" inputStyle="col-6 row row-cols-2 mx-0">
                                    <input posInput name="gridButtonImageRadius" type="text"
                                        class="form-control col-120" [(ngModel)]="buttonConfig.ImageRadius" />
                                    <!-- <label class="col-30 edit-form__section__label"> px </label> -->
                                </pos-form-field>
                            </pos-column>
                        </pos-row>

                        <pos-row
                            *ngIf="kioskScreen.TemplateType == templateTypes.FixedGridLayout || kioskScreen.TemplateType == templateTypes.FluidGridLayout">
                            <pos-column>
                                <pos-form-field>
                                    <p-checkbox posInput name="isShowButtonText" #isShowButtonText="ngModel"
                                        [(ngModel)]="buttonConfig.IsShowButtonText" binary="true"
                                        label="Show Button Text">
                                    </p-checkbox>
                                </pos-form-field>
                            </pos-column>                            
                        </pos-row>

                    </div>
                    <div class="col-12 p-0 section-border" *ngIf="secondaryButtonConfig">
                        <pos-row>
                            <pos-column>
                                <pos-form-field>
                                    <p-checkbox posInput name="isShowSecondaryButtons" #isShowSecondaryButtons="ngModel"
                                        [(ngModel)]="secondaryButtonConfig.IsShow" binary="true"
                                        label="Show Secondary Button">
                                    </p-checkbox>
                                </pos-form-field>
                            </pos-column>
                            <pos-column *ngIf="secondaryButtonConfig.IsShow">
                                <pos-row class="mx-0">
                                    <pos-column>
                                        <pos-form-field>
                                            <p-checkbox posInput name="isShowSecondaryButtonText"
                                                #isShowSecondaryButtonText="ngModel"
                                                [(ngModel)]="secondaryButtonConfig.IsShowButtonText" binary="true"
                                                label="Show Button Text">
                                            </p-checkbox>
                                        </pos-form-field>
                                    </pos-column>
                                </pos-row>
                                <pos-row class="mx-0" colLg="6">
                                    <pos-column>
                                        <pos-form-field label="Height" inputStyle="col-6 row row-cols-2 mx-0">
                                            <input posInput name="secondaryButtonHeight" type="number"
                                                class="form-control col-120"
                                                [(ngModel)]="secondaryButtonConfig.Height" />
                                            <label class="col-30 edit-form__section__label"> px </label>
                                        </pos-form-field>
                                    </pos-column>

                                    <pos-column>
                                        <pos-form-field label="Width" inputStyle="col-6 row row-cols-2 mx-0">
                                            <input posInput name="secondaryButtonWidth" type="number"
                                                class="form-control col-120"
                                                [(ngModel)]="secondaryButtonConfig.Width" />
                                            <label class="col-30 edit-form__section__label"> px </label>
                                        </pos-form-field>
                                    </pos-column>
                                    <pos-column>
                                        <pos-form-field label="Horizontal" inputStyle="col-6">
                                            <ng-select posInput name="secondaryButtonHorizontal" bindLabel="Name"
                                                bindValue="Value" [items]="horizontalAlignments"
                                                [(ngModel)]="secondaryButtonConfig.Alignment.Horizontal"
                                                [clearOnBackspace]="false" appendTo="body"
                                                placeholder="Select horizontal alignment"></ng-select>
                                        </pos-form-field>
                                    </pos-column>

                                    <pos-column>
                                        <pos-form-field label="Vertical" inputStyle="col-6">
                                            <ng-select posInput name="secondaryButtonVertical" bindLabel="Name"
                                                bindValue="Value" [items]="alignItemsVertical"
                                                [(ngModel)]="secondaryButtonConfig.Alignment.Vertical"
                                                [clearOnBackspace]="false" appendTo="body"
                                                placeholder="Select vertical alignment"></ng-select>
                                        </pos-form-field>
                                    </pos-column>

                                    <pos-column>
                                        <pos-form-field label="Radius" inputStyle="col-6 row row-cols-2 mx-0">
                                            <input posInput name="secondaryButtonRadius" type="number"
                                                class="form-control col-120"
                                                [(ngModel)]="secondaryButtonConfig.Radius" />
                                            <label class="col-30 edit-form__section__label"> px </label>
                                        </pos-form-field>
                                    </pos-column>
                                    <pos-column>
                                        <pos-form-field label="Opacity">
                                            <pos-row class="mx-0">
                                                <pos-column xs="11" class="ps-0">
                                                    <ngx-slider [(value)]="secondaryButtonConfig.Opacity"
                                                        [options]="options"></ngx-slider>
                                                </pos-column>
                                                <pos-column xs="1" class="p-0">
                                                    <span class="badge mt-pix-2 volume-circle">
                                                        {{ secondaryButtonConfig.Opacity ?(secondaryButtonConfig.Opacity
                                                        * 100) : 0 }}
                                                    </span>
                                                </pos-column>
                                            </pos-row>
                                        </pos-form-field>
                                    </pos-column>

                                    <pos-column>
                                        <pos-form-field label="Rotation Degree" inputStyle="col-6 row row-cols-2 mx-0"
                                            [validationMessages]="{min: 'Degree must be between 0 and 360.', max: 'Degree must be between 0 and 360.'}">
                                            <input posInput name="secondaryButtonRotationDegree" type="number"
                                                class="form-control col-120"
                                                [(ngModel)]="secondaryButtonConfig.RotationDegree" min="0" max="360" />
                                            <label class="col-30 edit-form__section__label"> deg </label>
                                        </pos-form-field>
                                    </pos-column>

                                    <pos-column>
                                        <pos-form-field label="Container Height" inputStyle="col-6 row row-cols-2 mx-0">
                                            <input posInput name="secondaryButtonContainerHeight" type="number"
                                                class="form-control col-120"
                                                [(ngModel)]="secondaryButtonConfig.ContainerHeight" />
                                            <label class="col-30 edit-form__section__label"> px </label>
                                        </pos-form-field>
                                    </pos-column>

                                    <pos-column>
                                        <pos-form-field label="Container Width">
                                            <input posInput name="secondaryButtonContainerWidth" type="text"
                                                class="form-control col-120"
                                                [(ngModel)]="secondaryButtonConfig.ContainerWidth" />
                                        </pos-form-field>
                                    </pos-column>
                                </pos-row>
                            </pos-column>
                        </pos-row>
                    </div>
                    <div class="col-12 p-0" *ngIf="shakeButtonConfig">
                        <pos-row colLg="6">
                            <pos-column>
                                <pos-form-field label="Text Background">
                                    <pos-color-picker posInput name="textBackgroundColor"
                                        [(ngModel)]="shakeButtonConfig.BackgroundColor"></pos-color-picker>
                                </pos-form-field>
                            </pos-column>

                            <pos-column>
                                <pos-form-field label="Font Color">
                                    <pos-color-picker posInput name="textColor"
                                        [(ngModel)]="shakeButtonConfig.FontColor"></pos-color-picker>
                                </pos-form-field>
                            </pos-column>
                        </pos-row>
                    </div>
                </div>
                <div [hidden]="selectedTab != tabList.Panel" class="col-12 pt-pix-5">
                    <div class="col-12 p-0" *ngIf="leftPanelConfig">
                        <div>
                            <h3 class="setting-title mt-pix-5">Left Panel</h3>
                        </div>
                        <pos-row class="pos-title-content" colLg="6">
                            <pos-column>
                                <pos-form-field label="Horizontal" inputStyle="col-6">
                                    <ng-select posInput name="leftPanelHorizontal" bindLabel="Name" bindValue="Value"
                                        [items]="horizontalAlignments"
                                        [(ngModel)]="leftPanelConfig.Alignment.Horizontal" [clearOnBackspace]="false"
                                        appendTo="body" placeholder="Select horizontal alignment"></ng-select>
                                </pos-form-field>
                            </pos-column>

                            <pos-column>
                                <pos-form-field label="Vertical" inputStyle="col-6">
                                    <ng-select posInput name="leftPanelVertical" bindLabel="Name" bindValue="Value"
                                        [items]="alignItemsVertical" [(ngModel)]="leftPanelConfig.Alignment.Vertical"
                                        [clearOnBackspace]="false" appendTo="body"
                                        placeholder="Select vertical alignment"></ng-select>
                                </pos-form-field>
                            </pos-column>

                            <pos-column>
                                <pos-form-field label="Height" inputStyle="col-6 row row-cols-2 mx-0">
                                    <input posInput name="leftPanelHeight" type="number" class="form-control col-120"
                                        [(ngModel)]="leftPanelConfig.Height" />
                                    <label class="col-30 edit-form__section__label"> px </label>
                                </pos-form-field>
                            </pos-column>

                            <pos-column>
                                <pos-form-field label="Width" inputStyle="col-6 row row-cols-2 mx-0">
                                    <input posInput name="leftPanelWidth" type="number" class="form-control col-120"
                                        [(ngModel)]="leftPanelConfig.Width" />
                                    <label class="col-30 edit-form__section__label"> px </label>
                                </pos-form-field>
                            </pos-column>

                            <pos-column>
                                <pos-form-field label="Radius" inputStyle="col-6 row row-cols-2 mx-0">
                                    <input posInput name="leftPanelRadius" type="number" class="form-control col-120"
                                        [(ngModel)]="leftPanelConfig.Radius" />
                                    <label class="col-30 edit-form__section__label"> px </label>
                                </pos-form-field>
                            </pos-column>

                            <pos-column>
                                <pos-form-field label="Opacity">
                                    <pos-row class="mx-0">
                                        <pos-column xs="11" class="ps-0">
                                            <ngx-slider [(value)]="leftPanelConfig.Opacity"
                                                [options]="options"></ngx-slider>
                                        </pos-column>
                                        <pos-column xs="1" class="p-0">
                                            <span class="badge mt-pix-2 volume-circle">{{ leftPanelConfig.Opacity ?
                                                (leftPanelConfig.Opacity * 100) :
                                                0 }}</span>
                                        </pos-column>
                                    </pos-row>
                                </pos-form-field>
                            </pos-column>

                            <pos-column>
                                <pos-form-field label="Vertical Position" inputStyle="col-6 row row-cols-2 mx-0">
                                    <input posInput name="verticalPosition" type="number" class="form-control col-120"
                                        [(ngModel)]="leftPanelConfig.VerticalPosition" />
                                    <label class="col-30 edit-form__section__label"> px </label>
                                </pos-form-field>
                            </pos-column>

                            <pos-column>
                                <pos-form-field label="Rotation Degree" inputStyle="col-6 row row-cols-2 mx-0"
                                    [validationMessages]="{min: 'Degree must be between 0 and 360.', max: 'Degree must be between 0 and 360.'}">
                                    <input posInput name="leftPanelButtonRotationDegree" type="number"
                                        class="form-control col-120" [(ngModel)]="leftPanelConfig.RotationDegree"
                                        min="0" max="360" />
                                    <label class="col-30 edit-form__section__label"> deg </label>
                                </pos-form-field>
                            </pos-column>
                        </pos-row>
                    </div>

                    <div class="col-12 section-border p-0" *ngIf="rightPanelConfig">
                        <div>
                            <h3 class="setting-title mt-pix-5">Right Panel</h3>
                        </div>
                        <div class="ps-3">
                            <pos-row>
                                <pos-column>
                                    <pos-form-field label="Width" inputStyle="col-6 row row-cols-2 mx-0">
                                        <input posInput name="rightPanelWidth" type="text" class="form-control col-120"
                                            [(ngModel)]="rightPanelConfig.Width" />
                                    </pos-form-field>
                                </pos-column>
                            </pos-row>
                        </div>
                        <div class="ps-3">
                            <div>
                                <h3 class="setting-title mt-pix-5">Button</h3>
                            </div>
                            <pos-row class="pos-title-content" colLg="6">
                                <pos-column>
                                    <pos-form-field label="Vertical Alignment" inputStyle="col-6">
                                        <ng-select posInput name="rightPanelVertical" bindLabel="Name" bindValue="Value"
                                            [items]="alignItemsVertical"
                                            [(ngModel)]="rightPanelConfig.Alignment.Vertical" [clearOnBackspace]="false"
                                            appendTo="body" placeholder="Select vertical alignment"></ng-select>
                                    </pos-form-field>
                                </pos-column>

                                <pos-column>
                                    <pos-form-field label="Height" inputStyle="col-6 row row-cols-2 mx-0">
                                        <input posInput name="rightPanelHeight" type="number"
                                            class="form-control col-120" [(ngModel)]="rightPanelConfig.Height" />
                                        <label class="col-30 edit-form__section__label"> px </label>
                                    </pos-form-field>
                                </pos-column>
                            </pos-row>
                            <pos-row class="pos-title-content" colLg="6">
                                <pos-column>
                                    <pos-form-field label="Radius" inputStyle="col-6 row row-cols-2 mx-0">
                                        <input posInput name="rightPanelRadius" type="number"
                                            class="form-control col-120" [(ngModel)]="rightPanelConfig.Radius" />
                                        <label class="col-30 edit-form__section__label"> px </label>
                                    </pos-form-field>
                                </pos-column>

                                <pos-column>
                                    <pos-form-field label="Opacity">
                                        <pos-row class="mx-0">
                                            <pos-column xs="11" class="ps-0">
                                                <ngx-slider [(value)]="rightPanelConfig.Opacity"
                                                    [options]="options"></ngx-slider>
                                            </pos-column>
                                            <pos-column xs="1" class="p-0">
                                                <span class="badge mt-pix-2 volume-circle">{{
                                                    rightPanelConfig.Opacity
                                                    ?
                                                    (rightPanelConfig.Opacity * 100) :
                                                    0 }}</span>
                                            </pos-column>
                                        </pos-row>
                                    </pos-form-field>
                                </pos-column>
                            </pos-row>
                            <div class="section-border">
                                <h3 class="setting-title mt-pix-5">Header</h3>
                            </div>
                            <pos-row class="pos-title-content" colLg="6">
                                <pos-column>
                                    <pos-form-field label="Height" inputStyle="col-6 row row-cols-2 mx-0">
                                        <input posInput name="rightPanelHeaderHeight" type="number"
                                            class="form-control col-120" [(ngModel)]="rightPanelConfig.Header.Height" />
                                        <label class="col-30 edit-form__section__label"> px </label>
                                    </pos-form-field>
                                </pos-column>

                                <pos-column>
                                    <pos-form-field label="Title" inputStyle="col-6">
                                        <input posInput name="rightPanelHeaderTitle" type="text" class="form-control"
                                            [(ngModel)]="rightPanelConfig.Header.Title" />
                                    </pos-form-field>
                                </pos-column>

                                <pos-column sm="6" xs="12">
                                    <pos-form-field label="Background Color">
                                        <pos-color-picker posInput name="rightPanelHeaderBackgroundColor"
                                            cpPosition="top"
                                            [(ngModel)]="rightPanelConfig.Header.BackgroundColor"></pos-color-picker>
                                    </pos-form-field>
                                </pos-column>

                                <pos-column sm="6" xs="12">
                                    <pos-form-field label="Font Color">
                                        <pos-color-picker posInput name="rightPanelHeaderFontColor" cpPosition="top"
                                            [(ngModel)]="rightPanelConfig.Header.FontColor"></pos-color-picker>
                                    </pos-form-field>
                                </pos-column>

                                <pos-column sm="6" xs="12">
                                    <pos-form-field label="Font size">
                                        <input posInput name="fontSize" #fontSize="ngModel" class="form-control col-120"
                                            [(ngModel)]="rightPanelConfig.Header.FontSize" />
                                    </pos-form-field>
                                </pos-column>

                                <pos-column sm="6" xs="12"
                                    *ngIf="kioskScreen.TemplateType == templateTypes.WelcomeScreenLayout">
                                    <pos-form-field label="Image" inputStyle="col-12">
                                        <pos-row>
                                            <pos-column md="8" xs="9">
                                                <input posInput type="file" id="headerImageInput"
                                                    name="headerImageInput" class="form-control"
                                                    accept=".png,.jpg,.jpeg,.gif,.bmp" ngModel
                                                    (change)="handleFileInput($event.target.files, 'headerImage', 'headerImageInput')" />
                                            </pos-column>
                                            <pos-column md="1" xs="3" class=" p-0 col-50 mt-pix-n1"
                                                [hidden]="!rightPanelConfig.Header.Image">
                                                <img #headerImage id="headerImage" alt="" class="col-40" height="40"
                                                    src="{{imageRootPath}}{{rightPanelConfig.Header.Image}}" />
                                                <div class="relative mt-pix-n7 float-right"
                                                    (click)="removeIcon('headerImageInput'); rightPanelConfig.Header.Image = null;">
                                                    <pos-icon [icon]="icons.times"
                                                        class="pointer ms-pix-n7 remove-icon-badge"></pos-icon>
                                                </div>
                                            </pos-column>
                                        </pos-row>
                                    </pos-form-field>
                                </pos-column>

                                <pos-column *ngIf="kioskScreen.TemplateType == templateTypes.WelcomeScreenLayout">
                                    <pos-form-field label="Image Height" inputStyle="col-6 row row-cols-2 mx-0">
                                        <input posInput name="rightPanelImageHeight" type="number"
                                            class="form-control col-120"
                                            [(ngModel)]="rightPanelConfig.Header.ImageHeight" />
                                        <label class="col-30 edit-form__section__label"> px </label>
                                    </pos-form-field>
                                </pos-column>

                                <pos-column *ngIf="kioskScreen.TemplateType == templateTypes.WelcomeScreenLayout">
                                    <pos-form-field label="Image Radius" inputStyle="col-6 row row-cols-2 mx-0">
                                        <input posInput name="rightPanelImageRadius" type="number"
                                            class="form-control col-120"
                                            [(ngModel)]="rightPanelConfig.Header.ImageRadius" />
                                        <label class="col-30 edit-form__section__label"> px </label>
                                    </pos-form-field>
                                </pos-column>
                            </pos-row>
                        </div>
                    </div>
                </div>
                <div [hidden]="selectedTab != tabList.Allergy" class="col-12 pt-pix-5"
                    *ngIf="kioskScreen.TemplateType == templateTypes.AllergensSelectionScreenLayout || kioskScreen.TemplateType == templateTypes.AllergensVerifyScreenLayout">
                    <pos-row colLg="6" colXs="12">
                        <pos-column xs="12"
                            *ngIf="kioskScreen.TemplateType == templateTypes.AllergensSelectionScreenLayout || kioskScreen.TemplateType == templateTypes.AllergensVerifyScreenLayout">
                            <pos-form-field label="Title" inputStyle="col-lg-8 col-12">
                                <input posInput name="allergenTitle" type="text" class="form-control"
                                    [(ngModel)]="screenConfig.Title" />
                            </pos-form-field>
                        </pos-column>
                        <pos-column xs="12"
                            *ngIf="kioskScreen.TemplateType == templateTypes.AllergensVerifyScreenLayout">
                            <pos-form-field label="Subtitle" inputStyle="col-lg-8 col-12">
                                <input posInput name="allergenSubtitle" type="text" class="form-control"
                                    [(ngModel)]="screenConfig.Subtitle" />
                            </pos-form-field>
                        </pos-column>
                        <pos-column xs="12"
                            *ngIf="kioskScreen.TemplateType == templateTypes.AllergensVerifyScreenLayout">
                            <pos-form-field label="Allergen Note" inputStyle="col-12">
                                <ckeditor posInput [(ngModel)]="screenConfig.AllergenNote" #ckeditor
                                    [config]="toolbarConfig" [editorUrl]="'assets/ckeditor/ckeditor.js'"
                                    name="allergenNote">
                                </ckeditor>
                            </pos-form-field>
                        </pos-column>

                        <pos-column>
                            <pos-form-field label="Background Color">
                                <pos-color-picker posInput name="buttonBackgroundColor"
                                    [(ngModel)]="screenConfig.ButtonBackgroundColor"></pos-color-picker>
                            </pos-form-field>
                        </pos-column>

                        <pos-column>
                            <pos-form-field label="Font Color">
                                <pos-color-picker posInput name="buttonFontColor"
                                    [(ngModel)]="screenConfig.ButtonFontColor"></pos-color-picker>
                            </pos-form-field>
                        </pos-column>

                        <pos-column *ngIf="kioskScreen.TemplateType == templateTypes.AllergensVerifyScreenLayout">
                            <pos-form-field>
                                <p-checkbox posInput name="isShowImage" #isShowImage="ngModel"
                                    [(ngModel)]="screenConfig.IsShowImage" binary="true" label="Show Image">
                                </p-checkbox>
                            </pos-form-field>
                        </pos-column>
                    </pos-row>
                </div>
                <div [hidden]="selectedTab != tabList.AllergenButtons" class="col-12 pt-pix-5"
                    *ngIf="kioskScreen.TemplateType == templateTypes.AllergensInquiryLayout">
                    <pos-column>
                        <pos-form-field label="Vertical" inputStyle="col-6">
                            <ng-select posInput name="buttonsVerticalAlignment" bindLabel="Name" bindValue="Value"
                                [items]="alignItemsVertical" [(ngModel)]="screenConfig.VerticalAlignment"
                                [clearOnBackspace]="false" appendTo="body"
                                placeholder="Select vertical alignment"></ng-select>
                        </pos-form-field>
                    </pos-column>
                    <div class="col-12 p-0" *ngIf="screenConfig">
                        <div class="ps-3">
                            <div>
                                <h3 class="setting-title mt-pix-5">Confirm Button</h3>
                            </div>
                            <pos-row class="pos-title-content" colLg="6">
                                <pos-column xs="12">
                                    <pos-form-field label="Button Text" inputStyle="col-12">
                                        <ckeditor posInput [(ngModel)]="screenConfig.ConfirmButtonText" #ckeditor
                                            [config]="toolbarConfig" [editorUrl]="'assets/ckeditor/ckeditor.js'"
                                            name="confirmButtonText">
                                        </ckeditor>
                                    </pos-form-field>
                                </pos-column>

                                <pos-column sm="6" xs="12">
                                    <pos-form-field label="Background Color">
                                        <pos-color-picker posInput name="confirmButtonBackgroundColor"
                                            [(ngModel)]="screenConfig.ConfirmButtonBackgroundColor"></pos-color-picker>
                                    </pos-form-field>
                                </pos-column>

                                <pos-column sm="6" xs="12">
                                    <pos-form-field label="Font Color">
                                        <pos-color-picker posInput name="confirmButtonFontColor"
                                            [(ngModel)]="screenConfig.ConfirmButtonFontColor"></pos-color-picker>
                                    </pos-form-field>
                                </pos-column>

                                <pos-column>
                                    <pos-form-field label="Width" inputStyle="col-6 row row-cols-2 mx-0">
                                        <input posInput name="confirmButtonWidth" type="number"
                                            class="form-control col-120"
                                            [(ngModel)]="screenConfig.ConfirmButtonWidth" />
                                        <label class="col-30 edit-form__section__label"> px </label>
                                    </pos-form-field>
                                </pos-column>

                                <pos-column>
                                    <pos-form-field label="Opacity">
                                        <pos-row class="mx-0">
                                            <pos-column xs="11" class="ps-0">
                                                <ngx-slider [(value)]="screenConfig.ConfirmButtonOpacity"
                                                    [options]="options"></ngx-slider>
                                            </pos-column>
                                            <pos-column xs="1" class="p-0">
                                                <span class="badge mt-pix-2 volume-circle">{{
                                                    screenConfig.ConfirmButtonOpacity
                                                    ?
                                                    (screenConfig.ConfirmButtonOpacity * 100) :
                                                    0 }}</span>
                                            </pos-column>
                                        </pos-row>
                                    </pos-form-field>
                                </pos-column>
                            </pos-row>
                        </div>
                        <div class="ps-3">
                            <div>
                                <h3 class="setting-title mt-pix-5">Reject Button</h3>
                            </div>
                            <pos-row class="pos-title-content" colLg="6">
                                <pos-column xs="12">
                                    <pos-form-field label="Button Text" inputStyle="col-12">
                                        <ckeditor posInput [(ngModel)]="screenConfig.RejectButtonText" #ckeditor
                                            [config]="toolbarConfig" [editorUrl]="'assets/ckeditor/ckeditor.js'"
                                            name="rejectButtonText">
                                        </ckeditor>
                                    </pos-form-field>
                                </pos-column>

                                <pos-column sm="6" xs="12">
                                    <pos-form-field label="Background Color">
                                        <pos-color-picker posInput name="rejectButtonBackgroundColor" cpPosition="top"
                                            [(ngModel)]="screenConfig.RejectButtonBackgroundColor"></pos-color-picker>
                                    </pos-form-field>
                                </pos-column>

                                <pos-column sm="6" xs="12">
                                    <pos-form-field label="Font Color">
                                        <pos-color-picker posInput name="rejectButtonFontColor" cpPosition="top"
                                            [(ngModel)]="screenConfig.RejectButtonFontColor"></pos-color-picker>
                                    </pos-form-field>
                                </pos-column>

                                <pos-column>
                                    <pos-form-field label="Width" inputStyle="col-6 row row-cols-2 mx-0">
                                        <input posInput name="rejectButtonWidth" type="number"
                                            class="form-control col-120" [(ngModel)]="screenConfig.RejectButtonWidth" />
                                        <label class="col-30 edit-form__section__label"> px </label>
                                    </pos-form-field>
                                </pos-column>

                                <pos-column>
                                    <pos-form-field label="Opacity">
                                        <pos-row class="mx-0">
                                            <pos-column xs="11" class="ps-0">
                                                <ngx-slider [(value)]="screenConfig.RejectButtonOpacity"
                                                    [options]="options"></ngx-slider>
                                            </pos-column>
                                            <pos-column xs="1" class="p-0">
                                                <span class="badge mt-pix-2 volume-circle">{{
                                                    screenConfig.RejectButtonOpacity
                                                    ?
                                                    (screenConfig.RejectButtonOpacity * 100) :
                                                    0 }}</span>
                                            </pos-column>
                                        </pos-row>
                                    </pos-form-field>
                                </pos-column>
                            </pos-row>
                        </div>
                    </div>
                </div>
                <div [hidden]="selectedTab != tabList.Layout" class="col-12 pt-pix-5" *ngIf="createdShakeScreenConfig">
                    <div>
                        <h3 class="setting-title mt-pix-5">Shake Detail</h3>
                    </div>
                    <pos-row class="pos-title-content" colLg="6">
                        <pos-column>
                            <pos-form-field label="Container Width">
                                <input posInput name="detailContainerWidth" type="text" class="form-control col-120"
                                    [(ngModel)]="createdShakeScreenConfig.ShakeDetails.ContainerWidth" />
                            </pos-form-field>
                        </pos-column>
                        <pos-column>
                            <pos-form-field label="Horizontal" inputStyle="col-6">
                                <ng-select posInput name="detailHorizontal" bindLabel="Name" bindValue="Value"
                                    [items]="horizontalAlignmentsForFlex"
                                    [(ngModel)]="createdShakeScreenConfig.ShakeDetails.Alignment.Horizontal"
                                    [clearOnBackspace]="false" appendTo="body"
                                    placeholder="Select horizontal alignment"></ng-select>
                            </pos-form-field>
                        </pos-column>
                        <pos-column>
                            <pos-form-field label="Vertical" inputStyle="col-6">
                                <ng-select posInput name="detailVertical" bindLabel="Name" bindValue="Value"
                                    [items]="alignItemsVertical"
                                    [(ngModel)]="createdShakeScreenConfig.ShakeDetails.Alignment.Vertical"
                                    [clearOnBackspace]="false" appendTo="body"
                                    placeholder="Select vertical alignment"></ng-select>
                            </pos-form-field>
                        </pos-column>
                        <pos-column>
                            <pos-form-field label="Vertical Position" inputStyle="col-6 row row-cols-2 mx-0">
                                <input posInput name="detailVerticalPosition" type="number" class="form-control col-120"
                                    [(ngModel)]="createdShakeScreenConfig.ShakeDetails.VerticalPosition" />
                                <label class="col-30 edit-form__section__label"> px </label>
                            </pos-form-field>
                        </pos-column>

                        <pos-column sm="6" xs="12">
                            <pos-form-field label="Detail Background Color">
                                <pos-color-picker posInput name="shakeDetailBackgroundColor" cpPosition="top"
                                    [(ngModel)]="createdShakeScreenConfig.ShakeDetails.BackgroundColor"></pos-color-picker>
                            </pos-form-field>
                        </pos-column>

                        <pos-column sm="6" xs="12">
                            <pos-form-field label="Detail Font Color">
                                <pos-color-picker posInput name="shakeDetailFontColor" cpPosition="top"
                                    [(ngModel)]="createdShakeScreenConfig.ShakeDetails.Color"></pos-color-picker>
                            </pos-form-field>
                        </pos-column>

                        <pos-column sm="6" xs="12">
                            <pos-form-field label="Primary Flavor Background Color">
                                <pos-color-picker posInput name="primaryFlavorBackgroundColor" cpPosition="top"
                                    [(ngModel)]="createdShakeScreenConfig.ShakeDetails.PrimaryFlavorBackgroundColor"></pos-color-picker>
                            </pos-form-field>
                        </pos-column>

                        <pos-column sm="6" xs="12">
                            <pos-form-field label="Primary Flavor Font Color">
                                <pos-color-picker posInput name="primaryFlavorFontColor" cpPosition="top"
                                    [(ngModel)]="createdShakeScreenConfig.ShakeDetails.PrimaryFlavorColor"></pos-color-picker>
                            </pos-form-field>
                        </pos-column>
                    </pos-row>
                    <div class="section-border">
                        <h3 class="setting-title mt-pix-5">Buttons</h3>
                    </div>
                    <pos-row class="pos-title-content" colLg="6">
                        <pos-column>
                            <pos-form-field label="Container Width">
                                <input posInput name="buttonContainerWidth" type="text" class="form-control col-120"
                                    [(ngModel)]="createdShakeScreenConfig.Buttons.ContainerWidth" />
                            </pos-form-field>
                        </pos-column>
                        <pos-column>
                            <pos-form-field label="Horizontal" inputStyle="col-6">
                                <ng-select posInput name="buttonHorizontal" bindLabel="Name" bindValue="Value"
                                    [items]="horizontalAlignmentsForFlex"
                                    [(ngModel)]="createdShakeScreenConfig.Buttons.Alignment.Horizontal"
                                    [clearOnBackspace]="false" appendTo="body"
                                    placeholder="Select horizontal alignment"></ng-select>
                            </pos-form-field>
                        </pos-column>

                        <pos-column>
                            <pos-form-field label="Vertical" inputStyle="col-6">
                                <ng-select posInput name="buttonVertical" bindLabel="Name" bindValue="Value"
                                    [items]="alignItemsVertical"
                                    [(ngModel)]="createdShakeScreenConfig.Buttons.Alignment.Vertical"
                                    [clearOnBackspace]="false" appendTo="body"
                                    placeholder="Select vertical alignment"></ng-select>
                            </pos-form-field>
                        </pos-column>

                        <pos-column>
                            <pos-form-field label="Vertical Position" inputStyle="col-6 row row-cols-2 mx-0">
                                <input posInput name="verticalPosition" type="number" class="form-control col-120"
                                    [(ngModel)]="createdShakeScreenConfig.Buttons.VerticalPosition" />
                                <label class="col-30 edit-form__section__label"> px </label>
                            </pos-form-field>
                        </pos-column>

                        <pos-column>
                            <pos-form-field label="Height" inputStyle="col-6 row row-cols-2 mx-0">
                                <input posInput name="buttonHeight" type="number" class="form-control col-120"
                                    [(ngModel)]="createdShakeScreenConfig.Buttons.Height" />
                                <label class="col-30 edit-form__section__label"> px </label>
                            </pos-form-field>
                        </pos-column>

                        <pos-column>
                            <pos-form-field label="Width" inputStyle="col-6 row row-cols-2 mx-0">
                                <input posInput name="buttonWidth" type="number" class="form-control col-120"
                                    [(ngModel)]="createdShakeScreenConfig.Buttons.Width" />
                                <label class="col-30 edit-form__section__label"> px </label>
                            </pos-form-field>
                        </pos-column>

                        <pos-column>
                            <pos-form-field label="Radius" inputStyle="col-6 row row-cols-2 mx-0">
                                <input posInput name="buttonRadius" type="number" class="form-control col-120"
                                    [(ngModel)]="createdShakeScreenConfig.Buttons.Radius" />
                                <label class="col-30 edit-form__section__label"> px </label>
                            </pos-form-field>
                        </pos-column>

                        <pos-column sm="6" xs="12">
                            <pos-form-field label="Font size">
                                <input posInput name="fontSize" #fontSize="ngModel" class="form-control col-120"
                                    [(ngModel)]="createdShakeScreenConfig.Buttons.FontSize" />
                            </pos-form-field>
                        </pos-column>

                    </pos-row>
                    <div class="section-border">
                        <h3 class="setting-title mt-pix-5">Add to Cart</h3>
                    </div>
                    <pos-row class="pos-title-content" colLg="6">
                        <pos-column>
                            <pos-form-field label="Background Color">
                                <pos-color-picker posInput name="addToCartBackgroundColor" cpPosition="top"
                                    [(ngModel)]="createdShakeScreenConfig.AddToCart.BackgroundColor"></pos-color-picker>
                            </pos-form-field>
                        </pos-column>

                        <pos-column>
                            <pos-form-field label="Font Color">
                                <pos-color-picker posInput name="addToCartFontColor" cpPosition="top"
                                    [(ngModel)]="createdShakeScreenConfig.AddToCart.Color"></pos-color-picker>
                            </pos-form-field>
                        </pos-column>
                    </pos-row>
                    <div class="section-border">
                        <h3 class="setting-title mt-pix-5">Try Again</h3>
                    </div>
                    <pos-row class="pos-title-content" colLg="6">
                        <pos-column>
                            <pos-form-field label="Background Color">
                                <pos-color-picker posInput name="tryAgainBackgroundColor" cpPosition="top"
                                    [(ngModel)]="createdShakeScreenConfig.TryAgain.BackgroundColor"></pos-color-picker>
                            </pos-form-field>
                        </pos-column>

                        <pos-column>
                            <pos-form-field label="Font Color">
                                <pos-color-picker posInput name="tryAgainFontColor" cpPosition="top"
                                    [(ngModel)]="createdShakeScreenConfig.TryAgain.Color"></pos-color-picker>
                            </pos-form-field>
                        </pos-column>
                    </pos-row>

                </div>
            </div>
        </div>
        <div class="col-12 action-button-container page-footer">
            <button type="submit" class="btn-primary btn-lg btn btn-save pos-button">Save</button>
            <button type="button" class="btn-secondary btn-lg btn btn-cancel-modal pos-button"
                (click)="onCancel()">Cancel</button>
        </div>
    </form>
</pos-edit-wrapper>
