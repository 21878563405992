<div>
  <div>
    <h4 class="modal-content__heading">Dietary Warnings</h4>
  </div>
  <div class="pos-modal-body pt-pix-15">
    <table class="table mb-0">
      <thead>
        <tr>
          <th style="width: 30%">Product</th>
          <th style="width: 25%">Recipe</th>
          <th style="width: 65px">Warning</th>
          <th style="width: 15%"></th>
          <th style="width: 140px" *ngIf="allowOverridesOnDietaryRestrictions"></th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let warning of orderDietaryRestrictions; let i = index;">
          <tr>
            <td colspan="5" class="bold border-0">{{warning.Product}}</td>
          </tr>
          <ng-container *ngFor="let component of warning.GroupedComponents | keyvalue; let lastGroup = last;">
            <tr>
              <td colspan="5" *ngIf="component.value[0]?.SalesProductId != warning.SalesProductId"
                class="border-0 ps-pix-15-important font-weight-600">
                {{component.value[0]?.SalesProduct}}</td>
            </tr>
            <tr *ngFor="let item of component.value; let last = last;" [ngClass]="{'no-border': !last || (lastGroup && (i + 1) == orderDietaryRestrictions?.length)}">
              <td style="width: 30%"></td>
              <td style="width: 25%">{{item.InventoryProductFriendlyName ? item.InventoryProductFriendlyName : item.InventoryProduct}}</td>
              <td style="width: 65px">
                <span [ngClass]="{'red':!item.IsOverridable, 'dark-orange': item.IsOverridable}">
                  {{item.DietaryWarning}}
                </span>
              </td>
              <td style="width: 15%" [ngStyle]="{'color': item.DietaryWarningTypeId == 1 ? 'orangered' : 'green' }">{{item.DietaryWarningTypeId == 1 ? 'Allergen' : 'Environmental'}}</td>
              <td style="width: 140px" class="text-center" *ngIf="allowOverridesOnDietaryRestrictions">
                <button type="button" [ngClass]="{'btn-success white': item.IsAdded, 'btn-secondary': !item.IsAdded }"
                  (click)="item.IsAdded = !item.IsAdded; checkIfAllWarningsAreOverridden();" class="btn"
                  *ngIf="(allowOverridesOnDietaryRestrictions && item.IsOverridable) || item.DietaryWarningTypeId == 2">
                  <pos-icon [icon]="icons.checkCircleSolid" class="pe-pix-7 m-l-5" *ngIf="item.IsAdded">
                  </pos-icon>
                  <i *ngIf="item.IsAdded" class="pe-pix-5"></i>{{item.IsAdded ? "Added" : "Add Anyway"}}
                </button>
                <button type="button" class="btn btn-secondary" disabled *ngIf="!item.IsOverridable && item.DietaryWarningTypeId == 1">
                  Can't Override
                </button>
              </td>
            </tr>
          </ng-container>
        </ng-container>
      </tbody>
    </table>
  </div>
  <div class="col-12 action-button-container popup-footer pb-pix-10">
    <button class="btn btn-lg btn-primary btn-save pos-button" [disabled]="!isAllWarningOverrided" (click)="onSubmit()"
      type="button">Add Product</button>
    <button class="btn btn-lg btn-secondary btn-cancel-modal pos-button" (click)="onClose()" type="button">Don't
      Add</button>
  </div>
  <div class="clearfix"></div>
</div>
