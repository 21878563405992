<div class="page-content ">
    <div class="tab-content">
        <div class="tab-pane fade in active show">
            <div class="col-md-12 pb-pix-5">
                <a class="close" (click)="onCancel()">×</a>
                <div class="row">
                    <div class="col-md-10 col-xs-9">
                        <h3 class="page-content__header">
                            {{this.id? 'Edit': 'Add'}} Button History{{this.id ? ': ' + buttonName : ''}}
                        </h3>
                    </div>
                </div>
                <div class="row">
                    <form #buttonHistoryForm="ngForm" (ngSubmit)="saveButtonHistory(buttonHistoryForm.valid)"
                        autocomplete="off">
                        <div class="col-md-12 col-xs-12 ">
                            <div class="edit-form__section">
                                <h3 class="edit-form__section__heading">
                                    Button History
                                </h3>
                                <div class="row pt-pix-10">
                                    <div class="col-lg-1 col-md-1 col-sm-1 label-section required col-150 pe-0">
                                        <label>
                                            Button Text
                                        </label>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-sm-5"
                                        [ngClass]="{'has-error': (buttonText.invalid && buttonHistoryForm.submitted)}">
                                        <input class="form-control" name="buttonText" #buttonText="ngModel"
                                            [(ngModel)]="buttonHistory.ButtonText" required>
                                        <span *ngIf="buttonText.invalid && buttonHistoryForm.submitted">
                                            <label class="control-label" *ngIf="buttonText.errors?.required">Please
                                                enter button text.</label>
                                        </span>
                                    </div>
                                </div>

                                <div class="row pt-pix-10">
                                    <div class="col-lg-1 col-md-1 col-sm-1 label-section  col-150 pe-0">
                                        <label>
                                            Kitchen Text
                                        </label>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-sm-5">
                                        <input class="form-control" name="internalText" #internalText="ngModel"
                                            [(ngModel)]="buttonHistory.InternalText">
                                    </div>
                                </div>

                                <div class="row pt-pix-10">
                                    <div class="col-lg-1 col-md-1 col-sm-1 label-section required col-150 pe-0">
                                        <label>
                                            Receipt Text
                                        </label>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-sm-5"
                                        [ngClass]="{'has-error': (receiptText.invalid && buttonHistoryForm.submitted)}">
                                        <input class="form-control" name="receiptText" #receiptText="ngModel"
                                            [(ngModel)]="buttonHistory.ReceiptText" required>
                                        <span *ngIf="buttonText.invalid && buttonHistoryForm.submitted">
                                            <label class="control-label" *ngIf="receiptText.errors?.required">Please
                                                enter receipt text.</label>
                                        </span>
                                    </div>
                                </div>

                                <div class="row pt-pix-10">
                                    <div class="col-lg-1 col-md-1 col-sm-1 label-section col-150 pe-0"
                                        [ngClass]="{'required':buttonHistory.ButtonType == 'MainProduct'}">
                                        <label>
                                            Product
                                        </label>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-sm-5"
                                        [ngClass]="{'has-error': (salesProduct.invalid && buttonHistoryForm.submitted)}">
                                        <ng-select posInput appendTo="body" [items]="salesProductList" bindLabel="Name"
                                            bindValue="id"
                                            [ngClass]="{'select-disabled': buttonHistory.ButtonType == 'NavigationOnly' || buttonHistory.ButtonType == 'Functional'}"
                                            placeholder="Select product" #salesProduct="ngModel"
                                            [(ngModel)]="buttonHistory.SalesProductId" name="salesProduct"
                                            [required]="buttonHistory.ButtonType == 'MainProduct'">
                                        </ng-select>
                                        <span *ngIf="salesProduct.invalid && buttonHistoryForm.submitted">
                                            <label class="control-label" *ngIf="salesProduct.errors?.required">Please
                                                select product.</label>
                                        </span>
                                    </div>
                                </div>

                                <div class="row pt-pix-10">
                                    <div class="col-lg-1 col-md-1 col-sm-1 label-section  col-150 pe-0">

                                    </div>
                                    <div class="col-md-4">
                                        <label>
                                            <p-checkbox name="buttonToggle" #buttonToggle="ngModel"
                                                [(ngModel)]="buttonHistory.Toggle" binary="true">
                                            </p-checkbox> Toggle
                                        </label>
                                    </div>
                                </div>

                                <div *ngIf="!id">
                                    <div class="row pt-pix-10">
                                        <div class="col-lg-1 col-md-1 col-sm-1 label-section required col-150 pe-0">
                                            <label>
                                                Date
                                            </label>
                                        </div>
                                        <div class="col-lg-2 col-md-2 col-sm-4"
                                            [ngClass]="{'has-error': (dateEdited.invalid && buttonHistoryForm.submitted)}">
                                            <p-calendar [(ngModel)]="buttonHistory.DateAdded" [showIcon]="true"
                                                [dateFormat]="dateFormat" [readonlyInput]="true" name="dateEdited"
                                                yearNavigator="true" [showTime]="true" [hourFormat]="hourFormat"
                                                [inputStyle]="{'width':'unset','display':'unset'}" [maxDate]="today"
                                                inputStyleClass="form-control" yearRange="2000:2099"
                                                #dateEdited="ngModel" [monthNavigator]=true [required]="true"
                                                [ngClass]="{'has-error': (dateEdited.invalid && buttonHistoryForm.submitted)}">
                                            </p-calendar>
                                            <span *ngIf="dateEdited.invalid && dateEdited.submitted">
                                                <label class="control-label" *ngIf="dateEdited.errors?.required">Please
                                                    enter edit date.</label>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="clearfix"></div>
                            <div class="col-12 action-button-container">
                                <button type="submit" class="btn-primary btn-lg btn normal me-pix-5 pos-button">Save </button>
                                <button type="button" class="btn-secondary btn-lg btn normal pos-button"
                                    (click)="onCancel()">Cancel</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
