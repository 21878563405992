<pos-edit-wrapper heading="Quick Search" (close)="onCancel()">
    <form #formQuickSearch="ngForm" autocomplete="off">
        <div class="col-12 pos-modal-body px-pix-5 screen-scroll"
            style="height:80vh;overflow-y:auto;overflow-x:hidden;">
            <div class="row mx-0 col-12 pt-pix-10 pb-pix-5">
                <div class="col-md-3 col-sm-1"></div>
                <div class="col-md-2 col-sm-3 p-0">
                    <h3 class="pti-5 pbi-0">Inventory Product</h3>
                </div>
                <div class="col-md-4 col-sm-7 col-12 p-0">
                    <ng-select posInput appendTo="body" [items]="productList" [clearable]="false" bindLabel="Name" bindValue="id"
                        (change)="getInventoryDetails()" placeholder="Select product" [(ngModel)]="inventoryProductId"
                        id="inventoryProduct" name="inventoryProduct" #inventoryProductQuickSearch>
                    </ng-select>
                </div>
            </div>

            <div class="mt-pix-10" *ngIf="inventoryProductDetails && inventoryProductDetails.id">
                <!--Inventory Info Start-->
                <pos-row class="border-1 mx-0 pb-pix-5 mb-pix-10">
                    <pos-column xs="12" class="text-align-center"
                        [ngStyle]="{'color': getFontColor(defaultBGColor), 'background': defaultBGColor}">
                        <h1> {{inventoryProductDetails.name}} </h1>
                    </pos-column>

                    <pos-column md="6" xs="12" class="mt-pix-5 p-0">
                        <pos-row class="mx-pix-5">
                            <pos-column lg="4" xs="6">
                                <h2 class="theme-separator">Vendor</h2>
                            </pos-column>
                            <pos-column lg="8" xs="6" class="ps-pix-10">
                                <h2> {{inventoryProductDetails.VendorName}}</h2>
                            </pos-column>
                        </pos-row>
                    </pos-column>

                    <pos-column md="6" xs="12" class="mt-pix-5 p-0">
                        <pos-row class="mx-pix-5">
                            <pos-column lg="4" xs="6">
                                <h2 class="theme-separator">Current Qty</h2>
                            </pos-column>
                            <pos-column lg="4" xs="3" class="ps-pix-10">
                                <h2> {{inventoryProductDetails.CalculatedQty}}</h2>
                            </pos-column>
                            <pos-column lg="4" sm="3" xs="12">
                                <button class="float-end btn btn-info white pos-button no-wrap"
                                    *ngIf="!inventoryProductDetails.IsSkipInventory" type="button"
                                    (click)="getInventoryForLogging()">Log Inventory</button>
                            </pos-column>
                        </pos-row>
                    </pos-column>

                    <pos-column md="6" xs="12" class="mt-pix-5 p-0">
                        <pos-row class="mx-pix-5">
                            <pos-column lg="4" xs="6">
                                <h2 class="theme-separator">Unit</h2>
                            </pos-column>
                            <pos-column lg="8" xs="6" class="ps-pix-10">
                                <h2> {{inventoryProductDetails.UnitName}}</h2>
                            </pos-column>
                        </pos-row>
                    </pos-column>

                    <pos-column md="6" xs="12" class="mt-pix-5 p-0">
                        <pos-row class="mx-pix-5">
                            <pos-column lg="4" xs="6">
                                <h2 class="theme-separator">Last Logged Qty</h2>
                            </pos-column>
                            <pos-column lg="8" xs="6" class="ps-pix-10">
                                <h2> {{inventoryProductDetails.LastLoggedQty}}
                                    <span class="font-16">on</span>
                                    {{inventoryProductDetails.LastLoggedDate | tark_date_time}}
                                </h2>
                            </pos-column>
                        </pos-row>
                    </pos-column>

                    <pos-column md="6" xs="12" class="mt-pix-5 p-0">
                        <pos-row class="mx-pix-5">
                            <pos-column lg="4" xs="6">
                                <h2 class="theme-separator">Subcategory</h2>
                            </pos-column>
                            <pos-column lg="8" xs="6" class="ps-pix-10">
                                <h2> {{inventoryProductDetails.SubCategory}}</h2>
                            </pos-column>
                        </pos-row>
                    </pos-column>

                    <pos-column md="6" xs="12" class="mt-pix-5 p-0">
                        <pos-row class="mx-pix-5">
                            <pos-column lg="4" xs="6">
                                <h2 class="theme-separator">Price / {{ inventoryProductDetails.UnitName }}</h2>
                            </pos-column>
                            <pos-column lg="8" xs="6" class="ps-pix-10">
                                <h2> {{inventoryProductDetails.price_unit | tark_currency }} </h2>
                            </pos-column>
                        </pos-row>
                    </pos-column>

                    <pos-column xs="12" class="mt-pix-5 p-0" *ngIf="inventoryProductDetails.unit_details">
                        <pos-row class="mx-pix-5">
                            <pos-column lg="2" xs="6">
                                <h2 class="theme-separator">Product Details</h2>
                            </pos-column>
                            <pos-column lg="10" xs="6" class="ps-pix-10">
                                <h2 [innerText]="inventoryProductDetails.unit_details"></h2>
                            </pos-column>
                        </pos-row>
                    </pos-column>

                    <pos-column xs="12" class="mt-pix-5 p-0" *ngIf="inventoryProductDetails.ReceivingInstructions">
                        <pos-row class="mx-pix-5">
                            <pos-column lg="2" xs="6">
                                <h2 class="theme-separator">Receiving Instructions</h2>
                            </pos-column>
                            <pos-column lg="10" xs="6" class="ps-pix-10">
                                <h2 [innerText]="inventoryProductDetails.ReceivingInstructions"></h2>
                            </pos-column>
                        </pos-row>
                    </pos-column>

                </pos-row>
                <!--Inventory Info End-->

                <!-- Zone, Bin Start-->
                <pos-row colMd="6" colXs="12" colStyle="mb-pix-10" class="mx-0">
                    <pos-column class="p-0">
                        <!--Primary Zone Info Start-->
                        <pos-row colXs="12" class="border-1 mx-0 pb-pix-5 mb-pix-10" *ngIf="inventoryProductDetails?.PrimaryZoneName">
                            <pos-column class="text-align-center p-0">
                                <h1
                                    [ngStyle]="{'color': getFontColor(inventoryProductDetails.PrimaryZoneColor), 'background-color': inventoryProductDetails.PrimaryZoneColor}">
                                    Primary Zone</h1>
                            </pos-column>

                            <pos-column class="mt-pix-5 p-0">
                                <pos-row class="mx-pix-5">
                                    <pos-column lg="4" xs="6">
                                        <h2 class="theme-separator">Name</h2>
                                    </pos-column>
                                    <pos-column lg="8" xs="6" class="ps-pix-10">
                                        <h2> {{inventoryProductDetails.PrimaryZoneName}}</h2>
                                    </pos-column>
                                </pos-row>
                            </pos-column>

                            <pos-column class="mt-pix-5 p-0">
                                <pos-row class="mx-pix-5">
                                    <pos-column lg="4" xs="6">
                                        <h2 class="theme-separator">Code</h2>
                                    </pos-column>
                                    <pos-column lg="8" xs="6" class="ps-pix-10">
                                        <h2> {{inventoryProductDetails.PrimaryZoneCode}}</h2>
                                    </pos-column>
                                </pos-row>
                            </pos-column>
                            <pos-column class="mt-pix-5 p-0">
                                <pos-row class="mx-pix-5">
                                    <pos-column lg="4" xs="6">
                                        <h2 class="theme-separator">Label</h2>
                                    </pos-column>
                                    <pos-column lg="8" xs="6" class="ps-pix-10">
                                        <h2> {{inventoryProductDetails.PrimaryZoneLabel}}</h2>
                                    </pos-column>
                                </pos-row>
                            </pos-column>
                            <pos-column class="mt-pix-5 p-0">
                                <pos-row class="mx-pix-5">
                                    <pos-column lg="4" xs="6">
                                        <h2 class="theme-separator">Description</h2>
                                    </pos-column>
                                    <pos-column lg="8" xs="6" class="ps-pix-10">
                                        <h2> {{inventoryProductDetails.PrimaryZoneDescription}}</h2>
                                    </pos-column>
                                </pos-row>
                            </pos-column>
                        </pos-row>
                        <!--Primary Zone Info End-->

                        <!--Primary Bin Info Start-->
                        <pos-row colXs="12" class="border-1 mx-0 pb-pix-5 mb-pix-10" *ngIf="inventoryProductDetails?.PrimaryBinName">
                            <pos-column class="text-align-center p-0">
                                <h1
                                    [ngStyle]="{'color': getFontColor(inventoryProductDetails.PrimaryBinColor), 'background-color': inventoryProductDetails.PrimaryBinColor}">
                                    Primary Bin</h1>
                            </pos-column>

                            <pos-column class="mt-pix-5 p-0">
                                <pos-row class="mx-pix-5">
                                    <pos-column lg="4" xs="6">
                                        <h2 class="theme-separator">Name</h2>
                                    </pos-column>
                                    <pos-column lg="6" xs="2" class="ps-pix-10">
                                        <h2> {{inventoryProductDetails.PrimaryBinName}}</h2>
                                    </pos-column>
                                    <pos-column lg="2" xs="4" class="text-align-right">
                                        <button class="btn btn-warning white pos-button" (click)="triggerFlashLightOfPrimaryBin()"
                                            *ngIf="inventoryPrimaryBinLights.length">
                                            Flash
                                        </button>
                                    </pos-column>
                                </pos-row>
                            </pos-column>
                            <pos-column class="mt-pix-5 p-0">
                                <pos-row class="mx-pix-5">
                                    <pos-column lg="4" xs="6">
                                        <h2 class="theme-separator">Description</h2>
                                    </pos-column>
                                    <pos-column lg="8" xs="6" class="ps-pix-10">
                                        <h2> {{inventoryProductDetails.PrimaryBinDescription}}</h2>
                                    </pos-column>
                                </pos-row>
                            </pos-column>
                        </pos-row>
                        <!--Primary Bin Info End-->
                    </pos-column>

                    <pos-column class="pe-0 ps-pix-0 ps-pix-md-15">
                        <!--Secondary Zone Info Start-->
                        <pos-row colXs="12" class="border-1 mx-0 pb-pix-5 mb-pix-10" *ngIf="inventoryProductDetails?.SecondaryZoneName">
                            <pos-column class="text-align-center p-0">
                                <h1
                                    [ngStyle]="{'color': getFontColor(inventoryProductDetails.SecondaryZoneColor), 'background-color': inventoryProductDetails.SecondaryZoneColor}">
                                    Secondary Zone</h1>
                            </pos-column>

                            <pos-column class="mt-pix-5 p-0">
                                <pos-row class="mx-pix-5">
                                    <pos-column lg="4" xs="6">
                                        <h2 class="theme-separator">Name</h2>
                                    </pos-column>
                                    <pos-column lg="8" xs="6" class="ps-pix-10">
                                        <h2> {{inventoryProductDetails.SecondaryZoneName}}</h2>
                                    </pos-column>
                                </pos-row>
                            </pos-column>

                            <pos-column class="mt-pix-5 p-0">
                                <pos-row class="mx-pix-5">
                                    <pos-column lg="4" xs="6">
                                        <h2 class="theme-separator">Code</h2>
                                    </pos-column>
                                    <pos-column lg="8" xs="6" class="ps-pix-10">
                                        <h2> {{inventoryProductDetails.SecondaryZoneCode}}</h2>
                                    </pos-column>
                                </pos-row>
                            </pos-column>
                            <pos-column class="mt-pix-5 p-0">
                                <pos-row class="mx-pix-5">
                                    <pos-column lg="4" xs="6">
                                        <h2 class="theme-separator">Label</h2>
                                    </pos-column>
                                    <pos-column lg="8" xs="6" class="ps-pix-10">
                                        <h2> {{inventoryProductDetails.SecondaryZoneLabel}}</h2>
                                    </pos-column>
                                </pos-row>
                            </pos-column>
                            <pos-column class="mt-pix-5 p-0">
                                <pos-row class="mx-pix-5">
                                    <pos-column lg="4" xs="6">
                                        <h2 class="theme-separator">Description</h2>
                                    </pos-column>
                                    <pos-column lg="8" xs="6" class="ps-pix-10">
                                        <h2> {{inventoryProductDetails.SecondaryZoneDescription}}</h2>
                                    </pos-column>
                                </pos-row>
                            </pos-column>
                        </pos-row>
                        <!--Secondary Zone Info End-->

                        <!--Secondary Bin Info Start-->
                        <pos-row colXs="12" class="border-1 mx-0 pb-pix-5 mb-pix-10" *ngIf="inventoryProductDetails?.SecondaryBinName">
                            <pos-column class="text-align-center p-0">
                                <h1
                                    [ngStyle]="{'color': getFontColor(inventoryProductDetails.SecondaryBinColor), 'background-color': inventoryProductDetails.SecondaryBinColor}">
                                    Secondary Bin</h1>
                            </pos-column>

                            <pos-column class="mt-pix-5 p-0">
                                <pos-row class="mx-pix-5">
                                    <pos-column lg="4" xs="6">
                                        <h2 class="theme-separator">Name</h2>
                                    </pos-column>
                                    <pos-column lg="6" xs="2" class="ps-pix-10">
                                        <h2> {{inventoryProductDetails.SecondaryBinName}}</h2>
                                    </pos-column>
                                    <pos-column lg="2" xs="4" class="text-align-right">
                                        <button class="btn btn-warning white pos-button" (click)="triggerFlashLightOfSecondaryBin()"
                                            *ngIf="inventorySecondaryBinLights.length">
                                            Flash
                                        </button>
                                    </pos-column>
                                </pos-row>
                            </pos-column>

                            <pos-column class="mt-pix-5 p-0">
                                <pos-row class="mx-pix-5">
                                    <pos-column lg="4" xs="6">
                                        <h2 class="theme-separator">Description</h2>
                                    </pos-column>
                                    <pos-column lg="8" xs="6" class="ps-pix-10">
                                        <h2> {{inventoryProductDetails.SecondaryBinDescription}}</h2>
                                    </pos-column>
                                </pos-row>
                            </pos-column>
                        </pos-row>
                        <!--Secondary Bin Info End-->
                    </pos-column>
                </pos-row>

                <!-- Other Locations Begin -->
                <pos-row class="mx-0" *ngIf="offsiteInventoryProducts.length">
                    <pos-column md="6" xs="12" class="p-0 border-1">
                        <pos-row class="mx-0">
                            <pos-column xs="12" class="text-align-center bg-theme-separator">
                                <h1>Other Locations</h1>
                            </pos-column>
                        </pos-row>
                        <pos-row class="mx-0">
                            <pos-column xs="12" class="p-pix-15">
                                <ng-table [columns]="offsiteInventoryProductColumns" [data]="offsiteInventoryProducts"
                                [paginator]="false" [search]="false"></ng-table>
                            </pos-column>
                        </pos-row>
                    </pos-column>
                </pos-row>
                <!-- Other Locations End -->
            </div>
        </div>
        <div class="col-12 action-button-container popup-footer">
            <button class="btn btn-primary btn-lg btn-save pos-button" type="button"
                *ngIf="inventoryProductDetails && inventoryProductDetails.id && (inventoryProductDetails.MaxPortionsText || inventoryProductDetails.MeasureByInstructions)"
                (click)="labelPrint()">Print Label</button>
            <button class="btn btn-secondary btn-lg btn-cancel-modal pos-button" type="button"
                (click)="onCancel()">Close</button>
        </div>
    </form>
</pos-edit-wrapper>
