import { IKioskNavigation } from "../interfaces/ikiosk-navigation";
import { ChoiceBehavior } from "./choices-behavior";
import { Injectable } from "@angular/core";
import { KioskNavigationService } from "../services";
import { find, remove } from "lodash";
import { DomainConstants } from "src/app/shared";

@Injectable({
    providedIn: 'root'
})
export class AddDetailEntryBehavior extends ChoiceBehavior {

    constructor(private kioskNavigationService: KioskNavigationService) {
        super();
    }

    execute(navObj: IKioskNavigation, orderNavigation: any): Promise<void> {
        return this.addProduct(navObj, orderNavigation);
    }

    private async addProduct(x: IKioskNavigation, orderNavigation): Promise<void> {
        if (!this.kioskNavigationService.versionedVal?.Obj?.Order?.Product?.SalesProductId) {
            this.kioskNavigationService.versionedVal.Obj.Order.Product.SalesProductId = x.button.SalesProductId;
            this.kioskNavigationService.versionedVal.Obj.Order.Product.Qty = x.button.SalesProduct?.DefaultQty ?? 1;
        }

        //Toggle button will handle in handle toggle function
        if (x.button.ButtonType != DomainConstants.KioskChoiceType.Toggle) {
            this.addComponent(x);
        } else {
            x.button.IsSelected ? this.addComponent(x) : this.removeComponent(x);
        }

        return Promise.resolve();
    }

    addComponent(x: IKioskNavigation) {
        let component = find(this.kioskNavigationService.versionedVal.Obj.Order.Product.Components, (component) => {
            return component.ButtonId == x.button.Id
        });
        // Don't Add component if the button is default and has navigation
        //In that scenario, user is just going with default and navigating to next screen
        // TODO: Check default, next screen and stack screen scenarios
        // if (!component && (x.button.IsDefault && !x.button.NextScreenId&& !x.button.StackScreenId) || !x.button.IsDefault) {
        if (!component) {
            this.kioskNavigationService.versionedVal.Obj.Order.Product.Components.push({
                SalesProductId: x.button.SalesProductId,
                ButtonId: x.button.Id,
                SizeId:  this.kioskNavigationService.versionedVal.Obj.Order.Product.SizeId,
                ButtonText: x.button.ButtonText,
                KitchenText: x.button.KitchenText
                // IsExclusion: x.button.IsDefault,
                // TODO: Check comment. temporary nav and group name
                // Comment: this.kioskNavigationService.versionedVal.Obj.Order.OrderItem.Comment,
                // TemporayNavigationScreenChoiceId: this.roundTripButton ? this.roundTripButton.ScreenChoiceId : null,
                // GroupName: x.button.GroupName,
            });
        }
    }

    removeComponent(x: IKioskNavigation) {
        remove(this.kioskNavigationService.versionedVal.Obj.Order.Product.Components, (component) => {
            return component.ButtonId == x.button.Id
        });
    }
}

